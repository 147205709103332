import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`

`;

const Deck = styled.div`
  border-bottom: 1px solid #CCC;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

const WordList = styled.div`
  font-size: 14px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ImageWrapper = styled.div`
  img {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    margin-bottom: 10px;
  }
`;



@observer
class TreeNodeVocab extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.treeNode && this.props.treeNode.treeNodeDecks && this.props.treeNode.treeNodeDecks.map(treeNodeDeck => {
            if (treeNodeDeck.deck.webPublished || AppUI.user.admin) {
              return (
                <Deck
                  key={treeNodeDeck.id}
                >
                  <ImageWrapper>
                    <img src={treeNodeDeck.deck.imageUrl} alt="" />
                  </ImageWrapper>
                  <Title>
                    {treeNodeDeck.deck.name}
                  </Title>
                  <WordList>
                    {treeNodeDeck.deck.wordsList}
                  </WordList>
                </Deck>
              )
            } else {
              return null;
            }
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TreeNodeVocab);
