import { AppUI } from '@seedlang/state';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { observer } from 'mobx-react';
import React from 'react';
import CheckoutSeedlang from './checkout_seedlang';
import CheckoutWhitelabel from './checkout_whitelabel';

@observer
class Checkout extends React.Component {
  render() {
    return (
      AppUI.siteIsDefault ? (
        <CheckoutSeedlang {...this.props} />
      ) : (
        <CheckoutWhitelabel {...this.props} />
      )
    );
  }
}

export default ErrorBoundary(Checkout);
