import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import PatreonConfirmation from 'components/user/patreon_confirmation';
import { flexCenterColumn } from '@seedlang/style_mixins';

const Wrapper = styled.div`
  ${flexCenterColumn()}
`;

@observer
class SettingsPatreon extends React.Component {

  render() {
    return (
      <Wrapper>
        <PatreonConfirmation
          showRemoveLink
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SettingsPatreon);
