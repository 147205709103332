import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { OnboardingIllustrationWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import Text from 'components/text';
import autobind from 'autobind-decorator';
import { pixify } from '@seedlang/utils';
import StickerImage from 'components/sticker_image';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  background: #FFF;
  margin-bottom: 20px;
`;

const ImageWrapper = styled.div`
  img {
    height: 80px;
  }
`;

const SpeechBubble = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  width: 260px;
  border: 2px solid #CCC;
  font-size: 15px;
  font-weight: bold;
  color: #333;
  margin-left: 20px;
  min-height: ${props => props.minHeight};
`;

const ArrowWrapper = styled.div`
  position: absolute;
  left: -17px;
  top: 15px;
`;

@observer
class OnboardingIllustration extends React.Component {

  render () {
    return (
      <Wrapper>
        <ImageWrapper>
          <StickerImage
            height="80px"
            width="80px"
            reaction={this.props.reaction}
            languageId={AppUI.targetLanguageId}
          />
        </ImageWrapper>
        <SpeechBubble
          minHeight={pixify(this.props.speechBubbleHeight)}
        >
          <ArrowWrapper>
            <img src="/images/speech_bubble_arrow.png" />
          </ArrowWrapper>
          {this.props.displayedText}
        </SpeechBubble>
      </Wrapper>
    );
  }
}

export default OnboardingIllustrationWrapper(OnboardingIllustration);
