import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { DeckUI } from '@seedlang/state';
import Text from 'components/text';
import { pixify } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import DeleteButton from 'components/button/delete_button';
import { LanguageIcon } from '@seedlang/icons';
import SourceSubmission from 'components/source/source_submission';
import TranslatorSentence from 'pages/translator/translator_sentence';
import TranslatorDecksShow from 'pages/translator/translator_decks_show';
import { isPresent, displayTargetTextCorrectedForLanguage } from "@seedlang/utils";
import Button from "../button/button";
import cx from 'classnames';
import { AppUI } from "@seedlang/state";
import FlagButton from 'components/button/flag_button';
const Wrapper = styled.div`
  padding: 10px;
  background: white;
  height: ${props => props.height};
  overflow: auto;
`;

const SourceWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background: whitesmoke;
  margin: 10px 0;
`;

const Source = styled.div`
  flex: 1;
`;

const TranslatorSentencesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

@observer
class SourceEdit extends React.Component {
  @observable expandSentences = false;

  @computed get word() {
    // using passed in word doesn't work because it is passed in on array and not "reactive" data?
    return DeckUI.currentCard && DeckUI.currentCard.word ? DeckUI.currentCard.word : {};
  }

  @autobind onDelete(id) {
    DeckUI.translationSourceStore.destroy({ids: {translationSourceId: id}}, this.afterUpdate);
  }

  @autobind flagTranslation(translation){
    translation.flaggedForRetranslation = !translation.flaggedForRetranslation;
    DeckUI.translationSourceStore.update({ids: {translationSourceId: translation.id}, data: {flaggedForRetranslation: translation.flaggedForRetranslation}}, this.afterUpdate)
  }

  @autobind afterUpdate() {
    if (isPresent(this.word)) {
      DeckUI.enrichWord(this.props.card, true);
    } else if (isPresent(this.props.sentence)) {
      DeckUI.enrichSentence(this.props.card, true);
    }
  }

  @computed get isVocabDeck() {
    return AppUI.routeStore.routeName.startsWith('vocab');
  }

  render() {
    return (
      <Wrapper
        height={pixify(DeckUI.layout.overlayHeight)}
      >
        <Text
          center
          italic
          margin="10px 0 0 0"
        >
          Update Translations for
        </Text>
        <Text
          heading="2"
          center
          margin="0px 0 20px 0"
        >
          {this.word && this.word.targetText}
          {this.props.sentence && displayTargetTextCorrectedForLanguage(this.props.sentence.targetText)}
        </Text>
        {
          this.word && this.word.translationSources && this.word.vocabSourceForUser(DeckUI.user) && this.word.vocabSourceForUser(DeckUI.user).filter(item => item.showInVocab).map(item => {
            return (
              <SourceWrapper
                key={item.id}
              >
                <LanguageIcon
                  languageId={item.source && item.source?.languageId}
                  margin="0 10px 0 0"
                />
                <Source>
                  {`${item.source && item.source.text}${item.source?.ukSpelling ? ` / ${item.source.ukSpelling}` : ''}${item.source.displayGender ? this.word.genderAbbreviation : ''}`}
                </Source>
                <FlagButton 
                    onConfirm={()=>this.flagTranslation(item)}
                    flagged={item.flaggedForRetranslation}
                />
                {
                  (item.userId === DeckUI.user.id || (item.source && item.source?.languageId !== 'EN')) &&
                    <DeleteButton
                      allowDeletion
                      message="Remove this Translation?"
                      onConfirm={() => this.onDelete(item.id)}
                    />
                }
              </SourceWrapper>
            )
          })
        }
        <Text
          bold
          center
          margin="20px 0 10px 0"
        >
          Submit a New Translation
        </Text>
        {
          isPresent(this.props.sentence) &&
            <TranslatorSentencesWrapper>
              {
                !this.expandSentences &&
                  <TranslatorSentence
                    hideVideos
                    sentence={this.props.sentence}
                    afterSubmit={this.afterUpdate}
                    width="100%"
                    onFlag={this.flagTranslation}
                  />
              }
              {
                this.expandSentences &&
                  <TranslatorDecksShow
                    inDeck
                    params={{deckId: DeckUI.deck.id}}
                  />
              }
              {
                !this.expandSentences && !this.isVocabDeck &&
                  <Button
                    onClick={() => this.expandSentences = !this.expandSentences}
                    margin="0 0 20px 0"
                    width="250px"
                  >
                    <i
                      className={cx('fa', {
                        'fa-plus': !this.expandSentences,
                        'fa-minus': this.expandSentences,
                      })}
                      style={{
                        margin: "3px 5px 0 0",
                      }}
                    />
                    Show All Sentences
                  </Button>
              }
            </TranslatorSentencesWrapper>
        }
        {
          isPresent(this.word) &&
            <SourceSubmission
            afterUpdate={this.afterUpdate}
            word={this.word}
            />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SourceEdit);
