import React from 'react';
import { observer } from 'mobx-react';
import { WordOptionsCardWrapper } from '@seedlang/hoc';
import MediaSection from 'components/vocab_card/media_section';
import CardSection from 'components/vocab_card/card_section';
import ExpandedTarget from 'components/vocab_card/expanded_target';
import { DeckUI } from '@seedlang/state';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import MultipleChoiceButtons from 'components/card/multiple_choice_buttons';
import EvaluateButtons from 'components/card/evaluate_buttons';
import CardSourceList from 'components/vocab_card/card_source_list';
import { pixify, isPresent } from '@seedlang/utils';
import Answer from "components/vocab_card/answer";

@observer
class WordOptionsCard extends React.Component {

  render() {
    return (
      <CardWrapper
        card={this.props.card}
        cardState={this.props.cardState}
        userCard={this.props.userCard}
        icons={this.props.icons}
      >
        <CardContent
          height={pixify(this.props.cardState.revealed ? DeckUI.layout.contentHeight : DeckUI.layout.contentHeightWithMultipleChoiceButtons(4))}
        >
          {
            this.props.card.hasTargetVideoClip &&
              <MediaSection
                showMedia
                card={this.props.card}
                cardState={this.props.cardState}
                autoPlay={this.props.inView}
                borderTopRadius={10}
                height={this.props.sectionHeight}
              />
          }
          <CardSection
            background="#FFF"
            borderTopRadius={this.props.card.hasTargetVideoClip ? 0 : 10}
            borderBottomRadius={this.props.cardState.revealed ? 0 : 10}
            height={this.props.sectionHeight}
          >
            {
              this.props.showExpandedTarget &&
                <ExpandedTarget
                  card={this.props.card}
                  cardState={this.props.cardState}
                  height={this.props.sectionHeight}
                  word={this.props.card.word}
                />
            }
          </CardSection>
          {
            this.props.cardState.revealed &&
              <CardSection
                background="#CCC"
                borderBottomRadius={10}
                height={this.props.sectionHeight}
                fontSize={(this.props.card.word.isNumber && this.props.card.word.digitSourceText) ? "34px" : null}
              >
                {
                  (!this.props.card.word.isNumber || !this.props.card.word.digitSourceText) &&
                    <CardSourceList
                      showEdit
                      word={this.props.card.word}
                      card={this.props.card}
                      showWrongAnswer={isPresent(this.props.incorrectAnswer)}
                      wrongAnswer={this.props.incorrectAnswer}
                    />
                }
                {
                  this.props.card.word.isNumber && this.props.card.word.digitSourceText &&
                    <span>{this.props.card.word.formattedDigitSourceText}</span>
                }
                {
                  this.props.card.word.isNumber && this.props.card.word.digitSourceText && isPresent(this.props.incorrectAnswer) &&
                    <Answer
                      text={this.props.incorrectAnswer}
                      marginBottom='10px'
                    />
                }
              </CardSection>
          }
        </CardContent>
        {
          !this.props.cardState.revealed &&
            <MultipleChoiceButtons
              disabled={!this.props.inView}
              height={DeckUI.layout.totalMultipleChoiceButtonHeight(4)}
              buttonHeight={DeckUI.layout.multipleChoiceButtonHeight}
              width={DeckUI.layout.innerDeckWidth}
              options={this.props.vocabMultipleChoiceOptions}
              onClick={(option) => this.props.onClick(option)}
              selected={[this.props.multipleChoiceSelected]}
              isMobile={DeckUI.layout.isMobile}
              correct={this.props.correct}
              field="text"
              languageId={this.props.card.languageId}
            />
        }
        {
          this.props.cardState.revealed &&
            <EvaluateButtons
              card={this.props.card}
              onNextClick={this.props.onNextClick}
              cardState={this.props.cardState}
              userCard={this.props.userCard}
              disabled={!this.props.inView}
            />
        }
      </CardWrapper>
    );
  }
}

export default WordOptionsCardWrapper(WordOptionsCard);
