import React from 'react';
import { observer } from 'mobx-react';
import { ReviewDeckSettingsWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import { Languages, Theme } from '@seedlang/constants';
import InPlaceTextInput from 'components/form/in_place_text_input';
import InPlaceColorPicker from 'components/form/in_place_color_picker';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { AppUI } from '@seedlang/state';
import Alert from 'components/alert';
import Text from 'components/text';
import { isPresent, pixify } from '@seedlang/utils';
import ReviewDeckNoCards from 'components/review/review_deck_no_cards';
import InPlaceSelect from "../form/in_place_select";
import Spinner from "components/spinner";

const Wrapper = styled.div`

`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonText = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  color: ${props => props.color};
  .fa-arrow-right {
    transform: rotate(-45deg);
  }
  i {
    position: absolute;
    left: -5px;
    top: 16px;
    color: ${props => props.color};
  }
  .label {
    margin-left: 10px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  input {
    width: 70px;
    margin-right: 5px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DeleteConfirmationWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 120px;
`;

const DailyGoalWrapper = styled.div`
  margin-top: 10px;
`;

@observer
class ReviewDeckSettings extends React.Component {

  render () {
    return (
      <Wrapper>
        {
          this.props.askForDeleteConfirmation &&
            <DeleteConfirmationWrapper>
              <Text
                color="#333"
                bold
                center
              >
                Delete This Deck?
              </Text>
              <Text
                color="#333"
                fontSize="14px"
                center
                lineHeight="17px"
              >
                This will delete all cards in this deck and is not reversible.
              </Text>
              <ButtonRow>
                <Button
                  height="50px"
                  width="100px"
                  color="#FFF"
                  background={Theme.green}
                  margin="10px 5px 10px 0"
                  onClick={this.props.onDeleteDeck}
                >
                  Yes
                </Button>
                <Button
                  height="50px"
                  width="100px"
                  color="#FFF"
                  background={Theme.red}
                  margin="10px 0 10px 5px"
                  onClick={() => this.props.onSetAskForDeleteConfirmation(false)}
                >
                  No
                </Button>
              </ButtonRow>
            </DeleteConfirmationWrapper>
        }
        {
          this.props.askForDeleteCardsConfirmation &&
            <DeleteConfirmationWrapper>
              <Text
                color="#333"
                bold
                center
              >
                Delete All Cards in this Deck?
              </Text>
              <Text
                color="#333"
                fontSize="14px"
                center
                lineHeight="17px"
              >
                This will delete all cards in this deck and is not reversible.
              </Text>
              <ButtonRow>
                <Button
                  height="50px"
                  width="100px"
                  color="#FFF"
                  background={Theme.green}
                  margin="10px 5px 10px 0"
                  onClick={this.props.onDeleteCards}
                >
                  Yes
                </Button>
                <Button
                  height="50px"
                  width="100px"
                  color="#FFF"
                  background={Theme.red}
                  margin="10px 0 10px 5px"
                  onClick={() => this.props.onSetAskForDeleteCardsConfirmation(false)}
                >
                  No
                </Button>
              </ButtonRow>
            </DeleteConfirmationWrapper>
        }
        {
          this.props.askForResetConfirmation &&
            <DeleteConfirmationWrapper>
              {
                !this.props.resettingReviews &&
                <>
                  <Text
                    color="#333"
                    bold
                    center
                  >
                    Reset All Reviews in this Deck?
                  </Text>
                  <Text
                    color="#333"
                    fontSize="14px"
                    center
                    lineHeight="17px"
                  >
                    {`This will erase your progress and all ${this.props.deck.reviewCardsCount.toLocaleString('en')} reviews in the deck will be due today.`}
                  </Text>
                  <ButtonRow>
                    <Button
                      height="50px"
                      width="100px"
                      color="#FFF"
                      background={Theme.green}
                      margin="10px 5px 10px 0"
                      onClick={this.props.onResetCards}
                    >
                      Yes
                    </Button>
                    <Button
                      height="50px"
                      width="100px"
                      color="#FFF"
                      background={Theme.red}
                      margin="10px 0 10px 5px"
                      onClick={() => this.props.onSetAskForResetConfirmation(false)}
                    >
                      No
                    </Button>
                  </ButtonRow>
                </>
              }
              {
                this.props.resettingReviews &&
                <div>
                  <Spinner />
                  <Text small margin='10px 0'>Resetting reviews...</Text>
                </div>
              }
            </DeleteConfirmationWrapper>
        }
        {
          isPresent(this.props.message) &&
            <Alert
              width="100%"
              background={Theme.green}
              textAlign="center"
            >
              {this.props.message}
            </Alert>
        }
        {
          this.props.afterCreateDeck &&
            <ReviewDeckNoCards />
        }
        {
          !this.props.afterCreateDeck && !this.props.askForDeleteConfirmation &&  !this.props.askForResetConfirmation && !this.props.askForDeleteCardsConfirmation &&
            <InnerWrapper>
              <Text
                color="#333"
                bold
                width="100%"
                textAlign="left"
                fontSize="14px"
              >
                Deck Name
              </Text>
              <InPlaceTextInput
                width="100%"
                margin="10px 0"
                value={this.props.name}
                onChange={(val) => this.props.onSetName(val)}
              />
              {
                this.props.duplicateNameError &&
                  <Alert>
                    Another deck exists with this name.
                  </Alert>
              }
              <Text
                color="#333"
                bold
                width="100%"
                textAlign="left"
                fontSize="14px"
                margin="20px 0 0 0"
              >
                Deck Color
              </Text>
              <InPlaceColorPicker
                value={this.props.color}
                onChange={(val) => this.props.onSetColor(val)}
              />
              <InPlaceCheckbox
                allowUpdate
                icon="check"
                width="100%"
                wrapperMargin="10px 0 0 0"
                value={this.props.defaultReviewDeck}
                onChange={this.props.onToggleDefaultReviewDeck}
              >
                <Text
                  color="#333"
                  bold
                  textAlign="left"
                  fontSize="14px"
                >
                  Default Deck
                </Text>
              </InPlaceCheckbox>
              <Text
                color="#333"
                width="100%"
                textAlign="left"
                fontSize="12px"
                lineHeight="16px"
                margin="0"
              >
                If checked, this deck will receive new reviews if you don't specify a deck.
              </Text>
              {
                this.props.showDailyGoalSetting &&
                <DailyGoalWrapper>
                  <InPlaceCheckbox
                    allowUpdate
                    icon="check"
                    width="100%"
                    wrapperMargin="10px 0 0 0"
                    value={this.props.useDailyGoal}
                    onChange={this.props.onToggleUseDailyGoal}
                  >
                    <Text
                      color="#333"
                      bold
                      textAlign="left"
                      fontSize="14px"
                    >
                      Set a Daily Goal
                    </Text>
                  </InPlaceCheckbox>
                  <Text
                    color="#333"
                    width="100%"
                    textAlign="left"
                    fontSize="12px"
                    lineHeight="16px"
                    margin="0 0 2px 0"
                  >
                    This is helpful if the number of reviews in your deck is too many to keep up with each day.
                  </Text>
                  {
                    this.props.useDailyGoal &&
                      <InputWrapper>
                        <InPlaceTextInput
                          value={this.props.dailyGoal}
                          onChange={(val) => this.props.onSetDailyGoal(val)}
                          type="number"
                          min={this.props.dailyGoalMin}
                          max={isPresent(this.props.deck) && this.props.dailyGoalMax > 24 ? this.props.dailyGoalMax : 100}
                          disabled={isPresent(this.props.deck) ? this.props.dailyGoalMax <= this.props.dailyGoalMin : null}
                        />
                        Cards Per Day
                      </InputWrapper>
                  }
                  {
                    this.props.dailyGoalIsTooLow &&
                    <Alert fontSize='12px'
                           lineHeight='14px'>{`The daily goal must be higher than ${this.props.dailyGoalMin}.`}</Alert>
                  }
                  {
                    this.props.dailyGoalIsTooHigh &&
                    <Alert fontSize='12px'
                           lineHeight='14px'>{`The daily goal must be lower than the number of review cards (${this.props.dailyGoalMax}).`}</Alert>
                  }
                </DailyGoalWrapper>
              }
              {
                isPresent(this.props.deck) &&
                <>
                  <InPlaceCheckbox
                    allowUpdate={this.props.deck && !this.props.deck?.defaultReviewDeck}
                    icon="check"
                    width="100%"
                    wrapperMargin="10px 0 0 0"
                    value={this.props.archived}
                    onChange={this.props.onToggleArchived}
                  >
                    <Text
                      color="#333"
                      bold
                      textAlign="left"
                      fontSize="14px"
                    >
                      Archived
                    </Text>
                  </InPlaceCheckbox>
                  <Text
                    color="#333"
                    width="100%"
                    textAlign="left"
                    fontSize="12px"
                    lineHeight="16px"
                    margin="0"
                  >
                    If checked, you won't be able to add reviews to this deck and it will be moved to the archive tab.
                  </Text>
                </>
              }
              {
                AppUI.user.targetLanguagesWithXpCount > 1 && this.props.deck &&
                <div>
                  <Text
                    color="#333"
                    bold
                    width="100%"
                    textAlign="left"
                    fontSize="14px"
                    margin="10px 0 0 0"
                  >
                    Language
                  </Text>
                  <InPlaceSelect
                    allowUpdate
                    options={AppUI.user.targetLanguages.map(item => [item.languageId, Languages[item.languageId]])}
                    value={this.props.languageId}
                    id={this.props.deck.id}
                    onChange={(val) => this.props.onChangeLanguage(val)}
                  />
                </div>
              }
              <Button
                height="50px"
                width="100%"
                background={this.props.enabledSubmitButton ?  Theme.blue : "#CCC"}
                cursor={this.props.enabledSubmitButton ? "pointer" : "default"}
                margin="10px 0 0 0"
                onClick={this.props.onSubmit}
              >
                <ButtonText
                  color="#FFF"
                >
                  <i className='fa fa-check' />
                  {isPresent(this.props.deck) ? 'Update Deck' : 'Create Deck'}
                </ButtonText>
              </Button>
              {
                false &&
                  <ButtonRow>
                    {
                      isPresent(this.props.deck) && this.props.deck.reviewCardsCount > 0 &&
                      <Button
                        height="50px"
                        width={pixify((AppUI.layout.defaultModalWidth - 50) / 2)}
                        background="#FFF"
                        border={`3px solid ${Theme.green}`}
                        margin="10px 5px 0 0"
                        onClick={() => this.props.updateReviewAfter(1)}
                      >
                        <ButtonText
                          color={Theme.green}
                        >
                          Snooze 1 Day
                        </ButtonText>
                      </Button>
                    }
                    {
                      isPresent(this.props.deck) && this.props.deck.reviewCardsCount > 0 &&
                      <Button
                        height="50px"
                        width={pixify((AppUI.layout.defaultModalWidth - 50) / 2)}
                        background="#FFF"
                        border={`3px solid ${Theme.green}`}
                        margin="10px 0 0 5px"
                        onClick={() => this.props.updateReviewAfter(7)}
                      >
                        <ButtonText
                          color={Theme.green}
                        >
                          Snooze 7 Days
                        </ButtonText>
                      </Button>
                    }
                  </ButtonRow>
              }
              {
                AppUI.reviewDeckStore.indexData.length === 1 && this.props.deck && this.props.deck.reviewCardsCount > 0 &&
                  <Button
                    height="50px"
                    width="100%"
                    background="#FFF"
                    border={`3px solid ${Theme.red}`}
                    margin="10px 0 0 0"
                    onClick={() => this.props.onSetAskForDeleteCardsConfirmation(true)}
                  >
                    <ButtonText
                      color={Theme.red}
                    >
                      <i className='fa fa-times' />
                      Clear Cards
                    </ButtonText>
                  </Button>
              }
              {
                !this.props.askForDeleteConfirmation && !this.props.askForResetConfirmation && isPresent(this.props.deck) && AppUI.reviewDeckStore.indexData.length > 1 &&
                <ButtonRow>
                  <Button
                    height="50px"
                    width="50%"
                    background="#FFF"
                    border={`3px solid ${Theme.blue}`}
                    margin="10px 0 0 0"
                    onClick={() => this.props.onSetAskForResetConfirmation(true)}
                  >
                    <ButtonText
                      color={Theme.blue}
                    >
                      <i className='fa fa-times'/>
                      Reset Deck
                    </ButtonText>
                  </Button>
                  <Button
                    height="50px"
                    width="50%"
                    background="#FFF"
                    border={`3px solid ${Theme.red}`}
                    margin="10px 0 0 0"
                    onClick={() => this.props.onSetAskForDeleteConfirmation(true)}
                  >
                    <ButtonText
                      color={Theme.red}
                    >
                      <i className='fa fa-times'/>
                      Delete Deck
                    </ButtonText>
                  </Button>
                </ButtonRow>
              }
            </InnerWrapper>
        }
      </Wrapper>
    );
  }
}

export default ReviewDeckSettingsWrapper(ReviewDeckSettings);
