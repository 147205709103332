import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { MembershipTypeStore } from '@seedlang/stores';
import { Site } from '@seedlang/models';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import MembershipTypeCreate from 'pages/builder/membership_types/membership_type_create';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import InPlaceText from 'components/form/in_place_text';
import CheckIfTrue from 'components/check_if_true';

@observer
class MembershipTypeIndex extends React.Component {

  componentDidMount() {
    this.loadMembershipTypes();
  }

  @autobind onDelete(id) {
    MembershipTypeStore.destroy({ids: {membershipTypeId: id}});
  }

  @autobind loadMembershipTypes() {
    MembershipTypeStore.getIndex();
  }

  @computed get filteredMembershipTypes() {
    return MembershipTypeStore.indexData.filter((item) => item.siteId === Site.defaultSiteId())
  }

  render() {
    return (
      <div className='builder-membership-type-index'>
        <div className='row'>
          <div className='col-xs-12'>
            <MembershipTypeCreate />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <table className="table-wrapper admin-table">
              <thead>
                <tr>
                  <th width="20" />
                  <th>Pos</th>
                  <th>Name</th>
                  <th>Slug</th>
                  <th>Active</th>
                  <th width="40"></th>
                </tr>
              </thead>
              <tbody>
                {
                  this.filteredMembershipTypes.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <Link
                            to={{ name: 'builder.membership_types.edit', params: { membershipTypeId: item.id } }}
                            className="on-click"
                          >
                            <i className="fa fa-chevron-right fa-block" />
                          </Link>
                        </td>
                        <td>
                          <InPlaceText
                            id={item.id}
                            defaultValue={item.position}
                            model="membership_types"
                            field="position"
                            type="number"
                            step="1"
                            afterChange={this.loadMembershipTypes}
                          />
                        </td>
                        <td>
                          <div className="text">
                            {item.name}
                          </div>
                        </td>
                        <td>
                          <div className="text">
                            {item.slug}
                          </div>
                        </td>
                        <td>
                          <div className="text">
                            <CheckIfTrue
                              value={item.active}
                            />
                          </div>
                        </td>
                        <td>
                          <DeleteButton
                            onConfirm={() => this.onDelete(item.id)}
                            message="Delete this Payment Plan?"
                            className="fa-block right"
                          />
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
            <Paginator
              store={MembershipTypeStore}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MembershipTypeIndex;
