import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { AppUI } from '@seedlang/state';
import { Link } from 'react-router';
import { GraphOutlineIcon, FaqIcon } from '@seedlang/icons';
import styled from '@emotion/styled';
import HeaderBar from 'components/header_bar';
import PageOnboarding from 'components/onboarding/page_onboarding';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
`;

const Content = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.padding};
`;

@observer
class Review extends React.Component {

  constructor(props) {
    super(props)
    AppUI.loadUser();
    AppUI.createEvent('reviews - view page');
  }

  @computed get routeName() {
    return this.props.children && this.props.children.props && this.props.children.props.route ? this.props.children.props.route.name : '';
  }

  render() {
    return (
      <Wrapper>
        {
          !AppUI.user.isMember &&
            <PageOnboarding
              hideCloseIcon
              className='tree-onboarding'
              margin={AppUI.layout.isMobile ? "0" : "0 0 10px 0"}
              background={Theme.orange}
              color="#FFF"
              flat
              borderRadius={AppUI.layout.isMobile ? 0 : 5}
              fontSize={AppUI.layout.isMobile ? 13 : 15}
            >
              {
                AppUI.layout.isMobile &&
                  <Link
                    to={{name: 'stories.home'}}
                    className='underline'
                  >
                    Get started with our <b>Free Content</b>.
                  </Link>
              }
              {
                !AppUI.layout.isMobile &&
                  <span>
                    <span>Get started with our </span>
                    <Link
                      to={{name: 'stories.home'}}
                      className='underline'
                    >
                      free content
                    </Link>
                    <span>. Upgrade to </span>
                    <Link
                      to={{name: 'memberships.index'}}
                      className='underline'
                    >
                      Seedlang Pro
                    </Link>
                    <span> to unlock all content and features.</span>
                  </span>
              }
            </PageOnboarding>
        }
        <HeaderBar
          title={`${AppUI.targetLanguageName} REVIEWS`}
          titleRoute="reviews.home"
          lookupPage
          pageInfo={[
            {
              route: 'reviews.progress',
              current: 'Progress',
            },
            {
              route: 'reviews.show',
              current: 'Review Details',
              breadcrumbs: [['reviews.index', 'Reviews List']],
              lookupPage: true,
            },
          ]}
          icons={[
            {
              icon: <FaqIcon />,
              tooltipText: "Help Center",
              iconRoute: "faq_categories.show",
              iconParams: {faqCategoryId: '7d2bdc2a-34ec-414a-971c-afb00c9291a5'},
            },
            {
              icon: <GraphOutlineIcon />,
              tooltipText: "View Progress",
              iconRoute: "reviews.progress",
              breadcrumb: "Progress",
            },
          ]}
        />
        <Content>
          {this.props.children}
        </Content>
      </Wrapper>
    )
  }
}

export default ErrorBoundary(Review);
