import React from 'react';
import { observer } from 'mobx-react';
import { WordTranslateCardWrapper} from '@seedlang/hoc';
import styled from '@emotion/styled';
import MediaSection from 'components/vocab_card/media_section';
import CardSourceList from 'components/vocab_card/card_source_list';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import { DeckUI } from '@seedlang/state';
import WordEntry from 'components/word_entry';
import CardSection from 'components/vocab_card/card_section';
import { Theme, Constants } from '@seedlang/constants';
import ExpandedTarget from 'components/vocab_card/expanded_target';
import TranslateButtons from 'components/vocab_card/translate_buttons';
import CardUserInput from 'components/card/card_user_input';
import { isBlank } from '@seedlang/utils';
import CardArrowBox from 'components/card/card_arrow_box';
import HintWarning from 'components/card/hint_warning';
import PageOnboarding from 'components/onboarding/page_onboarding';
import { isPresent } from "@seedlang/utils/src";
import Answer from "components/vocab_card/answer";

const Hint = styled.span`
  font-weight: bold;
  letter-spacing: 5px;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: ${Theme.z["card-3"]};
  .fa-times {
    color: ${Theme.red};
  }
  .fa-check {
    color: ${Theme.green};
  }
`;

const HintWrapper = styled.div`
  height: 80px;
`;

const WordEntryWrapper = styled.div`
  margin-top: ${props => props.marginTop};
  width: 100%;
`;

@observer
class WordTranslateCard extends React.Component {

  render() {
    return (
      <CardWrapper
        card={this.props.card}
        cardState={this.props.cardState}
        userCard={this.props.userCard}
        icons={this.props.icons}
      >
        <CardContent>
          {
            this.props.cardState.revealed && this.props.card.hasTargetVideoClip &&
              <MediaSection
                showMedia
                autoPlay={this.props.inView}
                card={this.props.card}
                cardState={this.props.cardState}
                borderTopRadius={10}
                height={this.props.sectionHeight}
                afterVideoPlayback={this.props.afterVideoPlayback}
              />
          }
          <CardSection
            background="#FFF"
            height={this.props.sectionHeight}
            numSections={this.props.numSections}
            borderTopRadius={this.props.cardState.revealed && this.props.card.hasTargetVideoClip ? 0 : 10}
            fontSize={(this.props.card.word.isNumber && this.props.card.word.digitSourceText) ? "34px" : null}
          >
            {
              (!this.props.card.word.isNumber || !this.props.card.word.digitSourceText) &&
                <CardSourceList
                  center
                  fontSize={DeckUI.layout.isMobile ? "20px" : "26px"}
                  background="#FFF"
                  showEdit={this.props.cardState.revealed}
                  word={this.props.card.word}
                  card={this.props.card}
                />
            }
            {
              this.props.card.word.isNumber && this.props.card.word.digitSourceText &&
                <span>{this.props.card.word.formattedDigitSourceText}</span>
            }
          </CardSection>
          <CardSection
            background="#CCC"
            height={this.props.sectionHeight}
            numSections={this.props.numSections}
            borderBottomRadius={this.props.showUserInput ? 0 : 10}
          >
            {
              !this.props.showUserInput && this.props.cardState.revealed &&
                <IconWrapper>
                  {
                    this.props.userCard.correct &&
                      <i className='fa fa-check' />
                  }
                  {
                    !this.props.userCard.correct && isBlank(this.props.cardState.textEntry) &&
                      <i className='fa fa-times' />
                  }
                </IconWrapper>
            }
            {
              !this.props.showUserInput && !this.props.showExpandedTarget && this.props.card.word &&
              <WordEntryWrapper
                marginTop={this.props.cardState.revealed ? '' : '80px'}
              >
                <WordEntry
                  disabled={!this.props.inView}
                  focus={this.props.inView && DeckUI.firstCardHasAdvanced}
                  target={this.props.card.word.targetTextWithDefiniteArticle}
                  message={this.props.message}
                  onChange={this.props.onTextInputChange}
                  onPressEnter={() => DeckUI.onEvaluateTextInput(this.props.card, this.props.cardState, this.props.userCard)}
                  value={this.props.cardState.textEntry}
                  hint={this.props.hint}
                  languageId={this.props.card.languageId || DeckUI.deck.languageId || 'DE'}
                  incorrect={this.props.cardState.revealed && this.props.vocabCardIncorrect}
                  revealed={this.props.cardState.revealed}
                  revealedValue={this.props.wrongAnswerWithDiff}
                />
              </WordEntryWrapper>
            }
            {
              this.props.showExpandedTarget &&
                <ExpandedTarget
                  card={this.props.card}
                  cardState={this.props.cardState}
                  showWrongAnswer={this.props.vocabCardIncorrect}
                  wrongAnswer={this.props.cardState.textEntry}
                  height={DeckUI.vocabTextHeight}
                  word={this.props.card.word}
                />
            }
            {
              this.props.cardState.revealed && !this.props.showExpandedTarget && isPresent(this.props.card.word) &&
                <Answer
                  correct
                  text={this.props.correctAnswerWithDiff}
                  marginBottom='10px'
                />
            }
            {
              !this.props.cardState.revealed &&
              <HintWrapper>
                <HintWarning
                  visibility={!this.props.hint && isBlank(this.props.warning) ? 'hidden' : null}
                >
                  { this.props.hint && <span>Hint: <Hint>{this.props.hint}</Hint></span> }
                  { isPresent(this.props.warning) && isPresent(Constants.WARNINGS[this.props.warning]) && <span>{Constants.WARNINGS[this.props.warning]}</span>}
                </HintWarning>
              </HintWrapper>
            }
          </CardSection>
          {
            this.props.showUserInput &&
              <CardUserInput
                card={this.props.card}
                cardState={this.props.cardState}
                userCard={this.props.userCard}
                disabled={!this.props.inView}
              />
          }
          <CardArrowBox
            card={this.props.card}
            possibleArrowBoxes={this.props.possibleArrowBoxes}
            currentArrowBox={this.props.currentArrowBox}
          />
        </CardContent>
        <TranslateButtons
          onNextClick={this.props.hideOnboardingPopups}
          advanceCard
          card={this.props.card}
          cardState={this.props.cardState}
          userCard={this.props.userCard}
          disabled={!this.props.inView}
          setShowHelp={this.props.setShowHelp}
          showHelpButton={this.props.showHelpButton}
          onHelpButtonClicked={this.props.onHelpButtonClicked}
        />
      </CardWrapper>
    );
  }
}

export default WordTranslateCardWrapper(WordTranslateCard);
