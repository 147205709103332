import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: ${(props) => props.right};
  left: ${(props) => props.left};
  background: orange;
  color: white;
  z-index: 999;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 15px;
`;

@observer
class HeightLabel extends React.Component {

  render() {
    return (
      <Wrapper
        right={this.props.right}
        left={this.props.left}
      >
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(HeightLabel);
