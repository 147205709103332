import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import InPlaceText from 'components/form/in_place_text';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import SettingsUserImage from 'components/user/settings_user_image';
import UserDeckIndex from 'pages/builder/user_decks/user_deck_index';
import NotificationIndex from 'pages/builder/notifications/notification_index';
import CampaignMessageIndex from 'pages/builder/campaign_messages/campaign_message_index';
import ContactMessageIndex from 'pages/builder/contact_messages/contact_message_index';
import SentenceSubmissionIndex from "pages/builder/sentence_submissions/sentence_submission_index";
import { Link } from 'react-router';
import { DailyXpCountStore, GroupStore, LevelStore, MembershipGroupStore, MembershipTypeStore, RouteStore, UserGroupStore, UserStore} from '@seedlang/stores';
import DailyActivity from 'components/daily_activity/daily_activity';
import SubscriptionIndex from 'pages/builder/subscriptions/subscription_index';
import InPlaceDatePicker from 'components/form/in_place_date_picker';
import { formatFilterTag, isBlank, isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';
import Alert from 'components/alert';
import styled from '@emotion/styled';
import DeleteButton from 'components/button/delete_button';
import OutlineButton from 'components/button/outline_button';
import { Constants, Languages, Theme, TimeZones } from '@seedlang/constants';
import CheckIfTrue from 'components/check_if_true';
import ReviewDeckIndex from 'pages/builder/decks/review_deck_index';
import FilterTags from 'components/filter_tags';
import { merge } from 'lodash';
import { Permissions, Site } from '@seedlang/models';
import ErrorLogIndex from 'pages/builder/error_logs/error_log_index';

const Row = styled.div`
  display: flex;
`;

const DailyActivityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 240px;
  padding: 10px;
`;

@observer
class UserEdit extends React.Component {
  @observable respSuccess;
  @observable respFailure;
  @observable addXpDate;
  @observable translationsUpdated = false;
  @observable showPasswordChangeConfirmation = false;
  @observable password;
  @observable groupId;

  // copied from vocab_wrapper
  @computed get filterSettings() {
    if (!UserStore.hasShowData || isBlank(UserStore.showData.targetLanguages)) { return null }
    return Constants.LANGUAGE_ABBREVIATIONS.filter(languageId => isPresent(this.userTargetLanguage(languageId))).map((languageId, index) => {
      let settings = [
        {
          key: 'level_id',
          userKey: 'vocabLevelId',
          label: 'Level',
          multiSelect: true,
          includeBlank: true,
          options: this.arrayToOptions(Constants.LEVELS.filter(item => item.abbreviation !== 'N'), 'abbreviation'),
          filterType: "select",
        },
        {
          key: 'root_word_type_id',
          userKey: 'vocabWordTypeId',
          label: 'Word Type',
          multiSelect: true,
          includeBlank: true,
          options: this.arrayToOptions(Constants.VOCAB_WORD_TYPES, 'name'),
          filterType: "select",
        },
        {
          key: 'learned',
          userKey: 'vocabLearned',
          label: 'Learned',
          description: 'Words that you have marked as learned by clicking the "Star" icon.',
          includeBlank: true,
          options: [[true, 'Yes'], [false, 'No']],
          filterType: "select",
        },
        {
          key: 'reviewing',
          userKey: 'vocabReviewing',
          label: 'Reviewing',
          includeBlank: true,
          description: "Words that you have added to a review deck.",
          options: [[true, 'Yes'], [false, 'No']],
          filterType: "select",
        },
        {
          key: 'seen',
          userKey: 'vocabSeen',
          label: 'Seen',
          includeBlank: true,
          description: 'Words that you have seen on Seedlang. Words seen in a sentence context also count as "Seen".',
          options: [[true, 'Yes'], [false, 'No']],
          filterType: "select",
        },
        {
          key: 'has_sentence',
          userKey: 'vocabHasSentence',
          label: 'Has Sentences',
          includeBlank: true,
          options: [[true, 'Yes'], [false, 'No']],
          filterType: "select",
        },
        {
          key: 'languages_string',
          userKey: 'vocabLanguagesWithWithout',
          label: "Translations in Language",
          shortLabel: 'Translations',
          includeBlank: true,
          filterType: "selectLanguage",
          options: [['with', 'Has Translations'], ['without', 'Has No Translations']],
          filterValue: `${this.userTargetLanguage(languageId).vocabLanguagesWithWithout !== "with" ? '!' : ''}${UserStore.showData.languageId}`,
          customTag: this.languagesStringFormatted ? this.languagesStringFormatted[index] : null,
          showCustomTag: true,
        }
      ]
      let updatedSettings = settings.map(item => {
        let value = this.userTargetLanguage(languageId)[item.userKey];
        const hsh = {
          tagDisplay: item.showCustomTag ? item.customTag : formatFilterTag(item, value),
          value: this.userTargetLanguage(languageId)[item.userKey],
        }
        return merge(item, hsh);
      });
      return updatedSettings;
    })
  }

  @autobind userTargetLanguage(languageId) {
    return UserStore.hasShowData && isPresent(UserStore.showData.targetLanguages) && UserStore.showData.targetLanguages.find(targetLanguage => targetLanguage.languageId === languageId);
  }

  @computed get languagesStringFormatted() {
    if (isPresent(UserStore.showData.vocabLanguagesEnable)) {
      return Constants.LANGUAGE_ABBREVIATIONS.map((languageId) => {
        return `${this.userTargetLanguage(languageId)?.vocabLanguagesWithWithout} ${Languages[UserStore.showData.languageId]}`
      });
    } else {
      return null;
    }
  }

  @computed get nativeLanguageDropdown() {
    return UserStore.showData.vocabLanguagesEnable ? UserStore.showData.vocabLanguagesWithWithout : null;
  }

  @computed get allowedMembershipTypes() {
    return MembershipTypeStore.indexData.filter((item => item.siteId === Site.defaultSiteId()));
  }

  constructor(props) {
    super(props);
    this.dailyXpCountStore = new DailyXpCountStore();
    this.loadUser();
    if (!MembershipTypeStore.hasIndexData) {
      MembershipTypeStore.getIndex();
    }
    GroupStore.getIndex();
    if (!MembershipGroupStore.hasIndexData) {
      MembershipGroupStore.getIndex();
    }
  }

  onChangePassword() {
    this.showPasswordChangeConfirmation = false;
    UserStore.update({
      ids: {
        userId: this.props.params.userId,
      },
      data: {
        password: this.password,
        password_confirmation: this.password,
      }
    }, this.afterChangePassword.bind(this))
  }

  changePasswordButtonClicked() {
    this.password = this.refs.password.value;
    this.refs.password.value = null;
    if (AppUI.isDevelopment) {
      this.onChangePassword();
    } else {
      this.showPasswordChangeConfirmation = true;
    }
  }

  afterChangePassword(resp) {
    if (resp.success) {
      this.respSuccess = true;
    } else {
      this.respFailure = true;
    }
  }

  @autobind loadUser() {
    UserStore.getShow({ids: {userId: this.props.params.userId}}, this.afterUserGetShow);
  }

  @autobind afterUserGetShow(resp) {
    UserStore.setShowData(resp);
  }

  @autobind onAddXp() {
    if (isPresent(this.addXpDate) && new Date(this.addXpDate) < new Date()) {
      UserStore.addXp({queryStrings: {date: this.addXpDate}, ids: {userId: this.props.params.userId}}, this.afterAddXp);
      this.addXpDate = null;
    }
  }

  @autobind updateTranslations() {
    this.translationsUpdated = true;
    UserStore.updateTranslationSources({ids: {userId: this.props.params.userId}}, this.loadUser);
  }

  @autobind afterAddXp() {
    window.location.reload();
  }

  @autobind addUserGroup() {
    UserGroupStore.create({data: {group_id: this.groupId, user_id: this.props.params.userId}}, this.loadUser)
    this.groupId = null;
  }

  @autobind onDeleteUser() {
    UserStore.destroy({ ids: { userId: UserStore.showData.id } }, this.afterDeleteUser);
  }

  @autobind afterDeleteUser() {
    RouteStore.routeToNamed("builder.users.index");
  }

  @autobind clearLastRepairedAt() {
    UserStore.update({ data: {lastStreakRepairedAt: null}, ids: { userId: UserStore.showData.id } }, this.loadUser);
  }

  @autobind arrayToOptions(object, field) {
    return object.map(item => [item.id, item[field]]);
  }

  render() {
    return (
      <div className='user-edit'>
        <div className='row'>
          <div className='col-xs-6'>
            <div style={{marginTop: 10}}>
              {
                UserStore.hasShowData &&
                  <Link
                    to={{name: 'profiles.show', params: {userId: UserStore.showData.id}}}
                    className='underline'
                  >
                    View Profile
                  </Link>
              }
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-4'>
            <fieldset>
              <legend>User</legend>
              <InPlaceText
                defaultValue={UserStore.showData.name}
                model="users"
                field="name"
                id={UserStore.showData.id}
              />
              <InPlaceText
                defaultValue={UserStore.showData.email}
                model="users"
                field="email"
                id={UserStore.showData.id}
                afterChange={this.loadUser}
              />
              <InPlaceCheckbox
                model="users"
                field="confirmed"
                id={UserStore.showData.id}
                value={UserStore.showData.confirmed}
                icon="check"
                afterChange={UserStore.reloadShow.bind(UserStore)}
              >
                Email confirmed
              </InPlaceCheckbox>
              <div style={{ fontSize: 12 }}>
                {UserStore.showData && (UserStore.showData.confirmed ? (<div>Email Confirmed At: {UserStore.showData.confirmedAt.formattedDateWithYearAndTime}</div>) : <div>Email Unconfirmed</div>)}
                {UserStore.showData.confirmedEmail && UserStore.showData.confirmedEmail !== UserStore.showData.email && <div>Confirmed Email: {UserStore.showData.confirmedEmail}</div>}
                {UserStore.showData.unconfirmedEmail && UserStore.showData.unconfirmedEmail !== UserStore.showData.email && <div>Unconfirmed Email: {UserStore.showData.unconfirmedEmail}</div>}
              </div>
            </fieldset>
            <fieldset>
              <legend>Target Language</legend>
              <InPlaceSelect
                value={UserStore.showData.targetLanguageId}
                model="users"
                field="target_language_id"
                options={Constants.LANGUAGE_SELECT_OPTIONS}
                id={UserStore.showData.id}
                afterChange={UserStore.reloadShow.bind(UserStore)}
              />
            </fieldset>
            <fieldset>
              <legend>User Groups</legend>
              {
                UserStore.hasShowData &&
                <ul className="bulleted">
                  {
                    UserStore.showData.userGroups.filter(item => !item.name.match('New Site')).map((item) => {
                      return (
                        <div>
                          <Link
                              to={{name: "creator.groups.users.edit", params: { groupId: item.group.id, userId: UserStore.showData.id}}}
                              key={item.id}
                            >
                            {item.name}
                          </Link>
                        </div>
                      );
                    })
                  }
                </ul>
              }
              <InPlaceSelect
                includeBlank
                options={GroupStore.indexData.filter(item => !item.name.match('New Site')).sort(item => item.name).map(item => [item.id, item.name])}
                onChange={(val) => this.groupId = val}
                value={this.groupId}
              />
              {
                this.groupId &&
                  <button
                    style={{marginTop: "5px"}}
                    className="button-primary"
                    onClick={this.addUserGroup}
                  >
                    Add to Group
                  </button>
              }
            </fieldset>
            <fieldset>
              <legend>Paypal Email</legend>
              <InPlaceText
                defaultValue={UserStore.showData.paypalEmail}
                model="users"
                field="paypal_email"
                id={UserStore.showData.id}
                placeholder="Enter Paypal Email"
              />
            </fieldset>
            <fieldset>
              <legend>Apple User ID</legend>
              <InPlaceText
                fontSize="12px"
                defaultValue={UserStore.showData.appleUserId}
                model="users"
                field="apple_user_id"
                id={UserStore.showData.id}
                placeholder="Enter Apple User Id"
              />
            </fieldset>
            <fieldset>
              <legend>Seedlang Membership</legend>
              <div className='row'>
                <div className='col-xs-12'>
                  <div>
                    <InPlaceSelect
                      includeBlank
                      options={this.allowedMembershipTypes.map(item => [item.id, item.name])}
                      value={UserStore.showData.membershipTypeId}
                      model="users"
                      field="membership_type_id"
                      id={UserStore.showData.id}
                      afterChange={UserStore.reloadShow.bind(UserStore)}
                    />
                  </div>
                  <div>
                    <InPlaceCheckbox
                      model="users"
                      field="permanent_membership"
                      id={UserStore.showData.id}
                      value={UserStore.showData.permanentMembership}
                      icon="check"
                      afterChange={UserStore.reloadShow.bind(UserStore)}
                    >
                      Permanent Free
                    </InPlaceCheckbox>
                  </div>
                  <div>
                    <InPlaceCheckbox
                      model="users"
                      field="temporary_free_membership"
                      id={UserStore.showData.id}
                      value={UserStore.showData.temporaryFreeMembership}
                      icon="check"
                      afterChange={UserStore.reloadShow.bind(UserStore)}
                    >
                      <div>Temporary Free</div>
                      <small>(Need to Set Membership Until)</small>
                    </InPlaceCheckbox>
                  </div>
                  <div>
                    <InPlaceCheckbox
                      model="users"
                      field="mobile_payment_without_receipt"
                      id={UserStore.showData.id}
                      value={UserStore.showData.mobilePaymentWithoutReceipt}
                      icon="check"
                      afterChange={UserStore.reloadShow.bind(UserStore)}
                    >
                      <div>Mobile Payment w/o Receipt</div>
                    </InPlaceCheckbox>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Membership Until</legend>
              <InPlaceDatePicker
                showCancelIcon
                id={UserStore.showData.id}
                model="users"
                field="member_until"
                value={UserStore.showData.memberUntil}
                afterChange={UserStore.reloadShow.bind(UserStore)}
              />
            </fieldset>
            <fieldset>
              <legend>Membership Group</legend>
              <InPlaceSelect
                includeBlank
                id={UserStore.showData.id}
                model="users"
                field="membership_group_id"
                options={MembershipGroupStore.indexData.map(item => [item.id, item.name])}
                value={UserStore.showData.membershipGroup && UserStore.showData.membershipGroup.id}
                afterChange={UserStore.reloadShow.bind(UserStore)}
              />
            </fieldset>
            <fieldset>
              <legend>Translator Status</legend>
              <InPlaceSelect
                includeBlank
                id={UserStore.showData.id}
                model="users"
                field="translator_type"
                options={[['3', 'Lead Translator'], ['2', 'Translator'], ['1', 'Trial Translator']]}
                value={UserStore.showData.translatorType}
                afterChange={UserStore.reloadShow.bind(UserStore)}
              />
              {
                UserStore.showData?.removedAsTranslator &&
                  <div
                    style={{fontSize: "12px", margin: "5px 0 0 0", color: "red"}}
                  >
                    <i className='fa fa-warning' /> Removed as Translator
                  </div>
              }
            </fieldset>
            <fieldset>
              <legend>Translator Assigned At</legend>
              <InPlaceDatePicker
                showCancelIcon
                id={UserStore.showData.id}
                model="users"
                field="translator_assigned_at"
                value={UserStore.showData.translatorAssignedAt}
                afterChange={UserStore.reloadShow.bind(UserStore)}
              />
            </fieldset>
            <fieldset>
              <legend>Xp View</legend>
              <InPlaceSelect
                includeBlank
                id={UserStore.showData.id}
                model="users"
                field="xp_view"
                options={[['calendar', 'Calendar'], ['list', 'List']]}
                value={UserStore.showData.xpView}
                afterChange={UserStore.reloadShow.bind(UserStore)}
              />
            </fieldset>
            <fieldset>
              <legend>Mobile Text Direction</legend>
              <InPlaceSelect
                includeBlank
                id={UserStore.showData.id}
                model="users"
                field="mobile_text_direction"
                options={[['auto', 'Auto'], ['forward', 'Forward'], ['reverse', 'Reverse']]}
                value={UserStore.showData.mobileTextDirection}
                afterChange={UserStore.reloadShow.bind(UserStore)}
              />
            </fieldset>
          </div>
          <div
            className='col-xs-4'
            style={{
              fontSize: 14,
            }}
          >
            <fieldset>
              <legend>Info</legend>
              {
                UserStore.hasShowData &&
                  <ul className='bulleted'>
                    <li>Active: {UserStore.showData.lastActiveAt.formattedDateWithYearAndTime}</li>
                    <li>Created: {UserStore.showData.createdAt?.formattedDateWithYear}</li>
                    <li>Language Signed Up With: {UserStore.showData.languageSignedUpWith}</li>
                    <li>Device Signed Up With: {UserStore.showData.deviceSignedUpWith}</li>
                    <li>Last Mail: {UserStore.showData.lastCampaignMailerAt?.formattedDateWithYear}</li>
                    <li>Last Mobile Notification: {UserStore.showData.lastMobileNotificationSentAt?.formattedDateWithYear}</li>
                    <li>Reviews: {UserStore.showData.reviewCardsCount}</li>
                    <li>Submitted Translations: {UserStore.showData.translationSourcesCount}</li>
                    <li>Moderator Actions: {UserStore.showData.moderationActionCounts}</li>
                    <li>Total Notifications: {UserStore.showData.notificationsCount}</li>
                    <li>Unread Notifications: {UserStore.showData.unseenNotificationsCount}</li>
                    <li>UI Seen: {UserStore.showData.deckTypesOpened}</li>
                    <li>Country Code: {UserStore.showData.countryCode}</li>
                    <li>Currency: {UserStore.showData.currency}</li>
                    <li>Referred By: {UserStore.showData.referrerOption?.text}</li>
                    <li>Free Trivia Games: {UserStore.showData.freeGamesAvailableCount}</li>
                    <li>Free Vocab Decks: {UserStore.showData.freeVocabDecksAvailableCount}</li>
                    <li>Free Gender Decks: {UserStore.showData.freeGenderDecksAvailableCount}</li>
                    <li>Free Plural Decks: {UserStore.showData.freePluralDecksAvailableCount}</li>
                    <li>Free Conjugation Decks: {UserStore.showData.freeConjugationDecksAvailableCount}</li>
                    <li>Free Number Decks: {UserStore.showData.freeNumberDecksAvailableCount}</li>
                  </ul>
              }
            </fieldset>
            <fieldset>
              <legend>Vocab Filters</legend>
              <div>
                {
                  this.filterSettings && this.userTargetLanguage('DE') && this.filterSettings.map((settings, index) => {
                    return (
                      <div>
                        <legend>{Constants.LANGUAGE_OPTIONS[Constants.LANGUAGE_ABBREVIATIONS[index]]}</legend>
                        <FilterTags
                          key={`filters-${index}`}
                          width="350px"
                          readOnly
                          filterSettings={settings}
                        />
                      </div>
                    )
                  })
                }
              </div>
            </fieldset>
            <fieldset>
              <legend>Roles</legend>
              <div>
                <InPlaceCheckbox
                  model="users"
                  field="admin"
                  id={UserStore.showData.id}
                  value={UserStore.showData.admin}
                  icon="check"
                  afterChange={UserStore.reloadShow.bind(UserStore)}
                >
                  Admin
                </InPlaceCheckbox>
              </div>

              <div>
                <InPlaceCheckbox
                  model="users"
                  field="team_member"
                  id={UserStore.showData.id}
                  value={UserStore.showData.teamMember}
                  icon="check"
                  afterChange={UserStore.reloadShow.bind(UserStore)}
                >
                  Team Member
                </InPlaceCheckbox>
                </div>
                <div>
                  <InPlaceCheckbox
                    model="users"
                    field="translator"
                    id={UserStore.showData.id}
                    value={UserStore.showData.translator}
                    icon="check"
                    afterChange={UserStore.reloadShow.bind(UserStore)}
                  >
                    Translator
                  </InPlaceCheckbox>
                </div>
              <div>
                <InPlaceCheckbox
                  model="users"
                  field="moderator"
                  id={UserStore.showData.id}
                  value={UserStore.showData.moderator}
                  icon="check"
                  afterChange={UserStore.reloadShow.bind(UserStore)}
                >
                  Moderator
                </InPlaceCheckbox>
              </div>
              <div>
                <InPlaceCheckbox
                  model="users"
                  field="video_creator"
                  id={UserStore.showData.id}
                  value={UserStore.showData.videoCreator}
                  icon="check"
                  afterChange={UserStore.reloadShow.bind(UserStore)}
                >
                  Video Creator
                </InPlaceCheckbox>
              </div>
              <div>
                <InPlaceCheckbox
                  model="users"
                  field="developer"
                  id={UserStore.showData.id}
                  value={UserStore.showData.developer}
                  icon="check"
                  afterChange={UserStore.reloadShow.bind(UserStore)}
                >
                  Developer
                </InPlaceCheckbox>
              </div>
            </fieldset>
            {
              AppUI.user.userDataAccessLevel &&
                <fieldset>
                  <legend>Rights</legend>
                  <div>
                    <div>User Data Access Level</div>
                    <InPlaceSelect
                      model="users"
                      id={UserStore.showData.id}
                      field="user_data_access_level"
                      options={Permissions.userDataAccessLevelForSelect}
                      value={UserStore.showData.userDataAccessLevel}
                      afterChange={UserStore.reloadShow.bind(UserStore)}
                    />
                  </div>
                  <div>
                    <InPlaceCheckbox
                      model="users"
                      field="can_update_records"
                      id={UserStore.showData.id}
                      value={UserStore.showData.canUpdateRecords}
                      icon="check"
                      afterChange={UserStore.reloadShow.bind(UserStore)}
                    >
                      Can Update Records
                    </InPlaceCheckbox>
                  </div>
                  <div>
                    <InPlaceCheckbox
                      model="users"
                      field="can_delete_records"
                      id={UserStore.showData.id}
                      value={UserStore.showData.canDeleteRecords}
                      icon="check"
                      afterChange={UserStore.reloadShow.bind(UserStore)}
                    >
                      Can Delete Records
                    </InPlaceCheckbox>
                  </div>
                </fieldset>
            }
            {
              AppUI.permissions.canAccessBasicUserData &&
                <fieldset>
                  <legend>Settings</legend>
                  <div>
                    <InPlaceCheckbox
                      model="users"
                      field="block_all_mailings"
                      id={UserStore.showData.id}
                      value={UserStore.showData.blockAllMailings}
                      icon="check"
                      afterChange={UserStore.reloadShow.bind(UserStore)}
                    >
                      Block Email and Mobile Notifications
                    </InPlaceCheckbox>
                  </div>
                  <div>
                    <InPlaceCheckbox
                      model="users"
                      field="hide_on_leaderboard"
                      id={UserStore.showData.id}
                      value={UserStore.showData.hideOnLeaderboard}
                      icon="check"
                      afterChange={UserStore.reloadShow.bind(UserStore)}
                    >
                      Hide Leaderboard
                    </InPlaceCheckbox>
                  </div>
                  <div>
                    <InPlaceCheckbox
                      model="users"
                      field="banned"
                      id={UserStore.showData.id}
                      value={UserStore.showData.banned}
                      icon="check"
                      afterChange={UserStore.reloadShow.bind(UserStore)}
                    >
                      Banned
                    </InPlaceCheckbox>
                  </div>
                  <div>
                    <InPlaceCheckbox
                      model="users"
                      field="reject_card_submissions"
                      id={UserStore.showData.id}
                      value={UserStore.showData.rejectCardSubmissions}
                      icon="check"
                      afterChange={UserStore.reloadShow.bind(UserStore)}
                    >
                      Reject Card Submissions
                    </InPlaceCheckbox>
                  </div>
                  <div>
                    <InPlaceCheckbox
                      model="users"
                      field="auto_hide_comments"
                      id={UserStore.showData.id}
                      value={UserStore.showData.autoHideComments}
                      icon="check"
                      afterChange={UserStore.reloadShow.bind(UserStore)}
                    >
                      Auto-Hide Comments
                    </InPlaceCheckbox>
                  </div>
                  <div>
                    <InPlaceCheckbox
                      model="users"
                      field="simplified_trivia_decks"
                      id={UserStore.showData.id}
                      value={UserStore.showData.simplifiedTriviaDecks}
                      icon="check"
                      afterChange={UserStore.reloadShow.bind(UserStore)}
                    >
                      Simplified Trivia Decks
                    </InPlaceCheckbox>
                  </div>
                  <div>
                    <InPlaceCheckbox
                      model="users"
                      field="show_beta_features"
                      id={UserStore.showData.id}
                      value={UserStore.showData.showBetaFeatures}
                      icon="check"
                      afterChange={UserStore.reloadShow.bind(UserStore)}
                    >
                      Has Beta Access
                    </InPlaceCheckbox>
                  </div>
                </fieldset>
            }
            {
              UserStore.hasShowData &&
                <fieldset>
                  <legend>Signed Up with Promotion</legend>
                  {
                    UserStore.showData.signedUpWithPromotion &&
                      <Link
                        to={{name: 'builder.promotions.edit', params: {promotionId: UserStore.showData.signedUpWithPromotion.id}}}
                      >
                        {UserStore.showData.signedUpWithPromotion.name}
                      </Link>
                  }
                  {
                    !UserStore.showData.signedUpWithPromotion &&
                      <div>None</div>
                  }
                </fieldset>
            }
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>XP</legend>
              <DailyActivityWrapper>
                {
                  UserStore.hasShowData &&
                    <DailyActivity
                      store={this.dailyXpCountStore}
                      dailyXpGoal={UserStore.showData.dailyXpGoal}
                      user={UserStore.showData}
                      userId={this.props.params.userId}
                      todaysDate={UserStore.showData.todaysDate}
                    />
                }
                </DailyActivityWrapper>
                <div style={{display: 'flex', alignItems: 'center', margin: "10px 0 0 0"}}>
                  <InPlaceDatePicker
                    value={this.addXpDate}
                    onChange={(date) => this.addXpDate = date.toString()}
                  />
                  <button
                    className="button-primary"
                    onClick={this.onAddXp}
                  >
                    Add XP
                  </button>
                </div>
                <div style={{fontSize: 14, marginTop: 10, marginLeft: 5}}>
                  <div>Streak: {UserStore.showData.currentStreakCount}</div>
                  <div>Last Repaired By User: {UserStore.showData.lastStreakRepairedAt?.formattedDateWithYear ?? 'none'}</div>
                  <div>Last Extended By User: {UserStore.showData.lastStreakExtendedAt?.formattedDateWithYear ?? 'never'}</div>
                  <InPlaceCheckbox
                    model="users"
                    field="enable_streak_extension"
                    id={UserStore.showData.id}
                    value={UserStore.showData.enableStreakExtension}
                    icon="check"
                    afterChange={UserStore.reloadShow.bind(UserStore)}
                  >
                    Enable Streak Extension
                  </InPlaceCheckbox>
                  {
                    isPresent(UserStore.showData.lastStreakRepairedAt?.formattedDateWithYear) &&
                      <button
                        className='button-primary'
                        onClick={() => this.clearLastRepairedAt()}
                      >
                        Clear Last Repaired At
                      </button>
                  }
                </div>
            </fieldset>
            {
              !UserStore.showData.admin &&
                <fieldset>
                  <legend>User Actions</legend>
                  <DeleteButton
                    confirmationMessageFloatsRight
                    onConfirm={this.onDeleteUser}
                    message="Delete this user?"
                    className="fa-block right"
                  >
                    <OutlineButton
                      selectedBackground={Theme.red}
                    >
                      Delete User
                    </OutlineButton>
                  </DeleteButton>
                </fieldset>
            }
            <fieldset>
              <legend>Troubleshooting</legend>
              {
                UserStore.hasShowData &&
                  <ul className='bulleted'>
                    <li>Web Active: {UserStore.showData.lastWebActiveAt && UserStore.showData.lastWebActiveAt.formattedDateWithYearAndTime}</li>
                    <li>Web Version: {UserStore.showData.lastWebVersionDownloaded}</li>
                    <li>Mobile Active: {UserStore.showData.lastMobileActiveAt && UserStore.showData.lastMobileActiveAt.formattedDateWithYearAndTime}</li>
                    <li>DE iOS Active: {UserStore.showData.lastIosActiveAt && UserStore.showData.lastIosActiveAt.formattedDateWithYearAndTime}</li>
                    <li>DE iOS Version: {UserStore.showData.lastIosVersionDownloaded}</li>
                    <li>ES iOS Active: {UserStore.showData.lastEsIosActiveAt && UserStore.showData.lastEsIosActiveAt.formattedDateWithYearAndTime}</li>
                    <li>ES iOS Version: {UserStore.showData.lastEsIosVersionDownloaded}</li>
                    <li>FR iOS Active: {UserStore.showData.lastFrIosActiveAt && UserStore.showData.lastFrIosActiveAt.formattedDateWithYearAndTime}</li>
                    <li>FR iOS Version: {UserStore.showData.lastFrIosVersionDownloaded}</li>
                    <li>DE Android Active: {UserStore.showData.lastAndroidActiveAt && UserStore.showData.lastAndroidActiveAt.formattedDateWithYearAndTime}</li>
                    <li>DE Android Version: {UserStore.showData.lastAndroidVersionDownloaded}</li>
                    <li>ES Android Active: {UserStore.showData.lastEsAndroidActiveAt && UserStore.showData.lastEsAndroidActiveAt.formattedDateWithYearAndTime}</li>
                    <li>ES Android Version: {UserStore.showData.lastEsAndroidVersionDownloaded}</li>
                    <li>FR Android Active: {UserStore.showData.lastFrAndroidActiveAt && UserStore.showData.lastFrAndroidActiveAt.formattedDateWithYearAndTime}</li>
                    <li>FR Android Version: {UserStore.showData.lastFrAndroidVersionDownloaded}</li>
                  </ul>
              }
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Level</legend>
              <InPlaceSelect
                includeBlank
                id={UserStore.showData.id}
                value={UserStore.showData.levelId}
                field="level_id"
                model="user"
                options={LevelStore.indexData.map(item => [item.id, item.nameWithAbbreviation])}
                afterChange={UserStore.reloadShow.bind(UserStore)}
              />
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Time Zone</legend>
              <InPlaceSelect
                includeBlank
                id={UserStore.showData.id}
                value={UserStore.showData.timeZone}
                field="time_zone"
                model="user"
                options={TimeZones}
                afterChange={UserStore.reloadShow.bind(UserStore)}
              />
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Language</legend>
              <InPlaceSelect
                includeBlank
                id={UserStore.showData.id}
                value={UserStore.showData.languageId}
                field="language_id"
                model="user"
                options={AppUI.languageOptions}
                afterChange={UserStore.reloadShow.bind(UserStore)}
              />
              <button
                className='button-primary'
                style={{marginTop: 10}}
                onClick={this.updateTranslations}
              >
                Update Sources
              </button>
              {
                this.translationsUpdated &&
                  <Alert>Updated!</Alert>
              }
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>English Preference</legend>
              <InPlaceSelect
                id={UserStore.showData.id}
                value={UserStore.showData.englishPreference}
                field="english_preference"
                model="user"
                options={[['us', 'American English'], ['uk', 'British English']]}
                afterChange={UserStore.reloadShow.bind(UserStore)}
              />
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Community Badge</legend>
              <InPlaceText
                defaultValue={UserStore.showData.communityBadge}
                model="users"
                field="community_badge"
                id={UserStore.showData.id}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Image</legend>
              <SettingsUserImage
                onChange={() => UserStore.reloadShow()}
                user={UserStore.showData}
              />
            </fieldset>
          </div>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Change Password</legend>
              {
                this.respSuccess &&
                  <div className='alert'>
                    Password was changed.
                  </div>
              }
              {
                this.respFailure &&
                  <div className='alert'>
                    There was a problem changing the password.
                  </div>
              }
              {
                !this.showPasswordChangeConfirmation &&
                  <div>
                    <input
                      ref="password"
                      placeholder="Enter Password"
                    />
                    <button
                      style={{marginTop: 10}}
                      className='button-primary'
                      onClick={() => this.changePasswordButtonClicked()}
                    >
                      Change Password
                    </button>
                  </div>
              }
              {
                this.showPasswordChangeConfirmation &&
                  <div>
                    <Alert>
                      Are you sure you want to change this password on production?
                    </Alert>
                    <Row>
                      <button
                        className='button-primary'
                        onClick={() => this.onChangePassword()}
                      >
                        Yes
                      </button>
                      <button
                        className='button-primary'
                        onClick={() => this.showPasswordChangeConfirmation = false}
                        style={{marginLeft: 5}}
                      >
                        No
                      </button>
                    </Row>
                  </div>
              }
            </fieldset>
          </div>
        </div>
        {
          UserStore.showData.admin &&
            <div className='row'>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>Team Page Title</legend>
                  <InPlaceText
                    defaultValue={UserStore.showData.teamPageTitle}
                    model="users"
                    field="team_page_title"
                    id={UserStore.showData.id}
                  />
                </fieldset>
              </div>
            </div>
        }
        {
          UserStore.showData.admin &&
            <div className='row'>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>Team Page Bio</legend>
                  <InPlaceText
                    richText
                    inputType="textarea"
                    defaultValue={UserStore.showData.teamPageBio}
                    model="users"
                    field="team_page_bio"
                    id={UserStore.showData.id}
                  />
                </fieldset>
              </div>
            </div>
        }
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Subscriptions</legend>
              <SubscriptionIndex
                userId={this.props.params.userId}
              />
              {
                isPresent(UserStore.showData.stripeCustomerId) &&
                  <a
                    href={`https://dashboard.stripe.com/customers/${UserStore.showData.stripeCustomerId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className='outline-button blue underline'
                    style={{margin: '10px 0 0 0'}}
                  >
                    Stripe Profile
                  </a>
              }
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Contact Messages</legend>
              <ContactMessageIndex
                userId={this.props.params.userId}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Error Logs</legend>
              <ErrorLogIndex
                userId={this.props.params.userId}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Review Decks</legend>
              <ReviewDeckIndex
                userId={this.props.params.userId}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>User Decks</legend>
              <UserDeckIndex
                userId={this.props.params.userId}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Campaign Messages</legend>
              <CampaignMessageIndex
                userId={this.props.params.userId}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Notifications</legend>
              <NotificationIndex
                userId={this.props.params.userId}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Sentence Submissions</legend>
              <SentenceSubmissionIndex
                userId={this.props.params.userId}
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default UserEdit;
