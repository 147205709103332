import React from 'react';
import { observer } from 'mobx-react';
import ExerciseInputTable from 'pages/creator/exercises/exercise_input_table';

@observer
class ExerciseMatchText extends React.Component {

  render() {
    return (
      <div className='exercise-match-items'>
        <ExerciseInputTable
          hideBreakdown
          {...this.props}
        />
      </div>
    );
  }
}

export default ExerciseMatchText;
