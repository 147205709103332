import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { LightbulbOnIcon, LightbulbOnSolidIcon } from '@seedlang/icons';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  .lightbulb-on-icon {
    svg {
      fill: #000;
    }
  }
  .lightbulb-on-solid-icon {
    svg {
      fill: ${Theme.red};
    }
  }
  svg {
    width: ${(props) => props.width};
    margin: ${(props) => props.margin};
  }
`;

@observer
class LightbulbIcon extends React.Component {

  render() {
    return (
      <Wrapper
        width={this.props.width}
        margin={this.props.margin}
      >
        {
          !this.props.on &&
            <LightbulbOnIcon />
        }
        {
          this.props.on &&
            <LightbulbOnSolidIcon />
        }
      </Wrapper>
    )
  }
}

export default ErrorBoundary(LightbulbIcon);
