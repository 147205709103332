import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Module from 'components/module';
import GamesPlayed from 'components/trivia/games_played';
import TriviaRecord from 'components/trivia/trivia_record';
import { AppUI } from '@seedlang/state';
import Leaderboard from 'components/leaderboard/leaderboard';
import FollowingIndex from 'components/following//following_index';
import BadgeCollection from 'components/badge_collection';

const Wrapper = styled.div`
  width: 100%;
`;


@observer
class TriviaProgress extends React.Component {

  render() {
    return (
      <Wrapper>
        <Module
          title="Record"
        >
          <TriviaRecord
            won={AppUI.user.triviaGamesWon}
            lost={AppUI.user.triviaGamesLost}
            tied={AppUI.user.triviaGamesTied}
          />
        </Module>
        <Module
          title="Games Played"
        >
          <GamesPlayed />
        </Module>
        {
          AppUI.userIsLoaded &&
            <Module
              title="My Badges"
              padding="0 20px 10px 20px"
            >
              <BadgeCollection
                user={AppUI.user}
                badges={["brainiac", "triviamaster"]}
                showProgress
                width="70px"
                badgeWidth="100%"
              />
            </Module>
        }
        <Module
          title="Trivia Leaderboard"
        >
          <Leaderboard
            includeUpdatedAt
            lastWeekColumn="trivia_points_last_week"
            lastMonthColumn="trivia_points_last_month"
            totalColumn="trivia_points"
            rankingFields={{"trivia_points": "triviaPointsRankingTotal", "trivia_points_last_week": "triviaPointsRankingLastWeek", "trivia_points_last_month": "triviaPointsRankingLastMonth"}}
          />
        </Module>
        <Module
          title="Friends"
        >
          <FollowingIndex
            iconColor="#CECECE"
            closeIconMargin="0 -5px 0 0"
          />
        </Module>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TriviaProgress);
