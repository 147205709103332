import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import DeckSettings from 'components/deck/deck_settings';
import Text from 'components/text';

const Wrapper = styled.div`
`;

@observer
class SettingsDeck extends React.Component {

  render() {
    return (
      <Wrapper>
        <Text
          heading="3"
          margin="0 0 20px 0"
        >
          Deck Settings
        </Text>
        <DeckSettings
          hideTitle
          padding="0px"
          background="#FFF"
          color="#000"
          titleColor="#333"
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SettingsDeck);
