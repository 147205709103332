import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Text from 'components/text';

const Wrapper = styled.div`
  margin-bottom: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
`;

const Column = styled.div`
  width: ${props => props.width};
  flex: ${props => props.flex};
`;

const TextWrapper = styled.div`
  background: #CCC;
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  height: 35px;
`;

@observer
class ReviewIndexAttribute extends React.Component {

  render () {
    return (
      <Wrapper>
        {
          this.props.view === 'card' &&
            <Column
              width="140px"
            >
              <TextWrapper>
                <Text
                  fontSize="10px"
                  color="#333"
                  bold
                >
                  {this.props.label}
                </Text>
              </TextWrapper>
            </Column>
        }
        <Column
          flex="1"
        >
          {
            this.props.value &&
              <Text>
                {this.props.value}
              </Text>
          }
          {this.props.children}
        </Column>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ReviewIndexAttribute);
