import React from 'react';
import { observer } from 'mobx-react';
import { autorun, computed, observable, toJS } from 'mobx';
import { AppUI } from '@seedlang/state';
import { CampaignTypeStore } from '@seedlang/stores';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import ErrorBoundary from 'components/hoc/error_boundary';
import autobind from 'autobind-decorator';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import Button from 'components/button/button';
import Text from 'components/text';
import Alert from 'components/alert';
import { isPresent } from '@seedlang/utils';
import EmailConfirmation from "components/email_confirmation";
import Spinner from "components/spinner";
import { every } from "lodash";

const Wrapper = styled.div`
  label {
    font-weight: 300;
    font-size: 16px;
  }
  input[type="checkbox"] {
    width: 20px;
  }
  p {
    margin: 0 0 10px 0;
    font-size: 16px;
  }
`;

const Buttons = styled.div`

`;

const EmailConfirmationWrapper = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

@observer
class SettingsUserNotifications extends React.Component {
  @observable pristineBlockMailingByType = [];
  @observable pristineBlockAllMailings = false;
  @observable blockMailingByType = [];
  @observable blockAllMailings = false;
  @observable showMessage = false;
  @observable userIsLoaded = false;
  @observable mailingTypeHasBeenToggled = false;

  constructor(props) {
    super(props);
    this.disposer = autorun(() => {
      if (AppUI.userIsLoaded && !this.userIsLoaded) {
        this.userIsLoaded = true;
        this.pristineBlockMailingByType = isPresent(AppUI.user.blockMailingByType) ? AppUI.user.blockMailingByType.split(",") : [];
        this.pristineBlockAllMailings = AppUI.user.blockAllMailings;
        this.blockMailingByType = isPresent(AppUI.user.blockMailingByType) ? AppUI.user.blockMailingByType.split(",") : [];
        this.blockAllMailings = AppUI.user.blockAllMailings;
      }
    });
    CampaignTypeStore.getIndex({filters: {unsubscribable: true}});
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
    this.mailingTypeHasBeenToggled = false;
  }

  @computed get pristineBlockMailingByTypeString() {
    return this.pristineBlockMailingByType && toJS(this.pristineBlockMailingByType).sort().join(",");
  }

  @computed get blockMailingByTypeString() {
    return this.blockMailingByType && toJS(this.blockMailingByType).sort().join(",");
  }

  @computed get dirtyForm() {
    return this.pristineBlockMailingByTypeString !== this.blockMailingByTypeString;
  }

  @autobind toggleBlockMailingByType(slug) {
    this.mailingTypeHasBeenToggled = true;
    if (this.blockMailingByType.indexOf(slug) === -1) {
      this.blockMailingByType.push(slug);
    } else {
      this.blockMailingByType = this.blockMailingByType.filter(item => item !== slug);
    }
  }

  @computed get allMailingTypesBlocked() {
    return CampaignTypeStore.hasIndexData && every(CampaignTypeStore.indexData.filter(item => AppUI.site?.hasPodcast || item.slug !== 'podcast-episode-notification').map(item => this.blockMailingByType.indexOf(item.slug) !== -1));
  }

  @autobind onReset() {
    this.blockMailingByType = this.pristineBlockMailingByType;
  }

  @autobind onSubmit() {
    this.mailingTypeHasBeenToggled = false;
    if (this.allMailingTypesBlocked) {
      this.blockAllMailings = true;
    } else {
      this.blockAllMailings = false;
    }
    if (AppUI.userIsSignedIn) {
      if (AppUI.siteIsDefault) {
        AppUI.authUserStore.update({data: {block_mailing_by_type: this.blockMailingByTypeString, block_all_mailings: this.blockAllMailings}})
      } else {
        if (this.blockMailingByTypeString !== this.pristineBlockMailingByTypeString) {
          AppUI.authUserStore.update({data: {block_mailing_by_type: this.blockMailingByTypeString}})
        }
        if (this.blockAllMailings !== this.pristineBlockAllMailings && isPresent(AppUI.user.currentUserGroup)) {
          AppUI.userGroupStore.update({
            ids: {userGroupId: AppUI.user.currentUserGroup.id},
            data: {block_all_mailings: this.blockAllMailings}
          }, this.afterUserUpdate);
        }
      }
    } else {
      const notificationToken = window.location.search.split("=")[1];
      if (notificationToken) {
        AppUI.userStore.getIndex({filters: {notification_token: notificationToken}}, this.afterGetUsers);
      }
    }
    this.pristineBlockMailingByType = this.blockMailingByType.map(item => item);
    this.pristineBlockAllMailings = this.blockAllMailings;
  }

  @autobind afterGetUsers(resp) {
    const userId = resp[0]["id"];
    AppUI.userStore.update({ids: {userId: userId}, data: {block_mailing_by_type: this.blockMailingByTypeString, block_all_mailings: this.blockAllMailings}}, this.afterUserUpdate);
  }

  @autobind afterUserUpdate() {
    this.showMessage = true;
  }

  @autobind onClickEmailConfirmation() {
    AppUI.userGroupStore.update({ids: {userGroupId: AppUI.user.currentUserGroup?.id}, data: {user_agreed_to_email: 'true'}}, AppUI.loadUser)
  }

  @computed get showEmailConfirmation() {
    return !AppUI.siteIsDefault && AppUI.user?.currentUserGroup?.userAgreedToEmail !== 'true';
  }

  render() {
    return (
      <Wrapper>
        {
          this.showMessage &&
            <Alert
              textAlign="center"
              width="100%"
            >
              Your email notification settings have been saved.
            </Alert>
        }
        <Text
          heading="3"
          margin="0 0 20px 0"
        >
          Email Notifications
        </Text>
        {
          this.showEmailConfirmation &&
            <EmailConfirmationWrapper>
              <Text>
                Email notifications are currently deactivated. Allow us to send you email notifications whenever
                there’s a new post or important information about your membership by clicking the checkbox below.
              </Text>
              <EmailConfirmation
                onClick={this.onClickEmailConfirmation}
                value={AppUI.user?.currentUserGroup?.userAgreedToEmail}
              />
            </EmailConfirmationWrapper>
        }
        <Text color={this.showEmailConfirmation ? '#CCC' : null} margin="0 0 10px 0">
          I would like to receive emails for...
        </Text>
        {
          !CampaignTypeStore.hasIndexData && CampaignTypeStore.requestCounter > 0 &&
          <Spinner
            background={AppUI.site.accentColor}
          />
        }
        {
          CampaignTypeStore.indexData.map(item => {
            if (!AppUI.site?.hasPodcast && item.slug === 'podcast-episode-notification') return ;
            return (
              <div
                key={item.id}
              >
                <InPlaceCheckbox
                  allowUpdate
                  icon="check"
                  display="flex"
                  id={item.id}
                  key={item.id}
                  onChange={() => this.toggleBlockMailingByType(item.slug)}
                  value={!this.blockAllMailings && this.blockMailingByType && this.blockMailingByType.indexOf(item.slug) === -1}
                  disabled={this.showEmailConfirmation}
                  iconStyle={{color: this.showEmailConfirmation ? '#CCC' : null}}
                >
                  {item.name}
                </InPlaceCheckbox>
              </div>
            )
          })
        }
        <Buttons>
          <Button
            disabled={!this.mailingTypeHasBeenToggled}
            background={Theme.green}
            onClick={this.onSubmit}
            margin="10px 2px 0 2px"
          >
            <i className="fa fa-check" />
            Submit
          </Button>
          {
            !this.props.hideReset &&
              <Button
                background={Theme.red}
                disabled={!this.mailingTypeHasBeenToggled}
                onClick={this.onReset}
                margin="10px 2px 0 2px"
              >
                <i className="fa fa-times" />
                Reset
              </Button>
          }
        </Buttons>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SettingsUserNotifications);
