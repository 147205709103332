import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';
import { noop } from 'lodash';
import { CloseIcon } from '@seedlang/icons';
import { isPresent } from '@seedlang/utils';
import Button from 'components/button/button';

const Wrapper = styled.div`
  background: #FFF;
  border-radius: 10px;
  padding: 20px;
  margin: ${props => props.margin};
  position: relative;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 0px;
  svg {
    fill: #333;
    width: 16px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  a {
    color: #FFF;
  }
`;

@observer
class BannerNoticeShow extends React.Component {
  @observable hide = false;

  constructor(props) {
    super(props);
    if (isPresent(this.props.bannerNotice.numberSessionsUntilExpiration) && this.props.bannerNotice.numberSessionsUntilExpiration > 0) {
      AppUI.userBannerNoticeStore.incrementSessionsSeen({data: {
        userId: AppUI.user.id,
        bannerNoticeId: this.props.bannerNotice.id,
      }}, noop);
    }
  }

  @autobind onHide() {
    this.hide = true;
    AppUI.userBannerNoticeStore.upsert({data: {
      userId: AppUI.user.id,
      bannerNoticeId: this.props.bannerNotice.id,
      dismissed: true}
    }, () => AppUI.loadUser())
  }

  render () {
    if (!this.hide) {
      return (
        <Wrapper
          margin={AppUI.layout.isMobile ? '10px 10px 0 10px' : '10px 0'}
        >
          <CloseIconWrapper
            onClick={this.onHide}
          >
            <CloseIcon />
          </CloseIconWrapper>
          <div dangerouslySetInnerHTML={{ __html: this.props.bannerNotice.text }} />
          {
            isPresent(this.props.bannerNotice?.buttonText) && isPresent(this.props.bannerNotice?.url) &&
              <ButtonRow>
                <Button
                  background={AppUI.site.accentColor}
                  scaleOnHover={1.01}
                  scaleOnActive={0.99}
                >
                  <a href={this.props.bannerNotice.url}>
                    {this.props.bannerNotice?.buttonText}
                  </a>
                </Button>
              </ButtonRow>
          }
        </Wrapper>
      );
    } else {
      return <div />
    }
  }
}

export default BannerNoticeShow;
