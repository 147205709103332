import React from 'react';
import { observer } from 'mobx-react';
import FloatingActionButton from "components/button/floating_action_button";

@observer
class BackToTopButton extends React.Component {

  render () {
    return (
      <FloatingActionButton
        {...this.props}
      >
        <i className='fa fa-angle-double-up' />
      </FloatingActionButton>
    );
  }
}

export default BackToTopButton;
