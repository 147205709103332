import React from 'react';
import { observer } from 'mobx-react';
import { GroupStore, RouteStore } from '@seedlang/stores';
import { AppUI } from '@seedlang/state';

@observer
class GroupCreate extends React.Component {

  onClick() {
    GroupStore.create(
      {
        data: {
          name: this.refs.name.value,
          siteId: AppUI.siteId,
        },
      },
      this.afterCreate
    );
    this.refs.name.value = '';
  }

  afterCreate(resp) {
    RouteStore.routeToNamed('builder.groups.edit', {groupId: resp.id})
  }

  render() {
    return (
      <div className="concept-category-create row">
        <div className="col-xs-10">
          <input
            placeholder="Enter a name"
            ref="name"
          />
        </div>
        <div className="col-xs-2">
          <button
            className="button-primary"
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default GroupCreate;
