import React from 'react';
import { observer } from 'mobx-react';
import { DeckUI } from '@seedlang/state';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  color: white;
  background: #545454;
  height: 100%;
  margin: 0;
  padding: 20px;
  h3 {
    margin-top: 10px;
  }
  p, ol {
    text-align: left;
    font-size: 16px;
    line-height: 22px;
  }
`;

@observer
class MediaBlocked extends React.Component {

  render() {
    return (
      <Wrapper>
        <h3>Recording is blocked</h3>
        <p>
          <b>Seedlang is functional without the ability to record audio or video, but it is not the ideal experience.</b>
        </p>
        {
          DeckUI.deviceDeniedAccess &&
            <div>
              <p>
                Your browser is not allowing the recording of audio or video.  This may have been an intentional setting since browsers do allow you to block access to your microphone.
              </p>
              <p>
                If it was not intentional, there are a few things you can try to fix this.
              </p>
              <ol>
                <li>Make sure your browser is not blocking your ability to record.  There would be an icon in your address bar indicating that recording is blocked.</li>
                <li>Try restarting your browser.  Make sure to close it completely.</li>
                <li>Try restarting your device. </li>
              </ol>
            </div>
        }
        {
          !DeckUI.layout.ios && !DeckUI.layout.mediaRecorderSupported &&
            <p>
              Your browser does not support recording of audio or video.  Please use either the Chrome or Firefox browser.
            </p>
        }
        {
          DeckUI.layout.ios &&
            <p>
              We are sorry, but iOS does not currently support recording of audio or video inside of a browser.  If you want to try recording audio or video on Seedlang, please use another device or computer.
            </p>
        }
      </Wrapper>
    );
  }
}

export default MediaBlocked;
