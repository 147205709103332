import React from 'react';
import { observer } from 'mobx-react';
import { RatingTypeStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';

@observer
class RatingTypeCreate extends React.Component {

  @autobind onClick() {
    RatingTypeStore.create(
      {
        data: {
          name: this.refs.name.value,
        },
      },
    );
    this.refs.name.value = '';
  }

  render() {
    return (
      <div className="concept-category-create row">
        <div className="col-xs-10">
          <input
            placeholder="Add a name"
            ref="name"
          />
        </div>
        <div className="col-xs-2">
          <button
            className="button-primary"
            onClick={this.onClick}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default RatingTypeCreate;
