import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import PieChartWithLegend from 'components/visualization/pie_chart_with_legend';
import { Theme } from '@seedlang/constants';
import { formatNumber } from '@seedlang/utils';
import { AppUI } from '@seedlang/state';
import Module from 'components/module';
import ForumShow from 'components/forum/forum_show';
import BadgeCollection from 'components/badge_collection';

const Wrapper = styled.div`
  display: flex;
  padding: 20px 0 0 0;
`;

const Visualizations = styled.div`
  width: 260px;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  padding-right: 10px;
`;

@observer
class TranslatorHome extends React.Component {
  @observable showAllDecks = true;
  @observable showAllSentences = true;
  @observable showAllWords = true;

  render () {
    return (
      <Wrapper>
        <Content>
          {
            AppUI.languageStore.hasShowData &&
              <ForumShow
                buttonMargin="0 10px 0 0"
                topBarBackground="#FFF"
                topBarTitle="Translator Discussions"
                loadForum
                hideBreadcrumbs
                forumId={AppUI.languageStore.showData.forumId}
                languageId={this.props.params.languageId}
              />
          }
        </Content>
        <Visualizations>
          {
            AppUI.languageStore.hasShowData &&
              <div>
                <Module
                  title="Your Progress"
                  padding="10px 20px"
                  slug="translator-home-decks"
                >
                  <BadgeCollection
                    user={AppUI.user}
                    badges={["translationmaster"]}
                    showProgress
                    fontSize="14px"
                    width="70px"
                    badgeWidth="100%"
                  />
                </Module>
                <Module
                  title="Decks"
                  padding="10px 0 10px 0"
                  slug="translator-home-decks"
                >
                  <PieChartWithLegend
                    id="translated-decks"
                    colors={[Theme.green, "#D8D8D8"]}
                    data={[
                      {y: AppUI.languageStore.showData.deDecksTranslatedCount},
                      {y: AppUI.languageStore.showData.deDecksTotalCount - AppUI.languageStore.showData.deDecksTranslatedCount},
                    ]}
                    width={160}
                    formatNumber
                    fontSize="28px"
                    margin="0 0 20px 0"
                    count={formatNumber(AppUI.languageStore.showData.deDecksTranslatedCount)}
                    tooltipText={`${formatNumber(AppUI.languageStore.showData.deDecksTranslatedCount)} translated of ${formatNumber(AppUI.languageStore.showData.deDecksTotalCount)}`}
                    legends={[[Theme.green, 'Translated'], ["#D8D8D8", 'Untranslated']]}
                  />
                </Module>
                <Module
                  title="Sentences"
                  padding="10px 0 10px 0"
                  slug="translator-home-sentences"
                >
                  <PieChartWithLegend
                    id="translated-sentences"
                    colors={[Theme.green, "#D8D8D8"]}
                    data={[
                      {y: AppUI.languageStore.showData.deSentencesTranslatedCount},
                      {y: AppUI.languageStore.showData.deSentencesTotalCount - AppUI.languageStore.showData.deSentencesTranslatedCount},
                    ]}
                    width={160}
                    fontSize="28px"
                    margin="0 0 20px 0"
                    count={formatNumber(AppUI.languageStore.showData.deSentencesTranslatedCount)}
                    tooltipText={`${formatNumber(AppUI.languageStore.showData.deSentencesTranslatedCount)} translated of ${formatNumber(AppUI.languageStore.showData.deSentencesTotalCount)}`}
                    legends={[[Theme.green, 'Translated'], ["#D8D8D8", 'Untranslated']]}
                  />
                </Module>
                <Module
                  title="Words"
                  padding="10px 0 10px 0"
                  slug="translator-home-words"
                >
                  <PieChartWithLegend
                    id="translated-words"
                    colors={[Theme.green, "#D8D8D8"]}
                    data={[
                      {y: AppUI.languageStore.showData.deWordsTranslatedCount},
                      {y: AppUI.languageStore.showData.deWordsTotalCount - AppUI.languageStore.showData.deWordsTranslatedCount},
                    ]}
                    fontSize="28px"
                    width={160}
                    margin="0 0 20px 0"
                    count={formatNumber(AppUI.languageStore.showData.deWordsTranslatedCount)}
                    tooltipText={`${formatNumber(AppUI.languageStore.showData.deWordsTranslatedCount)} translated of ${formatNumber(AppUI.languageStore.showData.deWordsTotalCount)}`}
                    legends={[[Theme.green, 'Translated'], ["#D8D8D8", 'Untranslated']]}
                  />
                </Module>
              </div>
          }
        </Visualizations>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TranslatorHome);
