import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

const Wrapper = styled.div`

`;

@observer
class ExerciseInformation extends React.Component {

  render () {
    return (
      <Wrapper>

      </Wrapper>
    );
  }
}

export default ExerciseInformation;
