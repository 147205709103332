import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { MembershipStore, MembershipTypeStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceSelect from 'components/form/in_place_select';
import autobind from 'autobind-decorator';
import { MembershipGroupStore } from '@seedlang/stores';
import Cookies from 'js-cookie';

@observer
class MembershipEdit extends React.Component {
  static defaultProps = {
    whiteLabeled: false
  }

  componentDidMount() {
    this.getMembershipTypes();
    this.getMembership();
    if (!MembershipGroupStore.hasIndexData) {
      MembershipGroupStore.getIndex();
    }
  }

  @autobind getMembershipTypes() {
    MembershipTypeStore.getIndex();
  }

  @autobind getMembership() {
    MembershipStore.getShow({ids: {membershipId: this.props.params.membershipId}});
  }

  @computed get filteredMembershipTypes() {
    const siteId = Cookies.get('site_id');

    return MembershipTypeStore.indexData.filter((item) => item.siteId === siteId);
  }

  @computed get filteredMembershipGroups() {
    const siteId = Cookies.get('site_id');

    return MembershipGroupStore.indexData.filter((item) => item.siteId === siteId);
  }

  render() {
    return (
      <div className='membership-edit'>
        <div className="row">
          <div className='col-xs-3'>
            <fieldset>
              <legend>Name (Internal)</legend>
              <InPlaceText
                defaultValue={MembershipStore.showDataField('name')}
                model="memberships"
                field="name"
                id={MembershipStore.showDataField('id')}
              />
            </fieldset>
            <fieldset>
              <legend>Title (External)</legend>
              <InPlaceText
                defaultValue={MembershipStore.showDataField('title')}
                model="memberships"
                field="title"
                id={MembershipStore.showDataField('id')}
              />
            </fieldset>
          </div>
          <div className="col-xs-3">
            <fieldset>
              <legend>Membership Group</legend>
              <InPlaceSelect
                includeBlank
                id={MembershipStore.showDataField('id')}
                model="memberships"
                field="membership_group_id"
                options={this.filteredMembershipGroups.map(item => [item.id, item.name])}
                value={MembershipStore.showDataField('membershipGroupId')}
                afterChange={this.getMembership}
              />
            </fieldset>
          </div>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Membership Type</legend>
              <InPlaceSelect
                includeBlank
                id={MembershipStore.showDataField('id')}
                model="memberships"
                field="membershipTypeId"
                options={this.filteredMembershipTypes.map(item => [item.id, item.name])}
                value={MembershipStore.showDataField('membershipTypeId')}
                afterChange={this.getMembership}
              />
            </fieldset>
          </div>
          <div className="col-xs-3">
            <fieldset>
              <legend># Months</legend>
              <InPlaceText
                defaultValue={MembershipStore.showDataField('numberMonths')}
                model="memberships"
                field="number_months"
                id={MembershipStore.showDataField('id')}
              />
            </fieldset>
          </div>
          <div className="col-xs-3">
            <fieldset>
              <legend>Discount</legend>
              <InPlaceText
                defaultValue={MembershipStore.showDataField('discount')}
                model="memberships"
                field="discount"
                id={MembershipStore.showDataField('id')}
              />
            </fieldset>
          </div>
          <div className="col-xs-3">
            <fieldset>
              <legend>Podcast Access</legend>
              <InPlaceCheckbox
                id={MembershipStore.showData.id}
                value={MembershipStore.showData.podcastAccess}
                model="memberships"
                field="podcast_access"
                icon="check"
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className="col-xs-3">
            <fieldset>
              <legend>Description (USD)</legend>
              <InPlaceText
                defaultValue={MembershipStore.showDataField('descriptionUsd')}
                model="memberships"
                field="description_usd"
                id={MembershipStore.showDataField('id')}
              />
            </fieldset>
          </div>
          <div className="col-xs-3">
            <fieldset>
              <legend>Description (EUR)</legend>
              <InPlaceText
                defaultValue={MembershipStore.showDataField('descriptionEur')}
                model="memberships"
                field="description_eur"
                id={MembershipStore.showDataField('id')}
              />
            </fieldset>
          </div>
          <div className="col-xs-3">
            <fieldset>
              <legend>Icon</legend>
              <InPlaceText
                defaultValue={MembershipStore.showDataField('icon')}
                model="memberships"
                field="icon"
                id={MembershipStore.showDataField('id')}
              />
            </fieldset>
          </div>
          <div className="col-xs-3">
            <fieldset>
              <legend>Settings</legend>
              <div>
                <InPlaceCheckbox
                  value={MembershipStore.showDataField('active')}
                  model="memberships"
                  field="active"
                  id={MembershipStore.showDataField('id')}
                  icon="check"
                >
                  Active
                </InPlaceCheckbox>
              </div>
              <div>
                <InPlaceCheckbox
                  value={MembershipStore.showDataField('default')}
                  model="memberships"
                  field="default"
                  id={MembershipStore.showDataField('id')}
                  icon="check"
                >
                  Default
                </InPlaceCheckbox>
              </div>
              <div>
                <InPlaceCheckbox
                  value={MembershipStore.showDataField('highlight')}
                  model="memberships"
                  field="highlight"
                  id={MembershipStore.showDataField('id')}
                  icon="check"
                >
                  Highlight
                </InPlaceCheckbox>
              </div>
              <div>
                <InPlaceCheckbox
                  value={MembershipStore.showDataField('recurring')}
                  model="memberships"
                  field="recurring"
                  id={MembershipStore.showDataField('id')}
                  icon="check"
                >
                  Recurring
                </InPlaceCheckbox>
              </div>
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className="col-xs-3">
            <fieldset>
              <legend>Cost (USD)</legend>
              <InPlaceText
                defaultValue={MembershipStore.showDataField('costUsd')}
                model="memberships"
                field="cost_usd"
                id={MembershipStore.showDataField('id')}
                afterChange={this.getMembership}
              />
            </fieldset>
          </div>
          <div className="col-xs-3">
            <fieldset>
              <legend>Cost (EUR)</legend>
              <InPlaceText
                defaultValue={MembershipStore.showDataField('costEur')}
                model="memberships"
                field="cost_eur"
                id={MembershipStore.showDataField('id')}
                afterChange={this.getMembership}
              />
            </fieldset>
          </div>
          <div className="col-xs-3">
            <fieldset>
              <legend>Monthly Cost (USD)</legend>
              <InPlaceText
                defaultValue={MembershipStore.showDataField('costMonthlyUsd')}
                model="memberships"
                field="cost_monthly_usd"
                id={MembershipStore.showDataField('id')}
              />
            </fieldset>
          </div>
          <div className="col-xs-3">
            <fieldset>
              <legend>Monthly Cost (EUR)</legend>
              <InPlaceText
                defaultValue={MembershipStore.showDataField('costMonthlyEur')}
                model="memberships"
                field="cost_monthly_eur"
                id={MembershipStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className="col-xs-3">
            <fieldset>
              <legend>Strikethrough (USD)</legend>
              <InPlaceText
                defaultValue={MembershipStore.showDataField('strikethroughUsd')}
                model="memberships"
                field="strikethrough_usd"
                id={MembershipStore.showDataField('id')}
              />
            </fieldset>
          </div>
          <div className="col-xs-3">
            <fieldset>
              <legend>Strikethrough (EUR)</legend>
              <InPlaceText
                defaultValue={MembershipStore.showDataField('strikethroughEur')}
                model="memberships"
                field="strikethrough_eur"
                id={MembershipStore.showDataField('id')}
              />
            </fieldset>
          </div>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Stripe Account</legend>
              <InPlaceSelect
                includeBlank
                id={MembershipStore.showDataField('id')}
                model="memberships"
                field="stripeAccount"
                options={[['DE', 'Seedlang Germany'], ['US', 'Seedlang USA'], ['EL', 'Easy Languages']]}
                value={MembershipStore.showDataField('stripeAccount')}
                afterChange={this.getMembership}
              />
            </fieldset>
          </div>
          <div className='col-xs-3'>
            <fieldset>
              <legend>PayPal Account</legend>
              <InPlaceSelect
                includeBlank
                id={MembershipStore.showDataField('id')}
                model="memberships"
                includeBlank
                field="paypalAccount"
                options={[['DE', 'Seedlang Germany'], ['US', 'Seedlang USA']]}
                value={MembershipStore.showDataField('paypalAccount')}
                afterChange={this.getMembership}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Test Stripe Ids (Recurring)</legend>
              <div className='row'>
                <div className='col-xs-3'>
                  USD
                </div>
                <div className='col-xs-9'>
                  <InPlaceText
                    defaultValue={MembershipStore.showDataField('stripeUsdTestPlanId')}
                    model="memberships"
                    field="stripe_usd_test_plan_id"
                    id={MembershipStore.showDataField('id')}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-3'>
                  EUR
                </div>
                <div className='col-xs-9'>
                  <InPlaceText
                    defaultValue={MembershipStore.showDataField('stripeEurTestPlanId')}
                    model="memberships"
                    field="stripe_eur_test_plan_id"
                    id={MembershipStore.showDataField('id')}
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Live Stripe Ids (Recurring)</legend>
              <div className='row'>
                <div className='col-xs-3'>
                  USD
                </div>
                <div className='col-xs-9'>
                  <InPlaceText
                    defaultValue={MembershipStore.showDataField('stripeUsdLivePlanId')}
                    model="memberships"
                    field="stripe_usd_live_plan_id"
                    id={MembershipStore.showDataField('id')}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-3'>
                  EUR
                </div>
                <div className='col-xs-9'>
                  <InPlaceText
                    defaultValue={MembershipStore.showDataField('stripeEurLivePlanId')}
                    model="memberships"
                    field="stripe_eur_live_plan_id"
                    id={MembershipStore.showDataField('id')}
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-6'>
            <fieldset>
              <legend>iOS ID</legend>
              <InPlaceText
                defaultValue={MembershipStore.showDataField('iosSubscriptionId')}
                model="memberships"
                field="ios_subscription_id"
                id={MembershipStore.showDataField('id')}
              />
            </fieldset>
          </div>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Android ID</legend>
              <InPlaceText
                defaultValue={MembershipStore.showDataField('androidSubscriptionId')}
                model="memberships"
                field="android_subscription_id"
                id={MembershipStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorBoundary(MembershipEdit);
