import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import { each } from 'lodash';
import { isBlank, isPresent } from '@seedlang/utils';
import PodcastEpisodeSearch from "components/podcast_episode_search";
import { Theme } from "@seedlang/constants";
import Copy from "components/copy";

const Wrapper = styled.div`
  h3 {
    width: 100%;
    margin-bottom: 20px!important;
  }
`;

const Table = styled.table`

`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  i {
    margin-right: 5px;
  }
`;

const Title = styled.span`
  margin-right: 5px;
`;

const Subtitle = styled.span`
  font-style: italic;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  input {
    border: 1px solid #AAA!important;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

@observer
class DownloadLinkEdit extends React.Component {
  @observable values = {};
  @observable showPodcastEpisodeSelect;

  constructor(props) {
    super(props);
    this.getLinks();
    each(this.props.worksheet.downloadLinks, (downloadLink) => {
      this.values[downloadLink.downloadLinkTypeId] = downloadLink.text;
    });
  }

  @autobind getLinks() {
    AppUI.downloadLinkTypeStore.getIndex();
  }

  @autobind updateValue(value, id) {
    this.values[id] = value;
  }

  @autobind onSubmit(id) {
    const data = {
      worksheetId: this.props.worksheet.id,
      downloadLinkTypeId: id,
      text: this.values[id]
    }
    AppUI.downloadLinkStore.upsert({data: data}, this.props.onChange);
  }

  @autobind addPodcastEpisode(podcastEpisode, id) {
    this.values[id] = podcastEpisode?.mp3?.url;
    this.showPodcastEpisodeSelect = null;
    this.onSubmit(id);
  }

  @autobind togglePodcastSearch(downloadLink) {
    this.showPodcastEpisodeSelect = downloadLink.id;
  }

  render () {
    return (
      <Wrapper>
        <center><h3>Edit Download Links for this Post</h3></center>
        <Table>
          <tbody>
          {
            AppUI.downloadLinkTypeStore.indexData.map(item => {
              return (
                <tr
                  key={item.id}
                >
                  <td>
                    <TitleWrapper>
                      <i className={`fa fa-${item.icon}`} />
                      <Title>{item.title}</Title>
                      <Subtitle>{item.subtitle}</Subtitle>
                    </TitleWrapper>
                    {
                      item.comment &&
                      <div style={{color: Theme.gray, marginTop: '5px'}}><i className='fa fa-info-circle' /> {item.comment}</div>
                    }
                    {
                      item.generatedPdfs &&
                      <div style={{color: Theme.gray, marginTop: '5px'}}><i className='fa fa-file-pdf-o' /> PDF worksheets are added automatically added here.</div>
                    }
                  </td>
                  <td style={{width: '50%'}}>
                    <InputWrapper>
                      {
                        (isBlank(item.podcast) || isPresent(this.values[item.id])) &&
                          <input
                            onChange={(el) => this.updateValue(el.target.value, item.id)}
                            onBlur={() => this.onSubmit(item.id)}
                            value={this.values[item.id]}
                          />
                      }
                      {
                        isPresent(this.values[item.id]) &&
                        <IconsWrapper>
                          <a href={this.values[item.id]} target='_blank'><i className="fa fa-external-link"/></a>
                          <Copy
                            text={this.values[item.id]}
                          />
                        </IconsWrapper>
                      }
                      {
                        isPresent(item.podcast) && isBlank(this.values[item.id]) &&
                          <PodcastEpisodeSearch
                            onSubmit={(podcastEpisode) => this.addPodcastEpisode(podcastEpisode,item.id)}
                            podcastId={item.podcast.id}
                          />
                      }
                    </InputWrapper>
                  </td>
                  <td>
                    {
                      isPresent(this.values[item.id]) && !/^(http|https):\/\//.test(this.values[item.id]) &&
                        <i className='fa fa-warning' style={{marginTop: '8px'}}/>
                    }
                  </td>
                </tr>
              )
            })
          }
          </tbody>
        </Table>
      </Wrapper>
    );
  }
}

export default DownloadLinkEdit;
