import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { DashboardStore } from '@seedlang/stores';
import DashboardCount from 'pages/builder/dashboard/dashboard_count';
import autobind from 'autobind-decorator';
import LineChartWithControls from 'components/visualization/line_chart_with_controls';

const Wrapper = styled.div`

`;

@observer
class DecksDashboard extends React.Component {
  @observable counts = {};

  componentDidMount() {
    DashboardStore.api({}, this.afterGetCounts, 'GET', 'decks');
  }

  @autobind afterGetCounts(resp) {
    this.counts = resp;
  }

  render() {
    return (
      <Wrapper>
        <fieldset>
          <legend>Decks</legend>
          <div className='row'>
            <div className="col-xs-3">
              <DashboardCount
                label="Users Started"
                count={this.counts.usersStartedDeckCount}
                linkTo={{name: 'builder.decks.index'}}
              />
            </div>
            <div className="col-xs-3">
              <DashboardCount
                label="Users Finished"
                count={this.counts.usersFinishedDeckCount}
                linkTo={{name: 'builder.decks.index'}}
              />
            </div>
          </div>
        </fieldset>
        <fieldset
          style={{marginBottom: 10}}
        >
          <legend>
            Decks Created
          </legend>
          <LineChartWithControls
            namespace='deck-dashboard-new-decks'
            charts={[
              {
                name: 'New Decks',
                resources: [
                  {
                    name: 'UserDeck',
                    label: 'Decks Created',
                  },
                  {
                    name: 'UserDeck',
                    label: 'Decks Finished',
                    query: "finished = TRUE",
                  },
                ],
              }
            ]}
          />
        </fieldset>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(DecksDashboard);
