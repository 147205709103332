import React from 'react';
import { observer } from 'mobx-react';
import ExerciseSentence from 'components/exercise/exercise_sentence';
import { ExerciseUI } from '@seedlang/state';
import ReorderAcceptItem from 'components/exercise/reorder_accept_item';
import styled from '@emotion/styled';
import ReorderClickItem from 'components/exercise/reorder_click_item';
import autobind from 'autobind-decorator';
import { computed, observable } from "mobx";

const Wrapper = styled.div`

`;

const ReorderClickItemWrapper = styled.div`
  width: 100%;
  margin: 0 20px 10px 0;
  @media only screen and (max-width : 649px) {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    background: #f1f1f1;
    position: fixed;
    bottom: 0px;
    left: 0px;
    padding: 10px 5px 30px 5px;
    margin-bottom: 0px;
    box-shadow: rgba(0, 0, 0, 0.32) 2px 2px 4px 4px;
    z-index: 999;
  }
`;

const ExerciseEntries = styled.div`
`;

const ExerciseEntry = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px dotted #CCC;
  margin-bottom: 10px;
  padding-bottom: 5px;
  @media only screen and (max-width : 649px) {
    flex-direction: column;
  }
`;

const ExerciseSentenceWrapper = styled.div`
  flex: 1;
  @media only screen and (max-width : 649px) {
    width: 100%;
  }
`;

const ReorderAcceptItemWrapper = styled.div`
  width: 50%;
  padding-right: 20px;
  @media only screen and (max-width : 649px) {
    width: 100%;
    padding-right: 0px;
  }
`;

@observer
class ExerciseMatchText extends React.Component {

  @observable showMobileClickItems = false;

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }
  @autobind onClick(item) {
    if (!ExerciseUI.exercise.state.revealed) {
      ExerciseUI.assignToNextExerciseEntry(item);
    }
  }

  @autobind onScroll() {
    this.showMobileClickItems = this.exerciseIsInView;
  }

  get exerciseIsInView() {
    let exerciseWrapper = document.getElementById('exercise');
    if (exerciseWrapper) {
      const rect = exerciseWrapper.getBoundingClientRect();
      return (
        rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.bottom >= 0
      );
    }
    return false;
  }


  render() {
    return (
      <Wrapper id="exercise">
        {
          ExerciseUI.exercise.hasExerciseEntries && (!ExerciseUI.layout.isMobile || this.showMobileClickItems) &&
            <ReorderClickItemWrapper>
              {
                ExerciseUI.exercise.shuffledExerciseEntries.map(item => {
                  if (!ExerciseUI.layout.isMobile || !item.state.assigned) {
                    return (
                      <ReorderClickItem
                        onClick={() => this.onClick(item)}
                        key={item.id}
                        margin="2px"
                        assigned={item.state.assigned}
                        height={ExerciseUI.layout.isMobile ? '50px' : 'fit-content'}
                        minHeight='50px'
                        inDeck={this.props.inDeck}
                        whiteSpace={ExerciseUI.layout.isMobile ? 'nowrap' : ''}
                      >
                        {item.prompt}
                      </ReorderClickItem>
                    )
                  }
                })
              }
            </ReorderClickItemWrapper>
        }
        <ExerciseEntries>
          {
            ExerciseUI.exercise.exerciseEntries.map(item => {
              return (
                <ExerciseEntry
                  key={item.id}
                  grammarTip={item.grammarTip}
                >
                  <ReorderAcceptItemWrapper>
                    <ReorderAcceptItem
                      disabled={ExerciseUI.exercise.state.revealed}
                      value={item.state.assignedExerciseEntryPrompt || ''}
                      onClick={() => ExerciseUI.removeExerciseEntry(item)}
                      correct={ExerciseUI.exercise.state.revealed && item.state.correct}
                      incorrect={ExerciseUI.exercise.state.revealed && !item.state.correct}
                      fontSize={item.sentence?.rightToLeftLanguage ? '16px' : '14px'}
                      id={item.id}
                      justifyContent={ExerciseUI.layout.isMobile ? 'center' : 'right' }
                      height='fit-content'
                      minHeight='50px'
                      width={ExerciseUI.layout.isMobile ? '100%' : null}
                    />
                  </ReorderAcceptItemWrapper>
                  {
                    item.sentence &&
                      <ExerciseSentenceWrapper>
                        <ExerciseSentence
                          showTargetText
                          sentence={item.sentence}
                        />
                      </ExerciseSentenceWrapper>
                  }
                </ExerciseEntry>
              )
            })
          }
        </ExerciseEntries>
      </Wrapper>
    );
  }
}

export default ExerciseMatchText;
