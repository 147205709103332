import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import styled from '@emotion/styled';
import Text from 'components/text';
import Button from 'components/button/button';
import autobind from 'autobind-decorator';
import { Theme } from '@seedlang/constants';
import isBlank from 'is-blank';
import FormWrapper from 'components/authenticate/form_wrapper';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { AppUI } from '@seedlang/state';
import WhitelabelLogo from "components/whitelabel_logo";
import { WhitelabelOnboardingState } from '@seedlang/state';
import Spinner from 'components/spinner';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  width: 100%;
`;

const Content = styled.div`
  ${flexCenterColumn()}
  width: 100%;
`;

const LogoWrapper = styled.a`
  margin: 10px 0 20px 0;
`;


const PatreonText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
  font-size: 18px;
`;

@observer
class OnboardingWhitelabelCreatePassword extends React.Component {
  @observable password;
  @observable passwordConfirmation;
  @observable showSpinner = true;

  constructor(props) {
    super(props);
    this.init();
  }

  async init() {
    if (window.location.href.match("from_patreon")) {
      WhitelabelOnboardingState.returnedFromPatreonAuthFlow();
    }
    this.showSpinner = false;
  }

  @computed get disabled() {
    return isBlank(this.password) || this.password !== this.passwordConfirmation || this.password.length < 6;
  }

  @autobind onSubmitForm(e) {
    e.preventDefault();
    if (!this.disabled) {
      this.onSubmitPassword(this.password)
    }
  }

  @autobind onSubmitPassword(password) {
    this.showSpinner = true;
    AppUI.authUserStore.update({ data: { password: password, password_confirmation: password } }, this.afterSubmitPassword);
  }

  @autobind afterSubmitPassword() {
    WhitelabelOnboardingState.passwordCreationStepFinished();
  }

  render() {
    if (this.showSpinner) {
      return <Spinner background={AppUI.site.accentColor || Theme.blue} />;
    }
    return (
      <Wrapper className="onboarding-create-password">
        <Content>
          <FormWrapper padding={0} color={"#000"}>
            {
              window.location.href.match("from_patreon") &&
                <PatreonText>
                  <LogoWrapper href="/">
                    <WhitelabelLogo
                      image={AppUI.site?.image}
                    />
                  </LogoWrapper>
                  <div style={{marginTop: '20px'}}>You have successfully connected to Patreon and your Easy Catalan Membership account has been created with the following email address:</div>
                  <div style={{fontWeight: 'bold'}}>{AppUI.user?.email}</div>
                  <div>Please create a password for future logins using the email address above.</div>
                </PatreonText>
            }
            {
              !window.location.href.match("from_patreon") &&
              <Text
                heading="3"
                center
                margin="0 0 20px 0"
                color={"#000"}
              >
                Set Your Password
              </Text>
            }
            <form
              onSubmit={this.onSubmitForm}
            >
              <div>
                <label>Password</label>
                <input
                  type="password"
                  autoFocus={this.props.show}
                  value={this.password || ''}
                  onChange={(e) => this.password = e.currentTarget.value}
                  style={{marginBottom: 10}}
                />
              </div>

              <div>
                <label>Password Confirmation</label>
                <input
                  type="password"
                  value={this.passwordConfirmation || ''}
                  onChange={(e) => this.passwordConfirmation = e.currentTarget.value}
                />
              </div>

              <Button
                margin="20px 0 0 0"
                height={'50px'}
                onClick={this.onSubmitForm}
                backgroundColor={Theme.green}
                width="100%"
                disabled={this.disabled}
              >
                Continue
              </Button>
            </form>
          </FormWrapper>
        </Content>
      </Wrapper>
    );
  }
}

export default OnboardingWhitelabelCreatePassword;
