import React from 'react';
import { observer } from 'mobx-react';
import { TriviaIntroCardWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { DeckUI } from '@seedlang/state';
import NextButton from 'components/trivia_card/next_button';
import { flexCenterColumn, flexCenter } from '@seedlang/style_mixins';
import Text from 'components/text';
import TriviaCardPlayer from 'components/trivia_card/trivia_card_player';
import PageOnboarding from 'components/onboarding/page_onboarding';
import { LanguageIcon } from '@seedlang/icons';
import { pixify } from '@seedlang/utils';
import TextResizer from 'components/text_resizer';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  height: ${(props) => props.height};
  position: relative;
  background: #333;
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const PlayersRow = styled.div`
  ${flexCenter()}
`;

const Words = styled.div`
  margin-top: 20px;
`;

const WordsTitleRow = styled.div`
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  hr {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;

const WordsTitle = styled.div`
  color: white;
  font-size: 14px;
  background: #333;
  display: inline-block;
  z-index: 2;
  padding: 10px;
  text-align: center;
`;

const WordRow = styled.div`
  ${flexCenter()}
  background: white;
  margin: 2px 10px;
  padding: 10px;
  border-radius: 5px;
`;

const WordRowText = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  text-align: left;
  padding-left: 5px;
  font-size: 16px;
  line-height: 20px;
  margin: ${(props) => props.margin};
  .icon-wrapper {
    margin-right: 5px;
    height: 20px;
  }
`;

const Versus = styled.div`
  margin: 0 30px;
  color: white;
`;

const PageOnboardingWrapper = styled.div`
  padding: 0 10px;
`;

const Sources = styled.div`
  display: flex;
  flex-direction: column;
`;

@observer
class TriviaIntroCard extends React.Component {

  render() {
    return (
      <Wrapper
        height={pixify(DeckUI.layout.contentHeightWithoutButtons)}
        width={pixify(DeckUI.layout.deckWidth)}
      >
        <Content>
          <Text
            heading="2"
            color="white"
            textAlign="center"
            margin="20px 0"
          >
            PLAY TRIVIA
          </Text>
          <PlayersRow>
            <TriviaCardPlayer
              flexDirection="column"
              nameMargin="5px 0 0 0"
              vertical
              hideProStatus
              user={DeckUI.user}
              nameFontSize="16px"
              userProfileImageWidth={100}
              userProfileBorder="5px solid white"
            />
            {
              this.props.hasOpponent &&
                <Versus>vs</Versus>
            }
            {
              this.props.hasOpponent &&
                <TriviaCardPlayer
                  flexDirection="column"
                  nameMargin="5px 0 0 0"
                  vertical
                  hideProStatus
                  user={this.props.opponent}
                  nameFontSize="16px"
                  userProfileImageWidth={100}
                  userProfileBorder="5px solid white"
                />
            }
          </PlayersRow>
          {
            this.props.hasOpponent && !DeckUI.user.wasUiElementSeen('trivia-opponent') &&
              <PageOnboardingWrapper>
                <PageOnboarding
                  margin="10px 0"
                  background="#bfbfbf"
                  uiElement="trivia-opponent"
                >
                  You are playing vs a previous performance of your opponent. Feel free to <b>take your time between turns</b>. Don't rush and most importantly, have fun!
                </PageOnboarding>
              </PageOnboardingWrapper>
          }
          <Words>
            <WordsTitleRow>
              <hr />
              <WordsTitle>
                Some Words That Will Help
              </WordsTitle>
            </WordsTitleRow>
            {
              DeckUI.deck.words.map(item => {
                if (item.vocabSourceListForUser(DeckUI.user)) {
                  return (
                    <WordRow
                      key={item.id}
                    >
                      <WordRowText>
                        <LanguageIcon
                          languageId={DeckUI.deck.languageId}
                        />
                        <TextResizer
                          height={25}
                          width={(DeckUI.layout.innerDeckWidth / 2) - 50}
                          characterLength={item.targetTextWithDefiniteArticle ? item.targetTextWithDefiniteArticle.length : 0}
                          maximumFontSize={16}
                          lineHeightOffset={3}
                        >
                          <Text>
                            {item.targetTextWithDefiniteArticle}
                          </Text>
                        </TextResizer>
                      </WordRowText>
                      <WordRowText>
                        <Sources>
                          {
                            item.vocabSourceForUser(DeckUI.user).slice(0,3).map(translationSource => {
                              return (
                                <WordRowText
                                  margin="2px 0"
                                  key={translationSource.id}
                                >
                                  <LanguageIcon
                                    languageId={translationSource.source && translationSource.source.languageId}
                                    useUkFlagForEn={DeckUI.user.useBritishEnglish}
                                  />
                                  {translationSource.source && translationSource.source.text}
                                </WordRowText>
                              )
                            })
                          }
                        </Sources>
                      </WordRowText>
                    </WordRow>
                  )
                } else {
                  return null;
                }
              })
            }
          </Words>
        </Content>
        <NextButton
          disabled={!this.props.inView}
          onClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard)}
          shortcut="1"
          submitOnEnter
          text="Start Game"
          margin="10px 0"
        />
      </Wrapper>
    );
  }
}

export default TriviaIntroCardWrapper(TriviaIntroCard);
