import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { noop } from 'lodash';
import Toggle from 'react-toggle';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 14px;
  margin: ${props => props.margin};
  .react-toggle {
    margin: 0 5px;
    pointer-events: none;
  }
  .react-toggle-track {
    background: #b7b7b7;
  }
  .react-toggle--checked .react-toggle-track{
    background: ${props => props.highlightColor};
  }
`;

const Label = styled.div`
  cursor: pointer;
  font-weight: ${(props) => props.selected ? "bold" : "default"};
`;

const ToggleWrapper = styled.div`
  cursor: pointer;
`;

@observer
class ToggleComponent extends React.Component {

  static defaultProps = {
    margin: "0 10px 0 0",
    highlightColor: Theme.green,
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
        highlightColor={this.props.highlightColor}
      >
        <Label
          onClick={this.props.onDeselect}
          selected={!this.props.selected}
        >
          {this.props.labels && this.props.labels[0]}
        </Label>
        <ToggleWrapper
          onClick={this.props.onToggle}
        >
          <Toggle
            checked={this.props.selected}
            icons={false}
            onChange={noop}
          />
        </ToggleWrapper>
        <Label
          onClick={this.props.onSelect}
          selected={this.props.selected}
        >
          {this.props.labels && this.props.labels[1]}
        </Label>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ToggleComponent);
