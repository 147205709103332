import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';

@observer
class SeedlangLogo extends React.Component {

  render() {
    return (
      <div className='seedlang-logo'>
        <svg viewBox="0 0 613 122" version="1.1" xmlns="https://www.w3.org/2000/svg">
            <g>
              <path d="M121.772,60.161 C121.772,93.652 94.62,120.805 61.128,120.805 C27.635,120.805 0.483,93.652 0.483,60.161 C0.483,26.667 27.635,-0.485 61.128,-0.485 C94.62,-0.485 121.772,26.667 121.772,60.161" id="Fill-1" fill="#FFFFFF"></path>
              <path d="M139.011,83.88 C138.561,83.52 138.11,82.89 138.11,82.08 C138.11,80.82 139.19,79.74 140.45,79.74 C141.08,79.74 141.62,80.01 141.981,80.28 C148.28,85.95 154.58,88.47 163.22,88.47 C172.669,88.47 179.148,83.16 179.148,75.96 L179.148,75.78 C179.148,69.121 175.639,65.251 161.329,62.371 C146.21,59.311 140.091,54.181 140.091,44.642 L140.091,44.462 C140.091,35.102 148.64,27.902 160.34,27.902 C168.799,27.902 174.649,30.063 180.409,34.382 C180.858,34.742 181.398,35.372 181.398,36.272 C181.398,37.532 180.318,38.612 179.059,38.612 C178.519,38.612 177.979,38.432 177.529,38.072 C172.039,33.842 166.55,32.132 160.159,32.132 C150.89,32.132 144.77,37.442 144.77,44.012 L144.77,44.192 C144.77,50.852 148.19,54.901 163.13,57.961 C177.709,60.931 183.829,66.151 183.829,75.331 L183.829,75.51 C183.829,85.68 175.099,92.7 162.949,92.7 C153.5,92.7 146.12,89.73 139.011,83.88" id="Fill-2" fill="#FFFFFF"></path>
              <path d="M193.82,89.46 L193.82,31.142 C193.82,29.882 194.9,28.802 196.16,28.802 L236.839,28.802 C238.009,28.802 238.999,29.792 238.999,30.962 C238.999,32.132 238.009,33.122 236.839,33.122 L198.5,33.122 L198.5,57.871 L232.789,57.871 C233.959,57.871 234.949,58.861 234.949,60.031 C234.949,61.201 233.959,62.191 232.789,62.191 L198.5,62.191 L198.5,87.48 L237.289,87.48 C238.459,87.48 239.448,88.47 239.448,89.64 C239.448,90.81 238.459,91.8 237.289,91.8 L196.16,91.8 C194.9,91.8 193.82,90.72 193.82,89.46" id="Fill-3" fill="#FFFFFF"></path>
              <path d="M250.52,89.46 L250.52,31.142 C250.52,29.882 251.6,28.802 252.859,28.802 L293.538,28.802 C294.708,28.802 295.698,29.792 295.698,30.962 C295.698,32.132 294.708,33.122 293.538,33.122 L255.199,33.122 L255.199,57.871 L289.488,57.871 C290.658,57.871 291.648,58.861 291.648,60.031 C291.648,61.201 290.658,62.191 289.488,62.191 L255.199,62.191 L255.199,87.48 L293.988,87.48 C295.158,87.48 296.147,88.47 296.147,89.64 C296.147,90.81 295.158,91.8 293.988,91.8 L252.859,91.8 C251.6,91.8 250.52,90.72 250.52,89.46" id="Fill-4" fill="#FFFFFF"></path>
              <path d="M307.22,31.142 C307.22,29.882 308.3,28.802 309.56,28.802 L328.19,28.802 C347.988,28.802 361.668,42.392 361.668,60.121 L361.668,60.301 C361.668,78.03 347.988,91.8 328.19,91.8 L309.56,91.8 C308.3,91.8 307.22,90.72 307.22,89.46 L307.22,31.142 L307.22,31.142 Z M328.19,87.48 C345.738,87.48 356.808,75.51 356.808,60.481 L356.808,60.301 C356.808,45.362 345.738,33.122 328.19,33.122 L311.899,33.122 L311.899,87.48 L328.19,87.48 L328.19,87.48 Z" id="Fill-5" fill="#FFFFFF"></path>
              <path d="M372.199,30.782 C372.199,29.522 373.279,28.442 374.539,28.442 C375.8,28.442 376.879,29.522 376.879,30.782 L376.879,87.48 L412.067,87.48 C413.237,87.48 414.228,88.47 414.228,89.64 C414.228,90.81 413.237,91.8 412.067,91.8 L374.539,91.8 C373.279,91.8 372.199,90.72 372.199,89.46 L372.199,30.782" id="Fill-6" fill="#FFFFFF"></path>
              <path d="M423.948,88.74 L450.857,30.422 C451.487,29.072 452.388,28.172 454.008,28.172 L454.187,28.172 C455.808,28.172 456.707,29.072 457.337,30.422 L484.156,88.56 C484.426,89.1 484.517,89.55 484.517,90 C484.517,91.17 483.526,92.16 482.267,92.16 C481.187,92.16 480.376,91.35 479.927,90.36 L472.547,74.161 L435.378,74.161 L427.998,90.45 C427.549,91.53 426.828,92.16 425.749,92.16 C424.578,92.16 423.589,91.26 423.589,90.18 C423.589,89.82 423.679,89.28 423.948,88.74 L423.948,88.74 Z M470.566,69.931 L454.008,33.482 L437.358,69.931 L470.566,69.931 L470.566,69.931 Z" id="Fill-7" fill="#FFFFFF"></path>
              <path d="M493.607,30.872 C493.607,29.612 494.688,28.532 495.947,28.532 L496.668,28.532 C497.657,28.532 498.378,29.072 499.008,29.882 L541.485,83.79 L541.485,30.692 C541.485,29.432 542.476,28.442 543.735,28.442 C544.995,28.442 545.985,29.432 545.985,30.692 L545.985,90.09 C545.985,91.17 545.176,92.07 544.096,92.07 L543.735,92.07 C542.836,92.07 542.115,91.44 541.396,90.54 L498.107,35.642 L498.107,89.91 C498.107,91.17 497.117,92.16 495.857,92.16 C494.598,92.16 493.607,91.17 493.607,89.91 L493.607,30.872" id="Fill-8" fill="#FFFFFF"></path>
              <path d="M556.518,60.481 L556.518,60.301 C556.518,43.292 568.848,27.723 587.656,27.723 C597.106,27.723 603.316,30.332 609.166,34.742 C609.706,35.192 610.065,35.822 610.065,36.632 C610.065,37.892 608.985,38.972 607.726,38.972 C607.366,38.972 606.826,38.792 606.286,38.342 C601.426,34.562 596.116,31.952 587.387,31.952 C572.087,31.952 561.378,44.912 561.378,60.121 L561.378,60.301 C561.378,76.59 571.548,88.74 588.377,88.74 C596.387,88.74 603.856,85.41 608.446,81.54 L608.446,63.451 L588.827,63.451 C587.656,63.451 586.667,62.461 586.667,61.291 C586.667,60.121 587.656,59.131 588.827,59.131 L610.606,59.131 C611.866,59.131 612.945,60.211 612.945,61.471 L612.945,82.08 C612.945,82.98 612.315,83.97 611.326,84.78 C605.386,89.37 597.557,92.88 588.196,92.88 C568.308,92.88 556.518,78.21 556.518,60.481" id="Fill-9" fill="#FFFFFF"></path>
              <path d="M61.291,69.356 C61.27,69.356 61.251,69.357 61.23,69.357 C61.172,68.472 61.139,67.579 61.139,66.678 C61.139,66.525 61.143,66.373 61.145,66.221 C61.144,66.1 61.14,65.981 61.14,65.861 C61.14,66.018 61.136,66.175 61.135,66.332 C61.135,66.448 61.139,66.562 61.139,66.678 C61.139,67.579 61.106,68.472 61.049,69.357 C61.029,69.357 61.01,69.356 60.989,69.356 C60.218,78.401 56.485,86.602 50.764,92.98 L71.518,92.98 C65.797,86.602 62.063,78.401 61.291,69.356" id="Fill-10" fill="#8F2327"></path>
              <path d="M20.647,27.34 C20.59,28.227 20.558,29.121 20.558,30.019 C20.558,52.412 38.669,70.571 61.041,70.652 C61.126,69.653 61.175,68.644 61.186,67.626 C61.002,45.375 42.932,27.387 20.647,27.34" id="Fill-11" fill="#47A455"></path>
              <path d="M101.694,30.019 C101.694,29.121 101.662,28.227 101.604,27.34 C79.356,27.387 61.313,45.314 61.068,67.515 C61.077,68.57 61.127,69.617 61.214,70.652 C83.585,70.568 101.694,52.409 101.694,30.019" id="Fill-12" fill="#45783A"></path>
            </g>
        </svg>
      </div>
    );
  }
}

export default ErrorBoundary(SeedlangLogo);
