import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  flex: 1;
  ul {
    width: 100%;
  }
  li {
    padding: 0 0 5px 0;
    display: block;
    margin: 0;
    font-size: 14px;
  }
`;

const Header = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  margin-bottom: 5px;
`;

const Text = styled.div`
  cursor: pointer;
  color: rgb(237, 237, 237);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

@observer
class FooterColumn extends React.Component {

  render() {
    return (
      <Wrapper>
        <Header>
          {this.props.title}
        </Header>
        <ul>
          {
            this.props.links.map(item => {
              return (
                <li
                  key={item.name}
                  onClick={() => item.route ? AppUI.routeStore.routeToNamed(item.route) : window.open(item.link)}
                >
                  <Text>
                    {item.name}
                  </Text>
                </li>
              )
            })
          }
        </ul>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(FooterColumn);
