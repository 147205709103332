import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexCenter, flexCenterColumn } from '@seedlang/style_mixins';
import { last } from 'lodash';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  margin: 0 20px 10px 20px;
  width: 100%;
  justify-content: center;
`;

const Button = styled.div`
  ${flexCenterColumn()}
  background: ${(props) => props.active ? '#FFF' : 'transparent'};
  border: 1px sold gray;
  cursor: pointer;
  border: 1px solid gray;
  padding: 0;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 2px;
`;

const Top = styled.div`
  ${flexCenter()}
  padding: 0 10px;
`;

const Key = styled.div`
  ${flexCenter()}
  background-color: ${(props) => props.active ? props.backgroundColor : '#CCC'};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px 0 0;
`;

const Label = styled.div`

`;

const Count = styled.div`
  border-top: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
  font-size: 28px;
  padding: 10px;
  height: 50px;
`;

@observer
class Legend extends React.Component {

  count(resource) {
    let count;
    if (this.props.chartType === 'cumulative') {
      count = last(resource.data)?.y ?? 0;
    } else {
      count = resource.data.map(item => item.y).reduce((a, b) => a + b, 0);
    }
    return Number(parseFloat(count).toFixed(2)).toLocaleString('en');
  }

  render() {
    return (
      <Wrapper>
        {
          this.props.data && this.props.data.map(item => {
            return (
              <Button
                key={item.id}
                onClick={() => this.props.onToggle(item.label || item.id)}
                active={this.props.selectedIds.indexOf(item.id) !== -1}
               >
                <Top>
                  <Key
                    backgroundColor={this.props.colors[item.colorIndex]}
                    active={this.props.selectedIds.indexOf(item.id) !== -1}
                  />
                  <Label>
                    {item.label || item.id}
                  </Label>
                </Top>
                {
                  !this.props.hideCounts &&
                    <Count>
                      {!this.props.loadingData && this.count(item)}
                    </Count>
                }
              </Button>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Legend);
