import React from 'react';
import { observer } from 'mobx-react';
import UserProfileImage from 'components/user/user_profile_image';
import { AppUI } from '@seedlang/state';
import styled from '@emotion/styled';
import Text from 'components/text';
import CommentCreate from 'components/comment/comment_create';
import NameWithIcons from 'components/user/name_with_icons';
import { pixify } from '@seedlang/utils';
import DeleteButton from 'components/button/delete_button';
import CommentButton from 'components/button/comment_button';
import XpAnimation from 'components/xp_animation';
import { Theme } from '@seedlang/constants';
import { CommentShowWrapper } from '@seedlang/hoc';
import FeedbackPicker from 'components/feedback/feedback_picker';
import FeedbackList from 'components/feedback/feedback_list';
import { EyeIcon, PencilIcon, PinIcon, ReplyIcon, SmileIcon } from '@seedlang/icons';
import TextDisplay from 'components/text_display';
import NameWithoutIcons from "components/user/name_without_icons";
import ReactJson from "react-json-view";
import { isPresent } from "@seedlang/utils/src";

const Wrapper = styled.div`
  position: relative;
  margin: ${(props) => props.margin};
  padding: ${(props) => `${props.padding} 0 0 0`};
  width: ${(props) => props.width};
  a {
    text-decoration: underline;
  }
  ul {
    margin-left: 20px;
    li {
      list-style-type: disc;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    margin: 10px 0;
  }
  .reply-icon, .smile-icon, .pencil-icon, .eye-icon, .pin-icon {
    fill: #333;
  }
  img {
    max-width: 500px;
    max-height: 500px;
  }
  .reply-icon {
    width: 12px;
    margin-right: 3px;
  }
  .smile-icon {
    width: 11px;
  }
  .eye-icon {
    width: 14px;
    height: 10px;
  }
  .pencil-icon {
    width: 10px;
  }
  .pin-icon {
    width: 11px;
    margin-right: 4px;
  }
  .pencil-icon, .smile-icon, .eye-icon {
    margin-right: 4px;
  }
  i {
    color: #333;
    font-size: 12px;
    margin: -1px 4px 0 0;
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
`;

const IndentationWrapper = styled.div`
  margin: ${(props) => props.margin};
  border: ${(props) => props.border};
  position: relative;
  display: flex;
  padding: 20px 20px 10px 20px;
  flex-direction: column;
  border-radius: 5px;
  background: #f1f1f1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
`;

const TextWrapper = styled.div`
  position: relative;
  flex: 1;
  text-align: left;
  a {
    word-break: break-all;
  }
`;

const Info = styled.div`
  line-height: 18px;
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
`;

const PostedAt = styled.div`
  text-align: right;
  font-size: 11px;
  line-height: 16px;
  color: #9c9c9c;
  font-style: italic;
  width: 80px;
`;

const BottomBar = styled.div`
  display: flex;
  margin: 10px 0 0 0;
  position: relative;
`;

const Resolved = styled.div`
  background: ${Theme.orange};
  color: white;
  font-size: 11px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 5px;
`;

const DebugInfoWrapper = styled.div`
  max-height: 500px;
  overflow: auto;
  border: 1px solid #9c9c9c;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
`;

@observer
class CommentShow extends React.Component {

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
        padding={pixify(this.props.padding)}
      >
        <XpAnimation
          namespace={this.props.comment.id}
          position="absolute"
          bottom="0"
          margin={`0 0 0 ${pixify(this.props.offset)}`}
        />
        <IndentationWrapper
          margin={`0 0 0 ${pixify(this.props.offset)}`}
          border={this.props.border}
        >
          {
            this.props.showHiddenBorder &&
              <Resolved>
                Private
              </Resolved>
          }
          <Content>
            <UserProfileImage
              clickToProfile={!this.props.hideUserProfile}
              hideProStatus={this.props.hideUserProfile}
              fontSize="13px"
              width={this.props.userProfileImageSize}
              margin="0 20px 0 0"
              user={this.props.comment.user}
            />
            <TextWrapper>
              <Info>
                <TopBar>
                  {
                    !this.props.hideUserProfile &&
                      <NameWithIcons
                        user={this.props.comment.user}
                      />
                  }
                  {
                    this.props.hideUserProfile &&
                      <NameWithoutIcons
                        user={this.props.comment.user}
                      />
                  }
                </TopBar>
              </Info>
              {
                this.props.comment.text && !this.props.comment.deleted &&
                  <Text
                    fontSize={this.props.fontSize}
                    lineHeight={this.props.lineHeight}
                    margin="10px 0"
                  >
                    {
                      !this.props.comment.deleted &&
                        <TextDisplay
                          markdown={this.props.comment.text}
                        />
                    }
                  </Text>
              }
              {
                this.props.comment.deleted &&
                  <Text
                    fontSize={this.props.fontSize}
                    lineHeight={this.props.lineHeight}
                    color="#B1B1B1"
                    margin="10px 0"
                  >
                    [Deleted]
                  </Text>
              }
            </TextWrapper>
          </Content>
          <BottomBar>
            <Links>
              {
                !AppUI.user.guest &&
                  <CommentButton
                    onClick={this.props.onToggleCreate}
                  >
                    <ReplyIcon />
                    Reply
                  </CommentButton>
              }
              {
                !AppUI.user.guest &&
                  <CommentButton
                    onClick={this.props.onToggleFeedback}
                  >
                    <SmileIcon />
                    React
                  </CommentButton>
              }
              {
                !this.props.comment.deleted && (this.props.comment.user.id === AppUI.user.id || AppUI.user.admin) &&
                  <CommentButton
                    onClick={this.props.onToggleEdit}
                  >
                    <PencilIcon />
                    Edit
                  </CommentButton>
              }
              {
                !this.props.comment.deleted && (this.props.comment.user.id === AppUI.user.id || AppUI.user.admin) &&
                  <DeleteButton
                    allowDeletion
                    onConfirm={this.props.onDeleteComment}
                    message="Delete this comment?"
                    confirmationMessageFloatsRight
                    right="auto"
                    left="10px"
                    top="-54px"
                  >
                    <CommentButton>
                      <i className='fa fa-times' />
                      Delete
                    </CommentButton>
                  </DeleteButton>
              }
              {
                (AppUI.user.admin || AppUI.user.moderator) &&
                  <CommentButton
                    onClick={this.props.onTogglePinned}
                  >
                    <PinIcon />
                    {this.props.comment.pinned ? 'Unpin' : 'Pin'}
                  </CommentButton>
              }
              {
                (AppUI.user.admin || AppUI.user.moderator) &&
                  <CommentButton
                    onClick={this.props.onToggleHidden}
                  >
                    <EyeIcon />
                    {this.props.comment.hidden ? 'Show' : 'Hide'}
                  </CommentButton>
              }
              {
                (AppUI.user.admin || AppUI.user.moderator) && !this.props.comment.user.admin &&
                  <CommentButton
                    onClick={this.props.onToggleAutoHide}
                  >
                    <EyeIcon />
                    {this.props.comment.user.autoHideComments ? 'Auto-Hide: On' : 'Auto-Hide: Off'}
                  </CommentButton>
              }
              {
                AppUI.user.admin && isPresent(this.props.comment.debugInfo) &&
                  <CommentButton
                    onClick={this.props.onToggleDebugInfo}
                  >
                    <i className="fa fa-code" />
                    {this.props.expandDebugInfo ? 'Hide Debug' : 'Show Debug'}
                  </CommentButton>
              }
            </Links>
            <PostedAt>
              {this.props.comment.createdAt.relativeTime}
              {
                this.props.comment.updated &&
                  <span
                    style={{marginLeft: 5}}
                  >
                    (updated)
                  </span>
              }
            </PostedAt>
            {
              this.props.expandFeedback &&
                <FeedbackPicker
                  onClose={this.props.onToggleFeedback}
                  onClick={this.props.onClickFeedback}
                />
            }
          </BottomBar>
          {
            this.props.comment.feedbacks && this.props.comment.feedbacks.length > 0 &&
              <FeedbackList
                id={this.props.comment.id}
                feedbacks={this.props.comment.feedbacks}
                commentId={this.props.comment.id}
                onChange={this.props.onChange}
              />
          }
          {
            (this.props.expandCreate || this.props.expandEdit) &&
              <CommentCreate
                namespace={this.props.comment.id}
                background="#f1f1f1"
                textInputButtonBackground="#FFF"
                edit={this.props.expandEdit}
                text={this.props.expandEdit ? this.props.comment.text : null}
                suggestedText={this.props.expandEdit ? null : this.props.comment.aiSuggestedAnswer}
                commentable={this.props.commentable}
                commentableType={this.props.commentableType}
                commentableId={this.props.commentableId}
                parentId={this.props.comment.id}
                afterCreateComment={this.props.onChange}
                afterSubmitComment={this.props.afterSubmitComment}
                margin="10px 0"
                languageId={this.props.languageId || 'DE'}
              />
          }
          {
            this.props.expandDebugInfo &&
            <DebugInfoWrapper>
              <ReactJson
                src={this.props.comment.debugInfoJson}
                displayDataTypes={false}
                displayObjectSize={false}
              />
            </DebugInfoWrapper>
          }
        </IndentationWrapper>
      </Wrapper>
    );
  }
}

export default CommentShowWrapper(CommentShow);
