import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import WorksheetGrid from 'components/worksheet/worksheet_grid';
import BannerNoticeIndex from 'components/banner_notice/banner_notice_index';

const Wrapper = styled.div`
  flex: 1;
  border-radius: 10px;
  overflow: hidden;
`;

@observer
class VideoWorksheetIndex extends React.Component {

  render() {
    return (
      <Wrapper>
        <BannerNoticeIndex
          route="videos.index"
        />
        <WorksheetGrid
          showFilters
          useGridSections={false}
          defaultWorksheetFilter={{youtube_url: true, podcast_episode_id: false}}
          {...this.props}
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(VideoWorksheetIndex);
