
import Text from 'components/text';
import React from 'react';
import { Link } from 'react-router';
import { AppUI } from "@seedlang/state";

class ContactUsTextWithLink extends React.Component {
  static defaultProps = {
    marginTop: "15px",
  }

  render() {
    const textColor = this.props.onDarkBackground ? "#FFF" : "#333";
    return (
      <Text color={textColor} style={{ marginTop: this.props.marginTop, fontSize: 14 }} textAlign={this.props.textAlign}>
        <>If you have any questions, you can </>
        <Link
          to={{ name: "contact" }}
          className='underline'
          style={{ color: 'inherit' }}
        >
          contact us here
        </Link>
        <span> or </span>
        <a
          href={AppUI.site.paymentMigrationConfig.infoUrl}
          className='underline'
          style={{ color: 'inherit' }}
        >
          read more about our Migration process here
        </a>
        <span>.</span>
      </Text>
    )
  }
}

export default ContactUsTextWithLink;
