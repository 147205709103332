import React from 'react';
import { observer } from 'mobx-react';
import UserProfileImage from 'components/user/user_profile_image';
import Paginator from 'components/paginator';
import { RouteStore } from '@seedlang/stores';
import styled from '@emotion/styled';
import { flexCenter } from '@seedlang/style_mixins';

const Wrapper = styled.div`

`;

const Row = styled.div`
  ${flexCenter()}
  margin: 5px 0;
`;

const Name = styled.div`
  margin-left: 10px;
  text-align: left;
  flex: 1;
`;

@observer
class UserIndex extends React.Component {

  static defaultProps = {
    userImageWidth: 60,
  }

  constructor(props) {
    super(props);
    if (this.props.store && !this.props.skipLoad) {
      this.props.store.getIndex({ids: this.props.ids});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.store !== this.props.store && !this.props.skipLoad) {
      this.props.store.getIndex({ids: this.props.ids});
    }
  }

  onClick(user) {
    if (this.props.onClick) {
      this.props.onClick(user);
    } else if (this.props.clickToProfile) {
      RouteStore.routeToNamed('profiles.show', {userId: user.id});
    }
  }

  render() {
    return (
      <Wrapper>
        {
          (this.props.users || this.props.store.indexData).map((item) => {
            return (
              <Row
                key={item.id}
                onClick={() => this.onClick(item)}
              >
                <UserProfileImage
                  clickToProfile
                  width={this.props.userImageWidth}
                  user={item}
                />
                <Name>
                  {item.name || item.email}
                </Name>
              </Row>
            )
          })
        }
        {
          this.props.store &&
            <Paginator
              store={this.props.store}
              ids={this.props.ids}
            />
        }
      </Wrapper>
    );
  }
}

export default UserIndex;
