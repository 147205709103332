import React from 'react';
import { observer } from 'mobx-react';
import UserProfileImage from 'components/user/user_profile_image';
import NameWithIcons from 'components/user/name_with_icons';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const NameWrapper = styled.div`
  padding-left: 10px;
  font-size: 14px;
  flex: 1;
  cursor: pointer;
`;

const Date = styled.div`
  width: 120px;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #999;
`;

@observer
class UserPost extends React.Component {

  render() {
    return (
      <Wrapper>
        <UserProfileImage
          onClick={this.props.onClick}
          user={this.props.user}
          width={30}
        />
        <NameWrapper
          onClick={this.props.onClick}
        >
          {!this.props.showUserIcons && this.props.user.name}
          {
            this.props.showUserIcons &&
              <NameWithIcons
                user={this.props.user}
              />
          }
        </NameWrapper>
        <Date>
          {this.props.date.relativeTime}
        </Date>
      </Wrapper>
    );
  }
}

export default UserPost;
