import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';
import { flexCenterColumn } from '@seedlang/style_mixins';
import MaintenanceMessage from 'components/maintenance_message';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  background: ${Theme.blue};
`;

@observer
class Maintenance extends React.Component {

  constructor(props) {
    super(props);
    setInterval(this.checkIfOnline, 5000)
  }

  @autobind checkIfOnline() {
    AppUI.loadUser(this.afterLoadUser);
  }

  @autobind afterLoadUser(resp) {
    AppUI.setAuthUserStoreShowData(resp);
    if (!AppUI.user.siteShowsMaintenancePage) {
      AppUI.routeStore.routeToNamed(AppUI.user.homePage);
    }
  }

  render() {
    return (
      <Wrapper>
        <MaintenanceMessage
          headline="Oh No! We are offline while we do a bit of work."
          showButtons
          color="white"
          width="400px"
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Maintenance);
