import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from "@seedlang/state";
import { computed } from "mobx";

const Wrapper = styled.div`
  width: ${props => props.width};
  height: 183px;
  cursor: pointer;
  img {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    margin-right: 10px;
  }
`;

const Description = styled.div`
  font-size: 18px;
  line-height: 24px;
  background: ${props => props.background || '#e9e9e9'};
  color: ${props => props.color || null};
  text-overflow: ellipsis;
  height: 183px;
  border-radius: 10px;
  margin: 0;
  padding: 10px;
  -webkit-mask-image: linear-gradient(to bottom,black,80%,transparent);
  p {
    margin-top: 0px!important;
  }
`;


@observer
class GridItemTextPreview extends React.Component {

  render () {
    return (
      <Wrapper
        width={this.props.width}
        height={this.props.height}
      >
        <Description
          dangerouslySetInnerHTML={{ __html: this.props.worksheet.previewText }}
          linkColor={AppUI.site.accentColor}
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(GridItemTextPreview);
