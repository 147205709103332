import React from 'react';
import { observer } from 'mobx-react';
import { DailyActivityCalendarDayWrapper } from '@seedlang/hoc';
import { LightningBoltIcon } from '@seedlang/icons';
import ReactTooltip from 'react-tooltip';
import styled from '@emotion/styled';
import { flexCenter } from '@seedlang/style_mixins';
import { LanguageIcon } from '@seedlang/icons';
import uuid from "uuid/v4";

const Wrapper = styled.div`
  ${flexCenter()}
  background: ${(props) => props.background};
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: ${props => props.borderRadius};
  margin: 1px 0;
  .lightning-bolt-icon {
    height: ${props => props.iconHeight};
    svg {
      width: ${props => props.iconWidth};
      fill: #FFF;
    }
  }
  &:hover {
    border: ${props => props.showTooltip ? "1px solid gray" : null};
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Col = styled.div`
  display: flex;
  align-items: center;
  .icon-wrapper {
    display: flex;
    align-items: center;
  }
`;

@observer
class DailyActivityCalendarDay extends React.Component {

  constructor(props) {
    super(props);
    this.id = uuid();
  }

  static defaultProps = {
    width: "27px",
    height: "27px",
    borderRadius: "8px",
    iconHeight: "16px",
    iconWidth: "10px",
  }

  render() {
    return (
      <Wrapper
        background={this.props.background}
        border={this.props.border}
        data-tip={this.props.showTooltip}
        data-for={this.id}
        showTooltip={this.props.showTooltip}
        hide={this.props.hide}
        width={this.props.width}
        height={this.props.height}
        borderRadius={this.props.borderRadius}
        iconHeight={this.props.iconHeight}
        iconWidth={this.props.iconWidth}
      >
        {
          this.props.dailyXpCount.streakMet &&
            <LightningBoltIcon />
        }
        {
          this.props.showTooltip &&
            <ReactTooltip
              place="top"
              type="light"
              effect="solid"
              id={this.id}
              class="custom-tooltip"
            >
              <div>
                <b>{this.props.dateString}</b>
              </div>
              <div>
                {`${this.props.dailyXpCount.xp || 0} Total XP`}
              </div>
              {
                this.props.xpBreakdowns && this.props.xpBreakdowns.map((item) => {
                  return (
                    <Row
                      key={item.id}
                    >
                      <Col>
                        {`${item.xp} XP ${item.xpDescriptionFromSlug}`}
                      </Col>
                      {
                        this.props.showFlags &&
                          <Col>
                            <LanguageIcon
                              width="12px"
                              margin="0 0 0 2px"
                              languageId={item.languageId || 'DE'}
                            />
                          </Col>
                      }
                    </Row>
                  )
                })
              }
            </ReactTooltip>
        }
      </Wrapper>
    );
  }
}

export default DailyActivityCalendarDayWrapper(DailyActivityCalendarDay);
