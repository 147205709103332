import React from 'react';
import { observer } from 'mobx-react';
import { CampaignStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';
import moment from 'moment';
import { AppUI } from '@seedlang/state';

@observer
class CampaignCreate extends React.Component {

  onClick() {
    if (this.props.oneTimeSending || isPresent(this.refs.name.value)) {
      CampaignStore.create(
        {
          data: {
            one_time_sending: this.props.oneTimeSending,
            name: this.props.oneTimeSending ? `Email - ${moment(new Date()).format('MM/DD, h:mm A')}` : this.refs.name.value,
            campaign_type_id: this.props.campaignTypeId,
            mobileNotification: this.props.mobileNotification,
            site_id: AppUI.site.id,
          },
        },
        this.props.afterCreate
      );
      if (!this.props.oneTimeSending) {
        this.refs.name.value = '';
      }
    }
  }

  render() {
    return (
      <div className="mailing-type-create create-row row">
        {
          !this.props.oneTimeSending &&
            <div className="col-xs-10">
              <input
                placeholder="Add a name"
                ref="name"
              />
            </div>
        }
        <div className="col-xs-2">
          <button
            className="button-primary"
            onClick={this.onClick.bind(this)}
          >
            { this.props.oneTimeSending ? "Create an Email" : "Create"}
          </button>
        </div>
      </div>
    );
  }
}

export default CampaignCreate;
