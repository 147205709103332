import React from 'react';
import { observer } from 'mobx-react';
import autobind from 'autobind-decorator';
import { pixify } from '@seedlang/utils';
import { ChatUI, AppUI } from '@seedlang/state';
import UserProfileImages from 'components/user/user_profile_images';
import DeleteButton from 'components/button/delete_button';
import Form from 'components/form_factory/form';
import FormFactory from 'components/form_factory/form_factory';
import UserSearch from 'components/user_search';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  margin: 10px;
  overflow: auto;
  height: ${(props) => props.height};
  input[type="checkbox"] {
    width: 30px;
  }
`;

const SearchWrapper = styled.div`
  margin: 10px 0 0 0;
`;

@observer
class ChatCreatorEdit extends React.Component {

  // this.userStore.setFilter('id', `![${this.chatStore.formCache.users.map(item => item.id)}]`, {refresh: true});

  constructor(props) {
    super(props);
    this.form = new FormFactory(ChatUI.chatStore, {
      ids: {chatId: this.props.params.chatId},
      onAfterCreate: this.onAfterCreateChat,
      onAfterUpdate: ChatUI.onAfterUpdateChat,
    });
    ChatUI.chatStore.registerFormField('users', true);
    if (props.params.chatId) {
      ChatUI.chatStore.initializeFormCacheValue('users', ChatUI.chatStore.showData.users);
    }
    if (props.params && props.params.userId) {
      ChatUI.userStore.getShow({ids: {userId: props.params.userId}}, this.afterGetUser)
    }
  }

  componentWillUnmount() {
    ChatUI.userStore.clearIndexData();
  }

  @autobind onAfterCreateChat(resp) {
    ChatUI.chatStore.getIndex({}, ChatUI.afterGetIndex.bind(ChatUI));
    if (ChatUI.layout.isMobile) {
      ChatUI.clearOverlays();
    } else {
      AppUI.gotoChat(resp.id);
    }
  }

  @autobind onToggleUserInFormData(user) {
    if (user.id === ChatUI.user.id) { return null }
    if (ChatUI.chatStore.formCache.users.find(item => item.id === user.id)) {
      ChatUI.chatStore.formCache.users = ChatUI.chatStore.formCache.users.filter(item => item.id !== user.id);
    } else {
      ChatUI.chatStore.formCache.users.push(user);
    }
  }

  @autobind onClear() {
    if (this.form.store.dirtyForm) {
      this.form.onClear();
    }
  }

  @autobind afterGetUser(resp) {
    ChatUI.userStore.setShowData(resp);
    ChatUI.chatStore.formCache.users.push(ChatUI.userStore.showData);
    ChatUI.chatStore.resetPristine();
  }

  fields() {
    return (
      <div className='fields'>
        { this.form.input({ name: 'title', required: true }) }
        <div className='participants'>
          <label>Participants</label>
          <UserProfileImages
            onClick={this.onToggleUserInFormData}
            users={ChatUI.chatStore.formCache.users}
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <Wrapper
        height={pixify(ChatUI.layout.chatShowHeight - 60)}
      >
        <Form
          form={this.form}
          fields={this.fields()}
          onClear={this.onClear}
          {...this.props}
        />
        <SearchWrapper>
          <UserSearch
            onSubmit={this.onToggleUserInFormData}
            searchByField="name"
            placeholder="Search for Participants"
          />
        </SearchWrapper>
        {
          ChatUI.chatStore.hasShowData &&
            <DeleteButton
              allowDeletion
              onConfirm={ChatUI.onDestroyChat.bind(ChatUI)}
              message="Delete this chat?"
            >
              <Button
                background={Theme.red}
                margin="10px 0 0 0"
              >
                Delete Chat
              </Button>
            </DeleteButton>
        }
      </Wrapper>
    );
  }
}

export default ChatCreatorEdit;
