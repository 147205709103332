import React from 'react';
import Cookies from 'js-cookie';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { PageStore } from '@seedlang/stores';
import { PageIndex } from 'pages/builder/pages';

@observer
class SitePageIndex extends React.Component {
  @observable showCreate = false;

  constructor(props) {
    super(props);

    const siteId = Cookies.get('site_id');
    PageStore.getFilteredIndex(siteId);
  }

  componentWillUnmount() {
    PageStore.clearIndexData();
  }

  onDelete(id) {
    PageStore.destroy({ ids: { pageId: id } });
  }

  @computed get siteId() {
    return Cookies.get('site_id');
  }

  @computed get pages() {
    return PageStore.indexData.filter(item => item.siteId === this.siteId);
  }

  render() {
    return (
      <PageIndex
        {...this.props}
        siteId={this.siteId}
        whiteLabeled
      />
    );
  }
}

export default SitePageIndex;
