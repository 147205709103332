import React from 'react';
import { observer } from 'mobx-react';
import { CardUserInputWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { DeckUI } from '@seedlang/state';
import UserInput from 'components/media/user_input';
import { pixify } from '@seedlang/utils';
import key from 'keymaster';
import autobind from 'autobind-decorator';

const Wrapper = styled.span`

`;

@observer
class CardUserInput extends React.Component {

  componentDidMount() {
    if (!this.props.disabled) {
      this.assignShortcut();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disabled && !this.props.disabled) {
      this.assignShortcut();
    } else if (!prevProps.disabled && this.props.disabled) {
      this.unbindShortcut();
    }
  }

  @autobind assignShortcut() {
    if (!DeckUI.isShortcutAssigned('a')) {
      DeckUI.assignShortcut('a');
      key('a', this.props.onToggleRecordAudio);
    }
  }

  @autobind unbindShortcut() {
    if (DeckUI.isShortcutAssigned('a')) {
      DeckUI.removeShortcut('a');
      key.unbind('a');
    }
  }

  render() {
    return (
      <Wrapper>
        {
          (this.props.show || DeckUI.showUserInput) &&
            <UserInput
              height={pixify(DeckUI.layout.userInputHeight)}
              userInputStore={this.props.userInputStore}
              mediaStore={this.props.mediaStore}
              submitted={this.props.cardState.submittedToFeed}
              requestedFeedback={this.props.cardState.requestedFeedback}
              allowTextInput={this.props.card.userResponseCard}
              allowVideoInput={false}
              allowAudioInput={DeckUI.canRecordMedia}
              disabled={this.props.disabled}
              onToggleRecordAudio={this.props.onToggleRecordAudio}
              cardId={this.props.card.id}
              width={pixify(DeckUI.layout.supplementWidth)}
              userId={DeckUI.user.id}
              audioOnly={!this.props.card.userResponseCard}
              messageOnSubmission={"Your feed has been updated."}
              languageId={this.props.card.languageId}
            />
        }
      </Wrapper>
    );
  }
}

export default CardUserInputWrapper(CardUserInput);
