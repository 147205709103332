import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { UserDeckStore } from '@seedlang/stores';
import ProgressBar from 'components/progress_bar';
import CheckIfTrue from 'components/check_if_true';
import { Link } from 'react-router';
import Paginator from 'components/paginator';
import { isPresent } from '@seedlang/utils';
import DeleteButton from 'components/button/delete_button';
import autobind from 'autobind-decorator';
import Copy from 'components/copy';
import { startCase } from "lodash";

@observer
class UserDeckIndex extends React.Component {

  componentDidMount() {
    this.getUserDecks();
  }

  @autobind getUserDecks() {
    if (this.props.params?.userId || this.props.userId) {
      UserDeckStore.getIndex({queryStrings: {user_id: this.props.params?.userId || this.props.userId}});
    } else if (this.props.params?.deckId) {
      console.log('deck id', this.props.params?.deckId)
      UserDeckStore.getIndex({queryStrings: {deck_id: this.props.params.deckId}});
    } else {
      UserDeckStore.getIndex({queryStrings: {expanded: true}});
    }
  }

  @autobind onDelete(id) {
    UserDeckStore.destroy({ids: {userDeckId: id}}, this.getUserDecks)
  }

  @autobind deckName(userDeck) {
    if (userDeck.deck && isPresent(userDeck.deck.name)) {
      return userDeck.deck.name;
    } else if (userDeck.deck && userDeck.deck.concept) {
      return userDeck.deck.concept.name;
    } else if (userDeck.deck && userDeck.deck.deckType) {
      return startCase(userDeck.deck.deckType);
    } else {
      return "Review"
    }
  }

  render() {
    return (
      <div className='user-deck-index'>
        <table className="table-wrapper">
          <thead>
            <tr>
              <th />
              <th>Updated At</th>
              {
                !this.props.userId &&
                  <th>User</th>
              }
              <th>Deck Name</th>
              <th>Last Card</th>
              <th>Progress</th>
              <th>Finished</th>
              <th>Pnts</th>
              <th>Opp</th>
              <th>1st Play</th>
              <th>User Type</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {
              UserDeckStore.indexData.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Copy
                        text={item.id}
                      />
                    </td>
                    <td>{item.createdAt.formatted}</td>
                    {
                      !this.props.userId && item.user && isPresent(item.user.id) &&
                        <td>
                          <Link
                            className='underline'
                            to={{name: 'builder.users.edit', params: {userId: item.user.id}}}
                          >
                            {item.user.name}
                          </Link>
                        </td>
                    }
                    <td>{this.deckName(item)}</td>
                    <td>
                      {item.lastCardType}
                    </td>
                    <td>
                      {
                        item.deck &&
                          <ProgressBar
                            width="30px"
                            height="8px"
                            borderRadius="5px"
                            current={item.progressCurrent + 1}
                            total={item.deck.cardsCount}
                          />
                      }
                    </td>
                    <td>
                      <CheckIfTrue
                        id={item.id}
                        value={item.finished}
                        model="user_decks"
                        field="finished"
                        icon="check"
                      />
                    </td>
                    <td>
                      {item.points > 0 ? item.points : null}
                    </td>
                    <td>
                      {item.opponentUserDeck ? item.opponentUserDeck.points : null}
                    </td>
                    <td>
                      <CheckIfTrue
                        id={item.id}
                        value={item.firstPlaythrough}
                        model="user_decks"
                        field="first_playthrough"
                        icon="check"
                      />
                    </td>
                    <td>
                      {item.guestUser && 'Guest'}
                      {item.registeredUser && 'Registered'}
                      {item.proUser && 'Pro'}
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message="Delete this user deck?"
                        className="fa-block right"
                      />
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Paginator
          store={UserDeckStore}
          queryStrings={{expanded: true, deck_id: this.props.deckId, user_id: this.props.userId}}
        />
      </div>
    );
  }
}

export default ErrorBoundary(UserDeckIndex);
