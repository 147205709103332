import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import LearnLayout from 'pages/main/learn_layout';
import ClassroomProgress from 'components/classroom/classroom_progress';

const Wrapper = styled.div`
    width: 100%;
    background: #FFF;
    padding: 20px;
    border-radius: 10px;
`;

@observer
class Classroom extends React.Component {

  render () {
    return (
      <LearnLayout>
        {
          AppUI.groupStore.hasShowData &&
            <Wrapper>
              {this.props.children}
            </Wrapper>
        }
        <ClassroomProgress />
      </LearnLayout>
    );
  }
}

export default ErrorBoundary(Classroom);
