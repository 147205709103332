import React from 'react';
import { observer } from 'mobx-react';
import { ConceptStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import styled from "@emotion/styled";
import cx from "classnames";
import { computed, observable } from "mobx";
import { isBlank } from '@seedlang/utils';
import { AppUI } from "@seedlang/state";

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
`;

@observer
class WordTagCreate extends React.Component {
  @observable name;
  @observable abbreviation;

  onClick() {
    if (!this.createButtonDisabled) {
      const data = {
        name: this.name,
        abbreviation: this.abbreviation,
        group_id: this.props.groupId,
        language_id: AppUI.site.languageId
      };
      ConceptStore.create({data: data}, this.afterCreate);
      this.refs.name.value = '';
      this.refs.abbreviation.value = '';
      this.name = '';
      this.abbreviation = '';
    }
  }

  @autobind afterCreate(resp) {
    this.props.afterChange();
  }

  @computed get createButtonDisabled() {
    return isBlank(this.name) || isBlank(this.abbreviation);
  }

  @autobind onKeyUp(e) {
    if (e.keyCode === 13 && !this.createButtonDisabled) {
      this.onClick();
    }
  }

  render() {
    return (
      <Wrapper>
        <div className="col-xs-6">
          <input
            placeholder="Enter Word Tag Name (eg. Imperfect Aspect)"
            ref="name"
            onKeyUp={this.onKeyUp}
            onChange={() => this.name = this.refs.name?.value}
            maxLength={40}
          />
        </div>
        <div className="col-xs-4">
          <input
            placeholder="Enter Word Tag Abbreviation (eg. imp)"
            ref="abbreviation"
            onKeyUp={this.onKeyUp}
            onChange={() => this.abbreviation = this.refs.abbreviation?.value}
            maxLength={10}
          />
        </div>
        <div className="col-xs-1">
          <button
            className={cx('button-primary', {
              disabled: this.createButtonDisabled
            })}
            onClick={this.onClick.bind(this)}
            style={{width: '100%'}}
          >
            Create
          </button>
        </div>
      </Wrapper>
    );
  }
}

export default WordTagCreate;
