import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Text from 'components/text';
import ShortcutBlock from 'components/shortcut_block';

const Wrapper = styled.div`
  background: #545454;
  height: 100%;
`;

@observer
class Shortcuts extends React.Component {

  render() {
    return (
      <Wrapper>
        <Text
          heading="2"
          textAlign="center"
          padding="10px 0 20px 0"
          color="#FFF"
        >
          Shortcuts
        </Text>
        <ShortcutBlock
          abbreviation="[1-4]"
          name="Button Selection"
          description='Clicking a number will select the corresponding button from left to right (or top left to bottom right).'
        />
        <ShortcutBlock
          abbreviation="[space]"
          name="Play / Pause Video with Audio"
        />
        <ShortcutBlock
          abbreviation="[a]"
          name="Start / Stop Audio Recording"
        />
        <ShortcutBlock
          abbreviation="[enter]"
          name="Go to Next Card"
          description='This is activated when a "Next" button has been enabled within a card.'
        />
        <ShortcutBlock
          abbreviation="[escape]"
          name="Close Overlay"
          description='When an overlay appears, it can be closed with the escape key.'
        />
        <ShortcutBlock
          abbreviation="[left arrow]"
          name="Go to Previous Card"
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Shortcuts);
