import React, { Children } from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator'
import { AppUI } from '@seedlang/state';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { pixify } from '@seedlang/utils';

const Wrapper = styled.div`
  width: ${(props) => props.width};
  overflow: ${(props) => props.overflow};
`;

const DropdownLink = styled.div`
  width: ${(props) => props.width};
  cursor:  ${(props) => props.cursor};
`;

const DropdownWrapper = styled.div`
  z-index: ${Theme.z["dropdown-foreground"]};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  width: ${(props) => props.width};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  text-align: ${(props) => props.textAlign};
  background: ${(props) => props.background};
  position: absolute;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
  li {
    background: ${(props) => props.background};
    font-weight: ${(props) => props.fontWeight};
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    user-select: none;
    color: black;
    width: 100%;
    margin-bottom: 0;
    cursor: pointer;
    &.on-click {
      padding: 10px 20px;
      font-size: 14px;
    }
    a {
      display: inline-block;
      width: 100%;
      padding: 10px 20px;
      font-size: 14px;
    }
    a:hover {
      color: white;
    }
  }
  li:hover {
    background: ${props => props.backgroundOnHover};
    color: ${props => props.colorOnHover};
    a {
      color: ${props => props.colorOnHover};
    }
  }
  a {
    color: black;
    text-decoration: none;
  }
  a:hover {
    color: black;
  }
`;

const DropdownBackground = styled.div`
  z-index: -1;
  position: fixed;
  pointer-events:initial;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const TEST_MODE = false;

@observer
class Dropdown extends React.Component {
  @observable active = false;

  @computed get useOnClickEvent() {
    return AppUI.layout.isMobile || this.props.showOnClick;
  }

  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: ({ children }, propName, componentName) => {
      if (Children.count(children) !== 2) {
        console.error(`Dropdown requires two children components in ${componentName}.`)
      }
    }
  }

  static defaultProps = {
    top: "40px",
    right: "0",
    background: "#FFF",
    fontWeight: 300,
    fontSize: '15px',
    textAlign: 'center',
    backgroundOnHover: Theme.darkestBlue,
    colorOnHover: 'white',
  }

  componentDidUpdate(prevProps) {
    if (this.props.closeTimestamp && this.props.closeTimestamp !== prevProps.closeTimestamp) {
      this.active = false;
    }
  }

  @autobind onMouseOver() {
    if (!this.useOnClickEvent) {
      clearTimeout(this._timeout); // for preventing overlapping events
      this.showDropdown();
    }
  }

  @autobind onMouseOut() {
    if (!this.useOnClickEvent) {
      this._timeout = setTimeout(this.hideDropdown, 350);
    }
  }

  @autobind onDropdownClick() {
    if (this.useOnClickEvent) {
      if (this.active && !TEST_MODE) {
        this.hideDropdown();
      } else {
        this.showDropdown()
      }
    }
  }

  @autobind showDropdown() {
    this.active = true;
  }

  @autobind hideDropdown() {
    this.active = false;
  }

  render() {
    return (
      <Wrapper
        width={this.props.linkWidth}
        overflow={this.props.linkOverflow}
      >
        <DropdownLink
          width={this.props.linkWidth}
          onClick={this.onDropdownClick}
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
          cursor={this.props.children.length > 1 ? 'pointer' : null}
        >
          {this.props.children[0]}
        </DropdownLink>
        {
          (this.active || TEST_MODE) &&
            <DropdownWrapper
              top={pixify(this.props.top)}
              width={this.props.dropdownWidth}
              right={pixify(this.props.right)}
              onMouseOver={this.onMouseOver}
              onMouseOut={this.onMouseOut}
              background={this.props.background}
              fontWeight={this.props.fontWeight}
              textAlign={this.props.textAlign}
              backgroundOnHover={this.props.backgroundOnHover}
            >
              {
                this.useOnClickEvent &&
                  <DropdownBackground
                    onClick={this.hideDropdown}
                  />
              }
              {this.props.children[1]}
            </DropdownWrapper>
        }
      </Wrapper>
    );
  }
}

export default Dropdown;
