import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';

@observer
class SegmentedTriangle extends React.Component {

  render() {
    return (
      <svg viewBox="0 0 481 168" version="1.1" xmlns="https://www.w3.org/2000/svg">
        <g>
          <path id="segment-1" d="M39,145.424901 L39,167.082031 L5.236927,167.082031 C3.09096531,167.082031 1.18431576,165.712664 0.498857214,163.679121 C-0.383190924,161.062357 1.02307388,158.22601 3.63983757,157.343961 L39,145.424901 Z"></path>
          <polygon id="segment-2" points="46 143.065369 87 129.245255 87 167.082031 46 167.082031 46 143.065369"></polygon>
          <polygon id="segment-3" points="135 113.065608 135 167.082031 94 167.082031 94 126.885723"></polygon>
          <polygon id="segment-4" points="183 96.8859622 183 167.082031 142 167.082031 142 110.706077"></polygon>
          <polygon id="segment-5" points="190 94.5264304 231 80.706316 231 167.082031 190 167.082031 190 94.5264304"></polygon>
          <polygon id="segment-6" points="279 64.5266698 279 167.082031 238 167.082031 238 78.3467843 279 64.5266698"></polygon>
          <polygon id="segment-7" points="327 48.3470236 327 167.082031 286 167.082031 286 62.1671381 327 48.3470236"></polygon>
          <polygon id="segment-8" points="334 45.9874919 375 32.1673775 375 167.082031 334 167.082031 334 45.9874919"></polygon>
          <polygon id="segment-9" points="423 15.9877313 423 167.082031 382 167.082031 382 29.8078457 423 15.9877313"></polygon>
          <path id="segment-10" d="M430,13.6281996 L467.41129,1.01775303 C468.440503,0.670830119 469.519359,0.493892615 470.605469,0.493892615 C476.128316,0.493892615 480.605469,4.97104512 480.605469,10.4938926 L480.605469,157.082031 C480.605469,162.604879 476.128316,167.082031 470.605469,167.082031 L430,167.082031 L430,13.6281996 Z"></path>
        </g>
      </svg>
    );
  }
}

export default ErrorBoundary(SegmentedTriangle);
