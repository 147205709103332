import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import Text from 'components/text';
import { pixify, isBlank, isPresent } from '@seedlang/utils';
import { FilterIcon } from '@seedlang/icons';
import { AppUI } from '@seedlang/state';
import Modal from 'components/modal';
import FilterOptions from 'components/filter_options';
import FilterTags from 'components/filter_tags';

const Wrapper = styled.div`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #FFF;
  border: 3px solid #CCC;
  border-radius: 5px;
  height: 46px;
  width: ${props => props.width};
  margin-bottom: ${props => props.marginBottom};
  margin-top: 10px;
`;

const FilterLabel = styled.div`
  width: 60px;
  padding-left: 10px;
  font-size: 12px;
  font-weight: bold;
  margin-right: 5px;
  cursor: pointer;
  color: #333;
  height: 19px;
  margin-top: ${props => props.marginTop};
`;

const Center = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
`;

const FilterIconWrapper = styled.div`
  width: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
  cursor: pointer;
  margin-top: ${props => props.marginTop};
  svg {
    fill: #333;
    width: 20px;
  }
`;

@observer
class Filter extends React.Component {

  render() {
    return (
      <Wrapper
        width={this.props.width}
        marginBottom={this.props.marginBottom}
      >
        {
          this.props.showFilterOptions &&
            <Modal
              width={pixify(Math.min(AppUI.layout.viewportWidth-50,400))}
              height={pixify(Math.min(AppUI.layout.viewportHeight-100,600))}
              onCloseModal={() => this.props.setShowFilterOptions(false)}
              gradient={AppUI.siteIsDefault || isBlank(AppUI.site.accentColor)}
              background={!AppUI.siteIsDefault && isPresent(AppUI.site.accentColor) ? AppUI.site.accentColor : null}
              padding="0"
              title={this.props.filterName}
              contentShouldScroll
            >
              <FilterOptions
                {...this.props}
              />
            </Modal>
        }
        <FilterLabel
          onClick={() => this.props.setShowFilterOptions(true)}
          marginTop={AppUI.siteIsDefault ? 0 : "-5px"}
        >
          Filters
        </FilterLabel>
        <Center>
          {
            Boolean(this.props.hasActiveFilter) &&
              <FilterTags
                // width={pixify(AppUI.layout.leftColumnWidth - 105)}
                // width="90%"
                filterSettings={this.props.filterSettings}
                onSetFilter={this.props.onSetFilter}
              />
          }
          {
            Boolean(!this.props.hasActiveFilter) &&
              <Text
                onClick={() => this.props.setShowFilterOptions(true)}
                fontSize="12px"
                lineHeight="15px"
                color="#777"
                cursor="pointer"
                marginTop="-1px"
              >
                {this.props.noFiltersSelectedMessage}
              </Text>
          }
        </Center>
        <FilterIconWrapper
          onClick={() => this.props.setShowFilterOptions(true)}
          marginTop={AppUI.siteIsDefault ? 0 : "5px"}
        >
          <FilterIcon
            width={20}
            height={20}
            fill="#444"
          />
        </FilterIconWrapper>
      </Wrapper>
    );
  }
}

export default Filter;
