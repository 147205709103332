import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import Prompt from 'components/exercise/prompt';
import { remove } from "lodash";
import autobind from "autobind-decorator";
import Text from 'components/text';

const Wrapper = styled.div`
  
`;

const ShowEnglishTranslation = styled.div`
  color: #696969;
  font-size: 12px;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
  i {
    margin-right: 5px;
    font-size: 10px;
  }
`;

@observer
class ExercisePrompt extends React.Component {
  @observable showEnglishTranslations = [];

  @autobind toggleEnglishTranslation(exerciseEntry) {
    if (this.englishTranslationIsToggled(exerciseEntry)) {
      remove(this.showEnglishTranslations, (item) => item === exerciseEntry.id);
    } else {
      this.showEnglishTranslations.push(exerciseEntry.id);
    }
  }

  @autobind englishTranslationIsToggled(exerciseEntry) {
    return this.showEnglishTranslations.indexOf(exerciseEntry.id) !== -1;
  }

  render() {
    return (
      <Wrapper>
        {
          this.props.exerciseEntry?.prompt && this.props.exerciseEntry?.promptIsEnglishTranslation &&
          <ShowEnglishTranslation onClick={() => this.toggleEnglishTranslation(this.props.exerciseEntry)} >
            {this.englishTranslationIsToggled(this.props.exerciseEntry) ? <i className='fa fa-minus' /> : <i className='fa fa-plus' />}
            <span className='underline'>{this.englishTranslationIsToggled(this.props.exerciseEntry) ? 'Hide English Translation' : 'Show English Translation'}</span>
          </ShowEnglishTranslation>
        }
        {
          this.props.exerciseEntry?.prompt && !(this.props.exerciseEntry?.promptIsEnglishTranslation && !this.englishTranslationIsToggled(this.props.exerciseEntry)) &&
          <Text onClick={() => this.toggleEnglishTranslation(this.props.exerciseEntry)} style={{marginTop: '5px'}}>
            {this.props.exerciseEntry?.prompt}
          </Text>
        }
      </Wrapper>
    );
  }
}

export default ExercisePrompt;
