import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  border-radius: ${(props) => props.borderRadius};
  font-size: 16px;
  line-height: 22px;
  background: whitesmoke;
  p {
    margin: 0;
  }
  a {
    text-decoration: underline;
  }
  ul {
    margin: 0 0 0 10px;
  }
  li {
    margin-bottom: 0;
  }
  ul ul, ul ol, ol ol, ol ul {
    font-size: 100%;
  }
  ul li {
    list-style-type: disc;
    margin: 0 0 0 10px;
  }
  ul br, ol br {
    display: none;
  }
  ul ul li {
    list-style-type: circle;
    margin: 0 0 0 10px;
  }
  table {
    width: 100%!important;
    max-width: 100%;
    margin-bottom: 20px;
    border-spacing: 0px!important;
    border: 1px solid #ccc;
    margin: 0;
  }
  table td {
    padding: 2px 5px;
  }
`;

@observer
class Description extends React.Component {

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
        padding={this.props.padding}
        borderRadius={this.props.borderRadius}
      >
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Description);
