"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = formatNumber;
var _is_present = _interopRequireDefault(require("./is_present"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function formatNumber(number) {
  if ((0, _is_present["default"])(number)) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}