import React from 'react';
import { observer } from 'mobx-react';
import Navigation from 'components/navigation';
import { last } from 'lodash';
import styled from '@emotion/styled';
import { admin } from '@seedlang/style_mixins';

const Wrapper = styled.div`
  ${admin()}
`;

@observer
class Classroom extends React.Component {

  render() {
    return (
      <Wrapper>
        <div className="builder">
          <Navigation
            currentRoute={last(this.props.routes).name}
            links={[
              {
                link: 'classroom.students',
                title: 'Students',
                params: {groupId: this.props.params.groupId},
              },
              {
                link: 'classroom.settings',
                title: 'Settings',
                params: {groupId: this.props.params.groupId},
              },
            ]}
          />
          {this.props.children}
        </div>
      </Wrapper>
    );
  }
}

export default Classroom;
