import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { isFunction } from "lodash";


const Wrapper = styled.div`
  background: #FFF;
  width: 100%;
  border-radius: 5px;
  background: whitesmoke;
  margin: ${props => props.margin};
`;

const Header = styled.div`
  background: #CCC;
  font-weight: bold;
  font-size: 12px;
  width: 100%;
  padding: 5px;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
  .fa {
    vertical-align: middle;
    position: absolute;
    left: 30px;
    margin-top: 5px;
  }
  @media (max-width: 899px) {
    .fa {
      left: 20px;
    }
  }
`;

@observer
class DeckFilters extends React.Component {

  static defaultProps = {
    margin: '0 0 10px 0',
  }

  render() {
    return (
      <Wrapper margin={this.props.margin}>
        <Header
          onClick={this.props.onClickHeader}
        >
          {
            isFunction(this.props.onClickHeader) && !this.props.isExpanded &&
              <i className='fa fa-plus' />
          }
          {
            isFunction(this.props.onClickHeader) && this.props.isExpanded &&
              <i className='fa fa-minus' />
          }
          {this.props.title}
        </Header>
        {this.props.children}
      </Wrapper>
    );
  }
}

export default DeckFilters;
