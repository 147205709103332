import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { css } from '@emotion/css'
import styled from '@emotion/styled';
import Text from 'components/text';
import { flexCenterColumn } from '@seedlang/style_mixins';

const Wrapper = styled.div`
  padding: ${(props) => props.padding || "120px 0"};
  background: ${(props) => props.background};
  margin: ${(props) => props.margin};
  ${
    props => props.center && flexCenterColumn()
  }
  ${(props) => props.backgroundImage && css`
    background-image: url('${props.backgroundImage}');
    background-position: center top;
    background-repeat: repeat-x;
    background-size: 100%;
  `}
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const Description = styled.div`
  max-width: 550px;
`;

const HeadlineWrapper = styled.div`
  ${flexCenterColumn()}
`;

@observer
class LandingStrip extends React.Component {

  static defaultProps = {
    margin: "-1px 0 0 0",
  }

  @computed get background() {
    if (this.props.lightBlue) {
      return "#e6f2f5";
    } else if (this.props.blue) {
      return "#cbe4ec";
    } else if (this.props.gray) {
      return "#efefef";
    } else if (this.props.darkGray) {
      return "#dadada";
    } else {
      return "#FFF";
    }
  }

  @computed get backgroundImage() {
    if (this.props.showWave) {
      return `/images/wave${this.props.showWave}.png`;
    }
    return null;
  }

  render() {

    return (
      <Wrapper
        margin={this.props.margin}
        padding={this.props.padding}
        background={this.background}
        backgroundImage={this.backgroundImage}
        center={this.props.center}
        className={this.props.className}
      >
        <Container>
          {
            this.props.headline &&
              <HeadlineWrapper>
                <Text
                  bold
                  fontSize="32px"
                  lineHeight="34px"
                  textAlign="center"
                  margin="20px 0"
                  color={this.props.headlineColor}
                >
                  {this.props.headline}
                </Text>
                {
                  this.props.description &&
                    <Description
                      className={this.props.descriptionClassName}
                    >
                      <Text
                        margin={this.props.descriptionMargin || "10px 10px 40px 10px"}
                        fontSize="16px"
                        lineHeight="22px"
                        color={this.props.descriptionColor}
                      >
                        {this.props.description}
                      </Text>
                    </Description>
                }
              </HeadlineWrapper>
          }
          {this.props.children}
        </Container>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(LandingStrip);
