import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Link } from 'react-router';
import InPlaceText from 'components/form/in_place_text';
import autobind from 'autobind-decorator';
import { FaqItemStore } from '@seedlang/stores';
import DeleteButton from 'components/button/delete_button';
import InPlaceCheckbox from 'components/form/in_place_checkbox';

const Wrapper = styled.div`

`;

@observer
class FaqItemIndex extends React.Component {

  @autobind onDelete(id) {
    FaqItemStore.destroy({ids: {faqItemId: id}}, this.props.afterChange);
  }

  render () {
    return (
      <Wrapper>
        <table className="table-wrapper admin-table">
          <thead>
            <tr>
              <th width="20" />
              <th width="80">Pos</th>
              <th>Title</th>
              <th>Description</th>
              <th>Views</th>
              <th>Published</th>
              <th width="40"></th>
            </tr>
          </thead>
          <tbody>
            {
              this.props.faqItems.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.faq_items.edit', params: {
                          faqCategoryId: this.props.faqCategoryId,
                          faqItemId: item.id
                        } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>
                      <InPlaceText
                        model="faq_items"
                        field="position"
                        id={item.id}
                        defaultValue={item.position}
                        afterChange={this.props.afterChange}
                      />
                    </td>
                    <td>
                      {item.title}
                    </td>
                    <td>
                      {item.description}
                    </td>
                    <td>
                      {item.viewsCount}
                    </td>
                    <td>
                      <InPlaceCheckbox
                        model="faq_items"
                        id={item.id}
                        value={item.published}
                        field="published"
                        icon="check"
                      />
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message="Delete this faq item?"
                        className="fa-block right"
                      />
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(FaqItemIndex);
