import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { StarWithCircleIcon } from '@seedlang/icons';
import Button from 'components/button/button';
import Spinner from 'components/spinner';
import { Motion, presets, spring } from 'react-motion';
import { Theme } from "@seedlang/constants/src";
import { VocabUI } from "@seedlang/state";
import { LearnedButtonWrapper } from '@seedlang/hoc';


const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  background: ${Theme.darkGreen};
  border-radius: 30px;
  
  .star-with-circle-icon {
    fill: #FFF;
    width: 20px;
    margin-right: 5px;
  }
`;

@observer
class LearnedButton extends React.Component {

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
        width={this.props.disableToggleButton ? '210px' : '240px'}
        style={this.props.style}
      >
        <Motion
          style={{
            scale: spring(this.props.animateScale, this.props.animateScale === 1 ? presets.wobbly : presets.stiff),
          }}
        >
          {
            ({scale}) =>
              <ButtonWrapper
                style={{
                  transform: `scale(${scale})`,
                }}
              >
                {
                  this.showSpinner &&
                  <Spinner />
                }
                {
                  !this.props.learned &&
                  <Button
                    width="100%"
                    height="40px"
                    onClick={this.props.onToggleLearned}
                    background={Theme.green}
                  >
                    <StarWithCircleIcon
                      backgroundColor="#FFF"
                      color={Theme.green}
                    />
                    Mark as Learned
                  </Button>
                }
                {
                  this.props.learned &&
                  <Button
                    width="100%"
                    height="40px"
                    background={`linear-gradient(-134deg, ${Theme.successCheckColor} -60%, ${Theme.green} 100%)`}
                    onClick={this.props.onToggleLearned}
                  >
                    <StarWithCircleIcon
                      backgroundColor="#FFF"
                      color={Theme.green}
                    />
                    Learned
                  </Button>
                }
              </ButtonWrapper>
          }
        </Motion>
      </Wrapper>
    );
  }
}

export default LearnedButtonWrapper(LearnedButton);
