import React from 'react';
import { observer } from 'mobx-react';
import { SettingsClearProgressWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import Alert from 'components/alert';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';
import DeleteButton from 'components/button/delete_button';
import Text from 'components/text';

const Wrapper = styled.div`

`;

@observer
class SettingsClearProgress extends React.Component {

  render() {
    return (
      <Wrapper>
        <Text
          heading="3"
          margin="0 0 10px 0"
        >
          Clear Progress
        </Text>
        <Text
          margin="0 0 20px 0"
        >
          If you would like to restart your progress for your currently selected language, click one of the buttons below.
        </Text>
        {
          this.props.message && this.props.showMessage &&
            <Alert
              textAlign="center"
            >
             {this.props.message}
            </Alert>
        }
        <DeleteButton
          allowDeletion
          left="310px"
          right="auto"
          confirmationMessageFloatsRight
          onConfirm={this.props.deleteAllReviews}
          message="Are you sure you want to delete all reviews?"
        >
          <Button
            background={Theme.red}
            margin="10px 0 10px 0"
          >
            Delete All Reviews
          </Button>
        </DeleteButton>
        <DeleteButton
          allowDeletion
          left="310px"
          right="auto"
          confirmationMessageFloatsRight
          message={<div><div>Are you sure you</div><div>want to reset your progress?</div></div>}
          onConfirm={this.props.clearStoriesProgress}
        >
          <Button
            background={Theme.red}
            margin="0 0 10px 0"
          >
            Clear Stories Progress
          </Button>
        </DeleteButton>
        <DeleteButton
          allowDeletion
          left="310px"
          right="auto"
          confirmationMessageFloatsRight
          message={<div><div>Are you sure you</div><div>want to reset your progress?</div></div>}
          onConfirm={this.props.clearVocabProgress}
        >
          <Button
            background={Theme.red}
            margin="0 0 10px 0"
          >
            Clear Vocab Progress
          </Button>
        </DeleteButton>
        <DeleteButton
          allowDeletion
          left="310px"
          right="auto"
          confirmationMessageFloatsRight
          message={<div><div>Are you sure you</div><div>want to reset your progress?</div></div>}
          onConfirm={this.props.clearGenderProgress}
        >
          <Button
            background={Theme.red}
            margin="0 0 10px 0"
          >
            Clear Gender Progress
          </Button>
        </DeleteButton>
        <DeleteButton
          allowDeletion
          left="310px"
          right="auto"
          confirmationMessageFloatsRight
          message={<div><div>Are you sure you</div><div>want to reset your progress?</div></div>}
          onConfirm={this.props.clearPluralProgress}
        >
          <Button
            background={Theme.red}
            margin="0 0 10px 0"
          >
            Clear Plural Progress
          </Button>
        </DeleteButton>
        <DeleteButton
          allowDeletion
          left="310px"
          right="auto"
          confirmationMessageFloatsRight
          message={<div><div>Are you sure you</div><div>want to reset your progress?</div></div>}
          onConfirm={this.props.clearNumberProgress}
        >
          <Button
            background={Theme.red}
            margin="0 0 10px 0"
          >
            Clear Number Progress
          </Button>
        </DeleteButton>
        <DeleteButton
          allowDeletion
          left="310px"
          right="auto"
          confirmationMessageFloatsRight
          message={<div><div>Are you sure you</div><div>want to reset your progress?</div></div>}
          onConfirm={this.props.clearConjugationProgress}
        >
          <Button
            background={Theme.red}
            margin="0 0 10px 0"
          >
            Clear Conjugation Progress
          </Button>
        </DeleteButton>
        <DeleteButton
          allowDeletion
          left="310px"
          right="auto"
          confirmationMessageFloatsRight
          message={<div><div>Are you sure you</div><div>want to reset your progress?</div></div>}
          onConfirm={this.props.clearTriviaProgress}
        >
          <Button
            background={Theme.red}
            margin="0 0 10px 0"
          >
            Clear Trivia Progress
          </Button>
        </DeleteButton>
      </Wrapper>
    );
  }
}

export default SettingsClearProgressWrapper(SettingsClearProgress);
