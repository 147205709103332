import React from 'react';

class HalfCircleCurvedArrow extends React.Component {

  render() {
    return (
      <div className='curved-arrow'>
        <svg viewBox="0 0 533 533" xmlns="http://www.w3.org/2000/svg">
        <g fill="none">
        <path d="M382.677 506.102c-99.284 48.08-222.22 30.92-304.621-51.481C-4.344 372.22-21.505 249.284 26.576 150l356.1 356.102z" className='background' />
        <path d="M88.183 270.564l68.37 70.705a5.158 5.158 0 01-.112 7.278 5.121 5.121 0 01-4.33 1.396l-66.853-10.019a5.116 5.116 0 00-1.516 0L16.89 349.943a5.134 5.134 0 01-5.834-4.33 5.157 5.157 0 011.392-4.344l68.369-70.705c2.012-2.04 5.221-2.09 7.255-.112.038.037.075.074.111.112z" className='arrow'/>
        <path d="M100.775 321c.792 36.395 9.944 61.418 26.66 76.078 17.047 14.951 44.028 22.912 81.428 23.15l1.137.005-.07 31.767c-45.031-.098-79.684-10.195-103.457-31.044-24.035-21.079-36.257-54.049-37.44-97.932L69 321.691l31.775-.691z" className='arrow' />
        <path d="M250.127 443.499l-70.145 68.145a5.1 5.1 0 01-7.22-.11 5.12 5.12 0 01-1.384-4.317l9.939-66.634a5.123 5.123 0 000-1.511l-9.94-66.634a5.113 5.113 0 014.296-5.814 5.1 5.1 0 014.31 1.387l70.144 68.145c2.024 2.006 2.073 5.205.11 7.232a5.111 5.111 0 01-.11.11z" className='arrow'/>
        <path d="M266.5 0C413.684 0 533 119.316 533 266.5S413.684 533 266.5 533 0 413.684 0 266.5 119.316 0 266.5 0zm0 1C119.868 1 1 119.868 1 266.5S119.868 532 266.5 532 532 413.132 532 266.5 413.132 1 266.5 1z" fill="none" />
        </g>
        </svg>
      </div>
    );
  }
}

export default HalfCircleCurvedArrow;
