import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import StoriesDeckLink from 'components/stories/stories_deck_link';
import { AppUI } from '@seedlang/state';
import { TreeNodeDecksWrapper } from "@seedlang/hoc";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

@observer
class TreeNodeDecks extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.decks && this.props.decks.map((item, index) => {
            if (item.deck.webPublished || AppUI.user.admin) {
              return (
                <StoriesDeckLink
                  fromGrammarSection={this.props.fromGrammarSection}
                  index={index}
                  calculateWidth={AppUI.layout.viewportWidth < 549}
                  key={item.id}
                  marginBottom="20px"
                  marginRight={this.props.marginRight}
                  deck={item.deck}
                  treeNodeId={this.props.treeNodeId}
                  userDeck={this.props.getUserDeck(item.deck.id)}
                />
              )
            } else {
              return null;
            }
          })
        }
      </Wrapper>
    );
  }
}

export default TreeNodeDecksWrapper(TreeNodeDecks);
