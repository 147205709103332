import React from 'react';
import { observer } from 'mobx-react';
import { computed } from "mobx";
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Checkbox from 'components/checkbox';
import { noop } from 'lodash';
import { isPresent, isBlank, pixify } from '@seedlang/utils';
import Text from 'components/text';
import VocabListWord from 'components/vocab/vocab_list_word';
import Paginator from 'components/paginator';
import Alert from 'components/alert';
import { AppUI, VocabUI } from '@seedlang/state';
import cx from 'classnames';
import Spinner from 'components/spinner';
import Button from 'components/button/button';
import InPlaceSelect from 'components/form/in_place_select';
import { Constants } from '@seedlang/constants';
import InPlaceText from 'components/form/in_place_text';

const Wrapper = styled.div`
`;

const ReviewOptionsWrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 0;
  margin-bottom: 10px;
  border: 2px solid #CCC;
  input {
    height: 30px!important;
    width: calc(100% - 40px)!important;
    margin-left: 20px;
  }
`;

@observer
class AddToReviews extends React.Component {


  static defaultProps = {
    confirmationMessage: "Your reviews were successfully created.",
  }

  render() {
    return (
      <Wrapper>
        {
          this.props.showReviewsCreatedMessage &&
            <Alert
              textAlign="center"
              width="100%"
            >
              <div dangerouslySetInnerHTML={{ __html: this.props.confirmationMessage }} />
            </Alert>
        }
        {
          isPresent(this.props.selectedWordIds) && isBlank(this.props.reviewDeckOptions) &&
          <ReviewOptionsWrapper>
            <Text
              fontSize="12px"
              bold
              margin="10px 0 0 0"
              color="#333"
            >
              Review Deck
            </Text>
            <InPlaceText
              allowUpdate
              show
              placeholder="Review Deck Name"
              defaultValue={VocabUI.newReviewDeckName}
              onChange={(val) => VocabUI.onSetNewReviewDeckName(val)}
            />
            <Button
              margin="10px 0 10px 0"
              onClick={() => VocabUI.onCreateNewReviewDeck(AppUI.loadReviewDecks)}
              disabled={isBlank(VocabUI.newReviewDeckName)}
            >
              <i className='fa fa-plus'/>
              Create Review Deck
            </Button>
          </ReviewOptionsWrapper>
        }
        {
          isPresent(this.props.selectedWordIds) && isPresent(this.props.reviewDeckOptions) &&
            <ReviewOptionsWrapper>
              <Text
                fontSize="12px"
                bold
                margin="10px 0 0 0"
                color="#333"
              >
                Review Deck
              </Text>
              <InPlaceSelect
                allowUpdate
                height="45px"
                options={this.props.reviewDeckOptions}
                value={this.props.deckId || AppUI.defaultReviewDeckId}
                onChange={(val) => this.props.onSetDeckId(val)}
              />
              <Text
                fontSize="12px"
                bold
                margin="10px 0 0 0"
                color="#333"
              >
                Card Type
              </Text>
              <InPlaceSelect
                allowUpdate
                height="45px"
                options={this.props.cardTypeOptions}
                value={this.props.cardTypeId}
                onChange={(val) => this.props.onSetCardTypeId(val)}
              />
              <Button
                margin="10px 0 10px 0"
                onClick={this.props.onCreateReviews}
                disabled={this.props.deckButtonDisabled}
              >
                <i className='fa fa-plus'/>
                Add to Reviews
              </Button>
              {
                this.props.selectedWordsExcludedFromGenderTrainer && this.props.cardTypeId === Constants.REVIEW_CARD_TYPES.find(item => item.slug === "gender")?.id &&
                <Alert
                  textAlign="center"
                  width="100%"
                >
                  Some nouns cannot be added as gender cards
                  (<i>{this.props.selectedWordsExcludedFromGenderTrainer.join(", ")}</i>).
                </Alert>
              }
              {
                this.props.selectedWordsExcludedFromPluralTrainer && this.props.cardTypeId === Constants.REVIEW_CARD_TYPES.find(item => item.slug === "plural")?.id &&
                <Alert
                  textAlign="center"
                  width="100%"
                >
                  Some nouns cannot be added as gender cards
                  (<i>{this.props.selectedWordsExcludedFromPluralTrainer.join(", ")}</i>).
                </Alert>
              }
              {
                this.props.selectedWordsExcludedFromConjugationTrainer && this.props.cardTypeId === Constants.REVIEW_CARD_TYPES.find(item => item.slug === "conjugation")?.id &&
                <Alert
                  textAlign="center"
                  width="100%"
                >
                  Some verbs cannot be added as conjugation cards at the moment
                  (<i>{this.props.selectedWordsExcludedFromConjugationTrainer.join(", ")}</i>).
                </Alert>
              }
            </ReviewOptionsWrapper>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(AddToReviews);
