import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { Theme } from '@seedlang/constants';
import { RightIcon } from '@seedlang/icons';
import { DeckUI } from '@seedlang/state';
import CardButton from 'components/card/card_button';

@observer
class RevealButton extends React.Component {

  render() {
    return (
      <CardButton
        submitOnEnter={this.props.submitOnEnter}
        shortcut={this.props.shortcut}
        text="Reveal"
        backgroundColor={Theme.darkOrange}
        icon={<RightIcon />}
        disabled={this.props.disabled}
        onClick={() => DeckUI.reveal(this.props.card, this.props.cardState)}
      />
    );
  }
}

export default ErrorBoundary(RevealButton);
