import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { LanguageIcon } from '@seedlang/icons';

const Wrapper = styled.div`
  display: flex;
  margin-right: 5px;
`;

const Country = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
`;

const Abbreviation = styled.div`
  color: white;
  position: absolute;
  font-size: 12px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 18px;
  width: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

@observer
class UserLevel extends React.Component {

  static defaultProps = {
    languageId: 'DE',
  }

  render() {
    return (
      <Wrapper>
        <Country>
          <LanguageIcon
              languageId={this.props.languageId}
          />
          <Abbreviation>
            {!this.props.hideAbbreviation && this.props.level.abbreviation}
          </Abbreviation>
        </Country>
      </Wrapper>
    );
  }
}

export default UserLevel;
