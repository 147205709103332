import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import Button from 'components/button/button';

const Wrapper = styled.div`
  border-bottom: 1px solid #d6d6d6;
  padding: 20px;
  cursor: pointer;
`;

const Answer = styled.div`
  background: white;
  line-height: 22px;
  font-size: 16px;
  padding: 5px;
  text-align: left;
  margin-top: 10px;
`;

const Question = styled.div`
  text-align: left;
  line-height: 22px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
`;

@observer
class FaqItem extends React.Component {
  @observable showAnswer = false;

  render() {
    return (
      <Wrapper
        onClick={() => this.showAnswer = true}
      >
        <Question>
          {this.props.question}
        </Question>
        {
          this.showAnswer &&
            <Answer>
              {this.props.answer}
              {
                this.props.url &&
                  <ButtonWrapper>
                    <a href={this.props.url}>
                      <Button>
                        {this.props.buttonText}
                      </Button>
                    </a>
                  </ButtonWrapper>
              }
            </Answer>
        }
      </Wrapper>
    );
  }
}

export default FaqItem;
