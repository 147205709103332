import React from 'react';
import { observer } from 'mobx-react';
import { isString } from 'lodash';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  cursor: ${(props) => props.cursor};
  height: 100%;
`;

const Title = styled.div`
  background: #585858;
  color: white;
  padding: 10px;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: bold;
  position: relative;
  .fa-info-circle {
    font-size: 18px;
    top: 10px;
    left: 10px;
    position: absolute;
  }
`;

const Body = styled.div`
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
`;

@observer
class OnboardingPopup extends React.Component {

  static defaultProps = {
    width: "300px",
  }

  render() {
    return (
      <Wrapper
        cursor={this.props.onClick ? "pointer" : "default"}
      >
        <Title>
          <i className='fa fa-info-circle' />
          {this.props.title}
        </Title>
        <Body>
          {
            isString(this.props.message) &&
              <div
                dangerouslySetInnerHTML={{ __html: this.props.message }}
              />
          }
          {
            !isString(this.props.message) && this.props.message
          }
        </Body>
      </Wrapper>
    );
  }
}

export default OnboardingPopup;
