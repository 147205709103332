import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import TextDisplay from 'components/text_display';

const Wrapper = styled.div`
    padding: 10px;
    li {
        list-style-type: circle;
        margin: 0 0 0 50px;
    }
`;

@observer
class Privacy extends React.Component {

  constructor(props) {
    super(props);
    AppUI.pageStore.getShow({ids: {pageId: 'privacy'}});
  }

  componentWillUnmount() {
    AppUI.pageStore.clearShowData();
  }

  render() {
    return (
      <Wrapper>
        {
          AppUI.pageStore.hasShowData &&
            <TextDisplay
              markdown={AppUI.pageStore.showData.content}
            />
        }
      </Wrapper>
    );
  }
}

export default Privacy;
