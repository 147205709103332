import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexCenter, flexCenterColumn } from '@seedlang/style_mixins';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  img {
    height: 16px;
    margin-right: 2px;
  }
`;

const Selection = styled.div`
  ${flexCenter()}
  background: #FFF;
  color: #000;
  border: ${(props) => props.selected ? '1px solid #000' : '1px solid #D1D1D1'};
  cursor: pointer;
  width: 100%;
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
`;

const Option = styled.div`
  ${flexCenter()}
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: ${(props) => props.selected ? '1px solid #D1D1D1' : '1px solid #D1D1D1'};
  margin-right: 10px;
`;

const CenterCircle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #535b86;
`;

const Text = styled.div`
  flex: 1;
`;

@observer
class PaymentOption extends React.Component {

  render() {
    return (
      <Wrapper>
        <Selection
          selected={this.props.selected}
          onClick={this.props.onClick}
        >
          <Option
            selected={this.props.selected}
          >
            {this.props.selected && <CenterCircle />}
          </Option>
          {
            this.props.image &&
              <img
                src={this.props.image}
                alt=""
              />
          }
          <Text>
            {this.props.text}
          </Text>
        </Selection>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(PaymentOption);
