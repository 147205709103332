import React from 'react';
import { observer } from 'mobx-react';
import { DeckUI } from '@seedlang/state';
import styled from '@emotion/styled';
import Text from 'components/text';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { DeckCloseWebWrapper } from '@seedlang/hoc';

const Wrapper = styled.div`
  ${flexCenterColumn()}
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  flex: 1;
  padding: 0 5px;
`;

@observer
class DeckClose extends React.Component {

  render() {
    return (
      <Wrapper>
        <Text
          heading="3"
          margin="0 0 20px 0"
        >
          Close this Deck?
        </Text>
        <ButtonGroup>
          <ButtonWrapper>
            <Button
              onClick={this.props.onCloseDeck}
              background={Theme.green}
              width="100%"
            >
              <i className='fa fa-check' />
              Confirm
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              onClick={() => DeckUI.showCloseModal = false}
              background={Theme.red}
              width="100%"
            >
              <i className='fa fa-times' />
              Cancel
            </Button>
          </ButtonWrapper>
        </ButtonGroup>
      </Wrapper>
    );
  }
}

export default DeckCloseWebWrapper(DeckClose);
