import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import ReviewDetails from 'components/card/review_details';

const Wrapper = styled.div`
  width: 100%;
`;

@observer
class ReviewShow extends React.Component {

  render() {
    return (
      <Wrapper>
        <ReviewDetails
          cardId={this.props.params.cardId}
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ReviewShow);
