import React from 'react';
import { observer } from 'mobx-react';
import { CustomTabStore, SiteStore, RouteStore } from '@seedlang/stores';
import { observable } from 'mobx';
import { AppUI } from '@seedlang/state';

@observer
class CustomTabCreate extends React.Component {
  @observable siteId = '';

  componentDidMount() {
    SiteStore.getIndex();
  }

  onClick() {
    CustomTabStore.create(
      {
        data: {
          title: this.refs.title.value,
          site_id: AppUI.site.id,
        },
      }, this.afterCreate.bind(this));
    this.refs.title.value = '';
  }

  onSelect(e) {
    this.siteId = e.target.value;
  }

  afterCreate(resp) {
    if (resp.customTabId && this.props.groupId) {
      RouteStore.routeToNamed("creator.groups.custom_tabs.edit", {customTabId: resp.customTabId, groupId: this.props.groupId});
    } else {
      this.error = true;
    }
  }

  render() {
    return (
      <div className="custom tab-create">
        {
          this.error &&
          <div className='row'>
            <div className="col-xs-12">
              <div className='alert'>Sorry, there was an error</div>
            </div>
          </div>
        }
        <input
          placeholder="Add a custom tab title"
          ref="title"
        />
        <div>
          <button
            style={{marginTop: '10px'}}
            className="button-primary"
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default CustomTabCreate;
