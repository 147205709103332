import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import Text from 'components/text';
import { Theme } from '@seedlang/constants';
import { isBlank, isPresent } from '@seedlang/utils';
import Checkbox from 'components/checkbox';
import RadioButton from 'components/radio_button';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Option = styled.div`
  padding: 10px 0;
  width: 100%;
  border: 0px solid #CCC;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  &:hover {
    background: whitesmoke;
  }
`;

const IconWrapper = styled.div`
  width: 35px;
  .check-with-circle-icon {
    svg {
      fill: ${Theme.green};
      width: 20px;
      height: 20px;
    }
  }
`;

const TextWrapper = styled.div`
  flex: 1;
  padding-left: 10px;
`;

@observer
class ListPicker extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          Boolean(this.props.includeBlank) &&
            <Option
              onClick={() => this.props.onSetFilter(this.props.userKey, null)}
            >
              <TextWrapper>
                <Text
                  fontSize={14}
                  lineHeight={18}
                  color="#333"
                >
                  Any
                </Text>
              </TextWrapper>
              <IconWrapper>
                {
                  this.props.multiSelect &&
                    <Checkbox
                      value={isBlank(this.props.value)}
                    />
                }
                {
                  !this.props.multiSelect &&
                    <RadioButton
                      value={isBlank(this.props.value)}
                    />
                }
              </IconWrapper>
            </Option>
        }
        {
          this.props.options.map(item => {
            const checked = ((this.props.multiSelect && isPresent(this.props.value) && this.props.value.indexOf(item[0]) !== -1) || (item[0] === this.props.value) || (isBlank(item[0]) && isBlank(this.props.value)));
            return (
              <Option
                padding={this.props.padding}
                onClick={() => this.props.onSetFilter(this.props.userKey, item[0])}
                key={item[0]}
              >
                <TextWrapper>
                  <Text
                    fontSize={14}
                    lineHeight={18}
                  >
                    {item[1]}
                  </Text>
                </TextWrapper>
                <IconWrapper>
                  {
                    this.props.multiSelect &&
                      <Checkbox
                        value={checked}
                      />
                  }
                  {
                    !this.props.multiSelect &&
                      <RadioButton
                        value={checked}
                      />
                  }
                </IconWrapper>
              </Option>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ListPicker;
