import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import ExerciseInputTable from 'pages/creator/exercises/exercise_input_table';

@observer
class ExerciseMatchMedia extends React.Component {

  render() {
    return (
      <div className='exercise-match-media'>
        <ExerciseInputTable
          showMediaOptions
          {...this.props}
        />
      </div>
    );
  }
}

export default ErrorBoundary(ExerciseMatchMedia);

