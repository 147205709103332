import React from 'react';
import { observer } from 'mobx-react';
import { FaqItemShowWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import TextDisplay from 'components/text_display';
import FaqHeader from 'components/faq_header';
import { Link } from 'react-router';
import { AppUI, ForumUI } from '@seedlang/state';
import { Theme } from '@seedlang/constants';
import Spinner from 'components/spinner';
import { isPresent, isBlank } from '@seedlang/utils';
import CommentCreate from 'components/comment/comment_create';
import CommentIndex from 'components/comment/comment_index';

const Wrapper = styled.div`

`;

const Body = styled.div`
  background: #FFF;
  padding: 20px;
`;

const AdminLink = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;
  text-decoration: underline;
`;

const CommentsWrapper = styled.div`
  padding: 20px;
  background: #FFF;
  margin-top: 10px;
`;

@observer
class FaqItemShow extends React.Component {

  render () {
    return (
      <Wrapper>
        <FaqHeader
          currentRoute="faq_items.show"
          title={this.props.faqItem.titleWithPublished}
          faqCategoryName={this.props.faqCategory?.name}
          faqCategoryId={this.props.params.faqCategoryId}
        />
        <Body>
          {
            isBlank(this.props.faqItem) &&
              <Spinner
                background={Theme.blue}
              />
          }
          {
            isPresent(this.props.faqItem) &&
              <TextDisplay
                fontSize="14px"
                markdown={this.props.faqItem.body || ''}
              />
          }
        </Body>
        <CommentsWrapper>
          <CommentCreate
            namespace={this.props.faqItem.id}
            commentable={ForumUI.forumThread}
            commentableType="ForumThread"
            afterCreateComment={this.props.afterCreateComment}
            faqItemId={this.props.faqItem.id}
          />
          {
            this.props.showSpinner &&
              <Spinner
                className="blue"
              />
          }
          {
            !this.props.showSpinner &&
              <CommentIndex
                languageId="DE"
                showCommentCount
                indentation={20}
                user={AppUI.user}
                onChange={this.props.afterEmbeddedCommentCreate}
                commentable={this.props.forumThread}
                commentableType="ForumThread"
                commentMargin="10px 0"
                margin={this.props.commentMargin}
                padding="0"
              />
          }
        </CommentsWrapper>
        {
          AppUI.user.admin &&
            <AdminLink>
              <Link
                to={{name: 'builder.faq_items.edit', params: {faqCategoryId: this.props.params.faqCategoryId, faqItemId: this.props.params.faqItemId}}}
              >
                Admin Link
              </Link>
            </AdminLink>
        }
      </Wrapper>
    );
  }
}

export default FaqItemShowWrapper(FaqItemShow);
