import React from 'react';
import { Link } from 'react-router';

class TranslationIndex extends React.Component {

  static defaultProps = {
    hide: [],
  }

  linkParams(item) {
    if (item.type === 'Sentence') {
      return { name: 'builder.sentences.edit', params: { sentenceId: item.id } };
    } else if (item.type === 'Word') {
      return { name: 'builder.words.edit', params: { wordId: item.id } };
    }
  }

  render() {
    return (
      <table className='table-wrapper translation-index'>
        <thead>
          <tr>
            <th width='20' />
            <th>Target</th>
            <th>Type</th>
            {
              !this.props.hide.includes('root') &&
                <th>Root</th>
            }
            <th>Gender</th>
          </tr>
        </thead>
        <tbody>
          {
            this.props.translations.map((item) => {
              return (
                <tr key={item.id}>
                  <td>
                    <Link
                      to={this.linkParams(item)}
                      className="on-click"
                    >
                      <i className="fa fa-chevron-right fa-block" />
                    </Link>
                  </td>
                  <td>
                    <div className='text'>
                      {item.targetTextWithDefiniteArticle || item.targetText}
                    </div>
                  </td>
                  <td>
                    <div className='text'>
                      {item.wordType ? item.wordType.name : item.type}
                    </div>
                  </td>
                  {
                      !this.props.hide.includes('root') &&
                        <td>
                          {
                              item.root &&
                              <i className='fa fa-check' />
                          }
                          {
                              !item.root && this.props.targetType === "Word" && item.rootWord &&
                              <Link
                                  to={this.linkParams(item)}
                              >
                                {item.rootWord.targetText}
                              </Link>
                          }
                        </td>
                  }
                  <td>
                    {
                        this.props.targetType === "Word" &&
                        <div className='text'>
                          {item.gender}
                        </div>
                    }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    );
  }
}

export default TranslationIndex;
