import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { LockIcon } from '@seedlang/icons';
import { flexCenter } from '@seedlang/style_mixins';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`
  ${flexCenter()}
  width: ${props => props.width};
  height: ${props => props.height};
  margin: ${props => props.margin};
  border-radius: 3px;
  border: 2px solid ${(props) => props.value ? props.selectedBackground : "#d8d8d8"};
  cursor: pointer;
  background: ${(props) => props.value ? props.selectedBackground : props.background};
  color: white;
  font-size: 12px;
  &:hover, &:active, &:focus {
    background: ${(props) => props.value ? props.selectedBackgroundHover : props.backgroundHover};
    border: 2px solid ${(props) => props.selected ? "#000" : "#7d7c7c"};
  }
  .icon-wrapper {
    height: 8px;
    margin-top: -4px;
  }
  svg {
    width: 8px;
    fill: #adadad;
  }
`;

@observer
class Checkbox extends React.Component {

  static defaultProps = {
    background: "#FFF",
    selectedBackground: Theme.blue,
    backgroundHover: 'whitesmoke',
    selectedBackgroundHover: "#000",
    width: "22px",
    height: "22px",
  }

  @autobind handleKeyUp(e) {
    if (e.key === ' ') {
      this.props.onClick();
    }
  }

  render() {
    return (
      <Wrapper
        width={this.props.width}
        height={this.props.height}
        onClick={this.props.onClick}
        value={this.props.value}
        margin={this.props.margin}
        background={this.props.background}
        selectedBackground={this.props.selectedBackground}
        backgroundHover={this.backgroundHover}
        selectedBackgroundHover={this.selectedBackgroundHover}
        role="checkbox"
        ariaChecked={this.props.value}
        tabIndex="0"
        onKeyUp={this.handleKeyUp}
      >
        {
          this.props.value && !this.props.showLock &&
            <i className='fa fa-check' />
        }
        {
          this.props.showLock &&
            <LockIcon />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Checkbox);
