import React from 'react';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { CustomTabStore } from '@seedlang/stores';
import DeleteButton from 'components/button/delete_button';
import Paginator from 'components/paginator';
import Modal from 'components/modal';
import { Link } from 'react-router';
import CheckIfTrue from 'components/check_if_true';
import CustomTabCreate from "pages/creator/site/custom_tab_create";
import InPlaceText from "components/form/in_place_text";
import BackEndExplainer from "pages/creator/back_end_explainer";

@observer
class CustomTabIndex extends React.Component {
  static defaultProps = {
    whiteLabeled: false
  }

  @observable showCreate = false;

  componentDidMount() {
    CustomTabStore.getIndex({filters: {worksheet_id: null}});
  }

  componentWillUnmount() {
    CustomTabStore.clearIndexData();
  }

  onDelete(id) {
    CustomTabStore.destroy({ ids: { customTabId: id } });
  }

  @computed get customTabs() {
    return this.props.whiteLabeled ? CustomTabStore.indexData.filter(item => item.siteId === this.props.siteId) : CustomTabStore.indexData;
  }

  render() {
    return (
      <div className="custom-tab-index">
        {
          !this.props.hideCreateButton && this.showCreate &&
          <Modal
            onCloseModal={() => this.showCreate = false}
          >
            <CustomTabCreate
              groupId={this.props.params.groupId}
            />
          </Modal>
        }
        <BackEndExplainer>
          Custom tabs can be displayed in worksheets, along with the vocab, exercises, downloads, etc. to selected groups of users.
        </BackEndExplainer>
        <div style={{display: 'flex', marginBottom: '10px'}}>
          <div style={{width: 150}}>
            {
              !this.props.hideCreateButton && (
                <button
                  className='gray-button'
                  onClick={() => this.showCreate = true}
                >
                  <i className='fa fa-plus' />
                  Create
                </button>
              )
            }
          </div>
        </div>
        <table className="table-wrapper admin-table">
          <thead>
          <tr>
            <th width="40"></th>
            <th width="40"></th>
            <th>Title</th>
            <th>Icon</th>
            <th>Slug</th>
            <th>Memberships</th>
            <th>Published</th>
            <th width="40"></th>
          </tr>
          </thead>
          <tbody>
          {
            this.customTabs.map(item => {
              return (
                <tr key={item.id}>
                  <td>
                    <Link
                      to={{ name: 'creator.groups.custom_tabs.edit', params: { customTabId: item.id, groupId: this.props.params.groupId } }}
                      className="on-click"
                    >
                      <i className='fa fa-chevron-right fa-block' />
                    </Link>
                  </td>
                  <td>
                    <InPlaceText
                      id={item.id}
                      defaultValue={item.position}
                      field="position"
                      model="custom_tabs"
                      type="number"
                      afterChange={() => CustomTabStore.getIndex()}
                    />
                  </td>
                  <td>
                    <div className="text">
                      {item.title}
                    </div>
                  </td>
                  <td>
                    <div>
                      <i className={`fa fa-${item.icon}`} />
                    </div>
                  </td>
                  <td>
                    <div className="text">
                      {item.slug}
                    </div>
                  </td>
                  <td>
                    <div className="text">
                      {item.membershipTypes.map(item => item.name).join(",")}
                    </div>
                  </td>
                  <td>
                    <CheckIfTrue
                      value={item.published}
                    />
                  </td>
                  <td>
                    <DeleteButton
                      onConfirm={this.onDelete.bind(this, item.id)}
                      message="Delete this Custom Tab?"
                      className="fa-block right"
                    />
                  </td>
                </tr>
              );
            })
          }
          </tbody>
        </table>
        <Paginator
          store={CustomTabStore}
        />
      </div>
    );
  }
}

export default CustomTabIndex;
