import React from 'react';
import { observer } from 'mobx-react';
import { SettingsCancelWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import Text from 'components/text';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import Button from 'components/button/button';
import { isBlank, isPresent } from '@seedlang/utils';
import { CheckWithCircleIcon } from '@seedlang/icons';
import { Theme } from '@seedlang/constants';
import Alert from 'components/alert';
import Spinner from 'components/spinner';

const Wrapper = styled.div`
  .fa-angle-left {
    margin: 0 4px 0 0;
    font-size: 20px;
  }
`;

const Option = styled.div`
  background: ${(props) => props.selected ? 'whitesmoke' : '#FFF'};
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid whitesmoke;
  cursor: pointer;
  .check-with-circle-icon {
    margin-right: 10px;
    svg {
      fill: ${(props) => props.selected ? Theme.green : '#CCC'};
    }
  }
  &:hover {
    background: whitesmoke;
  }
`;

const Message = styled.div`
  textarea {
    height: ${(props) => props.expanded ? 100 : 40}px;
  }
`;

@observer
class SettingsCancel extends React.Component {

  @autobind afterSubmit() {
    AppUI.routeStore.routeToNamed('settings.billing');
  }

  render() {
    if (this.props.mostRecentSubscription) {
      return (
        <Wrapper>
          <Link
            to={{name: 'settings.billing'}}
            style={{display: 'flex', alignItems: 'center'}}
          >
            <i className='fa fa-angle-left' />
            Back to Billing
          </Link>
          {
            this.props.showSpinner &&
              <Spinner
                className={AppUI.siteIsDefault ? "blue" : null}
                background={AppUI.siteIsDefault || isBlank(AppUI.site.accentColor) ? null : AppUI.site.accentColor}
              />
          }
          {
            this.props.showMessage &&
              <Alert
                textAlign="center"
                margin="10px 0"
              >
                Your membership has been cancelled.
              </Alert>
          }
          <Text
            heading="3"
            margin="20px 0 0 0"
          >
            We are sorry to see you go!
          </Text>
          <Text
            margin="10px 0 20px 0"
          >
            Could you do us a favor and let us know why you are cancelling your membership?
          </Text>
          {
            this.props.options.map(item => {
              return (
                <Option
                  key={item.slug}
                  onClick={() => this.props.onSetSelected(item)}
                  selected={this.props.selected && this.props.selected.slug === item.slug}
                >
                  <CheckWithCircleIcon />
                  <Text>
                    {item.option}
                  </Text>
                </Option>
              )
            })
          }
          {
            isPresent(this.props.selected) && isPresent(this.props.selected.expanded) &&
              <Message
                expanded={this.props.expandedTextArea}
              >
                <Text
                  bold
                  margin="20px 0 0 0"
                >
                  {this.props.selected.expanded}
                </Text>
                <textarea
                  onFocus={this.props.onExpandTextArea}
                  ref="message"
                />
              </Message>
          }
          <Button
            margin="20px 0 0 0"
            disabled={isBlank(this.props.selected)}
            onClick={() => this.props.onSubmit(this.afterSubmit)}
          >
            Cancel Membership
          </Button>
        </Wrapper>
      );
    }
    return <span />
  }
}

export default SettingsCancelWrapper(SettingsCancel);
