import React from 'react';
import { observer } from 'mobx-react';
import { ReviewToggleWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { CheckWithCircleIcon, ThreeDotsIcon } from '@seedlang/icons';
import Button from 'components/button/button';
import { AppUI } from '@seedlang/state';
import Modal from 'components/modal';
import ReviewToggleOptions from 'components/review/review_toggle_options';
import Spinner from 'components/spinner';
import { Motion, presets, spring } from 'react-motion';
import { pixify } from '@seedlang/utils';

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  background: #1699b7;
  border-radius: 30px;
  i {
    margin-right: 5px;
    margin-top: 1px;
    font-size: 14px;
  }
  .check-with-circle-icon svg {
    fill: #FFF;
    width: 20px;
    margin-right: 5px;
  }
`;

const IconWrapper = styled.div`
  width: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  .three-dots-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    width: 4px;
    fill: rgba(255, 255, 255, 0.8);
  }
`;

@observer
class ReviewToggle extends React.Component {

  static defaultProps = {
    buttonHeight: "38px",
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
        width={this.props.disableToggleButton ? '210px' : '240px'}
        style={this.props.style}
      >
        {
          this.props.modalVisible &&
            <Modal
              onCloseModal={() => this.props.setModalVisible(false)}
              top="50px"
              width={pixify(AppUI.layout.defaultModalWidth)}
              gradient
              title={this.props.title}
            >
              <ReviewToggleOptions
                {...this.props}
              />
            </Modal>
        }
        <Motion
          style={{
            scale: spring(this.props.animateScale, this.props.animateScale === 1 ? presets.wobbly : presets.stiff),
          }}
        >
          {
            ({scale}) =>
              <ButtonWrapper
                style={{
                  transform: `scale(${scale})`,
                }}
              >
                {
                  this.showSpinner &&
                    <Spinner />
                }
                {
                  !this.showSpinner && !this.props.hasReviews &&
                    <Button
                      width="210"
                      height="40px"
                      onClick={this.props.onToggleCreateReview}
                    >
                      <i className='fa fa-plus' />
                      {this.props.buttonText}
                    </Button>
                }
                {
                  !this.showSpinner && this.props.hasReviews &&
                    <Button
                      width="210"
                      height="40px"
                      background="linear-gradient(-134deg, rgba(59, 215, 197, 0.8) 0%, #0DAFF6 100%)"
                      cursor={this.props.disableToggleButton ? 'default' : 'pointer'}
                      onClick={this.props.onToggleCreateReview}
                    >
                      <CheckWithCircleIcon />
                      Reviewing
                    </Button>
                }
                {
                  !this.props.disableToggleButton &&
                    <IconWrapper
                      onClick={() => this.props.setModalVisible(true)}
                    >
                      <ThreeDotsIcon />
                    </IconWrapper>
                }
              </ButtonWrapper>
          }
        </Motion>
      </Wrapper>
    );
  }
}

export default ReviewToggleWrapper(ReviewToggle);
