import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import YoutubeVideo from 'components/youtube_video';
import PodcastButton from "components/worksheet/podcast_button";
import { isPresent } from "@seedlang/utils/src";
import { AppUI } from "@seedlang/state";

const Wrapper = styled.div`
  flex: 2;
  position: relative;
  height: fit-content;
  font-size: 16px;
  width: ${props => props.width};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: white;
  border-radius: ${props => props.borderRadius};
  @media (max-width: 999px) {
    order: -1;
    width: 100%;
  }
  iframe, .youtube-video {
    border-radius: 10px;
    overflow: hidden;
  }
  .icon-wrapper {
    display: inline-block;
    margin: 4px 4px 0 0;
  }
  .youtube-icon {
    display: inline-block;
    svg {
      width: 24px;
      fill: #c4302b;
    }
  }
`;

@observer
class YoutubePlayer extends React.Component {

  static defaultProps = {
    width: '600px',
  }

  render() {
    return (
      <Wrapper
        width={this.props.show ? this.props.width : 'fit-content'}
        borderRadius={this.props.borderRadius}
      >
        <YoutubeVideo
          url={this.props.url}
          placeholderImageUrl={this.props.placeholderImageUrl}
        />
        {
          isPresent(this.props.podcastEpisode) && this.props.hasAccess &&
            <PodcastButton
              hasAccess={this.props.hasAccess}
              width={AppUI.layout.isMobile ? '360px' : '540px'}
              margin="10px 0 0 0"
              height="50px"
              podcastEpisode={this.props.podcastEpisode}
            />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(YoutubePlayer);
