import React from 'react';
import { observer } from 'mobx-react';
import { UserSubmissionCardWrapper } from '@seedlang/hoc';
import { Constants } from '@seedlang/constants';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import CardButtons from 'components/card/card_buttons';
import RevealButton from 'components/button/reveal_button';
import EvaluateButtons from 'components/card/evaluate_buttons';
import { DeckUI } from '@seedlang/state';
import CardSection from 'components/vocab_card/card_section';
import Text from 'components/text';
import TextResizer from 'components/text_resizer';

@observer
class UserSubmissionCard extends React.Component {

  render() {
    return (
      <CardWrapper
        card={this.props.card}
        cardState={this.props.cardState}
        userCard={this.props.userCard}
        icons={this.props.icons}
      >
        <CardContent
          scroll
        >
          <CardSection
            background="#FFF"
            borderTopRadius={10}
            numSections={2}
          >
            <TextResizer
              resizeText
              height={100}
              width={DeckUI.layout.innerDeckWidth - 80}
              characterLength={this.props.card.frontText?.length}
            >
              <Text>
                {this.props.card.frontText}
              </Text>
            </TextResizer>
          </CardSection>
          <CardSection
            color="#FFF"
            borderBottomRadius={10}
            background="#CCC"
            numSections={2}
          >
            {
              this.props.cardState.revealed &&
                <TextResizer
                  resizeText
                  height={(DeckUI.layout.contentHeight / 2) - 20}
                  width={DeckUI.layout.innerDeckWidth - 40}
                  characterLength={this.props.card.backText?.length}
                >
                  <Text>
                    <div
                      dangerouslySetInnerHTML={{ __html: this.props.card.backTextWithBr }}
                    />
                  </Text>
                </TextResizer>
            }
          </CardSection>
        </CardContent>
        {
          !this.props.cardState.revealed &&
            <CardButtons>
              <RevealButton
                card={this.props.card}
                userCard={this.props.userCard}
                cardState={this.props.cardState}
                disabled={!this.props.inView}
                submitOnEnter
                shortcut="1"
              />
            </CardButtons>
        }
        {
          this.props.cardState.revealed &&
            <EvaluateButtons
              card={this.props.card}
              cardState={this.props.cardState}
              userCard={this.props.userCard}
              disabled={!this.props.inView}
              onNextClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard, {score: Constants.EASY_SCORE, xp: DeckUI.adjustedDefaultXp, xpXPos: DeckUI.layout.quarterDeckWidth * 1})}
            />
        }
      </CardWrapper>
    );
  }
}

export default UserSubmissionCardWrapper(UserSubmissionCard);
