import React from 'react';
import { observer } from 'mobx-react';
import SiteEdit from 'pages/builder/sites/site_edit';

@observer
class SiteLayout extends React.Component {

  render() {
    return (
      <SiteEdit
        {...this.props}
        whiteLabeled
      />
    );
  }
}

export default SiteLayout;
