import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Link } from 'react-router';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';
import Text from 'components/text';

const Wrapper = styled.div`

`;

const Message = styled.div`
  border-radius: 5px;
  margin: 5px 0 15px 0;
  color: ${Theme.blue};
  padding: 20px;
  border: 2px solid ${Theme.blue};
`;

const ButtonText = styled.div`
  width: 280px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.color};
  .fa-arrow-right {
    transform: rotate(-45deg);
  }
  i {
    position: absolute;
    left: -5px;
    top: 16px;
    color: ${props => props.color};
  }
`;


@observer
class ReviewDeckNoCards extends React.Component {

  render () {
    return (
      <Wrapper>
        <Message>
          <Text
            color={Theme.blue}
            bold
          >
            You haven't added any cards to this deck yet.
          </Text>
          <Text
            color={Theme.blue}
            fontSize={13}
            lineHeight={16}
            marginTop={5}
          >
            You can find words and sentences to add to your reviews from our Stories, Vocab, and Trivia sections.
          </Text>
        </Message>
        <Link
          to={{name: 'reviews.create'}}
        >
          <Button
            height="50px"
            width="100%"
            background={Theme.blue}
            margin="0 0 10px 0"
          >
            <ButtonText
              color="#FFF"
            >
              <i className='fa fa-arrow-right' />
              Create a Custom Card
            </ButtonText>
          </Button>
        </Link>
        <Link
          to={{name: 'vocab.home', params: {page: 1}}}
        >
          <Button
            height="50px"
            width="100%"
            background={Theme.blue}
            margin="0 0 10px 0"
          >
            <ButtonText
              color="#FFF"
            >
              <i className='fa fa-arrow-right' />
              Go to our Dictionary
            </ButtonText>
          </Button>
        </Link>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ReviewDeckNoCards);
