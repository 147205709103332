import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Paginator from 'components/paginator';
import { AffiliatePayoutStore } from '@seedlang/stores';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { Link } from 'react-router';

const Wrapper = styled.div`

`;

@observer
class AffiliatePayoutIndex extends React.Component {

  constructor(props) {
    super(props);
    AffiliatePayoutStore.getIndex();
  }

  render() {
    return (
      <Wrapper>
        <table
          className="table-wrapper"
          style={{marginTop: 10}}
        >
          <thead>
            <tr>
              <th />
              <th>Date</th>
              <th>User</th>
              <th>Payout Amount</th>
              <th>Total Revenue</th>
              <th>USD to EUR Rate</th>
              <th>Paid</th>
            </tr>
          </thead>
          <tbody>
            {
              AffiliatePayoutStore.indexData.map(item => {
                return (
                  <tr
                    key={item.id}
                  >
                    <td>
                      <Link
                        to={{ name: 'builder.affiliate_payouts.show', params: { affiliatePayoutId: item.id } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>{item.occurredAt.formattedDateWithYear.toString().replace("01 ", "")}</td>
                    <td>{item.user && item.user.name}</td>
                    <td>{item.formattedAmount}</td>
                    <td></td>
                    <td>{item.usdToEurRate}</td>
                    <td>
                      <InPlaceCheckbox
                        id={item.id}
                        value={item.paid}
                        model="affiliate_payouts"
                        field="paid"
                        icon="check"
                      />
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Paginator
          store={AffiliatePayoutStore}
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(AffiliatePayoutIndex);
