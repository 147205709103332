import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { isPresent } from '@seedlang/utils';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import ErrorBoundary from 'components/hoc/error_boundary';
import { SentenceStudioUI } from '@seedlang/state';
import { Link } from 'react-router';

const Wrapper = styled.div`
  .fa-play {
    cursor: pointer;
  }
`;

@observer
class TranslationIndex extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          isPresent(SentenceStudioUI.selectedTranslation) &&
            <div
              style={{textAlign: 'center', background: 'red', cursor: 'pointer', color: 'white', marginBottom: 10, fontSize: 13, padding: 5}}
              onClick={SentenceStudioUI.reset}
            >
              Return to Auto-Advance
            </div>
        }
        <table className='table-wrapper'>
          <tbody>
            <tr
              className={cx({
                selected: this.props.currentTranslation && this.props.currentTranslation.id === this.props.sentence.id && (!this.props.translationType || this.props.translationType === 'neutral'),
              })}
            >
              <td>
                <div
                  onClick={() => this.props.onSetVideoable(this.props.sentence, 'neutral')}
                  data-tip
                  data-for={`record-${this.props.sentence.id}`}
                >
                  <i className='fa fa-microphone fa-block fa-on-click' />
                  <ReactTooltip
                    place="top"
                    type="dark"
                    effect="solid"
                    id={`record-${this.props.sentence.id}`}
                    class="custom-tooltip"
                  >
                    Record
                  </ReactTooltip>
                </div>
              </td>
              <td>
                {
                  isPresent(this.props.sentence) && isPresent(this.props.sentence.target) && isPresent(this.props.sentence.target.videoClip) &&
                    <div
                      onClick={() => this.props.onPlayVideo(this.props.sentence, 'neutral')}
                      data-tip
                      data-for={`play-${this.props.sentence.id}`}
                    >
                      <i className="fa fa-block fa-play" />
                      <ReactTooltip
                        place="top"
                        type="dark"
                        effect="solid"
                        id={`play-${this.props.sentence.id}`}
                        class="custom-tooltip"
                      >
                        Play
                      </ReactTooltip>
                    </div>
                }
              </td>
              <td>
                <Link
                  to={{name: 'builder.sentences.edit', params: {sentenceId: this.props.sentence.id}}}
                  target="_blank"
                  className='underline'
                >
                  {this.props.sentence.targetText}{this.props.sentence.isAnswer ? ' (no sfx bitte)' : null}
                </Link>
              </td>
              <td>
                <div
                  className='text'
                  style={{fontSize: 12, color: '#333'}}
                >
                  {this.props.sentence.target.videoClip && this.props.sentence.target.videoClip.createdAt && this.props.sentence.target.videoClip.createdAt.formattedDateWithYear}
                </div>
              </td>
            </tr>
            {
              this.props.sentence.isAnswer && this.props.triviaDeck &&
                <tr
                  className={cx({
                    selected: this.props.currentTranslation && this.props.currentTranslation.id === this.props.sentence.id && this.props.translationType === 'correct',
                  })}
                >
                  <td>
                    <div
                      onClick={() => this.props.onSetVideoable(this.props.sentence, 'correct')}
                      data-tip
                      data-for={`record-${this.props.sentence.id}`}
                    >
                      <i className='fa fa-microphone fa-block fa-on-click' />
                      <ReactTooltip
                        place="top"
                        type="dark"
                        effect="solid"
                        id={`record-${this.props.sentence.id}`}
                        class="custom-tooltip"
                      >
                        Record
                      </ReactTooltip>
                    </div>
                  </td>
                  <td>
                    {
                      isPresent(this.props.sentence) && isPresent(this.props.sentence.correctVideoClip) &&
                        <div
                          onClick={() => this.props.onPlayVideo(this.props.sentence, 'correct')}
                          data-tip
                          data-for={`play-${this.props.sentence.correctVideoClip.id}`}
                        >
                          <i className="fa fa-block fa-play" />
                          <ReactTooltip
                            place="top"
                            type="dark"
                            effect="solid"
                            id={`play-${this.props.sentence.correctVideoClip.id}`}
                            class="custom-tooltip"
                          >
                            Play
                          </ReactTooltip>
                        </div>
                    }
                  </td>
                  <td>
                    <Link
                      to={{name: 'builder.sentences.edit', params: {sentenceId: this.props.sentence.id}}}
                      target="_blank"
                      className='underline'
                    >
                      {this.props.sentence.targetText} <span style={{color: 'green'}}>(correct)</span>
                    </Link>
                  </td>
                  <td>{this.props.sentence.correctVideoClip && this.props.sentence.correctVideoClip.createdAt && this.props.sentence.correctVideoClip.createdAt.formattedDateWithYear}</td>
                </tr>
            }
            {
              this.props.sentence.isAnswer && this.props.triviaDeck &&
                <tr
                  className={cx({
                    selected: this.props.currentTranslation && this.props.currentTranslation.id === this.props.sentence.id && this.props.translationType === 'incorrect',
                  })}
                >
                  <td>
                    <div
                      onClick={() => this.props.onSetVideoable(this.props.sentence, 'incorrect')}
                      data-tip
                      data-for={`record-${this.props.sentence.id}`}
                    >
                      <i className='fa fa-block fa-microphone fa-on-click' />
                      <ReactTooltip
                        place="top"
                        type="dark"
                        effect="solid"
                        id={`record-${this.props.sentence.id}`}
                        class="custom-tooltip"
                      >
                        Record
                      </ReactTooltip>
                    </div>
                  </td>
                  <td>
                    {
                      isPresent(this.props.sentence) && isPresent(this.props.sentence.incorrectVideoClip) &&
                        <div
                          onClick={() => this.props.onPlayVideo(this.props.sentence, 'incorrect')}
                          data-tip
                          data-for={`play-${this.props.sentence.incorrectVideoClip.id}`}
                        >
                          <i className="fa fa-block fa-play" />
                          <ReactTooltip
                            place="top"
                            type="dark"
                            effect="solid"
                            id={`play-${this.props.sentence.incorrectVideoClip.id}`}
                            class="custom-tooltip"
                          >
                            Play
                          </ReactTooltip>
                        </div>
                    }
                  </td>
                  <td>
                    <Link
                      to={{name: 'builder.sentences.edit', params: {sentenceId: this.props.sentence.id}}}
                      target="_blank"
                      className='underline'
                    >
                      {this.props.sentence.targetText} <span style={{color: 'red'}}>(incorrect)</span>
                    </Link>
                  </td>
                  <td>{this.props.sentence.incorrectVideoClip && this.props.sentence.incorrectVideoClip.createdAt && this.props.sentence.incorrectVideoClip.createdAt.formattedDateWithYear}</td>
                </tr>
            }
            {
              isPresent(this.props.sentence.wordAssociations) && this.props.sentence.wordAssociations.map((item) => {
                return (
                  <tr
                    key={item.id}
                    className={cx({
                      selected: this.props.currentTranslation && this.props.currentTranslation?.id === item?.word?.id,
                    })}
                  >
                    <td width="20">
                      <div
                        onClick={() => this.props.onSetVideoable(item.word)}
                        data-tip
                        data-for={`record-${item.id}`}
                      >
                        <i className='fa fa-block fa-microphone fa-on-click' />
                        <ReactTooltip
                          place="top"
                          type="dark"
                          effect="solid"
                          id={`record-${item.id}`}
                          class="custom-tooltip"
                        >
                          Record
                        </ReactTooltip>
                      </div>
                    </td>
                    <td width="20">
                      {
                        isPresent(item.word) && isPresent(item.word.target) && isPresent(item.word.target.videoClip) &&
                          <div
                            onClick={() => this.props.onPlayVideo(item.word)}
                            data-tip
                            data-for={`play-${item.id}`}
                          >
                            <i className="fa fa-block fa-play" />
                            <ReactTooltip
                              place="top"
                              type="dark"
                              effect="solid"
                              id={`play-${item.id}`}
                              class="custom-tooltip"
                            >
                              Play
                            </ReactTooltip>
                          </div>
                      }
                    </td>
                    <td>
                      <div className='text'>
                        {
                          item?.word &&
                            <Link
                              to={{name: 'builder.words.edit', params: {wordId: item?.word?.id}}}
                              target="_blank"
                              className='underline'
                            >
                              {item.targetText}
                            </Link>
                        }
                      </div>
                    </td>
                    <td>
                      <div
                        className='text'
                        style={{fontSize: 12, color: '#333'}}
                      >
                        {item.word?.target?.videoClip && item.word?.target?.videoClip?.createdAt && item.word?.target?.videoClip?.createdAt?.formattedDateWithYear}
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TranslationIndex);
