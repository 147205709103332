import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Spinner from 'components/spinner';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { pixify } from '@seedlang/utils';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  position: relative;
  background: #000;
  width: ${(props) => props.width};
  height: ${(props) => props.width};
  border-radius: 50%;
  margin-left: 10px;
  img {
    width: ${(props) => props.width};
    height: ${(props) => props.width};
    border-radius: 50%;
  }
  .spinner {
    margin: 0;
  }
`;

@observer
class Image extends React.Component {
  @observable showSpinner = true;

  render() {
    return (
      <Wrapper
        width={pixify(this.props.width)}
      >
        {
          this.showSpinner &&
            <Spinner />
        }
        <img
          src={this.props.src}
          onLoad={() => this.showSpinner = false}
          alt=""
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Image);
