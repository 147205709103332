import React from 'react';
import { observer } from 'mobx-react';
import Navigation from 'components/navigation';
import { last } from 'lodash';

@observer
class Deck extends React.Component {

  render() {
    return (
      <div className="page">
        <Navigation
          currentRoute={last(this.props.routes).name}
          smallButtons
          links={[
            {
              link: 'builder.decks.index',
              title: 'Decks',
              params: {page: 1},
            },
            {
              link: 'builder.trees.index',
              title: 'Trees',
            },
            {
              link: 'builder.ratings.index',
              title: 'Ratings',
            },
            {
              link: 'builder.deck_rulebooks.index',
              title: 'Rulebooks',
            },
            {
              link: 'builder.card_types.index',
              title: 'Card Types',
            },
            {
              link: 'builder.interjections.index',
              title: 'Interjections',
            },
          ]}
        />
        {this.props.children}
      </div>
    );
  }
}

export default Deck;
