import React from 'react';
import { observer } from 'mobx-react';
import UserProfileImage from 'components/user/user_profile_image';
import { isPresent } from '@seedlang/utils';
import styled from '@emotion/styled';

const Wrapper = styled.ul`
  li {
    display: inline-block;
    margin-right: 5px;
  }
`;

@observer
class UserProfileImages extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          isPresent(this.props.users) && this.props.users.map((item, index) => {
            return (
              <li
                key={`${item.id}-${index}`}
              >
                <UserProfileImage
                  displayName
                  onClick={() => this.props.onClick(item)}
                  user={item}
                  width={60}
                />
              </li>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default UserProfileImages;
