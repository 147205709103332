import React from 'react';
import { observer } from 'mobx-react';
import { ExerciseUI, DeckUI } from '@seedlang/state';
import styled from '@emotion/styled';
import { isPresent, pixify } from "@seedlang/utils/src";
import { ExerciseMultipleChoiceMediaWrapper } from "@seedlang/hoc";
import ExerciseEntry from 'components/exercise/exercise_entry';
import { isBlank } from '@seedlang/utils';
import Text from "components/text";
import { flexCenter, flexCenterColumn } from "@seedlang/style_mixins/src";
import VideoPlayer from "components/media/video_player";
import LanguageButton from "components/button/language_button";
import CardSection from "components/vocab_card/card_section";
import MultipleChoiceButtons from "components/card/multiple_choice_buttons";

const Wrapper = styled.div`
  height: ${(props) => props.height};  
`;

const MediaOption = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-right: ${(props) => props.marginRight ? props.marginRight : props.inDeck ? "5px" : "10px"};
  margin-left: ${(props) => props.inDeck ? "5px" : 0};
  margin-top: ${(props) => props.inDeck ? "5px" : 0};
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : props.inDeck ? "5px" : 0};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 10px solid #FFF;
  border-radius: 15px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${props => props.flexDirection};
  justify-content: space-around;
`;

const AnswersWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent};
  width: 100%;
  flex-wrap: wrap;
  max-width: ${props => props.maxWidth}; 
  margin-right: 10px;
  margin-bottom: 5px;
`;

const Button = styled.button`
  background-color: ${props =>  props.backgroundColor};
  color: ${props => props.color};
  cursor: ${(props) => props.revealed ? 'default' : 'pointer'};
  border: none;
  font-size: 16px;
  width: ${(props) => props.width};
  min-height: 100px;
  min-width: 40%;
  i {
    color: ${props => props.backgroundColor};
  }
  border-radius: 10px;
  text-transform: ${props => props.textTransform};
  padding: 5px;
  font-weight: bold;
  align-items: center;
  margin: 5px;
  font-size: 14px;
  line-height: 18px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex: 1;
`;

const VideoSection = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  margin-top: 40px;
`;

const VideoWrapper = styled.div`
  ${flexCenterColumn()}
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  position: relative;
`;

const AnswerWrapper = styled.div`

`;

const TopSection = styled.div`

`;

const Answer = styled.div`
  ${flexCenter()}
  cursor: pointer;
  visibility: ${props => props.visible ? 'visible' : 'hidden'}
`;

const Prompt = styled.div`
  margin: 0 20px;
  font-size: 24px;
  line-height: 34px;
`;

@observer
class ExerciseMultipleChoiceCard extends React.Component {

  render() {
    return (
      <Wrapper
        height="100%"
      >
        {
          this.props.exercise.exerciseEntries.map(exerciseEntry => {
            return (
              <>
              <CardSection
                background="#FFF"
                borderTopRadius={10}
                borderBottomRadius={10}
                height={this.props.sectionHeight}
              >
                {
                  exerciseEntry.prompt &&
                  <Prompt>
                    {exerciseEntry.prompt}
                  </Prompt>
                }
                {
                  isPresent(exerciseEntry.videoClip) &&
                  <VideoSection>
                    <VideoWrapper
                      height="180px"
                      width="180px"
                    >
                      <VideoPlayer
                        maximized={false}
                        autoPlay={this.props.inView}
                        circle
                        videoClip={ exerciseEntry.videoClip }
                        width={Math.floor(DeckUI.layout.deckWidth / 3.4)}
                        position='relative'
                      />
                    </VideoWrapper>
                  </VideoSection>
                }
                {
                  exerciseEntry.mediaOptions && isPresent(exerciseEntry.mediaOptions) && isPresent(exerciseEntry.mediaOptions[0].image) &&
                  <MediaOption
                    width={pixify(this.props.mediaWidth)}
                    marginBottom={ExerciseUI.layout.isMobile ? '10px' : null}
                    marginRight={ExerciseUI.layout.isMobile ? '0px' : null}
                    inDeck
                    key={exerciseEntry.mediaOptions[0].id}
                  >
                    {
                      isPresent(exerciseEntry.mediaOptions[0].image) &&
                      <img
                        src={exerciseEntry.mediaOptions[0].image.url}
                        alt=""
                      />
                    }
                  </MediaOption>
                }
                <AnswerWrapper>
                  <Answer
                    onClick={() => this.props.setLanguageId(this.props.languageId !== 'EN' ? 'EN' : DeckUI.deck.languageId)}
                    visible={this.props.exercise.state?.revealed}
                  >
                    {
                      (isPresent(exerciseEntry.word) || isPresent(exerciseEntry.sentence)) &&
                      <LanguageButton
                        margin="5px 5px 0 0"
                        languageId={this.props.languageId}
                      />
                    }
                    {
                      exerciseEntry.word &&
                      <Prompt>
                        {this.props.languageId === DeckUI.deck.languageId ? exerciseEntry.word.targetTextWithDefiniteArticle : exerciseEntry.word.sourceText}
                      </Prompt>
                    }
                    {
                      exerciseEntry.sentence &&
                      <Prompt>
                        {this.props.languageId === DeckUI.deck.languageId ? exerciseEntry.sentence.displayTargetText : exerciseEntry.sentence.sourceText}
                      </Prompt>
                    }
                  </Answer>
                </AnswerWrapper>
              </CardSection>
              <MultipleChoiceButtons
                disabled={!this.props.inView}
                height={DeckUI.layout.totalMultipleChoiceButtonHeight(exerciseEntry.shuffledMultipleChoiceOptions.length)}
                buttonHeight={DeckUI.layout.multipleChoiceButtonHeight}
                width={DeckUI.layout.innerDeckWidth}
                options={exerciseEntry.shuffledMultipleChoiceOptions}
                onClick={(option) => this.props.onClick(exerciseEntry, option)}
                selected={exerciseEntry.state.submittedValue?.split(',') || []}
                isMobile={DeckUI.layout.isMobile}
                field="targetText"
                languageId={this.props.card.languageId}
                revealed={this.props.cardState.revealed}
              />
              {
                exerciseEntry.numberOfCorrectOptions > 1 && exerciseEntry.selectAllCorrectOptions &&
                <Text italic fontSize="13px" color="#333" margin="10px 0 0 0">
                  Select all correct answers.
                </Text>
              }
              </>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ExerciseMultipleChoiceMediaWrapper(ExerciseMultipleChoiceCard);
