import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import TagCategoryCreate from 'pages/creator/tags/tag_category_create';
import TagCreate from 'pages/creator/tags/tag_create';
import autobind from 'autobind-decorator';
import { TagCategoryStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import DeleteButton from 'components/button/delete_button';
import { flexCenter } from '@seedlang/style_mixins';
import { TagStore } from '@seedlang/stores';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceSelect from 'components/form/in_place_select';
import { AppUI } from "@seedlang/state";
import ReactTooltip from 'react-tooltip';
import InfoTooltip from "components/info_tooltip";
import { startCase } from "lodash";
import BackEndExplainer from "pages/creator/back_end_explainer";

const Wrapper = styled.div`
`;

const TagCategory = styled.div`
  ${flexCenter()}
  background: white;
  padding: 10px;
  background: #d6d6d6;
`;

const TagCategoryWrapper = styled.div`
  margin-bottom: 20px;
`;

const TagWrapper = styled.div`
  background: #FFF;
  padding: 10px;
`;

const Delete = styled.div`
  width: 50px;
`;

const TagCategoryName = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 16px;
  display: flex;
`;

const TagName = styled.div`
  flex: 1;
  font-size: 16px;
  display: flex;
`;

const Position = styled.div`
  width: 50px;
`;

const Tag = styled.div`
  ${flexCenter()}
  padding-bottom: 5px;
`;

const Publish = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  width: 110px;
`;

const SelectOnlyOne = styled.div`
  width: 120px;
`;

const NavigationSettings = styled.div`
  width: 300px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: space-evenly;
`;

const SelectCategory = styled.div`
  width: 180px;
  select {
    font-size: 12px;
  }
`;

@observer
class TagsIndex extends React.Component {

  constructor(props) {
    super(props);
    this.loadTags();
  }

  @autobind loadTags() {
    TagCategoryStore.getIndex({ids: {groupId: this.props.params.groupId}});
  }

  @autobind onDeleteTagCategory(id) {
    TagCategoryStore.destroy({ids: {tagCategoryId: id}}, this.loadTags);
  }

  @autobind onDeleteTag(id) {
    TagStore.destroy({ids: {tagId: id}}, this.loadTags);
  }

  render() {
    return (
      <Wrapper>
        <BackEndExplainer>These tags can be attached to post and used to filter them by users.</BackEndExplainer>
        <TagCategoryCreate
          groupId={this.props.params.groupId}
          afterChange={this.loadTags}
        />
        {
          TagCategoryStore.indexData.map(item => {
            return (
              <TagCategoryWrapper
                key={item.id}
              >
                <TagCategory>
                  <Position>
                    <InPlaceText
                      model="tag_categories"
                      field="position"
                      defaultValue={item.position}
                      id={item.id}
                      type="number"
                      step="1"
                      afterChange={this.loadTags}
                    />
                  </Position>
                  <TagCategoryName>
                    {
                      item.isAutomatic &&
                      <InfoTooltip icon='magic' position="right" margin="0 5px 0 0">
                        <div>These automatic filters are based on the user's <b>exercise</b> progress,</div>
                        <div>and cannot be assigned manually to worksheets or exercises.</div>
                      </InfoTooltip>
                    }
                    <InPlaceText
                      model="tag_categories"
                      field="name"
                      defaultValue={item.name}
                      id={item.id}
                      afterChange={this.loadTags}
                    />
                  </TagCategoryName>
                  <NavigationSettings>
                    <div
                      data-tip
                      data-for={`videos-${item.id}`}
                    >
                      <InPlaceCheckbox
                        model="tag_categories"
                        field="display_on_home_worksheets"
                        value={item.displayOnHomeWorksheets}
                        id={item.id}
                        afterChange={this.loadTags}
                        icon="check"
                      >
                        Home
                      </InPlaceCheckbox>
                      <ReactTooltip
                        type="dark"
                        effect="solid"
                        id={`videos-${item.id}`}
                        class="custom-tooltip"
                      >
                        <div>Display this tag on the homepage.</div>
                      </ReactTooltip>
                    </div>
                    <div
                      data-tip
                      data-for={`videos-${item.id}`}
                    >
                      <InPlaceCheckbox
                        model="tag_categories"
                        field="display_on_video_worksheets"
                        value={item.displayOnVideoWorksheets}
                        id={item.id}
                        afterChange={this.loadTags}
                        icon="check"
                      >
                        {AppUI.site.navigationItems.find(item => item.path === '/videos')?.name || 'Videos'}
                      </InPlaceCheckbox>
                      <ReactTooltip
                        type="dark"
                        effect="solid"
                        id={`videos-${item.id}`}
                        class="custom-tooltip"
                      >
                        <div>Display this tag on the {AppUI.site.navigationItems.find(item => item.path === '/videos')?.name || 'Videos'} page.</div>
                      </ReactTooltip>
                    </div>
                    <div
                      data-tip
                      data-for={`podcast-${item.id}`}
                    >
                      <InPlaceCheckbox
                        model="tag_categories"
                        field="display_on_podcast_worksheets"
                        value={item.displayOnPodcastWorksheets}
                        id={item.id}
                        afterChange={this.loadTags}
                        icon="check"
                      >
                        {AppUI.site.navigationItems.find(item => item.path === '/podcast')?.name || 'Podcasts'}
                      </InPlaceCheckbox>
                      <ReactTooltip
                        type="dark"
                        effect="solid"
                        id={`podcast-${item.id}`}
                        class="custom-tooltip"
                      >
                        <div>Display this tag on the {AppUI.site.navigationItems.find(item => item.path === '/podcast')?.name || 'Videos'} page.</div>
                      </ReactTooltip>
                    </div>
                  </NavigationSettings>

                  <SelectOnlyOne>
                    {
                      !item.isAutomatic &&
                        <InPlaceCheckbox
                          model="tag_categories"
                          field="select_only_one"
                          value={item.selectOnlyOne}
                          id={item.id}
                          afterChange={this.loadTags}
                          icon="check"
                        >
                          Select One
                        </InPlaceCheckbox>
                    }
                  </SelectOnlyOne>
                  <Publish>
                    <InPlaceCheckbox
                      model="tag_categories"
                      field="published"
                      value={item.published}
                      id={item.id}
                      afterChange={this.loadTags}
                      icon="check"
                    >
                      Publish
                    </InPlaceCheckbox>
                  </Publish>
                  <Delete>
                    <DeleteButton
                      onConfirm={() => this.onDeleteTagCategory(item.id)}
                      message="Delete this category? (all tags will also be deleted)"
                      className="fa-block right"
                      disabled={item.isAutomatic}
                    />
                  </Delete>
                </TagCategory>
                <TagWrapper>
                  {
                    item.tags.map(tag => {
                      return (
                        <Tag
                          key={tag.id}
                        >
                          <Position>
                            <InPlaceText
                              model="tags"
                              field="position"
                              defaultValue={tag.position}
                              id={tag.id}
                              type="number"
                              step="1"
                              afterChange={this.loadTags}
                            />
                          </Position>
                          <Position>
                            {tag.overallPosition}
                          </Position>
                          <TagName>
                            {
                              tag.isAutomatic &&
                              <InfoTooltip icon='magic' position="right" margin="0 5px 0 0">
                                <div>{`This tag will show a user's "${startCase(tag.slug.replace('_', ' '))}" posts.`}</div>
                              </InfoTooltip>
                            }
                            <InPlaceText
                              model="tags"
                              field="name"
                              defaultValue={tag.name}
                              id={tag.id}
                              afterChange={this.loadTags}
                            />
                          </TagName>
                          <SelectCategory>
                            {
                              !item.isAutomatic &&
                                <InPlaceSelect
                                  model="tags"
                                  field="tag_category_id"
                                  value={item.id}
                                  options={TagCategoryStore.indexData.map(item => [item.id, item.name])}
                                  id={tag.id}
                                  afterChange={this.loadTags}
                                />
                            }
                          </SelectCategory>
                          <Publish>
                            <InPlaceCheckbox
                              model="tags"
                              field="published"
                              value={tag.published}
                              id={tag.id}
                              afterChange={this.loadTags}
                              icon="check"
                            >
                              Publish
                            </InPlaceCheckbox>
                          </Publish>
                          <Delete>
                            <DeleteButton
                              onConfirm={() => this.onDeleteTag(tag.id)}
                              message="Delete this tag?"
                              className="fa-block right"
                              disabled={item.isAutomatic}
                            />
                          </Delete>
                        </Tag>
                      )
                    })
                  }
                  {
                    !item.isAutomatic &&
                      <TagCreate
                        tagCategoryId={item.id}
                        groupId={this.props.params.groupId}
                        afterChange={this.loadTags}
                      />
                  }
                </TagWrapper>
              </TagCategoryWrapper>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TagsIndex);
