import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import UserProfileImage from 'components/user/user_profile_image';
import { Theme } from '@seedlang/constants';
import { flexCenter } from '@seedlang/style_mixins';
import { pixify, isPresent } from '@seedlang/utils';
import AnimatedCircle from 'components/animated_circle';

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  align-items: center;
`;

const Name = styled.div`
  margin: ${(props) => props.margin};
  color: white;
  font-weight: bold;
  line-height: 16px;
  font-size: 16px;
`;

const Score = styled.div`
  margin: ${(props) => props.margin};
  background: ${Theme.green};
  width: 30px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  color: white;
  line-height: 18px;
  padding: 3px 5px;
`;

const NameAndScore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.alignItems};
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingRight};
`;

const ImageWrapper = styled.div`
  order: ${(props) => props.align === "right" ? 1 : -1 }
`;

const Timer = styled.div`
  position: relative;
  ${flexCenter()}
  background: ${(props) => props.background};
  width: ${(props) => props.width};
  height: ${(props) => props.width};
  border-radius: 50%;
  .RadialProgressIndicator__label {
    display: none;
  }
`;

const ProgressBarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => props.width};
  height: ${(props) => props.width};
  z-index: 3;
`;

const TurnPoints = styled.div`
  ${flexCenter()}
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  z-index: 2;
  width: ${(props) => props.width};
  height: ${(props) => props.width};
  background: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
  padding: 1px 0 0 0;
`;

@observer
class TriviaCardPlayer extends React.Component {

  @computed get margin() {
    if (this.props.nameMargin) {
      return this.props.nameMargin;
    } else if (this.props.align === "left") {
      return "0 0 3px 5px";
    } else if (this.props.align === "right") {
      return "0 5px 3px 0";
    }
    return null;
  }

  @computed get progressColor() {
    if (this.props.turn && this.props.turn.correct === false) {
      return Theme.red;
    } else if (this.props.turn && this.props.turn.correct) {
      return Theme.green;
    }
    return Theme.orange;
  }

  render() {
    return (
      <Wrapper
        flexDirection={this.props.flexDirection}
      >
        <ImageWrapper
          align={this.props.align}
        >
          <Timer
            width={pixify(this.props.timerWidth)}
            background="#565656"
          >
            {
              this.props.turn && ((this.props.turn.correct || this.props.turn.correct === false)) &&
                <TurnPoints
                  width={pixify(this.props.timerWidth)}
                >
                  +{this.props.turn.points}
                </TurnPoints>
            }
            <UserProfileImage
              user={this.props.user}
              width={pixify(this.props.userProfileImageWidth)}
              hideProStatus={this.props.hideProStatus}
              border={this.props.userProfileBorder}
            />
            <ProgressBarWrapper
              width={this.props.timerWidth}
            >
              {
                this.props.turn && this.props.showTimer &&
                  <AnimatedCircle
                    animate={this.props.turn.timerActive}
                    responsive={false}
                    animationDuration="20s"
                    size={this.props.timerWidth}
                    lineWidth="45"
                    progress={this.props.turn.timerProgress}
                    bgColor="#565656"
                    progressColor={this.progressColor}
                    roundedStroke={false}
                    showPercentage={false}
                    showPercentageSymbol={false}
                  />
              }
            </ProgressBarWrapper>
          </Timer>
        </ImageWrapper>
        <NameAndScore
          alignItems={this.props.flexDirection === "row" ? "flex-start" : "flex-end"}
          paddingLeft={this.props.flexDirection === "row" ? "5px" : null}
          paddingRight={this.props.flexDirection !== "row" ? "5px" : null}
        >
          {
            !this.props.hideName &&
              <Name
                margin={this.margin}
                fontSize={this.props.nameFontSize}
              >
                {this.props.user?.firstName}
              </Name>
          }
          {
            isPresent(this.props.points) &&
              <Score
                margin={this.margin}
              >
                {this.props.points}
              </Score>
          }
        </NameAndScore>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TriviaCardPlayer);
