import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed, toJS } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import LineChart from 'components/visualization/line_chart';
import { AggregationStore } from '@seedlang/stores';
import { flexCenterColumn, flexCenter } from '@seedlang/style_mixins';
import autobind from 'autobind-decorator';
import FilterSelect from 'components/backend_filter/filter_select';
import FilterDate from 'components/backend_filter/filter_date';
import Alert from 'components/alert';
import InteractiveLegend from 'components/visualization/interactive_legend';
import { Constants } from '@seedlang/constants';
import { each, without } from 'lodash';
import InPlaceSelect from 'components/form/in_place_select';
import Spinner from 'components/spinner';
import { isPresent, getCookieValue, setCookieValue } from '@seedlang/utils';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  select {
    height: 35px;
  }
`;

const FiltersOuterWrapper = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  padding: 0 10px 0 10px;
`;

const FiltersInnerWrapper = styled.div`
  ${flexCenter()}
`;

const EmptyChart = styled.div`
  ${flexCenterColumn()}
  height: 400px;
`;


@observer
class LineChartWithControls extends React.Component {
  @observable loadingData = false;
  @observable currentChartName;
  @observable data = [];
  @observable selectedResources = [];
  @observable timePeriod = 'week';
  @observable dateRange;
  @observable chartType = 'auto';

  @computed get currentChartNameKey() {
    return `chart-${this.props.namespace}-current-chart-name`;
  }

  @computed get timePeriodKey() {
    return `chart-${this.props.namespace}-time-period`;
  }

  @computed get dateRangeKey() {
    return `chart-${this.props.namespace}-date-range`;
  }

  @computed get chartTypeKey() {
    return `chart-${this.props.namespace}-chart-type`;
  }

  @computed get currentChart() {
    return this.currentChartName ? this.props.charts.find(item => item.name === this.currentChartName) : this.props.charts[0];
  }

  @computed get hasFilteredData() {
    return isPresent(toJS(this.filteredData));
  }

  @computed get hasFilteredDataItems() {
    return this.hasFilteredData && this.filteredData.some(x => (x?.data?.length ?? 0) > 0);
  }

  @computed get filteredData() {
    return this.data.filter(item => this.selectedResources.indexOf(item.id) !== -1);
  }

  @computed get filteredResources() {
    return this.currentChart && this.currentChart.resources.filter(item => this.selectedResources.indexOf(item.label) !== -1);
  }

  @computed get hasData() {
    return isPresent(toJS(this.data));
  }

  componentDidMount() {
    this.updateFiltersFromCookies();
    this.getData();
  }

  @autobind onToggleResource(resource) {
    if (this.selectedResources.indexOf(resource) === -1) {
      this.selectedResources.push(resource);
    } else {
      this.selectedResources = without(this.selectedResources, resource);
    }
  }

  @autobind updateFiltersFromCookies() {
    if (isPresent(getCookieValue(this.currentChartNameKey))) {
      this.currentChartName = getCookieValue(this.currentChartNameKey);
    }
    if (isPresent(getCookieValue(this.timePeriodKey))) {
      this.timePeriod = getCookieValue(this.timePeriodKey);
    }
    if (isPresent(getCookieValue(this.dateRangeKey))) {
      this.dateRange = getCookieValue(this.dateRangeKey);
    }
    if (isPresent(getCookieValue(this.chartTypeKey))) {
      this.chartType = getCookieValue(this.chartTypeKey);
    }
  }

  @autobind async getData() {
    this.setSelectedResources();
    this.loadingData = true;
    AggregationStore.create({
      data: {
        time_period: this.timePeriod,
        resources: this.currentChart && this.currentChart.resources,
        date_range: this.dateRange,
        chart_type: this.chartType,
      }
    }, this.afterGetData)
  }

  @autobind setSelectedResources() {
    this.selectedResources = this.currentChart.resources && this.currentChart.resources.map(item => item.label);
  }

  @autobind afterGetData(resp) {
    let index = 0;
    each(resp, (resource) => {
      resource.color = Constants.CHART_COLORS[index % Constants.CHART_COLORS.length];
      resource.colorIndex = index % Constants.CHART_COLORS.length;
      index += 1;
    })
    this.data = resp;
    this.loadingData = false;
  }

  @autobind setChartType(chartType) {
    setCookieValue(this.chartTypeKey, chartType);
    this.chartType = chartType;
    this.getData();
  }

  @autobind setTimePeriod(timePeriod) {
    setCookieValue(this.timePeriodKey, timePeriod);
    this.timePeriod = timePeriod;
    this.getData();
  }

  @autobind setDateRange(dateRange) {
    setCookieValue(this.dateRangeKey, dateRange);
    this.dateRange = dateRange;
    this.getData();
  }

  @autobind onChangeCurrentChart(name) {
    setCookieValue(this.currentChartNameKey, name);
    this.currentChartName = name;
    this.updateFiltersFromCookies();
    this.getData();
  }

  render() {
    return (
      <Wrapper>
        {
          this.props.charts.length > 1 &&
            <InPlaceSelect
              allowUpdate
              value={this.currentChartName}
              options={this.props.charts.map(item => [item.name, item.name])}
              onChange={this.onChangeCurrentChart}
            />
        }
        {
          !this.loadingData && this.hasData && this.hasFilteredDataItems &&
            <LineChart
              data={this.filteredData}
            />
        }
        {
          this.loadingData &&
            <EmptyChart>
              <Spinner
                className='blue'
              />
            </EmptyChart>
        }
        {
          this.hasData && (!this.hasFilteredDataItems) &&
            <EmptyChart>
              <Alert
                width="100%"
                margin="10px 0 10px 0"
              >
                There is no data to display
              </Alert>
            </EmptyChart>
        }
        <InteractiveLegend
          hideCounts={this.currentChart && this.currentChart.hideCounts}
          data={this.data}
          loadingData={this.loadingData}
          colors={Constants.CHART_COLORS}
          onToggle={this.onToggleResource}
          selectedIds={this.selectedResources}
          chartType={this.chartType}
        />
        <FiltersOuterWrapper>
          <FiltersInnerWrapper>
            {
              (!this.props.hideFilters || this.props.filters.indexOf('chartType') === -1 ) &&
                <FilterSelect
                  flat
                  options={['auto', 'cumulative']}
                  default={this.chartType}
                  onChange={this.setChartType}
                />
            }
            {
              (!this.props.hideFilters || this.props.filters.indexOf('timePeriod') === -1 ) &&
                <FilterSelect
                  flat
                  options={['day', 'week', 'month']}
                  default={this.timePeriod}
                  onChange={this.setTimePeriod}
                />
            }
            <FilterDate
              label="Created At"
              default={this.dateRange}
              onChange={this.setDateRange}
            />
          </FiltersInnerWrapper>
        </FiltersOuterWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(LineChartWithControls);
