import React from 'react';
import CampaignTypeEdit from 'pages/builder/campaign_types/campaign_type_edit';

class SiteCampaignTypeEdit extends React.Component {
  render() {
    return (
      <CampaignTypeEdit
        {...this.props}
        whiteLabeled
      />
    );
  }
}

export default SiteCampaignTypeEdit;
