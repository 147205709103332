import { AppUI } from '@seedlang/state';
import { PaymentMigrationConfigStore, PaymentMigrationStore } from '@seedlang/stores';
import { stripHtmlTags } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import Button from 'components/button/button';
import ContactUsTextWithLink from 'components/contact_us_text_with_link';
import Spinner from 'components/spinner';
import Text from 'components/text';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router';
import { isPresent, isBlank } from "@seedlang/utils";
import { dollar, cents } from '@seedlang/utils';
import OutlineButton from 'components/button/outline_button';
import { Theme } from '@seedlang/constants';
import styled from '@emotion/styled';
import { amountWithCurrency } from "@seedlang/utils/src";

const Wrapper = styled.div`
 margin-top: 20px;
 text-align: left;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent};
`;

const InstructionList = styled.ol`
  li {
    margin-bottom: 5px;
  }
`;

const CancelButton = ({children, onClick, ...rest}) => (
  <OutlineButton
    selectedBackground={Theme.red}
    onClick={onClick}
    {...rest}
  >
    {children ?? 'Cancel'}
  </OutlineButton>
)

@observer
class SettingsBillingMigrationSection extends React.Component {
  refreshInterval = 1000;
  refreshTimer = null;

  @observable loading = false;
  @observable errorMessage = null;

  constructor(props) {
    super(props);
    this.periodicallyRefreshIfNeeded();
    PaymentMigrationConfigStore.getShow();
    this.loadPaymentMigration();
  }

  @autobind loadPaymentMigration(params, callback = null) {
    if (isBlank(this.paymentMigration.id) || params?.forceGetShow) {
      PaymentMigrationStore.getShow({}, () => {
        AppUI.loadUser(null, {
          successCallback: callback,
        });
      });
    }
  }

  get paymentMigration() {
    return AppUI.user.paymentMigration;
  }

  periodicallyRefreshIfNeeded() {
    if (this.paymentMigration.status === 'stripe_payment_setup_started') {
      this.refreshTimer = setTimeout(() => {
        PaymentMigrationStore.getShow({}, null, {
          successCallback: () => {
            this.refreshInterval *= 1.5;
            this.periodicallyRefreshIfNeeded();
          }
        });
      }, this.refreshInterval);
    }
  }

  @autobind startPaymentSetup() {
    this.loading = true;
    this.errorMessage = null;

    PaymentMigrationStore.createPaymentSetupSession((resp) => {
      this.loading = false;
      if (!resp.url) {
        console.warn('createPaymentSetupSession resp.url is missing', resp);
      }
      window.location.href = resp.url
    }, {
      data: {onboarding: true, membershipId: this.membership.id, currency: this.currency},
      failureCallback: (resp) => {
        this.loading = false;
        this.errorMessage = resp.message ?? 'Something went wrong. Please try again later.';
      }
    })
  }

  @autobind cancelMigration() {
    this.errorMessage = null;
    if (this.refreshTimer) clearTimeout(this.refreshTimer);
    PaymentMigrationStore.destroy({ids: {paymentMigrationId: this.paymentMigration.id}}, this.afterDestroy);
  }

  @autobind afterDestroy(resp) {
    this.loadPaymentMigration({forceGetShow: true});
  }

  @autobind reload() {
    this.loading = true;
    this.loadPaymentMigration({forceGetShow: true}, () => this.loading = false);
  }

  get afterPaymentSetupHtml() {
    return PaymentMigrationConfigStore.showData.afterPaymentSetupMonthlyHtml;
  }

  @computed get isYearly() {
    return this.paymentMigration.patreonPledgeCadence === 12;
  }

  @computed get currency() {
    if (!this.paymentMigration) {
      console.warn('paymentMigration is missing in SettingsBillingMigrationSection');
    }
    if (typeof this.paymentMigration?.currency?.toUpperCase() === 'undefined' || this.paymentMigration?.currency?.toUpperCase() === null) {
      console.warn(`paymentMigration.currency is blank in SettingsBillingMigrationSection`);
    }
    console.log('this.paymentMigration?.currency', this.paymentMigration?.currency, AppUI.user?.currency)
    return this.paymentMigration?.currency ?? AppUI.user?.currency ?? 'USD';
  }

  @computed get currencySymbol() {
    return this.currency?.toUpperCase() === 'USD' ? '$' : '€';
  }

  @computed get membership() {
    return this.paymentMigration.membership;
  }

  @computed get cost() {
    return this.membership.cost(this.currency);
  }

  @computed get costMonthly() {
    return this.membership.costMonthly(this.currency);
  }

  @computed get patreonCampaignId() {
    return AppUI.user.site?.patreonCampaignId;
  }

  render() {
    return (
      <Wrapper>
        {
          !this.props.onboarding && isPresent(this.paymentMigration.status) && !this.paymentMigration.isComplete &&
            <Text heading="4" margin="20px 0 10px 0">Migration from Patreon</Text>
        }
        {
          this.paymentMigration.status === 'setting_up' && (
            AppUI.user.patreonUser?.isPatreonActive ? (
              <>
                <Text margin="0 0 20px 0" fontSize='18px'>By switching your payment from Patreon to our site, you'll unlock our platform's new interactive features.</Text>

                {this.membership ? (
                  <>
                    <Text fontSize='16px'>
                      <strong>New membership price:{" "}</strong>
                      <span>
                        {stripHtmlTags(this.membership.title)}
                        {" – "}
                        {this.currencySymbol}{dollar(this.costMonthly)}.{cents(this.costMonthly)} / month (incl. tax)
                        {" – "}
                        {this.currency?.toUpperCase() === 'USD' ? this.membership.descriptionUsd : this.membership.descriptionEur}
                      </span>
                      {" "}
                      <Link
                        to={{ name: 'memberships.change', query: { mode: 'payment_migration', membershipId: this.paymentMigration.membership.id } }}
                        style={{ color: AppUI.siteIsDefault && isPresent(AppUI.site.accentColor) ? "#0584a1" : AppUI.site.accentColor, marginLeft: "0.5em" }}
                        className='underline'
                      >
                        Change Tier?
                      </Link>
                    </Text>
                    <ButtonRow justifyContent={this.props.onboarding ? 'center' : 'left'}>
                      {
                        this.loading ? (
                          <Spinner
                            margin="10px 0"
                            background={AppUI.siteIsDefault || isBlank(AppUI.site.accentColor) ? null : AppUI.site.accentColor}
                          />
                        ) : (
                          <Button
                            background={AppUI.siteIsDefault || isBlank(AppUI.site.accentColor) ? null : AppUI.site.accentColor}
                            margin="10px 0"
                            onClick={this.startPaymentSetup}
                          >
                            Add Your Payment Details
                          </Button>
                        )
                      }
                    </ButtonRow>
                    {
                      <Text italic fontSize='14px' textAlign={this.props.onboarding ? 'center' : 'left'}>You will not be charged during the migration process.</Text>
                    }
                  </>
                ) : (
                  <Button
                    background={AppUI.siteIsDefault || isBlank(AppUI.site.accentColor) ? null : AppUI.site.accentColor}
                    onClick={() => { }}
                    style={{display: 'block'}}
                  >
                    Select Membership
                  </Button>
                )}

                {
                  this.errorMessage && (
                    <Text color="red" margin="0 0 10px 0">{this.errorMessage}</Text>
                  )
                }
                {
                  !this.props.hideContactLink &&
                    <ContactUsTextWithLink marginTop={this.props.onboarding ? '5px' : '20px'} textAlign={this.props.onboarding ? 'center' : null}/>
                }
              </>
            ) : !AppUI.user.patreonUser && !this.props.hasActiveSubscription ? (
              <Text margin="20px 0 20px 0">
                If you have a subscription on Patreon, you can{" "}
                <Link to={{ name: 'settings.patreon' }} className='underline' style={{ color: 'inherit'}}>
                  link your Patreon account
                </Link>{" "}
                and migrate your subscription to our membership platform.
              </Text>
            ) : null
          )
        }

        {
          this.paymentMigration.status === 'stripe_payment_setup_started' && (
            <div style={{textAlign: this.props.onboarding ? 'center' : 'left'}}>
              <Text margin="0 0 10px 0">Waiting for confirmation from the payment provider. This should take a few seconds...</Text>
              {
                this.loading ? (
                  <Spinner
                    background={AppUI.siteIsDefault || isBlank(AppUI.site.accentColor) ? null : AppUI.site.accentColor}
                  />
                ) : (
                  <CancelButton onClick={this.cancelMigration}>Cancel</CancelButton>
                )
              }
              {
                !this.props.hideContactLink &&
                <ContactUsTextWithLink marginTop={this.props.onboarding ? '5px' : '20px'} textAlign={this.props.onboarding ? 'center' : null}/>
              }
            </div>
          )
        }

        {
          this.paymentMigration.status === 'waiting_for_patreon_cancellation' && (
            <>
              {
                this.loading ? (
                  <Spinner
                    background={AppUI.site.accentColor}
                  />
                ) : (
                  <>
                    <div style={{textAlign: this.props.onboarding ? 'center' : 'left', fontSize: this.props.onboarding ? '20px' : '18px', fontWeight: 800}}>Payment Details Added Successfully</div>
                    <p style={{textAlign: this.props.onboarding ? 'center' : 'left', fontStyle: 'italic', margin: this.props.onboarding ? '30px 0 15px 0' : null}}>Now, let's finalize your switch from Patreon to unlock all features:</p>
                    <InstructionList>
                      <li><a href={`https://www.patreon.com/settings/memberships/${this.patreonCampaignId}`} target="_blank" rel="noopener noreferrer"  className='underline'>Open your "Memberships" settings on Patreon here, select 'Cancel Membership'.</a></li>
                      <li>Choose "Cancel Membership" and confirm cancellation.</li>
                      <li>Come back to this page and click the button below.</li>
                    </InstructionList>
                    {
                      this.patreonCampaignId && (
                        <div>
                          <ButtonRow justifyContent={this.props.onboarding ? 'center' : 'start'}>
                            <Button
                              margin="10px 0"
                              background={AppUI.site.accentColor}
                              onClick={this.reload}
                              fontWeight={800}
                              fontSize='16px'
                            >
                              I've Cancelled My Patreon Membership
                            </Button>
                          </ButtonRow>
                          <Text italic fontSize='14px' textAlign={this.props.onboarding ? 'center' : 'left'}>You'll still maintain access to your perks and will only be charged once you subscription ends.</Text>
                        </div>
                      )
                    }
                    {
                      !this.props.hideContactLink &&
                      <ContactUsTextWithLink marginTop={this.props.onboarding ? '5px' : '20px'} textAlign={this.props.onboarding ? 'center' : null}/>
                    }
                  </>
                )
              }

            </>
          )
        }

        {
          this.paymentMigration.status === 'waiting_for_patreon_expiry' && (
            <>
              { this.props.onboarding && <h3 style={{textAlign: this.props.onboarding ? 'center' : 'left'}}>Thank You for Migrating Your Payment Method</h3> }
              {
                !this.isYearly &&
                  <p>We will start charging you <strong>{this.currencySymbol}{dollar(this.costMonthly)}.{cents(this.costMonthly)} / month</strong> once your subscription on Patreon has expired.</p>
              }
              {
                this.isYearly &&
                  <p>We will start charging you <strong>{this.currencySymbol}{dollar(this.cost)}.{cents(this.cost)} / year</strong> once your subscription on Patreon has expired.</p>
              }
              <p>In the meantime, please enjoy all the benefits and perks connected to your membership.</p>
              {
                !this.props.onboarding &&
                  <CancelButton onClick={this.cancelMigration}>Cancel Migration</CancelButton>
              }

              {
                !this.props.hideContactLink &&
                <ContactUsTextWithLink marginTop={this.props.onboarding ? '5px' : '20px'} textAlign={this.props.onboarding ? 'center' : null}/>
              }
            </>
          )
        }

        {
          (this.paymentMigration.status === 'waiting_for_patreon_refund') && (
            <>
              <p>Setup complete.</p>
              <div
                dangerouslySetInnerHTML={{ __html: PaymentMigrationConfigStore.showData.waitingForPatreonRefundHtml}}
              />
              {
                !this.props.onboarding &&
                  <CancelButton onClick={this.cancelMigration}>Cancel Migration</CancelButton>
              }
              {
                !this.props.hideContactLink &&
                <ContactUsTextWithLink marginTop={this.props.onboarding ? '5px' : '20px'} textAlign={this.props.onboarding ? 'center' : null}/>
              }
            </>
          )
        }

        {
          (this.paymentMigration.status === 'waiting_for_patreon_refund_timeout') && (
            <>
              <p>Setup complete.</p>
              <div
                dangerouslySetInnerHTML={{ __html: PaymentMigrationConfigStore.showData.waitingForPatreonRefundTimeoutHtml}}
              />
              {
                !this.props.onboarding &&
                  <CancelButton onClick={this.cancelMigration}>Cancel Migration</CancelButton>
              }
              {
                !this.props.hideContactLink &&
                <ContactUsTextWithLink marginTop={this.props.onboarding ? '5px' : '20px'} textAlign={this.props.onboarding ? 'center' : null}/>
              }
            </>
          )
        }
      </Wrapper>
    )
  }
}

export default SettingsBillingMigrationSection;
