import React from 'react';
import { observer } from 'mobx-react';
import { depixify, pixify } from '@seedlang/utils';
import ImageWithStatus from 'components/image_with_status';
import { Link } from 'react-router';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { flexCenter } from '@seedlang/style_mixins';
import { TreeNodeIconWrapper } from '@seedlang/hoc';
import { AppUI } from "@seedlang/state";

const Wrapper = styled.div`
  cursor: ${(props) => props.published ? 'pointer' : 'default'};
  width: ${props => props.width};
  margin: ${props => props.margin};
  position: relative;
  overflow: hidden;
`;

const LabelWrapper = styled.div`
  z-index: ${Theme.z["over-foreground"]};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div`
  background: ${(props) => props.background};
  color: white;
  padding: 3px 7px;
  font-size: 11px;
  line-height: 12px;
  font-weight: 700;
  width: 45px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressCount = styled.div`
  ${flexCenter()}
  z-index: ${Theme.z["foreground"]};
  color: ${(props) => props.color};
  border: ${(props) => props.borderWidth}px solid ${(props) => props.borderColor};
  position: absolute;
  top: ${props => props.top};
  width: ${props => props.width};
  left: 21px;
  border-radius: 10px;
  margin: 0 auto;
  background: #333;
  text-align: center;
  height: 32px;
  font-size: 14px;
  font-weight: bold;
  pointer-events: none;
`;

const Slash = styled.span`
  margin: ${(props) => props.margin};
`;

@observer
class TreeNodeIcon extends React.Component {

  render() {
    return (
      <Link
        to={{name: 'stories.show', params: {treeNodeId: this.props.treeNode.id}}}
        id={this.props.treeNode?.id}
        className={this.props.className}
      >
        <Wrapper
          published={this.props.nodeIsEnabled}
          height={pixify(depixify(this.props.height) + 40)}
          width={pixify(this.props.width)}
          margin={AppUI.layout.viewportWidth <= 480 ? '10px' : '20px'}
        >
          {
            !this.props.treeNode.webPublished &&
              <LabelWrapper>
                <Label
                  background={Theme.red}
                >
                  UNP
                </Label>
              </LabelWrapper>
          }
          {
            this.props.treeNode.webPublished && this.props.treeNode.webPublishedInLastWeek &&
              <LabelWrapper>
                <Label
                  background={Theme.green}
                >
                  NEW
                </Label>
              </LabelWrapper>
          }
          <ImageWithStatus
            showLock
            smallLock
            borderWidth={this.props.borderWidth}
            borderColor={this.props.borderColor}
            borderTopRadius="30px"
            borderBottomRadius="30px"
            height={this.props.height}
            width={this.props.width}
            imageHeight={depixify(this.props.height) - 20}
            imageWidth={depixify(this.props.width) - 20}
            inProgress={this.props.inProgress}
            hasAccess={this.props.hasAccess}
            finished={this.props.finished}
            src={this.props.src}
            checkmarkWidth="70px"
            imageBorderRadius="20px"
            imageMarginTop="5px"
            imageMarginBottom={this.props.imageMarginBottom}
          />
          {
            this.props.showProgressCount &&
              <ProgressCount
                color={this.props.color}
                borderWidth={this.props.borderWidth}
                borderColor={this.props.borderColor}
                top={pixify(depixify(this.props.width) - 18)}
                width={pixify(depixify(this.props.width) - 40)}
              >
                <span>
                  {this.props.finishedUserDecksCount}
                </span>
                <Slash
                  margin={this.props.treeNode.treeNodeDecksCount === 1 ? "0 1px 0 0" : null}
                >
                  /
                </Slash>
                <span>
                  {this.props.treeNode.treeNodeDecksCount}
                </span>
              </ProgressCount>
          }
          {this.props.children}
        </Wrapper>
      </Link>
    );
  }
}

export default TreeNodeIconWrapper(TreeNodeIcon);
