import Cookie from 'js-cookie';

export default function deleteAllCookies() {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const name = cookies[i].split("=")[0];
    if (name) {
      Cookie.remove(name, {path: '/'});
    }
  }
}