import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import TriviaImage from 'components/trivia/trivia_image';
import DeckLink from 'components/deck/deck_link';
import { Theme } from '@seedlang/constants';
import { TriviaDeckLinkWrapper } from '@seedlang/hoc';


const InnerWrapper = styled.div`
  position: flex;
  display: relative;
`;

@observer
class TriviaDeckLink extends React.Component {

  render() {
    return (
      <DeckLink
        title={`${this.props.treeNode.level.abbreviation} Trivia`}
        width={190}
        new={this.props.showNewLabel}
        progressCurrent={this.props.sentencesSeenCount}
        progressTotal={this.props.treeNode.publishedSentencesCount}
        borderColor={!AppUI.user.isMember && AppUI.user.freeGamesAvailableCount === 0 ? "#777" : Theme.purple}
        marginRight="10px"
        onClick={this.props.onClick}
        progressLabel="Questions Seen"
        disabled={!AppUI.user.isMember && AppUI.user.freeGamesAvailableCount === 0}
      >
        <InnerWrapper>
          {
            this.props.treeNode.image &&
              <TriviaImage
                src={this.props.treeNode.image.url}
                width="170px"
                height="170px"
                borderTopLeftRadius="10px"
                borderTopRightRadius="10px"
                level={this.props.treeNode.level && this.props.treeNode.level.abbreviation}
                disabled={!AppUI.user.isMember && AppUI.user.freeGamesAvailableCount === 0}
              />
          }
        </InnerWrapper>
      </DeckLink>
    );
  }
}

export default TriviaDeckLinkWrapper(TriviaDeckLink);
