import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import Copy from 'components/copy';
import { isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const VideoWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
  height: ${props => props.height};
  width: ${props => props.width};
  .copy {
    position: absolute;
    top: 5px;
    left: 5px;
    background: ${Theme.green};
    border-radius: 3px;
    padding: 2px 5px;
    font-size: 12px;
    height: 16px;
    display: flex;
    align-items: center;
    color: white;
    margin: 0;
    i {
      margin-right: 3px;
    }
  }
`;


const Expand = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  background: #13ab5e;
  color: white;
  font-size: 12px;
  border-radius: 3px;
  cursor: pointer;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
`;

@observer
class VideoPlayersWithType extends React.Component {
  @observable expanded = false;

  @computed get videosCount() {
    return [this.props.videoClip.webmUrl, this.props.videoClip.mp4Url].filter(item => isPresent(item)).length;
  }

  @computed get src() {
    if (this.props.videoClip) {
      return this.props.videoClip[`${this.props.videoClip.originalExtension}Url`] || this.props.videoClip.webmUrl || this.props.videoClip.mp4Url;
    } return null;
  }

  render() {
    return (
      <Wrapper>
        {
          this.props.videoClip && !this.expanded &&
            <VideoWrapper
              width={this.props.width || "150px"}
              height={this.props.width || "150px"}
            >
              <video
                muted={this.props.muted}
                autoPlay={this.props.autoPlay}
                loop={this.props.loop}
                playsInline
                src={this.props.videoClip.mp4Url}
                width={this.props.width || "150"}
                height={this.props.width || "150"}
                controls={true}
              />
            </VideoWrapper>
        }
        {
          this.props.videoClip && this.expanded && this.props.videoClip.mp4Url &&
            <VideoWrapper
              width={this.props.width || "150px"}
              height={this.props.width || "150px"}
            >
              <video
                muted={this.props.muted}
                autoPlay={this.props.autoPlay}
                loop={this.props.loop}
                playsInline
                src={this.props.videoClip.mp4Url}
                width={this.props.width || "150"}
                height={this.props.width || "150"}
                controls={true}
              />
              <Copy
                text={this.props.videoClip.mp4Url}
              >
                mp4
              </Copy>
            </VideoWrapper>
        }
        {
          this.props.videoClip && this.expanded && this.props.videoClip.webmUrl &&
            <VideoWrapper
              width={this.props.width || "150px"}
              height={this.props.width || "150px"}
            >
              <video
                muted={this.props.muted}
                autoPlay={this.props.autoPlay}
                loop={this.props.loop}
                playsInline
                src={this.props.videoClip.webmUrl}
                width={this.props.width || "150"}
                height={this.props.width || "150"}
                controls={true}
              />
              <Copy
                text={this.props.videoClip.webmUrl}
              >
                webm
              </Copy>
            </VideoWrapper>
        }
        {
          this.props.videoClip && false &&
            <Expand
              onClick={() => this.expanded = !this.expanded}
            >
              <i className='fa fa-expand' />
            </Expand>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(VideoPlayersWithType);
