import React from 'react';
import { observer } from 'mobx-react';
import { computed, toJS } from 'mobx';
import styled from '@emotion/styled';
import { range } from 'lodash';
import AnalyticsListItem from 'components/analytics/analytics_list_item';
import AnalyticsListRow from 'components/analytics/analytics_list_row';
import { uniq } from 'lodash';
import { pixify } from '@seedlang/utils';
import { nonDataColumnsWidth } from 'components/analytics/analytics_list_row';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1000px;
  border-top: 1px solid #CCC;
  border-left: 1px solid #CCC;
  overflow: auto;
  height: 600px;
`;

const InnerWrapper = styled.div`
  width: ${props => props.width};
`;

@observer
class AnalyticsListWeekly extends React.Component {

  @computed get uniqueDates() {
    return uniq(this.props.counts.map(item => item.date));
  }

  render () {
    return (
      <Wrapper>
        <InnerWrapper
          width={pixify(nonDataColumnsWidth + (this.props.statFields.length * this.props.dataColumnWidth))}
        >
          <AnalyticsListRow
            {...this.props}
            header
            languageId="Language"
            date="Date"
            timePeriod={this.props.timePeriod}
            statFields={this.props.statFields}
            position="sticky"
          />
          {
            this.uniqueDates.map((date, index) => {
              return (
                <AnalyticsListItem
                  {...this.props}
                  key={index}
                  date={date}
                  items={this.props.counts.filter(item => item.date === date)}
                />
              )
            })
          }
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default AnalyticsListWeekly;
