import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';

const Wrapper = styled.a`
  display: inline-block;
  .fragment {
    display: inline;
  }
  .fragment:not(:first-child) {
    margin: 0 0 0 5px;
  }
  .link {
    cursor: pointer;
  }
  .object {
    font-weight: bold;
  }
`;

@observer
class NotificationMessage extends React.Component {

  render() {
    return (
      <Wrapper
        href={this.props.notification.notificationLink}
      >
        {
          this.props.notification.primarySender &&
            <span className='fragment'>
              {this.props.notification.primarySender.name ? this.props.notification.primarySender.name.trim() : 'Someone'}
            </span>
        }
        {
          this.props.notification.additionalUsers &&
            <span className='fragment'>
              {this.props.notification.additionalUsers}
            </span>
        }
        <span className='fragment'>
          {this.props.notification.notificationText}
        </span>
        {
          this.props.notification.linkText &&
            <div
              className='fragment object link'
            >
              {this.props.notification.linkText}
            </div>
        }
        {
          this.props.notification.notificationObject &&
            <div
              className='fragment object'
            >
              {this.props.notification.notificationObject}
            </div>
        }
        .
      </Wrapper>
    );
  }
}

export default ErrorBoundary(NotificationMessage);
