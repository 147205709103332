import React from 'react';

class Badge extends React.Component {

  render() {
    return (
      <svg viewBox="0 0 243 170" version="1.1" xmlns="https://www.w3.org/2000/svg">
          <g>
              <path d="M61.9999887,92.4999887 C61.9999887,125.360938 88.6390512,152 121.5,152 C154.360949,152 181.000011,125.360938 181.000011,92.4999913 L181.000011,18 L61.9999887,18 L61.9999887,92.4999887 Z M57,0 L186,0 C193.179702,0 199,5.82029825 199,13 L199.000011,92.4999887 C199.000011,135.302063 164.302074,170 121.5,170 C78.6979257,170 43.9999887,135.302063 43.9999887,92.4999887 L44,13 C44,5.82029825 49.8202983,0 57,0 Z" id="Rectangle"></path>
              <polygon id="Path-2" points="37 28 0 28 18.5 46.5 0 65 37 65"></polygon>
              <polygon id="Path-2" transform="translate(224.500000, 46.500000) scale(-1, 1) translate(-224.500000, -46.500000) " points="243 28 206 28 224.5 46.5 206 65 243 65"></polygon>
          </g>
      </svg>
    );
  }
}

export default Badge;
