import React from 'react';
import { observer } from 'mobx-react';
import { PatreonUserStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import styled from '@emotion/styled';
import CheckIfTrue from 'components/check_if_true';
import { Link } from 'react-router';
import Filter from 'components/backend_filter/filter';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`
  .text {
    padding: 0 5px;
  }
`;

@observer
class PatreonUsersIndex extends React.Component {

  constructor(props) {
    super(props);
    this.getIndex();
  }

  @autobind getIndex() {
    PatreonUserStore.getIndex();
  }

  render() {
    return (
      <Wrapper>
        <Filter
          store={PatreonUserStore}
          defaultSort="email"
          namespace="patreon-user-index"
          filters={
            [
              {
                type: 'text',
                name: 'email',
                label: 'email',
                placeholder: 'Email',
                default: '',
              },
              {
                type: 'text',
                name: 'reward_title',
                label: 'Reward Title',
                default: '',
              },
              {
                type: 'boolean',
                name: 'is_active_patron',
                label: 'Active Patron',
                default: '',
              },
              {
                type: 'boolean',
                name: 'has_seedlang_user',
                label: 'Has Seedlang User',
                default: '',
              },
            ]
          }
        />
        <table
          className="table-wrapper"
          style={{
            marginTop: 10,
          }}
        >
          <thead>
            <tr>
              <th width="40"></th>
              <th>Email</th>
              <th>First</th>
              <th>Last</th>
              <th>Reward Title</th>
              <th>Active Patron</th>
              <th>Seedlang User</th>
            </tr>
          </thead>
          <tbody>
            {
              PatreonUserStore.indexData.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'creator.groups.patreon_users.edit', params: { groupId: this.props.params.groupId, patreonUserId: item.id } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>
                      <div className='text'>
                        {item.email}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.firstName}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.lastName}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.rewardTitle}
                      </div>
                    </td>
                    <td>
                      <InPlaceCheckbox
                        icon="check"
                        model="patreon_users"
                        id={item.id}
                        afterChange={this.loadPatreonUser}
                        field="is_patreon_active"
                        value={item.isPatreonActive}
                      />
                    </td>
                    <td>
                      <CheckIfTrue
                        value={item.hasSeedlangUser}
                      />
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Paginator
          store={PatreonUserStore}
          queryStrings={{expanded: true}}
        />
      </Wrapper>
    );
  }
}

export default PatreonUsersIndex;
