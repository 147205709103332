import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { VictoryPie } from 'victory';
import { absoluteCenter, flexCenterColumn } from '@seedlang/style_mixins';
import Text from 'components/text';
import InteractiveLegend from 'components/visualization/interactive_legend';
import { Constants } from '@seedlang/constants';
import { pixify, isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  margin: ${(props) => props.margin};
  height: ${(props) => props.width};
  width: ${(props) => props.width};
`;

const Label = styled.div`
  ${absoluteCenter()}
  ${flexCenterColumn()}
`;

const ChartWrapper = styled.div`
  position: relative;
  height: ${(props) => props.width};
  width: ${(props) => props.width};
`;

@observer
class PieChart extends React.Component {

  static defaultProps = {
    width: 160,
    margin: "0 0 5px 0",
    innerRadius: 50,
    fontSize: "40px",
    lineHeight: "44px",
  }

  render() {
    return (
      <Wrapper
        width={pixify(this.props.width)}
        margin={this.props.margin}
      >
        {
          this.props.data &&
            <ChartWrapper
              width={pixify(this.props.width)}
            >
              <Label>
                {
                  isPresent(this.props.label) &&
                    <Text
                      heading="3"
                    >
                      {this.props.label}
                    </Text>
                }
                {
                  isPresent(this.props.count) &&
                    <Text
                      bold
                      fontSize={this.props.fontSize}
                      lineHeight={this.props.lineHeight}
                    >
                      {this.props.count}
                    </Text>
                }
                {
                  isPresent(this.props.subText) &&
                    <Text
                      bold
                      fontSize="12px"
                    >
                      {this.props.subText}
                    </Text>
                }
              </Label>
              <VictoryPie
                padAngle={2}
                innerRadius={this.props.innerRadius}
                cornerRadius={5}
                padding={0}
                width={this.props.width}
                height={this.props.width}
                labels={() => null}
                colorScale={this.props.colors || Constants.CHART_COLORS}
                data={this.props.data}
              />
            </ChartWrapper>
        }
        {
          this.props.showLegend &&
            <InteractiveLegend
              data={this.props.data}
              colors={Constants.CHART_COLORS}
            />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(PieChart);
