import React from 'react';
import { observer } from 'mobx-react';
import CardButtons from 'components/card/card_buttons';
import { DeckUI } from '@seedlang/state';
import ProgressBarWithLevel from 'components/progress_bar_with_level';
import CheckpointCardCenter from 'components/card/checkpoint_card_center';
import { pixify } from '@seedlang/utils';
import { Theme } from '@seedlang/constants';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import Text from 'components/text';
import { CheckpointCardWrapper } from '@seedlang/hoc';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import NextButton from 'components/button/next_button';

const ContentInner = styled.div`
  ${flexCenterColumn()}
  position: relative;
  z-index: ${Theme.z["card-2"]};
  width: 100%;
  height: ${(props) => props.height};
  background: ${Theme.darkestBlue};
  border-radius: 10px;
  .progress-bar-with-level {
    max-width: 400px;
    width: calc(100% - 40px);
    .progress-bar {
      height: 16px;
      border-radius: 5px;
      .current {
        background: ${Theme.orange};
      }
    }
  }
`;

const BottomSection = styled.div`
  z-index: 0;
  position: absolute;
  background: #f1f1f1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: ${(props) => props.height};
  bottom: 0;
  width: 100%;
`;

const Encouragement = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  margin: 5px 0 20px 0;
  cursor: pointer;
  color: white;
  @media (max-height: 500px) {
    display: none;
  }
`;

const ProgressBarWithLevelWrapper = styled.div`
  padding: 0 10px;
  width: 100%;
  z-index: 1;
`;

@observer
class CheckpointCard extends React.Component {

  render() {
    return (
      <CardWrapper>
        {
          DeckUI.userIsLoaded && DeckUI.userDeck.hasUserCards &&
            <CardContent>
              <ContentInner
                height={pixify(DeckUI.layout.contentHeight)}
              >
                <BottomSection
                  height={pixify((DeckUI.layout.contentHeight) / 2)}
                />
                <div className='text'>
                  <Text
                    italic
                    center
                    color="#FFF"
                  >
                    {this.props.achievementText}
                  </Text>
                  <Encouragement
                    onClick={this.props.toggleLanguage}
                  >
                    { this.props.encouragement[this.props.languageIndex] }
                  </Encouragement>
                </div>
                <CheckpointCardCenter
                  user={DeckUI.user}
                  languageId={this.props.card.languageId || 'DE'}
                  inView={this.props.inView}
                />
                <ProgressBarWithLevelWrapper>
                  <ProgressBarWithLevel
                    user={DeckUI.user}
                    flatProgressBar
                  />
                </ProgressBarWithLevelWrapper>
              </ContentInner>
            </CardContent>
        }
        <CardButtons>
          <NextButton
            disabled={!this.props.inView}
            onClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard)}
            submitOnEnter
            shortcut="1"
          />
        </CardButtons>
      </CardWrapper>
    );
  }
}

export default CheckpointCardWrapper(CheckpointCard);
