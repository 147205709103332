import React from 'react';
import { observer } from 'mobx-react';
import { NavigationItemStore } from '@seedlang/stores';

@observer
class NavigationItemCreate extends React.Component {
  onClick() {
    const data = Object.assign({
      name: this.refs.name.value,
      route_name: this.refs.route_name.value,
      path: this.refs.path.value,
      site_id: this.props.siteId,
    }, this.props.data);

    NavigationItemStore.create({ data: data }, this.props.afterCreate());
    this.refs.name.value = '';
    this.refs.route_name.value = '';
    this.refs.path.value = '';
  }

  render() {
    return (
      <div className="nav-item-create row">
        <div className="col-xs-2">
          <input
            placeholder="Add a name"
            ref="name"
          />
        </div>
        <div className="col-xs-2">
          <input
            placeholder="Add a route name"
            ref="route_name"
          />
        </div>
        <div className="col-xs-2">
          <input
            placeholder="Add a path"
            ref="path"
          />
        </div>
        <div className="col-xs-2">
          <button
            onClick={this.onClick.bind(this)}
            className='button-primary'>
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default NavigationItemCreate;
