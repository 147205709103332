import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Module from 'components/module';
import Leaderboard from 'components/leaderboard/leaderboard';
import { TreeUI, AppUI } from '@seedlang/state';
import { isPresent } from '@seedlang/utils';
import TreeNodeIcon from 'components/tree_node/tree_node_icon';
import Text from 'components/text';

const Wrapper = styled.div`
  width: 100%;
`;

const ColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

@observer
class ClassroomProgress extends React.Component {

  @computed get highlightedTreeNode() {
    if (isPresent(TreeUI.storiesTree) && AppUI.groupStore.hasShowData && AppUI.groupStore.showData.highlightedNodeId) {
      return TreeUI.storiesTreeNodes.find(item => item.id === AppUI.groupStore.showData.highlightedNodeId);
    } return null;
  }

  render () {
    return (
      <Wrapper>
        {
          this.highlightedTreeNode &&
            <Module
              title="Highlighted"
            >
              <ColumnWrapper>
                {
                  this.highlightedTreeNode &&
                    <TreeNodeIcon
                      width="240px"
                      treeNode={this.highlightedTreeNode}
                      imageMarginBottom="5px"
                    />
                }
                {
                  this.highlightedTreeNode &&
                    <Text
                      bold
                      center
                    >
                      {this.highlightedTreeNode.name}
                    </Text>
                }
              </ColumnWrapper>
            </Module>
        }
        <Module
          title="Leaderboard"
        >
          {
            AppUI.groupStore.hasShowData &&
              <Leaderboard
                emptyMessage="No one in this classroom has earned XP yet."
                lastWeekColumn="xp_last_week"
                lastMonthColumn="xp_last_month"
                totalColumn="xp"
                groupId={AppUI.groupStore.showData.id}
                rankingFields={{xp: "xpRankingTotal", "xp_last_week": "xpRankingLastWeek", "xp_last_month": "xpRankingLastMonth"}}
                hideYourRanking
              />
          }
        </Module>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ClassroomProgress);
