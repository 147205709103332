import React from 'react';
import { observer } from 'mobx-react';
import { TranslationCommentsWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import CommentIndex from 'components/comment/comment_index';
import { ForumUI } from '@seedlang/state';
import Text from 'components/text';
import { displayTargetTextCorrectedForLanguage, isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  padding: ${(props) => props.padding};
  overflow: hidden;
`;

@observer
class TranslationComments extends React.Component {

  static defaultProps = {
    padding: "20px",
  }

  render() {
    return (
      <Wrapper
        padding={this.props.padding}
      >
        {
          !this.props.hideTitle &&
            <div>
              <Text
                heading={isPresent(this.props.answer) ? null : "3"}
                fontSize={isPresent(this.props.answer) ? "16px" : null}
                bold={isPresent(this.props.answer)}
                margin="10px 0 0 0"
              >
                {displayTargetTextCorrectedForLanguage(this.props.targetText, this.props.word?.languageId || this.props.sentence?.languageId)}
              </Text>
              <Text
                italic
                margin="0 0 20px 0"
              >
                {this.props.sentence && this.props.sentence.sourceText}
                {this.props.word && this.props.word.vocabWordList}
              </Text>
          </div>
        }
        <CommentIndex
          showXp
          showCommentCreate={!ForumUI.user.guest}
          showCommentCount
          indentation={20}
          user={ForumUI.user}
          wordId={this.props.wordId}
          sentenceId={this.props.sentenceId}
          namespace={this.props.translationId}
          blockTextExpansion={this.props.blockTextExpansion}
          onChange={this.props.afterEmbeddedCommentCreate}
          commentable={this.props.forumThread}
          commentableType="ForumThread"
          commentableId={this.props.forumThreadId}
          forumThreadId={this.props.forumThreadId}
          commentMargin="10px 0"
          margin={this.props.commentMargin}
          padding="0"
          languageId={this.props.word?.languageId || this.props.sentence?.languageId}
          debugInfo={this.props.debugInfo}
          placeholder={this.props.commentCreatePlaceholder}
        />
      </Wrapper>
    );
  }
}

export default TranslationCommentsWrapper(TranslationComments);
