import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexCenter } from '@seedlang/style_mixins';
import Text from 'components/text';
import VocabProgressBar from 'components/vocab/vocab_progress_bar';
import { LearnedIcon } from '@seedlang/icons';
import { Link } from 'react-router';
import Checkbox from 'components/checkbox';
import Frequency from 'components/vocab/frequency';
import autobind from 'autobind-decorator';
import { AppUI, VocabUI } from '@seedlang/state';
import VocabSourceForUser from 'components/vocab/vocab_source_for_user';
import { Constants } from '@seedlang/constants';
import ReactTooltip from 'react-tooltip';
import { Theme } from "@seedlang/constants";
import { isBlank } from '@seedlang/utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Word = styled.div`
  flex: 1;
  padding-left: 3px;
  .text {
    border-bottom: ${props => props.clickable ? '2px solid #b5b5b5' : null};
    display: inline-block;
  }
  .register {
    color: ${Theme.gray};
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 5px;
`;

const Cell = styled.div`
  ${flexCenter()}
  width: ${(props) => props.width};
  flex: ${(props) => props.expand ? 1 : null};
  padding: 0 5px;
  .selection-button {
    width: 100%;
  }
  .icon-wrapper {
    cursor: pointer;
  }
`;

const NumTranslations = styled.div`
  font-size: 12px;
  color: #7d7d7d;
  font-style: italic;
  text-align: left;
  width: 100%;
`;

@observer
class VocabListWord extends React.Component {
  @observable language = 'de';
  @observable sourceEditWord = false;

  @autobind onClick() {
    this.props.setWord(this.props.word);
  }

  @computed get selectedCardTypeIsCompatible() {
    if (this.props.selectedCardTypeId === undefined || isBlank(VocabUI.selectedWords)) {
      return true;
    }

    switch (Constants.REVIEW_CARD_TYPES.find(item => item.id === this.props.selectedCardTypeId).slug) {
      case "conjugation":
        return this.props.word.isVerb && !this.props.word.hideFromConjugationTrainer;
      case "gender":
        return this.props.word.isNoun && !this.props.word.hideFromGenderTrainer;
      case "plural":
        return this.props.word.isNoun && !this.props.word.hideFromPluralTrainer && !this.props.word.noPlural && this.props.word.languageId === 'DE';
      default:
        return true;
    }
  }

  render() {
    return (
      <Wrapper>
        <Row>
          {
            !this.props.grammarSection &&
              <Cell
                width="40px"
                data-tip
                data-for={`incompatible-${this.props.word.id}`}
              >
                <Checkbox
                  onClick={() => (this.selectedCardTypeIsCompatible || this.props.selectedWordIds.indexOf(this.props.word.id) !== -1) && this.props.onToggleAddWordToDeck(this.props.word)}
                  value={this.props.selectedWordIds.indexOf(this.props.word.id) !== -1}
                  showLock={!this.props.allowSelection || !this.selectedCardTypeIsCompatible}
                />
                {
                  !this.props.allowSelection &&
                    <ReactTooltip
                      place="right"
                      type="dark"
                      effect="solid"
                      id={`incompatible-${this.props.word.id}`}
                      class="custom-tooltip"
                    >
                      <div>Become a Pro Member to create</div>
                      <div>multiple reviews at a time.</div>
                    </ReactTooltip>
                }
                {
                  this.props.allowSelection && !this.selectedCardTypeIsCompatible &&
                    <ReactTooltip
                      place="right"
                      type="dark"
                      effect="solid"
                      id={`incompatible-${this.props.word.id}`}
                      class="custom-tooltip"
                    >
                      <div>This word is not compatible</div>
                      <div>{`with ${Constants.REVIEW_CARD_TYPES.find(item => item.id === this.props.selectedCardTypeId).name} cards.`}</div>
                    </ReactTooltip>
                }
              </Cell>
          }
          {
            !this.props.grammarSection && !this.props.isMobile && AppUI.layout.leftColumnWidth > 700 &&
              <Cell
                width="60px"
              >
                <Text
                  fontSize="13px"
                >
                  {!this.props.word.frequencyRanking && '-'}
                  {
                    this.props.word.frequencyRanking &&
                      <Frequency
                        word={this.props.word}
                      />
                  }
                </Text>
              </Cell>
          }
          <Cell
            expand={!this.props.showSource}
            width={this.props.showSource ? "200px" : null}
          >
            <Word
              clickable={this.props.word.publicInVocab}
            >
              {
                this.props.word && this.props.word.publicInVocab &&
                  <Link
                    onClick={this.onClick}
                    to={{name: 'vocab.word', params: {wordId: this.props.word.id}}}
                  >
                    <Text
                      fontSize={this.props.isMobile ? "14px" : "16px"}
                    >
                      {this.language === 'de' ? this.props.word.targetTextWithDefiniteArticle : this.props.word.vocabSourceList}
                    </Text>
                  </Link>
              }
              {
                this.props.word && !this.props.word.publicInVocab &&
                  <Text
                    fontSize={this.props.isMobile ? "14px" : "16px"}
                  >
                    {this.language === 'de' ? this.props.word.targetTextWithDefiniteArticle : this.props.word.vocabSourceList}
                  </Text>
              }
            </Word>
          </Cell>
          {
            this.props.showSource &&
              <Cell
                expand
              >
                {
                  !this.props.expandTranslation && this.props.word && this.props.word.vocabSourceForUser &&
                    <NumTranslations>
                      {this.props.word.vocabSourceForUser(this.props.user) ? this.props.word.vocabSourceForUser(this.props.user).length : 0} translations
                    </NumTranslations>
                }
                {
                  this.props.expandTranslation && this.props.word && this.props.word.vocabSourceForUser &&
                    <VocabSourceForUser
                      onClick={() => this.sourceEditWord = true}
                      sources={this.props.word.vocabSourceForUser(this.props.user)}
                      languageId={AppUI.user.languageId}
                      genderAbbreviation={this.props.word.genderAbbreviation}
                      user={this.props.user}
                    />
                }
              </Cell>
          }
          <Cell
            width="70px"
            center
          >
            <VocabProgressBar
              word={this.props.word}
            />
          </Cell>
          <Cell
            width="65px"
            center
          >
            <LearnedIcon
              width="28px"
              selected={this.props.word.learned}
              onClick={() => this.props.onToggleLearned(this.props.word)}
            />
          </Cell>
        </Row>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(VocabListWord);
