import React from 'react';
import { observer } from 'mobx-react';
import { AppUI, ExerciseUI } from '@seedlang/state';
import { isBlank, isPresent } from '@seedlang/utils';
import Spinner from 'components/spinner';
import styled from '@emotion/styled';
import YoutubePlayer from 'components/worksheet/youtube_player';
import PodcastPlayer from "components/worksheet/podcast_player";
import WorksheetDescription from "components/worksheet/worksheet_description";

const TopSection = styled.div`
  display: flex;
  @media (max-width: 999px) {
    flex-direction: column;
  }
  gap: 10px;
`;

const MediaSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

@observer
class WorksheetInfo extends React.Component {

  render() {
    return (
      <TopSection>
          {
            !ExerciseUI.hasWorksheet &&
            <Spinner
              background={AppUI.site.accentColor || '#868686'}
            />
          }
          {
            ExerciseUI.hasWorksheet &&
            <WorksheetDescription
              namespace={this.props.params.namespace}
              borderRadius={this.props.borderRadius}
            />
          }
          {
            !AppUI.layout.isMobile && ExerciseUI.hasWorksheet && isPresent(ExerciseUI.worksheet.podcastEpisode) && isBlank(ExerciseUI.formattedYoutubeUrl) &&
            <MediaSection>
              <PodcastPlayer
                hasAccess={ExerciseUI.worksheet.userHasAccess}
                podcast={ExerciseUI.worksheet.podcastEpisode.podcast}
                podcastEpisode={ExerciseUI.worksheet.podcastEpisode}
                borderRadius={this.props.borderRadius}
              />
            </MediaSection>
          }
          {
            ExerciseUI.hasWorksheet && isPresent(ExerciseUI.formattedYoutubeUrl) &&
            <MediaSection id="video-player">
              <YoutubePlayer
                hasAccess={ExerciseUI.worksheet.userHasAccess}
                url={ExerciseUI.formattedYoutubeUrl}
                placeholderImageUrl={ExerciseUI.worksheet?.image?.url}
                podcastEpisode={ExerciseUI.worksheet.podcastEpisode}
                borderRadius={this.props.borderRadius}
              />
            </MediaSection>
          }
        </TopSection>
    );
  }
}

export default WorksheetInfo;
