import React from 'react';
import { observer } from 'mobx-react';
import { ConjugationCardWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import CardSection from 'components/vocab_card/card_section';
import { DeckUI } from '@seedlang/state';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import { pixify } from '@seedlang/utils';
import MultipleChoiceButtons from 'components/card/multiple_choice_buttons';
import EvaluateButtons from 'components/card/evaluate_buttons';
import ConjugationCardPrompt from 'components/conjugation_card/conjugation_card_prompt';
import ConjugationCardRevealed from 'components/conjugation_card/conjugation_card_revealed';
import PageOnboarding from 'components/onboarding/page_onboarding';
import autobind from 'autobind-decorator';
import { noop } from 'lodash';

const PromptText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #FFF;
  font-style: italic;
`;

const OnboardingText = styled.div`
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
`;

@observer
class ConjugationCardMultipleChoice extends React.Component {

  @autobind showTextEntry() {
    DeckUI.user.set('textInputConjugationTrainer', true);
    DeckUI.authUserStore.update({data: {textInputConjugationTrainer: true}}, noop);
  }

  render() {
    return (
      <CardWrapper
        card={this.props.card}
        cardState={this.props.cardState}
        userCard={this.props.userCard}
        icons={this.props.icons}
      >
        <CardContent
          padding={0}
          height={pixify(this.props.revealed ? DeckUI.layout.contentHeight : DeckUI.layout.contentHeightWithMultipleChoiceButtons(this.props.buttonOptions?.length))}
        >
          {
            !this.props.revealed &&
              <CardSection
                background="#5d5d5d"
                borderTopRadius={10}
                numSections={2}
              >
                {
                  !DeckUI.user.wasUiElementSeen('conjugation-multiple-choice') &&
                    <PageOnboarding
                      uiElement='conjugation-multiple-choice'
                    >
                      <OnboardingText>
                        Click <span onClick={this.showTextEntry} style={{textDecoration: 'underline', cursor: 'pointer'}}>HERE</span> if you would like text entry for this card. You can change this at any time from your deck settings.
                      </OnboardingText>
                    </PageOnboarding>
                }
                <PromptText>
                  What is the conjugation of this verb?
                </PromptText>
              </CardSection>
          }
          <CardSection
            background="#FFF"
            numSections={this.props.revealed ? 1 : 2}
            borderTopRadius={this.props.revealed ? 10 : 0}
            borderBottomRadius={10}
            justifyContent={this.props.revealed ? "auto" : "center"}
          >
            {
              this.props.revealed &&
                <ConjugationCardRevealed
                  {...this.props}
                />
            }
            {
              !this.props.revealed &&
                <ConjugationCardPrompt
                  {...this.props}
                />
            }
          </CardSection>
        </CardContent>
        {
          !this.props.revealed &&
            <MultipleChoiceButtons
              blockFontCalculation
              width={DeckUI.layout.innerDeckWidth}
              height={pixify(DeckUI.layout.totalMultipleChoiceButtonHeight(this.props.buttonOptions?.length))}
              buttonHeight={DeckUI.layout.multipleChoiceButtonHeight}
              selected={[this.props.multipleChoiceSelected]}
              correct={this.props.correct}
              options={this.props.buttonOptions}
              onClick={(val) => this.props.onClickButton(val)}
              isMobile={DeckUI.layout.isMobile}
              field="displayValue"
              padding="5px"
              margin="0"
              fontSize={DeckUI.layout.minimumHeight ? "13px" : "16px"}
              disabled={!this.props.inView}
              languageId={this.props.card.languageId}
            />
        }
        {
          this.props.revealed &&
            <EvaluateButtons
              card={this.props.card}
              onNextClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard)}
              cardState={this.props.cardState}
              userCard={this.props.userCard}
              disabled={!this.props.inView}
            />
        }
      </CardWrapper>
    );
  }
}

export default ConjugationCardWrapper(ConjugationCardMultipleChoice);
