import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import Paginator from 'components/paginator';
import { Link } from 'react-router';
import Modal from 'components/modal';
import SourceListWithInput from 'components/source/source_list_with_input';
import UserProfileImage from 'components/user/user_profile_image';
import DeleteButton from 'components/button/delete_button';
import autobind from 'autobind-decorator';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceText from 'components/form/in_place_text';

const Wrapper = styled.div`
  table {
    margin-top: 20px;
  }
`;

const BackArrow = styled.div`
  margin: 10px 0;
  text-decoration: underline;
`;

const TargetText = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;

const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => props.width};
  flex: ${(props) => props.expand ? 1 : null};
  padding: 0 10px;
  border-right: 1px solid white;
  height: 30px;
  cursor: ${(props) => props.clickable ? "pointer" : "default"};
  color: #FFF;
  display: block;
  font-size: 10px;
  line-height: 11px;
  font-weight: bold;
  color: white;
  margin: 0;
  display: flex;
  i {
    color: #FFF;
    text-decoration: none;
    margin-right: 4px;
  }
  .fa-check {
    margin-right: 0;
  }
`;

const HeaderRow = styled.div`
  background: #636363;
  display: flex;
  align-items: center;
  height: 30px;
`;

const Table = styled.div`
  background: #FFF;
  margin-top: 20px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  padding: 5px 0;
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  width: ${(props) => props.width};
  flex: ${(props) => props.expand ? 1 : null};
  padding: 0 5px;
  .selection-button {
    width: 100%;
  }
  .icon-wrapper {
    cursor: pointer;
  }
`;

const NameWrapper = styled.div`
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const InputWrapper = styled.div`
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
`;

@observer
class TranslatorUser extends React.Component {
  @observable wordId = null;

  constructor(props) {
    super(props);
    this.loadUser();
    this.loadTranslationSources();
  }

  componentWillUnmount() {
    AppUI.translationSourceStore.clearIndexData();
  }

  @autobind removeAllTranslations() {
    AppUI.userStore.removeTranslations({ids: {userId: this.props.params.userId}}, this.loadTranslationSources)
  }

  @autobind loadTranslationSources() {
    AppUI.translationSourceStore.getIndex({filters: {"joins:source|language_id": this.props.params.languageId, user_id: this.props.params.userId}});
  }

  @autobind loadUser() {
    AppUI.userStore.getShow({ids: {userId: this.props.params.userId}});
  }

  @autobind toggleTranslator() {
    let data;
    if (AppUI.userStore.showData.translator) {
      data = {
        removed_as_translator: true,
        translator: false,
        translator_type: null,
      };
    } else {
      data = {
        removed_as_translator: false,
        translator: true,
      };
    }
    AppUI.userStore.update({ids: {userId: this.props.params.userId}, data: data}, this.loadUser)
  }

  @autobind afterRemoveAsTranslator() {
    AppUI.routeStore.routeToNamed("translator.users", {languageId: this.props.params.languageId}, this.loadUser);
  }

  @autobind toggleLeadTranslator() {
    const translatorType = AppUI.userStore.showData?.translatorType === 2 ? 3 : 2;
    AppUI.userStore.update({data: {translatorType: translatorType}, ids: {userId: this.props.params.userId}}, this.loadUser);
  }

  @autobind promoteTranslator() {
    AppUI.userStore.update({data: {translatorType: 2}, ids: {userId: this.props.params.userId}}, this.loadUser);
  }

  render () {
    return (
      <Wrapper>
        {
          this.wordId &&
            <Modal
              onCloseModal={() => this.wordId = null}
            >
              <SourceListWithInput
                showTargetText
                wordId={this.wordId}
                showInput
                fontSize="16px"
                lineHeight="20px"
                afterSubmitSourceInput={this.props.afterSubmitSourceInput}
              />
            </Modal>
        }
        <Link
          to={{name: 'translator.users', params: {languageId: this.props.params.languageId}}}
        >
          <BackArrow>
            <span role="img">⬅</span>️ All Translators
          </BackArrow>
        </Link>
        <NameWrapper>
          <UserProfileImage
            clickToProfile
            width="60px"
            user={AppUI.userStore.showData}
          />
          <div
            style={{margin: "0 0 0 10px"}}
          >
            {AppUI.userStore.showData?.name}, {AppUI.userStore.showData?.translatorRole}
          </div>
        </NameWrapper>
        <ButtonWrapper>
          {
            AppUI.user.admin && AppUI.userStore.showData?.translator && !AppUI.userStore.showData?.trialTranslator &&
              <button
                onClick={this.toggleLeadTranslator}
                className="gray-button"
                style={{margin: "5px 10px 0 0"}}
              >
                {AppUI.userStore.showData?.leadTranslator ? 'Remove as' : 'Add as'} Lead
              </button>
          }
          {
            AppUI.user.adminOrLeadTranslator && AppUI.userStore.showData?.trialTranslator && AppUI.userStore.showData?.translationSourcesCount >= 30 &&
              <DeleteButton
                message={`Promote this translator?`}
                onConfirm={this.promoteTranslator}
                allowUpdate
              >
                <button
                  className="gray-button"
                  style={{margin: "5px 10px 0 0"}}
                >
                  Promote after trial
                </button>
              </DeleteButton>
          }
          {
            AppUI.user.adminOrLeadTranslator &&
              <DeleteButton
                message={`${AppUI.userStore.showData?.translator ? 'Remove' : 'Add'} As Translator?`}
                onConfirm={this.toggleTranslator}
                allowUpdate
              >
                <button
                  className="gray-button"
                  style={{margin: "5px 10px 0 0"}}
                >
                  {AppUI.userStore.showData?.translator ? 'Remove' : 'Add'} As Translator
                </button>
              </DeleteButton>
          }
          {
            AppUI.user.adminOrLeadTranslator &&
              <DeleteButton
                message="Remove All Translations?"
                onConfirm={this.removeAllTranslations}
                allowUpdate
              >
                <button
                  className="gray-button"
                  style={{margin: "5px 10px 0 0"}}
                >
                  Remove All Translations
                </button>
              </DeleteButton>
          }
        </ButtonWrapper>
        <Table>
          <HeaderRow>
            <HeaderCell width="150px">Date</HeaderCell>
            <HeaderCell expand>Original</HeaderCell>
            <HeaderCell width="380px">Translation</HeaderCell>
            <HeaderCell width="100px">Accepted</HeaderCell>
          </HeaderRow>
          {
            AppUI.translationSourceStore.indexData.map(item => {
              return (
                <Row
                  key={item.id}
                >
                  <Cell
                    width="150px"
                  >
                    {item.createdAt.formattedDateWithYear}
                  </Cell>
                  <Cell
                    expand
                  >
                    {
                      item.translation.type === 'Word' &&
                        <TargetText
                          onClick={() => this.wordId = item.translation.id}
                        >
                          {item.translation?.targetText}
                        </TargetText>
                    }
                    {
                      item.translation.type !== 'Word' &&
                        <div>
                          {item.translation?.targetText}
                        </div>
                    }
                  </Cell>
                  <Cell
                    width="380px"
                  >
                    {
                      item.translation.type === 'Word' &&
                        <div>
                          {item.source.text}
                        </div>
                    }
                    {
                      item.translation.type !== 'Word' &&
                        <InputWrapper
                          fontSize={item.source?.rightToLeftLanguage ? '22px' : '14px'}
                          lineHeight={item.source?.rightToLeftLanguage ? '24px' : '16px'}
                        >
                          <InPlaceText
                            allowUpdate
                            field="text"
                            model="sources"
                            id={item.source.id}
                            defaultValue={item.source.text}
                          />
                        </InputWrapper>
                    }
                  </Cell>
                  <Cell
                    width="100px"
                  >
                    <InPlaceCheckbox
                      allowUpdate
                      model="translation_sources"
                      id={item.id}
                      field="accepted"
                      value={item.accepted}
                      icon="check"
                      bigIcon
                    />
                  </Cell>
                </Row>
              )
            })
          }
        </Table>
        <Paginator
          store={AppUI.translationSourceStore}
          maxPage={20}
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TranslatorUser);
