import React from 'react';
import { observer } from 'mobx-react';
import Sky from 'components/sky';
import Berlin from 'components/berlin';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`

`;

const BerlinBase = styled.div`
  position: fixed;
  z-index: 0;
  left: 0;
  height: 183px;
  bottom: 0;
  right: 0px;
  background: ${(props) => props.background};
`;

@observer
class Backdrop extends React.Component {

  static defaultProps = {
    background: "#032033",
  }

  render() {
    if (AppUI.siteIsDefault) return (
      <Wrapper>
        <Sky
          {...this.props}
        />
        <Berlin
          {...this.props}
        />
        <BerlinBase
          background={this.props.background}
        />
      </Wrapper>
    );

    return null;
  }
}

export default Backdrop;
