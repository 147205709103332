import React from 'react';
import { observer } from 'mobx-react';
import { TargetStore } from '@seedlang/stores';
import { Link } from 'react-router';
import DeleteButton from 'components/button/delete_button';
import Paginator from 'components/paginator';
import Filter from 'components/backend_filter/filter';
import CheckIfTrue from 'components/check_if_true';
import { LanguageIcon } from "@seedlang/icons";

@observer
class TargetIndex extends React.Component {

  componentDidMount() {
    TargetStore.setFilter('translation_type', ['Word', 'Sentence']);
  }

  onDelete(id) {
    TargetStore.destroy({ids: {targetId: id}}, TargetStore.reloadIndex.bind(TargetStore));
  }

  render() {
    return (
      <div className='target-index'>
        <Filter
          store={TargetStore}
          defaultSort="-translations_count"
          namespace="target"
          queryStrings={{include_count: true}}
          filters={
            [
              {
                type: 'text',
                name: 'text',
                label: 'Target',
                placeholder: 'Enter target',
                default: '',
              },
              {
                type: 'select',
                name: 'language_id',
                label: 'Language',
                options: [{id: 'DE', name: 'German'}, {id: 'ES', name: 'Spanish'}, {id: 'FR', name: 'French'}],
                default: '',
                labelField: 'name',
              },
              {
                type: 'multi_select',
                name: 'translation_type',
                label: 'Translation Type',
                options: [{id: 'Word', name: 'Word'}, {id: 'Sentence', name: 'Sentence'}],
                default: '',
              },
              {
                type: 'boolean',
                name: 'has_genders',
                label: 'Has Genders',
                default: '',
              },
              {
                type: 'boolean',
                name: 'video_clip_id',
                label: 'Has Video',
                default: '',
              },
              {
                type: 'number',
                name: 'translations_count',
                label: '# Translations',
                default: '',
              },
            ]
          }
        />
        <table className="table-wrapper admin-table">
          <thead>
            <tr>
              <th width="10"/>
              <th width="10"/>
              <th
                  width="120"
              >
                # Translations
              </th>
              <th>Target (normalized)</th>
              <th>Has video</th>
              <th>Type</th>
              <th>Has Genders</th>
              <th>Gender</th>
              <th/>
            </tr>
          </thead>
          <tbody>
            {
              TargetStore.indexData.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.targets.edit', params: { targetId: item.id } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>
                      <LanguageIcon
                          languageId={item.languageId}
                          margin="0 10px 0 0"
                      />
                    </td>
                    <td>
                      {item.translationsCount}
                    </td>
                    <td>
                      {item.text}
                    </td>
                    <td>
                      <CheckIfTrue
                          value={item.videoClip}
                      />
                    </td>
                    <td>
                      {item.translationType}
                    </td>
                    <td>
                      <CheckIfTrue
                        value={item.hasGenders}
                      />
                    </td>
                    <td>
                      {item.gender}
                    </td>
                    <td>
                      {
                        item.translationsCount === 0 &&
                          <DeleteButton
                            message="Delete this Target?"
                            onConfirm={() => this.onDelete(item.id)}
                            className="fa-block right"
                          />
                      }
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Paginator
          store={TargetStore}
        />
      </div>
    );
  }
}

export default TargetIndex;
