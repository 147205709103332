import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { Link } from 'react-router';
import InPlaceText from 'components/form/in_place_text';
import InPlaceSelect from 'components/form/in_place_select';
import autobind from 'autobind-decorator';
import { TreeUI } from '@seedlang/state';
import SentenceIndex from 'pages/builder/sentences/sentence_index';
import Modal from 'components/modal';
import SentenceCreate from 'pages/builder/sentences/sentence_create';
import { ConceptGrammarDeckStore, DeckGrammarConceptStore, DeckStore, TreeStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';
import InPlaceImageUpload from 'components/form/in_place_image_upload';
import SentenceSearch from 'components/sentence_search';
import InPlaceVideoUpload from 'components/form/in_place_video_upload';
import styled from '@emotion/styled';
import Spinner from 'components/spinner';
import ReactTooltip from 'react-tooltip';

const Wrapper = styled.div`
  .concept-studio {
    margin-top: 10px;
    .col-xs-6 {
      padding: 0;
    }
    .compact {
      td {
        padding: 3px;
      }
    }
    .name-wrapper {
      display: flex;
      .in-place-text-wrapper {
        flex: 1;
      }
    }
    .sentences-search {
      display: flex;
      .search {
        flex: 1;
        margin-left: 10px;
      }
    }
    .tab-body {
      background: white;
      border-left: 1px solid #CCC;
      border-bottom: 1px solid #CCC;
      border-right: 1px solid #CCC;
      padding: 10px;
      font-size: 14px;
      line-height: 18px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
`;

@observer
class ConceptStudio extends React.Component {
  @observable showCreateSentence = false;
  @observable selectedSentenceIds = [];
  @observable showCreateTriviaDeckSpinner = false;

  @computed get treeModule() {
    if (TreeStore.hasShowData) {
      return TreeStore.showData.treeModules.find(item => item.id === this.props.params.treeModuleId);
    }
    return {};
  }

  @computed get hasTreeModule() {
    return isPresent(this.treeModule);
  }

  constructor(props) {
    super(props);
    if (!TreeUI.hasTree) {
      this.loadTree();
    }
    TreeUI.treeNodeStore.getIndex();
    TreeUI.treeNodeStore.getShow({ids: {treeNodeId: this.props.params.treeNodeId}});
    this.loadConcept();
    this.loadSentences();
    this.loadConceptWords();
  }

  componentDidMount() {
    this.loadDecks();
  }

  componentWillUnmount() {
    TreeUI.sentenceConceptStore.clearIndexData();
  }

  @autobind loadDecks() {
    ConceptGrammarDeckStore.getIndex({ids: {grammarConceptId: this.props.params.conceptId}});
  }

  @autobind loadTree() {
    TreeStore.getShow({ids: {treeId: this.props.params.treeId}});
  }

  @autobind loadConcept() {
    TreeUI.conceptStore.getShow({ids: {conceptId: this.props.params.conceptId}});
  }

  @autobind loadSentences() {
    this.showCreateTriviaDeckSpinner = false;
    this.selectedSentenceIds = [];
    TreeUI.sentenceConceptStore.getIndex({ limit: 20, ids: { conceptId: this.props.routeParams.conceptId } });
  }

  @autobind onDeleteSentence(id) {
    TreeUI.sentenceConceptStore.destroyByIds({ data: { sentenceId: id, conceptId: this.props.params.conceptId } }, this.loadSentences);
  }

  @autobind loadConceptWords() {
    TreeUI.conceptWordStore.getIndex({ids: { conceptId: this.props.routeParams.conceptId }});
  }

  @autobind onAddSentence(sentence) {
    TreeUI.sentenceConceptStore.create({data: {sentenceId: sentence.id, conceptId: this.props.params.conceptId}}, this.loadSentences);
  }

  @autobind onAddDeck(deck) {
    DeckGrammarConceptStore.create({data: {deck_id: deck.id, concept_id: this.props.params.conceptId}}, this.loadDecks);
  }

  @autobind onCreateConceptWord(word) {
    TreeUI.conceptWordStore.create({ data: { concept_id: TreeUI.conceptStore.showDataField('id'), word_id: word.id } }, this.loadConceptWords);
  }

  @autobind onDestroyConceptWord(id) {
    TreeUI.conceptWordStore.destroy({ ids: { concept_id: TreeUI.conceptStore.showDataField('id'), word_id: id } }, this.loadConceptWords);
  }

  @autobind onClickCheckbox(id) {
    if (this.selectedSentenceIds.indexOf(id) === -1) {
      this.selectedSentenceIds.push(id);
    } else {
      this.selectedSentenceIds = this.selectedSentenceIds.filter(item => item !== id);
    }
  }

  @autobind onCreateTriviaDeck() {
    this.showCreateTriviaDeckSpinner = true;
    DeckStore.createFromSentences({data: {tree_node_id: this.props.params.treeNodeId, concept_id: TreeUI.conceptStore.showDataField('id'), sentence_ids: this.selectedSentenceIds}}, this.loadSentences);
  }

  render() {
    return (
      <Wrapper>
        <div className='concept-studio'>
          {
            this.showCreateSentence &&
              <Modal
                onCloseModal={() => {this.showCreateSentence = false}}
              >
                <SentenceCreate
                  afterCreate={this.loadSentences}
                  conceptId={TreeUI.conceptStore.showData.id}
                />
              </Modal>
          }
          <div className='inner'>
            <div className='breadcrumbs-wrapper'>
              <div className='breadcrumbs'>
                <Link
                  to={{name: 'builder.trees.index'}}
                >
                  Trees
                </Link>
                <i className='fa fa-angle-double-right' />
                <Link
                  to={{name: 'builder.trees.edit', params: {treeId: this.props.params.treeId}}}
                >
                  {TreeStore.hasShowData && TreeStore.showData.name}
                </Link>
                <i className='fa fa-angle-double-right' />
                <Link
                  to={{name: 'builder.tree_modules.edit', params: {treeModuleId: this.props.params.treeModuleId, treeId: this.props.params.treeId}}}
                >
                  {this.hasTreeModule && this.treeModule.name}
                </Link>
                <i className='fa fa-angle-double-right' />
                <Link
                  to={{name: 'builder.tree_nodes.edit', params: {treeModuleId: this.props.params.treeModuleId, treeRowId: this.props.params.treeRowId, treeId: this.props.params.treeId, treeNodeId: this.props.params.treeNodeId}}}
                >
                  {TreeUI.treeNodeStore.showData.name}
                </Link>
                <i className='fa fa-angle-double-right' />
                <div className='current'>
                  {TreeUI.conceptStore.showData.name}
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Name</legend>
                <div className='name-wrapper'>
                  {
                    TreeUI.conceptStore.hasShowData &&
                      <Link
                        to={{name: 'builder.concepts.edit', params: {conceptId: TreeUI.conceptStore.showDataField('id')}}}
                        style={{display: 'inlineBlock'}}
                      >
                        <i className='fa fa-external-link' />
                      </Link>
                  }
                  <InPlaceText
                    defaultValue={TreeUI.conceptStore.showDataField('name')}
                    model="concepts"
                    field="name"
                    id={TreeUI.conceptStore.showDataField('id')}
                  />
                </div>
              </fieldset>

              <fieldset>
                <legend>Grammar</legend>
                <InPlaceText
                  defaultValue={TreeUI.conceptStore.showDataField('displayNameLine1')}
                  model="concepts"
                  field="display_name_line_1"
                  id={TreeUI.conceptStore.showDataField('id')}
                />
              </fieldset>
            </div>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Tree Node</legend>
                <InPlaceSelect
                  options={TreeUI.treeNodeStore.indexData}
                  optionName="name"
                  model="concepts"
                  field="tree_node_id"
                  id={TreeUI.conceptStore.showData.id}
                  value={this.props.params.treeNodeId}
                  afterChange={this.redirectToTreeNode}
                />
              </fieldset>
            </div>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Image</legend>
                <InPlaceImageUpload
                  model="images"
                  image={TreeUI.conceptStore.showData.image}
                  onChange={this.loadConcept}
                  querystring={{
                    imageable_id: TreeUI.conceptStore.showDataField('id'),
                    imageable_type: 'Concept'
                  }}
                />
              </fieldset>
            </div>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Video</legend>
                <InPlaceVideoUpload
                  model="video_clips"
                  removeModel="concepts"
                  removeId={TreeUI.conceptStore.showData.id}
                  videoClip={TreeUI.conceptStore.showData.videoClip}
                  onChange={this.loadConcept}
                  querystring={{
                    videoable_id: TreeUI.conceptStore.showDataField('id'),
                    videoable_type: 'Concept',
                  }}
                />
              </fieldset>
            </div>
          </div>
          {
            TreeStore.showData?.slug === 'trivia' && TreeUI.conceptStore.hasShowData && TreeUI.conceptStore.showData.name.match('Unpublished') &&
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Create A Trivia Deck</legend>
                    <div>
                      {this.selectedSentenceIds.length} Sentences Selected (7 Needed)
                    </div>
                    {
                      this.selectedSentenceIds.length === 7 && !this.showCreateTriviaDeckSpinner &&
                        <button
                          className='gray-button'
                          onClick={this.onCreateTriviaDeck}
                        >
                          Create Trivia Deck
                        </button>
                    }
                    {
                      this.showCreateTriviaDeckSpinner &&
                        <Spinner />
                    }
                  </fieldset>
                </div>
              </div>
          }
          <div className="row">
            <div className="col-xs-12">
              <fieldset>
                <legend>
                  <span>Decks (as grammar concept)</span>
                  <span
                    data-for="nodesGrammarDescription"
                    data-tip
                    style={{marginLeft: '5px'}}
                  >
                    <i className='fa fa-info-circle' />
                    <ReactTooltip
                      place="right"
                      type="dark"
                      effect="solid"
                      id="nodesGrammarDescription"
                      className="custom-tooltip"
                    >
                      <div>These decks use this concept in their grammar description.</div>
                    </ReactTooltip>
                  </span>
                </legend>

                <table className="table-wrapper admin-table" style={{marginBottom: '10px'}}>
                  <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    ConceptGrammarDeckStore.indexData.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <Link
                              to={{name: 'builder.decks.edit', params: {deckId: item.id}}}
                              className="on-click"
                            >
                              <i className="fa fa-chevron-right fa-block" />
                            </Link>
                          </td>
                          <td>
                            {item.name}
                          </td>
                        </tr>
                      )
                    })
                  }
                  </tbody>
                </table>
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Sentences</legend>
                <div className='sentences-search'>
                  <button
                    className='gray-button'
                    style={{marginBottom: "10px"}}
                    onClick={() => {this.showCreateSentence = true}}
                  >
                    <i className='fa fa-plus' />
                    Create
                  </button>
                  <div className='search'>
                    <SentenceSearch
                      onSubmit={this.onAddSentence}
                    />
                  </div>
                </div>
                <SentenceIndex
                  selectedSentenceIds={this.selectedSentenceIds}
                  showCheckbox={TreeUI.conceptStore.hasShowData ? TreeUI.conceptStore.showData.name.match('Unpublished') : false}
                  onClickCheckbox={this.onClickCheckbox}
                  showVideoColumns
                  showUseInFeedEntryColumn
                  showCarryOverSupplement
                  showSource
                  hideSupplement={TreeStore.showData?.slug === 'trivia'}
                  showCases
                  conceptId={this.props.routeParams.conceptId}
                  hide={['creator', 'createdAt', 'tag', 'public', 'ENSource']}
                  sentences={TreeUI.sentenceConceptStore.indexData}
                  onDelete={this.onDeleteSentence}
                  store={TreeUI.sentenceConceptStore}
                  deletePrompt="Remove this sentence from this concept?"
                  ids={{ conceptId: this.props.routeParams.conceptId }}
                  sentencesMaintainOrdering={TreeUI.conceptStore.showData.sentencesMaintainOrdering}
                  onChangePosition={this.loadSentences}
                  onChangeMedia={this.loadSentences}
                  scoped
                />
              </fieldset>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default ConceptStudio;
