import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { DeckUI } from '@seedlang/state';
import TextDisplay from 'components/text_display';

@observer
class WordTypeDescription extends React.Component {

  constructor(props) {
    super(props);
    DeckUI.wordTypeStore.getShow({ids: {wordTypeId: this.props.wordTypeId}});
  }

  render() {
    return (
      <TextDisplay
        markdown={DeckUI.wordTypeStore.showData.descriptionMd}
      />
    );
  }
}

export default ErrorBoundary(WordTypeDescription);
