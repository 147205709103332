import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';

@observer
class SquareButton extends React.Component {

  render() {
    return (
      <Button
        fontSize="14px"
        borderRadius="5px"
        height="32px"
        padding="0 15px"
        fontWeight="300"
        margin={this.props.margin}
        background={this.props.outline ? "#FFF" : Theme.blue}
        color={this.props.outline ? Theme.blue : "#FFF"}
        border={this.props.outline ? `1px solid ${Theme.blue}` : "none"}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </Button>
    );
  }
}

export default ErrorBoundary(SquareButton);
