"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = amountWithCurrency;
function amountWithCurrency(amount, currency) {
  var normalizedCurrency = currency ? currency.toUpperCase() : currency;
  var currencySymbol = normalizedCurrency === 'USD' ? '$' : '€';
  var seperator = normalizedCurrency === 'USD' ? '.' : ',';
  return "".concat(currencySymbol).concat(amount.toFixed(2).replace(".", seperator));
}