import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import React from 'react';
import { isPresent, pixify } from '@seedlang/utils';
import autobind from 'autobind-decorator';

@observer
class InPlaceTextInput extends React.Component {
  @observable temporaryValue = '';

  static defaultProps = {
    style: {},
  }

  @observable expanded = false;

  @computed get height() {
    return this.expanded ? pixify(this.props.expandedHeight) : pixify(this.props.height);
  }

  @computed get submitOnEnter() {
    if (!this.props.submitOnEnter) {
      return this.props.inputType === 'text' ? true: false;
    }
    return this.props.submitOnEnter;
  }

  componentDidMount() {
    if (this.props.focusOnMount || this.props.focus) {
      this.focusInputDelayed();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.caretPosition && this.props.caretPosition !== prevProps.caretPosition) {
      this.setCaretPosition();
    } else if ((this.props.focusOnMount && !prevProps.focusOnMount) || (!prevProps.focus && this.props.focus)) {
      this.focusInputDelayed();
    }
  }

  @autobind onKeyUp(e) {
    if (e.keyCode === 27) {
      this.refs.inputField.blur();
    } else if (this.submitOnEnter && e.keyCode === 13 && (!this.props.rejectBlank || isPresent(this.refs.inputField.value))) {
      this.props.onSubmit(this.refs.inputField.value);
      if (this.props.contractOnSubmit) {
        this.expanded = false;
      }
      if (this.props.clearOnEnter) {
        this.refs.inputField.value = '';
        this.props.onChange('');
      }
    }
  }

  @autobind hasNumber(value) {
    return isPresent(value) && value.match(/\d/);
  }

  setCaretPosition() {
    if (this.refs.inputField.setSelectionRange) {
      this.focusInput();
      this.refs.inputField.setSelectionRange(this.props.caretPosition, this.props.caretPosition);
    } else if (this.refs.inputField.createTextRange) {
      var range = this.refs.inputField.createTextRange();
      range.collapse(true);
      range.moveEnd('character', this.props.caretPosition);
      range.moveStart('character', this.props.caretPosition);
      range.select();
    }
  }


  @autobind onChange() {
    if (this.props.blurOnNumber && this.hasNumber(this.refs.inputField.value)) {
      this.refs.inputField.blur();
    } else if (!this.props.readOnly && this.props.onChange) {
       this.props.onChange(this.refs.inputField.value);
    }
  }

  @autobind onFocus() {
    this.expanded = true;
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  @autobind focusInputDelayed() {
    // without this there is a problem with the card animation
    setTimeout(this.focusInput, 100);
  }

  @autobind focusInput() {
    if (this.refs.inputField) {
      this.refs.inputField.focus();
    }
  }

  render() {
    if (this.props.inputType === 'textarea') {
      return (
        <textarea
          ref="inputField"
          onKeyUp={this.onKeyUp}
          onKeyDown={this.props.onKeyDown}
          placeholder={this.props.placeholder}
          onChange={this.onChange}
          onFocus={this.onFocus}
          value={this.props.value || ''}
          onBlur={this.props.onBlur}
          style={Object.assign({}, this.props.style, {height: this.height})}
          disabled={this.props.disabled}
          id={this.props.id}
        />
      )
    }
    return (
      <input
        type={this.props.type || "text"}
        ref="inputField"
        spellCheck="false"
        step={this.props.step}
        max={this.props.max}
        min={this.props.min}
        maxLength={this.props.maxLength}
        onKeyUp={this.onKeyUp}
        onKeyDown={this.props.onKeyDown}
        placeholder={this.props.placeholder}
        onChange={this.onChange}
        onFocus={this.onFocus}
        value={isPresent(this.props.value) ? this.props.value : this.temporaryValue}
        onBlur={this.props.onBlur}
        style={this.props.style}
        disabled={this.props.disabled}
        readOnly={this.props.readOnly}
        tabIndex={this.props.tabIndex}
        id={this.props.id}
        autoComplete={this.props.showAutoComplete ? "on" : "off"}
      />
    );
  }
}

export default InPlaceTextInput;
