import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { MembershipGroupStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import MembershipIndex from 'pages/builder/memberships/membership_index';
import autobind from 'autobind-decorator';
import Copy from 'components/copy';

@observer
class MembershipGroupEdit extends React.Component {
  static defaultProps = {
    whiteLabeled: false
  }

  componentDidMount() {
    this.getMembershipGroup();
  }

  @computed get link() {
    return `http://${window.location.hostname}/assign_membership_group/${this.props.params.membershipGroupId}`;
  }

  @autobind getMembershipGroup() {
    MembershipGroupStore.getShow({ids: {membershipGroupId: this.props.params.membershipGroupId}}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    MembershipGroupStore.setShowData(resp);
  }

  render() {
    return (
      <div className='membership-type-edit'>
        <div className='row'>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Name</legend>
              <InPlaceText
                id={MembershipGroupStore.showData.id}
                defaultValue={MembershipGroupStore.showData.name}
                field="name"
                model="membership_groups"
              />
            </fieldset>
          </div>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Slug</legend>
              <InPlaceText
                id={MembershipGroupStore.showData.id}
                defaultValue={MembershipGroupStore.showData.slug}
                field="slug"
                model="membership_groups"
              />
            </fieldset>
          </div>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Link</legend>
              <div style={{display: 'flex', alignItems: 'center', fontSize: '15px'}}>
                <Copy
                  text={this.link}
                />
                <small style={{marginLeft: 5}}>Use this link to assign a user to this membership group</small>
              </div>
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Memberships</legend>
              {
                MembershipGroupStore.hasShowData &&
                  <MembershipIndex
                    whiteLabeled={this.props.whiteLabeled}
                    groupId={this.props.params.groupId}
                    memberships={MembershipGroupStore.showData.memberships}
                    membershipGroupId={this.props.params.membershipGroupId}
                    afterChange={this.getMembershipGroup}
                  />
              }
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorBoundary(MembershipGroupEdit);
