import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexLeftColumn } from '@seedlang/style_mixins';
import Text from 'components/text';

const Wrapper = styled.div`
  ${flexLeftColumn()}
  margin: 0 0 20px 0;
  color: white;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
`;

const Key = styled.div`
  width: 120px;
`;

const Name = styled.div`
  flex: 1;
`;

@observer
class ShortcutBlock extends React.Component {

  render() {
    return (
      <Wrapper>
        <Title>
          <Key>
            <Text
              color="#FFF"
              bold
            >
              {this.props.abbreviation}
            </Text>
          </Key>
          <Name>
            <Text
              bold
              textAlign="left"
              color="#FFF"
            >
              {this.props.name}
            </Text>
            {
              this.props.description &&
                <Text
                  textAlign="left"
                  color="#FFF"
                  margin="10px 0 0 0"
                  fontSize="14px"
                >
                  {this.props.description}
                </Text>
            }
          </Name>
        </Title>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ShortcutBlock);
