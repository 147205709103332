import React from 'react';
import { observer } from 'mobx-react';
import { DeckSummaryCardWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { DeckUI, AppUI } from '@seedlang/state';
import CardButtons from 'components/card/card_buttons';
import { flexCenter } from '@seedlang/style_mixins';
import Text from 'components/text';
import TranslationWithSource from 'components/translation_with_source';
import { Theme } from '@seedlang/constants';
import { LearnedIcon } from '@seedlang/icons';
import { pixify, isPresent } from '@seedlang/utils';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import NextButton from 'components/button/next_button';
import SelectionButton from 'components/button/selection_button';
import InPlaceSelect from 'components/form/in_place_select';
import { CheckWithCircleIcon } from '@seedlang/icons';

const Table = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

const FaqTitleWrapper = styled.div`
  text-align: center;
  font-style: italic;
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 10px;
  background: ${(props) => props.background};
  text-align: left;
  position: relative;
  justify-content: flex-end;
  width: 100%;
  min-height: 40px;
  align-items: center;
  .inner-row {
    display: flex;
    width: 100%;
  }
  .already-added {
    font-size: 12px;
    font-style: italic;
    color: ${Theme.gray};
  }
`;

const ButtonWrapper = styled.div`
  ${flexCenter()}
  width: 120px;
  .bookmark-icon {
    svg {
      width: 14px;
    }
    #background {
      fill: ${Theme.blue};
    }
    #border {
      fill: #FFF;
    }
    margin-right: 3px;
  }
  .star-with-circle-icon {
    fill: #FFF;
    margin-right: 3px;
  }
`;

const Reviewing = styled.div`
  ${flexCenter()}
  font-size: 12px;
  cursor: pointer;
  .icon-wrapper {
    height: 18px;
    margin-right: 5px;
  }
  svg {
    fill: ${Theme.green};
    width: 18px;
  }
`;

const InnerContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  padding: 20px 10px;
  background: white;
  overflow: auto;
  margin-bottom: 10px;
  border-radius: 10px;
`;

const Learned = styled.div`
  ${flexCenter()}
`;

const Result = styled.div`
  ${flexCenter()}
  width: 30px;
  .fa-check {
    color: ${Theme.green};
  }
  .fa-times {
    color: ${Theme.red};
  }
`;

const Question = styled.div`
  margin: 5px 0;
  font-weight: bold;
`;

const Answer = styled.div`
  padding: 0 10px 10px 10px;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-top: 5px;
    cursor: pointer;
  }
`;

const FaqWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
`;

const Faq = styled.div`
  background: ${props => props.background};
  padding: 10px 0;
`;

const Header = styled.div`
  cursor: pointer;
  i {
    margin: 4px 0 0 5px;
    font-size: 17px;
  }
`;

const TranslationWrapper = styled.div`
  flex: 1;
  font-weight: ${props => props.fontWeight};
  font-size: 14px;
  display: flex;
`;

const CardTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  background: whitesmoke;
  padding: 5px 0;
`;

@observer
class DeckSummaryCard extends React.Component {

  render() {
    return (
      <CardWrapper>
        <CardContent
          scroll
        >
          <InnerContent>
            <Header>
              <Text
                heading="3"
              >
                Create Reviews
              </Text>
            </Header>
            <FaqTitleWrapper
              onClick={this.props.toggleShowFaq}
            >
              What are Reviews?
            </FaqTitleWrapper>
            {
              this.props.showFaq &&
                <FaqWrapper>
                  {
                    this.props.faqItems.map((item, index) => {
                      return (
                        <Faq
                          key={item.question}
                          background={index % 2 === 0 ? 'whitesmoke' : '#FFF'}
                        >
                          <Question>
                            {item.question}
                          </Question>
                          <Answer>
                            {item.answerWeb || item.answer}
                            {
                              item.image &&
                                <img
                                  style={{width: pixify(item.imageWidth)}}
                                  src={`/images/${item.image}`}
                                  onClick={() => AppUI.routeStore.routeToNamed(item.webRoute)}
                                  alt=""
                                />
                            }
                          </Answer>
                        </Faq>
                      )
                    })
                  }
                </FaqWrapper>
            }
            {
              this.props.reviewDeckOptions.length > 1 &&
                <InPlaceSelect
                  allowUpdate
                  margin="20px 0 0 0"
                  options={this.props.reviewDeckOptions}
                  value={this.props.deckId}
                  onChange={(val) => this.props.onSetDeckId(val)}
                />
            }
            <Table>
              {
                this.props.cardTypesForPotentialReview.map(item => {
                  return (
                    <CardTypeWrapper
                      key={item.cardTypeId}
                    >
                      <Row>
                        <TranslationWrapper
                          fontWeight="bold"
                        >
                          {item.cardTypeName} Cards
                        </TranslationWrapper>
                        {
                          !this.props.allReviewsSelectedForCardType(item.cardTypeId) && this.props.cardsForPotentialReview && this.props.cardsForPotentialReview.filter(card => card.cardType.id === item.cardTypeId).length > 1 &&
                            <ButtonWrapper>
                              <SelectionButton
                                width="110px"
                                background="#FFF"
                                icon={<i className='fa fa-plus' />}
                                onClick={() => this.props.onReviewAllForCardType(item.cardTypeId)}
                              >
                                Review All
                              </SelectionButton>
                            </ButtonWrapper>
                        }
                      </Row>
                      {
                        this.props.cardsForPotentialReview?.filter(card => card.cardType.id === item.cardTypeId && isPresent(card.translation)).map((card, index) => {
                          const reviewCard = this.props.reviewCards?.find(reviewCard => (
                            ((card.word && reviewCard.translationId === card.word.id)
                            || (card.sentence && reviewCard.translationId === card.sentence.id))
                            && reviewCard.cardTypeId === card.cardTypeId
                            && reviewCard.deckId === this.props.deckId
                          ));
                          const decksWithReviewCard = this.props.reviewCards?.filter(reviewCard => (
                            ((card.word && reviewCard.translationId === card.word.id)
                            || (card.sentence && reviewCard.translationId === card.sentence.id))
                            && reviewCard.cardTypeId === card.cardTypeId
                            && reviewCard.deckId !== this.props.deckId
                          ))?.map(reviewCard => this.props.reviewDecks?.find(reviewDeck => reviewDeck.id === reviewCard.deckId)?.name).join(', ');
                          return (
                            <Row
                              key={card.id}
                            >
                              <div
                                className="inner-row"
                              >
                                <TranslationWrapper>
                                  <TranslationWithSource
                                    flex="none"
                                    translation={card.translation}
                                    languageButtonWrapperWidth="18px"
                                    user={DeckUI.user}
                                  />
                                </TranslationWrapper>
                                {
                                  (card.cardType?.slug === 'gender' || card.cardType?.slug === 'plural' || card.cardType?.slug === 'conjugation') &&
                                  <Result>
                                    {this.props.userCardForId(card.id).firstAnswerCorrect && <i className='fa fa-check' />}
                                    {!this.props.userCardForId(card.id).firstAnswerCorrect && <i className='fa fa-times' />}
                                  </Result>
                                }
                                {
                                  ["vocab_word_translate", "gender", "plural", "conjugation", "number_options", "number_translate"].indexOf(card.cardType?.slug) !== -1 && card.word &&
                                  <Learned>
                                    <LearnedIcon
                                      showTooltip
                                      width="28px"
                                      id={card.word.id}
                                      selected={this.props.isLearned(card.word.id)}
                                      onClick={() => this.props.onToggleLearned(card.word.id)}
                                    />
                                  </Learned>
                                }
                                <ButtonWrapper>
                                  {
                                    !reviewCard &&
                                      <SelectionButton
                                        width="110px"
                                        icon={<i className='fa fa-plus' />}
                                        onClick={() => DeckUI.onToggleReview(card, this.props.deckId)}
                                        background={Theme.blue}
                                        color="white"
                                        border="none"
                                      >
                                        Review
                                      </SelectionButton>
                                  }
                                  {
                                    reviewCard &&
                                    <Reviewing
                                      onClick={() => DeckUI.onToggleReview(card, this.props.deckId)}
                                    >
                                      <CheckWithCircleIcon />
                                      Reviewing
                                    </Reviewing>
                                  }
                                </ButtonWrapper>
                              </div>
                              {
                                isPresent(decksWithReviewCard) &&
                                  <div
                                    className="already-added"
                                  >
                                    {isPresent(reviewCard) ? 'Also' : 'Already'} added to {decksWithReviewCard}.
                                  </div>
                              }
                            </Row>
                          )
                        })
                      }
                    </CardTypeWrapper>
                  )
                })
              }
            </Table>
          </InnerContent>
        </CardContent>
        <CardButtons>
          <NextButton
            text="Finish Deck"
            disabled={!this.props.inView}
            onClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard)}
            shortcut="1"
            submitOnEnter
          />
        </CardButtons>
      </CardWrapper>
    );
  }
}

export default DeckSummaryCardWrapper(DeckSummaryCard);
