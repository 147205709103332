import React from 'react';
import { observer } from 'mobx-react';
import { PaymentStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import InPlaceDatePicker from 'components/form/in_place_date_picker';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import Button from 'components/button/button';

@observer
class PaymentEdit extends React.Component {

  componentDidMount() {
    this.getPayment();
  }

  @autobind getPayment() {
    PaymentStore.getShow({ ids: {paymentId: this.props.params.paymentId }});
  }

  @autobind onSubmitRefund() {
    PaymentStore.refund({ ids: {paymentId: this.props.params.paymentId }}, this.getPayment);
  }

  render() {
    return (
      <div className='payment-edit'>
        {
          this.props.params.subscriptionId &&
            <Link
              to={{name: 'builder.subscriptions.edit', params: {subscriptionId: this.props.params.subscriptionId}}}
              className="underline"
            >
              <i className='fa fa-caret-left' />
              Return to Subscription
            </Link>
        }
        <div className="row">
          <div className="col-xs-4">
            <fieldset>
              <legend>Amount</legend>
              <InPlaceText
                defaultValue={PaymentStore.showDataField('amount')}
                model="payments"
                field="amount"
                id={PaymentStore.showDataField('id')}
                afterChange={this.getPayment}
              />
            </fieldset>
          </div>
          <div className="col-xs-4">
            <fieldset>
              <legend>Currency</legend>
              <InPlaceText
                defaultValue={PaymentStore.showDataField('currency')}
                model="payments"
                field="currency"
                id={PaymentStore.showDataField('id')}
                afterChange={this.getPayment}
              />
            </fieldset>
          </div>
          <div className="col-xs-4">
            <fieldset>
              <legend>Occurred At</legend>
              <InPlaceDatePicker
                value={PaymentStore.showDataField('occurredAt')}
                model="payments"
                field="occurredAt"
                id={PaymentStore.showDataField('id')}
                afterChange={this.getPayment}
              />
            </fieldset>
          </div>
          <div className="col-xs-4">
            <fieldset>
              <legend>Refunded At</legend>
              <InPlaceDatePicker
                value={PaymentStore.showDataField('refundedAt')}
                model="payments"
                field="refundedAt"
                id={PaymentStore.showDataField('id')}
                afterChange={this.getPayment}
              />
            </fieldset>
          </div>
          <div className="col-xs-4">
            <fieldset>
              <legend>Transaction Id</legend>
              <InPlaceText
                defaultValue={PaymentStore.showDataField('transactionId')}
                model="payments"
                field="transactionId"
                id={PaymentStore.showDataField('id')}
              />
              {
                PaymentStore.showDataField('transactionId') && PaymentStore.showDataField('transactionId').match(/^ch_/) &&
                  <a
                    href={`https://dashboard.stripe.com/payments/${PaymentStore.showDataField('transactionId')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      style={{marginTop: 10}}
                    >
                      Open on Stripe
                    </Button>
                  </a>
              }
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentEdit;
