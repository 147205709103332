import React from 'react';
import { observer } from 'mobx-react';
import Filter from 'components/backend_filter/filter';
import Paginator from 'components/paginator';
import VideoClipIndex from 'pages/builder/video_clips/video_clip_index';
import VideoClipsContainer from 'pages/creator/video_clips/video_clips_container';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  .video-clips {
    .tab-body {
      background: white;
      padding: 10px;
    }
    .video-player-wrapper {
      margin-top: 20px;
    }
    .filter input {
      width: 100%;
    }
    .filter-component {
      width: 100%;
      margin-bottom: 10px;
    }
    .in-place-select {
      margin-bottom: 10px;
    }
  }
`;

@observer
class VideoClips extends React.Component {

  render() {
    return (
      <Wrapper>
        <div className="video-clips">
          <div className="flex-row">
            <div style={{ flex: 1, marginRight: 20 }}>
              <fieldset>
                <legend>Video Clips</legend>
                <div className="flex-row">
                  <div style={{ flex: 1, marginRight: 20 }}>
                    <Filter
                      store={this.props.videoClipStore}
                      defaultSort="-created_at"
                      namespace="video-clip-index"
                      filters={
                      [
                        {
                          type: 'text',
                          name: 'text',
                          default: '',
                          placeholder: 'Search for Video Clip',
                        },
                      ]
                      }
                    />
                  </div>
                </div>
                <VideoClipIndex
                  videoClips={this.props.videoClipStore.indexData}
                  videoableId={this.props.videoableId}
                  onDeleteVideoClip={this.props.onDeleteVideoClip}
                  onPlayVideo={this.props.onPlayVideo}
                  onSetDefault={this.props.onSetDefault}
                  onSetVideoable={this.props.onSetVideoable}
                />
                <Paginator
                  store={this.props.videoClipStore}
                />
              </fieldset>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default VideoClipsContainer(VideoClips);
