import React from 'react';
import { observer } from 'mobx-react';
import styled from "@emotion/styled";

const Wrapper = styled.div`
  border-radius: 10px;
  background: ${props => props.background};
  align-items: center;
  padding: 10px 20px 10px 10px;
  margin-bottom: 10px;
  font-size: 16px;
  display: flex;
  i {
    margin: 0px 10px 5px 5px;
  }
`;

@observer
class BackEndExplainer extends React.Component {

  static defaultProps = {
    background: "#ffffffad",
  }

  render() {
    return (
      <Wrapper
        background={this.props.background}
      >
        <i className='fa fa-lightbulb-o' />
        <div>{this.props.children}</div>
      </Wrapper>
    )
  }
}

export default BackEndExplainer;
