import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { DailyActivityWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import DailyActivityCalendar from 'components/daily_activity/daily_activity_calendar';
import DailyActivityList from 'components/daily_activity/daily_activity_list';
import { AppUI } from '@seedlang/state';
import { Theme } from '@seedlang/constants';
import DailyActivityHeader from 'components/daily_activity/daily_activity_header';
import DailyActivityFooter from 'components/daily_activity/daily_activity_footer';
import DailyActivityInfo from 'components/daily_activity/daily_activity_info';
import Text from 'components/text';
import { isPresent } from "@seedlang/utils";
import ProgressBar from "components/progress_bar";

const Wrapper = styled.div`
  width: 210px;
`;

const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 10px 0;
`;

const Button = styled.div`
  color: ${(props) => props.selected ? "#FFF" : Theme.green};
  background: ${(props) => props.selected ? Theme.green : "#FFF"};
  border: 1px solid ${Theme.green};
  font-size: 11px;
  line-height: 15px;
  padding: 2px 10px;
  border-radius: 25px;
  cursor: pointer;
  margin: 0 2px;
`;

const RepairStreakWrapper = styled.div`
  margin-top: 10px;
  font-size: 12px;
  line-height: 14px;
`;

const ProgressText = styled.div`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 3px;
  bottom: 0;
  color: rgba(255, 255, 255, 0.9);
  font-size: 12px;
  text-align: center;
  line-height: 14px;
`;

@observer
class DailyActivity extends React.Component {
  @observable showInfo = false;

  render() {
    return (
      <Wrapper>
        {
          !this.showInfo &&
            <DailyActivityHeader
              {...this.props}
            />
        }
        {
          AppUI.user.xpView === 'calendar' && !this.showInfo &&
            <DailyActivityCalendar
              {...this.props}
              showFlags={this.props.user.targetLanguagesWithXpCount > 1}
            />
        }
        {
          AppUI.user.xpView !== 'calendar' && !this.showInfo &&
            <DailyActivityList
              {...this.props}
              showFlags={this.props.user.targetLanguagesWithXpCount > 1}
            />
        }
        {
          this.showInfo &&
            <DailyActivityInfo
              hideInfo={() => this.showInfo = false}
            />
        }
        <DailyActivityFooter
          showInfo={this.showInfo}
          toggleInfo={() => this.showInfo = !this.showInfo}
          {...this.props}
        />
        {
          this.props.showStreakExtendFooter &&
          <RepairStreakWrapper>
            <ProgressBar
              current={this.props.user.currentDayXp}
              total={this.props.streakExtendGoal}
              height="20px"
              borderTopRightRadius="5px"
              borderTopLeftRadius="5px"
              borderBottomRightRadius="5px"
              borderBottomLeftRadius="5px"
              currentBackground={this.props.user.streakCanBeExtended ? Theme.orange : Theme.green}
              background="#5b5b5b"
            >
              <ProgressText>{this.props.user.currentDayXp} XP / {this.props.streakExtendGoal} XP</ProgressText>
            </ProgressBar>
              <Text fontSize='12px' lineHeight='14px' margin='5px 0 0 0'>
                { this.props.user.streakCanBeExtended && isPresent(this.props.dayBeforeStreakStart) &&`Add ${this.props.dayBeforeStreakStart?.format("MMMM D")} to your streak by reaching ${this.props.streakExtendGoal}XP today!`}
                { !this.props.user.streakCanBeExtended  && this.props.streakWasExtendedToday && isPresent(this.props.streakExtendGoal) && `Congrats for reaching  ${this.props.streakExtendGoal}XP! We've extended your streak.`}
              </Text>
          </RepairStreakWrapper>
        }
      </Wrapper>
    );
  }
}

export default DailyActivityWrapper(DailyActivity);
