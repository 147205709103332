import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import { AppUI, ExerciseUI } from '@seedlang/state';
import { isPresent } from '@seedlang/utils';
import { Theme } from "@seedlang/constants";
import { computed } from "mobx";
import { Link } from "react-router";
import { startCase } from "lodash";
import NoAccessButton from "components/worksheet/no_access_button";


const Wrapper = styled.div`
  padding: 20px;
  background: white;
  border-radius: ${props => props.borderRadius};
`;

const AdminLink = styled.div`
  margin-top: 5px;
  font-size: 11px;
  text-decoration: underline;
  text-align: center;
  padding: 5px;
`;

const Table = styled.table`
  width: 100%;
  td {
    padding: 5px;
    vertical-align: middle;
  }
`;

const RSSLink = styled.span`
  display: flex;
  font-size: 14px;
  align-items: center;
  margin: 0 10px;
  color: #333333;
  cursor: pointer;
  span {
    text-decoration: underline;
  }
  i {
    margin-right: 5px;
  }
  span:hover {
    text-decoration: none;
  }
  justify-content: ${props => props.justifyContent};
`;

@observer
class DownloadLinkIndex extends React.Component {

  @computed get worksheet() {
    if (isPresent(this.props.worksheet)) {
      return this.props.worksheet;
    } else if (ExerciseUI.hasWorksheet) {
      return ExerciseUI.worksheet;
    }
  }

  render () {
    return (
      <Wrapper borderRadius={this.props.borderRadius}>
        {
          isPresent(this.worksheet) &&
            <Table className={`${AppUI.layout.isDesktop ? 'table-striped' : ''} table-hover table-responsive`}>
              <tbody>
                {
                  this.worksheet.downloadLinks.map(item => {
                    return (
                      <React.Fragment key={item.id}>
                      <tr>
                        <td>
                          <i className={`fa fa-${item.icon || 'link'}`} style={{margin: "5px 10px 5px 20px"}}/>
                        </td>
                        <td style={{display: 'flex', minHeight: '40px', justifyContent: "space-between"}}>
                          <span>{`${startCase(item.title.toLowerCase())}${isPresent(item.subtitle) ? ' - ' : ''}${item.subtitle || ''}`}</span>
                          {
                            !item.blocked && isPresent(item.podcastTitle) && !AppUI.layout.isMobile &&
                              <Link
                                to={{name: "settings.rss_feed"}}
                                target='_blank'
                              >
                                <RSSLink>
                                  <i className='fa fa-rss' /><span>Also available as a podcast feed</span>
                                </RSSLink>
                              </Link>
                          }
                        </td>
                        <td>
                          {
                            !AppUI.siteIsDefault && item.blocked &&
                              <Link
                                to="memberships.index"
                                style={{margin: "5px"}}
                              >
                                <Button
                                  height="30px"
                                  fontSize="14px"
                                  color="#FFF"
                                  borderRadius="5px"
                                  background={"#AAA"}
                                  scaleOnHover={AppUI.layout.isMobile ? null : "1.03"}
                                  backgroundOnActive={Theme.gray}
                                  width='90%'
                                  iconMarginRight={AppUI.layout.isMobile ? '0px' : '3px'}
                                >
                                  <i className={`fa fa-lock`}/>{AppUI.layout.isMobile ? '' : ' Upgrade to Unlock'}
                                </Button>
                              </Link>
                          }
                          {
                            !AppUI.siteIsDefault && item.blockedUntilMigrated && !item.blocked &&
                              <Link
                                to="settings.billing"
                                style={{margin: "5px"}}
                              >
                                <Button
                                  height="30px"
                                  fontSize="14px"
                                  color="#FFF"
                                  borderRadius="5px"
                                  background={AppUI.site.accentColor || "#D8D8D8"}
                                  scaleOnHover={AppUI.layout.isMobile ? null : "1.03"}
                                  backgroundOnActive={AppUI.site.accentColor || "#D8D8D8"}
                                  width='90%'
                                  iconMarginRight={AppUI.layout.isMobile ? '0px' : '3px'}
                                >
                                  <i className={`fa fa-lock`}/>{AppUI.layout.isMobile ? '' : "Switch Payment to our Platform"}
                                </Button>
                              </Link>
                          }
                          {
                            (AppUI.siteIsDefault || (!item.blocked && !item.blockedUntilMigrated)) &&
                              <a
                                href={item.text}
                                download={item.title}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{margin: "5px"}}
                                key={Math.random()}
                              >
                                <Button
                                  height="30px"
                                  width='90%'
                                  fontSize="14px"
                                  color="#FFF"
                                  borderRadius="5px"
                                  background={AppUI.site.accentColor || "#716f6f"}
                                  scaleOnHover={AppUI.layout.isMobile ? null : "1.03"}
                                  backgroundOnActive={Theme.gray}
                                  iconMarginRight={AppUI.layout.isMobile ? '0px' : '3px'}
                                >
                                  <i className={`fa fa-download`}/>{AppUI.layout.isMobile ? '' : 'Download'}
                                </Button>
                              </a>
                          }
                        </td>
                      </tr>
                        {
                          !item.blocked && isPresent(item.podcastTitle) && AppUI.layout.isMobile &&
                            <tr>
                              <td colSpan='3'>
                                <Link
                                  to={{name: "settings.rss_feed"}}
                                  target='_blank'
                                >
                                  <RSSLink justifyContent='flex-end'>
                                    <i className='fa fa-rss' /><span>Also available as a podcast feed</span>
                                  </RSSLink>
                                </Link>
                              </td>
                            </tr>
                        }
                      </React.Fragment>
                    )
                  })
                }
              </tbody>
            </Table>
        }
        {
          ExerciseUI.user.anyAdmin && this.worksheet && this.worksheet.groupId &&
          <AdminLink>
            <Link
              to={{name: 'creator.groups.worksheets.edit', params: {groupId: this.worksheet.groupId, worksheetId: this.worksheet.id}}}
            >
              Admin Link
            </Link>
          </AdminLink>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(DownloadLinkIndex);
