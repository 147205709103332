import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { MembershipTypeStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import autobind from 'autobind-decorator';

@observer
class MembershipTypeEdit extends React.Component {

  componentDidMount() {
    MembershipTypeStore.getShow({ids: {membershipTypeId: this.props.params.membershipTypeId}}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    MembershipTypeStore.setShowData(resp);
  }

  render() {
    return (
      <div className='membership-type-edit'>
        <div className='row'>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Name</legend>
              <InPlaceText
                id={MembershipTypeStore.showData.id}
                defaultValue={MembershipTypeStore.showData.name}
                field="name"
                model="membership_types"
              />
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Slug</legend>
              <InPlaceText
                id={MembershipTypeStore.showData.id}
                defaultValue={MembershipTypeStore.showData.slug}
                field="slug"
                model="membership_types"
              />
            </fieldset>
          </div>
          <div className="col-xs-4">
            <fieldset>
              <legend>Active</legend>
              <InPlaceCheckbox
                value={MembershipTypeStore.showDataField('active')}
                model="membership_types"
                field="active"
                id={MembershipTypeStore.showDataField('id')}
                icon="check"
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            Image
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Description (Markdown)</legend>
              <InPlaceText
                markdown
                id={MembershipTypeStore.showData.id}
                defaultValue={MembershipTypeStore.showData.descriptionMd}
                field="description_md"
                model="membership_types"
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Description (HTML)</legend>
              <InPlaceText
                richText
                id={MembershipTypeStore.showData.id}
                defaultValue={MembershipTypeStore.showData.description}
                field="description"
                model="membership_types"
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorBoundary(MembershipTypeEdit);
