import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import cx from 'classnames';
import { Api } from '@seedlang/stores';
import pluralize from 'pluralize';
import autobind from 'autobind-decorator';
import uuid from 'uuid/v4';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import Confirmation from "../confirmation";
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  display: ${props => props.display};
  align-items: center;
  position:relative;
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  font-size: ${(props) => props.fontSize};
  background: ${(props) => props.background};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  text-align: ${(props) => props.textAlign};
  i {
    color: ${(props) => props.selectedColor};
    margin-right: 5px;
  }
  i.disabled {
    color: ${props => props.disabledIconColor};
  }
  
`;

const Label = styled.label`
  display: inline-block;
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color};
  flex: 1;
  cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};
  margin: ${(props) => props.margin};
  font-size: ${(props) => props.fontSize};
`;

@observer
class InPlaceCheckbox extends React.Component {
  @observable value = this.props.value || false;
  @observable showConfirmation = false;

  static propTypes = {
    onChange: PropTypes.func,
    afterChange: PropTypes.func,
    model: PropTypes.string,
    field: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.string,
  }

  static defaultProps = {
    selectedColor: "#000",
    wrapperMargin: "0 0 0 0",
    display: 'inline-block',
    withConfirmation: false,
  }

  constructor(props) {
    super(props);
    this.id = uuid();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.value = this.props.value;
      if (this.refs.checkbox) {
        this.refs.checkbox.value = this.props.value;
      }
    }
  }

  @computed get disabled() {
    return (this.props.disabled && (!this.props.allowUncheck || !this.value));
  }

  @computed get labelColor() {
    if (this.props.color) {
      return this.props.color;
    } else if (this.disabled) {
      return '#CCC';
    } else if (this.props.hideCheckbox && this.value) {
      return "#FFF";
    } else {
      return "#000";
    }
  }

  @autobind toggleValue() {
    if (this.props.disabled && (!this.props.allowUncheck)) {
      return;
    }
    if (AppUI.user.canUpdateRecords || this.props.allowUpdate) {
      this.value = this.props.icon || this.props.hideCheckbox ? !this.value : this.refs.checkbox.checked;
    }
    if (this.props.onChange && !this.props.locked) {
      this.props.onChange(this.value);
    } else if (!this.props.locked && (AppUI.user.canUpdateRecords || this.props.allowUpdate)) {
      const data = {};
      data.data = {};
      data.data[this.props.field] = this.value;
      Api.put({
        url: `/api/${pluralize(this.props.model)}/${this.props.id}`,
        data: data,
        successCallback: ((resp) => {
          if (this.props.afterChange) {
            this.props.afterChange(this.value);
          }
        }),
      });
    }
  }

  @autobind onConfirm() {
    this.showConfirmation = false;
    this.toggleValue();
  }

  @autobind onClick() {
    if (!this.value && this.props.withConfirmation) {
      this.showConfirmation = true;
    } else {
      this.toggleValue();
    }
  }

  render() {
    return (
      <Wrapper
        margin={this.props.wrapperMargin}
        padding={this.props.hideCheckbox ? "1px 3px" : 0}
        borderRadius={this.props.hideCheckbox ? "5px" : "0px"}
        background={this.props.hideCheckbox && this.value ? Theme.green : "transparent"}
        disabled={this.disabled}
        width={this.props.width}
        display={this.props.display}
        border={this.props.hideCheckbox ? "1px solid gray" : null}
        selectedColor={this.props.selectedColor}
        disabledIconColor={this.props.disabledIconColor}
      >
        {
          this.props.withConfirmation && this.showConfirmation &&
            <Confirmation
              left={this.props.left}
              right={this.props.right}
              floatsRight={this.props.confirmationMessageFloatsRight}
              close={() => this.showConfirmation = false}
              onConfirm={() => this.onConfirm()}
              message={this.props.message}
            />
        }
        {
          this.props.icon &&
            <i
              className={cx('fa', `fa-${this.props.icon}`, { 'fa-on-click': AppUI.user.canUpdateRecords, "fa-2x": this.props.bigIcon, disabled: !this.value })}
              onClick={!this.props.locked ? this.onClick : undefined}
              style={this.props.iconStyle}
            />
        }
        {
          !this.props.icon && !this.props.hideCheckbox &&
            <input
              type="checkbox"
              disabled={this.props.disabled}
              onChange={this.onClick}
              value={this.value}
              checked={this.value}
              id={this.id}
              ref="checkbox"
            />
        }
        {
          this.props.children &&
            <Label
              htmlFor={this.id}
              color={this.labelColor}
              margin={this.props.hideCheckbox ? "0" : "0 0 0 5px"}
              textAlign={this.props.hideCheckbox ? "center" : "left"}
              fontSize={this.props.hideCheckbox ? "11px" : "14px"}
              onClick={this.onClick}
              disabled={this.disabled}
            >
              {this.props.children}
            </Label>
        }
      </Wrapper>
    );
  }
}

export default InPlaceCheckbox;
