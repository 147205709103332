import React from 'react';
import { observer } from 'mobx-react';
import { AppUI } from '@seedlang/state';
import OnboardingSeedlangRegistration from './onboarding_seedlang_registration';
import OnboardingWhitelabelRegistration from './onboarding_whitelabel_registration';

@observer
class OnboardingRegistration extends React.Component {
  render() {
    return (
      AppUI.siteIsDefault ? (
        <OnboardingSeedlangRegistration {...this.props} />
      ) : (
        <OnboardingWhitelabelRegistration {...this.props} />
      )
    );
  }
}

export default OnboardingRegistration;
