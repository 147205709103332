import isPresent from './is_present';
import isBlank from './is_blank';
import { isArray } from 'lodash';

export default function formatFilterTag(filter, value) {
 let displayValue;
 if (filter.multiSelect && isPresent(value)) {
   if (isArray(value)) {
     const ids = value.filter(item => isPresent(item));
     if (ids.length > 2) {
        displayValue = `${ids.length} Selected`;
      } else {
        displayValue = ids.map((id) => {
          const option = filter.options.find(option => option[0] === id);
          if (option) {
            return option[1];
          }
        }).sort().join(", ");
     }
   } else if (value.match(",")) {
     const ids = value.split(",").filter(item => isPresent(item));
     if (ids.length > 2) {
        displayValue = `${ids.length} Selected`;
      } else {
        displayValue = ids.map((id) => {
          const option = filter.options.find(option => option[0] === id);
          if (option) {
            return option[1];
          }
        }).sort().join(", ");
      }
   }
 }
 if (isBlank(displayValue) && isPresent(value)) {
   const option = filter.options.find(item => item[0] === value);
   if (isPresent(option)) {
     displayValue = option[1];
   }
 } else if (filter.userKey === 'vocabSeen') {
   displayValue = 'Any';
 }
 return isPresent(displayValue) ? `${filter.label}: ${displayValue}` : null;
}