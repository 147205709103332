import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import ReferrerOptionCreate from './referrer_option_create';
import autobind from 'autobind-decorator';
import DeleteButton from 'components/button/delete_button';
import { sortBy } from 'lodash';
import InPlaceText from 'components/form/in_place_text';

const Wrapper = styled.div`

`;

@observer
class ReferrerOptionIndex extends React.Component {

  constructor(props) {
    super(props);
    this.getReferrerOptions();
  }

  @autobind getReferrerOptions() {
    AppUI.referrerOptionStore.getIndex();
  }

  @autobind onDelete(id) {
    AppUI.referrerOptionStore.destroy({ids: {referrerOptionId: id}}, this.getReferrerOptions)
  }

  render () {
    return (
      <Wrapper>
        <ReferrerOptionCreate
          afterCreate={this.getReferrerOptions}
        />
          <table className="table-wrapper admin-table">
            <thead>
              <tr>
                <th width="200px">
                  Text
                </th>
                <th width="200px">
                  Slug
                </th>
                <th>
                  Language
                </th>
                <th>
                  Web Count
                </th>
                <th>
                  Mobile Count
                </th>
                <th width="20px" />
              </tr>
            </thead>
            <tbody>
            {
              sortBy(AppUI.referrerOptionStore.indexData, ['languageId', 'text']).map(item => {
                return (
                  <tr
                    key={item.id}
                  >
                    <td>
                      <InPlaceText
                        field="text"
                        model="referrer_options"
                        defaultValue={item.text}
                        id={item.id}
                      />
                    </td>
                    <td>
                      <InPlaceText
                        field="slug"
                        model="referrer_options"
                        defaultValue={item.slug}
                        id={item.id}
                      />
                    </td>
                    <td>{item.languageId}</td>
                    <td>{item.usersWebCount}</td>
                    <td>{item.usersMobileCount}</td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message="Delete this referrer option?"
                        className="fa-block right"
                      />
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </Wrapper>
    );
  }
}

export default ReferrerOptionIndex;
