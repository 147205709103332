import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { ExerciseUI } from '@seedlang/state';
import { Theme } from '@seedlang/constants';
import autobind from "autobind-decorator";
import InfoTooltip from "components/info_tooltip";

const Wrapper = styled.div`

`;

const TagCategory = styled.div`
  padding: 10px;
  background: #CCC;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
`;

const TagCategoryWrapper = styled.div`
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
`;

const Tags = styled.div`
  background: white;
`;

const Tag = styled.div`
  padding: 5px 10px;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  color: ${(props) => props.selected ? "#FFF" : "#000"};
  background: ${(props) => props.selected ? Theme.blue : "#FFF"};
  &:hover {
    background: ${(props) => props.selected ? Theme.blue : "whitesmoke"};
  }
`;

@observer
class TagIndex extends React.Component {

  constructor(props) {
    super(props);
    ExerciseUI.tagCategoryStore.getIndex({queryStrings: {published: true}, ids: {groupId: this.props.groupId}});
    if (ExerciseUI.user.admin || ExerciseUI.user.canAccessAdminSite) {
      ExerciseUI.exerciseTypeStore.getIndex();
    }
  }

  @autobind onClickExerciseType(exerciseType) {
    if (ExerciseUI.exerciseStore.filters['exercise_type_id'] === exerciseType.id) {
      ExerciseUI.exerciseStore.clearFilter();
    } else {
      ExerciseUI.exerciseStore.setFilter('exercise_type_id', exerciseType.id, {refresh: true});
    }
  }

  render() {
    return (
      <Wrapper>
        {
          (ExerciseUI.user.admin || ExerciseUI.user.canUpdateRecords) &&
            <TagCategoryWrapper
              key='exercise-types'
            >
              <TagCategory>
                Exercise Types
              </TagCategory>
              <Tags>
                {
                  ExerciseUI.exerciseTypeStore.indexData.map(exerciseType => {
                    return (
                      <Tag
                        key={exerciseType.id}
                        onClick={() => this.onClickExerciseType(exerciseType)}
                        selected={ExerciseUI.exerciseStore.filters['exercise_type_id'] === exerciseType.id}
                      >
                        {exerciseType.name}
                      </Tag>
                    )
                  })
                }
              </Tags>
          </TagCategoryWrapper>
        }
        {
          ExerciseUI.tagCategoryStore.indexData.map(item => {
            return (
              <TagCategoryWrapper
                key={item.id}
              >
                <TagCategory>
                  {item.name}
                </TagCategory>
                <Tags>
                  {
                    item.tags.map(tag => {
                      return (
                        <Tag
                          key={tag.id}
                          onClick={() => this.props.onClickTag(item, tag.id)}
                          selected={this.props.selectedTagIds.indexOf(tag.id) !== -1}
                        >
                          {tag.name}
                        </Tag>
                      )
                    })
                  }
                </Tags>
              </TagCategoryWrapper>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TagIndex);
