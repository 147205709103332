import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { LightningBoltIcon } from '@seedlang/icons';
import { flexCenter } from '@seedlang/style_mixins';
import { Theme } from '@seedlang/constants';
import { AppUI } from '@seedlang/state';
import { ListWithBulletIcon, CalendarIcon } from '@seedlang/icons';

const KeySection = styled.div`
  display: flex;
  align-items: center;
`;

const Key = styled.div`
  ${flexCenter}
  background: ${(props) => props.faded ? Theme.fadedGreen : Theme.green};
  font-size: 12px;
  margin-right: 8px;
  font-weight: bold;
  border-radius: 3px;
  height: 24px;
  padding: 5px;
  color: white;
  .lightning-bolt-icon {
    svg {
      width: 9px;
      margin: 5px 3px 0 0;
      fill: #FFF;
    }
  }
`;

const BoltWrapper = styled.div`
  margin-top: -6px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const IconWrapper = styled.div`
  height: 24px;
  width: 24px;
  cursor: pointer;
  background: ${props => props.background};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  .list-with-bullet-icon svg {
    width: 15px;
    height: 19px;
  }
  .calendar-icon svg {
    width: 24px;
    height: 24px;
    margin: 3px 0 0 0;
  }
  .info-circle-icon svg {
    width: 18px;
  }
  svg {
    fill: #FFF;
  }
  i {
    font-size: 14px;
    color: #FFF;
  }
`;

const Expand = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

@observer
class DailyActivityFooter extends React.Component {

  render () {
    return (
      <Wrapper>
        <KeySection>
          <Key faded>
            {`1-${(this.props.dailyXpGoal || 15) - 1}XP`}
          </Key>
          <Key>
            <BoltWrapper>
              <LightningBoltIcon />
            </BoltWrapper>
            Streak
          </Key>
        </KeySection>
        <Expand>
          <IconWrapper
            background={this.props.showInfo ? Theme.red : "#5b5b5b"}
            onClick={this.props.toggleInfo}
          >
            {
              this.props.showInfo &&
                <i className='fa fa-times' />
            }
            {
              !this.props.showInfo &&
                <i className='fa fa-info' />
            }
          </IconWrapper>
          <IconWrapper
            background="#5b5b5b"
            onClick={this.props.onToggleXpView}
          >
            {
              AppUI.user.xpView === 'calendar' &&
                <ListWithBulletIcon />
            }
            {
              AppUI.user.xpView === 'list' &&
                <CalendarIcon />
            }
          </IconWrapper>
        </Expand>
      </Wrapper>
    );
  }
}

export default DailyActivityFooter;
