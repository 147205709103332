import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ConceptStore } from '@seedlang/stores';
import { ConceptCategoryStore } from '@seedlang/stores';
import { WordTypeStore } from '@seedlang/stores';
import { Link } from 'react-router';
import ConceptCreate from 'pages/builder/concepts/concept_create';
import Modal from 'components/modal';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import SortableColumns from 'components/hoc/sortable_columns';
import cx from 'classnames';
import { LevelStore } from '@seedlang/stores';
import Filter from 'components/backend_filter/filter';
import CheckIfTrue from 'components/check_if_true';
import { isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import ReactTooltip from 'react-tooltip';
import { noop } from 'lodash';
import RatingInput from 'components/rating_input';
import InPlaceCheckbox from 'components/form/in_place_checkbox';

@observer
class ConceptIndex extends React.Component {
  @observable showCreate = false;

  componentDidMount() {
    if (!ConceptCategoryStore.hasOutstandingRequests) {
      ConceptCategoryStore.getIndex();
    }
  }

  componentWillUnmount() {
    ConceptStore.clearIndexData();

  }

  onDelete(id) {
    if (this.props.onDelete) {
      this.props.onDelete(id);
    } else {
      ConceptStore.destroy({ ids: { conceptId: id } });
    }
  }

  onCopy(id) {
    ConceptStore.copy({ ids: { conceptId: id } }, this.reloadConcepts);
  }

  @autobind reloadConcepts() {
    ConceptStore.reloadIndexFiltered();
  }

  @autobind onClickRating(item, value, conceptId) {
    const rating = value === item.rating ? null : value;
    item.rating = rating;
    ConceptStore.update({ids: {conceptId: conceptId}, data: {rating: rating}}, noop);
  }

  concepts() {
    if (this.props.concepts) {
      return this.props.concepts;
    } else if (ConceptStore.hasIndexData) {
      return ConceptStore.indexData;
    } return [];
  }

  render() {
    return (
      <div className="concept-index">
        {
          !this.props.hideCreateButton && this.showCreate &&
            <Modal
              onCloseModal={() => this.showCreate = false}
            >
              <ConceptCreate
                showTreeNodeWarning
              />
            </Modal>
        }
        <div style={{display: 'flex'}}>
          <div style={{width: 150}}>
            {
              !this.props.hideCreateButton && (
                <button
                  className='gray-button'
                  onClick={() => this.showCreate = true}
                >
                  <i className='fa fa-plus' />
                  Create
                </button>
              )
            }
          </div>
          <div style={{flex: 1}}>
            {
              !this.props.hideFilter &&
                <Filter
                  store={ConceptStore}
                  defaultSort="-sentences_count,name"
                  namespace="concept-index-main"
                  queryStrings={{include_count: true}}
                  filters={
                  [
                    {
                      type: 'text',
                      name: 'name',
                      label: 'Name',
                      default: '',
                    },
                    {
                      type: 'select',
                      name: 'language_id',
                      options: [{id: 'DE', name: 'German'}, {id: 'ES', name: 'Spanish'}, {id: 'FR', name: 'French'}],
                      labelField: 'name',
                      label: 'Language',
                      default: '',
                    },
                    {
                      type: 'boolean',
                      name: 'long_description',
                      default: '',
                      label: 'Description',
                    },
                    {
                      type: 'boolean',
                      name: 'grammar_case',
                      default: '',
                      label: 'Case',
                    },
                    {
                      type: 'boolean',
                      name: 'grammar_tense',
                      default: '',
                      label: 'Tense',
                    },
                    {
                      type: 'boolean',
                      name: 'show_as_word_type',
                      default: '',
                      label: 'As Word Type',
                    },
                    {
                      type: 'select',
                      name: 'gender',
                      options: ['masculine', 'feminine', 'neuter'],
                      label: 'Gender',
                    },
                    {
                      type: 'boolean',
                      name: 'long_description_md',
                      label: 'Long Description',
                    },
                    {
                      type: 'multi_select',
                      name: 'level_id',
                      label: 'Level',
                      options: LevelStore.indexData,
                      default: LevelStore.pluckIndex('id'),
                      labelField: 'abbreviation',
                    },
                    {
                      type: 'select',
                      name: 'concept_category_id',
                      options: ConceptCategoryStore.indexData,
                      label: 'Category',
                      labelField: 'name',
                    },
                    {
                      type: 'multi_select',
                      name: 'rating',
                      options: [{id: 1, name: '1 Star'}, {id: 2, name: '2 Stars'}, {id: 3, name: '3 Stars'}],
                      label: 'Rating',
                      labelField: 'name',
                    },
                    {
                      type: 'select',
                      name: 'word_type_id',
                      options: WordTypeStore.indexData,
                      label: 'Word Type',
                      labelField: 'name',
                    },
                    {
                      type: 'boolean',
                      name: 'featured',
                      default: '',
                      label: 'Features',
                    },
                    {
                      type: 'boolean',
                      name: 'decks_count',
                      label: "Has deck"
                    },
                    {
                      type: 'boolean',
                      name: 'deck_grammar_concepts_count',
                      label: "Has grammar deck(s)"
                    },
                    {
                      type: 'boolean',
                      name: 'has_all_supplements',
                      default: '',
                      label: 'Has All Supplements',
                    },
                    {
                      type: 'boolean',
                      label: 'Approved: Didactics 1',
                      name: 'reviewed2',
                      default: '',
                      icon: 'thumbs-up',
                    },
                    {
                      type: 'boolean',
                      label: 'Approved: Didactics 2',
                      name: 'reviewed3',
                      default: '',
                      icon: 'thumbs-up',
                    },
                    {
                      type: 'boolean',
                      name: 'reviewed1',
                      label: 'Approved: English',
                      default: '',
                      icon: 'thumbs-up',
                    },
                    {
                      type: 'number',
                      name: 'words_count',
                      label: '# Words',
                      default: '',
                    },
                    {
                      type: 'boolean',
                      name: 'show_in_dictionary',
                      label: "Visible in dictionary"
                    },
                    {
                      type: 'boolean',
                      name: 'youtube_url',
                      label: "Has Youtube video",
                      icon: 'youtube-play'
                    },
                  ]}
                />
            }
          </div>
        </div>
        <table className="table-wrapper">
          <thead>
            <tr>
              <th width="40"></th>
              <th
                width="40"
                className={cx('sortable', this.props.columnClassNames('sentencesCount'))}
                onClick={this.props.onSortColumn.bind(this, 'sentencesCount')}
              >
                #
              </th>
              <th />
              <th
                width="300"
                className={cx('sortable', this.props.columnClassNames('normalized_name'))}
                onClick={this.props.onSortColumn.bind(this, 'normalized_name')}
              >
                Name
              </th>
              <th width="300">Display</th>
              <th width="60">Auto</th>
              <th width="70">Deck</th>
              {/*<th width="100">Rating</th>*/}
              {/*<th width="100">Lang</th>*/}
              <th width="100">Level</th>
              <th width="200">Word Type</th>
              <th width="200"># Words</th>
              <th width="30"></th>
              <th width="50">C/T</th>
              <th>Desc?</th>
              <th>Dict</th>
              <th width="30"></th>
              {
                !this.props.hideDelete &&
                  <th width="30"></th>
              }
            </tr>
          </thead>
          <tbody>
            {
              this.concepts().map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.concepts.edit', params: { conceptId: item.id } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>
                      <div className="text">
                        {item.sentencesCount}
                      </div>
                    </td>
                    <td>
                      <div className="text" style={{width: 60, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <span data-tip data-for={`didactics-${item.id}`} style={{marginRight: 4}}>
                          <InPlaceCheckbox
                            model="concepts"
                            field="reviewed2"
                            value={item.reviewed2}
                            id={item.id}
                            icon="thumbs-up"
                          />
                          <ReactTooltip
                            place="top"
                            type="dark"
                            effect="solid"
                            id={`didactics-${item.id}`}
                            class="custom-tooltip"
                          >
                            Approved: Didactics 1
                          </ReactTooltip>
                        </span>
                        <span data-tip data-for={`translation-${item.id}`} style={{marginRight: 4}}>
                          <InPlaceCheckbox
                            model="concepts"
                            field="reviewed3"
                            value={item.reviewed3}
                            id={item.id}
                            icon="thumbs-up"
                          />
                          <ReactTooltip
                            place="top"
                            type="dark"
                            effect="solid"
                            id={`translation-${item.id}`}
                            class="custom-tooltip"
                          >
                            Approved: Didactics 2
                          </ReactTooltip>
                        </span>
                        <span data-tip data-for={`content-${item.id}`} style={{marginRight: 4}}>
                          <InPlaceCheckbox
                            model="concepts"
                            field="reviewed1"
                            value={item.reviewed1}
                            id={item.id}
                            icon="thumbs-up"
                          />
                          <ReactTooltip
                            place="top"
                            type="dark"
                            effect="solid"
                            id={`content-${item.id}`}
                            class="custom-tooltip"
                          >
                            Approved: English
                          </ReactTooltip>
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.name}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        <div>{item.displayNameLine1}</div>
                        <div><i>{item.displayNameLine2}</i></div>
                      </div>
                    </td>
                    {/*<td>*/}
                    {/*  <RatingInput*/}
                    {/*    hideTooltip*/}
                    {/*    starWidth="12px"*/}
                    {/*    id={item.id}*/}
                    {/*    marginTop="0"*/}
                    {/*    maxRating={3}*/}
                    {/*    defaultValue={item.rating}*/}
                    {/*    onClick={(value) => this.onClickRating(item, value, item.id)}*/}
                    {/*  />*/}
                    {/*</td>*/}
                    {/*<td>*/}
                    {/*  <div className="text">*/}
                    {/*    {item.languageId}*/}
                    {/*  </div>*/}
                    {/*</td>*/}
                    <td>
                      <CheckIfTrue
                        value={item.automaticAssociations}
                      />
                    </td>
                    <td>
                      {
                        isPresent(item.deckIds) &&
                        <Link
                          to={{name: 'builder.decks.edit', params: {deckId: item.deckIds.split(',')[0]}}}
                          className="on-click"
                        >
                          <i className="fa fa-chevron-right fa-block" />
                        </Link>
                      }
                    </td>
                    <td>
                      <div className="text">
                        {item.level && item.level.abbreviation}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.wordType && item.wordType.abbreviation}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.wordsCount}
                      </div>
                    </td>
                    <td>
                      {
                        isPresent(item.youtubeUrl) &&
                          <i className="fa fa-youtube-play" />
                      }
                    </td>
                    <td>
                      <div className="text">
                        {item.grammarTense}{item.grammarCase}
                      </div>
                    </td>
                    <td>
                      <CheckIfTrue
                        hideIfFalse
                        value={isPresent(item.longDescriptionMd)}
                      />
                    </td>
                    <td>
                      <CheckIfTrue
                        value={item.showInDictionary}
                      />
                    </td>
                    <td>
                      <div
                        data-tip
                        data-for={item.id}
                      >
                        <i
                          className='fa fa-copy fa-block fa-on-click'
                          onClick={() => this.onCopy(item.id)}
                        />
                        <ReactTooltip
                          place="left"
                          type="dark"
                          effect="solid"
                          id={item.id}
                          class="custom-tooltip"
                        >
                          Clone this Concept
                        </ReactTooltip>
                      </div>
                    </td>
                    {
                      !this.props.hideDelete &&
                        <td>
                          <DeleteButton
                            onConfirm={this.onDelete.bind(this, item.id)}
                            message={this.props.deleteMessage || "Delete this concept?"}
                            className="fa-block right"
                          />
                        </td>
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        {
          !this.props.hidePaginator &&
            <Paginator
              store={this.props.store || ConceptStore}
              ids={{sentenceId: this.props.sentenceId}}
            />
        }
      </div>
    );
  }
}

export default SortableColumns(ConceptIndex, ConceptStore, { sortColumn: 'sentencesCount', sortIsAscending: false });
