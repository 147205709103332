import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import FilterSelect from 'components/backend_filter/filter_select';
import FilterBoolean from 'components/backend_filter/filter_boolean';
import FilterMultiSelect from 'components/backend_filter/filter_multi_select';
import FilterText from 'components/backend_filter/filter_text';
import FilterNumber from 'components/backend_filter/filter_number';
import FilterDate from 'components/backend_filter/filter_date';
import { each, keys, merge } from 'lodash';
import styled from '@emotion/styled';
import autobind from 'autobind-decorator';
import { formatNumber, getCookieValue, isBlank, isPresent, setCookieValue } from '@seedlang/utils';
import FilterIndex from 'components/backend_filter/filter_index';
import Modal from 'components/modal';
import { Theme } from '@seedlang/constants';
import Spinner from "components/spinner";

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  width: 100%;
  ul {
    margin: 0;
    padding: 0;
  }
  input {
    height: 30px;
    margin: 0;
    display: inline-block;
    outline: none;
  }
  select {
    font-size: 11px!important;
    height: 25px!important;
    line-height: 14px;
    padding: 0!important;
  }
  .disabled {
    color: #333;
  }
  fa {
    pointer-events: none;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: ${props => props.filterExpanded ? "0" : "10px"};
  border-bottom-right-radius: ${props => props.filterExpanded ? "0" : "10px"};
  cursor: pointer;
`;

const Toggle = styled.div`
  width: 100px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  i {
    color: #333;
    font-size: 14px;
  }
`;

const Text = styled.div`
  flex: 1;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: ${(props) => props.fontWeight || "bold"};
`;

const FilterOptions = styled.div`
  padding: 0 10px 10px 10px;
  border-radius: 10px;
`;

const FilterTags = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-weight: bold;
  color: #333;
  font-size: 14px;
  margin-top: 10px;
`;

const FilterTag = styled.div`
  cursor: pointer;
  background: ${Theme.green};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  margin: 2px 5px;
  padding: 2px 10px;
  i {
    margin-left: 5px;
  }
  -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
`;

const ExpandedOptions = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const NoFilters = styled.div`
  cursor: pointer;
`;

const FilterCount = styled.div`
  width: 100px;
  height: 24px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-size: 11px;
  margin-right: 5px;
`;

@observer
class Filter extends React.Component {
  @observable filterExpanded = getCookieValue(`${this.props.namespace}-expanded`);
  @observable showFilterIndex = false;

  static propTypes = {
    store: PropTypes.object,
    prefix: PropTypes.string,
    filters: PropTypes.array,
    hide: PropTypes.bool,
    unit: PropTypes.string,
  }

  static defaultProps = {
    margin: "0 0 10px 0",
    limit: 20,
  }

  constructor(props) {
    super(props);
    if (this.props.onMount) {
      this.props.onMount();
    }
    this.props.store.initializeFilters(this.props.defaultFilters, this.props.filters, this.props.defaultSort, this.props.namespace, this.props.queryStrings, this.props.limit, this.props.ids);
    this.filters = {
      multi_select: FilterMultiSelect,
      select: FilterSelect,
      boolean: FilterBoolean,
      hidden: FilterText,
      text: FilterText,
      number: FilterNumber,
      date: FilterDate,
    };
  }

  _component(item, index) {
    if (this.filters[item.type]) {
      return React.createElement(
        this.filters[item.type], merge({
          store: this.props.store,
          namespace: this.props.namespace,
          key: index,
          exactValue: this.props.exactValue,
          hidden: this.props.hidden,
          ids: this.props.ids,
        }, item),
      );
    } else if (item.type !== 'hidden' && !this.filters[item.type]) {
      console.warn(`The filter '${item.type}' must be registered`);
    }
  }

  _filters() {
    if (this.props.store.filterInitialized) {
      return this.props.filters.map((item, index) => {
        return this._component(item, index);
      });
    }
  }

  @autobind toggleFilterExpanded() {
    this.filterExpanded = !this.filterExpanded;
    setCookieValue(`${this.props.namespace}-expanded`, this.filterExpanded);
  }

  @autobind onClickFilterTag(key) {
    this.props.store.setFilter(key, '', {ids: this.props.ids, refresh: true});
  }

  @computed get filterTags() {
    let ary = [];
    let value;
    each((keys(this.props.store.filters)), (key) => {
      if (isPresent(this.props.store.filters[key])) {
        const filter = this.props.filters.find(item => item.name === key);
        if (filter && filter.options && this.props.store.filters[key].map) {
          value = this.props.store.filters[key].map((id) => {
            const option = filter.options.find(item => item.id === id);
            if (option) {
              return option[filter.labelField || "name"];
            } else {
              return null;
            }
          }).filter(item => isPresent(item)).join(", ");
        } else {
          if (filter && filter.options && filter.options.find(item => item.id === this.props.store.filters[key])) {
            const selectedOption = filter.options.find(item => item.id === this.props.store.filters[key]);
            value = selectedOption[filter.labelField || "name"];
          } else {
            value = this.props.store.filters[key];
          }
        }
        if (isPresent(this.props.store.filters[key]) && filter && !filter.hidden) {
          ary.push({key: key, string: `${filter && isPresent(filter.label) ? filter.label : key}: ${value}`});
        }
      }
    })
    return ary;
  }

  @computed get filterString() {
    return this.filterTags.map(item => item.string).join(", ");
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        {
          this.showFilterIndex &&
            <Modal
              onCloseModal={() => this.showFilterIndex = false}
            >
              <FilterIndex
                namespace={this.props.namespace}
                filterString={this.filterString}
                store={this.props.store}
                afterLoadFilter={() => this.showFilterIndex = false}
              />
            </Modal>
        }
        <Header
          filterExpanded={this.filterExpanded}
        >
          <Toggle
            onClick={this.toggleFilterExpanded}
          >
            { this.filterExpanded && <i className='fa fa-minus' /> }
            { !this.filterExpanded && <i className='fa fa-plus' /> }
          </Toggle>
          <Text
            onClick={this.toggleFilterExpanded}
            >
            Filters
          </Text>
          {
            Boolean(this.props.queryStrings && this.props.queryStrings["include_count"] && isPresent(this.props.store.totalCount)) && this.props.store.requestCounter === 0 &&
              <FilterCount>
                {`${formatNumber(this.props.store.totalCount)} Result${this.props.store.totalCount > 1 ? "s" : "" }`}
              </FilterCount>
          }
          {
            Boolean(this.props.queryStrings && this.props.queryStrings["include_count"] && isPresent(this.props.store.totalCount)) && this.props.store.requestCounter > 0 &&
              <Spinner
                background="#A7A7A7"
                margin="0"
                height="10px"
              />
          }
        </Header>
        <FilterOptions>
          {
            !this.filterExpanded &&
              <FilterTags>
                {
                  isBlank(this.filterTags) && !this.filterExpanded &&
                    <NoFilters
                      onClick={this.toggleFilterExpanded}
                    >
                      No Filters
                    </NoFilters>
                }
                {
                  this.filterTags.map(item => {
                    return (
                      <FilterTag
                        key={item.key}
                        onClick={() => this.onClickFilterTag(item.key)}
                      >
                        {item.string}
                        <i className='fa fa-times' />
                      </FilterTag>
                    )
                  })
                }
              </FilterTags>
          }
          {
            this.filterExpanded &&
              <ExpandedOptions>
                { this._filters() }
              </ExpandedOptions>
          }
        </FilterOptions>
      </Wrapper>
    );
  }
}

export default Filter;
