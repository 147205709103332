import React from 'react';
import { observer } from 'mobx-react';
import { Motion, spring } from 'react-motion';
import { observable } from 'mobx';
import cx from 'classnames';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  .fade-out {
    opacity: 0;
    transition: opacity 1s linear;
  }
`;

const Circle = styled.div`
  z-index: ${Theme.z["topmost"]};
  border: 5px solid ${(props) => props.borderColor};
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  position: absolute;
  opacity: 0.9;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;
`;

const DESTINATION_Y_POS = -300;
const DEFAULT_Y_POS = 0;

@observer
class XpAnimation extends React.Component {
  @observable yPos = this.props.bottom  || DEFAULT_Y_POS;
  @observable opacity = 1;
  @observable animating = false;

  xPos(y) {
    const waveHeight = 35;
    const waveWidth = 15;
    return Math.sin(y / waveHeight) * waveWidth;
  }

  componentDidMount() {
    this.animating = true;
    this.yPos = DESTINATION_Y_POS;
  }

  componentWillUnmount() {
    this.props.state.onRemoveXpAnimation(this.props.id);
  }

  render() {
    return (
      <Wrapper>
        <Motion
          style={{
            y: this.animating ? spring(this.yPos, {stiffness: 30, damping: 15}) : DEFAULT_Y_POS,
          }}
          onRest={() => this.props.state.onRemoveXpAnimation(this.props.id)}
        >
          {
            ({y}) =>
              <Circle
                borderColor={this.props.xp === 0 ? "#333" : "yellow"}
                background={this.props.xp === 0 ? "#333" : "yellow"}
                color={this.props.xp === 0 ? "#FFF" : "#333"}
                className={cx({
                  'fade-out': this.animating && y < DESTINATION_Y_POS / 2,
                })}
                style={Object.assign({}, this.props.style, {
                  transform: this.animating ? `translate(${this.xPos(y)}px, ${y}px)` : 'translate(0, 0)',
                  left: this.props.xpXPos ? this.props.xpXPos - 25 : 0,
                })}
              >
               +{this.props.xp}
              </Circle>
          }
        </Motion>
      </Wrapper>
    );
  }
}

export default XpAnimation;
