import React from 'react';
import { observer } from 'mobx-react';
import { MediaSectionWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { flexCenter } from '@seedlang/style_mixins';
import VideoPlayerWithSupplement from 'components/media/video_player_with_supplement';
import CardSection from 'components/vocab_card/card_section';
import { DeckUI } from '@seedlang/state';
import Image from 'components/image';
import { times } from "lodash";
import { pixify } from "@seedlang/utils";
import { computed } from 'mobx';


const GENDER_BLOCK_WIDTH = 50;
const GENDER_BLOCK_HEIGHT = 50;

const Wrapper = styled.div`
  ${flexCenter()}
`;

const GenderBackground = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: ${props => pixify(props.width)};
  height: ${props => pixify(props.height)};
  overflow: hidden;
  justify-content: space-between;
  font-weight: bold;
`;

const Gender = styled.div`
  ${flexCenter()}
  height: ${props => pixify(props.height)};
  width: ${props => pixify(props.width)};
  opacity: 0.8;
  color: #FFF;
`;

const Media = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
`;

@observer
class MediaSection extends React.Component {

  @computed get numGenders() {
    const numWidth = Math.floor(DeckUI.layout.innerDeckWidth / GENDER_BLOCK_WIDTH);
    const numHeight = Math.floor(this.height / GENDER_BLOCK_HEIGHT);
    return numWidth * numHeight;
  }

  @computed get height() {
    return this.props.height || DeckUI.layout.heightForCardSections(3);
  }

  render() {
    return (
      <CardSection
        id="top-section"
        height={this.props.height}
        background={this.props.background}
        borderTopRadius={this.props.borderTopRadius}
        borderBottomRadius={this.props.borderBottomRadius}
        fontSize="8px"
  >
        <Wrapper>
          {
            this.props.card?.word?.definiteArticle &&
              <GenderBackground
                width={DeckUI.layout.innerDeckWidth}
                height={this.height}
              >
                {
                  times(this.numGenders).map(num => {
                    return (
                      <Gender
                        key={num}
                        width={GENDER_BLOCK_WIDTH}
                        height={GENDER_BLOCK_HEIGHT}
                        style={{transform: "rotate(-45deg)" }}
                      >
                        {this.props.card?.word?.definiteArticle.toUpperCase()}
                      </Gender>
                    )
                  })
                }
              </GenderBackground>
          }
          <Media>
            {
              this.props.hasVideoClip && this.props.showMedia &&
                <VideoPlayerWithSupplement
                  hasVideoClip
                  autoPlay={this.props.autoPlay}
                  width={Math.floor(DeckUI.layout.deckWidth / 2.8)}
                  videoClip={this.props.videoClip}
                  afterVideoPlayback={this.props.afterVideoPlayback}
                  supplementVideoClip={this.props.supplementVideoClip}
                />
            }
            {
              this.props.supplementImageUrl && this.props.showMedia &&
                <Image
                  width={Math.floor(DeckUI.layout.deckWidth / 2.8)}
                  src={this.props.supplementImageUrl}
                />
            }
          </Media>
        </Wrapper>
      </CardSection>
    );
  }
}

export default MediaSectionWrapper(MediaSection);
