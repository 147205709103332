import React from 'react';
import { observer } from 'mobx-react';
import { TextResizerWrapper } from '@seedlang/hoc';

@observer
class TextResizer extends React.Component {

  render() {
    return (
      <>
        {React.cloneElement(this.props.children, {
          fontSize: this.props.fontSize,
          lineHeight: this.props.lineHeight,
          marginRight: this.props.marginRight,
          width: this.props.width,
        })}
      </>
    );
  }
}

export default TextResizerWrapper(TextResizer);
