import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { noop } from 'lodash';
import ReorderClickItem from 'components/exercise/reorder_click_item';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => props.center ? "center" : "flex-start"};
`;

const GapWordWrapper = styled.div`
  margin: 0 5px 5px 0;
`;

const GapWord = styled.div`
  height: 30px;
  background: white;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

@observer
class ExerciseWordAssociations extends React.Component {

  render() {
    return (
      <Wrapper
        center={this.props.center}
      >
        {
          this.props.wordAssociations && this.props.wordAssociations.map((item, index) => {
            return (
              <GapWordWrapper
                key={item.id}
              >
                {
                  !this.props.clickable &&
                    <GapWord>
                      {item.targetText.normalize('NFC')}
                    </GapWord>
                }
                {
                  this.props.clickable &&
                    <ReorderClickItem
                      onClick={() => item.state.assigned ? noop : this.props.onClick(item, this.props.sentence && this.props.sentence.id, this.props.clickWithinSentence)}
                      height={this.props.inDeck ? '50px' : '40px'}
                      assigned={item.state.assigned}
                      fontSize={this.props.sentence?.rightToLeftLanguage ? '16px' : '14px'}
                      inDeck={this.props.inDeck}
                    >
                      {item.targetText.normalize('NFC')}
                    </ReorderClickItem>
                }
              </GapWordWrapper>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ExerciseWordAssociations;
