import React from 'react';
import { observer } from 'mobx-react';
import { BlogEntryStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';
import { AuthUserStore } from '@seedlang/stores';

@observer
class BlogEntryCreate extends React.Component {

  onClick() {
    if (isPresent(this.refs.title.value)) {
      BlogEntryStore.create(
        {
          data: {
            title: this.refs.title.value,
            user_id: AuthUserStore.showData.id,
          },
        },
        this.props.afterCreate
      );
      this.refs.title.value = '';
    }
  }

  render() {
    return (
      <div className="concept-category-create row">
        <div className="col-xs-10">
          <input
            placeholder="Add a title"
            ref="title"
          />
        </div>
        <div className="col-xs-2">
          <button
            className="button-primary"
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default BlogEntryCreate;
