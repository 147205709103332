import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { AppUI } from '@seedlang/state';
import styled from '@emotion/styled';
import MembershipOption from 'components/membership/membership_option';
import Text from 'components/text';
import { CheckWithCircleIcon, SeedlangProWithCrown } from '@seedlang/icons';
import { Theme } from '@seedlang/constants';
import { isPresent, pixify, formattedRoundNumber } from '@seedlang/utils';


const Wrapper = styled.div`
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width : 649px) {
    width: 100%;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const MembershipOptionWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection};
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  @media only screen and (max-width : 649px) {
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
    width: 100%;
  }
`;

const MoneyBack = styled.div`
  width: 100%;
  margin: 20px 0;
  padding: 20px 0;
  text-align: center;
  p {
    margin: 0;
  }
`;

const BenefitsSectionWrapper = styled.div`
  background: #e6f2f5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding-bottom: 50px;

`;

const BenefitsSection = styled.div`
  margin: 20px 0;
  max-width: 650px;
  width: 100%;
  font-size: 20px;
  line-height: 26px;
  @media only screen and (max-width : 649px) {
    padding: 0 20px;
    font-size: 16px;
    line-height: 22px;
    margin-top: 5px;
  }
`;

const BenefitWrapper = styled.div`
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  background: #FFF;
  legend {
    font-size: 14px;
    color: #838383;
  }
`;

const Row = styled.div`
  display: flex;
  margin: 10px 0;
  border-bottom: 1px solid #d0d0d0;
  border-top: ${(props) => props.borderTop ? "1px solid #d0d0d0" : null};
  padding: ${(props) => props.borderTop ? "20px 0 20px 0" : "10px 0 20px 0"};
  svg {
    margin: 3px 5px 0 0;
    fill: ${Theme.orange};
    width: 23px;
  }
`;

const Benefit = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #575757;
  flex: 1;
  margin: 0 0 0 10px;
  b {
    color: #000;
  }
`;

const Header = styled.div`
  margin: 40px 0 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    width: 280px;
  }
`;

const PatreonPricing = styled.div`
  border-bottom: 4px solid ${Theme.blue};
  display: flex;
  align-items: center;
  margin: 20px 0 -20px 0;
  i {
    color: ${Theme.blue};
    margin-right: 5px;
    font-size: 16px;
  }
`;

const Small = styled.div`
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px;
`;

@observer
class MembershipOptionsDefault extends React.Component {

  constructor(props) {
    super(props);
    AppUI.loadUser();
  }

  @computed get filteredMemberships() {
    if (!AppUI.user || !AppUI.user.site) { return null }
    const memberships = isPresent(AppUI.user?.membershipGroup) ? AppUI.user.membershipGroup.memberships : AppUI.user.site.membershipGroup?.memberships;
    if (isPresent(AppUI.user.paymentOption) && isPresent(memberships.find(item => item.experiment === AppUI.user.paymentOption))) {
      return memberships.filter(item => (!item.default || item.experiment === AppUI.user.paymentOption));
    } else if (memberships) {
      return memberships.filter(item => !item.experiment);
    } return null;
  }

  @computed get currency() {
    return AppUI.user?.currency ?? 'USD';
  }

  @computed get currencySymbol() {
    return this.currency?.toUpperCase() === 'USD' ? '$' : '€';
  }

  onClick(membership) {
    if (AppUI.user.signedOut) {
      AppUI.routeStore.routeToNamed('onboarding');
    } else {
      AppUI.set('currentlyViewedMembership', membership);
      const routeTo = window.location.href && window.location.href.match("settings") ? 'settings.memberships.create' : 'memberships.create';
      AppUI.routeStore.routeToNamed(routeTo, {membershipId: membership.id})
    }
  }

  render() {
    return (
      <Wrapper>
        <Header>
          <SeedlangProWithCrown />
          <Text
            fontSize="30px"
            lineHeight="32px"
            bold
            margin="0 0 10px 0"
            textAlign="center"
            color="#333"
          >
            Learn {AppUI.targetLanguageName || 'a Language'} Faster.
          </Text>
          <Text
            fontSize="16px"
            lineHeight="20px"
            margin="0 0 10px 0"
            textAlign="center"
            color="#333"
            bold
          >
            Unlock all content and features for German 🇩🇪, French 🇫🇷, and Spanish 🇪🇸.
          </Text>
        </Header>
        <InnerWrapper>
          <MembershipOptionWrapper
            flexDirection={AppUI.layout.isMobile ? 'column' : 'row'}
          >
            {
              this.filteredMemberships && this.filteredMemberships.map((item, index) => {
                return (
                  <MembershipOption
                    margin="5px"
                    key={item.id}
                    user={AppUI.user}
                    currency={this.currency}
                    currencySymbol={this.currencySymbol}
                    membership={item}
                    signedOut={AppUI.user.signedOut}
                    onClick={this.onClick}
                    borderRadius="20px"
                    hoverTransform="scale(1.05)"
                    background="linear-gradient(-134deg, #1fcc64 0%, #45775e 100%)"
                    width={AppUI.layout.isMobile ? pixify(AppUI.layout.viewportWidth - 40) : "290px"}
                  />
                )
              })
            }
          </MembershipOptionWrapper>
          {
            AppUI.user.activePatreonMember && AppUI.user.membershipGroup && AppUI.user.membershipGroup.slug === 'discount' &&
              <PatreonPricing>
                <i
                  className='fa fa-info-circle'
                />
                You have a discount for being an Easy German Patron.
              </PatreonPricing>
          }
          {
            this.props.showMoneyback &&
              <MoneyBack>
                <p>Cancel at any time.</p>
                <p style={{marginTop: '10px'}}>There is a 15 day money-back guarantee on all purchases.</p>
              </MoneyBack>
          }
          <BenefitsSectionWrapper>
            <BenefitsSection>
              <Text
                fontSize="30px"
                lineHeight="36px"
                bold
                margin="60px 0 40px 0"
                textAlign="center"
                color="#000"
              >
                Benefits of Being a Pro
              </Text>
              <BenefitWrapper>
                <Row
                  borderTop
                >
                  <CheckWithCircleIcon />
                  <Benefit>
                    Learn from our <b>German, Spanish, and French</b> courses with one low price.
                    <Small>Spanish and French courses are only available on the website. Apps for these languages will be released at the end of September.</Small>
                  </Benefit>
                </Row>
                {
                  AppUI.targetLanguage?.contentTreeDecksCount > 0 &&
                    <Row>
                      <CheckWithCircleIcon />
                      <Benefit>
                        Unlock{AppUI.targetLanguage ? ` ${formattedRoundNumber(AppUI.targetLanguage.contentTreeDecksCount)} ` : ''}lessons in our <b>Stories Content Tree</b> to work on your grammar, vocabulary, speaking and listening skills.
                      </Benefit>
                    </Row>
                }
                <Row>
                  <CheckWithCircleIcon />
                  <Benefit>
                    Drill vocabulary in our <b>Vocab Trainer</b> by building your own custom decks from {AppUI.targetLanguage ? ` ${formattedRoundNumber(AppUI.targetLanguage.vocabTrainerWordsCount)} ` : ''} words spanning A1 to C1.
                  </Benefit>
                </Row>
                <Row>
                  <CheckWithCircleIcon />
                  <Benefit>
                    Train {AppUI.targetLanguage ? ` ${formattedRoundNumber(AppUI.targetLanguage.vocabTrainerVerbsCount)} ` : ''} verbs with our <b>Conjugation Trainer</b>. Improve your command over conjugations across different verb tenses.
                  </Benefit>
                </Row>
                <Row>
                  <CheckWithCircleIcon />
                  <Benefit>
                    Train {AppUI.targetLanguage ? ` ${formattedRoundNumber(AppUI.targetLanguage.vocabTrainerNounsCount)} ` : ''} nouns with our <b>Gender and Plural Trainers</b>. Improve your command over genders in a fun and effective way.
                  </Benefit>
                </Row>
                <Row>
                  <CheckWithCircleIcon />
                  <Benefit>
                    Train numbers with our new <b>Number Trainer</b>. Drilling numbers will help you to have instant command over this important language feature.
                  </Benefit>
                </Row>
                {
                  AppUI.targetLanguage?.triviaQuestionsCount > 0 &&
                    <Row>
                      <CheckWithCircleIcon />
                      <Benefit>
                        Unlock unlimited daily games and {AppUI.targetLanguage ? ` ${formattedRoundNumber(AppUI.targetLanguage.triviaQuestionsCount)} ` : ''} questions in our <b>Trivia Game</b> to practice your listening skills and improve your vocabulary.
                      </Benefit>
                    </Row>
                }
                <Row>
                  <CheckWithCircleIcon />
                  <Benefit>
                    Get 1 <b>Streak Repair</b> per month, for those times when life gets in the way of your language learning.
                  </Benefit>
                </Row>
              </BenefitWrapper>
            </BenefitsSection>
          </BenefitsSectionWrapper>
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(MembershipOptionsDefault);
