import React from 'react';
import { observer } from 'mobx-react';
import { CustomTabStore, MembershipTypeCustomTabStore, MembershipTypeStore, SiteStore } from '@seedlang/stores';
import { computed } from 'mobx';
import autobind from 'autobind-decorator';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import Cookies from 'js-cookie';
import { find, isFunction, noop } from 'lodash';
import InPlaceImageUpload from "components/form/in_place_image_upload";
import Text from 'components/text';
import IconInPlaceSelect from "pages/icon_in_place_select";
import isBlank from "is-blank";
import { isPresent } from "@seedlang/utils";
import DeleteButton from "components/button/delete_button";
import Button from 'components/button/button';
import InfoTooltip from "components/info_tooltip";
import Alert from "components/alert";

@observer
class CustomTabEdit extends React.Component {
  static defaultProps = {
    whiteLabeled: false
  }

  componentDidMount() {
    if (!this.props.whiteLabeled) {
      SiteStore.getIndex();
    }
    this.getMembershipTypes();
    if (isBlank(this.props.customTab)) {
      this.getCustomTab();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (isPresent(this.props.routeParams) && isPresent(prevProps.routeParams) && this.props.routeParams.customTabId !== prevProps.routeParams.customTabId) {
      this.getCustomTab();
    }
  }

  componentWillUnmount() {
    if (CustomTabStore.hasShowData) {
      CustomTabStore.clearShowData();
    }
  }

  @computed get customTab() {
    return this.props.customTab || CustomTabStore.showData;
  }

  @computed get filteredMembershipTypes() {
    return MembershipTypeStore.indexData.filter(item => item.siteId === Cookies.get('site_id'));
  }

  @autobind afterUpdate() {
    if (isFunction(this.props.afterUpdate)) {
      this.props.afterUpdate();
    } else {
      this.getCustomTab();
    }
  }

  @autobind getCustomTab() {
    CustomTabStore.getShow({ ids: { customTabId: this.props.routeParams.customTabId }});
  }

  @autobind getMembershipTypes() {
    MembershipTypeStore.getIndex();
  }

  @autobind getMembershipTypeCustomTab(membershipTypeId) {
    return find(this.customTab?.membershipTypeCustomTabs, ['membershipTypeId', membershipTypeId]);
  }

  @autobind onChange(membershipTypeCustomTab, membershipTypeId, value) {
    if (value) {
      MembershipTypeCustomTabStore.create({ data: { custom_tab_id: this.customTab.id, membership_type_id: membershipTypeId } }, noop);
    } else {
      MembershipTypeCustomTabStore.destroy({ ids: { membershipTypeCustomTabId: membershipTypeCustomTab.id } }, noop);
    }
  }

  @autobind onDelete() {
    CustomTabStore.destroy({ ids: { customTabId: this.customTab.id } }, this.afterUpdate);
  }

  render() {
    if (CustomTabStore.showData) {
      return (
        <div className="custom-tabs-edit">
          <div className="row">
            <div className="col-xs-5">
              <fieldset style={{display: 'flex'}}>
                <legend>Name</legend>
                <InPlaceText
                  id={this.customTab.id}
                  defaultValue={this.customTab.title}
                  model="custom_tabs"
                  field="title"
                />
                {
                  isBlank(this.customTab.title) &&
                  <InfoTooltip icon="warning">Don't forget to add a name!</InfoTooltip>
                }
              </fieldset>
            </div>
            <div className="col-xs-2">
              <fieldset style={{display: 'flex'}}>
                <legend>Published</legend>
                <InPlaceCheckbox
                  icon="check"
                  value={this.customTab.published}
                  id={this.customTab.id}
                  field='published'
                  model="custom_tabs"
                />
                {
                  !this.customTab.published &&
                  <InfoTooltip icon="warning">The custom tab isn't published</InfoTooltip>
                }
              </fieldset>
            </div>
            <div className="col-xs-3">
              <fieldset style={{display: 'flex', alignItems: 'center'}}>
                <legend>Icon</legend>
                <IconInPlaceSelect
                  includeBlank={false}
                  id={this.customTab.id}
                  value={this.customTab.icon}
                  model="custom_tabs"
                  field="icon"
                  options={[
                    ['question-circle', 'Question'],
                    ['video-camera', 'Video'],
                    ['link', 'Link'],
                    ['download', 'Download'],
                    ['play-circle', 'Play Button'],
                    ['puzzle-piece', 'Puzzle'],
                    ['lightbulb-o', 'Lightbulb'],
                    ['rocket', 'Rocket'],
                    ['globe', 'Globe'],
                  ]}
                  afterChange={this.afterUpdate}
                />
              </fieldset>
            </div>
            <div className="col-xs-2" style={{display: "flex", alignItems: "center", justifyContent: 'flex-end'}}>
              <DeleteButton
                onConfirm={this.onDelete}
                message="Delete this Custom Tab?"
                className="fa-block right"
              >
                <Button>Delete</Button>
              </DeleteButton>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6">
              <fieldset>
                <legend>Membership Types</legend>
                {
                  this.filteredMembershipTypes.map((item) => {
                    return (
                      <div
                        key={item.id}
                      >
                        <InPlaceCheckbox
                          icon="check"
                          value={Boolean(this.getMembershipTypeCustomTab(item.id))}
                          model="membership_type_custom_tabs"
                          onChange={(value) => this.onChange(this.getMembershipTypeCustomTab(item.id), item.id, value)}
                        >
                          {item.name}
                        </InPlaceCheckbox>
                      </div>
                    );
                  })
                }
              </fieldset>
            </div>
            <div className="col-xs-6">
              <fieldset style={{width: '100%'}}>
                <legend>Other Filters</legend>
                <div>
                  <InPlaceCheckbox
                    icon="check"
                    id={this.customTab.id}
                    value={this.customTab?.showToNonMembers}
                    field='show_to_non_members'
                    model="custom_tabs"
                  >
                    Show to non members
                  </InPlaceCheckbox>
                </div>
                <div>
                  <InPlaceCheckbox
                    icon="check"
                    id={this.customTab.id}
                    value={this.customTab?.blockIfNotMigrated}
                    field='block_if_not_migrated'
                    model="custom_tabs"
                  >
                    Block if User Needs to Migrate
                  </InPlaceCheckbox>
                </div>
                {
                  isBlank(this.customTab.worksheetId) &&
                  <>
                    <div>
                      <InPlaceCheckbox
                        afterChange={this.afterUpdate}
                        icon="check"
                        id={this.customTab.id}
                        value={this.customTab?.showOnVideoWorksheets}
                        field='show_on_video_worksheets'
                        model="custom_tabs"
                      >
                        Show on Video Posts
                      </InPlaceCheckbox>
                    </div>
                    <div>
                      <InPlaceCheckbox
                        afterChange={this.afterUpdate}
                        icon="check"
                        id={this.customTab.id}
                        value={this.customTab?.showOnPodcastWorksheets}
                        field='show_on_podcast_worksheets'
                        model="custom_tabs"
                      >
                        Show on Podcast Posts
                      </InPlaceCheckbox>
                    </div>
                  </>
                }
              </fieldset>
            </div>
            <div className="col-xs-12">
              <fieldset>
                <legend>Text</legend>
                <InPlaceText
                  wrapperClassName="word-info"
                  richText
                  defaultValue={this.customTab.content}
                  model="custom_tabs"
                  field="content"
                  inputType="textarea"
                  id={this.customTab.id}
                />
              </fieldset>
            </div>
            <div className="col-xs-12">
              <fieldset>
                <legend>Image</legend>
                <Text fontSize='15px'>This image will be displayed with full width under the text.</Text>
                <InPlaceImageUpload
                  image={this.customTab.image}
                  model="images"
                  onChange={this.afterUpdate}
                  querystring={{
                    imageable_id: this.customTab.id,
                    imageable_type: 'CustomTab',
                  }}
                />
                <InPlaceText
                  show
                  id={this.customTab.id}
                  defaultValue={this.customTab.imageLinkUrl}
                  model="custom_tabs"
                  field="image_link_url"
                  label="Image link url"
                />
              </fieldset>
            </div>
            <div className="col-xs-12">
              <fieldset>
                <legend>Youtube Video</legend>
                <Text fontSize='15px'>This video will be embedded with full width under the text and image (if there are any).</Text>
                <InPlaceText
                  show
                  id={this.customTab.id}
                  defaultValue={this.customTab.youtubeUrl}
                  model="custom_tabs"
                  field="youtube_url"
                />
              </fieldset>
            </div>
            <div className="col-xs-12">
              <fieldset>
                <legend>Button Link</legend>
                <Text fontSize='15px'>This button will be displayed at the bottom of the tab.</Text>
                <InPlaceText
                  show
                  id={this.customTab.id}
                  defaultValue={this.customTab.buttonUrl}
                  model="custom_tabs"
                  field="button_url"
                  label="Button URL"
                />
                <InPlaceText
                  show
                  id={this.customTab.id}
                  defaultValue={this.customTab.buttonText}
                  model="custom_tabs"
                  field="button_text"
                  label="Button text"
                />
              </fieldset>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default CustomTabEdit;

