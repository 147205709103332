import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import PropTypes from 'prop-types';
import { Api } from '@seedlang/stores';
import pluralize from 'pluralize';
import autobind from 'autobind-decorator';
import { UrlBuilder } from '@seedlang/models';
import { isPresent } from '@seedlang/utils';
import Spinner from 'components/spinner';

@observer
class InPlaceFileUpload extends React.Component {
  @observable showSpinner = false;
  @observable errorMessage = null;

  static propTypes = {
    successCallback: PropTypes.func,
    value: PropTypes.string,
    model: PropTypes.string,
    querystring: PropTypes.object
  }

  @autobind onMultipartPost() {
    this.showSpinner = true;
    this.errorMessage = null;
    Api.multipartPost({
      url: this.props.submitToUrl || new UrlBuilder().build(pluralize(this.props.model), this.props.querystring),
      data: {data: this.data()},
      successCallback: this.successCallback,
      failureCallback: this.failureCallback,
    });
  }

  @autobind onDelete() {
    this.showSpinner = true;
    this.errorMessage = null;
    Api.destroy({
      url: `/api/${pluralize(this.props.model)}/${this.props.file.id}`,
      successCallback: this.successCallback,
    });
  }

  data() {
    const data = new FormData();
    if (this.refs.input.files[0].size <= 100000000) { // don't allow upload over 100mb
      data.append('blob', this.refs.input.files[0]);
      return data;
    }
  }

  @autobind successCallback(resp) {
    this.showSpinner = false;
    if (this.props.successCallback) {
      this.props.successCallback(resp);
    }
  }

  @autobind failureCallback(resp) {
    this.showSpinner = false;

    this.errorMessage = resp.message ?? 'An error occurred while uploading the file.';

    if (this.props.failureCallback) {
      this.props.failureCallback(resp);
    }
  }

  render() {
    return (
      <div style={{position: 'relative'}} className="in-place-file-upload in-place-image-upload">
        <input
          type="file"
          ref="input"
          onChange={this.onMultipartPost}
        />
        {
          isPresent(this.props.file) && isPresent(this.props.file.url) &&
            <div className='file-wrapper'>
              <i
                className="fa fa-times fa-on-click delete-btn"
                onClick={this.onDelete.bind(this)}
              />
              <div className='url-wrapper'>
                <a href={this.props.file.url}>{this.props.file.url}</a>
              </div>
            </div>
        }
        {
          this.errorMessage && (
            <div style={{ color: 'red' }} onClick={() => this.errorMessage = null}>
              {this.errorMessage}
            </div>
          )
        }
        { this.showSpinner && <Spinner className='blue' />}
      </div>
    );
  }
}

export default InPlaceFileUpload;
