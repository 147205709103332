import React from 'react';
import CampaignTypeIndex from 'pages/builder/campaign_types/campaign_type_index';

class SiteCampaignTypeIndex extends React.Component {
  render() {
    return (
      <CampaignTypeIndex
        {...this.props}
        whiteLabeled
      />
    );
  }
}

export default SiteCampaignTypeIndex;
