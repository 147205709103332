import React from 'react';
import { observer } from 'mobx-react';
import XpCoin from 'components/xp_coin';
import { XpState } from '@seedlang/models';
import styled from '@emotion/styled';
import { XpAnimationWrapper } from '@seedlang/hoc';

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  position: ${(props) => props.position};
  bottom: ${(props) => props.bottom};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  left: ${(props) => props.left};
  padding: ${(props) => props.debug ? 20 : 0}px;
  border: ${(props) => props.debug ? "2px solid red" : "none"};
`;

@observer
class XpAnimation extends React.Component {

  render() {
    return (
      <Wrapper
        position={this.props.position}
        margin={this.props.margin}
        bottom={this.props.bottom}
        top={this.props.top}
        right={this.props.right}
        left={this.props.left}
        debug={this.props.debug}
        onClick={this.props.onClick}
      >
        {
          this.props.debug && 'XP ANIMATION WRAPPER'
        }
        {
          this.props.currentAnimations.map((item) => {
            return (
              <XpCoin
                key={item.id}
                state={XpState}
                onRest={XpState.onRemoveXpAnimation}
                {...this.props}
                {...item}
              />
            )
          })
        }
      </Wrapper>
    );
  }
}

export default XpAnimationWrapper(XpAnimation);
