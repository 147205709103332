"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = loadCss;
function loadCss(url) {
  var css = document.createElement('link');
  css.rel = 'stylesheet';
  css.href = url;
  document.head.appendChild(css);
}