import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import ContactForm from 'components/user/contact_form';
import { isBlank } from '@seedlang/utils';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import { Link } from 'react-router';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  margin: 10px;
  textarea {
    margin-bottom: 10px;
  }
  p, ul {
    font-size: 16px;
    line-height: 20px;
  }
  ul {
    margin: 0 0 0 20px;
    li {
      list-style-type: disc;
      margin: 0;
    }
  }
`;

const HelpCenterLink = styled.div`
  text-decoration: underline;
  margin-top: 5px;
`;

@observer
class Contact extends React.Component {
  @observable selectExpanded = false;

  static defaultProps = {
    autoExpand: true,
  }

  @computed get expanded() {
    return this.props.autoExpand || this.selectExpanded;
  }

  render() {
    return (
      <Wrapper>
        <h3>
          We would love to hear from you.
        </h3>

        {
          this.props.message &&
            <p>{this.props.message}</p>
        }
        {
          isBlank(this.props.message) &&
            <p>
              Whether it's a question or feedback, leave a message and we will get back to you as soon as we can.
            </p>
        }
        {
          this.expanded && AppUI.siteIsDefault &&
            <Link
              to={{name: 'help_center'}}
            >
              <HelpCenterLink>
                You can also view our Help Center.
              </HelpCenterLink>
            </Link>
        }
        {
          !this.expanded &&
            <Button
              width="100%"
              onClick={() => this.selectExpanded = true}
            >
              Leave Message
            </Button>
        }
        {
          this.expanded &&
            <ContactForm
              {...this.props}
            />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Contact);
