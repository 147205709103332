const vrb_A1 = [
  "to participate",
  "to join",
  "to try",
  "to cost",
  "to wash",
  "to hike",
  "to stand",
  "to get",
  "to bring along",
  "to sell",
  "to please",
  "to be glad",
  "to dance",
  "to meet",
  "to fly",
  "to go",
  "to smoke",
  "to stop",
  "to close",
  "to play",
  "to fall",
  "to buy",
  "to grill",
  "to find",
  "to learn",
  "to undress",
  "to take off",
  "to board",
  "to spell",
  "to rain",
  "to understand",
  "to thank",
  "to lay",
  "to know",
  "to belong",
  "to study",
  "to recommend",
  "to celebrate",
  "to breakfast",
  "to read",
  "to load",
  "to congratulate",
  "to stay overnight",
  "to swim",
  "to phone",
  "to visit",
  "to arrive",
  "to end",
  "to call",
  "to ask",
  "to lie",
  "to come along",
  "to see",
  "to take along",
  "to repeat",
  "to earn",
  "to win",
  "to run",
  "to sit",
  "to keep",
  "to sign",
  "to seem",
  "to act",
  "to believe",
  "to care",
  "to tell",
  "to marry",
  "to take",
  "to put",
  "to laugh",
  "to speak",
  "to equal",
  "to give",
  "to open",
  "to search",
  "to love",
  "to print",
  "to pick up",
  "to invite",
  "to mean",
  "to enroll",
  "to begin",
  "to attract",
  "to constitute",
  "to use",
  "to offer",
  "to exit",
  "to press",
  "to be allowed to",
  "to look",
  "to bring",
  "to send",
  "to eat",
  "to be",
  "to come",
  "to live",
  "to become",
  "to drink",
  "to say",
  "to help",
  "to cook",
  "to make",
  "to sleep",
  "to can",
  "to shower",
  "to work",
  "to get to know",
  "to should",
  "to have",
  "to wire",
  "to transfer",
  "to allow",
  "to move",
  "to imagine",
  "to tick",
  "to fill",
  "to depart",
  "to pay",
  "to explain",
  "to order",
  "to be pleased",
  "to repair",
  "to start",
  "to write",
  "to hear",
  "to need",
  "to remain",
  "to respond",
  "to wait",
  "to taste",
  "to drive",
  "to travel",
  "to like",
  "to apologize",
  "to shop",
  "to accompany",
  "to account for",
  "to achieve",
  "to acquire",
  "to announce",
  "to answer",
  "to apologise",
  "to appear",
  "to ask oneself",
  "to assemble",
  "to attend",
  "to bathe",
  "to be able to",
  "to be alive",
  "to be called",
  "to be named",
  "to be obliged",
  "to be permitted to",
  "to be similar to",
  "to be supposed to",
  "to behave",
  "to clarify",
  "to conclude",
  "to consider",
  "to declare",
  "to deliver",
  "to depend on",
  "to dress oneself",
  "to drill",
  "to drop off",
  "to enter",
  "to excuse",
  "to exercise",
  "to exist",
  "to finish",
  "to get dressed",
  "to get married",
  "to give birth",
  "to introduce oneself",
  "to invite oneself",
  "to join in",
  "to know each other",
  "to know oneself",
  "to lie down",
  "to listen",
  "to look after",
  "to look for",
  "to move out",
  "to obtain",
  "to party",
  "to pause",
  "to plan",
  "to point",
  "to practice",
  "to pull",
  "to purchase",
  "to reach",
  "to receive",
  "to reply",
  "to require",
  "to reserve",
  "to ride",
  "to seek",
  "to shine",
  "to show",
  "to stay",
  "to stretch",
  "to strike",
  "to strip",
  "to submit",
  "to suit",
  "to supply",
  "to surrender",
  "to talk",
  "to think",
  "to train",
  "to volunteer",
  "to walk",
  "to wander",
  "to want",
  "to watch",
  "to wonder",
];
const vrb_B1 = [
  "to abandon",
  "to accept",
  "to access",
  "to accompany",
  "to accomplish",
  "to accumulate",
  "to accuse",
  "to achieve",
  "to acknowledge",
  "to acquire",
  "to act",
  "to activate",
  "to actuate",
  "to adapt",
  "to add",
  "to add more",
  "to address",
  "to adjust",
  "to admire",
  "to admit",
  "to adopt",
  "to advance",
  "to advise",
  "to affect",
  "to affirm",
  "to agree",
  "to aim for",
  "to align",
  "to allow",
  "to allowed",
  "to amaze",
  "to amuse",
  "to amuse oneself",
  "to analyze",
  "to announce",
  "to annoy",
  "to apologize",
  "to appeal",
  "to appear",
  "to apply",
  "to apply make-up",
  "to apply makeup",
  "to apply to sth.",
  "to appreciate",
  "to approach",
  "to approve",
  "to argue",
  "to arise",
  "to arm",
  "to arrange",
  "to arrange oneself",
  "to arrest",
  "to arrive",
  "to ascend",
  "to ask",
  "to assemble",
  "to assign",
  "to assimilate",
  "to associate",
  "to assume",
  "to attack",
  "to attend",
  "to attest",
  "to attract",
  "to attribute",
  "to avenge",
  "to avoid",
  "to awake",
  "to award",
  "to balance",
  "to baptize",
  "to beat",
  "to belong",
  "to benefit",
  "to benefit sb.",
  "to bet",
  "to betray",
  "to bite",
  "to bleed",
  "to bless",
  "to bloom",
  "to blow",
  "to board",
  "to boil",
  "to boost",
  "to bore",
  "to borrow",
  "to braise",
  "to brake",
  "to break",
  "to break down",
  "to break in",
  "to breathe",
  "to bring  closer",
  "to broadcast",
  "to buckle up",
  "to build",
  "to bump",
  "to bump oneself",
  "to burn",
  "to burn oneself",
  "to calculate",
  "to calm",
  "to calm down",
  "to camp",
  "to cancel",
  "to care",
  "to catch",
  "to catch a cold",
  "to catch hold sth.",
  "to catch sight of",
  "to catch up",
  "to cause",
  "to center",
  "to certify",
  "to change",
  "to change clothes",
  "to characterize",
  "to charge",
  "to chat",
  "to cheat",
  "to check",
  "to check in",
  "to chew",
  "to choose",
  "to circumvent",
  "to claim",
  "to clap",
  "to clarify",
  "to classify",
  "to clean",
  "to clear",
  "to click",
  "to climb",
  "to clog",
  "to coincide",
  "to collaborate",
  "to collect",
  "to collect oneself",
  "to combine",
  "to come back",
  "to come by",
  "to come from",
  "to come in",
  "to come nearer",
  "to comment",
  "to commit",
  "to communicate",
  "to compare",
  "to compensate",
  "to compete",
  "to complain",
  "to complement",
  "to complete",
  "to complicate",
  "to compose",
  "to compromise",
  "to conceive",
  "to concentrate",
  "to conclude",
  "to condemn",
  "to confer",
  "to confess",
  "to confront",
  "to confuse",
  "to congratulate",
  "to connect",
  "to consent",
  "to consider",
  "to consist",
  "to constitute",
  "to consult",
  "to consume",
  "to contain",
  "to contemplate",
  "to continue",
  "to contradict",
  "to contribute",
  "to convene",
  "to convince",
  "to cool",
  "to copy",
  "to correct",
  "to correspond",
  "to cough",
  "to count",
  "to cover",
  "to crack",
  "to create",
  "to criticize",
  "to cross",
  "to cure",
  "to curse",
  "to cut out",
  "to damage",
  "to deceive",
  "to decide",
  "to decompose",
  "to decorate",
  "to decrease",
  "to dedicate",
  "to deem",
  "to defend",
  "to define",
  "to delete",
  "to deliberate",
  "to deliver",
  "to demand",
  "to demonstrate",
  "to deny",
  "to depart",
  "to depend",
  "to deprive",
  "to deserve",
  "to design",
  "to destroy",
  "to detail",
  "to detect",
  "to determine",
  "to develop",
  "to die",
  "to differ",
  "to differentiate",
  "to disappear",
  "to disappoint",
  "to discard",
  "to discover",
  "to discuss",
  "to dismiss",
  "to display",
  "to dispose",
  "to dispute",
  "to dissolve",
  "to distinguish",
  "to distribute",
  "to disturb",
  "to dive",
  "to divert",
  "to dominate",
  "to doubt",
  "to download",
  "to draw near",
  "to drift",
  "to drip",
  "to drive",
  "to drive away",
  "to drown",
  "to dry",
  "to eat (animal)",
  "to eat up",
  "to educate",
  "to elaborate",
  "to eliminate",
  "to embrace",
  "to employ",
  "to employ sb.",
  "to encounter",
  "to encourage",
  "to enjoy",
  "to enlarge",
  "to enrich",
  "to ensure",
  "to enter",
  "to entertain",
  "to erect sth.",
  "to err",
  "to escape",
  "to establish",
  "to estimate",
  "to evolve",
  "to exaggerate",
  "to exceed",
  "to exchange",
  "to exclude",
  "to execute",
  "to exert",
  "to exhibit",
  "to exist",
  "to expand",
  "to expect",
  "to experience",
  "to experiment",
  "to explode",
  "to explore",
  "to expose",
  "to express",
  "to extend",
  "to extract",
  "to extract sth.",
  "to face",
  "to facilitate",
  "to fail",
  "to fall",
  "to fall asleep",
  "to fall in love",
  "to favor",
  "to fax",
  "to fear",
  "to feed",
  "to feel",
  "to fight",
  "to fight each other",
  "to figure",
  "to fill",
  "to fill up",
  "to film",
  "to finance",
  "to finance oneself",
  "to find",
  "to find out",
  "to finish",
  "to fire",
  "to fit",
  "to fix",
  "to flash",
  "to flee",
  "to flow",
  "to focus",
  "to fold",
  "to follow",
  "to forbid",
  "to force",
  "to force oneself",
  "to foresee",
  "to forgive",
  "to form",
  "to foster",
  "to found",
  "to frame",
  "to free",
  "to freeze",
  "to frost",
  "to fry",
  "to fulfill",
  "to gasp",
  "to gather",
  "to generalize",
  "to generate",
  "to get",
  "to get annoyed",
  "to get better",
  "to get engaged",
  "to get frightened",
  "to get in touch",
  "to get it right",
  "to get lost",
  "to get out",
  "to get sb. used to sth.",
  "to get sth. back",
  "to get to know sth.",
  "to get upset",
  "to get used to",
  "to get wet",
  "to give one's opinion",
  "to give up",
  "to glue",
  "to go back up",
  "to go out again",
  "to go to",
  "to go to bed",
  "to go with sb.",
  "to google",
  "to gossip",
  "to govern",
  "to grab",
  "to grade",
  "to grant",
  "to grant lands",
  "to grasp",
  "to greet",
  "to grow",
  "to guarantee",
  "to handle",
  "to hang",
  "to happen",
  "to hate",
  "to hate oneself",
  "to have a good sleep",
  "to have sb. to stay",
  "to have sth available",
  "to heal",
  "to hide",
  "to hijack",
  "to hinder",
  "to hire",
  "to hit",
  "to hold",
  "to hold back",
  "to hold on",
  "to honk at sb.",
  "to hunt",
  "to hurry",
  "to hurt",
  "to imagine",
  "to imply",
  "to impose",
  "to impress",
  "to improve",
  "to inaugurate",
  "to incarcerate",
  "to include",
  "to incorporate",
  "to increase",
  "to index",
  "to indulge in sth.",
  "to influence",
  "to inform",
  "to infuriate",
  "to inhale",
  "to initiate",
  "to insert",
  "to insist",
  "to inspire",
  "to install",
  "to instruct",
  "to insult",
  "to insure",
  "to integrate",
  "to intend",
  "to interest",
  "to interpret",
  "to interrupt",
  "to intervene",
  "to introduce",
  "to intuit",
  "to invade",
  "to invent",
  "to invest",
  "to investigate",
  "to invoice",
  "to involve",
  "to iron",
  "to jingle",
  "to join",
  "to jump",
  "to justify",
  "to keep",
  "to kill",
  "to kiss",
  "to knock",
  "to land",
  "to last",
  "to laugh",
  "to lay",
  "to lay down",
  "to lead",
  "to leave",
  "to lend",
  "to let sb. know",
  "to lie",
  "to lie down",
  "to lift",
  "to lift up",
  "to light",
  "to limit",
  "to link",
  "to list",
  "to listen",
  "to live",
  "to live together",
  "to load",
  "to locate",
  "to lock up",
  "to look",
  "to look after",
  "to look up",
  "to maintain",
  "to make",
  "to make a bid",
  "to make a fuss",
  "to make an appointment",
  "to make an effort",
  "to make out",
  "to manage",
  "to manifest",
  "to mark",
  "to market",
  "to marry",
  "to match",
  "to mean",
  "to measure",
  "to meditate",
  "to meet again",
  "to melt",
  "to mention",
  "to mince",
  "to mix",
  "to monitor",
  "to move",
  "to move away",
  "to move in",
  "to murder",
  "to name",
  "to narrate",
  "to need",
  "to negotiate",
  "to not know",
  "to note",
  "to notice",
  "to notify",
  "to nourish",
  "to obey",
  "to observe",
  "to obtain",
  "to occupy",
  "to occur",
  "to open",
  "to operate",
  "to opt",
  "to opt for",
  "to order",
  "to overcome",
  "to overlook",
  "to overtake",
  "to own",
  "to pack",
  "to participate",
  "to pay off",
  "to pay out",
  "to perceive",
  "to perform",
  "to persuade",
  "to place",
  "to plan",
  "to please",
  "to plug in",
  "to point",
  "to pollute",
  "to pose",
  "to postpone",
  "to pour",
  "to practice",
  "to praise",
  "to pray",
  "to precede",
  "to predict",
  "to prefix",
  "to prepare",
  "to prescribe",
  "to preserve",
  "to preside",
  "to press",
  "to prevent",
  "to print out",
  "to proceed",
  "to produce",
  "to program",
  "to prohibit",
  "to promise",
  "to promote",
  "to pronounce",
  "to propagate",
  "to propose",
  "to protect",
  "to protest",
  "to prove",
  "to provide",
  "to provoke",
  "to publish",
  "to pull out",
  "to punish",
  "to pursue",
  "to push",
  "to push aside",
  "to push away",
  "to put",
  "to quarrel",
  "to queue",
  "to queue up",
  "to quote",
  "to raise",
  "to raise awareness",
  "to reach",
  "to read again",
  "to read out loud",
  "to realize",
  "to rebuild",
  "to recall",
  "to receive",
  "to recognize",
  "to recommend",
  "to recommend oneself",
  "to reconstitute",
  "to record",
  "to recover",
  "to recover oneself",
  "to recycle",
  "to reduce",
  "to refer",
  "to reflect",
  "to refrain from",
  "to refuel",
  "to refund",
  "to refuse",
  "to register",
  "to regret",
  "to regulate",
  "to rehearse",
  "to reinforce",
  "to reject",
  "to relate",
  "to relax",
  "to release",
  "to relieve",
  "to remain",
  "to remember",
  "to remind",
  "to remove",
  "to rent",
  "to rent out",
  "to repair",
  "to replace",
  "to replenish",
  "to report",
  "to represent",
  "to reproach",
  "to reproduce",
  "to request",
  "to require",
  "to rescue",
  "to reside",
  "to resist",
  "to respect",
  "to respond",
  "to restrict",
  "to resume",
  "to retire",
  "to retract",
  "to return",
  "to reveal",
  "to reward",
  "to ride",
  "to ring",
  "to rinse",
  "to rise",
  "to rub",
  "to run",
  "to run away",
  "to run over",
  "to rush",
  "to sabotage",
  "to salt",
  "to satisfy",
  "to save",
  "to save oneself",
  "to say goodbye",
  "to scare",
  "to scratch",
  "to scream",
  "to secure",
  "to seduce",
  "to see again",
  "to select",
  "to send",
  "to send an email",
  "to sentence",
  "to separate",
  "to serve",
  "to set",
  "to set up",
  "to sew",
  "to shake",
  "to shape",
  "to shock",
  "to shoot",
  "to show oneself",
  "to sigh",
  "to sign",
  "to sign out",
  "to simplify",
  "to sin",
  "to sink",
  "to sit down",
  "to skip",
  "to sleep in",
  "to smile",
  "to snow",
  "to solve",
  "to sound",
  "to specialize",
  "to specify",
  "to speed up",
  "to spend",
  "to spread",
  "to stamp",
  "to stand out",
  "to star",
  "to start",
  "to stay",
  "to steal",
  "to step",
  "to sting",
  "to stink",
  "to stop",
  "to store",
  "to stretch",
  "to strike",
  "to subdue",
  "to subscribe",
  "to succeed",
  "to suck",
  "to suffer",
  "to suggest",
  "to suit",
  "to summarize",
  "to supervise",
  "to support",
  "to surf",
  "to surpass",
  "to surprise",
  "to surround",
  "to survive",
  "to suspect",
  "to sway",
  "to swear",
  "to sweat",
  "to switch",
  "to symbolize",
  "to take a chance",
  "to take off",
  "to taste",
  "to teach",
  "to test",
  "to thank",
  "to threaten",
  "to throw",
  "to throw up",
  "to tie",
  "to title",
  "to touch",
  "to train",
  "to transfer",
  "to transform",
  "to transmit",
  "to transport",
  "to travel",
  "to treat",
  "to tremble",
  "to trouble",
  "to trust",
  "to try",
  "to try on",
  "to try out",
  "to turn around",
  "to turn on",
  "to type",
  "to underline",
  "to understand",
  "to undertake",
  "to undress",
  "to unify",
  "to upload",
  "to upset",
  "to use",
  "to value",
  "to vary",
  "to venture",
  "to vote",
  "to waive",
  "to wake up",
  "to wander",
  "to warn",
  "to wash off",
  "to waste",
  "to watch",
  "to watch out",
  "to wave",
  "to weigh",
  "to welcome",
  "to win",
  "to wish",
  "to wonder",
  "to work",
  "to worry",
  "to worsen",
  "to worship",
  "to write",
  "to yearn for"];
const adj_A1 = [
"emotional",
"fast",
"beautiful",
"high",
"funny",
"gray",
"male",
"wrong",
"free",
"vacant",
"available",
"gold",
"more",
"bad",
"little",
"briefly",
"short",
"dear",
"kind",
"good",
"equal",
"tired",
"hard",
"heavy",
"unemployed",
"other",
"long",
"last",
"green",
"loud",
"wide",
"quiet",
"cultural",
"local",
"black",
"cheap",
"affordable",
"newly",
"hasty",
"big",
"all",
"second",
"hot",
"brown",
"cold",
"red",
"small",
"clear",
"evil",
"international",
"slightly",
"unmarried",
"single",
"broken",
"punctual",
"ill",
"orange",
"bright",
"yellow",
"important",
"half",
"possible",
"wonderful",
"expensive",
"valid",
"white",
"calm",
"happy",
"finished",
"blue",
"degree",
"first",
"much",
"old",
"married",
"simply",
"famous",
"open",
"pink",
"united",
"lazy",
"alien",
"terrible",
"sustaining",
"female",
"welcome",
"bitter",
"angry",
"automatic",
"best",
"better",
"brief",
"broad",
"clean",
"colorful",
"convenient",
"difficult",
"dirty",
"early",
"easy",
"far",
"favorable",
"foreign",
"fortunate",
"grey",
"hungry",
"inexpensive",
"jobless",
"large",
"legal",
"logical",
"lovely",
"lucky",
"manly",
"masculine",
"nasty",
"new",
"nice",
"powerful",
"ready",
"regional",
"rotten",
"sad",
"serious",
"sick",
"silent",
"simple",
"sincere",
"straight",
"strong",
"tall",
"unfamiliar",
"urgent",
"well-known",
"worn out",
"young"
];
const adj_B1 = [
  "absent",
  "absolute",
  "absurd",
  "academic",
  "acceptable",
  "accessible",
  "accurate",
  "accustomed",
  "actual",
  "addicted",
  "additional",
  "adjusted",
  "administrative",
  "advertising",
  "affective",
  "affordable",
  "aggressive",
  "agreed",
  "airborne",
  "alcoholic",
  "alive",
  "allowed",
  "alternative",
  "amateur",
  "amazing",
  "ambitious",
  "analog",
  "animal",
  "annoying",
  "annual",
  "anonymous",
  "antisocial",
  "approximate",
  "aquatic",
  "archaeological",
  "arrogant",
  "artificial",
  "artistic",
  "associative",
  "atheist",
  "atlantic",
  "attractive",
  "atypical",
  "audio",
  "automobile",
  "autonomous",
  "available",
  "aware",
  "bad",
  "balanced",
  "bald",
  "bank",
  "banking",
  "basic",
  "beautiful",
  "beige",
  "below",
  "best",
  "biological",
  "bitter",
  "blind",
  "brain",
  "brave",
  "brutal",
  "buddhist",
  "business",
  "busy",
  "candidate",
  "capable",
  "central",
  "certain",
  "champion",
  "charismatic",
  "charming",
  "chauvinist",
  "cheeky",
  "chemical",
  "civic",
  "civil",
  "classy",
  "clear",
  "clinic",
  "close to",
  "closed",
  "cloudy",
  "collaborative",
  "comfortable",
  "commercial",
  "common",
  "communist",
  "compact",
  "comparable",
  "compared",
  "compatible",
  "competitive",
  "competitor",
  "complementary",
  "complete",
  "complex",
  "complicated",
  "computer",
  "concerned",
  "concrete",
  "confidential",
  "connected",
  "conservative",
  "considerable",
  "constant",
  "constitutional",
  "contemporary",
  "continuous",
  "contradictory",
  "contrary",
  "cool",
  "correct",
  "correspondent",
  "corrupt",
  "cracked",
  "craft",
  "creative",
  "creepy",
  "criminal",
  "critical",
  "crooked",
  "crucial",
  "curator",
  "curious",
  "current",
  "cyclist",
  "dead",
  "deaf",
  "decided",
  "deep",
  "definitive",
  "delayed",
  "delicate",
  "delicious",
  "democratic",
  "dependent",
  "description",
  "design",
  "determined",
  "developed",
  "different",
  "differently",
  "digital",
  "disabled",
  "disappointed",
  "disappointing",
  "discreet",
  "divorced",
  "documentary",
  "double",
  "dramatic",
  "dried",
  "drunk",
  "durable",
  "ecological",
  "economical",
  "educational",
  "effective",
  "efficient",
  "electoral",
  "electric",
  "electronic",
  "elegant",
  "elementary",
  "embarrassing",
  "emotional",
  "employed",
  "enemy",
  "energetic",
  "enthusiastic",
  "entire",
  "environmental",
  "eternal",
  "everyday",
  "evident",
  "exaggerated",
  "excellent",
  "excited",
  "exciting",
  "exhausted",
  "expected",
  "expensive",
  "expert",
  "exterior",
  "external",
  "extraordinary",
  "extreme",
  "fabulous",
  "fair",
  "faithful",
  "familiar",
  "famous",
  "fancy",
  "farmer",
  "fat",
  "fatal",
  "fault",
  "favorite",
  "feature",
  "federal",
  "feminine",
  "feminist",
  "festive",
  "final",
  "financial",
  "finished",
  "fixed",
  "flat",
  "flexible",
  "fluent",
  "following",
  "forbidden",
  "formal",
  "former",
  "fragile",
  "frank",
  "free",
  "fresh",
  "friendly",
  "frozen",
  "full",
  "functional",
  "fundamental",
  "furious",
  "furnished",
  "future",
  "general",
  "generalist",
  "geometric",
  "gigantic",
  "global",
  "golden",
  "gorgeous",
  "graphic",
  "grateful",
  "great",
  "greedy",
  "grumpy",
  "guilty",
  "habitual",
  "hidden",
  "historian",
  "historical",
  "honest",
  "horizontal",
  "horrible",
  "huge",
  "human",
  "humanitarian",
  "humble",
  "humid",
  "humiliating",
  "hypocritical",
  "ideal",
  "idealist",
  "illegal",
  "immediate",
  "impressive",
  "increased",
  "incredible",
  "independent",
  "indirect",
  "individual",
  "industrial",
  "influenced",
  "informal",
  "initial",
  "innocent",
  "innovative",
  "insane",
  "insufficient",
  "integrated",
  "intellectual",
  "intense",
  "intensive",
  "intercultural",
  "internal",
  "irregular",
  "joint",
  "judicial",
  "just",
  "last",
  "left",
  "legal",
  "legislative",
  "legitimate",
  "liberal",
  "lilac",
  "liquid",
  "literary",
  "live",
  "lively",
  "logical",
  "lonely",
  "loose",
  "lovely",
  "low",
  "low-fat",
  "loyal",
  "made-up",
  "major",
  "majority",
  "mandatory",
  "manual",
  "marginal",
  "maritime",
  "marked",
  "married",
  "massive",
  "maternal",
  "mathematical",
  "mature",
  "maximum",
  "meaningful",
  "mechanical",
  "medical",
  "medium",
  "mental",
  "merchant",
  "mild",
  "military",
  "minimal",
  "minimum",
  "mixed",
  "mobile",
  "monthly",
  "morale",
  "mormon",
  "most",
  "multimedia",
  "municipal",
  "musical",
  "mute",
  "mutual",
  "narrow",
  "native",
  "near",
  "neat",
  "necessary",
  "negative",
  "next door",
  "night",
  "normal",
  "northern",
  "nuclear",
  "numerous",
  "obvious",
  "offended",
  "official",
  "olympic",
  "only",
  "operating",
  "optimist",
  "optimistic",
  "oral",
  "orange",
  "organized",
  "oriental",
  "original",
  "orthodox",
  "painful",
  "pale",
  "parallel",
  "particular",
  "passive",
  "perfect",
  "perhaps",
  "permanent",
  "permitted",
  "personal",
  "pessimistic",
  "physical",
  "planned",
  "playful",
  "pleasant",
  "poetic",
  "pointless",
  "police",
  "polite",
  "political",
  "popular",
  "positive",
  "powerful",
  "prepared",
  "present",
  "presidential",
  "prestigious",
  "presumable",
  "pretty",
  "prevailing",
  "previous",
  "priority",
  "prisoner",
  "problematic",
  "productive",
  "professional",
  "progressive",
  "promiscuous",
  "promising",
  "proud",
  "provisional",
  "prudent",
  "psychological",
  "public",
  "punishable",
  "punished",
  "pure",
  "purple",
  "quickly",
  "racist",
  "random",
  "rare",
  "raw",
  "real",
  "realistic",
  "reasonable",
  "recent",
  "rectangular",
  "regional",
  "regular",
  "relative",
  "relevant",
  "reliable",
  "religious",
  "removed",
  "representative",
  "republican",
  "required",
  "reserved",
  "residential",
  "resolved",
  "responsible",
  "responsive",
  "ripe",
  "rival",
  "round",
  "rural",
  "saint",
  "salty",
  "same",
  "sanitary",
  "satellite",
  "satisfied",
  "satisfying",
  "scarce",
  "secret",
  "secular",
  "separate",
  "serious",
  "severe",
  "sexual",
  "sharp",
  "shy",
  "silver",
  "similar",
  "single",
  "skilled",
  "skinny",
  "sliced",
  "slim",
  "small",
  "smiling",
  "smooth",
  "sober",
  "social",
  "socialist",
  "soft",
  "solar",
  "solid",
  "sound",
  "south",
  "space",
  "spacious",
  "special",
  "specialist",
  "specific",
  "spectacular",
  "speedy",
  "spelled",
  "spontaneous",
  "sports",
  "square",
  "stable",
  "state",
  "statistical",
  "steep",
  "stormy",
  "strategic",
  "strenuous",
  "stressed",
  "strict",
  "strong",
  "stunning",
  "subjective",
  "sublime",
  "successful",
  "sufficient",
  "suitable",
  "summer",
  "surprised",
  "surprising",
  "suspicious",
  "sustainable",
  "symbolic",
  "technical",
  "technological",
  "technology",
  "tempered",
  "temporary",
  "tender",
  "terrible",
  "territorial",
  "theoretical",
  "therapeutic",
  "thermal",
  "thin",
  "thirsty",
  "tight",
  "timely",
  "together",
  "tolerant",
  "tourist",
  "toxic",
  "traditional",
  "tragic",
  "translated",
  "transparent",
  "tremendous",
  "triangular",
  "true",
  "turquoise",
  "typical",
  "unavoidable",
  "uncertain",
  "unconscious",
  "undecided",
  "underground",
  "understandable",
  "unemployed",
  "unexpected",
  "unfriendly",
  "uniform",
  "unimportant",
  "united",
  "unlimited",
  "unpublished",
  "unsuccessful",
  "unsuspecting",
  "unusual",
  "urban",
  "urgent",
  "usable",
  "useless",
  "usual",
  "usually",
  "vain",
  "valuable",
  "variable",
  "various",
  "vegetable",
  "vegetarian",
  "versatile",
  "vertical",
  "violent",
  "virtual",
  "visible",
  "visited",
  "voice",
  "volunteer",
  "wave",
  "weekly",
  "weird",
  "western",
  "wide",
  "wild",
  "wise",
  "wonderful",
  "worker",
  "worldwide"];
const noun_A1 = [
  "surname",
  "first name",
  "class",
  "landlord",
  "reunion",
  "example",
  "hair",
  "salad",
  "country",
  "jacket",
  "computer store",
  "washing",
  "rent",
  "light",
  "machine",
  "information",
  "course",
  "world",
  "hotel",
  "lord",
  "March",
  "weekday",
  "airport",
  "lake",
  "customer",
  "year",
  "eye",
  "railway",
  "wind",
  "foreigner",
  "banana",
  "german class",
  "helicopter",
  "welcome",
  "stairs",
  "chefs",
  "mouth",
  "job",
  "size",
  "height",
  "rinse",
  "weekend",
  "son",
  "grandmother",
  "form",
  "moment",
  "hunger",
  "plane",
  "absence",
  "glimpse",
  "glance",
  "view",
  "winter",
  "beginning",
  "sofa",
  "hour",
  "primary",
  "area code",
  "fruit",
  "week",
  "paper",
  "question",
  "computer",
  "break",
  "school",
  "fire",
  "departure",
  "tea",
  "problem",
  "letter",
  "date",
  "image",
  "picture",
  "sauerkraut",
  "leg",
  "coffee",
  "group",
  "fall",
  "autumn",
  "ticket",
  "french",
  "breakfast",
  "business",
  "shop",
  "statement",
  "photo",
  "west",
  "bread",
  "village",
  "box",
  "number",
  "passport",
  "bed",
  "arrival",
  "weight",
  "color",
  "afternoon",
  "teacher",
  "leadership",
  "apartment",
  "girl",
  "invitation",
  "butter",
  "housewife",
  "apology",
  "boy",
  "single room",
  "hand",
  "salutation",
  "appearance",
  "family",
  "plan",
  "table",
  "respect",
  "lady",
  "bottle",
  "abroad",
  "account",
  "address",
  "adjective",
  "airplane",
  "announcement",
  "answering machine",
  "apple",
  "apple juice",
  "appointment",
  "arm",
  "arrangement",
  "assignment",
  "athletics",
  "attention",
  "baby",
  "bag",
  "baggage",
  "bakery",
  "bank",
  "bath",
  "bathroom",
  "beer",
  "belly",
  "bench",
  "bike",
  "bill",
  "birthday",
  "body hair",
  "book",
  "boss",
  "brother",
  "bus",
  "bus stop",
  "cabinet",
  "cake",
  "call",
  "car",
  "celebration",
  "cell phone",
  "chef",
  "cherry",
  "chicken",
  "child",
  "cigarette",
  "cinema",
  "city",
  "clock",
  "closet",
  "clothes",
  "colleague",
  "colour",
  "company",
  "conclusion",
  "cook",
  "corner",
  "counter",
  "cuisine",
  "cupboard",
  "customs",
  "dad",
  "daughter",
  "day",
  "deficiency",
  "degree",
  "dinner",
  "doctor",
  "dog",
  "drink",
  "driver",
  "education",
  "egg",
  "elevator",
  "end",
  "entrance",
  "error",
  "evening",
  "exam",
  "examination",
  "excuse",
  "family name",
  "farm",
  "father",
  "feedback",
  "film",
  "fish",
  "fitness studio",
  "flat",
  "flat share",
  "flower",
  "food",
  "football",
  "foreign",
  "free time",
  "freeway",
  "fridge",
  "friend",
  "garden",
  "gentleman",
  "german teacher",
  "gift",
  "glass",
  "goodbye",
  "gorilla",
  "grandfather",
  "grandma",
  "grandpa",
  "greenery",
  "greeting",
  "guest",
  "hall",
  "ham",
  "happiness",
  "head",
  "highway",
  "hobby",
  "home",
  "homeland",
  "homework",
  "house",
  "husband",
  "identification",
  "identification card",
  "insect",
  "intermission",
  "internet",
  "internship",
  "invoice",
  "issue",
  "jeans",
  "journey",
  "juice",
  "ketchup",
  "key",
  "keyboard",
  "kiosk",
  "kitchen",
  "land",
  "language",
  "lesson",
  "life",
  "lift",
  "lobby",
  "look",
  "luck",
  "luggage",
  "lunch",
  "lyrics",
  "mail",
  "man",
  "mayonnaise",
  "meal",
  "meat",
  "medical doctor",
  "menu",
  "message",
  "milk",
  "minute",
  "mistake",
  "money",
  "month",
  "morning",
  "mother",
  "motherland",
  "motorway",
  "mouse",
  "movie",
  "movie theater",
  "name",
  "newspaper",
  "night",
  "noon",
  "north",
  "nut",
  "oil",
  "one-room apartment",
  "order",
  "output",
  "paint",
  "part",
  "partner",
  "party",
  "pear",
  "person",
  "phone",
  "piece",
  "place",
  "platform",
  "police",
  "port",
  "post",
  "potato",
  "power",
  "present",
  "price",
  "printer",
  "rain",
  "raspberry",
  "refrigerator",
  "restaurant",
  "restroom",
  "rice",
  "room",
  "runner",
  "salt",
  "sentence",
  "share",
  "shared room",
  "shield",
  "shoe",
  "shortage",
  "shower",
  "sign",
  "signature",
  "sister",
  "solution",
  "spa",
  "space",
  "spare time",
  "sport",
  "spring",
  "spring time",
  "stair",
  "stamp",
  "start",
  "station",
  "stay-at-home mom",
  "stomach",
  "stop",
  "street",
  "streetcar",
  "student",
  "studio",
  "suitcase",
  "summer",
  "sun",
  "swimming pool",
  "switch",
  "t-shirt",
  "tap",
  "task",
  "taxi",
  "taxi-driver",
  "television",
  "test",
  "testimony",
  "text",
  "theme",
  "thing",
  "time",
  "toilet",
  "tomato",
  "topic",
  "track",
  "train",
  "train station",
  "tram",
  "travel",
  "tree",
  "trip",
  "tuition",
  "van",
  "vegetable",
  "vehicle",
  "vending machine",
  "vocabulary",
  "vocabulary test",
  "voice mail",
  "waist",
  "wardrobe",
  "watch",
  "water",
  "weather",
  "wedding",
  "wife",
  "window",
  "wine",
  "woman",
  "work",
];
const noun_B1 = ["activity",
  "actress",
  "administration",
  "adult",
  "advantage",
  "advertisement",
  "advice",
  "alcohol",
  "alternative",
  "analysis",
  "apparatus",
  "appearance",
  "appetizer",
  "application",
  "area",
  "assembly",
  "audience",
  "authority",
  "average",
  "ballpoint pen",
  "band",
  "bank account",
  "basket",
  "beginning",
  "behavior",
  "birth",
  "blood",
  "boat",
  "bookshop",
  "booth",
  "border",
  "boredom",
  "bowl",
  "box",
  "brand",
  "breakdown",
  "buffet",
  "building",
  "burglary",
  "button",
  "café",
  "candy",
  "car factory",
  "century",
  "chalet",
  "challenge",
  "chance",
  "change",
  "channel",
  "checkout",
  "choice",
  "church",
  "circle",
  "citizen",
  "claim",
  "classroom",
  "coast",
  "coin",
  "communication",
  "community",
  "comparison",
  "competition",
  "compromise",
  "concern",
  "condition",
  "conference",
  "confirmation",
  "conflict",
  "connection",
  "consulate",
  "consultation",
  "content",
  "contribution",
  "control",
  "conversation",
  "conviction",
  "couch",
  "courage",
  "course",
  "court",
  "cousin",
  "cow",
  "crisis",
  "criticism",
  "cross",
  "curtain",
  "daily life",
  "damage",
  "dance step",
  "danger",
  "death",
  "decade",
  "decision",
  "declaration",
  "demand",
  "democracy",
  "department",
  "detail",
  "detergent",
  "development",
  "dialect",
  "difficulty",
  "direction",
  "disadvantage",
  "discussion",
  "display",
  "dispute",
  "district",
  "divorce",
  "document",
  "dolphin",
  "doubt",
  "driving licence",
  "economy",
  "edge",
  "edition",
  "effect",
  "email address",
  "employment",
  "energy",
  "entrepreneur",
  "entry",
  "envelope",
  "environment",
  "escape",
  "event",
  "exception",
  "exhibition",
  "expenses",
  "experience",
  "expression",
  "facility",
  "fair",
  "federal state",
  "fee",
  "field",
  "fight",
  "figure",
  "fire department",
  "flavor",
  "fog",
  "folk music",
  "foot",
  "football team",
  "force",
  "fork",
  "form",
  "freedom",
  "function",
  "future",
  "gallery",
  "garbage",
  "gas",
  "gate",
  "generation",
  "goat",
  "god",
  "gold",
  "government",
  "grill",
  "groceries",
  "ground floor",
  "gymnasium",
  "half",
  "hall",
  "head",
  "heading",
  "heart",
  "heat",
  "height",
  "helmet",
  "high school",
  "hole",
  "holiday",
  "hope",
  "humor",
  "imagination",
  "impression",
  "industry",
  "influence",
  "injury",
  "instruction",
  "introduction",
  "investigation",
  "invitation",
  "jazz",
  "job interview",
  "journey",
  "joy",
  "judge",
  "judgement",
  "justification",
  "knowledge",
  "ladder",
  "landlord",
  "law",
  "leadership",
  "line",
  "liquid",
  "list",
  "literature",
  "location",
  "loss",
  "love",
  "mailbox",
  "majority",
  "marriage",
  "master",
  "meadow",
  "measure",
  "media",
  "member",
  "memory",
  "message",
  "mineral water",
  "miracle",
  "mirror",
  "moment",
  "mood",
  "motor vehicle",
  "motorcycle",
  "movement",
  "music",
  "need",
  "needle",
  "net",
  "network",
  "news",
  "next thing",
  "niece",
  "noise",
  "novel",
  "number",
  "nurse",
  "office",
  "onion",
  "opening",
  "opera",
  "operation",
  "opportunity",
  "opposite",
  "overtime",
  "package",
  "painter",
  "parking",
  "part-time",
  "password",
  "past",
  "pavement",
  "payment",
  "peace",
  "pension",
  "percentage",
  "performance",
  "perpetrator",
  "picnic",
  "pipe",
  "plan",
  "planning",
  "plant",
  "point",
  "point in time",
  "policeman",
  "pollution",
  "population",
  "porcelain box",
  "porcupine",
  "port",
  "portion",
  "possibility",
  "postman",
  "potato salad",
  "pound",
  "praise",
  "presentation",
  "process",
  "production",
  "professor",
  "profit",
  "programming course",
  "progress",
  "promotion",
  "pronunciation",
  "property",
  "proposal",
  "pub",
  "public",
  "publication",
  "publisher",
  "punishment",
  "purpose",
  "purse",
  "quality",
  "quantity",
  "ratio",
  "reality",
  "reason",
  "recording",
  "reform",
  "region",
  "regulation",
  "relationship",
  "relative",
  "religion",
  "report",
  "representation",
  "reproach",
  "request",
  "research",
  "responsibility",
  "rest",
  "risk",
  "roof",
  "round",
  "route",
  "sack",
  "scene",
  "science",
  "screen",
  "seat",
  "section",
  "security",
  "selection",
  "sense",
  "separation",
  "series",
  "service",
  "shelf",
  "sign",
  "significance",
  "sink",
  "slice",
  "snack",
  "society",
  "socket",
  "song",
  "soup cup",
  "souvenir",
  "spectator",
  "speech",
  "spiderweb",
  "square kilometer",
  "square meter",
  "stadium",
  "staff",
  "standpoint",
  "stay",
  "steering wheel",
  "step",
  "stone",
  "stress",
  "strike",
  "studies",
  "study",
  "style",
  "success",
  "sum",
  "superhero",
  "supervisor",
  "support",
  "surprise",
  "survey",
  "suspicion",
  "syringe",
  "system",
  "table",
  "tarantula",
  "tax",
  "technology",
  "teenager",
  "temperature",
  "the only one",
  "the state",
  "theory",
  "thought",
  "ticket",
  "tourism",
  "town hall",
  "trade",
  "tradition",
  "trainer",
  "translation",
  "transportation",
  "travel agency",
  "travel guide",
  "treasure",
  "trend",
  "truck",
  "truth",
  "tutoring",
  "understanding",
  "unemployment",
  "value",
  "vehicle",
  "velocity",
  "victory",
  "video",
  "video producer",
  "violence",
  "war",
  "warehouse",
  "way",
  "weather report",
  "winner",
  "wish",
  "wood",
  "work colleague",
  "work permit",
  "writer",
  "yogurt"];
const adv_A1 = [
  "a little bit",
  "a lot of",
  "about",
  "above",
  "afterwards",
  "again",
  "ahead",
  "alike",
  "alone",
  "along",
  "alongside",
  "already",
  "also",
  "always",
  "anymore",
  "around",
  "as well",
  "aside",
  "at the moment",
  "back",
  "badly",
  "beautifully",
  "behind",
  "below",
  "besides",
  "best",
  "better",
  "bit",
  "both",
  "briefly",
  "closed",
  "correctly",
  "currently",
  "definitely",
  "definitively",
  "down",
  "downstairs",
  "early",
  "easy",
  "equally",
  "even",
  "evening",
  "fast",
  "faster",
  "first",
  "first of all",
  "for now",
  "frequently",
  "from",
  "further",
  "gladly",
  "good",
  "gradually",
  "greatly",
  "here",
  "highly",
  "however",
  "immediately",
  "in a moment",
  "in addition",
  "just",
  "just now",
  "late",
  "later",
  "least",
  "like",
  "little",
  "long",
  "longer",
  "many",
  "merely",
  "more",
  "morning",
  "most of",
  "most of the time",
  "mostly",
  "much",
  "never",
  "next",
  "next door",
  "next to",
  "no",
  "now",
  "off",
  "often",
  "once",
  "only",
  "outdoors",
  "outside",
  "over",
  "overly",
  "poorly",
  "possible",
  "preferably",
  "presently",
  "properly",
  "quickly",
  "rapidly",
  "rather",
  "really",
  "recently",
  "right",
  "same",
  "shortly",
  "simply",
  "since",
  "slightly",
  "slowly",
  "so",
  "solely",
  "someday",
  "somewhat",
  "soon",
  "sooner",
  "still",
  "straight",
  "straight ahead",
  "straightforward",
  "such",
  "then",
  "there",
  "therefore",
  "though",
  "today",
  "tomorrow",
  "too",
  "totally",
  "under",
  "upstairs",
  "usually",
  "very",
  "very much",
  "way",
  "well",
  "willingly",
  "yesterday",
  "yet",
];
const adv_B1 = ["about",
  "about what",
  "absolutely",
  "across",
  "actually",
  "afterwards",
  "again",
  "ago",
  "ahead",
  "alike",
  "almost",
  "along",
  "already",
  "also",
  "always",
  "anyway",
  "apart",
  "apparently",
  "approximately",
  "around",
  "as well",
  "at all",
  "at least",
  "at most",
  "at the latest",
  "backwards",
  "barely",
  "behind",
  "briefly",
  "by the way",
  "completely",
  "constantly",
  "crazily",
  "crudely",
  "definitely",
  "despite",
  "down",
  "each other",
  "elsewhere",
  "even",
  "ever",
  "every now and then",
  "exactly so",
  "exclusively",
  "finally",
  "first",
  "forever",
  "forth",
  "free",
  "frequently",
  "fully",
  "generally",
  "hardly",
  "here",
  "however",
  "immediately",
  "incidentally",
  "independent from one another",
  "initially",
  "insanely",
  "least",
  "likewise",
  "meanwhile",
  "mere",
  "more often",
  "most cordially",
  "mostly",
  "namely",
  "naturally",
  "nearby",
  "never",
  "nevertheless",
  "normally",
  "not at all",
  "now",
  "obviously",
  "occasionally",
  "of course",
  "off",
  "officially",
  "often",
  "on the way",
  "one hand",
  "only",
  "out",
  "outside",
  "partially",
  "particularly",
  "partly",
  "patiently",
  "personally",
  "polite",
  "politely",
  "proud",
  "public",
  "publicly",
  "quickly",
  "quite",
  "rather",
  "realistic",
  "realistically",
  "recently",
  "respectively",
  "same",
  "serious",
  "seriously",
  "similarly",
  "simultaneously",
  "since",
  "so far",
  "so much",
  "somehow",
  "sometime",
  "specifically",
  "still",
  "sweepingly",
  "terribly",
  "theoretically",
  "there",
  "thereby",
  "therefore",
  "through it",
  "together",
  "unfortunately",
  "until now",
  "unwillingly",
  "upwards",
  "utterly",
  "vainly",
  "wherein",
  "whereof",
  "whereupon",
  "why",
  "worldwide"];
const num_text = ["a hundred",
  "a thousand",
  "eight",
  "eighteen",
  "eighty",
  "eighty-five",
  "eighty-two",
  "eleven",
  "fifteen",
  "fifteen thousand",
  "fifty",
  "fifty-four",
  "first",
  "five",
  "five hundred",
  "forty",
  "forty-eight",
  "forty-seven",
  "forty-three",
  "four",
  "fourteen",
  "fourteenth",
  "nine",
  "nineteen",
  "ninety-nine",
  "one",
  "one hundred",
  "one hundred and thirty-three",
  "one hundred and three",
  "one hundred fifty",
  "one hundred thirty-seven",
  "one hundred thirty-three",
  "one hundred three",
  "one hundred twelve",
  "one thousand",
  "one thousand nine hundred ninety",
  "one thousand six hundred",
  "seven",
  "seven thousand",
  "seventeen",
  "seventy",
  "seventy-five",
  "six",
  "six hundred",
  "sixteen",
  "sixty",
  "sixty-five",
  "sixty-four",
  "ten",
  "tenth",
  "thirteen",
  "thirteen hundred seventy-nine",
  "thirty",
  "thirty-eight",
  "thirty-four",
  "thirty-one",
  "thirty-two",
  "thousand",
  "three",
  "three hundred",
  "three hundred and eighty thousand",
  "three hundred and seventy",
  "three hundred fifty",
  "three hundred seventy",
  "three thousand",
  "twelve",
  "twenty",
  "twenty fifth",
  "twenty-eight",
  "twenty-five",
  "twenty-four",
  "twenty-one",
  "twenty-seven",
  "twenty-six",
  "twenty-three",
  "twenty-two",
  "two",
  "two hundred",
  "two hundred seventy",
  "two thousand",
  "two thousand and eleven",
  "two thousand eleven",
  "two thousand nineteen",
  "two thousand sixteen",
  "two thousand thirteen",
];
const prep = ["about",
"above",
"against",
"among",
"at",
"before",
"by",
"for",
"from",
"in",
"in addition to",
"in the",
"near",
"of",
"off",
"on",
"out",
"over",
"since",
"through",
"to",
"toward",
"under",
"until",
"with",
"without"];
const int = ["OK",
"ah",
"bye",
"congratulations",
"eh!",
"good",
"great",
"ha",
"help",
"hi",
"hmmm",
"no",
"oh",
"please",
"shhh",
"tadaa",
"thank you",
"well",
"well done",
"wow",
"yeah",
"yoohoo",
"yuck",];
const pron = ["I",
"a lot",
"all",
"any",
"both",
"each other",
"every",
"everyone",
"everything",
"he",
"hers",
"him",
"himself",
"how",
"how much",
"it",
"lots",
"many",
"me",
"mine",
"myself",
"no one",
"nobody",
"none",
"nothing",
"one",
"one another",
"other",
"others",
"several",
"she",
"so many",
"some",
"someone",
"something",
"something like that",
"such",
"that",
"them",
"there",
"these",
"they",
"thing",
"this",
"those",
"us",
"we",
"what",
"when",
"where",
"which",
"who",
"whose",
"you",
"yourself"];
const cnj = ["as far as",
"and",
"that",
"that's why",
"instead of",
"but",
"as",
"like",
"when",
"if",
"since",
"because",
"after",
"except",
"during",
"until",
"however",
"or",
"to",
"how",
"therefore",
"than",
"as if",
"then",
"in order",
"for",
"around",
"at",
"by",
"as well",
"although",
"though",
"even though",
"the more",
"the",
"so",
"while",
"before",
"why",
"whether",
"besides",
"as well as",
"as soon as",
"so that",
"with it",
"hence",
"as a result",
"in case",
"provided that",
"either",
"through",
"which is why",
"whereas",
"instead",
"without",
"as long as"
];
const art = ["a", "the", "none", "most", "any", "few"];

const SourceList = {

  vrb: {
    "A1": vrb_A1,
    "A2": vrb_A1,
    "B1": vrb_B1,
    "B2": vrb_B1,
    "C1": vrb_B1
  },
  adj: {
    "A1": adj_A1,
    "A2": adj_A1,
    "B1": adj_B1,
    "B2": adj_B1,
    "C1": adj_B1
  },
  noun: {
    "A1": noun_A1,
    "A2": noun_A1,
    "B1": noun_B1,
    "B2": noun_B1,
    "C1": noun_B1
  },
  adv: {
    "A1": adv_A1,
    "A2": adv_A1,
    "B1": adv_B1,
    "B2": adv_B1,
    "C1": adv_B1
  },
  num: {
    "A1": num_text,
    "A2": num_text,
    "B1": num_text,
    "B2": num_text,
    "C1": num_text
  },
  prep: {
    "A1": prep,
    "A2": prep,
    "B1": prep,
    "B2": prep,
    "C1": prep
  },
  int: {
    "A1": int,
    "A2": int,
    "B1": int,
    "B2": int,
    "C1": int
  },
  pron: {
    "A1": pron,
    "A2": pron,
    "B1": pron,
    "B2": pron,
    "C1": pron
  },
  cnj: {
    "A1": cnj,
    "A2": cnj,
    "B1": cnj,
    "B2": cnj,
    "C1": cnj
  },
  art: {
    "A1": art,
    "A2": art,
    "B1": art,
    "B2": art,
    "C1": art
  },
};

export default SourceList;