"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = depixify;
var _is_present = _interopRequireDefault(require("./is_present"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function depixify(value) {
  if ((0, _is_present["default"])(value)) {
    value = String(value).includes('px') ? value.replace("px", "") : value;
    return parseInt(value, 10);
  }
}