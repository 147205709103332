import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { InvitationStore } from '@seedlang/stores';
import CheckIfTrue from 'components/check_if_true';
import Paginator from 'components/paginator';
import { Link } from 'react-router';

const Wrapper = styled.div`

`;

@observer
class InvitationIndex extends React.Component {

  constructor(props) {
    super(props);
    InvitationStore.getIndex();
  }

  render() {
    return (
      <Wrapper>
        <table className="table-wrapper admin-table">
          <thead>
            <tr>
              <th>Created</th>
              <th>Sender</th>
              <th>Invited</th>
              <th>Email</th>
              <th>Message</th>
              <th>Email Sent</th>
            </tr>
          </thead>
          <tbody>
            {
              InvitationStore.indexData.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className="text">
                        {item.createdAt.formatted}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {
                          item.user &&
                            <Link
                            to={{name: 'builder.users.edit', params: {userId: item.user.id}}}
                            >
                            {item.user.name}
                            </Link>
                        }
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {
                          item.invitedUser &&
                            <Link
                              to={{name: 'builder.users.edit', params: {userId: item.invitedUser.id}}}
                            >
                              {item.invitedUser.name}
                            </Link>
                        }
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.email}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.message}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        <CheckIfTrue
                          value={item.emailSent}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={InvitationStore}
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(InvitationIndex);
