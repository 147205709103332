import React from 'react';
import { observer } from 'mobx-react';
import { TriviaResultsCardWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { AppUI, DeckUI } from '@seedlang/state';
import { flexCenterColumn, flexCenter } from '@seedlang/style_mixins';
import Text from 'components/text';
import YouWon from 'components/svg/you_won';
import YouLost from 'components/svg/you_lost';
import YouTied from 'components/svg/you_tied';
import PlayerRibbon from 'components/trivia_card/player_ribbon';
import Confetti from 'react-confetti';
import { Theme } from '@seedlang/constants';
import CardButtons from 'components/card/card_buttons';
import CardButton from 'components/card/card_button';
import { RightIcon, TimesWithCircleIcon } from '@seedlang/icons';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import autobind from 'autobind-decorator';

const Title = styled.div`
  margin: 40px 0 20px 0;
  position: relative;
  z-index: 999;
  ${flexCenterColumn}
  color: white;
  svg {
    width: 243px;
  }
`;

const PlayersRow = styled.div`
  margin-top: 40px;
  ${flexCenter()}
`;

@observer
class TriviaResultsCard extends React.Component {

  constructor(props) {
    super(props);
    if (this.props.inView) {
      this.playAudio();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.inView && this.props.inView) {
      this.playAudio();
    }
  }

  @autobind playAudio() {
    if (DeckUI.hasUserDeck && (!this.props.hasOpponent || this.props.gameWon || this.props.gameTied)) {
      AppUI.soundEffectStore.play('wonGame');
    } else if (DeckUI.hasUserDeck) {
      AppUI.soundEffectStore.play('lostGame');
    }
  }

  render() {
    return (
      <CardWrapper>
        {
          (this.props.gameWon || !this.props.hasOpponent) &&
            <Confetti
              width={DeckUI.layout.deckWidth}
              height={DeckUI.layout.contentHeightWithoutButtons}
            />
        }
        <CardContent>
          <Title>
            { (this.props.gameWon || !this.props.hasOpponent) && <YouWon /> }
            { this.props.gameLost && <YouLost /> }
            { this.props.gameTied && <YouTied /> }
            <Text
              bold
              color="#FFF"
              margin="10px 0"
            >
              {this.props.resultsText}
            </Text>
          </Title>
          <PlayersRow>
            <PlayerRibbon
              animate={this.props.inView}
              won={!this.props.hasOpponent || this.props.gameWon || this.props.gameTied}
              points={DeckUI.userDeck.points}
              user={DeckUI.user}
            />
            {
              this.props.hasOpponent &&
                <PlayerRibbon
                  animate={this.props.inView}
                  won={this.props.gameLost || this.props.gameTied}
                  points={this.props.opponentPoints}
                  user={this.props.opponent}
                />
            }
          </PlayersRow>
        </CardContent>
        <CardButtons>
          <CardButton
            shortcut="1"
            marginRight={5}
            text="Finish Game"
            fontSize="14px"
            backgroundColor={Theme.darkestGreen}
            icon={
              <TimesWithCircleIcon />
            }
            disabled={!this.props.inView}
            onClick={() => DeckUI.skipToEnd()}
          />
          <CardButton
            shortcut="2"
            marginLeft={5}
            submitOnEnter
            text="Practice Vocab"
            fontSize="14px"
            backgroundColor={Theme.darkGreen}
            icon={<RightIcon />}
            disabled={!this.props.inView}
            onClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard)}
          />
        </CardButtons>
      </CardWrapper>
    );
  }
}

export default TriviaResultsCardWrapper(TriviaResultsCard);
