import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { TreeUI } from '@seedlang/state';
import { AppUI } from '@seedlang/state';
import { GraphOutlineIcon, FaqIcon } from '@seedlang/icons';
import HeaderBar from 'components/header_bar';
import PageOnboarding from 'components/onboarding/page_onboarding';
import { Theme } from '@seedlang/constants';
import { Link} from 'react-router';

const Wrapper = styled.div`
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.padding};
`;

const FreeGamesCount = styled.span`
  background: ${Theme.green};
  color: white;
  margin: 0 5px 0 0;
  padding: 3px 10px;
  border-radius: 5px;
`;

@observer
class Trivia extends React.Component {

  constructor(props) {
    super(props);
    if (!AppUI.userTreeNodeStore.hasIndexData) {
      AppUI.userTreeNodeStore.getIndex();
    }
    AppUI.createEvent('trivia - view page');
  }

  componentWillUnmount() {
    TreeUI.treeStore.clearShowData();
  }

  render() {
    return (
      <Wrapper>
        {
          !AppUI.user.isMember &&
            <PageOnboarding
              hideCloseIcon
              className='tree-onboarding'
              margin={AppUI.layout.isMobile ? "0" : "0 0 10px 0"}
              background={Theme.orange}
              color="#FFF"
              flat
              borderRadius={AppUI.layout.isMobile ? 0 : 5}
              fontSize={AppUI.layout.isMobile ? 13 : 15}
            >
              <FreeGamesCount>
                {TreeUI.user.freeGamesAvailableCount <= 0 ? 0 : TreeUI.user.freeGamesAvailableCount}
              </FreeGamesCount>
              Free Game{TreeUI.user.freeGamesAvailableCount === 1 ? null : 's'} Remaining.
              <Link
                to={{name: 'memberships.index'}}
                className='underline'
                style={{marginLeft: 5}}
              >
                Unlock all <b>Trivia Games</b>.
              </Link>
            </PageOnboarding>
        }
        <HeaderBar
          title="TRIVIA"
          titleRoute="trivia.home"
          pageInfo={[
            {
              route: 'trivia.progress',
              current: 'Progress',
            },
          ]}
          icons={[
            {
              icon: <FaqIcon />,
              tooltipText: "Help Center",
              iconRoute: "faq_categories.show",
              iconParams: {faqCategoryId: 'afaf027c-ba41-4a7f-bad7-50d605d4fc7d'},
            },
            {
              icon: <GraphOutlineIcon />,
              tooltipText: "View Progress",
              iconRoute: "trivia.progress",
              breadcrumb: "Progress",
            },
          ]}
        />
        <Content>
          {this.props.children}
        </Content>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Trivia);
