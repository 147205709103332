import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  padding: ${(props) => props.padding};
  font-size: ${(props) => props.fontSize};
  font-weight: bold;
  cursor: pointer;
  color: ${(props) => props.selected ? props.borderColor : "#A9A9A9"};
  border-bottom: ${(props) => props.selected ? `4px solid ${props.borderColor}` : null};
  margin: 0 5px 0 0;
  text-transform: uppercase;
  white-space: nowrap;
`;

@observer
class Tab extends React.Component {

  static defaultProps = {
    padding: "10px",
    margin: "0 2px 0 0",
    borderColor: Theme.blue,
  }

  render() {
    return (
      <Wrapper
        fontSize={AppUI.layout.isMobile ? "12px" : "14px"}
        selected={this.props.selected}
        padding={this.props.padding}
        margin={this.props.margin}
        onClick={this.props.onClick}
        borderColor={this.props.borderColor}
      >
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Tab);
