import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { StarIcon } from '@seedlang/icons';
import { times } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { isBlank } from '@seedlang/utils';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const StarWrapper = styled.div`
  padding: 2px;
  cursor: pointer;
  svg {
    width: ${(props) => props.width};
    fill: ${(props) => props.selected ? Theme.orange : "#CCC"};
  }
`;

@observer
class RatingInput extends React.Component {
  @observable hoveredRating;
  @observable selectedRating = this.props.defaultValue;

  static defaultProps = {
    starWidth: "28px",
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue || (prevProps.id !== this.props.id)) {
      this.selectedRating = this.props.defaultValue;
      this.hoveredRating = null;
    }
  }

  @computed get rating() {
    return this.selectedRating || this.hoveredRating;
  }

  onClick(rating) {
    this.selectedRating = rating;
    this.props.onClick(rating, this.props.slug);
  }

  onHover(rating) {
    if (isBlank(this.selectedRating)) {
      this.hoveredRating = rating;
    }
  }

  render() {
    return (
      <Wrapper
        onMouseOut={() => this.onHover(null)}
      >
        {
          times(this.props.maxRating, (num) => {
            return (
              <StarWrapper
                onMouseOver={() => this.onHover(num + 1)}
                width={this.props.starWidth}
                key={num}
                data-tip={!this.props.hideTooltip}
                data-for={`star-${num}`}
                selected={this.rating && (num + 1) <= this.rating}
              >
                <StarIcon
                  onClick={() => this.onClick(num + 1)}
                />
                {
                  !this.props.hideTooltip &&
                    <ReactTooltip
                      place="top"
                      type="dark"
                      effect="solid"
                      id={`star-${num}`}
                      class="custom-tooltip"
                    >
                      {['Poor', 'Not Good', 'Average', 'Good', 'Great'][num]}
                    </ReactTooltip>
                }
              </StarWrapper>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default RatingInput;
