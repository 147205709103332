import React from 'react';
import { observer } from 'mobx-react';
import Paginator from 'components/paginator';
import { Link } from 'react-router';
import UserProfileImage from 'components/user/user_profile_image';
import CheckIfTrue from 'components/check_if_true';
import moment from 'moment';

@observer
class SimpleUserIndex extends React.Component {

  componentWillUnmount() {
    this.props.store.clearIndexData();
  }

  render() {
    return (
      <div className="user-index user-index-page">
        <table className="table-wrapper">
          <thead>
            <tr>
              <th></th>
              <th>
                Active
              </th>
              <th>
                Created
              </th>
              <th>
                Email
              </th>
              <th>
                Name
              </th>
              <th>
                Img
              </th>
              <th>
                Level
              </th>
              <th>
                Grp
              </th>
              <th>
                Strk
              </th>
              <th>
                XP
              </th>
              <th>
                Goal
              </th>
              <th>
                Rvw
              </th>
              <th>
                Decks
              </th>
              <th>
                Last Deck
              </th>
            </tr>
          </thead>
          <tbody>
            {
              this.props.store.indexData.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: this.props.whiteLabel ? 'creator.groups.users.edit' : 'builder.users.edit', params: { userId: item.id, groupId: this.props.groupId } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>
                      <div className="text">
                        {item.lastActiveAt && moment(item.lastActiveAt).format("MM/DD, hh:mm")}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.createdAt && moment(item.createdAt).format("MM/DD, hh:mm")}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.email}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.name}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        <UserProfileImage
                          clickToProfile
                          width={60}
                          user={item}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.levelAbbreviation}
                      </div>
                    </td>
                    <td>
                      <CheckIfTrue
                        value={item.userGroupsCount > 0}
                      />
                    </td>
                    <td>
                      {item.currentStreakCount}
                    </td>
                    <td>
                      {item.xp}
                    </td>
                    <td>
                      {item.dailyXpGoal}
                    </td>
                    <td>
                      {item.pastDueReviewCardsCount}/{item.reviewCardsCount}
                    </td>
                    <td>
                      <div className="text">
                        {item.finishedUserDecksCount}/{item.userDecksCount}
                      </div>
                    </td>
                    <td>
                      {item.lastDeckInteractionAt ? item.lastDeckInteractionAt.formatted : ''}
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={this.props.store}
          ids={this.props.ids}
        />
      </div>
    );
  }
}

export default SimpleUserIndex;
