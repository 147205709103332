import React from 'react';
import CampaignEdit from 'pages/builder/campaigns/campaign_edit';

class SiteCampaignEdit extends React.Component {
  render() {
    return (
      <CampaignEdit
        {...this.props}
        whiteLabeled
      />
    );
  }
}

export default SiteCampaignEdit;
