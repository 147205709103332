import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { keys, isArray, isObject, sortBy } from 'lodash';
import FormLabel from 'components/form_factory/form_label';
import FormError from 'components/form_factory/form_error';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  select {
    padding: 10px;
    font-size: 14px;
    height: 40px!important;
  }
`;

@observer
class FormSelect extends React.Component {

  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    store: PropTypes.object.isRequired,
    options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    includeBlank: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
    alphabetizeOptions: PropTypes.bool,
  };

  static defaultProps = {
    options: [],
    includeBlank: true,
    disabled: false,
    defaultValue: '',
  }

  constructor(props) {
    super(props);
    this.props.form.registerComponent(this.props);
  }

  componentWillUnmount() {
    // this.props.form.unregisterComponent(this.props);
  }

  onChange() {
    this.props.onChange(this.props.name, this.refs[this.props.name].value);
  }

  getValue() {
    return this.props.form.getValue(this.props.name) || '';
  }

  getError() {
    return this.props.store.getError(this.props.name);
  }

  name() {
    if (this.props.name.indexOf('.') !== -1) {
      const ary = this.props.name.split('.');
      return `${ary[0]}[${ary[1]}]`;
    }
    return this.props.name;
  }

  orderedOptions() {
    if (this.props.alphabetizeOptions && this.props.options.length > 0 && isObject(this.props.options[0])) {
      return sortBy(this.props.options, ['name']);
    } else if (this.props.alphabetizeOptions && this.props.options.length > 0) {
      return this.props.options.sort();
    }
    return this.props.options;
  }

  orderedKeys() {
    const ary = keys(this.props.options);
    return this.props.alphabetizeOptions && isObject(this.props.options) ? ary.sort() : ary;
  }

  _options() {
    if (isArray(this.props.options)) {
      return this.orderedOptions().map((obj) => {
        let id, name;
        if (isArray(obj)) {
          id = obj[0];
          name = obj[1];
        } else {
          id = isObject(obj) ? obj.id : obj;
          name = isObject(obj) ? obj.name : obj;
        }
        return (
          <option
            key={id}
            value={id}
          >
            {name}
          </option>
        );
      });
    }
    return this.orderedKeys().map((k) => {
      return (
        <option
          key={k}
          value={k}
        >
          {this.props.options[k]}
        </option>
      );
    });
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        <FormLabel
          {...this.props}
        />
        <select
          key={`select-${this.props.name}`}
          onChange={this.onChange.bind(this)}
          ref={this.props.name}
          name={this.name()}
          value={this.getValue()}
          disabled={this.props.disabled}
        >
          {
            this.props.includeBlank &&
              <option
                value=""
                key="-99"
              />
          }
          { this._options() }
        </select>
        <FormError
          message={this.getError()}
        />
      </Wrapper>
    );
  }
}

export default FormSelect;
