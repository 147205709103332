import React from 'react';
import { observer } from 'mobx-react';
import { CardTypeStore } from '@seedlang/stores';

@observer
class CardTypeCreate extends React.Component {

  onClick() {
    CardTypeStore.create(
      {
        data: {
          name: this.refs.name.value,
        },
      },
    );
    this.refs.name.value = '';
  }

  render() {
    return (
      <div className="card-type-create">
        <input
          placeholder="Add a name"
          ref="name"
        />
        <div>
          <button
            className="button-primary"
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default CardTypeCreate;
