import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexCenter } from '@seedlang/style_mixins';
import { Link } from 'react-router';
import { AppUI } from '@seedlang/state';
import ReactTooltip from 'react-tooltip';
import HeaderBarItem from 'components/header_bar_item';

const Wrapper = styled.div`
  ${flexCenter()}
  margin: ${(props) => props.margin};
  background: white;
  border-radius: ${(props) => props.borderRadius};
  overflow: hidden;
  height: 40px;
  border-bottom: 1px solid #c5c5c5;
`;

const Title = styled.div`
  text-decoration: ${(props) => props.textDecoration};
  padding: ${(props) => props.padding};
  cursor: ${(props) => props.cursor};
  font-size: 14px;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
`;

const Center = styled.div`
  flex: 1;
`;

const IconSection = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  ${flexCenter()}
  width: 50px;
  height: 40px;
  border-left: 1px solid #CCC;
  cursor: pointer;
  background: ${(props) => props.selected ? "whitesmoke" : "#FFF"};
  &:hover {
    background: whitesmoke;
  }
  svg {
    width: 20px;
  }
`;

const BreadcrumbWrapper = styled.div`
  ${flexCenter()}
  text-transform: uppercase;
`;

const TitleWrapper = styled.div`
  padding-left: 5px;
  ${flexCenter()}
  i {
    font-size: 20px;
    color: #333;
  }
`;

@observer
class HeaderBar extends React.Component {

  @computed get pageInfo() {
    return this.props.pageInfo.find(item => item.route === AppUI.routeStore.routeName)
  }

  routeIsSelected(item) {
    const routes = (item.highlightRoutes || []).concat([item.iconRoute]);
    return routes.indexOf(AppUI.routeStore.routeName) !== -1;
  }

  render() {
    return (
      <Wrapper
        margin={AppUI.layout.isMobile ? "0" : "0 0 10px 0"}
        borderRadius={AppUI.layout.isMobile ? "0" : "5px"}
      >
        <TitleWrapper>
          <Link
            to={{
              name: this.props.titleRoute,
              params: this.props.titleParams,
            }}
          >
            <Title
              textDecoration={AppUI.routeStore.routeName && AppUI.routeStore.routeName !== this.props.titleRoute && AppUI.routeStore.routeName !== this.props.homeRoute ? "underline" : null}
              padding="0 5px"
            >
              {this.props.title}
            </Title>
          </Link>
          {
            this.pageInfo && this.pageInfo.breadcrumbs && this.pageInfo.breadcrumbs.map(item => {
              return (
                <HeaderBarItem
                  item={item}
                  lookupPage={this.props.lookupPage}
                />
              )
            })
          }
          {
            this.pageInfo && this.pageInfo.current &&
              <i className='fa fa-angle-right' />
          }
          {
            this.pageInfo && this.pageInfo.current &&
              <BreadcrumbWrapper>
                <Title
                  padding="0 5px"
                >
                  {this.pageInfo.current}
                </Title>
              </BreadcrumbWrapper>
          }
        </TitleWrapper>
        <Center />
        {
          this.props.icons &&
            <IconSection>
              {
                this.props.icons.filter(item => !item.hide).map(item => {
                  return (
                    <Link
                      key={item.iconRoute}
                      to={{
                        name: item.iconRoute,
                        params: item.iconParams
                      }}
                      data-tip
                      data-for={item.iconRoute}
                    >
                      <IconWrapper
                        selected={this.routeIsSelected(item)}
                      >
                        {item.icon}
                      </IconWrapper>
                      {
                        !AppUI.layout.isMobile &&
                          <ReactTooltip
                            type="dark"
                            effect="solid"
                            id={item.iconRoute}
                            class="custom-tooltip"
                          >
                            {item.tooltipText}
                          </ReactTooltip>
                      }
                    </Link>
                  )
                })
              }
            </IconSection>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(HeaderBar);
