import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import ListPicker from 'components/form/list_picker';
import InPlaceSelect from 'components/form/in_place_select';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

@observer
class VocabFilterSelectLanguage extends React.Component {

  render() {
    return (
      <Wrapper>
        <ListPicker
          includeBlank={this.props.includeBlank}
          value={this.props.value}
          onSetFilter={this.props.onSetFilter}
          userKey="vocabLanguagesWithWithout"
          options={this.props.options}
        />
        <InPlaceSelect
          allowUpdate
          options={AppUI.languageOptions}
          value={AppUI.user.languageId}
          onChange={val => this.props.onSetFilter('languageId', val)}
          margin="0 10px 0 0"
        />
      </Wrapper>
    );
  }
}

export default VocabFilterSelectLanguage;
