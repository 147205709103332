import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import LearnLayout from 'pages/main/learn_layout';
import VocabProgress from 'components/vocab/vocab_progress';
import { VocabWrapper } from '@seedlang/hoc';
import VocabDecks from 'components/vocab/vocab_decks';
import VocabList from 'components/vocab/vocab_list';
import Filter from 'components/filter';
import SearchInput from 'components/form/search_input';
import { AppUI } from '@seedlang/state';
import { isBlank } from '@seedlang/utils';
import { pixify } from "@seedlang/utils/src";

const Wrapper = styled.div`
  width: 100%;
`;

const InterfaceWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: ${props => props.maxWidth};
`;

const TopBar = styled.div`
  display: flex;
  margin-bottom: 10px;
  margin-top: ${props => props.marginTop};
`;

const TabButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const TabButton = styled.div`
  flex: 1;
  display: flex;
  margin-right: ${props => props.marginRight};
  margin-left: ${props => props.marginLeft};
  align-items: center;
  justify-content: center;
  height: 40px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: ${props => props.background};
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
`;

const TabContainer = styled.div`
  background: #FFF;
  padding-top: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const FilterAndLanguageDropdown = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  height: 50px;
  margin-top: -5px;
`;

@observer
class Vocab extends React.Component {

  componentDidMount() {
    window.location.href = "#top";
  }

  render() {
    return (
      <Wrapper>
        <LearnLayout>
          <InterfaceWrapper
            isMobile={this.props.isMobile}
            maxWidth={pixify(AppUI.layout.leftColumnWidth)}
          >
            <TopBar
              marginTop={AppUI.layout.isMobile ? "10px" : "0"}
            >
              <SearchInput
                placeholder="Search for a word"
                onChange={this.props.onUpdateSearchQuery}
                insertText={this.props.insertText}
                focusOnId={this.props.searchQuery}
                value={this.props.searchQuery}
                caretPosition={this.props.caretPosition}
                background="#FFF"
              />
            </TopBar>
            {
              isBlank(this.props.searchQuery) &&
                <FilterAndLanguageDropdown>
                  <Filter
                    filterName="Vocab Filters"
                    noFiltersSelectedMessage={this.props.noFiltersSelectedMessage}
                    filterSettings={this.props.filterSettings}
                    onSetFilter={this.props.onSetFilter}
                    searchQuery={this.props.searchQuery}
                    hasActiveFilter={this.props.hasActiveFilter}
                    onToggleFiltersExpanded={this.props.onToggleFiltersExpanded}
                    setShowFilterOptions={this.props.setShowFilterOptions}
                    showFilterOptions={this.props.showFilterOptions}
                    filteredItemsCount={this.props.filteredItemsCount}
                    filteredItemsType="Word"
                    width="100%"
                  />
                </FilterAndLanguageDropdown>

            }
            <TabButtons>
              <TabButton
                marginRight="5px"
                background={this.props.showTrainersTab ? "#FFF" : "#CCC"}
                onClick={() => this.props.onSetVocabTab('trainers')}
              >
                Vocab Trainers
              </TabButton>
              <TabButton
                marginLeft="5px"
                background={this.props.showTrainersTab ? "#CCC" : "#FFF"}
                onClick={() => this.props.onSetVocabTab('wordList')}
              >
                Dictionary
              </TabButton>
            </TabButtons>
            <TabContainer>
              {
                this.props.showTrainersTab &&
                  <VocabDecks
                    onSetFilter={this.props.onSetFilter}
                    filters={this.props.filters}
                    selectedWordIds={this.props.selectedWordIds}
                    filterSettings={this.props.filterSettings}
                    languageId={AppUI.targetLanguageId}
                    searchQuery={this.props.searchQuery}
                  />
              }
              {
                this.props.vocabTab === 'wordList' &&
                  <VocabList
                    {...this.props}
                  />
              }
            </TabContainer>
          </InterfaceWrapper>
          <VocabProgress
            {...this.props}
          />
        </LearnLayout>
      </Wrapper>
    );
  }
}

export default VocabWrapper(Vocab);
