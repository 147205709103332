import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import AuthenticateStatus from 'components/authenticate/authenticate_status';
import { AuthenticateStore } from '@seedlang/stores';
import { AppUI, TreeUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import { isBlank, isPresent, getCookieValue } from '@seedlang/utils';
import Alert from 'components/alert';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';
import Text from 'components/text';
import Spinner from 'components/spinner';
import styled from '@emotion/styled';
import InPlaceCheckbox from 'components/form/in_place_checkbox';

const Wrapper = styled.div`
  display: flex;
  height: 27px;
  align-items: center;
  margin: ${props => props.margin};
`;

const CheckboxWrapper = styled.div`
  border: 1px solid #bfbfbf;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  i {
    margin: 2px 0 0 0!important;
  }
`;

const ConfirmationText = styled.div`
  flex: 1;
`;

@observer
class EmailConfirmation extends React.Component {

  render() {
    return (
      <Wrapper
        margin={this.props.margin || '0 0 5px 0'}
      >
        <CheckboxWrapper>
          <InPlaceCheckbox
            icon="check"
            value={this.props.value === 'true'}
            onChange={this.props.onClick}
            disabledIconColor="transparent"
          />
        </CheckboxWrapper>
        <ConfirmationText>
          I consent to receiving occasional emails on the latest content and updates.
        </ConfirmationText>
      </Wrapper>
    );
  }
}

export default EmailConfirmation;
