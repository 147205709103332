import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import ConjugationCardMultipleChoice from 'components/conjugation_card/conjugation_card_multiple_choice';
import ConjugationCardTextEntry from 'components/conjugation_card/conjugation_card_text_entry';
import { AppUI } from '@seedlang/state';

@observer
class ConjugationCard extends React.Component {

  @computed get showTextEntryCard() {
    return this.props.card.cardType.slug !== 'conjugation-multiple-choice' && ((AppUI.user.textInputConjugationTrainer && this.props.card.cardType.slug === 'conjugation') || this.props.card.cardType.slug === 'conjugation-fill-in-blanks');
  }

  render () {
    if (AppUI.userIsLoaded) {
      if (this.showTextEntryCard) {
        return (
          <ConjugationCardTextEntry
            {...this.props}
          />
        )
      } else {
        return (
          <ConjugationCardMultipleChoice
            {...this.props}
          />
        )
      }
    }
    return <span />
  }
}

export default ErrorBoundary(ConjugationCard);
