import React from 'react';
import { observer } from 'mobx-react';
import { AnswerStore, SentenceStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import SentenceSearch from 'components/sentence_search';

@observer
class AnswerAssociationEdit extends React.Component {


  @autobind onSubmit(answer) {
    SentenceStore.update({ids: {sentenceId: answer.id}, data: {question_id: this.props.sentence.id}}, this.props.afterChange)
  }

  render() {
    return (
      <div className='answer-association-edit sentence-concept-edit'>
        <SentenceSearch
          placeholder="Search for Answer"
          store={AnswerStore}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

export default AnswerAssociationEdit;
