import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import { GraphOutlineIcon } from '@seedlang/icons';
import HeaderBar from 'components/header_bar';

const Wrapper = styled.div`
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.padding};
`;

@observer
class ClassroomShow extends React.Component {

  constructor(props) {
    super(props);
    this.loadGroup();
  }

  @autobind loadGroup() {
    AppUI.groupStore.getShow({ids: {groupId: this.props.params.groupId}});
    AppUI.userGroupStore.getIndex({ids: {groupId: this.props.params.groupId}});
  }

  render () {
    return (
      <Wrapper>
        <HeaderBar
          title={AppUI.groupStore.showData?.name?.toUpperCase()}
          titleRoute="classroom.show"
          titleParams={{groupId: this.props.params.groupId}}
          pageInfo={[
            {
              route: 'classroom.progress',
              params: {groupId: this.props.params.groupId},
              current: 'Progress',
            },
          ]}
          icons={[
            {
              icon: <GraphOutlineIcon />,
              tooltipText: "View Progress",
              iconRoute: "classroom.progress",
              iconParams: {groupId: this.props.params.groupId},
              breadcrumb: "Progress",
            },
          ]}
        />
        <Content>
          {this.props.children}
        </Content>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ClassroomShow);
