import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import PropTypes from 'prop-types';
import { Api, VideoClipStore } from '@seedlang/stores';
import pluralize from 'pluralize';
import autobind from 'autobind-decorator';
import { UrlBuilder } from '@seedlang/models';
import VideoPlayersWithType from 'pages/builder/video_clips/video_players_with_type';
import Spinner from 'components/spinner';
import DeleteButton from 'components/button/delete_button';

@observer
class InPlaceVideoUpload extends React.Component {
  @observable showSpinner = false;

  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    model: PropTypes.string.isRequired,
    querystring: PropTypes.object
  }

  @autobind onMultipartPost() {
    this.showSpinner = true;
    Api.multipartPost({
      url: new UrlBuilder().build(pluralize(this.props.model), this.props.querystring),
      data: {data: this.data()},
      successCallback: this.onChange.bind(this),
    });
  }


  @autobind onDeleteVideo() {
    if (this.props.removeModel) {
      let data = {};
      if (this.props.querystring['foreign_key']) {
        data[this.props.querystring['foreign_key']] = null;
      } else {
        data['video_clip_id'] = null;
      }
      Api.put({
        url: `/api/${pluralize(this.props.removeModel)}/${this.props.removeId}`,
        data: {data: data},
        successCallback: this.onChange.bind(this),
      });
    } else {
      VideoClipStore.destroy({ids: {videoClipId: this.props.videoClip.id}}, this.props.onChange)
    }
  }

  onChange() {
    this.showSpinner = false;
    this.props.onChange();
  }

  data() {
    const data = new FormData();
    data.append('blob', this.refs.input.files[0]);
    return data;
  }

  render() {
    return (
      <div className="in-place-image-upload">
        {
          !this.props.videoClip &&
            <input
              type="file"
              ref="input"
              onChange={this.onMultipartPost}
            />
        }
        { this.showSpinner && <Spinner className='blue' />}
        {
          this.props.videoClip &&
            <div
              className='video-wrapper'
              style={{
                position: 'relative',
                display: 'inline-block',
                marginTop: 10,
              }}
            >
              <div
                className='delete-button-wrapper'
                style={{
                  position: 'absolute',
                  top: -5,
                  right: 5,
                  zIndex: 3,
                }}
              >
                <DeleteButton
                  onConfirm={this.onDeleteVideo}
                  message="Delete this video?"
                >
                  <i className="fa fa-times fa-on-click delete-btn" />
                </DeleteButton>
              </div>
              <VideoPlayersWithType
                videoClip={this.props.videoClip}
              />
              {
                this.props.videoClip && this.props.videoClip.createdAt &&
                  <div style={{fontSize: 12}}>
                    {this.props.videoClip.createdAt.formattedDateWithYear}
                  </div>
              }
            </div>
        }
      </div>
    );
  }
}

export default InPlaceVideoUpload;
