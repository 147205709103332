import React from 'react';
import { observer } from 'mobx-react';
import { CardNoteWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import Text from 'components/text';
import { isBlank, isPresent } from '@seedlang/utils';
import CommentCreate from 'components/comment/comment_create';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import TextDisplay from 'components/text_display';
import DeleteButton from 'components/button/delete_button';
import CommentButton from 'components/button/comment_button';
import { CloseIcon, PencilIcon } from '@seedlang/icons';

const Wrapper = styled.div`
  padding: 0 20px;
  .pencil-icon {
    width: 10px;
    margin-right: 5px;
  }
`;

const CreateNoteWrapper = styled.div`
  background: rgb(241, 241, 241);
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 40px;
  position: relative;
  .close-icon {
    position: absolute;
    top: 10px;
    right: 5px;
    cursor: pointer;
    svg {
      fill: #737373;
      width: 20px;
    }
  }
`;

const MarkdownWrapper = styled.div`
  padding: 20px 20px 10px 20px;
  border-radius: 5px;
  background: rgb(241, 241, 241);
`;

const BottomBar = styled.div`
  display: flex;
  margin: 10px 0 0 0;
  position: relative;
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  .fa-times {
    margin-right: 5px;
  }
`;

const CheckboxWrapper = styled.div`
  margin-top: 10px;
`;

@observer
class CardNote extends React.Component {

  render () {
    return (
      <Wrapper>
        <Text
          heading="3"
          textAlign="center"
          padding="20px 0"
        >
          Review Note
        </Text>
        {
          !this.props.showEdit && isPresent(this.props.card.note) &&
            <MarkdownWrapper>
              <TextDisplay
                markdown={this.props.card.note}
              />
              <BottomBar>
                <Links>
                  <CommentButton
                    onClick={() => this.props.onSetShowEdit(true)}
                  >
                    <PencilIcon />
                    Edit
                  </CommentButton>
                  <DeleteButton
                    allowDeletion
                    onConfirm={() => this.props.onSubmitNote(null)}
                    message="Delete this note?"
                    confirmationMessageFloatsRight
                    right="auto"
                    left="10px"
                    top="-54px"
                  >
                    <CommentButton>
                      <i className='fa fa-times' />
                      Delete
                    </CommentButton>
                  </DeleteButton>
                </Links>
              </BottomBar>
            </MarkdownWrapper>
        }
        {
          (isBlank(this.props.card.note) || this.props.showEdit) &&
            <CreateNoteWrapper>
              {
                isPresent(this.props.card.note) &&
                  <CloseIcon
                    onClick={() => this.props.onSetShowEdit(false)}
                  />
              }
              <CommentCreate
                background="rgb(241, 241, 241)"
                width="100%"
                showTextExpansion
                text={this.props.card.note}
                placeholder="Leave a Private Note"
                onSubmitComment={this.props.onSubmitNote}
                languageId={this.props.card.languageId}
              />
            </CreateNoteWrapper>
        }
        <CheckboxWrapper>
          <InPlaceCheckbox
            allowUpdate
            onChange={this.props.onChangeShowNoteBeforeReveal}
            icon="check"
            value={this.props.card.showNoteBeforeReveal}
          >
            Show Note Icon Before Card is Revealed
          </InPlaceCheckbox>
        </CheckboxWrapper>
      </Wrapper>
    );
  }
}

export default CardNoteWrapper(CardNote);
