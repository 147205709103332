import React from 'react';
import LandingDefault from './landing_default';
import { RouteStore } from '@seedlang/stores';

class Landing extends React.Component {
  constructor(props) {
    super(props);
    RouteStore.setRouter(this.props.router);
  }

  render() {
    return (
      <LandingDefault />
    );
  }
}

export default Landing;
