import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexCenter, flexCenterColumn } from '@seedlang/style_mixins';
import TriviaRecordScore from 'components/trivia/trivia_record_score';

const Wrapper = styled.div`
  ${flexCenterColumn()}
`;

const Circle = styled.div`
  ${flexCenter()}
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 30px solid #CCC;
`;

@observer
class TriviaRecordNoGames extends React.Component {

  render() {
    return (
      <Wrapper>
        <Circle>
          <TriviaRecordScore
            won={0}
            lost={0}
          />
        </Circle>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TriviaRecordNoGames);
