import * as Sentry from '@sentry/react';

export default function sentryInitializer() {
  // need to disable uBlock Origin on localhost if testing error tracking
  Sentry.init({
    dsn: "https://019e707473e54ce39a35d78e8ed2cd6b@o85774.ingest.sentry.io/186663",
    ignoreErrors: ['AbortError', 'NotSupportedError', 'NotAllowedError'],
    beforeSend(event) {
      const localhost = window && window.location && window.location.href.match("localhost");
      if (localhost) {
        return null; // this is equal to shouldSend false
      } else {
        return event;
      }
    }
  });
}