import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { Theme } from '@seedlang/constants';
import { CheckWithCircleIcon, SeedlangIconWithCircle } from '@seedlang/icons';
import { AppUI } from '@seedlang/state';

const ITEMS = [
  {
   name: "Authentic videos",
   memrise: true,
  },
  {
   name: "Funny scenes and stories",
  },
  {
   name: "Speaking and listening focus",
  },
  {
   name: "Listen to recordings of yourself to improve pronunciation",
  },
  {
   name: "Structured grammar",
   duolingo: true,
  },
  {
   name: "Grammar descriptions embedded in every word",
  },
  {
   name: "Comprehensive vocab list to create your own flashcards",
  },
  {
   name: "Create your own reviews",
   memrise: true,
  },
  {
   name: "Competitive learning: play trivia against opponents",
  },
]

const Wrapper = styled.div`
  padding: 0 10px;
  .check-with-circle-icon svg {
    width: 27px;
  }
`;

const Table = styled.div`
  width: 100%;
  max-width: 700px;
  border-radius: 20px;
  overflow: hidden;
`;

const Row = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 40% 20% 20% 20%;
  grid-template-rows: min-content;
  grid-gap: 0;
  grid-template-areas: ". . . .";
  font-weight: ${(props) => props.header ? 'bold' : 300};
  line-height: 20px;
  background: white;
  @media only screen and (max-width : 499px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const Attribute = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  height: 100%;
  padding: 15px;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
`;

const Seedlang = styled.div`
  position: relative;
  ${flexCenterColumn()}
  background: ${Theme.blue};
  height: 100%;
  padding: 10px;
  border-left: 4px solid #2599b3;
  border-right: 4px solid #2599b3;
  color: white;
  svg {
    width: 32px;
  }
`;

const OtherSite = styled.div`
  ${flexCenterColumn()}
  height: 100%;
  padding: 10px;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  svg {
    fill: ${Theme.orange};
  }
`;

const Dash = styled.div`
  font-size: 40px;
  color: #DEDEDE;
  font-weight: bold;
`;

@observer
class SiteComparison extends React.Component {

  render() {
    return (
      <Wrapper>
        <Table>
          <Row
            header
            isMobile={AppUI.layout.isMobile}
          >
            <Attribute />
            <Seedlang>
              Seedlang
            </Seedlang>
            <OtherSite>
              Duolingo
            </OtherSite>
            <OtherSite>
              Memrise
            </OtherSite>
          </Row>
          {
            ITEMS.map(item => {
              return (
                <Row
                  key={item.name}
                >
                  <Attribute>
                    {item.name}
                  </Attribute>
                  <Seedlang>
                    <SeedlangIconWithCircle />
                  </Seedlang>
                  <OtherSite>
                    {item.duolingo ? <CheckWithCircleIcon /> : <Dash>-</Dash>}
                  </OtherSite>
                  <OtherSite>
                    {item.memrise ? <CheckWithCircleIcon /> : <Dash>-</Dash>}
                  </OtherSite>
                </Row>
              )
            })
          }
        </Table>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SiteComparison);
