import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import InPlaceTextInput from 'components/form/in_place_text_input';
import XpAnimation from 'components/xp_animation';
import autobind from 'autobind-decorator';
import { SendIcon } from '@seedlang/icons';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import Button from 'components/button/button';
import SpecialCharacterButtons from 'components/special_character_buttons';
import { CommentCreateWrapper } from '@seedlang/hoc';
import MarkdownLink from 'components/markdown_link';

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  background: ${(props) => props.background};
  text-align: left;
  .send-icon {
    svg {
      width: 14px;
      fill: #FFF;
      margin: 3px 4px 0 0;
    }
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
  i {
    margin: 2px 3px 0 0;
  }
  button {
    margin: 10px 10px 0 0;
  }
`;

const Row = styled.div`
  display: flex;
  @media only screen and (max-width : 649px) {
    flex-direction: column;
  }
`;

const Expand = styled.div`
  flex: 1;
  margin-right: 10px;
`;

@observer
class CommentCreate extends React.Component {
  @observable caretPosition;

  static defaultProps = {
    background: "#FFF",
    textInputButtonBackground: "#F1F1F1",
    placeholder: "Leave a Comment",
  }

  @autobind insertText(text) {
    if (this.props.disabled) { return null }
    const el = document.getElementById(this.props.id);
    if (el) {
      const caretPosition = el.selectionStart;
      const newValue = [this.props.commentText.slice(0, caretPosition), text, this.props.commentText.slice(caretPosition)].join('');
      this.props.onChangeComment(newValue, caretPosition + 1);
    }
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
        width={this.props.width}
        background={this.props.background}
      >
        {
          this.props.showXp &&
            <XpAnimation
              namespace={this.props.namespace}
            />
        }
        <InputWrapper>
          <InPlaceTextInput
            height="100px"
            expandedHeight={this.props.blockTextExpansion ? "100px" : "220px"}
            id={this.props.id}
            contractOnSubmit
            inputType='textarea'
            placeholder={this.props.placeholder}
            onChange={this.props.onChangeComment}
            onSubmit={this.props.onSubmit}
            value={this.props.commentText}
            caretPosition={this.props.caretPosition}
            focusOnId={this.props.commentText}
          />
        </InputWrapper>
        <Row>
          {
            !this.props.hideSpecialCharacters &&
              <Expand>
                <SpecialCharacterButtons
                  margin="10px 0 0 0"
                  languageId={this.props.languageId}
                  background={this.props.textInputButtonBackground}
                  onClick={this.insertText}
                />
              </Expand>
          }
          <MarkdownLink
            margin="5px 5px 0 0"
          />
        </Row>
        <Buttons>
          <Button
            onClick={this.props.onSubmit}
            disabled={this.props.disable}
            background={Theme.green}
          >
            <SendIcon />
            {this.props.edit ? 'Edit' : 'Submit'}
          </Button>
        </Buttons>
      </Wrapper>
    );
  }
}

export default CommentCreateWrapper(CommentCreate);
