import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Text from 'components/text';
import Button from 'components/button/button';
import { Link } from 'react-router';
import { AppUI } from '@seedlang/state';
import { flexCenterColumn } from '@seedlang/style_mixins';
import autobind from 'autobind-decorator';
import { AuthUserStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';
import ContactUsTextWithLink from 'components/contact_us_text_with_link';
import PatreonButton from "components/user/patreon_button";
import Modal from "components/modal";
import { Theme } from "@seedlang/constants/src";

const Wrapper = styled.div`
  ${flexCenterColumn()}
`;

const PatreonWrapper = styled.div`
  ${flexCenterColumn()}
  max-width: 500px;
  min-width: ${props => props.minWidth};
`;

const PatreonInfo = styled.div`

`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`;

const CenterWrapper = styled.div`
  width: 100%;
  ${flexCenterColumn()}
`;

const Warning = styled.div`
  background: #fdfdc1;
  padding: 10px;
  border: 1px solid #CCC;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 20px;
  a {
    text-decoration: underline;
  }
`;

const SmallText = styled.div`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin: 10px 0 0 0;
  color: #616161;
  width: 80%;
  a {
    color: #616161;
  }
`;

@observer
class PatreonConfirmation extends React.Component {

  @observable showConfirmationModal = false;

  @autobind onRemovePatreonUser() {
    AuthUserStore.update({data: {patreon_user_id: null}}, () => AppUI.loadUser());
    this.showConfirmationModal = false;
  }

  get introText() {
    if (AppUI.siteIsDefault) {
      return 'To gain access to Patron perks and worksheets, please link your account on Patreon by clicking here:'
    }
    else {
      return `If you have an ${AppUI.site.displayName} membership on Patreon, ${
        this.props.onboarding ? `sign up with your Patreon account` : `you can link your account here`
      } to get all the perks of your membership:`;
    }
  }

  render() {
    const textColor = this.props.onDarkBackground ? "#FFF" : "#333";
    return (
      <Wrapper>
        {
          this.showConfirmationModal &&
          <Modal
            onCloseModal={() => this.showConfirmationModal = false}
            title='Disconnect From Patreon'
            width={AppUI.layout.isMobile ? '90%' : '500px'}
            background={AppUI.site.accentColor}
            gradient={AppUI.siteIsDefault}
          >
            <Text center>Are you sure you want to disconnect your account from Patreon?</Text>
            {
              AppUI.user.activePatreonMember &&
                <Text center>Once disconnected, you will not be able to access your benefits.</Text>
            }
            {
              !AppUI.user.activePatreonMember && AppUI.user.paymentMigration.isComplete &&
              <Text center>You have completed the migration process. After disconnecting, you will still have access to your benefits and perks.</Text>
            }
              <Buttons>
                <Button
                  borderRadius="20px"
                  fontSize="16px"
                  height="40px"
                  width='60px'
                  padding="0 10px"
                  margin="2px 2px 0px 2px"
                  background={Theme.green}
                  onClick={this.onRemovePatreonUser}
                >
                  Yes
                </Button>
                <Button
                  borderRadius="20px"
                  fontSize="16px"
                  height="40px"
                  width='60px'
                  padding="0 10px"
                  margin="2px 2px 0px 2px"
                  background={Theme.red}
                  onClick={() => this.showConfirmationModal = false}
                >
                  No
                </Button>
              </Buttons>
          </Modal>
        }
        <PatreonInfo>
          {
            !AppUI.authUserStore.showData.patreonUser &&
              <PatreonWrapper
                minWidth={this.props.onboarding || AppUI.layout.isMobile ? '0' : '400px'}
              >
                <Text
                  center
                  margin="0 0 20px 0"
                  color={textColor}
                  style={{ textWrap: 'balance'}}
                >
                  {this.introText}
                </Text>
                <PatreonButton />
                {
                  !AppUI.siteIsDefault &&
                    <ContactUsTextWithLink onDarkBackground={this.props.onDarkBackground} />
                }
              </PatreonWrapper>
          }
          {
            AppUI.authUserStore.showData.patreonUser &&
              <PatreonWrapper style={{ minWidth: this.props.onboarding ? '0' : '400px' }}>
                <CenterWrapper>
                  <h3>Patreon Account Details</h3>
                </CenterWrapper>
                <CenterWrapper>
                  <div><b>Patreon Email</b></div>
                  <div>{AppUI.authUserStore.showData.patreonUser.email}</div><br />
                </CenterWrapper>
                {
                  isPresent(AppUI.authUserStore.showData.patreonUser.rewardTitle) &&
                    <CenterWrapper>
                      <div><b>Membership Type</b></div>
                      <div>{AppUI.authUserStore.showData.patreonUser.rewardTitle}</div><br />
                    </CenterWrapper>
                }
                {
                  AppUI.siteIsDefault && !AppUI.authUserStore.showData.patreonUser.isPatreonActive &&
                    <Warning>
                      <i className='fa fa-warning' style={{marginRight: 5}} />You do not have worksheet access. To gain access, please subscribe on the <a href="https://www.patreon.com/easygerman" target="_blank" rel="noopener noreferrer">Easy German Patreon Page</a>.
                    </Warning>
                }
                {
                  this.props.showRemoveLink &&
                    <CenterWrapper>
                      <Button
                        onClick={() => this.showConfirmationModal = true}
                        disabled={AppUI.user.paymentMigration?.isInProgress}
                      >
                        Disconnect From Patreon
                      </Button>
                    </CenterWrapper>
                }
                {
                  this.props.showRemoveLink && AppUI.user.paymentMigration?.isInProgress &&
                  <SmallText>You are currently migrating your payment from Patreon to our platform. You cannot disconnect your account as the process is happening. To see the status of your migration, <Link
                    to={{name: 'settings.billing'}}
                    className='underline'
                  >go here.</Link></SmallText>
                }
                {
                  AppUI.siteIsDefault && AppUI.authUserStore.showData.patreonUser.isPatreonActive && AppUI.user.contentGroupId &&
                    <CenterWrapper> 
                      <Link
                        to={{ name: 'groups.show', params: {groupId: AppUI.user.contentGroupId} }}
                      >
                        <Button
                          margin="10px 0 0 0"
                        >
                          View Worksheets
                        </Button>
                      </Link>
                    </CenterWrapper>
                }

                {
                  !AppUI.siteIsDefault && AppUI.authUserStore.showData.patreonUser.isPatreonActive &&
                    <CenterWrapper>
                      <Link
                        to={{ name: 'worksheets.index' }}
                      >
                        <Button
                          margin="10px 0 0 0"
                        >
                          View Content
                        </Button>
                      </Link>
                    </CenterWrapper>
                }
              </PatreonWrapper>
          }
        </PatreonInfo>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(PatreonConfirmation);
