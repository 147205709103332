"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = toCamelCase;
function toCamelCase(string) {
  return string.replace(/_([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
}