import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import MembershipOptions from 'components/membership/membership_options';
import { observer } from 'mobx-react';
import React from 'react';

const Wrapper = styled.div`
  @media (min-width: 700px) {
    margin: -10px 0 0 0;
  }
  `;

@observer
class MembershipChange extends React.Component {

  get mode() {
    return AppUI.routeStore.queryParam('mode');
  }

  render() {
    return (
      <Wrapper>
        {
          !AppUI.siteIsDefault &&
            <MembershipOptions mode={this.mode} />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(MembershipChange);
