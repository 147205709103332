import React from 'react';
import { DeckUI, OverlayUI } from '@seedlang/state';
import styled from '@emotion/styled';
import { Theme } from "@seedlang/constants";
import { pixify } from '@seedlang/utils';
import { PencilIcon } from "@seedlang/icons";

const PencilIconWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  top: ${props => props.top};
  right: 5px;
  width: ${props => props.width};
  height: ${props => props.width};
  background: #FFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  .pencil-icon {
    height: 16px;
    margin: -2px -1px 0 0;
    svg {
      width: 16px;
      fill: #333;
    }
`;

const Dot = styled.div`
  position: absolute;
  bottom: ${(props) => pixify(props.iconWidth * 0.75)};
  left: ${(props) => pixify(props.iconWidth * 0.75)};
  width: ${(props) => pixify(props.dotSize)};
  height: ${(props) => pixify(props.dotSize)};
  background: ${Theme.red};
  border: 1px solid white;
  border-radius: 50%;
  color: white;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

class TranslateSentenceIcon extends React.Component {
  static defaultProps = {
    top: "5px",
  }

  render() {
    return (
      <PencilIconWrapper
        width={DeckUI.layout.minimumHeight ? "30px" : "40px"}
        className='pencil-icon-wrapper'
        onClick={() => OverlayUI.addOverlay('sourceEdit', {card: this.props.card, sentence: this.props.card.sentence})}
        top={this.props.top}
      >
        <PencilIcon />
        {
          this.props.card.sentence.sourceTextForUserLanguageId(DeckUI.user) !== DeckUI.user.languageId &&
          <Dot
            iconWidth={DeckUI.layout.minimumHeight ? 30 : 40}
            dotSize={DeckUI.layout.minimumHeight ? 10 : 14}
          />
        }
      </PencilIconWrapper>
    );
  }
}

export default TranslateSentenceIcon;
