import React from 'react';
import { observer } from 'mobx-react';
import styled from "@emotion/styled";
import Modal from "components/modal";
import InPlaceText from "components/form/in_place_text";
import { isPresent, isBlank } from "@seedlang/utils/src";
import { AppUI } from "@seedlang/state";
import InfoTooltip from "components/info_tooltip";

@observer
class WordTagEdit extends React.Component {


  render() {
    if (isPresent(this.props.concept)) {
      return (
        <Modal
          title={this.props.concept?.name}
          gradient={isBlank(AppUI.site.accentColor)}
          background={AppUI.site.accentColor}
          onCloseModal={this.props.onClose}
        >
          <div className="row">
            <div className="col-xs-8">
              <fieldset>
                <legend>Name</legend>
                <InPlaceText
                  defaultValue={this.props.concept?.name}
                  model="concepts"
                  field="name"
                  id={this.props.concept?.id}
                  afterChange={this.props.onChange}
                />
              </fieldset>
            </div>
            <div className="col-xs-4">
              <fieldset>
                <legend>Abbreviation</legend>
                <InPlaceText
                  defaultValue={this.props.concept?.abbreviation}
                  model="concepts"
                  field="abbreviation"
                  id={this.props.concept?.id}
                  afterChange={this.props.onChange}
                />
              </fieldset>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <fieldset>
                <legend>Description <InfoTooltip>If a description exists, users can click on the tag and read it.</InfoTooltip></legend>
                <InPlaceText
                  richText
                  show
                  wrapperClassName="word-info"
                  defaultValue={this.props.concept.longDescription}
                  model="concepts"
                  field="long_description"
                  inputType="textarea"
                  id={this.props.concept.id}
                  afterChange={this.props.onChange}
                />
              </fieldset>
            </div>
          </div>
        </Modal>
      );
    }
  }
}

export default WordTagEdit;
