import React from 'react';
import { observer } from 'mobx-react';
import { computed, autorun, observable, toJS } from 'mobx';
import autobind from 'autobind-decorator';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { ForumUI, AppUI } from '@seedlang/state';
import UserProfileImage from 'components/user/user_profile_image';
import styled from '@emotion/styled';
import ForumCount from 'components/forum/forum_count';
import ForumRow from 'components/forum/forum_row';
import Paginator from 'components/paginator';
import { PinIcon, SeedlangIcon } from '@seedlang/icons';
import { Constants } from '@seedlang/constants';
import Filter from 'components/backend_filter/filter';
import Tag from 'components/tag';
import Spinner from 'components/spinner';
import { displayTargetTextCorrectedForLanguage } from '@seedlang/utils';
import { each, keys } from "lodash";

const Wrapper = styled.div`
  .seedlang-icon {
    border: 1px solid gray;
    border-radius: 50%;
    background: #FFF;
    svg {
      width: 50px;
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin: 0 10px 0 20px;
  font-size: 16px;
  .icon-wrapper {
    margin-right: 5px;
  }
  .icon-wrapper, .pin-icon {
    height: 14px;
    width: 14px;
  }
  svg {
    width: 14px;
    fill: #333;
  }
`;

const Date = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: #999;
  width: 180px;
`;

const TagWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

@observer
class ForumThreadIndex extends React.Component {
  @observable loadedIndex = false;

  constructor(props) {
    super(props);
    ForumUI.onReset();
    if (this.props.forumId && this.props.loadForum) {
      ForumUI.loadForum(this.props.forumId);
    }
    this.disposer = autorun(() => {
      if (!this.loadedIndex && ForumUI.forum && AppUI.userIsLoaded) {
        this.loadedIndex = true;
        ForumUI.forumThreadStore.setPage(this.props.params?.page || 1);
        if (!this.showFilters) {
          ForumUI.forumThreadStore.getIndex({filters: this.indexFilters});
        }
      }
    })
    AppUI.createEvent('discuss - view forum', {forum_id: this.props.params?.forumId});
  }

  componentWillUnmount() {
    ForumUI.forumThreadStore.clearIndexData();
    ForumUI.forumThreadStore.clearFilter();
    this.disposer && this.disposer();
  }

  @computed get forumId() {
    return this.props.forumId || this.props.params.forumId;
  }

  @computed get indexFilters() {
    let filters = {forum_id: this.forumId, hidden: false};
    if (this.props.defaultFilters) {
      each(keys(this.props.defaultFilters), key => {
        filters[key] = this.props.defaultFilters[key];
      });
    }
    return filters;
  }

  @computed get showFilters() {
    return (AppUI.user.admin || AppUI.user.moderator) && ForumUI.forum.showFilters;
  }

  @computed get filterComponents() {
    let ary = [];
    if (!this.props.groupId) {
      ary = [
        {
          type: 'multi_select',
          name: 'tag',
          label: 'Tag',
          options: [
            {id: '~Ling-DE~', name: 'Ling-DE'},
            {id: '~Ling-ES~', name: 'Ling-ES'},
            {id: '~Ling-FR~', name: 'Ling-FR'},
            {id: '~Cust-Sup~', name: 'Cust-Sup'},
            {id: '~Marie~', name: 'Marie'},
          ],
          labelField: 'name',
          default: ''
        },
      ];
    }
    if (ForumUI.forum.highlightUnseen) {
      ary.push(
        {
          type: 'boolean',
          name: 'seen_by_admin',
          label: 'Checked',
          default: ''
        }
      )
    }
    return ary;
  }

  @computed get vocabForumLanguageId() {
    return keys(Constants.VOCAB_FORUM_IDS).find(key => Constants.VOCAB_FORUM_IDS[key] === ForumUI.forumStore?.params?.ids?.forumId);
  }

  @autobind gotoForumThread(item) {
    let route ='forums.forum_threads.show';
    if (!AppUI.siteIsDefault) {
      route = 'creator.forum_threads.show';
    } else if (this.props.groupId) {
      route = 'classroom.forum_threads.show';
    }
    ForumUI.routeStore.routeToNamed(route, {forumThreadId: item.id, groupId: this.props.groupId, forumId: this.forumId})
  }

  render() {
    return (
      <Wrapper>

        {
          this.showFilters &&
            <Filter
              store={ForumUI.forumThreadStore}
              defaultFilters={this.indexFilters}
              defaultSort="-pinned,-last_commented_at"
              namespace="user-index"
              filters={this.filterComponents}
            />
        }
        {
          ForumUI.forumThreadStore.hasOutstandingRequests &&
            <Spinner />
        }
        {
          ForumUI.forumThreadStore.indexData.map((item) => {
            let background;
            if (ForumUI.forum.highlightUnseen && (AppUI.user.moderator || AppUI.user.admin) && item.tag) {
              background = "#f9f1dc";
            } else if (ForumUI.forum.highlightUnseen && (AppUI.user.moderator || AppUI.user.admin) && !item.seenByAdmin) {
              background = "#fdfae2";
            }
            return (
              <ForumRow
                key={item.id}
                background={background}
                onClick={() => this.gotoForumThread(item)}
              >
                {
                  item.tag && ForumUI.user.moderator &&
                    <TagWrapper>
                      <Tag
                        text={item.tag}
                      />
                    </TagWrapper>
                }
                {
                  !item.user && !this.props.hideLogo &&
                    <SeedlangIcon />
                }
                {
                  item.user &&
                    <UserProfileImage
                      user={item.user}
                      width={50}
                    />
                }
                <Title>
                  {
                    item.pinned &&
                     <PinIcon />
                  }
                  {displayTargetTextCorrectedForLanguage(item.titleOrTarget, this.vocabForumLanguageId || item.languageId)}
                </Title>
                <Date>
                  <div>
                    {item.lastCommentedAt.relativeTime}
                  </div>
                  {
                    !this.props.hideName &&
                      <i>
                        by {item.lastCommentName || 'Anonymous'}
                      </i>
                  }
                </Date>
                <ForumCount
                  count={AppUI.user.admin || AppUI.user.admin ? item.commentsCount : item.visibleCommentsCount}
                />
              </ForumRow>
            );
          })
        }
        {
          (ForumUI.forumThreadStore.hasIndexData || ForumUI.forumThreadStore.page > 1) &&
            <Paginator
              updateUrl
              store={ForumUI.forumThreadStore}
              ids={{forumId: this.forumId}}
              margin="20px 0"
            />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ForumThreadIndex);
