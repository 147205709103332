import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import pluralize from 'pluralize';
import { first, isArray, isString } from 'lodash';
import { Api } from '@seedlang/stores';
import cx from 'classnames';
import { isBlank, isPresent } from '@seedlang/utils';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`

`;

const Option = styled.div`
  display: inline-block;
  border: 1px solid gray;
  border-radius: 3px;
  margin: ${props => props.margin};
  font-size: ${props => props.fontSize};
  padding: ${props => props.padding};
  cursor: pointer;
  background: ${(props) => props.selected ? Theme.green : '#FFF'};
  color: ${(props) => props.selected ? "#FFF" : '#000'};
`;

const OptionsWrapper = styled.div`

`;

@observer
class InPlaceFlatSelect extends React.Component {
  @observable value = '';
  @observable editMode = !this.props.clickToEdit || isBlank(this.props.value);

  static propTypes = {
    clickToEdit: PropTypes.bool,
    optionName: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    model: PropTypes.string,
    field: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    onChange: PropTypes.func,
    afterChange: PropTypes.func,
    includeBlank: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    blurOnChange: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }

  static defaultProps = {
    clickToEdit: false,
    fontSize: "10px",
    margin: "1px",
    padding: "0 2px",
  }

  componentDidMount() {
    this.value = this.props.value;
    if (this.props.focus) {
      this.refs.select.focus();
    }
  }

  componentDidUpdate(prevProps) {
    this.value = this.props.value;
    if (isBlank(prevProps.value) && isPresent(this.props.value) && this.props.clickToEdit) {
      this.editMode = false;
    }
  }

  onClickToEdit() {
    if (!this.props.onlyCreate) {
      this.editMode = true;
    }
  }

  displayValue() {
    if (isPresent(this.value)) {
      if (isString(this.props.options[0])) {
        return this.value;
      } else if (isArray(this.props.options[0])) {
        return first(this.props.options.filter(item => item[0] === this.value))[1];
      }
      return first(this.props.options.filter(item => item[0] === this.value)).value;
    }
  }

  onChange(newValue) {
    if (!this.props.onChange && this.props.model) {
      const data = { data: {} };
      this.value = newValue;
      data.data[this.props.field] = newValue;
      Api.put({
        url: `/api/${pluralize(this.props.model)}/${this.props.id}`,
        data: data,
        successCallback: ((resp) => {
          if (this.props.afterChange) {
            this.props.afterChange();
          }
        }),
      });
    } else {
      this.props.onChange(newValue);
    }
  }

  render() {
    return (
      <Wrapper>
        {
          !this.editMode &&
            <div
              className={cx({
                'editable': !this.props.onlyCreate,
              })}
              onClick={() => this.onClickToEdit()}
            >
              {this.displayValue()}
            </div>
        }
        {
          this.editMode && this.props.options &&
            <OptionsWrapper>
              {
                 this.props.options.map((item, index) => {
                  return (
                    <Option
                      selected={item[0] === this.value}
                      onClick={() => this.onChange(item[0])}
                      key={item[0]}
                      fontSize={this.props.fontSize}
                      margin={this.props.margin}
                      padding={this.props.padding}
                    >
                      {item[1]}
                    </Option>
                  );
                })
              }
            </OptionsWrapper>
        }
      </Wrapper>
    );
  }
}

export default InPlaceFlatSelect;
