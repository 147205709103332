import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { AppUI, ForumUI } from '@seedlang/state';
import SpecialCharacterButtons from 'components/special_character_buttons';
import styled from '@emotion/styled';
import Text from 'components/text';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';
import InPlaceTextInput from 'components/form/in_place_text_input';
import Breadcrumbs from 'components/breadcrumbs';
import { SendIcon } from '@seedlang/icons';

const Wrapper = styled.div`
  .send-icon {
    svg {
      width: 14px;
      fill: #FFF;
      margin: 3px 4px 0 0;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  i {
    margin: 2px 3px 0 0;
  }
  button {
    margin: 10px 10px 10px 0;
  }
`;

@observer
class ForumThreadCreate extends React.Component {

  @computed get forumId() {
    let forumId;
    if (this.props.params?.groupId && AppUI.groupStore.hasShowData) {
      forumId = AppUI.groupStore.showData.forums[0].id;
    }
    if (this.props.params?.languageId && AppUI.user.languageForumId) {
      forumId = AppUI.user.languageForumId;
    }
    return this.props.forumId || this.props.params?.forumId || forumId || ForumUI.forumId;
  }

  @computed get editForumThread() {
    return this.props.forumThreadId;
  }

  constructor(props) {
    super(props);
    if (this.props.params && this.props.params.forumId) {
      ForumUI.forumThreadStore.clearShowData();
      ForumUI.loadForum(this.forumId)
    }
  }

  render() {
    return (
      <Wrapper>
        {
          !this.editForumThread && !this.props.params.groupId && !this.props.params?.languageId &&
            <Breadcrumbs
              height="50px"
              margin="0 0 10px 0"
              breadcrumbs={[
                {name: 'All Discussions', routeToName: 'forums.index'},
                {name: ForumUI.forumStore.showData.title, routeToName: "forums.show", routeToParams: {page: 1, forumId: this.forumId}},
                {name: "Create Discussion"},
              ]}
            />
        }
        {
          !this.props.blockTitleEdit &&
            <Text
              bold
              fontSize={this.editForumThread ? "16px" : "20px"}
              margin="0 0 10px 0"
            >
              Title
            </Text>
        }
        {
          !this.props.blockTitleEdit &&
            <InPlaceTextInput
              placeholder="Type a Title"
              caretPosition={ForumUI.caretPositionTitle}
              focusOnId={ForumUI.title}
              value={ForumUI.title}
              onChange={ForumUI.onChangeTitle}
              id="forum-title"
            />
        }
        {
          !this.props.blockTitleEdit &&
            <SpecialCharacterButtons
              margin="5px 0 0 0"
              background={this.editForumThread ? "#f1f1f1" : "#FFF"}
              options={ForumUI.forumStore.hasShowData ? ForumUI.forumStore.showData.language?.specialCharacterList : null}
              onClick={ForumUI.insertTitleText}
            />
        }
        <Text
          bold
          fontSize={this.editForumThread ? "16px" : "20px"}
          margin="10px 0"
        >
          Message
        </Text>
        <InPlaceTextInput
          height="100px"
          expandedHeight="200px"
          placeholder="Type a Message"
          inputType="textarea"
          caretPosition={ForumUI.caretPositionBody}
          focusOnId={ForumUI.body}
          value={ForumUI.body}
          onChange={ForumUI.onChangeBody}
          id="forum-body"
        />
        <SpecialCharacterButtons
          margin="5px 0 0 0"
          background={this.editForumThread ? "#f1f1f1" : "#FFF"}
          options={ForumUI.forumStore.hasShowData ? ForumUI.forumStore.showData.language?.specialCharacterList : null}
          onClick={ForumUI.insertBodyText}
        />
        <Buttons>
          <Button
            onClick={() => ForumUI.onCreateForumThread(this.forumId, this.props.params?.groupId, this.props.params?.languageId)}
            disabled={ForumUI.disabledForumThread}
            background={Theme.green}
          >
            <SendIcon />
            Submit
          </Button>
        </Buttons>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ForumThreadCreate);
