import React from 'react';
import { observer } from 'mobx-react';
import { CardReviewToggleWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import ReviewToggle from 'components/review/review_toggle';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { Theme } from '@seedlang/constants';
import ArrowBox from 'components/arrow_box';
import OnboardingPopup from 'components/onboarding/onboarding_popup';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  z-index: ${Theme.z["popup-background"]};
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 20px;
  width: 280px;
  pointer-events: ${props => props.pointerEvents};
`;

@observer
class CardReviewToggle extends React.Component {

  render () {
    return (
      <Wrapper
        pointerEvents={this.props.card.cardState.addToReviews ? 'auto' : 'none'}
      >
        {
          (this.props.card.word || this.props.card.sentence || this.props.card.exerciseCard) &&
            <ReviewToggle
              interfaceSource="card-review-toggle"
              disableToggleButton={this.props.disableToggleButton}
              animate
              showButton={this.props.card.cardState.addToReviews}
              card={this.props.card}
              hasReviews
              buttonText="Add to Reviews"
              word={this.props.card.word || this.props.card.exercise?.word}
              sentence={this.props.card.sentence || this.props.card.exercise?.sentence}
            />
        }
        {
          this.props.showOnboardingTip &&
            <ArrowBox
              closeIconFill="#FFF"
              show
              top="60px"
              arrowPosition="top"
              onboarding
              slug="reviews"
            >
              <OnboardingPopup
                title="Review Added!"
                message='This card has been added to the review deck on your home page.'
              />
            </ArrowBox>
        }
      </Wrapper>
    );
  }
}

export default CardReviewToggleWrapper(CardReviewToggle);
