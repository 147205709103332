import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { ExerciseUI } from '@seedlang/state';

const Wrapper = styled.div`
  background: white;
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  a {
    text-decoration: underline;
  }
`;

@observer
class GroupInfo2 extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          ExerciseUI.hasGroup &&
            <div
              dangerouslySetInnerHTML={{ __html: ExerciseUI.group.info2 }}
            />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(GroupInfo2);
