import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { TreeStore } from '@seedlang/stores';
import { TreeRowStore } from '@seedlang/stores';
import { TreeNodeStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import TreeNode from 'pages/builder/tree_nodes/tree_node';
import DeleteButton from 'components/button/delete_button';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { LevelStore } from '@seedlang/stores';
import Modal from 'components/modal';
import { Link } from 'react-router';
import { isPresent } from '@seedlang/utils';
import styled from '@emotion/styled';
import ConceptSearch from "components/concept_search";
import ReactTooltip from 'react-tooltip';

const Wrapper = styled.div`
  .builder-tree-node {
    background: white;
    margin: 5px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    font-size: 14px;
    position: relative;
    border: 1px solid #CACACA;
    width: ${props => props.grammarTree ? '300px' : '170px'};
    align-items: center;
    justify-content: center;
  }
  .empty {
    border: 1px solid transparent;
    justify-content: center;
    .fa {
      width: 15px;
      height: 15px;
      text-align: center;
      border-radius: 50%;
      border: 1px solid gray;
      font-size: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .tree-module-edit {
    position: relative;
    margin-top: 10px;
    .fields {
      margin-bottom: 10px;
    }
    .in-place-select {
      margin-top: 5px;
    }
    .modal {
      max-height: 600px;
      overflow: auto;
    }
    .tree-row-outer-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .first-of-level {
      width: 100%;
      text-align: center;
      background: #777;
      margin-bottom: 10px;
      border-radius: 5px;
      color: white;
      font-size: 14px;
    }
    .tree-row-wrapper {
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .tree-row {
        background: white;
        border: 1px solid gray;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 5px;
      }
      .add-row, .add-module {
        height: 15px;
        cursor: pointer;
        text-align: center;
        font-size: 9px;
        line-height: 16px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 15px;
        background: white;
        margin-left: 10px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid gray;
      }
      .level-wrapper {
        width: 50px;
      }
      .position {
        width: 40px;
        background: white;
        height: 100%;
        margin-right: 10px;
        text-align: center;
        font-size: 14px;
      }
      .tree-nodes {
        flex: 1;
        display: flex;
      }
    }
  }
`;

const PublishIcons = styled.div`
  display: flex;
`

@observer
class TreeModuleEdit extends React.Component {
  @observable showNote = false;

  @computed get treeModule() {
    if (TreeStore.hasShowData) {
      return TreeStore.showData.treeModules.find(item => item.id === this.props.params.treeModuleId);
    }
    return {};
  }

  @computed get hasTreeModule() {
    return isPresent(this.treeModule);
  }

  constructor(props) {
    super(props);
    LevelStore.getIndex();
    this.loadTree();
  }

  createRow(position) {
    TreeRowStore.create({data: {tree_module_id: this.props.params.treeModuleId, tree_id: TreeStore.showData.id, position: position}}, this.loadTree);
  }

  createNode(treeRowId, grammarConceptId) {
    TreeNodeStore.create({data: {tree_id: this.props.params.treeId, tree_row_id: treeRowId, tree_module_id: this.props.params.treeModuleId, grammar_concept_id: grammarConceptId}}, this.loadTree);
  }

  @autobind onShowNote(treeNodeId) {
    this.showNote = true;
    TreeNodeStore.getShow({ids: {treeNodeId: treeNodeId}});
  }

  @autobind loadTree(resp) {
    TreeStore.getShow({ids: {treeId: this.props.params.treeId}}, this.afterTreeGetShow);
  }

  @autobind afterTreeGetShow(resp) {
    // const module = resp?.treeModules?.find(item => item.id === this.props.params.treeModuleId);
    TreeStore.setShowData(resp);
  }

  @autobind onDeleteTreeRow(id, position) {
    TreeRowStore.destroy({ids: {treeRowId: id, position: position}}, this.loadTree);
  }

  @autobind onDeleteTreeNode(id) {
    TreeNodeStore.destroy({ids: {treeNodeId: id}}, this.loadTree);
  }

  republish() {
    TreeStore.update({data: {published: true}, ids: {treeId: this.props.params.treeId}});
  }

  @autobind onCreateGrammarTreeNode(conceptId, treeRowId, position) {
    this.createNode(treeRowId, conceptId);
    if (position === this.treeModule.treeRows.length) {
      this.createRow(position+1);
    }
  }

  render() {
    return (
      <Wrapper
        grammarTree={TreeStore.showData.grammar}
      >
        <div className='tree-module-edit'>
          {
            this.showNote &&
              <Modal
                onCloseModal={() => this.showNote = false}
              >
                <fieldset>
                  <legend>Note</legend>
                  <InPlaceText
                    richText
                    defaultValue={TreeNodeStore.showData.note}
                    model="tree_node"
                    inputType="textarea"
                    field="note"
                    id={TreeNodeStore.showData.id}
                  />
                </fieldset>
              </Modal>
          }
          <div className='breadcrumbs-wrapper'>
            <div className='breadcrumbs'>
              <Link
                to={{name: 'builder.trees.index'}}
              >
                Trees
              </Link>
              <i className='fa fa-angle-double-right' />
              <div className='current'>
                {
                  TreeStore.hasShowData &&
                    <Link
                      to={{name: 'builder.trees.edit', params: {treeId: this.props.params.treeId}}}
                    >
                      {TreeStore.showData.name}
                    </Link>
                }
              </div>
              <i className='fa fa-angle-double-right' />
              <div className='current'>
                {this.hasTreeModule && this.treeModule.name}
              </div>
            </div>
          </div>
          {
            this.hasTreeModule &&
              <div className='fields'>
                <div className="row">
                  <div className="col-xs-3">
                    <fieldset>
                      <legend>Module Name</legend>
                      <InPlaceText
                        defaultValue={this.treeModule.name}
                        model="tree_modules"
                        field="name"
                        id={this.treeModule.id}
                        afterChange={this.loadTree}
                      />
                    </fieldset>
                  </div>
                  <div className='col-xs-3'>
                    <fieldset>
                      <legend>Level</legend>
                      <InPlaceSelect
                        clickToEdit
                        includeBlank
                        id={this.treeModule.id}
                        value={this.treeModule.level && this.treeModule.level.id}
                        field="level_id"
                        model="tree_modules"
                        options={LevelStore.indexData.map(item => [item.id, item.nameWithAbbreviation])}
                        afterChange={this.loadTree}
                      />
                    </fieldset>
                  </div>
                  <div className='col-xs-2'>
                    <fieldset>
                      <legend>Web Published</legend>
                      <InPlaceCheckbox
                        id={this.treeModule.id}
                        value={this.treeModule.webPublished}
                        field="web_published"
                        model="tree_modules"
                        icon="check"
                      />
                    </fieldset>
                  </div>
                  <div className='col-xs-2'>
                    <fieldset>
                      <legend>Mobile Published</legend>
                      <InPlaceCheckbox
                        id={this.treeModule.id}
                        value={this.treeModule.mobilePublished}
                        field="mobile_published"
                        model="tree_modules"
                        icon="check"
                      />
                    </fieldset>
                  </div>
                </div>
              </div>
          }
          {
            isPresent(this.treeModule) && this.treeModule.treeRows.map(treeRow => {
              return (
                <div
                  className='tree-row-outer-wrapper'
                  key={treeRow.id}
                >
                  <div
                    className='tree-row-wrapper'
                  >
                    <div className='tree-row'>
                      <div className='position'>
                        <InPlaceText
                          id={treeRow.id}
                          defaultValue={treeRow.position}
                          field="position"
                          model="tree_rows"
                          type="number"
                          afterChange={this.loadTree}
                        />
                      </div>
                      <div className='tree-nodes'>
                        {
                          !TreeStore.showData.grammar && treeRow.treeNodes.map(treeNode => {
                            return (
                              <TreeNode
                                onShowNote={this.onShowNote}
                                key={treeNode.id}
                                treeId={this.props.params.treeId}
                                treeModuleId={this.props.params.treeModuleId}
                                treeRowId={treeRow.id}
                                treeNode={treeNode}
                                onDeleteTreeNode={this.onDeleteTreeNode}
                                loadTree={this.loadTree}
                                inGrammarTree={TreeStore.showData.grammar}
                              />
                            )
                          })
                        }
                        {
                          TreeStore.showData.grammar && treeRow.treeNodes.map(treeNode => {
                            return (
                              <Link
                                key={treeNode.id}
                                style={{fontWeight: 'bold'}}
                                to={{name: 'builder.tree_nodes.edit', params: {treeModuleId: this.props.params.treeModuleId, treeRowId: treeRow.id, treeId: this.props.params.treeId, treeNodeId: treeNode.id}}}
                              >
                                {`${treeNode.name} (${treeNode.treeNodeDecksCount} decks)`}
                              </Link>
                            )
                          })
                        }
                        {
                          !TreeStore.showData.grammar && (treeRow.treeNodesCount <= 2) &&
                            <div className='builder-tree-node'>
                              <div className='empty'>
                                <i
                                  className='fa fa-plus fa-on-click'
                                  onClick={() => this.createNode(treeRow.id)}
                                />
                              </div>
                            </div>
                        }
                        {
                          TreeStore.showData.grammar && treeRow.treeNodesCount === 0 &&
                            <ConceptSearch
                              onSubmit={(concept) => this.onCreateGrammarTreeNode(concept.id, treeRow.id, treeRow.position)}
                              languageId={TreeStore.showData.languageId}
                              displayField={"nameAndLevelAndSentencesAndDecksCount"}
                            />
                        }
                      </div>

                      {
                        TreeStore.showData.grammar && treeRow.treeNodes.map(treeNode => {
                          return (
                            <PublishIcons>
                              <div
                                data-for={`web-published-${treeNode.id}`}
                                data-tip
                                style={{margin: "0 5px"}}
                              >
                                <InPlaceCheckbox
                                  id={treeNode.id}
                                  value={treeNode.webPublished}
                                  model="tree_nodes"
                                  field="web_published"
                                  icon="laptop"
                                />
                                <ReactTooltip
                                  place="top"
                                  type="dark"
                                  effect="solid"
                                  id={`web-published-${treeNode.id}`}
                                  class="custom-tooltip"
                                >
                                  Web published
                                </ReactTooltip>
                              </div>
                              <div
                                data-for={`mobile-published-${treeNode.id}`}
                                data-tip
                                style={{margin: "0 20px 0 5px"}}
                              >
                                <InPlaceCheckbox
                                  id={treeNode.id}
                                  value={treeNode.mobilePublished}
                                  model="tree_nodes"
                                  field="mobile_published"
                                  icon="mobile"
                                />
                                <ReactTooltip
                                  place="top"
                                  type="dark"
                                  effect="solid"
                                  id={`mobile-published-${treeNode.id}`}
                                  class="custom-tooltip"
                                >
                                  Mobile published
                                </ReactTooltip>
                              </div>
                            </PublishIcons>

                          )
                        })
                      }
                      <DeleteButton
                        onConfirm={() => this.onDeleteTreeRow(treeRow.id)}
                        className="fa-block right"
                        message="Delete this row?"
                      />
                    </div>
                      <div
                        className='add-row'
                        onClick={() => this.createRow(treeRow.position + 1)}
                      >
                        <i className='fa fa-plus'/>
                      </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </Wrapper>
    );
  }
}

export default TreeModuleEdit;
