import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import Text from 'components/text';
import ListPicker from 'components/form/list_picker';
import FilterSelectLanguage from 'components/filter_select_language';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  border-bottom-width: 1px;
  padding-bottom: 20px;
  padding-left: 10px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FilterName = styled.div`
  width: 100%;
  border: ${props => (props.border ? '1px solid #DDD' : null)};
  borderBottomWidth: 0;
  borderTopWidth: 0;
  borderLeftWidth: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: 10px;
`;

@observer
class FilterOptionsItem extends React.Component {

  render () {
    return (
      <Wrapper>
        <FilterName
          border={this.props.border}
        >
          <TextWrapper>
            <Text
              bold
              color="#333"
            >
              {this.props.label}
            </Text>
            {
              this.props.description &&
                <Text
                  fontSize={12}
                  lineHeight={15}
                  margin="3px 0 0 0"
                  color="#A7A7A7"
                >
                  {this.props.description}
                </Text>
            }
          </TextWrapper>
        </FilterName>
        {
          this.props.filterType === 'select' &&
            <ListPicker
              {...this.props}
            />
        }
        {
          this.props.filterType === 'selectLanguage' &&
            <FilterSelectLanguage
              {...this.props}
            />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(FilterOptionsItem);
