"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = setInputCaret;
function setInputCaret(el, position) {
  if (el.setSelectionRange) {
    el.focus();
    el.setSelectionRange(position, position);
  } else if (el.createTextRange) {
    var range = el.createTextRange();
    range.collapse(true);
    range.moveEnd('character', position);
    range.moveStart('character', position);
    range.select();
  }
}