import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';
import { Theme } from '@seedlang/constants';
import InPlaceSelect from 'components/form/in_place_select';
import Button from 'components/button/button';
import { isBlank } from '@seedlang/utils';
import Alert from 'components/alert';
import InPlaceCheckbox from "../form/in_place_checkbox";

const Wrapper = styled.div`

`;

const Notice = styled.div`
  display: flex;
  align-items; center;
  font-size: 12px;
  padding: 10px 0 0 0;
  font-style: italic;
  color: #585858;
  text-align: left;
  line-height: 16px;
  i {
    margin: 1px 5px 0 0;
  }
`;

const FormWrapper = styled.div`
  select {
    height: 35px;
    margin: 0 5px 0 0;
    font-size: 14px;
  }
`;

const FormRow = styled.div`
  display: flex;
  margin: ${(props) => props.margin};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
`;

const InputWrapper = styled.div`
  flex: 1;
  padding-right: 10px;
`;

const Message = styled.div`
  border: 4px solid ${Theme.blue};
  border-radius: 5px;
  padding: 10px;
`;

@observer
class SourceSubmission extends React.Component {
  @observable value = '';
  @observable languageInput;
  @observable translationMessageClosed = false;
  @observable displayGender = false;

  @computed get disabled() {
    return isBlank(this.value);
  }

  @computed get hasDelimiter() {
    return this.value.match(/[;,\/|]/); // see translation_source/create_service.rb
  }

  @computed get hasGenderHint() {
    const genderHints = [
      "female",
      "male",
      "kvindelig",
      "女性",
      "mandlig",
      "M",
      "男性",
      "erkek",
      "kadın",
      "კაცი",
      "f.",
      "m.",
      "kvinnlig",
      "manlig",
      "ქალი",
      "男",
      "F",
      "女",
      "man",
      "fem",
      "vrouw",
      "femmina",
      "maschio",
      "남성",
      "féminin",
      "feminina",
      "nő",
      "feminino",
      "жін.",
      "mujer",
      "masculin",
    ];
    const genderHintMatcher = new RegExp(`\\s+\\((${genderHints.join("|")})\\)`, "i");
    return this.value.match(genderHintMatcher);
  }

  @computed get showEnterTranslation() {
    return AppUI.user.translationSourcesCount > 0 || this.translationMessageClosed;
  }

  @computed get languageId()  {
    return this.languageInput || AppUI.user.languageId;
  }

  @computed get english() {
    return !this.languageId || this.languageId === 'EN';
  }

  @autobind onSubmit() {
    if (!this.disabled) {
      const rejected = this.english ? true : !AppUI.user.translator;
      AppUI.translationSourceStore.create({data: {
        text: this.value,
        language_id: this.languageId,
        user_id: AppUI.user.id,
        translation_id: this.props.word.id,
        display_gender: this.displayGender,
        rejected: rejected,
      }}, this.afterUpdate);
    }
  }

  @autobind afterUpdate() {
    if (this.props.afterUpdate) {
      this.props.afterUpdate();
    }
    this.value = '';
    this.displayGender = false;
  }

  @autobind onKeyUp(e) {
    if (e.keyCode === 13) {
      this.onSubmit();
    }
  }

  render() {
    return (
      <Wrapper>
        {
          !this.showEnterTranslation &&
            <div>
              <Message>
                <div>
                  Submitting a translation is a way of updating incomplete translations, adding a translation in your native language, or even leaving yourself a note.
                </div>
                <Button
                  margin="10px 0 0 0"
                  onClick={() => this.translationMessageClosed = true}
                >
                  Continue
                </Button>
              </Message>
            </div>
        }
        {
          this.showEnterTranslation &&
            <div>
              <FormWrapper>
                <FormRow>
                  <InPlaceSelect
                    allowUpdate
                    options={AppUI.languageOptions}
                    value={this.languageId}
                    onChange={(language) => this.languageInput = language}
                    margin="0 10px 0 0"
                  />
                  <InputWrapper>
                    <input
                      placeholder="Enter Translation"
                      maxLength="40"
                      value={this.value}
                      onKeyUp={this.onKeyUp}
                      onChange={(el) => this.value = el.currentTarget.value}
                    />
                  </InputWrapper>
                </FormRow>
                <FormRow
                  margin="10px 0 0 0"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Button
                    onClick={this.onSubmit}
                    disabled={this.disabled}
                    height="35px"
                  >
                    Submit
                  </Button>
                  {
                    (this.props.word.isNoun || this.props.word.isPronoun) && this.props.word.genderAbbreviation &&
                      <div>
                        <InPlaceCheckbox
                            allowUpdate
                            wrapperMargin='0 0 0 10px'
                            value={this.displayGender}
                            icon="check"
                            onChange={(el) => {
                              this.displayGender = el
                            }}
                        >
                          {`Add gender abbreviation${this.props.word.genderAbbreviation}`}
                        </InPlaceCheckbox>
                        <span
                          data-for="add-gender"
                          data-tip
                        >
                          <a
                              href={"https://seedlang.com/discussions/4e559933-04b1-4652-8629-405c5dd3c855/threads/ab474948-c17d-4fa5-a3b8-ada316e47935"}
                              target="seedlang"
                              className="no-underline no-hover"
                          >
                            <i
                                className='fa fa-info-circle'
                                style={{color: "#e4e4e4"}}
                            />
                          </a>
                        </span>
                      </div>
                  }
                </FormRow>
              </FormWrapper>
              {
                this.hasDelimiter &&
                  <Alert
                    margin="10px 0 0 0"
                  >
                    Please enter only one translation at a time. Entries will be separated into several if they contain certain punctuation signs (eg. ;,/)
                  </Alert>
              }
              {
                this.hasGenderHint && this.props.word.isNoun &&
                <Alert
                  margin="10px 0 0 0"
                >
                  Please select "Add gender abbreviation" if you want the gender to appear in your translation.
                </Alert>
              }
              {
                this.english &&
                  <Notice>
                    <i className='fa fa-info-circle' /> English translations will only be visible by you until it is confirmed by Seedlang staff.
                  </Notice>
              }
            </div>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SourceSubmission);
