import React from 'react';
import { observer } from 'mobx-react';
import { WordCardWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { Constants, Theme } from '@seedlang/constants';
import ConjugationTable from 'components/conjugation_table';
import {
  ArrowIcon,
  BrokenCircleIcon,
  CaseIcon,
  CloseIcon,
  CloseIconRedX,
  LanguageIcon,
  RegularVerbIcon,
  TableIcon
} from '@seedlang/icons';
import WordTypeDescription from 'components/word_card/word_type_description';
import InfoTooltip from 'components/info_tooltip';
import WordCardRow from 'components/word_card/word_card_row';
import WordCardTitle from 'components/word_card/word_card_title';
import WordCardRowExpanded from 'components/word_card/word_card_row_expanded';
import { flexCenter, flexCenterColumn } from '@seedlang/style_mixins';
import { depixify, pixify } from '@seedlang/utils';
import AddInput from 'components/word_card/add_input';
import VideoPlayerWithSupplement from 'components/media/video_player_with_supplement';
import Text from 'components/text';
import ReviewToggle from 'components/review/review_toggle';
import SentenceBreakdown from 'components/sentence_breakdown';
import TextResizer from 'components/text_resizer';
import { DeckUI } from '@seedlang/state';
import key from 'keymaster';
import LearnedButton from "components/review/learned_button";

const ICONS = {
  regularVerb: <RegularVerbIcon />,
  irregularVerb: <BrokenCircleIcon />,
  dativeCase: <CaseIcon case='dative' />,
  accusativeCase: <CaseIcon case='accusative' />,
  nominativeCase: <CaseIcon case='nominative' />,
  genitiveCase: <CaseIcon case='genitive' />,
}

const Wrapper = styled.div`
  height: ${(props) => props.height};
  position: ${(props) => props.position};
  background: #FFF;
  left: 0;
  top: 0;
  z-index: ${Theme.z["card-info-4"]};
  width: 100%;
  overflow: hidden;
`;

const ScrollContainer = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  overflow: auto;
  padding-bottom: 80px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const CloseIconWrapper = styled.div`
  z-index: ${Theme.z["modal-background"]};
  position: absolute;
  width: 70px;
  height: 70px;
  top: 0;
  right: 10px;
  .icon-wrapper {
    position: absolute;
    top: 12px;
    right: 0
  }
  svg {
    width: 20px;
    fill: #000;
  }
`;

const WordCardRows = styled.div`
  width: 100%;
  .broken-circle-icon { svg { width: 10px; } }
  .arrow-icon { svg { width: 8px; } }
  .info-icon { svg { width: 2.5px; } }
  .table-icon { svg { width: 20px; } }
  .alert-icon { svg { width: 20px; } }
  .infinitive {
    .icon-wrapper {
      transform: rotate(180deg);
      display: flex;
      justify-content: flex-end;
    }
  }
  .info-icon, .arrow-icon, .broken-circle-icon {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: 1px solid black;
  }
  .icon-wrapper {
    width: 20px;
    svg {
      fill: #000;
    }
  }
`;

const NoReview = styled.div`
  font-size: 12px;
  font-style: italic;
  color: #565656;
  line-height: 15px;
  border: #565656 solid 1px;
  padding: 10px 20px;
  border-radius: 5px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  max-width: 400px;
`;

const Message = styled.div`
  ${flexCenter()}
  font-size: 14px;
  font-style: italic;
  margin-bottom: 10px;
  i {
    margin-right: 5px;
  }
`;

const VideoPlayerWrapper = styled.div`
  margin-bottom: 10px;
`;

const SentenceWrapper = styled.div`
  ${flexCenterColumn()}
  height: 140px;
  background: #E6E6E6;
  border-top: 2px solid #CCC;
`;

const SpanLink = styled.span`
  margin-left: 4px;
  cursor: pointer;
  text-decoration: underline;
  font-style: italic;
`;

const QuestionWrapper = styled.div`
  display: flex;
`;

const ReviewButtonWrapper = styled.div`
`;

@observer
class WordCard extends React.Component {

  componentDidMount() {
    key('escape', this.props.onCloseCard);
  }

  componentWillUnmount() {
    key.unbind('escape');
    if (this.props.sentence && this.props.sentence.state) {
      this.props.sentence.state.set('selectedWordAssociationId', null);
    }
  }

  render() {
    return (
      <Wrapper
        position={this.props.sentence && !this.props.backend ? 'absolute' : null}
        height={pixify(this.props.height)}
        borderRadius={this.props.isMobile ? 0 : "20px"}
      >
        <ScrollContainer
          className='scroll-container'
          width={pixify(this.props.width)}
          height={pixify(this.props.height - 140)}
        >
          <Content
            id="word-card"
          >
            {
              this.props.sentence && !this.props.backend &&
                <CloseIconWrapper
                  onClick={this.props.onCloseCard}
                >
                  <CloseIcon />
                </CloseIconWrapper>
            }
            {
              this.props.selectedVideoClip &&
                <CloseIconWrapper
                  onClick={this.props.clearVideoClips}
                >
                  <CloseIconRedX />
                </CloseIconWrapper>
            }
            <VideoPlayerWrapper>
              <VideoPlayerWithSupplement
                width={Math.floor(depixify(this.props.width) / 3)}
                hasVideoClip={this.props.hasVideoClip}
                autoPlay={this.props.autoPlay && !this.props.backend}
                videoClip={this.props.videoClip}
                supplementVideoClip={this.props.supplementVideoClip}
              />
            </VideoPlayerWrapper>
            {
              !this.props.showReviewToggle &&
                <NoReview>
                  <QuestionWrapper>
                    {
                      !this.props.showNoReviewExplanation &&
                        <span>
                          This word is not available for review.
                        </span>
                    }
                    {
                      !this.props.showNoReviewExplanation && this.props.reasonToBlockReviewToggle &&
                        <SpanLink
                          onClick={this.props.onToggleShowNoReviewExplanation}
                        >
                          Why?
                        </SpanLink>
                    }
                  </QuestionWrapper>
                  {
                    this.props.showNoReviewExplanation &&
                      <div>
                        {Constants.NO_REVIEW_EXPLANATIONS[this.props.reasonToBlockReviewToggle]}
                      </div>
                  }
                </NoReview>
            }
            {
              this.props.showReviewToggle &&
                <ReviewButtonWrapper>
                  <ReviewToggle
                    interfaceSource="word-card"
                    margin="0 0 10px 0"
                    buttonText="Add to Reviews"
                    word={this.props.word.rootWord ? this.props.word.rootWord : this.props.word}
                  />
                  <LearnedButton
                    word={this.props.word}
                  />
                </ReviewButtonWrapper>
            }
            {
              this.props.showReviewsMessage &&
                <Message>
                  <i className='fa fa-warning' /> This feature is available for Seedlang Pros.
                </Message>
            }
            <WordCardRows>
              <WordCardRow
                id={this.props.word.id}
                expandedId={this.props.expandedId}
                keepExpanded={!this.props.sentence}
                onClick={this.props.onClickRow}
              >
                <span>
                  {
                    this.props.primaryTranslationText &&
                      <WordCardTitle
                        text={this.props.primaryTranslationText}
                        icon={
                          <LanguageIcon
                            languageId={this.props.word.languageId}
                          />
                        }
                        bold
                        big={!this.props.sentence}
                      />
                  }
                  {
                    !this.props.sentence && this.props.word && this.props.word.translationSources.map(item => {
                      return (
                        <WordCardTitle
                          key={item.id}
                          text={`${item.source.textForUser(this.props.user)}${item.source.displayGender ? this.props.word.genderAbbreviation : ''}`}
                          icon={
                            <LanguageIcon
                              languageId="EN"
                              useUkFlagForEn={this.props.user?.useBritishEnglish}
                            />
                          }
                          big
                        />
                      )
                    })
                  }
                  {
                    this.props.sentence && this.props.blankSource &&
                      <WordCardTitle
                        text={
                          <Text
                            fontStyle="italic"
                            color="#888"
                            fontSize="16px"
                          >
                            No Translation
                            <InfoTooltip
                              margin="1px 0 0px 5px"
                            >
                              Some words are not translatable into English.
                            </InfoTooltip>
                          </Text>
                        }
                        icon={
                          <LanguageIcon
                            languageId="EN"
                            useUkFlagForEn={this.props.user?.useBritishEnglish}
                          />
                        }
                        big={!this.props.sentence}
                      />
                  }
                  {
                    this.props.sentence && !this.props.blankSource &&
                      <WordCardTitle
                        text={`${this.props.wordAssociation.source.textForUser(this.props.user)}${this.props.wordAssociation.source.displayGender ? this.props.word.genderAbbreviation : ''}`}
                        icon={
                          <LanguageIcon
                            languageId="EN"
                            useUkFlagForEn={this.props.user?.useBritishEnglish}
                          />
                        }
                        big={!this.props.sentence}
                      />
                  }
                  {
                    !this.props.sentence &&
                      <AddInput
                        linkText="Add Translation"
                        showInput={this.props.showSourceInput}
                        onEnableInput={this.props.onEnableSourceInput}
                        onSubmitInput={this.props.onSubmitSourceInput}
                        inputs={[{
                          name: 'text',
                          placeholder: 'Enter a Translation'
                        }]}
                      />
                  }
                </span>
                <WordCardRowExpanded
                  word={this.props.word}
                  willReceiveSentences={!this.props.sentence}
                  sentences={this.props.wordSentences}
                  onClickPlayVideo={this.props.onClickPlayVideo}
                  user={this.props.user}
                />
              </WordCardRow>
              {
                this.props.wordAssociation && this.props.wordAssociation.vocabConcepts.map((concept) => {
                  return (
                    <WordCardRow
                      key={concept.id}
                      id={concept.id}
                      expandedId={this.props.expandedId}
                      onClick={this.props.onClickRow}
                    >
                      <WordCardTitle
                        concept={concept}
                      />
                      <WordCardRowExpanded
                        concept={concept}
                        onClickPlayVideo={this.props.onClickPlayVideo}
                        user={this.props.user}
                      />
                    </WordCardRow>
                  )
                })
              }
              {
                this.props.infinitiveVerbTargetText &&
                  <WordCardRow
                    id='infinitive'
                    expandedId={this.props.expandedId}
                    onClick={this.props.onClickRow}
                  >
                    <WordCardTitle
                      text={`${this.props.infinitiveVerbTargetText}, Infinitive Form`}
                      icon={<ArrowIcon />}
                    />
                    <WordCardRowExpanded
                      title={this.props.word.hasInfinitiveVerb ? `Sentences using "${this.props.word.infinitiveVerb.targetText}"` : `Sentences using "${this.props.wordAssociation.infinitiveConcept.name}"`}
                      word={this.props.word.hasInfinitiveVerb ? this.props.word.infinitiveVerb : this.props.wordAssociation.infinitiveConcept}
                      onClickPlayVideo={this.props.onClickPlayVideo}
                      user={this.props.user}
                    />
                  </WordCardRow>
              }
              {
                this.props.showWordType &&
                  <WordCardRow
                    id={`word-type-${this.props.word.id}`}
                    expandedId={this.props.expandedId}
                    onClick={this.props.onClickRow}
                  >
                    <WordCardTitle
                      text={this.props.word.wordType.name}
                    />
                    <WordTypeDescription
                      wordTypeId={this.props.word.wordType.id}
                    />
                  </WordCardRow>
              }
              {
                this.props.showWordTypeConcept &&
                  <WordCardRow
                    id={`word-type-${this.props.word.id}`}
                    expandedId={this.props.expandedId}
                    onClick={this.props.onClickRow}
                  >
                    <WordCardTitle
                      icon={ICONS[this.props.word.wordTypeConcept.icon]}
                      concept={this.props.word.isNoun ? null : this.props.word.wordTypeConcept}
                      text={this.props.wordTypeText}
                      caseAbbreviation={this.props.word.isNoun && this.props.word.caseAbbreviation}
                      gender={this.props.word.plural ? 'plural' : this.props.word.gender}
                    />
                    <WordCardRowExpanded
                      hideSentences
                      htmlDescription={this.props.wordTypeLongDescription}
                      markdownDescription={this.props.wordTypeShortDescriptionMd}
                      concept={this.props.word.wordTypeConcept}
                      onClickPlayVideo={this.props.onClickPlayVideo}
                      user={this.props.user}
                    />
                  </WordCardRow>
              }
              {
                this.props.showConjugationTable &&
                  <WordCardRow
                    id='conjugation-table'
                    expandedId={this.props.expandedId}
                    onClick={this.props.onClickRow}
                  >
                    <WordCardTitle
                      icon={<TableIcon />}
                      text={this.props.word.isInfinitive ? "Conjugation Tables" : "Conjugation Table"}
                    />
                    <ConjugationTable
                      inDeck
                      word={this.props.word}
                    />
                  </WordCardRow>
              }
              {
                this.props.wordAssociation && this.props.wordAssociation.nonvocabConcepts.map((concept) => {
                  return (
                    <WordCardRow
                      key={concept.id}
                      id={concept.id}
                      expandedId={this.props.expandedId}
                      onClick={this.props.onClickRow}
                    >
                      <WordCardTitle
                        key={1}
                        concept={concept}
                      />
                      <WordCardRowExpanded
                        concept={concept}
                        onClickPlayVideo={this.props.onClickPlayVideo}
                        user={this.props.user}
                      />
                    </WordCardRow>
                  )
                })
              }
            </WordCardRows>
          </Content>
        </ScrollContainer>
        {
          this.props.sentence && !this.props.backend &&
            <SentenceWrapper>
              <TextResizer
                resizeText
                maximumFontSize={30}
                height={100}
                width={DeckUI.layout.innerDeckWidth - 20}
                characterLength={this.props.sentence.targetText && this.props.sentence.targetText.length}
              >
                <SentenceBreakdown
                  center
                  showUnderline
                  showCase
                  sentence={this.props.sentence}
                  onClick={() => DeckUI.onWordClick()}
                />
              </TextResizer>
            </SentenceWrapper>
        }
      </Wrapper>
    );
  }
}

export default WordCardWrapper(WordCard);
