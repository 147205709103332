import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { pixify } from '@seedlang/utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 5px;
  border-radius: 5px;
  width: ${(props) => props.width};
  margin: 0 2px;
  background: #e8e8e8;
  justify-content: center;
`;

const Icon = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${(props) => props.backgroundColor};
  color: white;
`;

const Label = styled.div`
  font-weight: bold;
  font-size: ${(props) => props.fontSize};
  margin: 0 5px;
`;

@observer
class Legend extends React.Component {

  static defaultProps = {
    width: "110px",
    fontSize: 12,
  }

  render() {
    return (
      <Wrapper
        width={this.props.width}
      >
        <Icon
          backgroundColor={this.props.iconBackground}
        />
        <Label
          fontSize={pixify(this.props.fontSize)}
        >
          {this.props.text}
        </Label>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Legend);
