import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { FlagDeIcon, LanguageIcon, StreakIcon } from '@seedlang/icons';
import { orderBy } from 'lodash';
import styled from '@emotion/styled';
import Text from 'components/text';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';
import ReactTooltip from 'react-tooltip';
import { isBlank, isPresent } from '@seedlang/utils';
import { Languages, Constants } from "@seedlang/constants/src";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  .streak-icon {
    margin-right: 0;
    .streak-icon-wrapper {
      height: 18px;
    }
    svg {
      width: 11px;
    }
    .streak-count {
      margin-left: 2px;
    }
  }
`;

const InnerWrapper = styled.div`
  display: inline-flex;
  cursor: ${(props) => props.cursor};
  align-items: center;
  flex-wrap: wrap;
`;

const IconWrapper = styled.div`
  margin: 0 4px;
  display: flex;
  align-items: center;
  .icon-wrapper, .icon-wrapper > div {
    height: 18px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Count = styled.span`
  margin: 0 0 0 3px;
`;

@observer
class NameWithIcons extends React.Component {

  @computed get blockLink() {
    return window.location.href.match(this.props.user.id);
  }

  @autobind onClick() {
    AppUI.routeStore.routeToNamed('profiles.show', {userId: this.props.user.id});
  }

  render() {
    return (
      <Wrapper
      >
        <InnerWrapper
          onClick={this.onClick}
          cursor={this.blockLink ? 'default' : 'pointer'}
        >
          <Text
            bold
            margin="0 4px 0 0"
            fontSize="14px"
            lineHeight="18px"
          >
            {isPresent(this.props.user.name) ? this.props.user.name : 'Anonymous'}
          </Text>
            {
              isPresent(this.props.user.targetLanguages) &&
                <Row>
                  {
                    orderBy(this.props.user.targetLanguages.filter(item => (item.xp > 0 || item.level?.abbreviation === 'N') && Constants.LANGUAGE_ABBREVIATIONS.indexOf(item.languageId) !== -1), [(item) => item.level?.abbreviation !== 'N','xp']).reverse().map(item => {
                      return (
                        <div
                          data-tip
                          data-for={`${item.id}-level`}
                          key={`${Math.random}-${item.id}`}
                        >
                          <IconWrapper>
                            <LanguageIcon
                              languageId={item.languageId}
                            />
                            <Count>
                              {item.level?.abbreviation === 'N' ? item.level.abbreviation : item.xpLevel}
                            </Count>
                          </IconWrapper>
                          {
                            item.level?.abbreviation !== 'N' &&
                              <ReactTooltip
                                type="dark"
                                effect="solid"
                                id={`${item.id}-level`}
                                class="custom-tooltip"
                              >
                                <center>Level {item.xpLevel}</center>
                                <center>{item.xp.toLocaleString('en')} XP</center>
                              </ReactTooltip>
                          }
                          {
                            item.level?.abbreviation === 'N' &&
                              <ReactTooltip
                                type="dark"
                                effect="solid"
                                id={`${item.id}-level`}
                                class="custom-tooltip"
                              >
                                <center>Native {Languages[item.languageId]} Speaker</center>
                              </ReactTooltip>
                          }
                        </div>
                      )
                    })
                  }
                </Row>
            }
            {
              isBlank(this.props.user.targetLanguages) &&
                <div
                  data-tip
                  data-for={`${this.props.user.id}-level`}
                >
                  <IconWrapper>
                    <FlagDeIcon />
                    <Count>
                      {this.props.user.xpLevel}
                    </Count>
                  </IconWrapper>
                  <ReactTooltip
                    type="dark"
                    effect="solid"
                    id={`${this.props.user.id}-level`}
                    class="custom-tooltip"
                  >
                    <center>Level {this.props.user.xpLevel}</center>
                    <center>{this.props.user.xp} XP</center>
                  </ReactTooltip>
                </div>
          }
          {
            this.props.user.currentStreakCount > 0 &&
              <div
                data-tip
                data-for={this.props.user.id}
              >
                <IconWrapper>
                  <StreakIcon
                    user={this.props.user}
                  />
                </IconWrapper>
                <ReactTooltip
                  type="dark"
                  effect="solid"
                  id={this.props.user.id}
                  class="custom-tooltip"
                >
                  {this.props.user.currentStreakCount} Day Streak
                </ReactTooltip>
              </div>
          }
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(NameWithIcons);
