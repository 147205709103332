import React from 'react';
import { observer } from 'mobx-react';
import { LevelStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';

@observer
class LevelEdit extends React.Component {

  componentDidMount() {
    LevelStore.getShow({ ids: {levelId: this.props.params.levelId }});
  }

  render() {
    return (
      <div className='level-edit'>
        <div className="row">
          <div className="col-xs-6">
            <fieldset>
              <legend>Name</legend>
              {LevelStore.showDataField('name')}
            </fieldset>
          </div>
          <div className="col-xs-6">
            <fieldset>
              <legend>Abb</legend>
              {LevelStore.showDataField('abbreviation')}
            </fieldset>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <fieldset>
              <legend>Word List</legend>
              <InPlaceText
                inputType='textarea'
                id={LevelStore.showDataField('id')}
                defaultValue={LevelStore.showDataField('wordList')}
                model="levels"
                field="word_list"
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default LevelEdit;
