import React from 'react';
import { observer } from 'mobx-react';
import { TreeUI, AppUI } from '@seedlang/state';
import { isPresent } from '@seedlang/utils';
import styled from '@emotion/styled';
import { TreeNodeShowWrapper } from '@seedlang/hoc';
import Tabs from 'components/tabs';
import TreeNodeDecks from 'components/tree_node/tree_node_decks';
import TreeNodeGrammar from 'components/tree_node/tree_node_grammar';
import TreeNodeVocab from 'components/tree_node/tree_node_vocab';
import TextDisplay from 'components/text_display';
import { Link } from 'react-router';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align: items: center;
  background: white;
  width: 100%;
  padding: 20px;
  border-radius 5px;
`;

const GrammarTitle = styled.div`
  display: inline-block;
  margin: ${(props) => props.margin};
  font-weight: normal;
  font-size: 20px;
  font-style: italic;
`;

const NameWrapper = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
`;

const Name = styled.div`

`;

const Description = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
`;

const Header = styled.div`
  display: flex;
`;

const Column = styled.div`
  flex: 1;
`;

const AdminLink = styled.div`
  font-size: 11px;
  text-decoration: underline;
  text-align: center;
  padding: 5px;
`;

@observer
class TreeNodeShow extends React.Component {

  componentDidMount() {
    document.getElementById('top').scrollIntoView();
  }

  render() {
    return (
      <Wrapper>
        {
          this.props.treeNode &&
            <Header>
              <Column>
                <NameWrapper
                  flexDirection={TreeUI.layout.isMobile ? "column" : "row"}
                >
                  <Name>
                    {this.props.treeNode.name}
                  </Name>
                  {
                    isPresent(this.props.treeNode.grammarTitle) &&
                      <GrammarTitle
                        margin={TreeUI.layout.isMobile ? "5px 0 0 0" : "0 0 0 7px"}
                      >
                        {this.props.treeNode.grammarTitle}
                      </GrammarTitle>
                  }
                </NameWrapper>
                {
                  this.props.treeNode.descriptionMd &&
                    <Description>
                      <TextDisplay
                        markdown={this.props.treeNode.descriptionMd }
                      />
                    </Description>
                }
              </Column>
            </Header>
        }
        {
          this.props.treeNode &&
            <Tabs
              tabs={this.props.tabs}
            >
              <TreeNodeDecks
                treeNodeId={this.props.treeNodeId}
                userTreeNode={this.props.userTreeNode}
                decks={this.props.treeNode && this.props.treeNode.treeNodeDecks}
              />
              <TreeNodeVocab
                treeNode={this.props.treeNode}
              />
              {
                this.props.showGrammar &&
                  <TreeNodeGrammar
                    treeNodeId={this.props.treeNodeId}
                  />
              }
            </Tabs>
        }
        {
          AppUI.user.admin && this.props.treeNode && this.props.treeNode.treeId && this.props.treeNode.treeModuleId &&
          <AdminLink>
            <Link
              to={{name: 'builder.tree_nodes.edit', params: {treeNodeId: this.props.treeNode.id, treeId: this.props.treeNode.treeId, treeModuleId: this.props.treeNode.treeModuleId }}}
            >
              Admin Link
            </Link>
          </AdminLink>
        }
      </Wrapper>
    );
  }
}

export default TreeNodeShowWrapper(TreeNodeShow);
