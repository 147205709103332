import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { PageStore } from '@seedlang/stores';
import { computed } from 'mobx';
import autobind from 'autobind-decorator';
import { AppUI } from "@seedlang/state";
import Spinner from "components/spinner";

const Wrapper = styled.div`
    margin-top: ${props => props.marginTop};
    margin-bottom: ${props => props.marginBottom};
    padding: 10px 20px;
    background: #FFF;
    border-radius: ${props => props.borderRadius};
    h1,h2 {
      line-height: 32px;
    }
    li {
      margin-left: 20px;
      list-style-type: disc;
    }
    a {
      text-decoration: underline;
      word-break: break-word;
    }
    a:hover {
      text-decoration: none;
    }
    p {
      margin: 10px 0!important;
    }
    img {
      max-width: 100%;
      height: auto;
    }
`;

@observer
class PageShow extends React.Component {
  componentDidMount() {
    this.getPage();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.routeParams.pageId !== prevProps.routeParams.pageId) {
      this.getPage();
    }
  }

  componentWillUnmount() {
    PageStore.clearShowData();
  }

  @computed get page() {
    return PageStore.showData;
  }

  @autobind getPage() {
    PageStore.getShow({ ids: { pageId: this.props.routeParams.pageId || 'home' }});
  }

  render() {
    return (
      <Wrapper
        marginTop={AppUI.layout.isMobile ? "15px" : null}
        marginBottom={AppUI.layout.isMobile ? "60px" : null}
        borderRadius={AppUI.layout.isMobile ? '0' : '10px'}
      >
        {
          PageStore.requestCounter > 0 &&
          <Spinner background={AppUI.site.accentColor} />
        }
        { 
          this.page.content &&
            <div
              dangerouslySetInnerHTML={{ __html: this.page.content || "" }}
            />
        }
      </Wrapper>
    );
  }
}

export default PageShow;
