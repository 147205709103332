import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ConceptStore } from '@seedlang/stores';
import { SentenceStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import InPlaceText from 'components/form/in_place_text';
import { IntersectingWordStore } from '@seedlang/stores';
import { IntersectingConceptStore } from '@seedlang/stores';
import SearchMultiSelect from 'components/form/search_multi_select';
import { WordStore } from '@seedlang/stores';

@observer
class VocabConceptCreate extends React.Component {
  @observable conceptId;

  @autobind onCreate() {
    ConceptStore.create({data: {
      name: this.refs.name.value,
      join_type: 'and',
      published: true,
      vocab: true
    }}, this.afterCreate);
  }

  @autobind afterCreate(resp) {
    this.conceptId = resp.conceptId;
    ConceptStore.getShow({ ids: {conceptId: resp.conceptId }});
  }

  @autobind onCreateIntersectingWord(word) {
    IntersectingWordStore.create({ ids: { conceptId: ConceptStore.showDataField('id') }, data: { id: word.id } }, this.onChange);
  }

  @autobind onDestroyIntersectingWord(id) {
    IntersectingWordStore.destroy({ ids: { intersectingWordId: id, conceptId: ConceptStore.showDataField('id') } }, this.onChange);
  }

  @autobind onCreateIntersectingConcept(concept) {
    IntersectingConceptStore.create({ ids: { conceptId: ConceptStore.showDataField('id') }, data: { id: concept.id } }, this.onChange);
  }

  @autobind onDestroyIntersectingConcept(id) {
    IntersectingConceptStore.destroy({ ids: { intersectingConceptId: id, conceptId: ConceptStore.showDataField('id') } }, this.onChange);
  }

  @autobind onChange() {
    ConceptStore.reloadShow();
    SentenceStore.reloadShow();
  }

  render() {
    return (
      <div
        className="vocab-concept-create"
        style={{height: 600}}
      >
        {
          !this.conceptId && (
            <div className='row'>
              <div className="col-xs-9">
                <input
                  placeholder="Add a concept name"
                  ref="name"
                />
              </div>
              <div className="col-xs-2">
                <button
                  className="button-primary"
                  onClick={this.onCreate}
                >
                  Create
                </button>
              </div>
            </div>
          )
        }
        {
          this.conceptId && (
            <span>
              <div className='row'>
                <div className="col-xs-12">
                  <fieldset>
                    <legend>Name</legend>
                    <InPlaceText
                      defaultValue={ConceptStore.showDataField('name')}
                      model="concepts"
                      field="name"
                      id={this.conceptId}
                    />
                  </fieldset>
                  <fieldset className="short_description">
                    <legend>Short Description</legend>
                    <InPlaceText
                      defaultValue={ConceptStore.showDataField('shortDescription')}
                      model="concepts"
                      field="short_description"
                      id={ConceptStore.showDataField('id')}
                    />
                  </fieldset>
                  <fieldset>
                    <legend>Intersecting Words</legend>
                    <SearchMultiSelect
                      ids={ConceptStore.showData.intersectingWordIds}
                      options={ConceptStore.showData.mappedIntersectingWords}
                      onCreate={this.onCreateIntersectingWord}
                      onDestroy={this.onDestroyIntersectingWord}
                      searchStore={WordStore}
                      searchField="target_text"
                      displayField="targetWithWordTypeParentInfinitiveAndSource"
                      sort="target_text"
                      linkTo="builder.words.edit"
                      linkId="wordId"
                    />
                  </fieldset>
                  <fieldset>
                    <legend>Intersecting Concepts</legend>
                    <SearchMultiSelect
                      ids={ConceptStore.showData.intersectingConceptIds}
                      options={ConceptStore.showData.mappedIntersectingConcepts}
                      onCreate={this.onCreateIntersectingConcept}
                      onDestroy={this.onDestroyIntersectingConcept}
                      searchStore={ConceptStore}
                      searchField="name"
                      displayField="name"
                      excludeIds={[ConceptStore.showData.id]}
                      linkTo="builder.concepts.edit"
                      linkId="conceptId"
                      sort="name"
                    />
                  </fieldset>
                </div>
              </div>
            </span>
          )
        }
      </div>
    );
  }
}

export default VocabConceptCreate;
