import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { SiteStore } from '@seedlang/stores';
import DeleteButton from 'components/button/delete_button';
import Paginator from 'components/paginator';
import Modal from 'components/modal';
import SiteCreate from 'pages/builder/sites/site_create';
import { Link } from 'react-router';
import autobind from 'autobind-decorator';
import InPlaceCheckbox from 'components/form/in_place_checkbox';

@observer
class SiteIndex extends React.Component {
  @observable showCreate = false;

  componentDidMount() {
    this.getSites();
  }

  componentWillUnmount() {
    SiteStore.clearIndexData();
  }

  @autobind onDelete(id) {
    SiteStore.destroy({ ids: { siteId: id } });
  }

  @autobind onCopy(id) {
    SiteStore.copy({ ids: { siteId: id } }, this.getSites);
  }

  @autobind getSites() {
    SiteStore.getIndex();
  }

  render() {
    return (
      <div className="site-index">
        {
          !this.props.hideCreateButton && this.showCreate &&
          <Modal
            onCloseModal={() => this.showCreate = false}
          >
            <SiteCreate />
          </Modal>
        }
        <div style={{display: 'flex', marginBottom: '10px'}}>
          <div style={{width: 150}}>
            {
              !this.props.hideCreateButton && (
                <button
                  className='gray-button'
                  onClick={() => this.showCreate = true}
                >
                  <i className='fa fa-plus' />
                  Create
                </button>
              )
            }
          </div>
        </div>
        <table className="table-wrapper admin-table">
          <thead>
            <tr>
              <th width="40"></th>
              <th>Name</th>
              <th>Domains</th>
              <th>Published?</th>
              <th>Web Version</th>
              <th>iOS Version</th>
              <th>Android Version</th>
              <th width="40"></th>
              <th width="40"></th>
            </tr>
          </thead>
          <tbody>
            {
              SiteStore.indexData.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.site.edit', params: { siteId: item.id } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>
                      <div className="text">
                        {item.name}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        <a href={"https://" + item.domainString} className='underline' target='_blank'>
                          {item.domainString}
                        </a>
                      </div>
                    </td>
                    <td>
                      <InPlaceCheckbox
                        model="sites"
                        field="published"
                        id={item.id}
                        value={item.published}
                        icon="check"
                      />
                    </td>
                    <td>
                      <div className="text">
                        {item.currentWebVersion}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.currentIosVersion}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.currentAndroidVersion}
                      </div>
                    </td>
                    <td>
                      <i 
                        onClick={() => this.onCopy(item.id)}
                        className='fa fa-copy fa-block fa-on-click' 
                      />
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message="Delete this site?"
                        className="fa-block right"
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={SiteStore}
        />
      </div>
    );
  }
}

export default SiteIndex;
