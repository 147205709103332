import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { isPresent } from "@seedlang/utils";
import { AppUI, ExerciseUI } from "@seedlang/state";
import PodcastButton from "components/worksheet/podcast_button";
import NoAccessButton from "components/worksheet/no_access_button";

const Wrapper = styled.div`
  flex: 2;
  position: relative;
  height: fit-content;
  font-size: 16px;
  width: ${props => props.width};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: white;
  border-radius: ${props => props.borderRadius};
  @media (max-width: 999px) {
    order: -1;
    width: 100%;
  }
  iframe, .youtube-video {
    border-radius: 10px;
    overflow: hidden;
  }
  .icon-wrapper {
    display: inline-block;
    margin: 4px 4px 0 0;
  }
  .youtube-icon {
    display: inline-block;
    svg {
      width: 24px;
      fill: #c4302b;
    }
  }
`;

const CoverWrapper = styled.div`
  flex: 1;
  max-width: ${props => props.maxWidth};
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  audio {
    width: 100%;
  }
  margin: 10px;
`;

const ImageWrapper = styled.a`
  img {
    max-height: ${props => props.maxHeight};
    border-radius: 10px;
  }
`;

@observer
class PodcastPlayer extends React.Component {

  render() {
    return (
      <Wrapper
        width={this.props.width}
        borderRadius={this.props.borderRadius}
      >
        {
          !AppUI.layout.isMobile &&
            <CoverWrapper
              maxWidth={AppUI.layout.isMobile ? '170px' : '320px'}
            >
              {
                isPresent(this.props.podcast?.podcastImage) && isPresent(this.props.podcast?.podcastImage.image) &&
                  <ImageWrapper
                    maxHeight={AppUI.layout.isMobile ? '150px' : '300px'}
                  >
                    <img
                      src={this.props.podcast?.podcastImage?.image.url}
                      alt={`Podcast cover for ${this.props.podcast.title}`}
                    />
                  </ImageWrapper>
              }
            </CoverWrapper>
        }
        {
          this.props.hasAccess && isPresent(this.props.podcastEpisode) &&
            <PodcastButton
              hasAccess={this.props.hasAccess}
              width={AppUI.layout.isMobile ? '360px' : '310px'}
              margin="10px 0 5px 0"
              height="50px"
              podcastEpisode={this.props.podcastEpisode}
              showImage={AppUI.layout.isMobile || ExerciseUI.worksheet.youtubeUrlIsValid}
            />
        }
        {
          !this.props.hasAccess &&
            <NoAccessButton
              margin="10px 0"
              message="Upgrade to Unlock Interactive Player"
            />
        }
        {
          this.props.showAudioPlayer && isPresent(this.props.podcastEpisode.mp3) &&
            <audio
              controls
              controlsList="nodownload"
              src={this.props.podcastEpisode.mp3.url}>
            </audio>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(PodcastPlayer);
