import React from 'react';
import Navigation from 'components/navigation';
import { last } from 'lodash';

class Subscription extends React.Component {

  render() {
    return (
      <div className="user">
        <Navigation
          currentRoute={last(this.props.routes).name}
          smallButtons
          links={[
            {
              link: 'builder.subscriptions.index',
              title: 'Subscriptions',
            },
            {
              link: 'builder.membership_groups.index',
              title: 'Membership Groups',
            },
            {
              link: 'builder.membership_types.index',
              title: 'Membership Types',
            },
            {
              link: 'builder.payments.index',
              title: 'Payments',
            },
            {
              link: 'builder.payment_intents.index',
              title: 'Payment Intents',
            },
          ]}
        />
        {this.props.children}
      </div>
    );
  }
}

export default Subscription;
