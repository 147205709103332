import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { pixify } from '@seedlang/utils';
import { CardSectionWrapper, BorderRadius } from '@seedlang/hoc';
import HeightLabel from 'components/height_label';
import { DeckUI } from '@seedlang/state';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => props.justifyContent};
  padding: ${(props) => props.padding};
  height: ${(props) => props.height};
  flex: ${(props) => props.height ? null : 1};
  background: ${(props) => props.background};
  background-size: ${(props) => props.backgroundSize};
  background-repeat: repeat;
  position: relative;
  width: 100%;
  border-top-left-radius: ${(props) => props.borderTopLeftRadius};
  border-top-right-radius: ${(props) => props.borderTopRightRadius};
  border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
  border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
  overflow: hidden;
  font-size: ${(props) => props.fontSize};
`;

@observer
class CardSection extends React.Component {

  static defaultProps = {
    padding: "10px",
    justifyContent: "center",
  }

  render() {
    return (
      <Wrapper
        height={pixify(this.props.height)}
        backgroundSize={pixify(this.props.height / 4)}
        className={this.props.className}
        background={this.props.background}
        padding={this.props.padding}
        borderTopLeftRadius={pixify(this.props.borderTopLeftRadius)}
        borderTopRightRadius={pixify(this.props.borderTopRightRadius)}
        borderBottomLeftRadius={pixify(this.props.borderBottomLeftRadius)}
        borderBottomRightRadius={pixify(this.props.borderBottomRightRadius)}
        justifyContent={this.props.justifyContent}
        fontSize={this.props.fontSize}
        onClick={this.props.onClick}
      >
        {
          DeckUI.layout.debug &&
            <HeightLabel
              left={0}
            >
              {this.props.height}
            </HeightLabel>
        }
        {this.props.children}
      </Wrapper>
    );
  }
}

export default BorderRadius(CardSectionWrapper(CardSection));
