import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Modal from 'components/modal';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceText from 'components/form/in_place_text';
import InPlaceSelect from 'components/form/in_place_select';
import { isPresent } from '@seedlang/utils';
import { Constants } from '@seedlang/constants';

const Wrapper = styled.div`

`;

const NoteLink = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 5px;
  text-decoration: underline;
  cursor: pointer;
  i {
    margin-right: 3px;
  }
`;

@observer
class ExcludeEdit extends React.Component {
  @observable showModal = false;

  @computed get noun() {
    return this.props.word.hasWordType && this.props.word.wordType.abbreviation === 'noun';
  }

  @computed get verb() {
    return this.props.word.hasWordType && this.props.word.wordType.abbreviation === 'vrb';
  }

  @computed get hasNote() {
    return isPresent(this.props.word.hideReason) || isPresent(this.props.word.note);
  }

  render() {
    return (
      <Wrapper>
        {
          this.showModal &&
            <Modal
              onCloseModal={() => this.showModal = false}
            >
              <fieldset>
                <legend>Reason for Hiding</legend>
                <InPlaceSelect
                  includeBlank
                  options={Constants.HIDE_FROM_VOCAB_TRAINER_REASONS}
                  value={this.props.word.hideReason}
                  model="words"
                  field="hide_reason"
                  id={this.props.word.id}
                  afterChange={this.props.onChange}
                />
              </fieldset>
              <fieldset>
                <legend>Note</legend>
                <InPlaceText
                  show
                  placeholder="Update Note"
                  defaultValue={this.props.word.note}
                  model="words"
                  inputType="textarea"
                  field="note"
                  id={this.props.word.id}
                  afterChange={this.props.onChange}
                />
              </fieldset>
            </Modal>
        }
        {
          this.props.word.hasAssignableRoot &&
            <div>
              <InPlaceCheckbox
                model="words"
                field="exclude_from_public_lists"
                value={this.props.word.excludeFromPublicLists}
                afterChange={this.props.onChange}
                id={this.props.word.id}
                icon="check"
              >
                Dictionary
              </InPlaceCheckbox>
            </div>
        }
        {
          this.verb &&
            <div>
             <InPlaceCheckbox
              icon="check"
              model="words"
              id={this.props.word.id}
              value={this.props.word.hideFromConjugationTrainer}
              field="hide_from_conjugation_trainer"
              afterChange={this.props.onChange}
             >
              Conjugation Trainer
             </InPlaceCheckbox>
            </div>
        }
        {
          this.noun &&
            <div>
             <InPlaceCheckbox
              icon="check"
              model="words"
              id={this.props.word.id}
              value={this.props.word.hideFromGenderTrainer}
              field="hide_from_gender_trainer"
              afterChange={this.props.onChange}
             >
              Gender Trainer
             </InPlaceCheckbox>
            </div>
        }
        {
          this.noun && this.props.word.languageId === 'DE' &&
            <div>
             <InPlaceCheckbox
              icon="check"
              model="words"
              id={this.props.word.id}
              value={this.props.word.hideFromPluralTrainer}
              field="hide_from_plural_trainer"
              afterChange={this.props.onChange}
             >
              Plural Trainer
             </InPlaceCheckbox>
            </div>
        }
        <NoteLink
          onClick={() => this.showModal = true}
        >
          { this.hasNote && <i className='fa fa-comment' /> }
          { !this.hasNote && <i className='fa fa-comment-o' /> }
          Note
        </NoteLink>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ExcludeEdit);
