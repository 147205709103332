import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { PageStore } from '@seedlang/stores';
import { computed } from 'mobx';
import autobind from 'autobind-decorator';
import TextDisplay from 'components/text_display';
import { isEmpty } from 'lodash';

const Wrapper = styled.div`
    padding: 10px;
    li {
        list-style-type: circle;
        margin: 0 0 0 50px;
    }
`;

@observer
class PageShowBySlug extends React.Component {
  componentDidMount() {
    this.getPage();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.routeParams.slug !== prevProps.routeParams.slug) {
      this.getPage();
    }
  }

  componentWillUnmount() {
    PageStore.clearShowData();
  }

  @computed get page() {
    return PageStore.showData;
  }

  @autobind getPage() {
    PageStore.showBySlug({ slug: this.props.routeParams.slug});
  }

  render() {
    return (
      <Wrapper>
        <h3>{this.page.title}</h3>
        { !isEmpty(this.page) &&
          <TextDisplay
            markdown={String(this.page.contentMd)}
          />
        }
      </Wrapper>
    );
  }
}

export default PageShowBySlug;
