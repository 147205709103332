import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import autobind from 'autobind-decorator';
import { SentenceStore } from '@seedlang/stores';
import cx from 'classnames';
import styled from '@emotion/styled';
import Checkbox from 'components/checkbox';

const Wrapper = styled.div`
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

@observer
class MergeWordsEdit extends React.Component {
  @observable words = [];
  @observable selectedWordIndex = []
  @observable sentence;


  componentDidMount() {
    this.words = this.props.words;
    this.sentence = this.props.sentence;
  }

  @autobind onCheck(index) {
    if (this.isSelected(index)) {
      this.selectedWordIndex = this.selectedWordIndex.filter(item => item !== index);
    } else {
      this.selectedWordIndex.push(index);
      this.selectedWordIndex = this.selectedWordIndex.slice().sort((a, b) => a - b);
    }
  }

  isSelected(index) {
    return this.selectedWordIndex && this.selectedWordIndex.includes(index);
  }

  @autobind nonConsecutiveWordsSelected() {
    let nonConsecutive = false;
    if (this.selectedWordIndex.length === 0) {
      return nonConsecutive;
    } else {
      this.selectedWordIndex.slice(0,-1).forEach((wordIndex, i) => {
        if (this.selectedWordIndex[i+1] !== wordIndex+1) {
          nonConsecutive = true;
        }
      })
    }
    return nonConsecutive;
  }

  @autobind onClick() {
    SentenceStore.mergeSentenceWords({ids: {sentenceId: this.sentence.id}, data: {indexes: this.selectedWordIndex}}, this.props.afterChange);
  }

  render() {
    return (
      <Wrapper>
        <div className='sentence-concept-edit'>
          {
            this.words.map((item, index) => {
              return (
                <Row
                  key={index}
                >
                  <Checkbox
                    onClick={() => this.onCheck(index)}
                    value={this.isSelected(index)}
                  />
                  <div className='name' style={{marginLeft: "5px"}}>
                    {item.targetText}
                  </div>
                </Row>
              )
            })
          }
          <button
            className={cx("button-primary", {
              disabled: this.nonConsecutiveWordsSelected() || this.selectedWordIndex.length === 0
            })}
          disabled={this.nonConsecutiveWordsSelected() || this.selectedWordIndex.length === 0}
          onClick={this.onClick}
          >
          Merge
          </button>
        </div>
        {
          this.nonConsecutiveWordsSelected() &&
          <Row>
            <div
              className='alert'
              style={{
                margin: "0 0 10px 0",
                width: "100%",
              }}
            >
              You can only merge consecutive words.
            </div>
          </Row>
        }
      </Wrapper>
    );
  }
}

export default MergeWordsEdit;
