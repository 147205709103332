import React from 'react';
import { observer } from 'mobx-react';
import { TagCategoryStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';

@observer
class TagCategoryCreate extends React.Component {

  onClick() {
    const data = {
      name: this.refs.name.value,
      group_id: this.props.groupId,
    }
    TagCategoryStore.create({data: data}, this.afterCreate);
    this.refs.name.value = '';
  }

  @autobind afterCreate(resp) {
    this.props.afterChange();
  }

  render() {
    return (
      <div
        className="concept-category-create row"
        style={{marginBottom: 10}}
      >
        <div className="col-xs-10">
          <input
            placeholder="Enter Tag Category Name"
            ref="name"
          />
        </div>
        <div className="col-xs-2">
          <button
            className="button-primary"
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default TagCategoryCreate;
