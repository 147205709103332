import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Text from 'components/text';
import { flexCenter, flexCenterColumn } from '@seedlang/style_mixins';
import ProgressBar from 'components/progress_bar';
import ReactTooltip from 'react-tooltip';
import { formatNumber } from '@seedlang/utils';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  ${flexCenter()}
`;

const ProgressBarWrapper = styled.div`
`;

const VerticalWrapper = styled.div`
  ${flexCenterColumn()}
  flex: 1;
`;

@observer
class VerticalProgressBars extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.data && this.props.data.map(item => {
            return (
                item.total > 0 &&
                  <VerticalWrapper
                    key={item.label}
                  >
                    <ProgressBarWrapper
                      data-tip
                      data-for={item.label}
                    >
                      <ProgressBar
                        vertical
                        height="135px"
                        width="30px"
                        current={[item.total - item.learned - item.seen, item.seen, item.learned]}
                        total={item.total}
                        currentColors={["#d8d8d8", Theme.orange, Theme.green]}
                        borderRadius="5px"
                        background="#FFF"
                        segmentMargin="1.5px 0"
                        direction="column"
                      />
                      <ReactTooltip
                        place="top"
                        type="dark"
                        id={item.label}
                        class="custom-tooltip"
                      >
                        {`${formatNumber(item.learned)} learned, ${formatNumber(item.seen)} seen of ${formatNumber(item.total)}`}
                      </ReactTooltip>
                    </ProgressBarWrapper>
                    <Text
                      bold
                      fontSize="14px"
                      margin="10px 0 0 0"
                    >
                      {item.label}
                    </Text>
                  </VerticalWrapper>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(VerticalProgressBars);
