import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import { AppUI, PrivacySettingsState } from '@seedlang/state';
import autobind from 'autobind-decorator';
import Text from 'components/text';
import Button from 'components/button/button';
import Toggle from 'components/toggle';

const WrapperDiv = styled.div`
`;

const TextDiv = styled.div`
  margin: 10px 0;
  p:first-of-type {
    margin-top: 0;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  a {
    color: inherit;
    text-decoration: underline;
  }
`;

const ButtonWrapper = styled.div`
  width: ${props => props.width};
  display: flex;
  align-items: center;
  flex-direction: ${props => props.flexDirection};
  margin-bottom: 30px;
  gap: 5px;
`;

const Category = styled.div`
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-weight: bold;
  flex: 1;
`;

const DEFAULT_TEXT = `When you visit websites, they may store or retrieve data in your browser. This storage is often necessary for the basic functionality of the website. In Seedlang's case, we only use cookies to interact with 3rd party services. All personalization of your learning experience is stored on our servers and not in the browser. We do not serve up advertisements on the site, and don't use cookies for anything related to advertisements. Privacy is important to us, so you have the option of disabling any browser based storage that is not used for the basic functioning of the website.`

@observer
class PrivacySettingsContent extends React.Component {
  @observable navigationIndex = 0;

  get isModal() {
    return !!this.props.onCloseModal;
  }

  @autobind onAcceptAllClick() {
    PrivacySettingsState.acceptAll();
    if (this.isModal) this.saveAndClose();
  }

  @autobind onRejectAllClick() {
    PrivacySettingsState.rejectAll();
    if (this.isModal) this.saveAndClose();
  }

  @autobind saveAndClose() {
    PrivacySettingsState.saveChoices();
    this.closeModal();
  }

  @autobind closeModal() {
    if (this.props.onCloseModal) this.props.onCloseModal();
  }


  get privacyTextHtml() {
    return AppUI.site?.privacyTextHtml ?? DEFAULT_TEXT;
  }

  render () {
    return (
      <WrapperDiv>
        <Text heading="3" margin="0 0 10px 0">Privacy Preferences</Text>

        <TextDiv dangerouslySetInnerHTML={{ __html: this.privacyTextHtml }} />

        <ButtonWrapper
          flexDirection={AppUI.layout.isMobile ? 'column' : 'row'}
          width={AppUI.layout.isMobile ? '100%' : '440px'}
        >
          <Button onClick={this.onAcceptAllClick}>Accept All Cookies</Button>
          <Button onClick={this.onRejectAllClick}>Reject All Cookies</Button>
        </ButtonWrapper>

        <Text heading="3" margin="0 0 10px 0">Manage Consent</Text>

        
        {
          PrivacySettingsState.togglableCategories.map((item, index) => {
            return (
              <Category
                onClick={() => this.navigationIndex = index}
                key={index}
              >
                <Row>
                  <Title>{item.name}</Title>
                  {
                    item.showToggle &&
                      <Toggle
                        selected={PrivacySettingsState.choices[item.key] ?? false}
                        onToggle={() => PrivacySettingsState.toggleChoice(item.key)}
                      />
                  }
                </Row>
                <TextDiv>
                  {item.description}
                </TextDiv>
              </Category>
            )
          })
        }
        {
          (PrivacySettingsState.hasUnsavedChanges || !this.isModal) &&
            <ButtonWrapper>
              <Button onClick={this.saveAndClose} disabled={!PrivacySettingsState.hasUnsavedChanges}>
                {this.isModal ? "Save and Close" : "Save"}
              </Button>
            </ButtonWrapper>
        }
      </WrapperDiv>
    );
  }
}

export default PrivacySettingsContent;
