import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import autobind from 'autobind-decorator';
import { TagCategoryStore, ExerciseTagStore, WorksheetTagStore } from '@seedlang/stores';
import { flexCenter } from '@seedlang/style_mixins';
import cx from 'classnames';
import Spinner from 'components/spinner';
import Button from 'components/button/button';
import { Link } from 'react-router';
import { isPresent, isBlank } from '@seedlang/utils';
import InPlaceCheckbox from "components/form/in_place_checkbox";
import { AppUI } from "@seedlang/state";
import ReactTooltip from 'react-tooltip';
import { flatten, intersection, remove, some, uniq } from "lodash";
import Alert from "components/alert";

const Wrapper = styled.div`
  overflow: scroll;
  h3 {
    text-align: center;
    margin-bottom: 20px!important;
  }
`;

const TagCategory = styled.div`
  ${flexCenter()}
  background: white;
  padding: 10px;
  background: #d6d6d6;
  border-bottom: 1px solid gray;
`;

const TagCategoryWrapper = styled.div`
`;

const TagWrapper = styled.div`
  padding: 10px;
  cursor: pointer;
  .fa-check {
    color: #BBB;
    margin-right: 5px;
  }
  .fa-check.selected {
    color: #000;
  }
`;

const TagCategoryName = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 14px;
`;

const TagName = styled.div`
  flex: 1;
  font-size: 14px;
`;

const Count = styled.div`
  width: 50px;
  font-size: 14px;
`;

const NoTagsMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Tag = styled.div`
  ${flexCenter()}
`;

const NavigationSettings = styled.div`
  width: 240px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: space-evenly;
`;

@observer
class TagEdit extends React.Component {
  @observable showSpinner = false;
  @observable selectedTags = [];

  constructor(props) {
    super(props);
    this.showSpinner = true;
    this.loadTags();
    this.initSelectedTags();
  }

  @autobind loadTags() {
    TagCategoryStore.getIndex({ids: {groupId: this.props.groupId}}, this.afterLoadTags);
  }

  @autobind afterLoadTags(resp) {
    TagCategoryStore.setIndexData(resp);
    this.showSpinner = false;
  }

  @autobind initSelectedTags() {
    if (isBlank(this.worksheetOrExerciseTags)) { return }
    this.worksheetOrExerciseTags.forEach(worksheetTag => {
      if (this.selectedTags.indexOf(worksheetTag.tag?.id) === -1) {
        this.selectedTags.push(worksheetTag.tag?.id)
      }
    })
  }

  @computed get worksheetOrExerciseTags() {
    if (this.props.exercise && isPresent(this.props.exercise.exerciseTags)) {
      return this.props.exercise.exerciseTags;
    } else if (this.props.worksheet && isPresent(this.props.worksheet.worksheetTags)) {
      return this.props.worksheet.worksheetTags;
    } else if (this.props.worksheets) {
      const worksheetTagIds = this.props.worksheets.map(item => item.worksheetTags.map(worksheetTag => worksheetTag.tag?.id))
      const commonTags = intersection(...worksheetTagIds);
      return flatten(this.props.worksheets.map(item => item.worksheetTags.filter(worksheetTag => commonTags.indexOf(worksheetTag.tag?.id) !== -1)));
    }
  }

  @autobind tagExists(tag) {
    return this.selectedTags.indexOf(tag.id) !== -1;
  }

  @autobind onToggle(tag) {
    if (this.props.exercise) {
      if (this.tagExists(tag)) {
        tag.set('exercisesCount', tag.exercisesCount - 1);
        remove(this.selectedTags, item => item === tag.id);
        const exerciseTag = this.worksheetOrExerciseTags.find(item => item.tag.id === tag.id);
        ExerciseTagStore.destroy({ids: {exerciseTagId: exerciseTag.id}}, this.props.onChange);
      } else {
        tag.set('exercisesCount', tag.exercisesCount + 1);
        this.selectedTags.push(tag.id);
        ExerciseTagStore.create({data: {tag_id: tag.id, exercise_id: this.props.exercise.id}}, this.props.onChange);
      }
    } else if (this.props.worksheet) {
      if (this.tagExists(tag)) {
        tag.set('worksheetsCount', tag.worksheetsCount - 1);
        remove(this.selectedTags, item => item === tag.id);
        const worksheetTag = this.worksheetOrExerciseTags.find(item => item.tag.id === tag.id);
        WorksheetTagStore.destroy({ids: {worksheetTagId: worksheetTag.id}}, this.props.onChange);
      } else {
        tag.set('worksheetsCount', tag.worksheetsCount + 1);
        this.selectedTags.push(tag.id);
        WorksheetTagStore.create({data: {tag_id: tag.id, worksheet_id: this.props.worksheet.id}}, this.props.onChange);
      }
    } else if (this.props.worksheets) {
      if (this.tagExists(tag)) {
        tag.set('worksheetsCount', tag.worksheetsCount - this.props.worksheets.length);
        remove(this.selectedTags, item => item === tag.id);
        const worksheetTags = uniq(flatten(this.worksheetOrExerciseTags.filter(item => item.tag.id === tag.id)));
        WorksheetTagStore.batchDelete({data: {worksheetTagIds: worksheetTags.map(item => item.id)}}, this.props.onChange);
      } else {
        tag.set('worksheetsCount', tag.worksheetsCount + this.props.worksheets.length);
        this.selectedTags.push(tag.id);
        WorksheetTagStore.batchCreate({data: {tagId: tag.id, worksheetIds: this.props.worksheets.map(item => item.id)}}, this.props.onChange);
      }
    }
  }

  @computed get showWarning() {
    if (this.props.worksheet && isPresent(this.props.worksheet.worksheetTags) && TagCategoryStore.hasIndexData) {
      const worksheetTagCategories = TagCategoryStore.indexData.filter(item => some(item.tags.map(tag => this.props.worksheet.worksheetTags.map(i => i.tag?.id).indexOf(tag.id) !== -1)));
      if (isPresent(this.props.worksheet.podcastEpisode)) {
        return some(worksheetTagCategories.map(item => !item.displayOnPodcastWorksheets));
      }
      if (isPresent(this.props.worksheet.youtubeUrl)) {
        return some(worksheetTagCategories.map(item => !item.displayOnVideoWorksheets));
      }
    }
  }

  render() {
    
    return (
      <Wrapper>
        {
          !this.showSpinner && TagCategoryStore.indexData.length === 0 &&
            <NoTagsMessage>
              You have not yet created any tags. You can do so on the "Tags" page.
              <Link
                to={{name: "creator.groups.tags.index", params: {groupId: this.props.groupId}}}
              >
                <Button
                  margin="20px 0 0 0"
                >
                  Go to Tags Page
                </Button>
              </Link>
            </NoTagsMessage>
        }
        {
          this.props.worksheets && this.props.worksheets.length > 1 &&
            <Alert>You have selected several worksheets. Only the tags they have in common are displayed as selected below.</Alert>
        }
        {
          this.showSpinner &&
            <Spinner />
        }
        {
          TagCategoryStore.indexData.length > 0 && this.props.worksheet &&
            <h3>Edit Tags for this Post</h3>
        }
        {
          TagCategoryStore.indexData.length > 0 && this.props.exercise &&
            <h3>Edit Tags for this Exercise</h3>
        }
        {
          TagCategoryStore.indexData.map(item => {
            if (!item.isAutomatic) {
              return (
                <TagCategoryWrapper
                  key={item.id}
                >
                  <TagCategory>
                    <TagCategoryName>
                      {item.name}
                    </TagCategoryName>
                    <NavigationSettings>
                      <div
                        data-tip
                        data-for={`videos-${item.id}`}
                      >
                        <InPlaceCheckbox
                          model="tag_categories"
                          field="display_on_video_worksheets"
                          value={item.displayOnVideoWorksheets}
                          id={item.id}
                          icon="check"
                          afterChange={this.loadTags}
                        >
                          {AppUI.site.navigationItems.find(item => item.path === '/videos')?.name || 'Videos'}
                        </InPlaceCheckbox>
                        <ReactTooltip
                          type="dark"
                          effect="solid"
                          id={`videos-${item.id}`}
                          class="custom-tooltip"
                        >
                          <div>Display this tag on
                            the {AppUI.site.navigationItems.find(item => item.path === '/videos')?.name || 'Videos'} page.
                          </div>
                        </ReactTooltip>
                      </div>
                      <div
                        data-tip
                        data-for={`podcast-${item.id}`}
                      >
                        <InPlaceCheckbox
                          model="tag_categories"
                          field="display_on_podcast_worksheets"
                          value={item.displayOnPodcastWorksheets}
                          id={item.id}
                          icon="check"
                          afterChange={this.loadTags}
                        >
                          {AppUI.site.navigationItems.find(item => item.path === '/podcast')?.name || 'Podcasts'}
                        </InPlaceCheckbox>
                        <ReactTooltip
                          type="dark"
                          effect="solid"
                          id={`podcast-${item.id}`}
                          class="custom-tooltip"
                        >
                          <div>Display this tag on
                            the {AppUI.site.navigationItems.find(item => item.path === '/podcast')?.name || 'Videos'} page.
                          </div>
                        </ReactTooltip>
                      </div>
                    </NavigationSettings>
                  </TagCategory>
                  <TagWrapper>
                    {
                      item.tags.map(tag => {
                        return (
                          <Tag
                            key={tag.id}
                            onClick={() => this.onToggle(tag)}
                          >
                            <i
                              className={cx('fa', 'fa-check', {
                                selected: this.tagExists(tag),
                              })}
                            />
                            <TagName>
                              {tag.name}
                            </TagName>
                            <Count>
                              {this.props.exercise ? tag.exercisesCount : tag.worksheetsCount}
                            </Count>
                          </Tag>
                        )
                      })
                    }
                  </TagWrapper>
                </TagCategoryWrapper>
              )
            }
          })
        }
        {
          this.showWarning &&
            <Alert>{`Some selected tags do not appear in the worksheet filters on the ${this.props.worksheet.podcastEpisode ? 'podcast' : 'videos'} page.`}</Alert>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TagEdit);
