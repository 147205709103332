import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import DatePicker from 'react-datepicker';
import autobind from 'autobind-decorator';
import { Api } from '@seedlang/stores';
import pluralize from 'pluralize';
import { isString } from 'lodash';
import { isPresent } from '@seedlang/utils';
import styled from "@emotion/styled";

const Label = styled.label`

`;

@observer
class InPlaceDatePicker extends React.Component {
  @observable selectedDate;

  static defaultProps = {
    isClearable: true,
  }

  @computed get value() {
    if (this.selectedDate || this.props.value) {
      if (isString(this.props.value)) {
        return Date.parse(this.props.value);
      } else if (this.props.value && this.props.value.date) {
        return Date.parse(this.props.value.date);
      } else if (isPresent(this.selectedDate)) {
        return this.selectedDate;
      } else {
        return '';
      }
    } return '';
  }

  @autobind onChange(date) {
    this.selectedDate = date;
    if (this.props.onChange) {
      this.props.onChange(date);
    } else {
      const data = {};
      data.data = {};
      data.data[this.props.field] = date ? new Date(date) : null;
      Api.put({
        url: `/api/${pluralize(this.props.model)}/${this.props.id}`,
        data: data,
        successCallback: this.updateSuccess.bind(this),
      });
    }
  }

  @autobind updateSuccess(resp) {
    this.lastValue = this.value;
    if (this.props.updateCallback) {
      this.props.updateCallback(resp);
    }
    if (this.props.afterChange) {
      this.props.afterChange(this.value);
    }
  }

  render() {
    return (
      <div className='in-place-date-picker'>
        {
          this.props.label &&
            <Label>
              {this.props.label}
            </Label>
        }
        <DatePicker
          selected={this.value}
          onChange={this.onChange}
          showTimeSelect={this.props.withTime}
          timeIntervals={this.props.timeIntervals}
          dateFormat={this.props.withTime ? "Pp" : undefined}
          todayButton={this.props.showTodayButton ? "Today" : null}
          isClearable={this.props.isClearable}
        />
      </div>
    );
  }
}

export default InPlaceDatePicker;
