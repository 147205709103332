import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  img {
    width: 26px;
  }
`;

@observer
class Emoticon extends React.Component {

  render() {
    return (
      <Wrapper>
        <img src={`/images/emoticons/${this.props.image}`} alt="emoticon" />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Emoticon);
