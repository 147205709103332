import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import Navigation from 'components/navigation';
import { last } from 'lodash';

@observer
class ProducerNavigation extends React.Component {

  render() {
    return (
      <div className='producer-navigation'>
        <Navigation
          currentRoute={last(this.props.routes).name}
          links={[
            {
              link: 'creator.producer.concepts.index',
              title: 'Concepts',
            },
            {
              link: 'creator.producer.sentences.index',
              title: 'Sentences',
            },
            {
              link: 'creator.producer.words.index',
              title: 'Words',
              params: {page: 1},
            },
            {
              link: 'creator.producer.video_clips.index',
              title: 'Video Clips',
            },
            {
              link: 'creator.producer.recording_sessions.index',
              title: 'Recording Sessions',
            },
          ]}
        />
        {this.props.children}
      </div>
    );
  }
}

export default ErrorBoundary(ProducerNavigation);
