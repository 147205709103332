import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { CrownIcon } from '@seedlang/icons';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  background: ${props => props.disabled ? '#b7b7b7' : props.background};
  color: ${props => props.disabled ? '#dadada' : 'white'};
  border-radius: 25px;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 0 20px;
  margin-left: 10px;
  font-weight: bold;
  border: 3px solid white;
  flex-direction: column;
  height: ${props => props.height};
  .crown-icon {
    height: 14px;
  }
  .icon-wrapper {
    width: 20px;
    margin-right: 5px;
    svg {
      fill: ${Theme.yellow};
    }
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

@observer
class EmphasisButton extends React.Component {
  static defaultProps = {
    showIcon: true,
    background: 'linear-gradient(-134deg, rgba(59, 215, 197, 0.8) 0%, #0DAFF6 100%)',
  }

  render() {
    return (
      <Wrapper
        height={this.props.height}
        background={this.props.background}
        disabled={this.props.disabled}
      >
        <InnerWrapper>
          {this.props.showIcon && <CrownIcon fill={this.props.disabled ? '#caca87' : null} />}
          {this.props.children}
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(EmphasisButton);
