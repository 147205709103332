import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import autobind from 'autobind-decorator';
import { isBlank } from '@seedlang/utils';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { AppUI } from '@seedlang/state';
import { noop } from 'lodash';
import Modal from 'components/modal';
import { isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  line-height: 24px;
  width: 100%;
  height: 100%;
`;

const Button = styled.div`
  background: ${props => props.background};
  color: ${props => props.color};
  width: ${props => props.width};
  margin: ${props => props.margin};
  padding: 5px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 10px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  &:active {
    transform:scale(0.98);
  }
`;

const ButtonWrapper = styled.div`
  font-size: 16px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  @media only screen and (max-width : 649px) {
    flex-direction: column;
  }
`;

const Text = styled.div`
  text-align: left;
  padding: ${props => props.padding};
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Terms = styled.div`
  margin-top: 10px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

@observer
class MissingAgreement extends React.Component {
  @observable hide = false;
  @observable userAgreedToTerms = AppUI.user.currentUserGroup?.userAgreedToTerms;
  @observable userAgreedToEmail = isPresent(AppUI.user.currentUserGroup?.userAgreedToEmail) && AppUI.user.currentUserGroup?.userAgreedToEmail !== 'unset';

  @computed get showModal() {
    return !this.hide && !AppUI.siteIsDefault && this.missingTerms;
  }

  @computed get missingTerms() {
    return AppUI.userIsLoaded && !AppUI.user.guest && (!this.userAgreedToTerms || !this.userAgreedToEmail);
  }

  @autobind onClick(field, value) {
    this[field] = value;
    const data = {};
    data[field] = value;
    if (field === 'userAgreedToEmail') {
      this.userAgreedToEmail = true;
    }
    if (field === 'userAgreedToTerms') {
      this.userAgreedToTerms = true;
    }
    AppUI.userGroupStore.update({ids: {userGroupId: AppUI.user.currentUserGroup?.id}, data: data}, AppUI.loadUser);
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!this.missingTerms) {
      this.hide = true;
    }
  }

  render() {
    if (this.showModal) {
      return (
        <Modal
          width={AppUI.layout.isMobile ? '90%' : "500px"}
          height="fit-content"
          alignItems="center"
          justifyContent="center"
        >
          {
            this.userAgreedToTerms && !this.userAgreedToEmail &&
              <Wrapper>
                <Title>
                  Sending Emails
                </Title>
                <Text>
                  <div>{`Stay in the loop with the latest from ${AppUI.site.nameWithoutMembership}!`}</div>
                  <div>Allow us to send you email notifications whenever there’s a new post or important information about your membership.</div>
                  <div>Don’t worry, you can opt out at any time if you wish!</div>
                </Text>
                <ButtonWrapper>
                  <Button
                    background={AppUI.site.accentColor}
                    color="#FFF"
                    onClick={() => this.onClick('userAgreedToEmail', true)}
                    width="300px"
                  >
                    <i className='fa fa-check' style={{marginRight: '5px'}}/>
                    Send Me Emails
                  </Button>
                  <Button
                    background={AppUI.site.accentColor}
                    color="#FFF"
                    onClick={() => this.onClick('userAgreedToEmail', false)}
                    width="300px"
                  >
                    <i className='fa fa-times' style={{marginRight: '5px'}}/>
                    Don't Send Me Emails
                  </Button>
                </ButtonWrapper>
              </Wrapper>
          }
          {
            !this.userAgreedToTerms &&
              <Wrapper>
                <Title>
                  Privacy and Terms of Service
                </Title>
                <Terms>
                  <span>By continuing, I agree to </span>
                  <span
                    dangerouslySetInnerHTML={{__html: AppUI.site.whiteLabelTermsString}}
                  />
                  <span> the <a className='underline' href='https://www.seedlang.com/terms' target='_blank'>Terms</a> & <a className='underline' href='https://www.seedlang.com/privacy' target='_blank'>Privacy Policy</a> of the learning platform Seedlang.</span>
                </Terms>
                <ButtonWrapper>
                  <Button
                    background={AppUI.site.accentColor}
                    color="#FFF"
                    onClick={() => this.onClick('userAgreedToTerms', true)}
                    width="300px"
                    margin="0 5px 0 0"
                  >
                    <i className='fa fa-check' style={{marginRight: '5px'}} />
                    I Agree To Terms
                  </Button>
                </ButtonWrapper>
              </Wrapper>
          }
        </Modal>
      )
    }
    return <span />
  }
}

export default MissingAgreement;
