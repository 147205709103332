import React from 'react';
import { observer } from 'mobx-react';
import { JobStore, RouteStore, SiteStore } from '@seedlang/stores';
import { observable } from 'mobx';

@observer
class JobCreate extends React.Component {
  @observable siteId = '';

  componentDidMount() {
    SiteStore.getIndex();
  }

  onClick() {
    JobStore.create(
      {
        data: {
          title: this.refs.title.value,
          site_id: this.refs.site_id.value,
        },
      }, this.afterCreate.bind(this));
    this.refs.title.value = '';
  }

  onSelect(e) {
    this.siteId = e.target.value;
  }

  afterCreate(resp) {
    if (this.props.routeToAfterCreate) {
      RouteStore.routeToNamed(this.props.routeToAfterCreate, Object.assign({}, {jobId: resp.jobId}, this.props.routeParams));
    } else if (resp.jobId && this.props.routeToName) {
      RouteStore.routeToNamed(this.props.routeToName, Object.assign({jobId: resp.jobId}, this.props.routeIds));
    } else if (resp.jobId) {
      RouteStore.routeTo(`/builder/jobs/${resp.jobId}/edit`);
    } else {
      this.error = true;
    }
  }

  render() {
    return (
      <div className="page-create">
        {
          this.error &&
          <div className='row'>
            <div className="col-xs-12">
              <div className='alert'>Sorry, there was an error</div>
            </div>
          </div>
        }
        <input
          placeholder="Add an job title"
          ref="title"
        />
        <select
          placeholder="Select site"
          disabled={!!this.props.siteId}
          onChange={this.onSelect.bind(this)}
          defaultValue={this.props.siteId}
          style={{ width: '100%', margin: '5px 0', visibility: this.props.siteId ? 'hidden' : 'auto'}}
          ref="site_id">
          {
            SiteStore.indexData.map(item => {
            return (
              <option value={item.id} key={item.id}>{item.name}</option>
            );
          })}
        </select>
        <div>
          <button
            className="button-primary"
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default JobCreate;
