import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import ExerciseInputOptions from 'pages/creator/exercises/exercise_input_options';
import ExerciseInputEnter from 'pages/creator/exercises/exercise_input_enter';
import ExerciseMatchText from 'pages/creator/exercises/exercise_match_text';
import ExerciseMatchMedia from 'pages/creator/exercises/exercise_match_media';
import ExerciseReorder from 'pages/creator/exercises/exercise_reorder';
import ExerciseHighlight from 'pages/creator/exercises/exercise_highlight';
import ExerciseSentenceEnter from 'pages/creator/exercises/exercise_sentence_enter';
import ExerciseMultipleChoice from 'pages/creator/exercises/exercise_multiple_choice';

// new components need to be added to /app/views/worksheets/*.haml
const COMPONENTS = {
  matchMedia: ExerciseMatchMedia,
  matchText: ExerciseMatchText,
  reorder: ExerciseReorder,
  inputEnter: ExerciseInputEnter,
  inputDrag: ExerciseInputOptions,
  inputOptions: ExerciseInputOptions,
  sentenceEnter: ExerciseSentenceEnter,
  highlight: ExerciseHighlight,
  multipleChoice: ExerciseMultipleChoice,
};

@observer
class ExerciseEntryIndex extends React.Component {

  render() {
    return (
      <div className='exercise-entry-index'>
        {
          COMPONENTS[this.props.exerciseType.slug] && React.createElement(COMPONENTS[this.props.exerciseType.slug], {
            afterUpdate: this.props.afterUpdate,
            exercise: this.props.exercise,
            hideTableHeader: this.props.hideTableHeader,
            allowTargetInput: this.props.allowTargetInput,
            hidePosition: this.props.hidePosition,
            languageId: this.props.languageId,
            hideInstruction: this.props.hideInstruction,
            showGrammarTip: this.props.showGrammarTips,
            inDeck: this.props.inDeck
          })
        }
      </div>
    );
  }
}

export default ErrorBoundary(ExerciseEntryIndex);
