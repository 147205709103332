import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { JobStore } from '@seedlang/stores';
import { computed } from 'mobx';
import autobind from 'autobind-decorator';
import TextDisplay from 'components/text_display';
import ErrorBoundary from 'components/hoc/error_boundary.js';

const Wrapper = styled.div`
  padding: 40px;
  background: white;
  border-radius: 10px;
  position: relative;
  h2, h3, h1 {
    color: #333;
    line-height: 34px;
    margin-bottom: 20px!important;
  }
  ul {
    margin: 0 0 40px 0;
    li {
      list-style-type: disc;
      margin: 0 0 5px 20px;
    }
  }
  p {
    margin: 0 0 40px 0;
  }
`;

@observer
class JobShow extends React.Component {
  componentDidMount() {
    this.getJob();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.routeParams.jobId !== prevProps.routeParams.jobId) {
      this.getJob();
    }
  }

  componentWillUnmount() {
    JobStore.clearShowData();
  }

  @computed get job() {
    return JobStore.showData;
  }

  @autobind getJob() {
    JobStore.getShow({ ids: { jobId: this.props.routeParams.jobId }});
  }

  render() {
    return (
      <Wrapper>
        <center><h1>{this.job.title}</h1></center>
        { this.job.bodyMd &&
          <TextDisplay
            markdown={String(this.job.bodyMd)}
          />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(JobShow);
