import React from 'react';
import { observer } from 'mobx-react';
import { TreeStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import TreeCreate from 'pages/builder/trees/tree_create';


@observer
class TreeIndex extends React.Component {

  componentDidMount() {
    TreeStore.getIndex();
  }

  componentWillUnmount() {
    TreeStore.clearIndexData();
  }

  @autobind onDelete(id) {
    TreeStore.destroy({ids: {treeId: id}});
  }

  render() {
    return (
      <div
        className='tree-index'
        style={{marginTop: 20}}
      >
        <TreeCreate />
        <table className="table-wrapper admin-table">
          <thead>
            <tr>
              <th width="20" />
              <th>Name</th>
              <th>Slug</th>
              <th>Version</th>
              <th>Rows</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              TreeStore.indexData.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.trees.edit', params: { treeId: item.id } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>
                      <div className="text">
                        {item.name}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.slug}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.version}
                      </div>
                    </td>
                    <td>
                      {item.treeRowsCount}
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message="Delete this tree?"
                        className="fa-block right"
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={TreeStore}
        />
      </div>
    );
  }
}

export default TreeIndex;
