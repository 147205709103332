import React from 'react';
import { observer } from 'mobx-react';
import { ChatStore } from '@seedlang/stores';
import ChatCreate from 'pages/builder/chats/chat_create';
import { Link } from 'react-router';
import autobind from 'autobind-decorator';
import DeleteButton from 'components/button/delete_button';

@observer
class ChatIndex extends React.Component {

  constructor(props) {
    super(props)
    ChatStore.getNonFeedbackIndex({});
  }

  @autobind onDelete(id) {
    ChatStore.destroy({ids: {chatId: id}});
  }

  render() {
    return (
      <div className='chat-builder-index'>
        <ChatCreate />
        <table className="table-wrapper admin-table">
          <thead>
            <tr>
              <th width="20" />
              <th>Updated</th>
              <th>Title</th>
              <th>Participants</th>
              <th width="40"></th>
            </tr>
          </thead>
          <tbody>
            {
              ChatStore.indexData.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.chats.show', params: { chatId: item.id } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td width='130'>
                      <div className='text'>
                        {item.createdAt ? item.createdAt.formatted : null}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.title}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.userList}
                      </div>
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message="Delete this chat?"
                        className="fa-block right"
                      />
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}

export default ChatIndex;
