import React from 'react';
import Navigation from 'components/navigation';
import { last } from 'lodash';

class Site extends React.Component {

  render() {
    return (
      <div className="site">
        <Navigation
          currentRoute={last(this.props.routes).name}
          smallButtons
          links={[
            {
              link: 'builder.site.index',
              title: 'Site',
            },
            {
              link: 'builder.page.index',
              title: 'Pages',
            },
            {
              link: 'builder.job.index',
              title: 'Jobs'
            },
            {
              link: 'builder.faq_categories.index',
              title: 'Help Center',
            },
            {
              link: 'builder.site_releases.index',
              title: 'Site Releases',
            },
            {
              link: 'builder.banner_notices.index',
              title: 'Banner Notices',
            },
            {
              link: 'builder.groups.index',
              title: 'Groups',
            },
            {
              link: 'builder.forums.index',
              title: 'Forums',
            },
            {
              link: 'builder.error_logs.index',
              title: 'Error Logs',
            },
          ]}
        />
        {this.props.children}
      </div>
    );
  }
}

export default Site;
