import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { GrammarIndexWrapper, GrammarWrapper } from "@seedlang/hoc";
import { AppUI } from "@seedlang/state";
import GrammarIndexModule from 'components/grammar/grammar_index_module';

const Wrapper = styled.div`
`;

@observer
class GrammarIndex extends React.Component {

  render() {
    return (
      <Wrapper>
          {
            this.props.tree && this.props.tree.treeModules.map((module) => {
              if ((module.webPublished && module.hasWebPublishedTreeNodes) || AppUI.user.admin) {
                return (
                  <GrammarIndexModule
                    key={module.id}
                    module={module}
                    {...this.props}
                  />
              )}
            })
          }
      </Wrapper>
    );
  }
}

export default GrammarWrapper(GrammarIndexWrapper(GrammarIndex));
