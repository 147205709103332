import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid #A2A2A2;
  border-radius: 5px;
  padding: 0px 7px;
  background-color: white;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 25px;
  height: 30px;
  margin: 0 1px;
`;

@observer
class FilterButton extends React.Component {

  render() {
    return (
      <Wrapper
        onClick={this.props.onClick}
      >
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(FilterButton);
