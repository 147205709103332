import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { ForumUI, AppUI } from '@seedlang/state';
import { CheckWithCircleIcon } from '@seedlang/icons';
import { Theme } from '@seedlang/constants';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`
  .check-with-circle-icon {
    svg {
      fill: ${Theme.green};
      width: 20px;
    }
  }
`;

const Row = styled.div`
  cursor: pointer;
  margin: 5px 0;
  border-bottom: 1px solid gray;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
`;

const Title = styled.div`

`;

const IconWrapper = styled.div`
  width: 40px;
`;

@observer
class MoveThread extends React.Component {
  @observable forumId;

  constructor(props) {
    super(props);
    if (!ForumUI.forumStore.hasIndexData) {
      ForumUI.forumStore.getIndex();
    }
  }

  @autobind onClick(forumId) {
    this.forumId = forumId;
    const data = {forum_id: forumId};
    ForumUI.forumThreadStore.update({ids: {forumThreadId: ForumUI.forumThread.id}, data: data}, this.afterUpdate)
  }

  @autobind afterUpdate() {
    this.props.onChange();
    AppUI.routeStore.routeToNamed('forums.show', {page: 1, forumId: this.forumId});
  }

  render() {
    return (
      <Wrapper>
        <h3>Move Thread</h3>
        {
          ForumUI.forumStore.indexData.map(item => {
            if (item.title !== 'All Forums') {
              return (
                <Row
                  key={item.id}
                  onClick={() => this.onClick(item.id)}
                >
                  <IconWrapper>
                    {
                      ForumUI.forumThread.forumId === item.id &&
                        <CheckWithCircleIcon />
                    }
                  </IconWrapper>
                  <Title>
                    {item.title}
                  </Title>
                </Row>
              )
            } else {
              return null;
            }
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(MoveThread);
