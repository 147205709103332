import React from 'react';
import { observer } from 'mobx-react';
import { LanguageStore, SiteStore, WordTypeStore } from '@seedlang/stores';
import Text from "components/text";
import styled from "@emotion/styled";
import autobind from "autobind-decorator";
import DeleteButton from "components/button/delete_button";
import Checkbox from "components/checkbox";
import { isBlank, isPresent } from "@seedlang/utils";
import InPlaceText from "components/form/in_place_text";
import ReactTooltip from 'react-tooltip';

const WordTypeRow = styled.div`
  display: flex;
  margin-bottom: 2px;
  align-items: center;
`;

const AddButton = styled.div`
  cursor: pointer;
`;

@observer
class LanguageEdit extends React.Component {

  constructor(props) {
    super(props);
    this.loadLanguage();
    WordTypeStore.getIndex();
    SiteStore.getIndex();
  }

  @autobind loadLanguage() {
    LanguageStore.getShow({ids: {languageId: this.props.params.languageId}});
  }

  @autobind onToggleWordType(wordType) {
    if (LanguageStore.hasShowData && !(this.props.whiteLabeled && LanguageStore.showData.hasLessons)) {
      WordTypeStore.toggleLanguage({ids: {wordTypeId: wordType.id, languageId: this.props.params.languageId}}, WordTypeStore.getIndex);
    }
  }

  @autobind toggleGender(gender) {
    LanguageStore.toggleGender({ids: {languageId: this.props.params.languageId, gender: gender}}, this.loadLanguage);
  }

  render() {
    return (
      <div>
        <div className='row'>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Name</legend>
                <Text>{LanguageStore.showData.name}</Text>
            </fieldset>
          </div>
          {
            !this.props.whiteLabeled &&
              <div className='col-xs-4'>
                <fieldset>
                  <legend>Sites</legend>
                  <ul>
                    {
                      SiteStore.hasIndexData && SiteStore.indexData.filter(item => item.languageId === this.props.params.languageId).map(item => {
                        return (
                          <li
                            key={item.id}
                          >
                            <a href={`https://${item.domainString}`} target="_blank" rel="noopener noreferrer">
                              {item.name}
                            </a>
                          </li>
                        )
                      })
                    }
                  </ul>
                </fieldset>
              </div>
          }
        </div>
        <div className='row'>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Word Types</legend>
              <Text fontSize="14px" margin="0 0 5px 0">These are the word types that can be selected when entering vocab lists.</Text>
              {
                LanguageStore.hasShowData && this.props.whiteLabeled && LanguageStore.showData.hasLessons &&
                  <Text fontSize="12px" margin="0 0 5px 0">The word types for this language cannot be modified.</Text>
              }
              {
                WordTypeStore.hasIndexData && WordTypeStore.indexData.filter(item => item.root && item.wordOption).map(item => {
                  return (
                    <WordTypeRow
                      key={item.id}
                    >
                      <Checkbox
                        width="15px"
                        height="15px"
                        margin="0 5px 0 0"
                        onClick={() => this.onToggleWordType(item)}
                        value={item.languagesString && item.languagesString.includes(`|${this.props.params.languageId}|`)}
                        showLock={LanguageStore.hasShowData && this.props.whiteLabeled && LanguageStore.showData.hasLessons}
                      />
                      {item.name}
                    </WordTypeRow>
                  )
                })
              }
            </fieldset>
          </div>
          <div className='col-xs-5'>
            <fieldset>
              <legend>Genders</legend>
              <table className="table-wrapper admin-table">
                <thead>
                  <tr>
                    <th>Gender</th>
                    <th>Singular article</th>
                    <th>Plural article</th>
                    {
                      !(this.props.whiteLabeled && LanguageStore.showData.hasLessons) &&
                        <th/>
                    }
                  </tr>
                </thead>
                <tbody>
                {
                  LanguageStore.hasShowData && ['feminine','masculine','neuter'].map(item => {
                    const gender = LanguageStore.showData.genders.find(i => i.gender === item);
                    return (
                      <tr
                        key={item}
                      >
                        <td>
                          <div style={{color: isPresent(gender) ? null : '#cbc8c8'}}>
                            {item}
                          </div>
                        </td>
                        <td>
                          {
                            gender && !(this.props.whiteLabeled && LanguageStore.showData.hasLessons) &&
                              <InPlaceText
                                id={gender.id}
                                submitOnEnter
                                defaultValue={gender.singularArticle}
                                model="genders"
                                field="singular_article"
                              />
                          }
                          {
                            this.props.whiteLabeled && LanguageStore.showData.hasLessons &&
                              gender.singularArticle
                          }
                        </td>
                        <td>
                          {
                            gender && !(this.props.whiteLabeled && LanguageStore.showData.hasLessons) &&
                              <InPlaceText
                                id={gender.id}
                                submitOnEnter
                                defaultValue={gender.pluralArticle}
                                model="genders"
                                field="plural_article"
                              />
                          }
                          {
                            this.props.whiteLabeled && LanguageStore.showData.hasLessons &&
                              gender.pluralArticle
                          }
                        </td>
                        {
                          !(this.props.whiteLabeled && LanguageStore.showData.hasLessons) &&
                          <td>
                            {
                              isPresent(gender) &&
                              <DeleteButton
                                onConfirm={() => this.toggleGender(item)}
                                message={"Remove this gender?"}
                                className="fa-block right"
                              />
                            }
                            {
                              isBlank(gender) &&
                              <AddButton
                                onClick={() => this.toggleGender(item)}
                                data-tip
                                data-for={`add-gender-${item}`}
                              >
                                <i className='fa fa-plus'/>
                                <ReactTooltip
                                  place="left"
                                  type="dark"
                                  effect="solid"
                                  id={`add-gender-${item}`}
                                  class="custom-tooltip"
                                >
                                  {`Add ${item} gender`}
                                </ReactTooltip>
                              </AddButton>
                            }
                          </td>
                        }
                      </tr>
                    )
                  })
                }
                </tbody>
              </table>
              <Text fontSize="12px" margin="0 0 5px 0">These are the default articles that are displayed before nouns in the vocab list.</Text>
            </fieldset>
            <fieldset>
              <legend>Special Characters</legend>
              {
                LanguageStore.hasShowData &&
                  <Text fontSize="14px" margin="0 0 5px 0">{`${LanguageStore.showData.name} characters that users might not have on their keyboard.`}</Text>
              }
              {
                LanguageStore.hasShowData && this.props.whiteLabeled && LanguageStore.showData.hasLessons &&
                <Text>{LanguageStore.showData.specialCharacters}</Text>
              }
              {
                LanguageStore.hasShowData && !(this.props.whiteLabeled && LanguageStore.showData.hasLessons) &&
                  <InPlaceText
                    submitOnEnter
                    show={isBlank(LanguageStore.showData.specialCharacters)}
                    id={LanguageStore.showData.id}
                    defaultValue={LanguageStore.showData.specialCharacters}
                    model="languages"
                    field="special_characters"
                    afterChange={this.loadLanguage}
                    maxLength={20}
                  />
              }
              {
                LanguageStore.hasShowData && !(this.props.whiteLabeled && LanguageStore.showData.hasLessons) &&
                  <Text fontSize="12px" margin="0 0 5px 0">Please enter characters without any spaces or punctuation between characters.</Text>
              }
              {
                LanguageStore.hasShowData && this.props.whiteLabeled && LanguageStore.showData.hasLessons &&
                  <Text fontSize="12px" margin="0 0 5px 0">The special characters for this language cannot be edited.</Text>
              }
            </fieldset>
          </div>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Verb Structure components</legend>
              <Text fontSize="14px" margin="0 0 5px 0">{`Keywords used in verb structures in ${LanguageStore.showData.name}.`}</Text>
              {
                LanguageStore.hasShowData && this.props.whiteLabeled && LanguageStore.showData.hasLessons &&
                <Text>{LanguageStore.showData.wordStructureComponents}</Text>
              }
              {
                LanguageStore.hasShowData && !(this.props.whiteLabeled && LanguageStore.showData.hasLessons) &&
                <InPlaceText
                  submitOnEnter
                  inputType="textarea"
                  show={isBlank(LanguageStore.showData.wordStructureComponents)}
                  id={LanguageStore.showData.id}
                  defaultValue={LanguageStore.showData.wordStructureComponents}
                  model="languages"
                  field="word_structure_components"
                  afterChange={this.loadLanguage}
                  maxWidth="250px"
                />
              }
              <Text fontSize="12px" margin="0 0 5px 0">Separated by commas, with optional short version in between brackets: something[sth],somebody[sb],with,against</Text>
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default LanguageEdit;
