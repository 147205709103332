import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { DeckUI } from '@seedlang/state';
import TriviaCardPlayer from 'components/trivia_card/trivia_card_player';
import { pixify } from '@seedlang/utils';

const Wrapper = styled.div`
  height: ${(props) => props.height};
  display: flex;
  align-items: center;
  width: 100%;
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
`;

@observer
class TriviaCardPlayers extends React.Component {

  @computed get triviaPlayerParams() {
    return {
      nameFontSize: 15,
      showScore: true,
      hideProStatus: true,
      timerWidth: DeckUI.layout.triviaPlayersHeight,
      userProfileImageWidth: DeckUI.layout.triviaPlayersHeight - 10,
    }
  }

  render() {
    return (
      <Wrapper
        height={pixify(DeckUI.layout.triviaPlayersHeight + 20)}
      >
        <Left>
          <TriviaCardPlayer
            {...this.triviaPlayerParams}
            showTimer={this.props.showTimer}
            turn={this.props.myTurn}
            user={DeckUI.user}
            points={DeckUI.userDeck.points}
            flexDirection="row"
          />
        </Left>
        <Right>
          {
            this.props.hasOpponent && !DeckUI.reviewDeck &&
              <TriviaCardPlayer
                {...this.triviaPlayerParams}
                showTimer={this.props.showTimer}
                turn={this.props.opponentTurn}
                user={this.props.opponent}
                points={this.props.opponentPoints}
                flexDirection="row-reverse"
              />
          }
        </Right>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TriviaCardPlayers);
