import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Leaderboard from 'components/leaderboard/leaderboard';
import LanguageIndex from 'components/language/language_index';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  width: 100%;
  min-height: 530px;
`;

const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
`;

const Button = styled.div`
  color: ${(props) => props.selected ? "#FFF" : Theme.green};
  background: ${(props) => props.selected ? Theme.green : "#FFF"};
  border: 1px solid ${Theme.green};
  font-size: 11px;
  line-height: 15px;
  padding: 2px 10px;
  border-radius: 25px;
  cursor: pointer;
  margin: 0 2px;
`;

@observer
class SourceCounts extends React.Component {
  @observable display = 'byUser';

  render() {
    return (
      <Wrapper>
        <Navigation>
          <Button
            selected={this.display === 'byUser'}
            onClick={() => this.display = 'byUser'}
          >
            By User
          </Button>
          <Button
            selected={this.display === 'byLanguage'}
            onClick={() => this.display = 'byLanguage'}
          >
            By Language
          </Button>
        </Navigation>
        {
          this.display === 'byUser' &&
            <Leaderboard
              hideNavigation
              hideYourRanking
              showLanguageIcon
              sortColumn="translation_sources_count"
              totalColumn="translation_sources_count"
              rankingFields={{"translation_sources_count": "translationSourcesCount"}}
            />
        }
        {
          this.display === 'byLanguage' &&
            <LanguageIndex />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SourceCounts);
