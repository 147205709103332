import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import styled from '@emotion/styled';
import DeleteButton from 'components/button/delete_button';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { VideoClipStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import VideoPlayersWithType from 'pages/builder/video_clips/video_players_with_type';
import Copy from 'components/copy';
import Filter from 'components/backend_filter/filter';
import Paginator from 'components/paginator';
import SortableColumns from 'components/hoc/sortable_columns';
import { Link } from 'react-router';
import { AppUI } from '@seedlang/state';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  i {
    margin-right: 5px;
  }
  .fa-exchange {
    transform: rotate(0deg);
  }
`;

@observer
class VideoClipIndex extends React.Component {

  componentWillUnmount() {
    if (!this.props.videoClips) {
      VideoClipStore.clearIndexData();
    }
  }

  @computed get deleteMessage() {
    let msg = 'Delete this video clip?';
    if (!this.userIsMe) {
      msg = `${msg} This will also delete related clips if they exist.`
    }
    return msg;
  }

  @computed get userIsMe() {
    return this.props.userId === AppUI.user.id;
  }

  @computed get videoClips() {
    return this.props.videoClips || VideoClipStore.indexData;
  }

  @autobind onDelete(id) {
    if (this.props.onDelete) {
      this.props.onDelete(id);
    } else {
      VideoClipStore.destroy({queryStrings: {destroy_related: !this.userIsMe}, ids: {videoClipId: id}}, this.afterDelete);
    }
  }

  @autobind afterDelete() {
    if (this.props.afterChange) {
      this.props.afterChange();
    } else {
      VideoClipStore.reloadIndexFiltered();
    }
  }

  render() {
    return (
      <div className="video-clip-index">
        {
          !this.props.videoClips &&
            <Filter
              store={VideoClipStore}
              defaultFilters={this.props.recordingSessionId ? {recording_session_id: this.props.recordingSessionId} : null}
              defaultSort="-created_at"
              namespace="video-clip-index"
              margin="0 0 20px 0"
              filters={
                [
                  {
                    type: 'multi_select',
                    name: 'original_extension',
                    label: 'Filetype',
                    options: [{id: 'mp4', name: 'mp4'}, {id: 'webm', name: 'webm'}],
                  },
                  {
                    type: 'boolean',
                    name: 'replace_video',
                    label: 'Replace',
                  },
                  {
                    type: 'boolean',
                    name: 'reviewed',
                    label: 'Approved',
                  },
                ]
              }
            />
        }
        <table className="table-wrapper">
          <thead>
            <tr>
              <th width="50" />
              <th width="20" />
              {
                !this.props.simplified &&
                  <th width="70"></th>
              }
              <th width="170"></th>
              <th>
                Info
              </th>
              <th>
                Loading Info
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              this.videoClips.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.video_clips.edit', params: { videoClipId: item.id } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>
                      <Copy
                        text={item.id}
                      />
                    </td>
                    {
                      !this.props.simplified &&
                        <td>
                          <div className="text" style={{paddingLeft: "30px", fontSize: "25px"}}>
                            {
                              item.videoable &&
                                <IconWrapper>
                                  <InPlaceCheckbox
                                    model="video_clips"
                                    field="reviewed"
                                    id={item.id}
                                    value={item.reviewed}
                                    icon="thumbs-up"
                                    afterChange={this.props.afterChange}
                                  />
                                  <div style={{fontSize: '12px'}}>Approved</div>
                                </IconWrapper>
                            }
                            {
                              item.videoable &&
                                <IconWrapper>
                                  <InPlaceCheckbox
                                    model="targets"
                                    field="replace_video"
                                    id={item.videoable.id}
                                    value={item.videoable.replaceVideo}
                                    icon="exchange"
                                    afterChange={this.props.afterChange}
                                  />
                                  <div style={{fontSize: '12px'}}>Replace</div>
                                </IconWrapper>
                            }
                          </div>
                        </td>
                    }
                    <td>
                      {
                        item['url'] &&
                          <video
                            muted={this.props.muted}
                            autoPlay={this.props.autoPlay}
                            loop={this.props.loop}
                            playsInline
                            src={item['url'] || item.webmUrl || item.mp4Url}
                            width="150"
                            controls="true"
                          />
                      }
                      {
                        !item['url'] &&
                          <VideoPlayersWithType
                            muted={this.props.muted}
                            autoPlay={this.props.autoPlay}
                            loop={this.props.loop}
                            videoClip={item}
                            width="150px"
                          />
                      }
                    </td>
                    <td>
                      <div><b>{item.videoable?.text}{item.cleared && " (Cleared)"}</b></div>
                      {
                        item.decoratedCreatedAt &&
                          <div>Created: {item.decoratedCreatedAt.formatted}</div>
                      }
                      <div>Name: {item.user?.name}</div>
                      <div>FileType: {item.originalExtension}</div>
                      <div>Size: {item.formattedFileSize}</div>
                    </td>
                    <td>
                      <div>Average Load (Android): {item.averageLoadTimeAndroid}</div>
                      <div>Average Load (iOS): {item.averageLoadTimeIos}</div>
                      <div>Timed Out (Android): {item.numberTimedOutAndroid}</div>
                      <div>Timed Out (iOS): {item.numberTimedOutIos}</div>
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message={this.deleteMessage}
                        className="fa-block right"
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        {
          !this.props.videoClips &&
            <Paginator
              store={VideoClipStore}
            />
        }
      </div>
    );
  }
}

export default SortableColumns(VideoClipIndex, VideoClipStore, { sortColumn: 'created_at', sortIsAscending: false });
