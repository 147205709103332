import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import PropTypes from 'prop-types';
import { Api } from '@seedlang/stores';
import pluralize from 'pluralize';
import autobind from 'autobind-decorator';
import { UrlBuilder } from '@seedlang/models';
import { isPresent } from '@seedlang/utils';
import Spinner from 'components/spinner';
import styled from '@emotion/styled';
import Alert from "components/alert";

const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  input {
    margin: ${props => props.margin};
  }
`;

const Image = styled.div`
  background-size: cover;
  background-position: center center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-image: ${(props) => props.backgroundImage};
`;

const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;
  .fa-times {
    position: absolute;
    right: 0;
    background: red;
    color: white;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 24px;
    border-radius: 50%;
    font-size: 14px;
  }
  img {
    margin-top: 10px;
    max-width: 180px;
  }
`;

@observer
class InPlaceImageUpload extends React.Component {
  @observable showSpinner = false;
  @observable showErrorMessage = false;
  @observable errorMessage;
  @observable imageUrlFromResponse;

  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    model: PropTypes.string.isRequired,
    querystring: PropTypes.object
  }

  static defaultProps = {
    margin: "0 0 20px 0",
  }

  @computed get imageUrl() {
    if (this.props.image) {
      return this.props.image.url;
    } else if (this.imageUrlFromResponse) {
      return this.imageUrlFromResponse;
    }
    return null;
  }

  @autobind onMultipartPost() {
    this.showSpinner = true;

    if (this.refs.input.files[0]) {

      Api.multipartPost({
        url: new UrlBuilder().build(pluralize(this.props.model), this.props.querystring),
        data: {data: this.data()},
        successCallback: this.onChange,
      });

    }
  }

  @autobind onDelete() {
    this.showSpinner = true;

    Api.destroy({
      url: `/api/${pluralize(this.props.model)}/${this.props.id || this.props.image.id}`,
      successCallback: this.onChange,
    });
  }

  data() {
    const data = new FormData();
    data.append('blob', this.refs.input.files[0]);
    return data;
  }

  @autobind onChange(resp) {
    this.showSpinner = false;
    if (resp.success) {
      if (this.props.updateCallback) {
        this.props.updateCallback(resp);
      }
    } else {
      this.showErrorMessage = true;
      this.errorMessage = resp.errorMessage;
    }

    // Clear the file input so that if the user selects the same file again, the input's onChange event will fire
    this.refs.input.value = '';

    if (this.props.onChange) {
      this.props.onChange(resp);
    }
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        <input
          type="file"
          ref="input"
          onChange={this.onMultipartPost}
        />
        {
          isPresent(this.imageUrl) &&
            <ImageWrapper>
              <i
                className="fa fa-times fa-on-click delete-btn"
                onClick={this.onDelete.bind(this)}
              />
              {
                !this.props.circle && <img alt='' src={this.imageUrl} />
              }
              {
                this.props.circle &&
                  <Image
                    onClick={this.onClick}
                    backgroundImage={`url(${this.imageUrl})`}
                  />
              }
            </ImageWrapper>
        }
        {
          this.showErrorMessage &&
          <Alert width='100%'>{isPresent(this.errorMessage) ? this.errorMessage : "The image couln't be uploaded."}</Alert>
        }
        {
          this.showSpinner &&
            <Spinner
              className='blue'
              margin="0 auto"
            />
        }
      </Wrapper>
    );
  }
}

export default InPlaceImageUpload;
