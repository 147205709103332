import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { SentenceStore, TargetStore, WordAssociationStore, WordStore } from '@seedlang/stores';
import ModalWrapper from 'components/hoc/modal_wrapper';
import cx from 'classnames';
import DeleteButton from 'components/button/delete_button';
import { capitalize, isEmpty, orderBy } from 'lodash';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import styled from '@emotion/styled';
import { isPresent } from '@seedlang/utils';
import ReactTooltip from 'react-tooltip';
import Spinner from 'components/spinner';

const Wrapper = styled.div`
  h4 {
    font-weight: bold;
  }
  ul {
    margin: 0;
  }
  .word-swap {
    .in-place-text {
      font-size: 14px;
      input {
        height: 38px;
        padding-left: 10px;
        border: 1px solid #D1D1D1;
        border-radius: 4px;
      }
    }
  }
`;

@observer
class WordSwap extends React.Component {
  @observable wordId = this.props.wordAssociation.word.id;
  @observable targetId = this.props.wordAssociation.word.target.id;
  @observable gender = null;
  @observable plural = false;
  @observable mergingWords = false;

  constructor(props) {
    super(props);
    this.getTargets();
  }

  componentWillUnmount() {
    TargetStore.clearShowData();
  }

  @autobind getTargets() {
    TargetStore.getIndex({queryStrings: {expanded: true}, filters: {normalized_text: this.props.wordAssociation.word.targetText?.toLowerCase(), language_id: this.props.wordAssociation.word?.languageId}});
  }

  @autobind afterSwapTarget() {
    SentenceStore.reloadShow();
    this.getTargets();
  }

  @autobind onDelete(wordId) {
    WordStore.destroy({ ids: { wordId: wordId } }, this.afterSwapTarget);
  }

  @autobind onCreateWord(targetText) {
    if (isPresent(targetText)) {
      WordStore.create({ data: {
        target_text: targetText,
        language_id: this.props.wordAssociation.word.languageId
      }}, this.afterCreateWord);
    }
  }

  @autobind afterCreateWord(resp) {
    this.wordId = resp.word.id;
    this.targetId = resp.word.targetId;
    WordAssociationStore.update({ ids: { wordAssociationId: this.props.wordAssociation.id }, data: { word_id: this.wordId } }, this.afterSwapTarget);
  }

  @autobind onCreateWordFromWord(word) {
    if (word) {
      WordStore.create({ data: {
        language_id: this.props.wordAssociation.word.languageId,
        target_id: word.targetId,
        target_text: this.props.wordAssociation.word.targetText,
        level_id: word.level ? word.level.id : null,
        word_type_id: word.wordType ? word.wordType.id : null,
        gender: word.gender,
        plural: word.plural,
        root_word_id: word.rootWord && word.rootWord.id,
      }}, this.getTargets);
    }
  }

  @autobind onCreateWordFromTarget(targetId, gender) {
    WordStore.create({ data: {
      language_id: this.props.wordAssociation.word?.languageId,
      level_id: this.props.wordAssociation.word?.level?.id,
      target_id: targetId,
      target_text: gender && this.props.wordAssociation.word.languageId === 'DE' ? capitalize(this.props.wordAssociation.word.targetText) : this.props.wordAssociation.word.targetText?.toLowerCase(),
      gender: gender,
      plural: this.plural,
      word_type_id: gender ? "d5c5fcef-bb92-48a8-b00b-e7c71cb33068" : null,
    }}, this.afterCreateWordFromTarget);
  }

  @autobind afterCreateWordFromTarget() {
    this.plural = false;
    this.gender = null;
    this.getTargets();
  }

  @autobind onCreateWordForm() {
    const target = TargetStore.indexData.find(item => item.gender === this.gender);
    if (target) {
      this.onCreateWordFromTarget(target.id, this.gender);
    } else {
      TargetStore.create({data: {
        language_id: this.props.wordAssociation.word.languageId,
        gender: this.gender,
        text: this.props.wordAssociation.word.targetText,
      }}, this.afterCreateTarget);
    }
  }

  @autobind onMergeDuplicateWords() {
    this.mergingWords = true;
    WordStore.mergeDuplicateWords({ ids: {wordId: this.props.wordAssociation.word.id}}, this.afterMergeDuplicateWords);
  }

  @autobind afterMergeDuplicateWords() {
    this.mergingWords = false;
    this.getTargets();
  }

  @autobind afterCreateTarget(resp) {
    this.onCreateWordFromTarget(resp["id"], this.gender);
  }

  @autobind onSwapWord(word) {
    this.wordId = word.id;
    this.targetId = word.target.id;
    WordAssociationStore.update({ ids: { wordAssociationId: this.props.wordAssociation.id }, data: { word_id: this.wordId } }, this.afterSwapTarget);
  }

  @autobind onSwapTarget(id) {
    this.wordId = id;
    WordAssociationStore.update({ ids: { wordAssociationId: this.props.wordAssociation.id }, data: { word_id: id } }, this.afterSwapTarget);
  }

  isSelected(id) {
    if (this.wordId) {
      return this.wordId === id;
    }
    return this.props.wordAssociation.word.id === id;
  }

  render() {
    return (
      <Wrapper>
        <div className="word-swap">
          <h4>Swap Word Form</h4>
          <div className="row">
            <div className="col-xs-12">
              <table className="table-wrapper admin-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Cnt</th>
                    <th>Target</th>
                    <th>Gender</th>
                    <th>Word Type</th>
                    <th>Parent</th>
                    <th>Concepts</th>
                    <th>Level</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
              {
                TargetStore.hasIndexData && orderBy(TargetStore.indexData, ['gender'], ['asc']).map((target) => {
                  return (
                    <>
                      {
                        target.translations && target.translations.filter(item => !item.root && item.languageId === this.props.wordAssociation.word.languageId).map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>
                                <span
                                  data-for={`select-${item.id}`}
                                  data-tip
                                >
                                  <i
                                    className={cx('fa', 'fa-big', 'fa-on-click', 'fa-check-circle', {
                                      selected: this.isSelected(item.id),
                                      disabled: !this.isSelected(item.id) ,
                                    })}
                                    onClick={() => this.onSwapTarget(item.id)}
                                  />
                                  <ReactTooltip
                                    place="top"
                                    type="dark"
                                    effect="solid"
                                    id={`select-${item.id}`}
                                    class="custom-tooltip"
                                  >
                                    Select this word form
                                  </ReactTooltip>
                                </span>
                              </td>
                              <td>
                                <div className="text">
                                  {item.sentencesCount}
                                </div>
                              </td>
                              <td>
                                <div className="text">
                                  <Link
                                    to={{name: "builder.words.edit", params: {wordId: item.id}}}
                                    className='underline'
                                  >
                                    {item.targetTextWithDefiniteArticle}
                                    {item.plural && <span> (plur)</span>}
                                    {item.isNoun && !item.plural && <span> (sing)</span>}
                                  </Link>
                                </div>
                                <i>
                                  {item.sourceList}
                                </i>
                              </td>
                              <td>
                                <div className="text">
                                  {item.gender ? item.gender[0] : null}
                                </div>
                              </td>
                              <td>
                                <div className="text">
                                  {item.wordTypeName}
                                </div>
                              </td>
                              <td>
                                <div className="text">
                                  {isEmpty(item.rootWord) ? '' : item.rootWord.targetText }
                                </div>
                              </td>
                              <td>
                                <ul>
                                  {
                                    orderBy(item.concepts, ['name']).map(concept => {
                                      return (
                                        <li>{concept.name}</li>
                                      )
                                    })
                                  }
                                </ul>
                              </td>
                              <td>
                                {item.levelAbbreviation}
                              </td>
                              <td>
                                {
                                  false &&
                                    <span
                                      data-for={`clone-${item.id}`}
                                      data-tip
                                    >
                                      <i
                                        className='fa fa-copy fa-block fa-on-click'
                                        onClick={() => this.onCreateWordFromWord(item)}
                                      />
                                      <ReactTooltip
                                        place="top"
                                        type="dark"
                                        effect="solid"
                                        id={`clone-${item.id}`}
                                        class="custom-tooltip"
                                      >
                                        Clone this word form
                                      </ReactTooltip>
                                    </span>
                                }
                              </td>
                              <td>
                                {
                                  !this.isSelected(item.id) && item.sentencesCount === 0 &&
                                    <span
                                      data-for={`delete-${item.id}`}
                                      data-tip
                                    >
                                      <DeleteButton
                                        onConfirm={() => this.onDelete(item.id)}
                                        message="Delete this word?"
                                        className="fa-block right"
                                      />
                                      <ReactTooltip
                                        place="top"
                                        type="dark"
                                        effect="solid"
                                        id={`delete-${item.id}`}
                                        class="custom-tooltip"
                                      >
                                        Delete this word form
                                      </ReactTooltip>
                                    </span>
                                }
                              </td>
                            </tr>
                          );
                        })
                      }
                    </>
                  )
                })
              }
              </tbody>
            </table>
            </div>
          </div>
          <div className="row" style={{marginTop: 20}}>
            <div className="col-xs-8">
              <h4 style={{marginBottom: 20}}>Create Word Form</h4>
              <div style={{marginBottom: 10}}>
              <div style={{display: 'inline-block', width: 200, marginRight: 5}}>
              <InPlaceSelect
                height="28px"
                includeBlank
                placeholder="Select Gender"
                options={this.props.wordAssociation.word.languageId === 'DE' ? ['masculine', 'feminine', 'neuter'] : ['masculine', 'feminine']}
                onChange={(val) => this.gender = val}
                value={this.gender}
              />
              </div>
              <InPlaceCheckbox
                height="34px"
                includeBlank
                label="Plural"
                onChange={(val) => this.plural = val}
                value={this.plural}
              >
                Plural
              </InPlaceCheckbox>
              </div>
              <div>
              <button
                onClick={() => this.onCreateWordForm(false)}
                className="button-primary"
                style={{marginRight: 10, display: 'inline-block'}}
              >
                <i className='fa fa-plus' />
                Create
              </button>
              </div>
              <h4 style={{marginBottom: 0, marginTop: "20px"}}>Merge Duplicate Words</h4>
              <div style={{marginBottom: "10px", fontSize: '12px'}}>If there are duplicate words with the same attributes, you can merge them together.</div>
              {
                !this.mergingWords &&
                  <button
                    onClick={this.onMergeDuplicateWords}
                    className="button-primary"
                    style={{marginRight: 10, marginTop: 10, display: 'inline-block'}}
                  >
                    <i className='fa fa-exchange' />
                    Merge Words
                  </button>
              }
              {
                this.mergingWords &&
                  <Spinner />
              }
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default ModalWrapper(WordSwap, {width: "800px"});
