import React from 'react';
import { observer } from 'mobx-react';
import { SourceStore } from '@seedlang/stores';
import DeleteButton from 'components/button/delete_button';
import Paginator from 'components/paginator';
import cx from 'classnames';
import SortableColumns from 'components/hoc/sortable_columns';
import InPlaceText from 'components/form/in_place_text';
import { Link } from 'react-router';
import Filter from 'components/backend_filter/filter';
import { Languages } from '@seedlang/constants';
import { keys } from "lodash";

let languagesWithU = {...Languages};
languagesWithU['U'] = 'Universal';

@observer
class SourceIndex extends React.Component {

  onDelete(id) {
    SourceStore.destroy({ ids: { sourceId: id } });
  }

  render() {
    return (
      <div className="source-index">
        <Filter
          store={SourceStore}
          defaultSort="-created_at"
          namespace="target"
          queryStrings={{include_count: true}}
          filters={
            [
              {
                type: 'text',
                name: 'text',
                Label: 'Text',
                placeholder: 'Enter source text',
                default: '',
              },
              {
                type: 'select',
                name: 'language_id',
                options: keys(languagesWithU).map(abbreviation => {
                  return {id: abbreviation, name: languagesWithU[abbreviation]}
                }),
                labelField: 'name',
                label: 'Language',
                default: '',
              },
            ]
          }
        />
        <table className="table-wrapper admin-table">
          <thead>
            <tr>
              <th />
              <th
                width="120"
                className={cx('sortable', this.props.columnClassNames('createdAt'))}
                onClick={this.props.onSortColumn.bind(this, 'created_at')}
              >
                Created
              </th>
              <th
                width="40"
                className={cx('sortable', this.props.columnClassNames('translationsCount'))}
                onClick={this.props.onSortColumn.bind(this, 'translations_count')}
              >
                #
              </th>
              <th
                className={cx('sortable', this.props.columnClassNames('text'))}
                onClick={this.props.onSortColumn.bind(this, 'text')}
              >
                Source
              </th>
              <th>Target</th>
              <th>Language</th>
              <th width="40"></th>
            </tr>
          </thead>
          <tbody>
            {
              SourceStore.indexData.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.sources.edit', params: { sourceId: item.id } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>
                      <div className="text">
                        {item.createdAt.formatted}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.translationsCount}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        <InPlaceText
                          defaultValue={item.text}
                          model="sources"
                          field="text"
                          id={item.id}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.translationList}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {Languages[item.languageId]}
                      </div>
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={this.onDelete.bind(this, item.id)}
                        message="Delete this source? This will delete it for all translations."
                        className="fa-block right"
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={SourceStore}
        />
      </div>
    );
  }
}

export default SortableColumns(SourceIndex, SourceStore, { sortColumn: 'translationsCount', sortIsAscending: false });
