import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { AppUI } from '@seedlang/state';
import UserProfileImage from 'components/user/user_profile_image';
import Paginator from 'components/paginator';
import { LeaderboardWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { flexCenterColumn } from '@seedlang/style_mixins';
import Spinner from 'components/spinner';

const Wrapper = styled.div`
  ${flexCenterColumn()}
`;

const Navigation = styled.div`
  display: flex;
  padding: 10px 0;
`;

const Row = styled.div`
  background: ${(props) => props.selected ? "rgba(255, 255, 0, 0.26)" : "#FFF"};
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  display: flex;
  margin: 0;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
  width: 100%;
`;

const Position = styled.div`
  width: 30px;
`;

const ImageWrapper = styled.div`
  width: 30px;
  height: 30px;
  .user-profile-image {
    width: 100%;
    height: 100%;
  }
`;

const Name = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 5px 0 10px;
  cursor: pointer;
`;

const Xp = styled.div`
  width: 45px;
  padding-left: 5px;
`;

const Button = styled.div`
  color: ${(props) => props.selected ? "#FFF" : Theme.green};
  background: ${(props) => props.selected ? Theme.green : "#FFF"};
  border: 1px solid ${Theme.green};
  font-size: 11px;
  line-height: 15px;
  padding: 2px 10px;
  border-radius: 25px;
  cursor: pointer;
  margin: 0 2px;
`;

const PaginatorWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const SpinnerWrapper = styled.div`
  ${flexCenterColumn()}
  height: 490px;
`;

const Table = styled.div`
  width: 100%;
`;

const Ranking = styled.div`
  font-size: 14px;
  text-align: center;
  padding: 10px;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
`;

const EmptyMessage = styled.div`
  padding: 0 20px;
  font-size: 14px;
  line-height: 17px;
`;

const Updated = styled.div`
  font-size: 12px;
  line-height: 15px;
  font-style: italic;
  margin-top: 10px;
  color: #979797;
  text-align: left;
  width: 100%;
`;

@observer
class Leaderboard extends React.Component {

  @computed get showEmptyMessage() {
    return AppUI.leaderboardStore.page === 1 && !AppUI.leaderboardStore.hasIndexData && !AppUI.leaderboardStore.hasOutstandingRequests;
  }

  render() {
    return (
      <Wrapper>
        {
          !this.props.hideNavigation &&
            <Navigation>
              <Button
                selected={this.props.sortColumn === this.props.lastWeekColumn}
                onClick={() => this.props.setSortColumn(this.props.lastWeekColumn)}
              >
                Last Week
              </Button>
              <Button
                selected={this.props.sortColumn === this.props.lastMonthColumn}
                onClick={() => this.props.setSortColumn(this.props.lastMonthColumn)}
              >
                Last Month
              </Button>
              <Button
                selected={this.props.sortColumn === this.props.totalColumn}
                onClick={() => this.props.setSortColumn(this.props.totalColumn)}
              >
                All Time
              </Button>
            </Navigation>
        }
        {
          this.props.showSpinner &&
            <SpinnerWrapper>
              <Spinner
                className='blue'
              />
            </SpinnerWrapper>
        }
        {
          this.showEmptyMessage &&
            <EmptyMessage>
              {this.props.emptyMessage}
            </EmptyMessage>
        }
        {
          !this.props.showSpinner && AppUI.leaderboardStore.hasIndexData &&
            <Table>
              {
                !this.props.hideYourRanking &&
                  <Ranking>
                    Your Ranking: <b>{this.props.ranking}</b>
                  </Ranking>
              }
              {
                AppUI.leaderboardStore.indexData.map((item, index) => {
                  return (
                    <Row
                      key={`${this.props.sortColumn}-${item.id}`}
                      selected={item.id === AppUI.user.id}
                    >
                      <Position>
                        {((AppUI.leaderboardStore.page - 1) * 10) + index + 1}
                      </Position>
                      <ImageWrapper>
                        <UserProfileImage
                          clickToProfile
                          showLanguageIcon={this.props.showLanguageIcon}
                          user={item}
                          width={30}
                        />
                      </ImageWrapper>
                      <Name
                        onClick={() => AppUI.routeStore.routeToNamed('profiles.show', {userId: item.id})}
                      >
                        {item.name}
                      </Name>
                      <Xp>
                        {this.props.points(item)}
                      </Xp>
                    </Row>
                  )
                })
              }
            </Table>
        }
        {
          !this.showSpinner && !this.showEmptyMessage &&
            <PaginatorWrapper>
              <Paginator
                compact
                margin="10px 0 5px 5px"
                store={AppUI.leaderboardStore}
                queryStrings={{group_id: this.props.groupId}}
                maxPage={10}
              />
            </PaginatorWrapper>
        }
        {
          this.props.includeUpdatedAt &&
            <Updated>Last updated on {AppUI.site.leaderboardUpdatedAt.formatted3DigitMonth}</Updated>
        }
      </Wrapper>
    );
  }
}

export default LeaderboardWrapper(Leaderboard);
