"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _theme = _interopRequireDefault(require("./theme"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
var Constants = {
  TRIVIA_TREE_NODES: ["034ed015-a05e-4f95-b9de-0b1d4901b4e8", "7055aad7-86e3-454e-98ec-44fcea904082", "634d0f7d-e723-4d20-afb0-6ff17ba32a2a", "0a91cab2-97d7-4d9a-ae41-8f91159fb6e2", "78662286-3a58-47f9-8f42-7e0b49d1d6d2", "7c31c08d-7abb-4e9a-933e-ed9aad3c8469", "8733e036-aed0-4be7-ba50-ed9984fc0d71", "70004b21-077c-437a-96b0-fbeec16e29c4"],
  TRIVIA_TREE_NODES_BY_LEVEL: {
    A1: '034ed015-a05e-4f95-b9de-0b1d4901b4e8',
    A2: '634d0f7d-e723-4d20-afb0-6ff17ba32a2a',
    B1: '0a91cab2-97d7-4d9a-ae41-8f91159fb6e2',
    B2: '0a91cab2-97d7-4d9a-ae41-8f91159fb6e2'
  },
  GRAMMAR_TREES: {
    DE: '84404fa6-8973-4269-afe7-d92f2de9d17d',
    ES: 'cace45cf-1ccd-41c5-bb94-f3941c4d6f77',
    FR: 'ae5dfb48-d531-4c19-8809-d09fe9af631e'
  },
  CHOOSE_DECK_LINKS: {
    DE: {
      A1: {
        treeNodeId: 'dc2d5b3c-2a4b-4941-9a15-d188ed1e419b',
        deckId: 'eef7a4b5-3e98-4ff7-99bd-b312b95d88ce'
      },
      A2: {
        treeNodeId: '4d476108-d1ad-42e2-9808-7e133f08a86d',
        deckId: 'c57c2dc2-ef73-467a-8c20-6826aecf5718'
      },
      B1: {
        treeNodeId: 'c2ea234b-ddb8-40ff-8802-4afb0d7b462b',
        deckId: '5cb8fc91-0eed-48fb-abc0-d13216e763c2'
      }
    },
    FR: {
      A1: {
        treeNodeId: '6ed7461e-1ca3-4538-a160-b650c13cb58a',
        deckId: '63772a32-9ed6-4221-aa3b-eae3936372b0'
      },
      A2: {
        treeNodeId: '5b4799a0-a35d-4cf8-9e3b-d0cdeb014f7e',
        deckId: 'c961b7fc-5fbf-4f79-8685-5fcb9f31556d'
      },
      B1: {
        treeNodeId: '652d3f4b-6c1c-417a-bfee-53d63083b9f5',
        deckId: '960e672c-45a3-4ef1-9675-0b05e0538d67'
      }
    },
    ES: {
      A1: {
        treeNodeId: '7f2a3975-2687-4de6-8087-cb9f4c562cfa',
        deckId: '1cbc943a-095b-4763-89e1-6d0a98ae05de'
      },
      A2: {
        treeNodeId: 'cd58c8d0-a72c-4a41-a24a-783ff1ac5e63',
        deckId: 'd4b44d4a-7693-43cc-a606-832f47780b27'
      },
      B1: {
        treeNodeId: '461ab36c-a926-4eb1-98ec-e616bc995683',
        deckId: '7bf3e489-c911-4790-99a8-757a82e25cad'
      }
    }
  },
  SOCIAL_MEDIA_LINKS: {
    instagram: {
      DE: 'https://www.instagram.com/seedlang_german',
      ES: 'https://www.instagram.com/seedlang_spanish',
      FR: 'https://www.instagram.com/seedlang_french'
    },
    tiktok: {
      DE: 'https://www.tiktok.com/@seedlang_german',
      ES: 'https://www.tiktok.com/@seedlang_spanish',
      FR: 'https://www.tiktok.com/@seedlang_french'
    },
    youtube: {
      DE: 'https://www.youtube.com/@seedlang_german',
      ES: 'https://www.youtube.com/@seedlang_spanish',
      FR: 'https://www.youtube.com/@seedlang_french'
    }
  },
  APP_IDS: {
    ios: {
      DE: '1521550965',
      ES: '1640500647',
      FR: '1640496995',
      all: '1640499279'
    },
    android: {
      DE: 'com.seedlang.mobile.android.all',
      ES: 'com.seedlang.mobile.android.es',
      FR: 'com.seedlang.mobile.android.fr',
      all: 'com.seedlang.mobile.android.root'
    }
  },
  APP_STORE_WEB_LINKS: {
    ios: {
      DE: 'https://apps.apple.com/us/app/seedlang/id1521550965',
      ES: 'https://apps.apple.com/us/app/seedlang/id1640500647',
      FR: 'https://apps.apple.com/us/app/seedlang/id1640496995',
      all: 'https://apps.apple.com/us/app/seedlang/id1640499279'
    },
    android: {
      DE: 'https://play.google.com/store/apps/details?id=com.seedlang.mobile.android.all',
      ES: 'https://play.google.com/store/apps/details?id=com.seedlang.mobile.android.es',
      FR: 'https://play.google.com/store/apps/details?id=com.seedlang.mobile.android.fr',
      all: 'https://play.google.com/store/apps/details?id=com.seedlang.mobile.android.root'
    }
  },
  APP_STORE_NATIVE_LINKS: {
    ios: {
      DE: 'itms-apps://itunes.apple.com/us/app/apple-store/id1521550965?mt=8',
      ES: 'itms-apps://itunes.apple.com/us/app/apple-store/id1640500647?mt=8',
      FR: 'itms-apps://itunes.apple.com/us/app/apple-store/id1640496995?mt=8',
      all: 'itms-apps://itunes.apple.com/us/app/apple-store/id1640499279?mt=8'
    },
    android: {
      DE: 'market://details?id=com.seedlang.mobile.android.all',
      ES: 'market://details?id=com.seedlang.mobile.android.es',
      FR: 'market://details?id=com.seedlang.mobile.android.fr',
      all: 'market://details?id=com.seedlang.mobile.android.root'
    }
  },
  REVENUE_CAT_PUBLIC_KEYS: {
    ios: {
      DE: 'DphNckIADQBWAogBNJAAfAMHTZrdOVMS',
      ES: 'appl_wIcWakeglwtpHKuAXSPaXsDDyFd',
      FR: 'appl_djsLpeljGmzMkJIocGeUgCMkvLb',
      all: 'appl_BlzqeRzxjzeGWrlfOPZhILWqVJm'
    },
    android: {
      DE: 'DphNckIADQBWAogBNJAAfAMHTZrdOVMS',
      FR: 'goog_MndYaktUYFlKKEngojZknHKqdMn',
      ES: 'goog_zrmXecSCCRQnyXPosANkzSpEqyZ',
      all: 'goog_EOkhFVJIwHCVCMpTevmVPotCNFC'
    }
  },
  BAD_SCORE: 1,
  GOOD_SCORE: 3,
  EASY_SCORE: 5,
  DEBUG_IDS: {
    wordId: '585e3d34-988b-45ec-8a26-0cbe62dc8bd6',
    reviewDeckId: 'review',
    lastCardDeckId: '93b6d455-ec74-412e-9bbf-75fac59a5cdf',
    triviaDeckId: '3b5ecb07-deee-4032-856b-f12c071e162f',
    treeNodeId: 'b3f49937-138c-4262-b46e-9558cadb59dc',
    answerDeckId: 'eb3c0658-4f20-438c-aafe-4e91f6053925',
    describeDeckId: '874bacd5-c99d-4fef-ad8f-aeb58625f5bf',
    vocabDeckId: '23e73f85-d180-4fcd-87aa-e3f3d80b1187',
    longWordsVocabId: 'f5c8e287-a31d-437b-aa94-d9ed5724336d',
    longWordsStoriesId: '9bda2688-f7e8-4246-9b68-0d7ffa94a9b3',
    storiesWithMediaExercise: '6c2cfee2-2650-473a-9b34-b4fa748695e5',
    storiesDeckId: 'dacda219-a0bf-448c-b6aa-75aaecda7787',
    storiesFullScreenVideoDeckId: 'd9d2b34f-2795-4f9f-a607-cb7d7ce7b337',
    storiesInterviewId: '6a925274-204a-44c1-a2e2-1fa2a6e82741',
    genderDeckId: '89c36018-3596-4fc8-9b67-8872687ee706',
    pluralDeckId: 'c4e627d2-726a-4258-ab2c-4b2ac38a090e'
  },
  VOCAB_TRIAD_CONCEPT_CATEGORY_ID: '1d691db8-93a8-4eca-af4e-98b2323538a0',
  TRIVIA_CONCEPT_CATEGORY_ID: '1d691db8-93a8-4eca-af4e-98b2323538a0',
  VOCAB_FORUM_IDS: {
    DE: 'a159c0d1-0c31-4587-8bd3-3428d44bf51f',
    ES: '62668dc3-3565-4ad5-bdcd-5a30a7ac4ceb',
    FR: '01abecce-25ab-45e3-8351-59684e8688c9'
  },
  RTL_LANGUAGE_IDS: ['AR', 'FA', 'IW', 'UR', 'KU'],
  LANGUAGE_ABBREVIATIONS: ['DE', 'FR', 'ES'],
  LANGUAGE_OPTIONS: {
    DE: 'German',
    FR: 'French',
    ES: 'Spanish'
  },
  LANGUAGE_SELECT_OPTIONS: [['DE', 'German'], ['FR', 'French'], ['ES', 'Spanish']],
  STORY_TREE_BY_LANGUAGE: {
    'DE': 'master',
    'FR': 'french',
    'ES': 'spanish'
  },
  GRAMMAR_TREE_BY_LANGUAGE: {
    'DE': 'de_grammar',
    'FR': 'fr_grammar',
    'ES': 'es_grammar'
  },
  NO_REVIEW_EXPLANATIONS: {
    'wrong-word-type': 'We currently only support nouns, verbs, adjectives, adverbs, interjections and numbers for reviews. We will support more word types in the future.',
    'no-video-clip': 'We have not yet recorded a video for this word.',
    'contains-number': 'This word contains a number.',
    'excluded': 'This word was excluded for review by our didactics team.'
  },
  REVIEW_INTERVALS: [1, 3, 7, 15, 30, 60, 120, 240, 480],
  REVIEW_BAD_SCORE_IN_MINUTES: 1,
  XP_CARD_DEFAULT: 1,
  XP_CARD_REVIEW: 2,
  XP_CARD_REVIEW_DECK: 3,
  XP_FEED_ENTRY: 5,
  XP_FEED_ENTRY_TEXT: 3,
  XP_FEED_ENTRY_AUDIO: 5,
  XP_FEED_ENTRY_VIDEO: 7,
  XP_COMMENT: 1,
  XP_CORRECTION: 5,
  XP_TRANSCRIPTION: 5,
  VIDEO_DIMENSIONS: 480,
  AUDIO_BITS_PER_SECOND: 44100,
  VIDEO_BITS_PER_SECOND: 962000,
  FACEBOOK_APP_ID: 368659050164187,
  FACEBOOK_SDK_VERSION: 'v2.8',
  GA_KEY: 'UA-45180104-4',
  CLOUDFRONT_ID: 'd20l1bbn620xh9',
  TRIVIA_TIMER_MAX_PROGRESS: 1000,
  TRIVIA_TIMER_NUMBER_SECONDS: 20,
  TRIVIA_TIMER_INTERVAL: 1000,
  TIME_BEFORE_SLOW_DECK_WARNING: 10000,
  MINIMUM_BROWSER_SPEED_FOR_CARD_ANIMATION: 3,
  DELAY_BEFORE_INITIATING_CARD_ANIMATION: 150,
  DELAY_BEFORE_REMOVING_CARD_AFTER_ANIMATION: 400,
  EASY_GERMAN_GROUP_ID: 'f74a4102-d65b-448c-b261-60b6be2c7eca',
  EASY_GERMAN_PATREON_CLIENT_ID: '7adEPJjeCtc30tcKHEJd6mUlSqdNCfCu7aSKbfEEkglPchUHsE0AAbdmYmufWSPR',
  SPECIAL_CHARACTERS: ['ä', 'ö', 'ü', 'Ä', 'Ö', 'Ü', 'ß'],
  SPECIAL_CHARACTERS_BY_LANGUAGE: {
    DE: ['ä', 'ö', 'ü', 'Ä', 'Ö', 'Ü', 'ß'],
    FR: ['â', 'à', 'é', 'è', 'ê', 'ë', 'î', 'ï', 'ô', 'œ', 'û', 'ù', 'ç'],
    ES: ['á', 'é', 'í', 'ó', 'ú', 'ü', 'ñ', '¡', '¿'],
    CA: ['À', 'Ç', 'É', 'È', 'Í', 'Ï', 'Ò', 'Ó', 'Ü', 'Ú'],
    CS: ['á', 'č', 'ď', 'é', 'ě', 'í', 'ň', 'ó', 'ř', 'š', 'ť', 'ú', 'ů', 'ý', 'ž'],
    NL: ['é', 'ë', 'ï', 'ó', 'ö', 'ü'],
    IT: ['à', 'è', 'é', 'ì', 'ò', 'ó', 'ù'],
    PL: ['ą', 'ć', 'ę', 'ł', 'ń', 'ó', 'ś', 'ź', 'ż'],
    PT: ['ã', 'á', 'à', 'â', 'ç', 'é', 'ê', 'í', 'õ', 'ó', 'ô', 'ú', 'ü'],
    TR: ['â', 'ç', 'ğ', 'ı', 'İ', 'î', 'ö', 'ş', 'ü', 'û']
  },
  LETTERS_THAT_TAKE_APOSTROPHE_IN_FR: "aeiouâéèêëœîïôAEIOUÂÉÈÊËÎÏÔŒ",
  SPECIAL_PUNCTUATION: {
    APOSTROPHE: "\'",
    RIGHT_SINGLE_QUOTATION_MARK: "’",
    LEFT_SINGLE_QUOTATION_MARK: "‘",
    NO_BREAK_SPACE: " ",
    NARROW_NO_BREAK_SPACE: " ",
    ELLIPSIS: "…"
  },
  HIDE_HEADER_ROUTES: ['maintenance', 'memberships.create'],
  HIDE_FOOTER_ROUTES: ['maintenance', 'choose_deck', 'memberships.create', 'onboarding.registration', 'onboarding.sign_in', 'onboarding.forgot_password', 'onboarding.create_password', 'onboarding.migration', 'onboarding/confirm'],
  WORD_TYPE_ICONS: ['regularVerb', 'irregularVerb', 'dativeCase', 'nominativeCase', 'accusativeCase', 'genitiveCase'],
  COLORS: {
    black: '#1b1b1b',
    blue: '#0d90ad',
    gray: '#5b5b5b',
    green: '#23b975',
    orange: _theme["default"].orange,
    pink: '#F886C1',
    purple: _theme["default"].purple,
    red: _theme["default"].red,
    yellow: '#cabb0f'
  },
  COLOR_OPTIONS: [['black', 'Black'], ['blue', 'Blue'], ['gray', 'Gray'], ['green', 'Green'], ['orange', 'Orange'], ['pink', 'Pink'], ['purple', 'Purple'], ['red', 'Red'], ['yellow', 'Yellow']],
  DIFFICULTY_LABEL_OPTIONS: [['very-easy', 'Very Easy'], ['easy', 'Easy'], ['moderate', 'Moderate'], ['hard', 'Hard'], ['very-hard', 'Very Hard']],
  HIDE_FROM_VOCAB_TRAINER_REASONS: [['no-source', 'Permanent - No Source'], ['permanent-other', 'Permanent - Other'], ['similar', 'Permanent - Similar Word Already Exists'], ['uncommon-word', 'Permanent - Uncommon Word'], ['temporary', 'Temporary']],
  MONTHS: {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December"
  },
  DAYS_OF_WEEK: {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday"
  },
  FEEDBACK_OPTIONS: [{
    feedbackType: 'smile',
    image: 'smile.png',
    site: null
  }, {
    feedbackType: 'big_smile',
    image: 'big_smile.png',
    site: null
  }, {
    feedbackType: 'cry_laughing',
    image: 'cry_laughing.png',
    site: null
  }, {
    feedbackType: 'star_eyes',
    image: 'star_eyes2.png',
    site: null
  }, {
    feedbackType: 'wink',
    image: 'wink.png',
    site: null
  }, {
    feedbackType: 'stick_out_tongue',
    image: 'stick_out_tongue.png',
    site: null
  }, {
    feedbackType: 'cool',
    image: 'cool.png',
    site: null
  }, {
    feedbackType: 'hug',
    image: 'hug.png',
    site: null
  }, {
    feedbackType: 'cry',
    image: 'cry.png',
    site: null
  }, {
    feedbackType: 'thumbs_up',
    image: 'thumbs_up.png',
    site: null
  }, {
    feedbackType: 'wave',
    image: 'wave.png',
    site: null
  }, {
    feedbackType: 'strong',
    image: 'strong.png',
    site: null
  }, {
    feedbackType: 'please',
    image: 'please.png',
    site: null
  }, {
    feedbackType: 'heart',
    image: 'heart.png',
    site: null
  }, {
    feedbackType: 'fire',
    image: 'fire.png',
    site: null
  }, {
    feedbackType: 'celebration',
    image: 'celebration2.png',
    site: null
  }, {
    feedbackType: 'rocket_ship',
    image: 'rocket_ship.png',
    site: null
  }, {
    feedbackType: 'sun',
    image: 'sun.png',
    site: null
  }, {
    feedbackType: 'present',
    image: 'present.png',
    site: null
  }, {
    feedbackType: 'explosion',
    image: 'explosion.png',
    site: null
  }, {
    feedbackType: 'germany',
    image: 'germany.png',
    site: "1a441cc2-da7f-40ed-8b30-6b231c046181"
  }, {
    feedbackType: 'spain',
    image: 'spain.png',
    site: "1a441cc2-da7f-40ed-8b30-6b231c046181"
  }, {
    feedbackType: 'france',
    image: 'france.png',
    site: "1a441cc2-da7f-40ed-8b30-6b231c046181"
  }, {
    feedbackType: 'dance',
    image: 'dance.png',
    site: "1a441cc2-da7f-40ed-8b30-6b231c046181"
  }, {
    feedbackType: 'shocked',
    image: 'shocked.png',
    site: "1a441cc2-da7f-40ed-8b30-6b231c046181"
  }],
  CASE_NAMES: {
    nm: 'Nominative',
    ak: 'Accusative',
    dt: 'Dative',
    gn: 'Genitive'
  },
  ARTICLES: {
    nm: {
      masculine: 'der',
      feminine: 'die',
      neuter: 'das',
      plural: 'die'
    },
    ak: {
      masculine: 'den',
      feminine: 'die',
      neuter: 'das',
      plural: 'die'
    },
    dt: {
      masculine: 'dem',
      feminine: 'der',
      neuter: 'dem',
      plural: 'den'
    },
    gn: {
      masculine: 'des',
      feminine: 'der',
      neuter: 'des',
      plural: 'der'
    }
  },
  ARTICLES_BY_GENDER: {
    masculine: {
      nm: 'der',
      ak: 'den',
      dt: 'dem',
      gn: 'des'
    },
    feminine: {
      nm: 'die',
      ak: 'die',
      dt: 'der',
      gn: 'der'
    },
    neuter: {
      nm: 'das',
      ak: 'das',
      dt: 'dem',
      gn: 'des'
    },
    plural: {
      nm: 'die',
      ak: 'die',
      dt: 'den',
      gn: 'der'
    }
  },
  SINGULAR_ARTICLES: {
    IT: {
      feminine: 'il',
      masculine: 'la'
    },
    CS: {
      feminine: 'ta',
      masculine: 'ten',
      neuter: 'to'
    },
    CA: {
      feminine: 'la',
      masculine: 'el'
    },
    DE: {
      feminine: 'die',
      masculine: 'der',
      neuter: 'das'
    },
    ES: {
      feminine: 'la',
      masculine: 'el'
    },
    FR: {
      feminine: 'la',
      masculine: 'le'
    },
    PT: {
      feminine: 'a',
      masculine: 'o'
    },
    PT2: {
      feminine: 'a',
      masculine: 'o'
    },
    NL: {
      feminine: 'de',
      masculine: 'de',
      neuter: 'het'
    },
    AR: {
      feminine: '',
      masculine: ''
    },
    KK: {
      feminine: '',
      masculine: ''
    }
  },
  PLURAL_ARTICLES: {
    IT: {
      feminine: 'i',
      masculine: 'le'
    },
    CS: {
      feminine: 'ty',
      masculine: 'ti',
      neuter: 'ta'
    },
    CA: {
      feminine: 'las',
      masculine: 'los'
    },
    DE: {
      feminine: 'die',
      masculine: 'die',
      neuter: 'die'
    },
    ES: {
      feminine: 'las',
      masculine: 'los'
    },
    FR: {
      feminine: 'les',
      masculine: 'les'
    },
    PT: {
      feminine: 'as',
      masculine: 'os'
    },
    PT2: {
      feminine: 'as',
      masculine: 'os'
    },
    NL: {
      feminine: 'een',
      masculine: 'een',
      neuter: 'een'
    },
    AR: {
      feminine: '',
      masculine: ''
    },
    KK: {
      feminine: '',
      masculine: ''
    }
  },
  WORD_TYPES: [{
    id: '6130e19f-1dc2-4497-990b-b7c2b9753c58',
    name: 'Pronoun, Personal'
  }, {
    id: '93bb19e6-ff52-4a83-ab35-397b375edd93',
    name: 'Pronoun, Interrogative'
  }, {
    id: '20a1b2b5-a885-4bd2-9629-9b85b29b80f0',
    name: 'Pronoun, Impersonal'
  }, {
    id: '4c4389c1-f805-4d1c-ba45-6721cf400aef',
    name: 'Verb'
  }, {
    id: '11a477ee-f4ef-40fc-84ce-caa7926b2053',
    name: 'Article, Indefinite'
  }, {
    id: '79aeabcc-fb2d-4b0c-869d-33ee7b72fc0b',
    name: 'Adjective, Predicate'
  }, {
    id: '82c61597-154d-4c92-9914-a46d162e71be',
    name: 'Article, Definite'
  }, {
    id: 'dd6106f4-1fd1-48e3-a109-f15f15f63002',
    name: 'Interjection'
  }, {
    id: '2440cf33-b893-453f-a570-3abf61f41614',
    name: 'Adjective, Indefinite Article'
  }, {
    id: 'e3a08cb8-2cfc-4873-b9d8-9388817f63ba',
    name: 'Adjective, Unpreceded by Article'
  }, {
    id: 'a4294de3-3ecb-4d7a-8152-ed037e7d50fa',
    name: 'Pronoun, Exclamative'
  }, {
    id: '9f299ca0-a15f-49cd-8e98-0f2c0182b52c',
    name: 'Preposition'
  }, {
    id: '3c466663-39b6-4a5e-bf3d-ead74aa05a36',
    name: 'Pronoun, Reflexive'
  }, {
    id: '8327454a-007a-4fe1-a593-795f96d8d57c',
    name: 'Pronoun, Dependant Possessive'
  }, {
    id: '7b662ba3-e43f-4274-a45d-8269d2dd3295',
    name: 'Adjective, Definite'
  }, {
    id: '08a66b0e-ed47-459c-868a-e84f6bde2422',
    name: 'Pronoun, Possessive'
  }, {
    id: 'e239d2c9-8d78-4fdd-baa5-5492274509fb',
    name: 'Pronoun, Independant Possessive'
  }, {
    id: 'a5ccf656-0120-4364-8048-df8950c22e85',
    name: 'Pronoun, Passive'
  }, {
    id: 'c6ca664d-dc01-47bb-9be4-142af605e954',
    name: 'Pronoun, Definite'
  }, {
    id: '3ce230d0-3e02-44e9-abd7-ee2d0f9cacc8',
    name: 'Adjective, Possessive'
  }, {
    id: 'd5c5fcef-bb92-48a8-b00b-e7c71cb33068',
    name: 'Noun'
  }, {
    id: '1a0d84ff-a903-4be9-b0a8-22fdcc211506',
    name: 'Number'
  }, {
    id: '19fbf309-98b1-42e4-a226-151eedb37641',
    name: 'Pronoun'
  }, {
    id: '83371aa0-a5e3-44ea-9ef5-0a88064329b0',
    name: 'Adjective, Uncategorized'
  }, {
    id: '7088a39d-8892-4b17-9577-f5072d8ea43a',
    name: 'Article'
  }, {
    id: '25f37710-e6aa-4be0-afad-e55ea24a0bdc',
    name: 'Pronoun, Indefinite'
  }, {
    id: '31d430ed-1d99-4267-a2a9-4ea78f913459',
    name: 'Pronoun, Relative'
  }, {
    id: '69d09edb-281d-4d6a-960a-dee2f7ed212b',
    name: 'Adjective, Indefinite'
  }, {
    id: '8ad90c03-d4c1-42e3-a343-a84718cdad20',
    name: 'Article, Negation'
  }, {
    id: 'e71e7ad0-75c2-4cc4-a8aa-f655f7d00098',
    name: 'Particle'
  }, {
    id: '349c01c5-e320-4df7-b349-b0837789ae42',
    name: 'Pronoun, Demonstrative'
  }, {
    id: '17ac1392-e5f4-4651-9831-2d1fc7bba280',
    name: 'Adjective'
  }, {
    id: '18bc6667-e0fa-4613-9a08-2d81d1edf825',
    name: 'Presentative'
  }, {
    id: 'e69cb9c7-ff7c-4f87-9087-ae390f2cfcb4',
    name: 'Adjective, Demonstrative'
  }, {
    id: 'ff3003a1-a003-4438-92db-792c39b07345',
    name: 'Adjective, Definite Article'
  }, {
    id: '21016859-00e8-41a9-a801-579e56404b48',
    name: 'Pronoun, Direct Object'
  }, {
    id: '7576799b-46f8-4ec6-aab9-ba4b07e4de5f',
    name: 'Pronoun, Indirect Object'
  }, {
    id: '6c6fdde2-df91-40ad-a531-ea4deac9db36',
    name: 'Adjective, Interrogative'
  }, {
    id: 'b598b62d-3ea7-479c-acfe-32e01bdeac18',
    name: 'Conjunction'
  }, {
    id: '3d47e621-d906-475a-8055-3e71ba5a0b4f',
    name: 'Article, Partitif'
  }, {
    id: 'ec1f3bd3-474b-47c3-93ba-8296ea903060',
    name: 'Pronoun, Stressed'
  }, {
    id: '8999c3c5-be0d-497a-9503-24ea0c32cd3e',
    name: 'Article, Interrogative'
  }, {
    id: 'ae4f2883-7f17-4d10-933d-588a3965b2f9',
    name: 'Article, Contracted'
  }, {
    id: '15853e37-aac1-4df7-8808-ca63c29d1605',
    name: 'Proper Noun'
  }, {
    id: 'ad1caf92-c167-42fc-85c7-797050ab628d',
    name: 'Adverb'
  }, {
    id: '25f6bd8e-5f47-4161-8a44-19699594b943',
    name: 'Pronoun, Complement'
  }, {
    id: '999698cd-16e7-46a0-8750-3a13ef8bcd2f',
    name: 'Adverb, Interrogative'
  }, {
    id: '638a3bae-9cc8-4b73-813d-73cff888cd01',
    name: 'Auxiliary'
  }],
  VOCAB_WORD_TYPES: [{
    id: '17ac1392-e5f4-4651-9831-2d1fc7bba280',
    name: 'Adjective'
  }, {
    id: 'ad1caf92-c167-42fc-85c7-797050ab628d',
    name: 'Adverb'
  }, {
    id: 'd5c5fcef-bb92-48a8-b00b-e7c71cb33068',
    name: 'Noun'
  }, {
    id: '1a0d84ff-a903-4be9-b0a8-22fdcc211506',
    name: 'Number'
  }, {
    id: '4c4389c1-f805-4d1c-ba45-6721cf400aef',
    name: 'Verb'
  }],
  LEVELS: [{
    id: '8f53a51c-a6db-42ef-b865-b766ddc8ac64',
    abbreviation: 'A1',
    name: 'Beginner'
  }, {
    id: '2591d8a5-62ed-4091-95bd-c6b076e2bc0b',
    abbreviation: 'A2',
    name: 'Elementary'
  }, {
    id: 'c2ceb16d-0e42-4a04-aee4-c836805ee654',
    abbreviation: 'B1',
    name: 'Intermediate'
  }, {
    id: '764dc5a9-78a5-4d08-baf2-e20c289d9cf8',
    abbreviation: 'B2',
    name: 'Upper Intermediate'
  }, {
    id: '3130a18e-6ac5-44e5-b305-09600811407e',
    abbreviation: 'C1',
    name: 'Advanced'
  }],
  EXPERIMENTS: {
    landing_page: {
      num: 2,
      options: ['start_speaking', 'video_cover_grid']
    }
  },
  CHART_COLORS: ['#1DB5D7', '#ECAC09', '#06b164', '#FF8133', '#9873E6', '#1190ad', '#26566b'],
  CURRENCY_SYMBOLS: {
    AFN: '؋',
    ARS: '$',
    AWG: 'ƒ',
    AUD: '$',
    AZN: '₼',
    BSD: '$',
    BBD: '$',
    BYN: 'Br',
    BZD: 'BZ$',
    BMD: '$',
    BOB: '$b',
    BAM: 'KM',
    BWP: 'P',
    BGN: 'лв',
    BRL: 'R$',
    BND: '$',
    KHR: '៛',
    CAD: '$',
    KYD: '$',
    CLP: '$',
    CNY: '¥',
    COP: '$',
    CRC: '₡',
    HRK: 'kn',
    CUP: '₱',
    CZK: 'Kč',
    DKK: 'kr',
    DOP: 'RD$',
    XCD: '$',
    EGP: '£',
    SVC: '$',
    EUR: '€',
    FKP: '£',
    FJD: '$',
    GHS: '¢',
    GIP: '£',
    GTQ: 'Q',
    GGP: '£',
    GYD: '$',
    HNL: 'L',
    HKD: '$',
    HUF: 'Ft',
    ISK: 'kr',
    INR: '₹',
    IDR: 'Rp',
    IRR: '﷼',
    IMP: '£',
    ILS: '₪',
    JMD: 'J$',
    JPY: '¥',
    JEP: '£',
    KZT: 'лв',
    KPW: '₩',
    KGS: 'лв',
    LAK: '₭',
    LBP: '£',
    LRD: '$',
    MKD: 'ден',
    MYR: 'RM',
    MUR: '₨',
    MXN: '$',
    MNT: '₮',
    MZN: 'MT',
    NAD: '$',
    NPR: '₨',
    ANG: 'ƒ',
    NZD: '$',
    NIO: 'C$',
    NGN: '₦',
    NOK: 'kr',
    OMR: '﷼',
    PKR: '₨',
    PAB: 'B',
    PYG: 'Gs',
    PEN: 'S',
    PHP: '₱',
    PLN: 'zł',
    QAR: '﷼',
    RON: 'lei',
    RUB: '₽',
    SHP: '£',
    SAR: '﷼',
    RSD: 'Дин',
    SCR: '₨',
    SGD: '$',
    SBD: '$',
    SOS: 'S',
    KRW: '₩',
    ZAR: 'R',
    LKR: '₨',
    SEK: 'kr',
    CHF: 'CHF',
    SRD: '$',
    SYP: '£',
    TWD: 'NT$',
    THB: '฿',
    TTD: 'TT$',
    TRY: '₺',
    TVD: '$',
    UAH: '₴',
    GBP: '£',
    USD: '$',
    UYU: '$U',
    UZS: 'лв',
    VEF: 'Bs',
    VND: '₫',
    YER: '﷼',
    ZWD: 'Z$'
  },
  CASES: [{
    abbreviation: 'Nm',
    name: 'Nominative'
  }, {
    abbreviation: 'Dt',
    name: 'Dative'
  }, {
    abbreviation: 'none',
    name: 'None'
  }, {
    abbreviation: 'Ak',
    name: 'Accusative'
  }, {
    abbreviation: 'Gn',
    name: 'Genitive'
  }, {
    abbreviation: '2w-Dt',
    name: 'Two-way Dative'
  }, {
    abbreviation: '2w-Ak',
    name: 'Two-way Accusative'
  }, {
    abbreviation: '2w-Ak',
    name: 'Two-way Accusative'
  }, {
    abbreviation: 'prep',
    name: 'Prepositional'
  }, {
    abbreviation: 'com',
    name: 'Comitative'
  }],
  MAILING_TYPE_FILTER_FIELDS: ['accepted_pro_meetup_rsvp', 'accepted_translator_meetup_rsvp', 'admin', 'past_due_review_cards_count', 'current_streak_active', 'current_streak_count', 'level_abbreviation', 'last_active_at', 'created_at', 'review_cards_count', 'user_decks_count', 'translator', 'translator_type', 'translator_assigned_at', 'language_id', 'finished_user_decks_count', 'last_deck_interaction_at', 'user_groups_count', 'advanced_past_first_card', 'membership_type_id', 'permanent_membership', 'last_campaign_mailer_at', 'has_opened_review_deck', 'has_opened_trivia_deck', 'has_opened_vocab_deck', 'has_opened_content_tree_deck', 'first_deck_was_content_tree', 'first_deck_was_vocab', 'first_deck_was_trivia', 'viewed_checkout_count', 'guest', 'last_android_active_at', 'last_ios_active_at', 'last_mobile_active_at', 'last_mobile_notification_sent_at', 'xp'],
  BADGE_COLORS: ['#6B6A6A', '#f08104', _theme["default"].red, _theme["default"].green, _theme["default"].blue, _theme["default"].purple, _theme["default"].darkPurple, _theme["default"].purple, _theme["default"].purple, _theme["default"].purple, _theme["default"].purple, _theme["default"].purple, _theme["default"].purple, _theme["default"].purple],
  BADGES: [{
    slug: 'storymaster',
    // needs to be added to comment.rb
    name: 'Storymaster',
    description: 'Story decks completed',
    field: 'contentTreeDecksFinishedCount',
    levels: [8, 24, 70, 200, 500, 1200, 3200, 10000, 30000]
  }, {
    slug: 'deckmaster',
    name: 'Deckmaster',
    description: 'Decks completed',
    field: 'finishedUserDecksCount',
    levels: [8, 20, 50, 125, 300, 700, 2000, 6000, 18000]
  }, {
    slug: 'backInTime',
    name: 'Back in Time',
    description: 'Review cards seen',
    field: 'reviewCardsCompletedCount',
    levels: [50, 125, 300, 700, 2400, 7000, 20000, 50000, 125000, 400000, 1000000]
  }, {
    slug: 'storyteller',
    name: 'Storyteller',
    description: 'Stories in your feed',
    field: 'storyFeedEntryCount',
    levels: [6, 15, 35, 80, 200, 500, 1500, 4500, 12000]
  }, {
    slug: 'interviewMe',
    name: 'Interview Me',
    description: 'Answers in your feed',
    field: 'answerFeedEntryCount',
    levels: [6, 15, 35, 80, 200, 500, 1500, 4500, 12000]
  }, {
    slug: 'brainiac',
    name: 'Brainiac',
    description: 'Trivia games won',
    field: 'triviaGamesWon',
    levels: [5, 12, 30, 80, 200, 500, 1200, 3200, 10000]
  }, {
    slug: 'triviamaster',
    name: 'Triviamaster',
    description: 'Trivia games played',
    field: 'triviaDecksFinishedCount',
    levels: [8, 24, 70, 200, 500, 1200, 3200, 10000, 30000]
  }, {
    slug: 'vocabmaster',
    name: 'Vocabmaster',
    description: 'Vocab decks completed',
    field: 'vocabDecksFinishedCount',
    levels: [8, 24, 70, 200, 500, 1200, 3200, 10000, 30000]
  }, {
    slug: 'gendermaster',
    name: 'Gendermaster',
    description: 'Gender decks completed',
    field: 'genderDecksFinishedCount',
    levels: [8, 24, 70, 200, 500, 1200, 3200, 10000, 30000]
  }, {
    slug: 'pluralmaster',
    name: 'Pluralmaster',
    description: 'Plural decks completed',
    field: 'pluralDecksFinishedCount',
    levels: [8, 24, 70, 200, 500, 1200, 3200, 10000, 30000]
  }, {
    slug: 'translationmaster',
    name: 'Translationmaster',
    description: 'New translations added',
    field: 'translationSourcesCount',
    levels: [50, 150, 400, 1200, 3000, 10000, 30000, 90000, 120000]
  }, {
    slug: 'sentencewriter',
    name: 'Sentencewriter',
    description: 'Sentences Submitted',
    field: 'sentenceSubmissionsCount',
    levels: [8, 24, 70, 200, 500, 1200, 3200, 10000, 30000]
  }, {
    slug: 'community',
    name: 'Zoom Star',
    description: 'Community Rewards',
    field: 'communityBadge',
    levels: [1, 2, 3, 4, 5, 6, 7, 8, 9]
  }],
  GRAMMAR_TENSES: {
    // also update create_cards_service.rb (create_text_conjugation_cards) & conjugation_card_prompts (TENSE_ICONS)
    DE: {
      'a9f10fd6-e041-4087-b31f-2ae640c3c3c4': 'Present',
      '56e11a26-8f93-4486-a897-f5be4fa2fb34': 'Simple Past',
      'c1e9f360-b6d3-422b-bde4-1ad86deb466a': 'Present Perfect'
      // '2c6bd03e-ba93-4721-8778-9addc42f08d2': 'Subjunctive I',
      // '6add2c4d-aa54-4cc5-8819-608272a44458': 'Subjunctive II'
    },
    FR: {
      '33635da2-3d1d-4dc3-a75d-bd7b038b964d': 'Present',
      '1dc1f99d-c766-4c2c-9f8e-0219c6b28b21': 'Perfect',
      'b37d06ac-8b5d-44c7-8ab6-4547ada49112': 'Imperfect Past',
      '6de9af2b-0219-4348-980b-fec89e52696a': 'Simple future',
      'ad790421-3602-408f-af2d-cb91e4197ac2': 'Conditional present'
    },
    ES: {
      '38ce3ce6-2b14-4f9b-b81e-2335d74d394b': 'Present',
      '239aea46-cf43-49d1-ac75-41fe4e27feb3': 'Indefinite Past',
      '23e01211-7348-4790-8ed4-d0a4d6d186d3': 'Imperfect Past',
      'f714550e-6d45-4050-927c-b3e720261a31': 'Perfect',
      'b43e9bd2-9f06-4602-9a0d-d47847e8f763': 'Simple Future',
      '4f187664-22a4-4580-8cdb-48f859f86a3b': 'Subjunctive'
    }
  },
  PRONOUN_STRINGS: {
    DE: {
      singular_1p: 'ich',
      singular_2p: 'du',
      singular_3p: 'er, sie (f.), es',
      plural_1p: 'wir',
      plural_2p: 'ihr',
      plural_3p: 'sie (pl.), Sie (form.)'
    },
    FR: {
      singular_1p: 'je',
      singular_2p: 'tu',
      singular_3p: 'il, elle, on',
      plural_1p: 'nous',
      plural_2p: 'vous',
      plural_3p: 'ils, elles'
    },
    ES: {
      singular_1p: 'yo',
      singular_2p: 'tú',
      singular_3p: 'él, ella, usted',
      plural_1p: 'nosotros, -as',
      plural_2p: 'vosotros, -as',
      plural_3p: 'ellos, ellas, ustedes'
    }
  },
  REVIEW_CARD_TYPES: [{
    slug: "user_submission",
    id: "88631b6e-0a8c-400a-9be0-5b84878f652e",
    name: "Custom"
  }, {
    slug: "exercise",
    id: "d177b111-fe21-47de-b6be-32eda778e895",
    name: "Exercise Cards"
  }, {
    slug: "gender",
    id: "bf2b0984-ddf0-4e88-ae95-4fb5a309370c",
    name: "Gender"
  }, {
    slug: "comprehend",
    id: "5677045b-9026-41cd-ae8f-6f647a933338",
    name: "Listen and Repeat"
  }, {
    slug: "plural",
    id: "19a422d1-1622-440b-b4d0-03205bfd29c8",
    name: "Plural"
  }, {
    slug: "vocab_sentence_comprehend",
    id: "2f5021a4-28a7-4c96-8620-753ac36ed794",
    name: "Sentence Comprehension"
  }, {
    slug: "vocab_sentence_translate",
    id: "bfde0cda-d6fe-4524-965f-173eb841aa9e",
    name: "Sentence Fill in the Blanks"
  }, {
    slug: "translate",
    id: "55a10e43-1936-4d83-b207-c3a4d3d181d8",
    name: "Spoken Translation"
  }, {
    slug: "trivia",
    id: "8fad518d-cb7f-46f6-bbc6-6bc44c6e0faf",
    name: "Trivia"
  }, {
    slug: "vocab_word_comprehend",
    id: "a677c94d-8345-46bd-a837-efea27bafd95",
    name: "Word Comprehension"
  }, {
    slug: "vocab_word_options",
    id: "ea744c6b-add2-4414-8755-0a7a9e90e3ad",
    name: "Word Multiple Choice"
  }, {
    slug: "vocab_word_translate",
    id: "b6813ff6-c5df-4dd5-b9bc-202be20bfa8c",
    name: "Word Translation"
  }, {
    slug: "conjugation",
    id: "42593191-a5f0-40ce-a584-7c653dd08c97",
    name: "Conjugation"
  }],
  ENCOURAGEMENTS: {
    DE: [['Gut gemacht!', 'Good job!'], ['Du bist der Hammer!', 'You are awesome!'], ['Weiter so!', 'Keep going!']],
    ES: [['¡Lo has conseguido!', 'You did it!'], ['¡Eres increíble!', 'You are amazing!']],
    FR: [['Tu déchires !', "You're awesome!"], ['Tu gères !', 'You are managing this!']],
    EN: [["You're awesome!", "You're awesome!"], ['You got this!', 'You got this!']]
  },
  WARNINGS: {
    accents: "Please note the special characters!",
    missingArticle: "Don't forget to type the article!",
    wrongArticle: "Are you sure this is the correct article?",
    letterCase: "Remember to capitalize your answer correctly.",
    needsMoreWords: "You need to type in multiple words!",
    synonym: "You're close, but we're looking for a synonym!"
  },
  ERROR_MESSAGES: {
    DE: {
      general: "Oh Nein! Something went wrong.",
      video: "Oh nein!  There was a problem loading this video.",
      sentence: "Oh nein!  There was a problem loading this sentence!"
    },
    FR: {
      general: "Oh non! Something went wrong.",
      video: "Oh non!  There was a problem loading this video.",
      sentence: "Oh non!  There was a problem loading this sentence!"
    },
    ES: {
      general: "Oh no! Something went wrong.",
      video: "Oh no!  There was a problem loading this video.",
      sentence: "Oh no!  There was a problem loading this sentence!"
    },
    EN: {
      general: "Oh no! Something went wrong.",
      video: "Oh no!  There was a problem loading this video.",
      sentence: "Oh no!  There was a problem loading this sentence!"
    }
  },
  REGISTER_OPTIONS: [['colloquial', 'colloquial'], ['vulgar', 'vulgar'], ['archaic', 'archaic'], ['dialectal', 'dialectal'], ['slang', 'slang'], ['technical', 'technical'], ['literary', 'literary'], ['formal', 'formal']],
  WORKSHEET_PROGRESS_TAGS: ['not-started', 'in-progress', 'finished'],
  WORKSHEETS_PER_SECTION: 6,
  // also in grid_item.rb
  USER_ROLES: ['Seedlang', 'Easy Languages', 'Team Member', 'Test Account']
};
var _default = exports["default"] = Constants;