import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

const Option = styled.li`
  &:hover {
    background: #f1f1f1;
  }
  padding: 2px 10px;
  margin-bottom: 0;
  font-size: 12px;
  border-radius: 10px;
  cursor: ${props => props.selected ? 'default' : 'pointer'};
  background: ${props => props.selected ? '#e6e6e6' : ''};
`;

const Wrapper = styled.ul`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

@observer
class MenuList extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.options.map((item,index) => {
            return (
              <Option
                key={`${item[1]}-${index}`}
                onClick={() => this.props.onClick(item[0])}
                selected={this.props.selectedId && this.props.selectedId === item[0]}
              >
                {item[1]}
              </Option>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default MenuList;
