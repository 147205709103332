import React from 'react';
import { observer } from 'mobx-react';
import { DeckMenuWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import Dropdown from 'components/dropdown';
import { WarningTriangleIcon, ShortcutIcon, SettingsOutlineIcon, InfoIcon, ThreeDotsIcon, RefreshIcon } from '@seedlang/icons';
import { DeckUI, AppUI } from '@seedlang/state';
import DeckMenuItem from 'components/deck/deck_menu_item';

const Wrapper = styled.div`
  .dropdown-wrapper {
    width: 170px;
    right: 0;
  }
  .left-arrow-icon {
    height: 21px;
  }
  .fa-angle-left {
    font-size: 22px;
    margin-left: -2px;
  }
  .fa-angle-right {
    font-size: 22px;
    margin-left: 2px;
  }
  .info-icon {
    svg {
      width: 2.5px;
    }
  }
  .close-icon {
    svg {
      width: 15px;
    }
  }
  .warning-triangle-icon {
    svg {
      fill: #333;
      width: 15px;
      margin-top: -2px;
    }
  }
  .settings-outline-icon {
    svg {
      width: 17px;
    }
  }
  .question-icon {
    svg {
      width: 9px;
    }
  }
  .refresh-icon {
    svg {
      width: 13px;
    }
  }
  .shortcut-icon {
    svg {
      width: 13px;
    }
  }
`;

const MenuIconWrapper = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  .dropdown-icon {
    opacity: 0.4;
    display: flex;
    svg {
      margin-top: 1px;
      width: 7px;
    }
    circle, path {
      fill: #FFF;
    }
  }
`;

@observer
class DeckMenu extends React.Component {

  render() {
    return (
      <Wrapper>
        <Dropdown
          top={DeckUI.layout.deckHeaderHeight}
          showOnClick
        >
          <MenuIconWrapper>
            <ThreeDotsIcon
              className='dropdown-icon'
            />
          </MenuIconWrapper>
          <div>
            {
              (!DeckUI.deck.triviaDeck || DeckUI.user.admin) &&
                <DeckMenuItem
                  onClick={() => this.props.resetCard()}
                  disabled={this.props.disableResetCard}
                >
                  <RefreshIcon />
                  Restart Card
                </DeckMenuItem>
            }
            {
              !DeckUI.reviewDeck && !DeckUI.deck.triviaDeck &&
                <DeckMenuItem
                  onClick={() => DeckUI.skipAnimationGotoLastCard()}
                  disabled={DeckUI.userDeck.currentUserCardIsFirstInDeck}
                >
                  <div className='icon-wrapper'>
                    <i className='fa fa-angle-left' />
                  </div>
                  Previous Card
                </DeckMenuItem>
            }
            {
              (DeckUI.user.admin || AppUI.isDevelopment) &&
                <DeckMenuItem
                  onClick={() => DeckUI.skipAnimationNextCard()}
                  disabled={DeckUI.isLastCard}
                >
                  <div className='icon-wrapper'>
                    <i className='fa fa-angle-right' />
                  </div>
                  Next Card
                </DeckMenuItem>
            }
            {
              DeckUI.showDeckGrammar &&
                <DeckMenuItem
                  onClick={() => DeckUI.gotoDeckGrammar()}
                >
                  <InfoIcon />
                  Deck Grammar
                </DeckMenuItem>
            }
            {
              (!DeckUI.deck.triviaDeck || DeckUI.user.admin) &&
                <DeckMenuItem
                  onClick={() => DeckUI.resetDeck()}
                >
                  <RefreshIcon />
                  Restart Deck
                </DeckMenuItem>
            }
            <DeckMenuItem
              onClick={() => DeckUI.gotoShortcuts()}
            >
              <ShortcutIcon />
              Shortcuts
            </DeckMenuItem>
            <DeckMenuItem
              onClick={() => DeckUI.gotoDeckSettings()}
            >
              <SettingsOutlineIcon />
              Settings
            </DeckMenuItem>
            <DeckMenuItem
              onClick={() => DeckUI.gotoReportBug()}
            >
              <WarningTriangleIcon />
              Report Bug
            </DeckMenuItem>
          </div>
        </Dropdown>
      </Wrapper>
    );
  }
}

export default DeckMenuWrapper(DeckMenu);
