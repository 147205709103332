import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  position: fixed;
  z-index: 0;
  left: 0;
  bottom: 180px;
  right: 0px;
  fill: ${(props) => props.background};
`;

class Berlin extends React.Component {

  render() {
    return (
      <Wrapper
        background={this.props.background}
      >
        <svg viewBox="0 0 1203 247" version="1.1" xmlns="https://www.w3.org/2000/svg">
          <g>
            <path d="M711,162.40625 L706,171 L706,219 L695,219 L695,246 L802,246 L802,175 L772,175 L772,219 L762,219 L762,182 L748,182 L748,219 L738,219 L738,116 L719,148.65625 L719,204 L711,204 L711,162.40625 Z" id="Combined-Shape"></path>
            <path d="M455,162.40625 L450,171 L450,219 L439,219 L439,246 L546,246 L546,175 L516,175 L516,219 L506,219 L506,182 L492,182 L492,219 L482,219 L482,116 L463,148.65625 L463,204 L455,204 L455,162.40625 Z" id="Combined-Shape"></path>
            <path d="M1027,162.40625 L1022,171 L1022,219 L1011,219 L1011,246 L1118,246 L1118,175 L1088,175 L1088,219 L1078,219 L1078,182 L1064,182 L1064,219 L1054,219 L1054,116 L1035,148.65625 L1035,204 L1027,204 L1027,162.40625 Z" id="Combined-Shape"></path>
            <path d="M298,156.5 C298,156.667006 297.998564,156.833677 297.995702,157 L298,193 L302,193 L302,246 L237,246 L237,193 L241,193 L241,157 C241.001436,156.833677 241,156.667006 241,156.5 C241,141.602253 252.430681,129.374285 267,128.108138 L267,122.5 C267,121.119288 268.119288,120 269.5,120 C270.880712,120 272,121.119288 272,122.5 L272,128.108138 C286.569319,129.374285 298,141.602253 298,156.5 Z M217,179 C217,173.477153 221.477153,169 227,169 C232.522847,169 237,173.477153 237,179 L237,246 L217,246 L217,179 Z M302,179 C302,173.477153 306.477153,169 312,169 C317.522847,169 322,173.477153 322,179 L322,246 L302,246 L302,179 Z" id="Combined-Shape"></path>
            <path d="M984.796644,75.5578487 C977.999152,77.4037423 973,83.6180638 973,91 C973,97.9712065 977.458325,103.901115 983.679263,106.094016 C982.493627,140.854403 981.267206,194.156398 980,246 L998.005859,246 C997.291391,193.868606 996.535684,140.373915 995.738737,105.515925 C1001.20809,102.972586 1005,97.4291173 1005,91 C1005,84.2858951 1000.86446,78.537697 995.001703,76.1637237 C994.967414,74.928306 994.933053,73.7079176 994.898621,72.5025586 C997.954936,71.8906584 1000,70.7749376 1000,69.5 C1000,68.2006169 997.875761,67.0666148 994.721708,66.462802 C993.390309,22.1542673 991.953125,0 990.410156,0 C988.769892,0 987.024085,22.1033665 985.172738,66.3100995 C981.532443,66.8596646 979,68.0812517 979,69.5 C979,70.8847061 981.412372,72.081599 984.912629,72.6491833 C984.874012,73.6092235 984.835351,74.5787786 984.796644,75.5578487 Z" id="Combined-Shape"></path>
            <polygon id="Combined-Shape" points="585 225 554 225 570.5 213 585 223.545455 585 204 607 204 607 246 585 246 585 225 554 225 554 246 585 246"></polygon>
            <path d="M206,208.4 L206,246 L145,246 L145,208.4 L142,203 L145.333333,203 L142,197 L169,197 L175.5,184 L182,197 L209,197 L205.666667,203 L209,203 L206,208.4 Z M198,233 L198,212 L195,212 L195,233 L198,233 Z M190,233 L190,212 L184,212 L184,233 L190,233 Z M179,233 L179,212 L171,212 L171,233 L179,233 Z M166,233 L166,212 L161,212 L161,233 L166,233 Z M156,233 L156,212 L153,212 L153,233 L156,233 Z" id="Combined-Shape"></path>
            <path d="M372,219 L331,219 L331,246 L431,246 L431,219 L389.689453,219 L389.689453,184.9375 L383.25,192.904259 L383.25,175.5 C383.25,174.119288 382.130712,173 380.75,173 C379.369288,173 378.25,174.119288 378.25,175.5 L378.25,192.65452 L372,184.9375 L372,219 Z" id="Combined-Shape"></path>
            <path d="M819.150253,170 L841,170 L841,246 L812,246 L812,179.70908 L819.150253,170 Z" id="Combined-Shape"></path>
            <path d="M851.019406,199.570312 L849.212891,199.570312 L849.212891,199.782985 C849.142051,199.790897 849.071087,199.798786 849,199.806641 L849.212891,199.806641 L849.212891,245.570312 L966.212891,245.570312 L966.212891,199.806641 L966.279297,199.806641 L966.279297,186.785156 C950.776693,186.785156 937.514323,180.856771 926.492188,169 C913.017578,184.395633 907.639648,191.732422 878.433594,189.101562 C875.225515,194.215529 866.087453,197.705112 851.019406,199.570312 Z" id="Combined-Shape"></path>
            <path d="M1164,86.1707057 C1162.83481,86.5825421 1162,87.6937812 1162,89 L1162,98 L1160.5,98 C1159.11929,98 1158,99.1192881 1158,100.5 C1158,101.880712 1159.11929,103 1160.5,103 L1162,103 L1162,169.865047 C1160.04571,170.898091 1158.60284,172.769286 1158.15003,175 L1143,175 L1147,179 L1152.71338,179 L1147,179 L1147,198 L1127,198 L1127,246 L1203,246 L1203,198 L1184,198 L1184,179 L1178.28662,179 L1184,179 L1188,175 L1172.84997,175 C1172.39716,172.769286 1170.95429,170.898091 1169,169.865047 L1169,103 L1170.5,103 C1171.88071,103 1173,101.880712 1173,100.5 C1173,99.1192881 1171.88071,98 1170.5,98 L1169,98 L1169,89 C1169,87.6937812 1168.16519,86.5825421 1167,86.1707057 L1167,80 C1167,79.4477153 1166.55228,79 1166,79 L1165,79 C1164.44772,79 1164,79.4477153 1164,80 L1164,86.1707057 Z" id="Combined-Shape"></path>
            <path d="M133,193.794922 L133,246 L64,246 L64,193.397461 L64,182 L75.6630237,193 L83,193 L83,153 L87,153 L87,133 L87.1659169,133 C87.9521904,128.313335 91.4550658,124.547838 96,123.378052 L96,119.5 C96,118.119288 97.1192881,117 98.5,117 C99.8807119,117 101,118.119288 101,119.5 L101,123.165917 C106.02501,124.008954 109.991046,127.97499 110.834083,133 L111,133 L111,153 L114,153 L114,193 L121.342836,193 L133.005859,182 L133.005859,193.794922 L133,193.794922 Z" id="Combined-Shape"></path>
            <path d="M685,193.794922 L685,246 L616,246 L616,193.397461 L616,182 L627.663024,193 L635,193 L635,153 L639,153 L639,133 L639.165917,133 C639.95219,128.313335 643.455066,124.547838 648,123.378052 L648,119.5 C648,118.119288 649.119288,117 650.5,117 C651.880712,117 653,118.119288 653,119.5 L653,123.165917 C658.02501,124.008954 661.991046,127.97499 662.834083,133 L663,133 L663,153 L666,153 L666,193 L673.342836,193 L685.005859,182 L685.005859,193.794922 L685,193.794922 Z" id="Combined-Shape"></path>
            <polygon id="Path-4" transform="translate(26.428711, 223.583008) scale(-1, 1) translate(-26.428711, -223.583008) " points="0 246.166016 0 215.335938 13.0996094 201 42.0976562 201 52.8574219 209.455078 52.8574219 215.335938 46.6269531 215.335938 46.6269531 246.166016"></polygon>
          </g>
        </svg>
      </Wrapper>
    );
  }
}

export default Berlin;
