import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { TreeIcon, QuestionIcon, SearchWordsIcon, PatreonIcon, HomeIcon, ProfileIcon } from '@seedlang/icons';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { AppUI } from '@seedlang/state';
import { flexCenter } from '@seedlang/style_mixins';
import cx from 'classnames';

const Wrapper = styled.div`
  ${flexCenter()}
  background: ${Theme.footerColor};
  height: 50px;
  width: 100%;
  .selected {
    background: #3b4b54;
  }
  .icon-wrapper {
    flex: 1;
    height: 100%;
    margin: 0;
    color: rgba(255, 255, 255, 0.9);
    display: flex;
  }
  .icon {
    ${flexCenter()}
    width: 100%;
  }
  svg {
    fill: #FFF;
    opacity: 0.7;
  }
  .patreon-icon {
    svg {
      width: 25px;
    }
  }
  .star-icon {
    svg {
      width: 26px;
    }
  }
  .target-icon {
    svg {
      width: 26px;
      margin-top: 4px;
    }
  }
  .profile-icon {
    svg {
      width: 21px;
      fill: #FFF;
    }
  }
`;

const TriviaIcon = styled.div`
  width: 24px;
  height: 24px;
  .question-icon {
    svg {
      width: 26px;
    }
  }
`;

const IconWrapper = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

@observer
class Footer extends React.Component {

  @computed get right() {
    return AppUI.layout.viewportWidth / 10;
  }

  matchRoute(str) {
    return this.props.currentRoute && this.props.currentRoute.match(str);
  }

  render() {
    return (
      <Wrapper
        bgColor={this.props.bgColor}
      >
        <IconWrapper
          onClick={() => AppUI.routeStore.routeToNamed('reviews.home')}
          className={cx('icon-wrapper', {
            selected: this.matchRoute('reviews.home')
          })}
        >
          <HomeIcon
            fill="#FFF"
            width="22px"
          />
        </IconWrapper>
        <IconWrapper
          onClick={() => AppUI.routeStore.routeToNamed('stories.home')}
          className={cx('icon-wrapper', {
            selected: this.matchRoute('stories.home')
          })}
        >
          <TreeIcon
            fill="#FFF"
            width="25px"
          />
        </IconWrapper>
        <IconWrapper
          onClick={() => AppUI.routeStore.routeToNamed('vocab.home', {page: 1})}
          className={cx('icon-wrapper', {
            selected: this.matchRoute('vocab.home')
          })}
        >
          <SearchWordsIcon
            fill="#FFF"
            width="26px"
          />
        </IconWrapper>
        <IconWrapper
          className={cx('icon-wrapper', {
            selected: this.matchRoute('trivia')
          })}
          onClick={() => AppUI.routeStore.routeToNamed('trivia.home')}
        >
          <TriviaIcon>
            <QuestionIcon />
          </TriviaIcon>
        </IconWrapper>
        {
          AppUI.userIsLoaded && AppUI.user.contentGroupId &&
            <PatreonIcon
              onClick={() => AppUI.routeStore.routeToNamed('groups.show', {groupId: AppUI.user.contentGroupId})}
              className={cx({
                selected: this.matchRoute('groups') || this.matchRoute('worksheet') || this.matchRoute('exercise')
              })}
            />
        }
        <ProfileIcon
          onClick={() => AppUI.routeStore.routeToNamed('profile')}
          className={cx({
            selected: this.matchRoute('profile')
          })}
        />
      </Wrapper>
    );
  }
}

export default Footer;
