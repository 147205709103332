import React from 'react';
import { observer } from 'mobx-react';
import styled from "@emotion/styled";
import InPlaceSelect from "components/form/in_place_select";
import { isPresent } from "@seedlang/utils";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

@observer
class IconInPlaceSelect extends React.Component {

  static defaultProps = {
    options: [
      ['file-pdf-o', 'PDF'],
      ['file-text-o', 'Text'],
      ['file-code-o', 'HTML'],
      ['volume-up', 'Sound'],
      ['film', 'Video'],
      ['link', 'Link'],
      ['download', 'Download'],
      ['image', 'Image'],
      ['archive', 'Archive']
    ],
    includeBlank: true,
    placeholder: "Choose an Icon",
  }



  render() {
    return (
      <Wrapper>
        {
          isPresent(this.props.value) &&
            <i className={`fa fa-${this.props.value}`} style={{marginRight: '10px'}} />
        }
        <InPlaceSelect
          options={this.props.options}
          model={this.props.model}
          field={this.props.field}
          includeBlank={this.props.includeBlank}
          id={this.props.id}
          value={this.props.value}
          placeholder={this.props.placeholder}
          afterChange={this.props.afterChange}
          margin="0 5px 0 0"
          height='25px'
        />
      </Wrapper>
    );
  }
}

export default IconInPlaceSelect;
