import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import Text from 'components/text';
import InPlaceText from 'components/form/in_place_text';
import InPlaceSelect from 'components/form/in_place_select';
import { isPresent } from "@seedlang/utils/src";
import { isFunction } from "lodash";
import Checkbox from "components/checkbox";
import DeckFilters from "components/deck_filters";
import { Theme } from "@seedlang/constants/src";
import { AdvancedFiltersWrapper } from "@seedlang/hoc";
import isBlank from "is-blank";

const Row = styled.div`

`;

const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  input {
    height: 35px;
  }
`;

const DaysWrapper = styled.div`
  width: 50px;
  margin-left: 10px;
  input {
    height: 35px!important;
  }
`;

const DateText = styled.span`
  color: #333;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const AdvancedOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 280px;
  overflow: auto;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #333;
  padding: 10px;
  input {
    color: #333;
  }
`;

const Tags = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  color: #656565;
  font-size: 12px;
  padding: 5px;
`;

const Tag = styled.div`
  background: ${Theme.blue};
  color: #FFF;
  font-weight: bold;
  font-size: 12px;
  padding: 5px 20px 5px 5px;
  border-radius: 5px;
  margin: 3px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  i {
    position: absolute;
    right: 5px;
  }
`;

const MultiSelectButton = styled.div`
  background: ${props => props.selected ? Theme.blue : '#FFF'};
  color: ${props => props.selected ? '#FFF' : '#000'};
  font-weight: bold;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 3px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  &:active {
    transform: scale(0.97);
  }
`;

const MultiSelect = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

@observer
class AdvancedFilters extends React.Component {

  static defaultProps = {
    title: 'Advanced Filters',
  }

  render () {
    return (
      <DeckFilters
        title={`${this.props.expandOptions ? 'Hide' : 'View'} ${this.props.title}`}
        onClickHeader={this.props.onToggleExpandOptions}
        isExpanded={this.props.expandOptions}
        margin={this.props.margin}
      >
        {
          !this.props.expandOptions && !this.props.hasAdvancedFilterSelected &&
            <Tags
              padding="10px"
            >
              No Advanced Filters Selected
            </Tags>
        }
        {
          !this.props.expandOptions && this.props.hasAdvancedFilterSelected &&
            <Tags>
              {
                this.props.filterSettings.map(item => {
                  if (isPresent(item.tagDisplay)) {
                    return (
                      <Tag
                        onClick={() => item.onChange(null)}
                        key={Math.random()}
                      >
                        {item.tagDisplay}
                        <i className='fa fa-times'/>
                      </Tag>
                    );
                  }
                })
              }
            </Tags>
        }
        {
          this.props.expandOptions &&
            <AdvancedOptions>
              {
                this.props.filterSettings?.map(item => {
                  if (item.hasOwnProperty('type') && item.hasOwnProperty('onChange') && isFunction(item.onChange) && item.hasOwnProperty('name') && item.hasOwnProperty('value')) {
                    let maxOptions = item.maxOptions || this.props.defaultMaxOptions;
                    return (
                      <Row key={item.name}>
                        <Text
                          color="#333"
                          bold
                          fontSize="14px"
                        >
                          {item.name}
                        </Text>
                        {
                          (item.type === 'InPlaceSelect' || item.type === 'Slider') && item.hasOwnProperty('options') &&
                            <InPlaceSelect
                              allowUpdate
                              includeBlank
                              placeholder={item.placeholder || 'Any'}
                              onChange={(val) => isFunction(item.onChange) && item.onChange(val)}
                              options={item.options}
                              value={item.value}
                            />
                        }
                        {
                          item.type === 'MultiSelect' && item.hasOwnProperty('options') &&
                            <MultiSelect>
                              {
                                item.options.length > maxOptions &&
                                  <MultiSelectButton
                                    key={`any-${item.name}`}
                                    onClick={() => item.onChange('')}
                                    selected={isBlank(item.value)}
                                  >
                                    Any
                                  </MultiSelectButton>
                              }
                              {
                                item.options.map((option,index) => {
                                  if ((index < maxOptions) || this.props.multiSelectIsExpanded(item.name) || item.value?.includes(option[0])) {
                                    return (
                                      <MultiSelectButton
                                        key={option[0]}
                                        onClick={() => item.onChange(option[0])}
                                        selected={item.value && item.value?.includes(option[0])}
                                      >
                                        {option[1]}
                                      </MultiSelectButton>
                                    )
                                  }
                                })
                              }
                              {
                                item.options.length > maxOptions && !this.props.multiSelectIsExpanded(item.name) &&
                                  <MultiSelectButton
                                    onClick={() => this.props.onToggleExpandMultiSelect(item.name)}
                                  >
                                    <i className='fa fa-plus' style={{marginRight: '5px'}}/>See More
                                  </MultiSelectButton>
                              }
                              {
                                item.options.length > this.props.defaultMaxOptions && this.props.multiSelectIsExpanded(item.name) &&
                                <MultiSelectButton
                                  onClick={() => this.props.onToggleExpandMultiSelect(item.name)}
                                >
                                  <i className='fa fa-minus' style={{marginRight: '5px'}}/>See Less
                                </MultiSelectButton>
                              }
                            </MultiSelect>
                        }
                        {
                          item.type === 'checkbox' &&
                            <Checkbox
                              onClick={(val) => isFunction(item.onChange) && item.onChange(val)}
                              value={item.value}
                            />
                        }
                        {
                          item.type === 'CreatedAt' &&
                          <FormWrapper>
                            <InPlaceSelect
                              allowUpdate
                              onChange={(val) => item.onChange(val, 'created_at_before_after')}
                              options={[['after', 'Last'], ['before', 'Before']]}
                              value={item.value.createdAtBeforeAfter}
                            />
                            <DaysWrapper>
                              <InPlaceText
                                allowUpdate
                                onChange={(val) => item.onChange(val, 'created_at_days_ago')}
                                value={item.value.createdAtDaysAgo}
                                show
                              />
                            </DaysWrapper>
                            <DateText
                              padding="0 0 0 10px"
                            >
                              Days{item.createdAtBeforeAfter === 'before' ? ' Ago' : null}
                            </DateText>
                          </FormWrapper>
                        }
                      </Row>
                    )
                  }
                  }
                )
              }
            </AdvancedOptions>
        }
      </DeckFilters>
    );
  }
}

export default AdvancedFiltersWrapper(AdvancedFilters);
