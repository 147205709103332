import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  display: ${(props) => props.display};
  background: ${(props) => props.background};
  margin: 3px 5px 0 0;
  border: 1px solid gray;
  padding: 3px;
  border-radius: 3px;
  position: relative;
  color: black;
  flex-wrap: no-wrap;
  .fa-circle {
    color: #9e9e9e;
  }
`;

@observer
class FilterComponent extends React.Component {

  render() {
    return (
      <Wrapper
        display={this.props.hidden ? "none" : "inline-block"}
        background={this.props.selected ? Theme.green : "#E4E4E4" }
      >
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(FilterComponent);
