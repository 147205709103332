import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

const Wrapper = styled.div`

`;

@observer
class OnboardingWrapper extends React.Component {

  render () {
    return (
      <Wrapper>
        {this.props.children}
      </Wrapper>
    );
  }
}

export default OnboardingWrapper;
