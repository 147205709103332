import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 340px;
  overflow: auto;
  padding: 5px 10px;
`;

@observer
class OnboardingContent extends React.Component {

  render () {
    return (
      <Wrapper>
        {this.props.children}
      </Wrapper>
    );
  }
}

export default OnboardingContent;
