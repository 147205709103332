import React from 'react';
import { observer } from 'mobx-react';
import FeedEntryIndex from 'components/feed/feed_entry_index';
import { AppUI } from '@seedlang/state';
import { Link } from 'react-router';
import styled from '@emotion/styled';
import DesktopTitle from 'components/desktop_title';

const Wrapper = styled.div`
  display: flex;
`;

const Left = styled.div`
  width: 300px;
  margin-right: 10px;
`;

const Right = styled.div`
  flex: 1;
`;

const Row = styled.div`
  cursor: pointer;
  height: 68px;
  display: flex;
  background: #FFF;
  position: relative;
  margin: 0;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: center;
`;

@observer
class Feed extends React.Component {

  render() {
    return (
      <Wrapper>
        <Left>
          {
            !AppUI.layout.isMobile &&
              <DesktopTitle>
                Feed Filters
              </DesktopTitle>
          }
          <Link
            to={{name: 'feeds.index'}}
          >
            <Row
              selected={this.props.route.name === 'feeds.index'}
            >
              All Feed Entries
            </Row>
          </Link>
          <Link
            to={{name: 'my.feed'}}
          >
            <Row
              selected={this.props.route.name === 'my.feed'}
            >
              My Feed Entries
            </Row>
          </Link>
        </Left>
        <Right>
          {
            !AppUI.layout.isMobile &&
              <DesktopTitle>
                Feed Entries
              </DesktopTitle>
          }
          <FeedEntryIndex
            show={this.props.route.name}
            user={this.props.route.name === 'my.feed' ? AppUI.user : null}
          />
        </Right>
      </Wrapper>
    );
  }
}

export default Feed;
