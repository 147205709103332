import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FormLabel from 'components/form_factory/form_label';
import FormError from 'components/form_factory/form_error';
import Toggle from 'components/toggle';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  display: flex;
  align-items: flex-start;
`;

const LabelWrapper = styled.div`

`;

const Description = styled.div`
  font-size: 14px;
  line-height: 18px;
`;

@observer
class FormCheckbox extends React.Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    store: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    defaultValue: '',
    type: 'text',
  }

  constructor(props) {
    super(props);
    this.props.form.registerComponent(this.props);
  }

  componentWillUnmount() {
    // this.props.form.unregisterComponent(this.props);
  }

  onToggle() {
    this.props.onChange(this.props.name, !this.props.form.getValue(this.props.name));
  }

  getValue() {
    return this.props.form.getValue(this.props.name) || false;
  }

  getError() {
    return this.props.store.getError(this.props.name);
  }

  name() {
    if (this.props.name.indexOf('.') !== -1) {
      const ary = this.props.name.split('.');
      return `${ary[0]}[${ary[1]}]`;
    }
    return this.props.name;
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        <Toggle
          selected={this.props.toggleShowsOppositeValue ? !this.getValue() : this.getValue()}
          onToggle={this.onToggle.bind(this)}
        />
        <LabelWrapper>
          <FormLabel
            {...this.props}
            onClick={this.onToggle.bind(this)}
            htmlFor={this.name()}
          />
          {
            this.props.description &&
              <Description>
                {this.props.description}
              </Description>
          }
        </LabelWrapper>
        <FormError
          message={this.getError()}
        />
      </Wrapper>
    );
  }
}

export default FormCheckbox;
