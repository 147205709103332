import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { isPresent, isBlank } from '@seedlang/utils';
import styled from '@emotion/styled';
import ReorderClickItem from 'components/exercise/reorder_click_item';
import { ReorderAcceptItemWrapper } from '@seedlang/hoc';

const Wrapper = styled.div`
  min-width: ${props => props.hasValue ? "auto" : props.minWidth};
  border-bottom: ${props => props.hasValue ? "none" : "2px solid #A5A5A5"};
  border-radius: 10px;
  background: #FFF;
  color: #000;
  width: 100%;
  height: ${props => props.height};
  min-height: ${props => props.minHeight};
  display: inline-flex;
  align-items: center;
  justify-content: ${props => props.justifyContent};
`;

@observer
class ReorderAcceptItem extends React.Component {

  @computed get hasValue() {
    return (isPresent(this.props.value) || this.props.correct || this.props.incorrect);
  }

  static defaultProps = {
    height: "50px",
    justifyContent: 'center',
    minWidth: "100px",
  }

  render() {
    return (
      <Wrapper
        hasValue={this.hasValue}
        textTransform={this.props.textTransform}
        height={this.props.height}
        minHeight={this.props.minHeight}
        justifyContent={this.props.justifyContent}
        minWidth={this.props.minWidth}
      >
        {
          this.props.value &&
            <ReorderClickItem
              height={this.props.height}
              width={this.props.width}
              minHeight={this.props.minHeight}
              correct={this.props.correct}
              incorrect={this.props.incorrect}
              onClick={() => !this.props.disabled && isPresent(this.props.value) && this.props.onClick()}
              background={this.background}
              fontSize={this.props.fontSize}
              inDeck={this.props.inDeck}
            >
              {this.props.valueWithCapitalization.normalize('NFC')}
            </ReorderClickItem>
        }
      </Wrapper>
    );
  }
}

export default ReorderAcceptItemWrapper(ReorderAcceptItem);
