import React from 'react';
import { observer } from 'mobx-react';
import ReactTooltip from 'react-tooltip';
import styled from '@emotion/styled';
import { flexCenterColumn, flexCenter } from '@seedlang/style_mixins';
import { CardButtonWrapper } from '@seedlang/hoc';
import key from 'keymaster';
import autobind from 'autobind-decorator';
import { isPresent, pixify } from '@seedlang/utils';
import { DeckUI, OverlayUI } from '@seedlang/state';

const Wrapper = styled.div`
  display: inline-block;
  margin-top: ${props => props.marginTop || props.marginVertical};
  margin-bottom: ${props => props.marginBottom || props.marginVertical};
  margin-right: ${props => props.marginRight || props.marginHorizontal};
  margin-left: ${props => props.marginLeft || props.marginHorizontal};
  width: ${props => props.width};
  height: ${props => props.height};
  position: relative;
  min-width: 90px;
  position: relative;
  div[data-tip="true"] {
    height: 100%;
  }
  svg, .icon-wrapper, .icon {
    height: 18px;
    width: 18px;
  }
  .icon {
    display: inline-block;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg, svg path {
      fill: ${props => props.iconColor};
    }
    i {
      text-align: center;
      font-size: 12px;
    }
  }
  .refresh-icon, .close-icon {
    background: ${props => props.iconColor};
  }
  .down-icon i {
    height: 11px;
    line-height: 9px;
  }
  .right-icon i {
    height: 11px;
    margin: 0 0 0 2px;
  }
  .back-icon i {
    height: 11px;
    margin: 0 0 0 -2px;
  }
`;

const Button = styled.button`
  background-color: ${props => props.backgroundColor};
  cursor: ${props => props.cursor};
  font-size: ${props => props.fontSize};
  width: ${props => props.width};
  height: ${props => props.height};
  i {
    color: ${props => props.backgroundColor};
  }
  border-radius: 10px;
  text-transform: ${props => props.textTransform};
  padding: ${props => props.padding};
  font-weight: bold;
  align-items: center;
  border: none;
`;

const InnerButton = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  height: 100%;
`;

const ButtonText = styled.div`
  ${flexCenterColumn()}
  position: relative;
  color: ${props => props.color};
`;

const PrimaryTextWrapper = styled.div`
  ${flexCenter()}
`;

const Text = styled.div`
  line-height: 18px;
  margin: 1px 0 0 3px;
  font-size: ${props => props.fontSize};
  hyphens: auto;
`;

const SubText = styled.div`
  font-size: 11px;
  line-height: 11px;
  opacity: 0.7;
  margin-top: 5px;
`;

const TooltipWrapper = styled.div`
  height: 100%;
`;

const Shortcut = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 10px;
  font-weight: bold;
  opacity: 0.35;
  color: ${props => props.color};
  pointer-events: none;
`;

@observer
class CardButton extends React.Component {

  static defaultProps = {
    disabledBorderRight: '1px solid #929292',
    fontSize: 16,
    padding: 0,
    margin: 0,
    width: '100%',
    height: '100%',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  }

  constructor(props) {
    super(props);
    this.id = Math.random();
  }

  componentDidMount() {
    if (this.props.shortcut && !this.props.disabled) {
      this.assignDelayedShortcut();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.shortcutAlreadyAssigned && !this.props.disabled) {
      this.assignDelayedShortcut();
    } else if (this.props.shortcutAlreadyAssigned && !prevProps.disabled && this.props.disabled) {
      this.unbindShortcut();
    }
  }

  componentWillUnmount() {
    if (this.props.shortcutAlreadyAssigned) {
      this.unbindShortcut();
    }
  }

  @autobind unbindShortcut() {
    if (this.props.shortcut) {
      key.unbind(this.props.shortcut.toString());
    }
    if (this.props.submitOnEnter) {
      key.unbind('enter');
      key.unbind('right');
    }
    this.props.setShortcutSettings({});
  }

  @autobind assignDelayedShortcut() {
    setTimeout(this.assignShortcut, 100);
  }

  @autobind assignShortcut() {
    this.props.setShortcutSettings({ shortcut: this.props.shortcut, text: this.props.text });
    key(this.props.shortcut.toString(), this.onShortcutPress);
    if (this.props.submitOnEnter) {
      key('enter', this.onShortcutPress);
      key('right', this.onShortcutPress);
    }
  }

  @autobind onShortcutPress() {
    if (!this.props.disabled && !OverlayUI.hasOverlays) {
      DeckUI.set('overlayExpanded', null);
      this.props.onClick();
    }
  }

  render() {
    return (
      <Wrapper
        width={pixify(this.props.width)}
        height={pixify(this.props.height)}
        marginTop={pixify(this.props.marginTop)}
        marginBottom={pixify(this.props.marginBottom)}
        marginRight={pixify(this.props.marginRight)}
        marginLeft={pixify(this.props.marginLeft)}
        iconColor={this.props.iconColor}
      >
        {
          !this.props.disabled && this.props.shortcut && !DeckUI.layout.isMobile &&
            <Shortcut
              color={this.props.color}
            >
              {this.props.shortcut}
            </Shortcut>
        }
        <TooltipWrapper
          data-for={this.props.showTooltip ? `card-button-${this.id}` : null}
          data-tip={this.props.showTooltip}
        >
          <Button
            width={pixify(this.props.width)}
            height={pixify(this.props.height)}
            fontSize={pixify(this.props.fontSize)}
            textTransform={this.props.textTransform}
            padding={this.props.padding}
            borderBottomLeftRadius={this.props.borderBottomLeftRadius}
            borderBottomRightRadius={this.props.borderBottomRightRadius}
            borderTopLeftRadius={this.props.borderTopLeftRadius}
            borderTopRightRadius={this.props.borderTopRightRadius}
            disabled={this.props.disabled}
            disabledBorderRight={this.props.disabledBorderRight}
            cursor={this.props.disabled ? 'default' : 'pointer'}
            backgroundColor={this.props.backgroundColor}
            onClick={this.props.onClick}
          >
            <InnerButton>
              <ButtonText
                color={this.props.color}
              >
                {
                  (!this.props.disabled || !this.props.hideTextIfDisabled) && isPresent(this.props.text) &&
                    <PrimaryTextWrapper>
                      {this.props.icon}
                      <Text
                        fontSize={pixify(this.props.fontSize)}
                        lang={DeckUI.deck.languageId}
                      >
                        {this.props.text}
                      </Text>
                    </PrimaryTextWrapper>
                }
                {
                  this.props.subText &&
                    <SubText>
                      {this.props.subText}
                    </SubText>
                }
              </ButtonText>
            </InnerButton>
          </Button>
          {
            this.props.showTooltip && !this.props.disabled &&
              <ReactTooltip
                place="top"
                type="dark"
                effect="solid"
                id={`card-button-${this.id}`}
                class="custom-tooltip"
              >
                {this.props.tooltip}
              </ReactTooltip>
          }
        </TooltipWrapper>
      </Wrapper>
    );
  }
}

export default CardButtonWrapper(CardButton);
