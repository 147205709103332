import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { CampaignMessageStore, CampaignTypeStore } from '@seedlang/stores';
import { UserCampaignMessageStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import { Link } from 'react-router';
import { isPresent } from '@seedlang/utils';
import Filter from 'components/backend_filter/filter';
import { AppUI } from "@seedlang/state";
import Spinner from "components/spinner";

@observer
class CampaignMessageIndex extends React.Component {

  static defaultProps = {
    limit: 10,
  }

  constructor(props) {
    super(props);
    CampaignTypeStore.getIndex();
    if (props.userId) {
      UserCampaignMessageStore.getIndex({limit: props.limit, ids: {userId: props.userId}});
    } else if (props.campaignId) {
      CampaignMessageStore.getIndex({limit: props.limit, filters: {campaign_id: props.campaignId}});
    }
  }

  store() {
    return this.props.userId ? UserCampaignMessageStore : CampaignMessageStore;
  }

  render() {
    return (
      <div className='interjection-index'>
        {
          !this.props.userId && !this.props.campaignId &&
            <Filter
              store={CampaignMessageStore}
              defaultSort="-created_at"
              defaultFilters={this.props.defaultFilters}
              namespace="notification-index"
              queryStrings={{include_count: true}}
              filters={[
                {
                  type: 'select',
                  name: 'campaign_type_id',
                  options: CampaignTypeStore.indexData,
                  label: 'Campaign Type',
                  labelField: 'name',
                },
                {
                  type: 'boolean',
                  label: 'Clicked',
                  name: 'clicked',
                  default: '',
                },
                {
                  type: 'date',
                  label: 'Created',
                  name: 'created_at',
                  default: '',
                },
              ]}
            />
        }
        <table className="table-wrapper admin-table">
          <thead>
            <tr>
              <th width="20" />
              <th width="150" >Created At</th>
              {
                !this.props.userId &&
                  <th>User</th>
              }
              {
                !this.props.whiteLabel &&
                  <th>Message Type</th>
              }
              <th>Campaign Type</th>
              <th>Content</th>
              {
                !this.props.whiteLabel &&
                  <th>Site</th>
              }
            </tr>
          </thead>
          <tbody>
            {
              this.store().indexData.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: this.props.groupId ? "creator.campaign_messages.show" : 'builder.campaign_messages.show', params: { campaignMessageId: item.id, groupId: this.props.groupId } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>
                      <div className="text">
                        {item.createdAt.formatted}
                      </div>
                    </td>
                    {
                      !this.props.userId && item.user &&
                        <td>
                          <div className="text">
                            <Link
                              to={{name: this.props.groupId ? 'creator.groups.users.edit' : 'builder.users.edit', params: {userId: item.user.id, groupId: this.props.groupId}}}
                              className="on-click"
                            >
                              {item.user.name}
                            </Link>
                          </div>
                        </td>
                    }
                    {
                      !this.props.whiteLabel &&
                        <td>
                          <div className="text">
                            {item.messageType}
                          </div>
                        </td>
                    }
                    <td>
                      <div className="text">
                        {
                          isPresent(item.campaignType) && isPresent(item.campaignType.id) &&
                            <Link
                              to={{ name: this.props.groupId ? 'creator.campaign_types.edit' : 'builder.campaign_types.edit', params: {  campaignTypeId: item.campaignType.id, groupId: this.props.groupId } }}
                            >
                              {item.campaignType.name}
                            </Link>
                        }
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        <div>{item.campaign?.subject}</div>
                        <div>{item.campaign?.title}</div>
                        <div>{item.campaign?.notificationBody}</div>
                      </div>
                    </td>
                    {
                      AppUI.siteIsDefault &&
                      <td>{item.siteName}</td>
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        {
          this.store.requestCounter > 0 &&
            <Spinner
              background={AppUI.site?.accentColor}
            />
        }
        <Paginator
          store={this.store()}
          ids={{userId: this.props.userId}}
        />
      </div>
    );
  }
}

export default ErrorBoundary(CampaignMessageIndex);
