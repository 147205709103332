import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { TranslationWithSourceWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import LanguageButton from 'components/button/language_button';
import SentenceBreakdown from 'components/sentence_breakdown';
import cx from 'classnames';
import Text from 'components/text';
import { PlayIcon } from '@seedlang/icons';
import { DeckUI } from '@seedlang/state';
import { isBlank } from "@seedlang/utils";
import { LanguageIcon } from "@seedlang/icons";
import {displayTargetTextCorrectedForLanguage} from "@seedlang/utils/src";

const Wrapper = styled.div`
  background: ${(props) => props.background};
  flex: ${props => props.flex};
  display: flex;
  padding: 10px 0;
  width: 100%;
`;

const LanguageButtonWrapper = styled.div`
  width: ${(props) => props.languageButtonWrapperWidth || "46px"};
  padding: ${(props) => props.languageButtonWrapperPadding || "0"};
  display: flex;
  justify-content: flex-end;
`;

const TextWrapper = styled.div`
  flex: 1;
  padding: 0 10px 0 10px;
`;

const PlayButtonWrapper = styled.div`
  width: 22px;
  cursor: pointer;
  .play-icon {
    opacity: 0.8;
  }
  svg {
    width: 22px;
  }
`;

@observer
class TranslationWithSource extends React.Component {
  @observable showType = 'target'

  static defaultProps = {
    flex: "1",
    fontSize: "14px",
  }

  render() {
    return (
      <Wrapper
        className={cx(this.props.className)}
        background={this.props.background}
        flex={this.props.flex}
      >
        {
          !isBlank(this.props.translation.sourceText) &&
            <LanguageButtonWrapper
              languageButtonWrapperWidth={this.props.languageButtonWrapperWidth}
              languageButtonWrapperPadding={this.props.languageButtonWrapperPadding}
            >
              <LanguageButton
                background={this.props.background}
                onClick={() => this.showType = this.showType === 'target' ? 'source' : 'target'}
                languageId={this.showType === 'source' ? (this.props.translation.sourceTextForUserLanguageId && this.props.translation.sourceTextForUserLanguageId(DeckUI.user)) || 'EN' : this.props.translation.languageId || 'DE'}
                useUkFlagForEn={this.props.user?.useBritishEnglish}
              />
            </LanguageButtonWrapper>
        }
        {
          isBlank(this.props.translation.sourceText) &&
            <LanguageIcon
              languageId={this.props.translation.languageId}
            />
        }
        <TextWrapper>
          {
            this.showType === 'target' && this.props.showSentenceBreakdown &&
              <SentenceBreakdown
                {...this.props.sentenceBreakdownProps}
                fontSize={this.props.fontSize}
                sentence={this.props.translation}
              />
          }
          {
            this.showType === 'target' && !this.props.showSentenceBreakdown &&
              <Text
                fontSize={this.props.fontSize}
              >
                {displayTargetTextCorrectedForLanguage(this.props.normalizedTargetText, this.props.translation.languageId)}
              </Text>
          }
          {
            this.showType === 'source' &&
              <Text
                fontSize={this.props.fontSize}
              >
                {this.props.translation.sourceTextForUser ? this.props.translation.sourceTextForUser(DeckUI.user) : this.props.translation.sourceText}
              </Text>
          }
        </TextWrapper>
        {
          this.props.onClickPlayVideo && this.props.translation &&
            <PlayButtonWrapper>
              <PlayIcon
                onClick={() => this.props.onClickPlayVideo(this.props.translation)}
              />
            </PlayButtonWrapper>
        }
      </Wrapper>
    );
  }
}

export default TranslationWithSourceWrapper(TranslationWithSource);
