import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { CreatorUI } from '@seedlang/state';
import autobind from 'autobind-decorator';

const VideoClipsContainer = (VideoClips) => observer(class VideoClipsContainer extends React.Component {

  constructor(props) {
    super(props);
    CreatorUI.createMediaStore();
    CreatorUI.setOnChangeCreatorCallback(this.reload.bind(this));
    CreatorUI.conceptStore.setSort('name');
  }

  componentWillUnmount() {
    CreatorUI.videoClipStore.clearIndexData();
    CreatorUI.videoClipStore.clearFilter();
  }

  @observable videoClip = null;
  @observable conceptId = null;
  @observable videoableType = 'Target';
  @observable videoableId = null;
  @observable currentPage = null;

  @autobind @action reload() {
    this.loadVideoClipIndex();
  }

  @autobind @action loadVideoClipIndex() {
    CreatorUI.videoClipStore.getIndex({ filters: { videoable_type: this.videoableType } });
  }

  // EVENT HANDLERS

  @autobind @action afterCreateVideo() {
    this.loadVideoClipIndex();
  }

  @autobind @action onDeleteVideoClip(id) {
    CreatorUI.videoClipStore.destroy({ ids: { videoClipId: id } }, this.reload);
  }

  @autobind @action onSetVideoable(videoable) {
    this.videoableId = videoable.id;
  }

  @autobind @action onPlayVideo(item) {
    this.videoClip = item.videoClip;
  }

  @autobind @action onPlaybackClosed(e) {
    e.preventDefault();
    this.videoClip = null;
  }

  @autobind @action onSetDefault(item) {
    if (!item.default) {
      CreatorUI.videoClipStore.update({ ids: { videoClipId: item.id }, data: { default: true } }, this.loadVideoClipIndex);
    }
  }

  render() {
    return (
      <VideoClips
        videoableId={this.videoableId}
        conceptId={this.conceptId}
        videoableType={this.videoableType}
        mediaStore={CreatorUI.mediaStore}
        videoClip={this.videoClip}
        afterCreateVideo={this.afterCreateVideo}
        onDeleteVideoClip={this.onDeleteVideoClip}
        onSetVideoable={this.onSetVideoable}
        onPlayVideo={this.onPlayVideo}
        onPlaybackClosed={this.onPlaybackClosed}
        onSetDefault={this.onSetDefault}
        onSetTranslationType={this.onSetTranslationType}
        videoClipStore={CreatorUI.videoClipStore}
        conceptStore={CreatorUI.conceptStore}
      />
    )
  }
});

export default VideoClipsContainer;
