import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import ForumThreadIndex from 'components/forum_thread/forum_thread_index';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`

`;

@observer
class WorksheetComments extends React.Component {

  render () {
    return (
      <Wrapper>
        {
          AppUI.userIsLoaded &&
            <ForumThreadIndex
              hideLogo
              hideName
              loadForum
              defaultFilters={{comments_count: 'true'}}
              forumId={AppUI.user.site.forumId}
              groupId={this.props.params.groupId}
            />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WorksheetComments);
