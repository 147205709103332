"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = isValidEmail;
function isValidEmail(email) {
  if (email === 'test--' || email === 'test++') {
    return true;
  }
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}