"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = decrementId;
function decrementId(ary, value) {
  var index = ary.findIndex(function (item) {
    return item.id === value;
  });
  if (index > 0) {
    return ary[index - 1].id;
  } else {
    return ary[ary.length - 1].id;
  }
}