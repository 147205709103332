import React from 'react';
import { observer } from 'mobx-react';
import { UserStore } from '@seedlang/stores';
import TypeAhead from 'components/type_ahead';
import { isEmpty } from 'lodash';
import { AppUI } from '@seedlang/state';

@observer
class UserSearch extends React.Component {

  static defaultProps = {
    searchByField: 'email',
    placeholder: 'Enter User Email',
  }

  componentDidMount() {
    UserStore.setSort(this.props.searchByField);
  }

  componentWillUnmount() {
    this.onClear();
  }

  onKeyUp(value) {
    value = value.trim();
    if (isEmpty(value)) {
      this.onClear();
    } else {
      UserStore.setFilter(this.props.searchByField, `~${value}~`, { refresh: true });
    }
  }

  onSelect(obj) {
    this.props.onSubmit(obj);
  }

  onClear() {
    UserStore.clearIndexData();
    UserStore.clearFilter();
  }

  render() {
    return (
      <div className="user-search" style={{margin: this.props.margin}}>
        <TypeAhead
          onKeyUp={this.onKeyUp.bind(this)}
          onSelect={this.onSelect.bind(this)}
          onClear={this.onClear.bind(this)}
          options={UserStore.indexData}
          displayField={AppUI.user.admin ? this.props.searchByField : 'name'}
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}

export default UserSearch;
