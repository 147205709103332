import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed, toJS } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { FilterStore } from '@seedlang/stores';
import { isBlank } from '@seedlang/utils';
import Button from 'components/button/button';
import autobind from 'autobind-decorator';
import Spinner from 'components/spinner';
import DeleteButton from 'components/button/delete_button';
import Paginator from 'components/paginator';
import { keys, each } from 'lodash';

const Wrapper = styled.div`
  font-size: 14px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #C1C1C1;
  margin: 5px 0;
  padding: 5px 0;
`;

const NameWrapper = styled.div`
  flex: 1;
`;

const FilterList = styled.div`

`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 20px 0;
`;

const ButtonWrapper = styled.div`
  width: 140px;
`;

const Delete = styled.div`
  width: 50px;
`;

const Name = styled.div`
  font-size: 15px;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 12px;
`;

@observer
class FilterIndex extends React.Component {
  @observable name = '';
  @observable loadingFilters = false;

  constructor(props) {
    super(props);
    FilterStore.setFilter('namespace', this.props.namespace);
    this.getFilters();
  }

  @computed get disabled() {
    return isBlank(this.name);
  }

  @autobind onClick() {
    FilterStore.create({data: {
      name: this.name,
      namespace: this.props.namespace,
      description: this.props.filterString,
      options: toJS(this.props.store.filters),
    }}, this.afterSave);
  }

  @autobind afterSave() {
    this.name = '';
    this.getFilters();
  }

  @autobind getFilters() {
    this.loadingFilters = true;
    FilterStore.getIndex({}, this.afterGetFilters);
  }

  @autobind afterGetFilters(resp) {
    FilterStore.setIndexData(resp);
    this.loadingFilters = false;
  }

  @autobind onDelete(filter) {
    FilterStore.destroy({ids: {filterId: filter.id}}, this.getFilters);
  }

  @autobind onLoadFilter(filter) {
    each(keys(filter.optionsJson), (key) => {
      this.props.store.setFilter(key, filter.optionsJson[key])
    })
    this.props.store.setPage(1);
    this.props.store.reloadIndexFiltered();
    this.props.afterLoadFilter();
  }

  render() {
    return (
      <Wrapper>
        <Title>Filters List</Title>
        {
          this.loadingFilters &&
            <Spinner />
        }
        {
          !this.loadingFilters &&
            <FilterList>
              {
                FilterStore.indexData.length === 0 &&
                  <FilterList>
                    There are no saved filters.
                  </FilterList>
              }
              {
                !this.loadingFilters && FilterStore.indexData.map(item => {
                  return (
                    <Row
                      key={item.id}
                    >
                      <ButtonWrapper>
                        <button
                          className='button-primary'
                          onClick={() => this.onLoadFilter(item)}
                        >
                          Load Filter
                        </button>
                      </ButtonWrapper>
                      <NameWrapper>
                        <Name>
                          {item.name}
                        </Name>
                        <Description>
                          {item.description}
                        </Description>
                      </NameWrapper>
                      <Delete>
                        <DeleteButton
                          onConfirm={() => this.onDelete(item)}
                          message="Delete this filter?"
                          className="fa-block right"
                        />
                      </Delete>
                    </Row>
                  )
                })
              }
              {
                FilterStore.indexData.length > 0 &&
                  <Paginator
                    store={FilterStore}
                  />
              }
            </FilterList>
        }
        <Title>Save Filter</Title>
        {this.props.filterString}
        <input
          style={{margin: "10px 0"}}
          type='text'
          value={this.name}
          placeholder="Enter Filter Name"
          onChange={(e) => this.name = e.currentTarget.value}
        />
        <Button
          onClick={this.onClick}
          disabled={this.disabled}
        >
          Save
        </Button>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(FilterIndex);
