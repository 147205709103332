import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { LanguageIcon } from '@seedlang/icons';
import Text from 'components/text';
import InfoTooltip from "components/info_tooltip";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 5px;
`;

const Source = styled.div`
  display: flex;
  justify-content: center;
  margin: 2px 0;
`;

@observer
class VocabSourceForUser extends React.Component {

  render() {
    return (
      <Wrapper
        onClick={this.props.onClick}
      >
        {
          this.props.sources && this.props.sources.map(item => {
            return (
              <Source
                key={item.id}
              >
                <LanguageIcon
                  languageId={item.source && item.source?.languageId}
                  translatedByAi={item.translatedByAi}
                  useUkFlagForEn={this.props.user?.useBritishEnglish}
                />
                <Text
                  margin="0 0 0 3px"
                  fontSize={item.source?.rightToLeftLanguage ? "22px" : "14px"}
                >
                  {item.source.textForUser(this.props.user)}
                  {item.source.displayGender ? this.props.genderAbbreviation : ''}
                </Text>
                {
                  this.props.user.admin && item.translatedByAi &&
                  <InfoTooltip color='#b3b3b3' icon='magic'><div>Translated by AI</div><div>[admins only]</div></InfoTooltip>
                }
              </Source>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(VocabSourceForUser);
