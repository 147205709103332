import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { ForumUI } from '@seedlang/state';
import ForumThreadIndex from 'components/forum_thread/forum_thread_index';
import Button from 'components/button/button';
import styled from '@emotion/styled';
import Breadcrumbs from 'components/breadcrumbs';
import { AppUI } from '@seedlang/state';
import { isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  width: 100%;
`;

const TopBar = styled.div`
  background: ${props => props.background};
  display: flex;
  align-items: center;
  height: 50px;
  margin: 0 0 10px 0;
`;

const BreadcrumbsWrapper = styled.div`
  flex: 1;
`;

const TopBarTitle = styled.div`
  flex: 1;
  font-size: 16px;
  color: #333;
  font-weight: bold;
  padding-left: 10px;
`;

@observer
class ForumShow extends React.Component {

  @computed get forumId() {
    let forumId;
    if (this.props.params?.groupId && AppUI.groupStore.hasShowData && isPresent(AppUI.groupStore.showData.forums[0])) {
      forumId = AppUI.groupStore.showData.forums[0].id;
    }
    return this.props.forumId || this.props.params?.forumId || forumId;
  }

  @computed get createDiscussionRoute() {
    if (this.props.params?.groupId) {
      return 'classroom.forum_threads.create';
    } else if (this.props.languageId) {
      return 'translator.forum_threads.create';
    }
    return 'forums.forum_threads.create';
  }

  constructor(props) {
    super(props);
    ForumUI.loadForum(this.forumId);
  }

  render() {
    return (
      <Wrapper>
        <TopBar
          background={this.props.topBarBackground}
        >
          {
            this.props.topBarTitle &&
              <TopBarTitle>
                { this.props.topBarTitle}
              </TopBarTitle>
          }
          {
            !this.props.hideBreadcrumbs && !this.props.params?.groupId &&
              <BreadcrumbsWrapper>
                <Breadcrumbs
                  breadcrumbs={[
                    {name: 'All Discussions', routeToName: "forums.index"},
                    {name: ForumUI.forumStore.showData.title}
                  ]}
                />
              </BreadcrumbsWrapper>
          }
          {
            !ForumUI.user.guest && ForumUI.forumStore.hasShowData && !ForumUI.forum.hideCreateDiscussionButton &&
              <Button
                margin={this.props.buttonMargin}
                height="34px"
                fontSize="14px"
                onClick={() => ForumUI.routeStore.routeToNamed(this.createDiscussionRoute, {groupId: this.props.params?.groupId, languageId: this.props.languageId, forumId: this.forumId})}
              >
                Create Discussion
              </Button>
          }
        </TopBar>
        {
          ForumUI.forumStore.hasShowData &&
            <ForumThreadIndex
              forumId={this.forumId}
              groupId={this.props.params?.groupId}
              {...this.props}
            />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ForumShow);
