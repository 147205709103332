import React from 'react';
import SubscriptionEdit from 'pages/builder/subscriptions/subscription_edit';

class SiteSubscriptionEdit extends React.Component {
  render() {
    return (
      <SubscriptionEdit
        {...this.props}
        whiteLabeled
      />
    );
  }
}

export default SiteSubscriptionEdit;
