import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Modal from 'components/modal';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`

`;

const Link = styled.div`
  cursor: pointer;
  text-decoration: underline;
  font-size: 10px;
  line-height: 14px;
  color: #444;
  margin: ${props => props.margin};
`;

const Documentation = styled.div`
  font-size: 12px;
`;

@observer
class MarkdownLink extends React.Component {
  @observable showModal = false;

  render() {
    return (
      <Wrapper>
        {
          this.showModal &&
            <Modal
              onCloseModal={() => this.showModal = false}
              height={Math.min(AppUI.layout.viewportHeight - 100, 500)}
              width={Math.min(AppUI.layout.viewportWidth - 40, 500)}
            >
              <Documentation>
                <h2>Markdown Formatting</h2>

                <div>Here are some of our markdown styling options. Copy and paste some of this text into your post to see how it works, and then edit it to make it your own!</div>

                <h4>Header Text</h4>

                <div># Header Text (biggest)</div>
                <div>## Header Text (next biggest)</div>
                <div>### Header Text (next biggest)</div>

                <h4>Bolding and Italics</h4>

                <div>Italics with *asterisks* or _underscores_.</div>
                <div>Bold with **asterisks** or __underscores__.</div>
                <div>Italics and bold with **_asterisks and underscores_**.</div>

                <h4>Tables</h4>

                <div><a className='underline' href='https://www.tablesgenerator.com/markdown_tables' target='_blank'>Table Code Generator</a></div>

                <h4>Links</h4>

                <div>[I'm a link](https://www.google.com)</div>

                <h4>Images</h4>

                <div>![](https://github.com/adam-p/markdown-here/raw/master/src/common/images/icon48.png)</div>

                <h4>Horizontal Rules</h4>

                <div>Three or more dashes create a line: ---</div>
              </Documentation>
            </Modal>
        }
        <Link
          onClick={() => this.showModal = true}
          margin={this.props.margin}
        >
          Markdown Formatting
        </Link>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(MarkdownLink);
