import React from 'react';
import { observer } from 'mobx-react';
import { OnboardingNativeLanguageWrapper } from '@seedlang/hoc';
import InPlaceLanguageSelect from 'components/form/in_place_language_select';
import Text from 'components/text';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { AppUI } from '@seedlang/state';
import Button from 'components/button/button';
import { Theme, Languages } from '@seedlang/constants';
import ListSelect from 'components/list_select';
import OnboardingContent from 'components/onboarding/onboarding_content';
import OnboardingWrapper from 'components/onboarding/onboarding_wrapper';
import OnboardingIllustration from 'components/onboarding/onboarding_illustration';
import autobind from 'autobind-decorator';

const Continue = styled.div`
  margin-top: 20px;
`;

@observer
class OnboardingNativeLanguage extends React.Component {

  @autobind onSubmit() {
    this.props.onSubmit();
    const route = AppUI.user.guest || AppUI.user.signedOut ? 'onboarding.registration' : 'onboarding.referrer';
    AppUI.routeStore.routeToNamed(route, {languageId: this.props.params.languageId});
  }

  render() {
    return (
      <OnboardingWrapper>
        <OnboardingIllustration
          text={this.props.languageId !== 'EN' ? this.props.languageMessage : `What is your native language?`}
          languageId={AppUI.targetLanguageId}
          reaction={this.props.languageId !== 'EN' ? "happy" : "disappointed"}
          marginBottom={20}
        />
        <OnboardingContent>
          <ListSelect
            value={this.props.languageId}
            options={AppUI.AiLanguageOptionsForFilter}
            onSelect={this.props.onSetNativeLanguageId}
            fieldName="name"
          />
        </OnboardingContent>
        <Continue>
          <Button
            onClick={this.onSubmit}
            width='440px'
            height='60px'
          >
            Continue
          </Button>
        </Continue>
      </OnboardingWrapper>
    );
  }
}

export default OnboardingNativeLanguageWrapper(OnboardingNativeLanguage);
