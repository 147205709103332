"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = cents;
function cents(amount) {
  if (amount) {
    return amount.toString().slice(-2);
  }
}