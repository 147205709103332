import React from 'react';
import { observer } from 'mobx-react';
import { UserSubscriptionStore } from '@seedlang/stores';
import UserSearch from 'components/user_search';
import autobind from 'autobind-decorator';

@observer
class UserSubscriptionCreate extends React.Component {

  @autobind onSelectUser(user) {
    UserSubscriptionStore.create(
      {
        data: {
          subscriptionId: this.props.subscriptionId,
          userId: user.id,
        },
      },
      this.props.afterUpdate
    );
    this.refs.transactionId.value = '';
  }

  render() {
    return (
      <div className="payment-create row">
        <div className="col-xs-5">
          <UserSearch
            onSubmit={this.onSelectUser}
            placeholder="Add User by Email"
            displayField="name"
          />
        </div>
      </div>
    );
  }
}

export default UserSubscriptionCreate;
