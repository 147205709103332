"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getUserMedia;
var _constants = require("@seedlang/constants");
var _bowser = _interopRequireDefault(require("bowser"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
var VIDEO_DIMENSIONS = {
  width: {
    min: _constants.Constants.VIDEO_DIMENSIONS,
    max: _constants.Constants.VIDEO_DIMENSIONS
  },
  height: {
    min: _constants.Constants.VIDEO_DIMENSIONS,
    max: _constants.Constants.VIDEO_DIMENSIONS
  }
};
function getUserMedia() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var successCallback = arguments.length > 1 ? arguments[1] : undefined;
  var failureCallback = arguments.length > 2 ? arguments[2] : undefined;
  var constraints = {
    audio: {
      deviceId: params.audioSource ? {
        exact: params.audioSource
      } : undefined
    }
  };
  if (params.videoSource || params.video) {
    constraints.video = {
      deviceId: params.videoSource ? {
        exact: params.videoSource
      } : undefined
    };
    if (!_bowser["default"].safari) {
      constraints.video.width = VIDEO_DIMENSIONS.width;
      constraints.video.height = VIDEO_DIMENSIONS.height;
    }
  }
  navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
    successCallback(stream);
  })["catch"](function (err) {
    failureCallback();
  });
}