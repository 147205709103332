import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import StoriesProgress from 'components/stories/stories_progress';
import TreeShow from 'components/tree/tree_show';
import LearnLayout from 'pages/main/learn_layout';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  width: 100%;
`;

@observer
class Stories extends React.Component {

  render() {
    return (
      <Wrapper>
        <LearnLayout>
          <TreeShow
            treeSlug='master'
          />
          <StoriesProgress />
        </LearnLayout>
      </Wrapper>
    )
  }
}

export default ErrorBoundary(Stories);
