import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { SettingsIcon } from '@seedlang/icons';
import { pixify } from '@seedlang/utils';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  margin-left: 10px;
  position: ${(props) => props.isMobile ? 'initial' : 'relative'};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  .settings-icon {
    cursor: pointer;
    background: #585858;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 12px;
      fill: #DDD;
    }
  }
`;

const Header = styled.div`
  background: #DDD;
  padding: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  flex: 1;
  text-align: left;
  font-weight: bold;
`;

const Popup = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  top: ${(props) => props.isMobile ? 50 : 55}px;
  right: ${(props) => props.right};
  left: ${(props) => props.left};
  z-index: ${Theme.z["overlay-1"]};
  background: white;
  position: absolute;
  box-shadow: 0px 2px 7px rgba(0,0,0,0.25);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const Triangle = styled.div`
  right: ${(props) => props.right};
  position: absolute;
  width: 0;
  height: 0;
  top: ${(props) => props.isMobile ? -5 : -10}px;
  border-style: solid;
  border-width: 0 15px 15px 15px;
  border-color: transparent transparent #DDD transparent;
  z-index: ${Theme.z["overlay-1"]};
`;

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  font-size: 14px;
  height: ${(props) => props.width};
  width: ${(props) => props.width};
  background: ${props => props.background ? props.background : '#1499b7'};
`;

@observer
class HeaderPopup extends React.Component {

  render() {
    return (
      <Wrapper
        isMobile={AppUI.layout.isMobile}
        width={AppUI.layout.isMobile ? "35px" : "42px"}
        height={this.props.height}
      >
        <IconWrapper
          onClick={this.props.onClickIcon}
          background={this.props.background}
          width={AppUI.layout.isMobile ? "35px" : "42px"}
        >
          {this.props.icon}
        </IconWrapper>
        {
          this.props.popupOpened &&
            <Popup
              isMobile={AppUI.layout.isMobile}
              height={pixify(this.props.height)}
              width={AppUI.layout.isMobile ? '100%' : pixify(this.props.width)}
              right={AppUI.layout.isMobile ? 'auto' : pixify(this.props.right)}
              left={AppUI.layout.isMobile ? '0' : 'auto'}
            >
              <Triangle
                isMobile={AppUI.layout.isMobile}
                right={pixify(this.props.arrowRight)}
              />
              <Header>
                <Title>
                  {this.props.title}
                </Title>
                {
                  this.props.showSettings &&
                    <SettingsIcon
                      onClick={this.props.onClickSettings}
                    />
                }
              </Header>
              {this.props.children}
            </Popup>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(HeaderPopup);
