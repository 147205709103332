import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { SentenceStore } from '@seedlang/stores';
import { LevelStore } from '@seedlang/stores';
import { Link } from 'react-router';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import Paginator from 'components/paginator';
import SentenceCreate from 'pages/builder/sentences/sentence_create';
import DeleteButton from 'components/button/delete_button';
import Filter from 'components/backend_filter/filter';
import cx from 'classnames';
import SortableColumns from 'components/hoc/sortable_columns';
import Modal from 'components/modal';
import CheckIfTrue from 'components/check_if_true';
import SentenceWithCases from 'components/sentence_with_cases';
import InPlaceMediaUpload from 'components/form/in_place_media_upload';
import InPlaceVideoUpload from 'components/form/in_place_video_upload';
import autobind from 'autobind-decorator';
import Button from 'components/button/button';
import { isPresent } from '@seedlang/utils';
import styled from '@emotion/styled';
import Tag from 'components/tag';
import ReactTooltip from "react-tooltip";
import Checkbox from 'components/checkbox';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  .sentence-index {
    .source {
      margin-top: 5px;
      color: ${Theme.gray};
    }
    .concepts {
      font-size: 12px;
      margin: 0;
      li {
        margin: 0;
        a {
          color: #333;
        }
      }
    }
    .toggles {
      font-size: 14px;
      margin-bottom: 10px;
      .toggle {
        cursor: pointer;
        display: inline-block;
      }
    }
  }

`;

@observer
class SentenceIndex extends React.Component {
  @observable showCreate = false;
  @observable showSource = false;
  @observable showCases = !this.props.scoped;

  static defaultProps = {
    store: SentenceStore,
    hide: ["tag"],
    showSource: false,
  }

  componentWillUnmount() {
    if (!this.props.scoped) {
      this.props.store.clearIndexData();
    }
  }

  onDelete(id) {
    this.props.onDelete ? this.props.onDelete(id) : this.props.store.destroy({ ids: { sentenceId: id } });
  }

  onDeleteVideo(sentenceId) {
    SentenceStore.update({data: {video_clip_id: null}, ids: {sentenceId: sentenceId}}, this.afterUpdate)
  }

  onAfterApprovedClicked(resp) {
    if (this.props.store.filters.reviewed1 !== null) {
      this.props.store.getIndexFiltered();
    }
  }

  hide(column) {
    return isPresent(this.props.hide) && this.props.hide.indexOf(column) !== -1;
  }

  @autobind afterUpdate() {
    this.props.store.reloadIndexFiltered({ids: this.props.ids});
  }

  _note(item) {
    if (item.note) {
      return (
        <i className="fa fa-comment" />
      );
    }
    return (
      <i className="fa fa-comment-o" />
    );
  }

  render() {
    return (
      <Wrapper>
      <div className="sentence-index">
        {
          !this.props.scoped && this.showCreate &&
            <Modal
              onCloseModal={() => this.showCreate = false}
            >
              <SentenceCreate
                simplifiedLayout={this.props.simplifiedCreate}
                afterCreate={this.afterUpdate}
              />
            </Modal>
        }
        <div style={{display: 'flex'}}>
          <div style={{display: 'flex', width: '100%'}}>
            <div style={{width: 120}}>
              {
                !this.props.scoped && (
                  <button
                    className='gray-button'
                    onClick={() => this.showCreate = true}
                  >
                    <i className='fa fa-plus' />
                    Create
                  </button>
                )
              }
            </div>
            <div style={{flex: 1}}>
              {
                !this.props.hideFilter && !this.props.scoped && LevelStore.hasIndexData &&
                  <Filter
                    store={this.props.store}
                    defaultSort="-created_at"
                    queryStrings={{expanded: true, include_count: true}}
                    margin="0 0 10px 0"
                    namespace={this.props.filterNamespace || "sentence-index"}
                    filters={
                    [
                      {
                        type: 'text',
                        name: 'target_text',
                        label: 'Target',
                        default: '',
                        placeholder: 'Search for Sentence',
                      },
                      {
                        type: 'select',
                        name: 'language_id',
                        options: [{id: 'DE', name: 'German'}, {id: 'ES', name: 'Spanish'}, {id: 'FR', name: 'French'}],
                        labelField: 'name',
                        label: 'Language',
                        default: '',
                      },
                      {
                        type: 'date',
                        name: 'created_at',
                        label: 'Created At',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        label: 'Approved: Didactics 1',
                        name: 'reviewed2',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        label: 'Approved: Didactics 2',
                        name: 'reviewed3',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        name: 'reviewed1',
                        label: 'Approved: English',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        label: 'Has Valid Setup',
                        name: 'valid_setup',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        label: 'Public',
                        name: 'public',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        name: 'has_all_video_clips',
                        label: 'Has All Videos',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        name: 'video_clip_id',
                        label: 'Has Supplement Video',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        label: 'Trivia',
                        name: 'is_trivia_sentence',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        label: 'Triad',
                        name: 'is_triad_sentence',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        label: 'Has Deck',
                        name: 'has_deck',
                        default: '',
                      },
                      {
                        type: 'multi_select',
                        label: 'Level',
                        name: 'level_id',
                        options: LevelStore.indexData.filter(item => item.abbreviation !== 'N'),
                        default: LevelStore.pluckIndex('id'),
                        labelField: 'abbreviation',
                      },
                      {
                        type: 'boolean',
                        label: 'Interjection',
                        name: 'interjection_id',
                      },
                      {
                        type: 'text',
                        label: 'Show on Device',
                        name: 'show_on_device',
                      },
                      {
                        type: 'date',
                        name: 'created_at',
                        label: 'Created',
                        default: '',
                      },
                      {
                        type: 'number',
                        name: 'sources_count',
                        label: '# Sources',
                        default: '',
                      },
                    ]}
                  />
              }
            </div>
          </div>
        </div>
        <table className="table-wrapper admin-table">
          <thead>
            <tr>
              <th width="10" />
              {this.props.icon && <th width="40" />}
              <th width="40" />
              {
                this.props.sentencesMaintainOrdering && (
                  <th width="45">
                    Pos
                  </th>
                )
              }
              {
                !this.hide('createdAt') &&
                  <th
                    width="80"
                    className={cx(!this.props.sentencesMaintainOrdering && this.props.columnClassNames('created_at'), {sortable: !this.props.sentencesMaintainOrdering})}
                    onClick={() => !this.props.sentencesMaintainOrdering && this.props.onSortColumn('created_at')}
                  >
                    Created
                  </th>
              }
              {
                !this.hide('reviewed') &&
                  <th width="30" />
              }
              <th width="40" />
              <th
                className={cx(!this.props.sentencesMaintainOrdering && this.props.columnClassNames('target_text'), {sortable: !this.props.sentencesMaintainOrdering})}
                onClick={() => !this.props.sentencesMaintainOrdering && this.props.onSortColumn('target_text')}
              >
                {this.props.showSource ? 'Target / Source' : 'Target'}
              </th>
              {
                  !this.hide('ENSource') &&
                    <th>
                      EN source
                    </th>
              }
              {
                !this.hide('tag') &&
                  <th width="100">
                    Tag
                  </th>
              }
              {
                this.props.showVideoColumns &&
                  <th width="140">
                    Primary Video
                  </th>
              }
              {
                this.props.showVideoColumns && !this.props.hideSupplement &&
                  <th width="140">
                    Secondary Video
                  </th>
              }
              {
                this.props.showUseInFeedEntryColumn &&
                  <th width="50">
                    Feed
                  </th>
              }
              <th>
                Allvids
              </th>
              {
                !this.hide('public') &&
                  <th>
                    Public
                  </th>
              }
              <th>
                Lvl
              </th>
              <th />
              <th width="40" />
            </tr>
          </thead>
          <tbody>
            {
              this.props.store.indexData.map((item, index) => {
                return (
                  <tr
                    key={item.id}
                    className={cx({ invalid: !item.validSetup })}
                  >
                    <td>
                      {
                        this.props.showCheckbox &&
                          <Checkbox
                            onClick={() => this.props.onClickCheckbox(item.id)}
                            value={this.props.selectedSentenceIds.indexOf(item.id) !== -1}
                          />
                      }
                      {
                        !item.validSetup && (
                          <i
                            className="fa fa-warning"
                            title="This sentence has not been set up yet"
                          />
                        )
                      }
                    </td>
                    {
                      this.props.icon &&
                        <td>
                          <div
                            onClick={() => this.props.onClickIcon && this.props.onClickIcon(index)}
                          >
                            {this.props.icon}
                          </div>
                        </td>
                    }
                    <td>
                      <Link
                        to={{ name: this.props.routeName || 'builder.sentences.edit', params: { sentenceId: item.id } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    {
                      this.props.sentencesMaintainOrdering && (
                        <td>
                          <div className='text'>
                            <InPlaceText
                              defaultValue={item.position}
                              model="sentence_concepts"
                              field="position"
                              id={item.sentenceConceptId}
                              type="number"
                              step="1"
                              afterChange={this.props.onChangePosition}
                            />
                          </div>
                        </td>
                      )
                    }
                    {
                      !this.hide('createdAt') &&
                        <td>
                          <div className="text small">
                            {item.createdAt?.formattedDateWithYear}
                          </div>
                        </td>
                    }
                    {
                      !this.hide('reviewed') &&
                        <td>
                          <div className="text" style={{width: 60}}>
                            <span data-tip data-for={`didactics-${item.id}`} style={{marginRight: 4}}>
                              <InPlaceCheckbox
                                model="sentences"
                                field="reviewed2"
                                value={item.reviewed2}
                                id={item.id}
                                icon="thumbs-up"
                                afterChange={this.onAfterApprovedClicked.bind(this)}
                              />
                              <ReactTooltip
                                place="top"
                                type="dark"
                                effect="solid"
                                id={`didactics-${item.id}`}
                                class="custom-tooltip"
                              >
                                Approved: Didactics 1
                              </ReactTooltip>
                            </span>
                            <span data-tip data-for={`translation-${item.id}`} style={{marginRight: 4}}>
                              <InPlaceCheckbox
                                model="sentences"
                                field="reviewed3"
                                value={item.reviewed3}
                                id={item.id}
                                icon="thumbs-up"
                                afterChange={this.onAfterApprovedClicked.bind(this)}
                              />
                              <ReactTooltip
                                place="top"
                                type="dark"
                                effect="solid"
                                id={`translation-${item.id}`}
                                class="custom-tooltip"
                              >
                                Approved: Didactics 2
                              </ReactTooltip>
                            </span>
                            <span data-tip data-for={`content-${item.id}`} style={{marginRight: 4}}>
                              <InPlaceCheckbox
                                model="sentences"
                                field="reviewed1"
                                value={item.reviewed1}
                                id={item.id}
                                icon="thumbs-up"
                                afterChange={this.onAfterApprovedClicked.bind(this)}
                              />
                              <ReactTooltip
                                place="top"
                                type="dark"
                                effect="solid"
                                id={`content-${item.id}`}
                                class="custom-tooltip"
                              >
                                Approved: English
                              </ReactTooltip>
                            </span>
                          </div>
                        </td>
                    }
                    <td>
                      {item.languageId}
                    </td>
                    <td>
                      <div className="text">
                        {!this.showCases && item.targetText}
                        {
                          this.showCases &&
                            <SentenceWithCases
                              sentence={item}
                            />
                        }
                      </div>
                      {
                        this.props.showSource &&
                          <div className='literal-source source'>
                            {item.literalSource}
                          </div>
                      }
                      {
                        this.props.showSource &&
                          <div className='idiomatic-source source'>
                            {item.sourceText}
                          </div>
                      }
                    </td>
                    {
                        !this.hide('ENSource') &&
                        <td>
                          {
                              isPresent(item.sourceText) &&
                                <div className="text">
                                  {item.sourceText}
                                </div>
                          }
                        </td>
                    }
                    {
                        !this.hide('tag') &&
                        <td>
                          {
                              isPresent(item.tag) &&
                              <Tag
                                  text={item.tag}
                              />
                          }
                        </td>
                    }
                    {
                      this.props.showVideoColumns && item.target &&
                        <td
                          style={{verticalAlign: 'top'}}
                        >
                          <InPlaceVideoUpload
                            model="video_clips"
                            removeModel="target"
                            removeId={item.target?.id}
                            videoClip={item.target?.videoClip}
                            onChange={this.props.onChangeMedia}
                            querystring={{
                              videoable_id: item.target?.id,
                              videoable_type: "Target",
                            }}
                          />
                        </td>
                    }
                    {
                      this.props.showVideoColumns && !this.props.hideSupplement &&
                        <td>
                          <InPlaceMediaUpload
                            autoPlay
                            simplified
                            loop
                            muted
                            image={item.image}
                            onDeleteVideo={() => this.onDeleteVideo(item.id)}
                            afterChange={this.props.onChangeMedia}
                            onChange={this.props.onChangeMedia}
                            mediaId={item.id}
                            mediaType="Sentence"
                            videoClip={item.supplementVideoClip}
                          />
                          {
                            this.props.conceptId && !item.supplementVideoClip && !item.image &&
                              <Button
                                margin="10px 0 0 0"
                                onClick={() => SentenceStore.carryOverMedia({ids: {sentenceId: item.id}, data: {conceptId: this.props.conceptId}}, this.afterUpdate)}
                              >
                                Carry Over
                              </Button>
                          }
                        </td>
                    }
                    {
                      this.props.showUseInFeedEntryColumn &&
                        <td>
                          <InPlaceCheckbox
                            model="sentence"
                            field="use_in_feed_entry"
                            value={item.useInFeedEntry}
                            id={item.id}
                            icon="check"
                          />
                        </td>
                    }
                    <td>
                      <CheckIfTrue
                        circle
                        value={item.hasAllVideoClips}
                      />
                    </td>
                    {
                      !this.hide('public') &&
                        <td>
                          <InPlaceCheckbox
                            id={item.id}
                            value={item.public}
                            model="sentence"
                            field="public"
                            icon="check"
                          />
                        </td>
                    }
                    <td>
                      <div className="text">
                        {item.levelAbbreviation}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {this._note(item)}
                      </div>
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={this.onDelete.bind(this, item.id)}
                        message={this.props.deletePrompt || "Delete this sentence?"}
                        className="fa-block right"
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={this.props.store}
          ids={this.props.ids}
        />
      </div>
      </Wrapper>
    );
  }
}

export default SortableColumns(SentenceIndex, SentenceStore, { sortColumn: 'created_at', sortIsAscending: false });
