import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import InPlaceTextInput from 'components/form/in_place_text_input';
import { isPresent } from '@seedlang/utils';
import { flexCenter } from '@seedlang/style_mixins';
import SpecialCharacterButtons from 'components/special_character_buttons';
import { Theme } from '@seedlang/constants';
import autobind from 'autobind-decorator';
import { AppUI } from "@seedlang/state";

const Wrapper = styled.div`
  ${flexCenter()}
  width: 100%;
  min-width: 200px;
`;

const InputWrapper = styled.div`
  ${flexCenter()}
  background: ${props => props.background};
  padding: 0 10px;
  border: 3px solid #CCC;
  border-radius: 45px;
  flex: 1;
  .fa-times {
    cursor: pointer;
  }
  i {
    margin-right: 5px;
    color: #BBB;
  }
  input {
    flex: 1;
    border: none;
    padding: 10px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  input::placeholder {
    font-size: 12px;
  }
`

const CloseIcon = styled.div`
  ${flexCenter()}
  background: ${Theme.red};
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  i {
    color: #FFF;
    margin: 0;
  }
`;

const ExpandIcon = styled.div`
  ${flexCenter()}
  text-decoration: underline;
  cursor: pointer;
  width: 30px;
`;

const CharacterWrapper = styled.div`
  ${flexCenter()}
  margin-left: 5px;
  i {
    cursor: pointer;
    margin: 0 5px;
  }
`;

@observer
class SearchInput extends React.Component {
  @observable expanded = false;

  @autobind onChange(text) {
    text = text.replace(/^\s+/, '');
    this.props.onChange(text);
  }

  @autobind insertText(val) {
    const el = document.getElementById("vocab-trainer-input");
    if (el) {
      const caretPosition = el.selectionStart;
      this.props.insertText(val, caretPosition);
    }
  }

  render() {
    return (
      <Wrapper>
        <InputWrapper
          background={this.props.background}
        >
          <i className='fa fa-search' />
          <InPlaceTextInput
            onChange={this.onChange}
            id='vocab-trainer-input'
            caretPosition={this.props.caretPosition}
            placeholder={this.props.placeholder}
            focusOnId={this.props.value}
            value={this.props.value}
          />
          {
            isPresent(this.props.value) &&
              <CloseIcon
                onClick={() => this.props.onChange(null)}
              >
                <i
                  className='fa fa-times'
                />
              </CloseIcon>
          }
        </InputWrapper>
        {
          !this.expanded && !this.props.hideSpecialCharacters &&
            <ExpandIcon
              onClick={() => this.expanded = true}
            >
              {AppUI.specialCharacterList[0]}
            </ExpandIcon>
        }
        {
          this.expanded &&
            <CharacterWrapper>
              <SpecialCharacterButtons
                center
                onClick={this.insertText}
                padding="5px 3px"
                border="1px solid grey"
                lineHeight="10px"
                fontSize="14px"
              />
              <i className='fa fa-times'
                onClick={() => this.expanded = false}
              />
            </CharacterWrapper>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SearchInput);
