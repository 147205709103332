import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { ReviewDecksWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import ReviewDeckLink from 'components/review/review_deck_link';
import { Theme } from '@seedlang/constants';
import Modal from 'components/modal';
import ReviewDeckOptions from 'components/review/review_deck_options';
import ReviewDeckSettings from 'components/review/review_deck_settings';
import Spinner from 'components/spinner';
import { AppUI } from '@seedlang/state';
import { pixify } from '@seedlang/utils';
import Text from 'components/text';
import { isBlank } from '@seedlang/utils';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const DeckWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: auto;
  flex-direction: row;
  display: flex;
  height: 310px;
  overflow-y: hidden;
`;

const ScrollWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  height: 320px;
  top: 0;
  left: 0;
  padding: 10px 20px 0 20px;
  justify-content: ${props => props.justifyContent};
`;

const NewDeckLinkInner = styled.div`
  width: ${props => props.width};
`;

const NewDeckLink = styled.div`
  border: 5px dotted ${Theme.blue};
  height: ${props => props.height};
  width: ${props => props.width};
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 44px;
  border-top-right-radius: 44px;
  background: #00b8db5c;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    color: ${Theme.blue};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ReviewDeckLinkWrapper = styled.div`
  height: 320px;
`;

const InformationWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #a9a9a9;
  .fa-question-circle {
    font-size: 40px;
    margin-bottom: 20px;
  }
`;

@observer
class ReviewDecks extends React.Component {

  @computed get deckWidthFitsInContainer() {
    return (this.props.filteredReviewDecks.length + 1) * (this.props.deckWidth + 10) < AppUI.layout.leftColumnWidth;
  }

  render () {
    return (
      <Wrapper>
        {
          this.props.showDeckOptions &&
            <Modal
              onClickGearIcon={this.props.onToggleEdit}
              onCloseModal={this.props.closeOptionsAndUpdate}
              top={AppUI.layout.isMobile ? "60px" : "100px"}
              width={pixify(AppUI.layout.defaultModalWidth)}
              gradient
              title={`'${this.props.selectedDeck.name || 'Default'}' Deck`}
              maxHeight={AppUI.layout.viewportHeight * 0.8}
              contentShouldScroll
            >
              <ReviewDeckOptions
                deck={this.props.selectedDeck}
                closeOptionsAndUpdate={this.props.closeOptionsAndUpdate}
                edit={this.props.edit}
                onToggleEdit={this.props.onToggleEdit}
                refreshSelectedDeck={this.props.refreshSelectedDeck}
              />
            </Modal>
        }
        {
          this.props.showDeckSettings &&
            <Modal
              onCloseModal={this.props.closeOptionsAndUpdate}
              top="100px"
              width="400px"
              gradient
              title="Create a Review Deck"
            >
              <ReviewDeckSettings
                deck={this.props.selectedDeck}
                afterCreateDeck={this.props.afterCreateDeck}
                closeOptionsAndUpdate={this.props.closeOptionsAndUpdate}
                refreshSelectedDeck={this.props.refreshSelectedDeck}
              />
            </Modal>
        }
        <DeckWrapper>
          {
            this.props.showSpinner &&
              <Spinner
                background={Theme.blue}
              />
          }
          {
            !this.props.showSpinner && this.props.archive && isBlank(this.props.filteredReviewDecks) &&
            <InformationWrapper>
              <i className='fa fa-question-circle' />
              <Text color="#a9a9a9">You currently do not have any archived review decks.</Text>
              <Text color="#a9a9a9">Review decks can be archived in their settings.</Text>
            </InformationWrapper>
          }
          {
            !this.props.showSpinner &&
              <ScrollWrapper
                justifyContent={this.deckWidthFitsInContainer ? 'center' : 'flex-start'}
              >
                {
                  this.props.filteredReviewDecks.map(item => {
                    return (
                      <ReviewDeckLinkWrapper
                        key={item.id}
                      >
                        <ReviewDeckLink
                          deck={item}
                          width={this.props.deckWidth}
                          iconWidth={AppUI.layout.isMobile || AppUI.layout.minimumHeight ? 40 : 60}
                          onClick={() => this.props.onSelectDeck(item)}
                        />
                      </ReviewDeckLinkWrapper>
                    )
                  })
                }
                {
                  !this.props.archive &&
                    <NewDeckLink
                      onClick={() => this.props.onSetShowDeckSettings(true)}
                      width={pixify(this.props.deckWidth)}
                      height={AppUI.layout.isMobile || AppUI.layout.minimumHeight ? "260px" : "280px"}
                    >
                      <NewDeckLinkInner
                        width={pixify(this.props.deckWidth)}
                      >
                        <IconWrapper>
                          <i className='fa fa-plus'/>
                        </IconWrapper>
                      </NewDeckLinkInner>
                    </NewDeckLink>
                }
              </ScrollWrapper>
          }
        </DeckWrapper>
      </Wrapper>
    );
  }
}

export default ReviewDecksWrapper(ReviewDecks);
