import React from 'react';
import { observer } from 'mobx-react';
import SentenceEdit from 'pages/builder/sentences/sentence_edit';

@observer
class SentenceShow extends React.Component {

  render() {
    return (
      <div className='creator-sentence-show'>
        <SentenceEdit
          routeParams={this.props.routeParams}
          hide={['concepts']}
        />
      </div>
    );
  }
}

export default SentenceShow;
