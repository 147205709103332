import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import key from 'keymaster';
import styled from '@emotion/styled'
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`

`;

const ModalBackground = styled.div`
  position: ${(props) => props.position};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: ${Theme.z["modal-background"]};
`;

const Modal = styled.div`
  width: ${(props) => props.width};
  top: ${(props) => props.top};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  position: fixed;
  background: white;
  border-radius: 10px;
  z-index: ${Theme.z["modal-foreground"]};
  overflow: auto;
  margin: 0 auto;
  left: 0;
  right: 0;
`;

const ModalWrapper = (Component, params) => class extends React.Component { // eslint-disable-line react/display-name

  static propTypes = {
    onCloseModal: PropTypes.func,
    displayAsModal: PropTypes.bool,
  }

  static defaultProps = {
    displayAsModal: true,
    modalBackgroundPosition: 'fixed',
    width: '660px',
    top: '50px',
    padding: '20px',
  }

  componentDidMount() {
    key('escape', this.onCloseModal);
  }

  componentWillUnmount() {
    key.unbind('escape', this.onCloseModal.bind(this));
  }

  @autobind onCloseModal() {
    if (this.props.onCloseModal) {
      this.props.onCloseModal();
    }
  }

  _content() {
    if (this.props.displayAsModal) {
      return (
        <Wrapper>
          <ModalBackground
            onClick={this.onCloseModal}
            position={this.props.modalBackgroundPosition}
          />
          <Modal
            padding={params && (params.padding || this.props.padding)}
            width={params && (params.width || this.props.width)}
            top={params && (params.top || this.props.top)}
            height={params && (params.height || this.props.height)}
          >
            <Component {...this.props} {...this.state} />
          </Modal>
        </Wrapper>
      )
    }
    return <Component {...this.props} {...this.state} />;
  }

  render() {
    return this._content();
  }
};

ModalWrapper.displayName = 'ModalWrapper';
export default ModalWrapper;
