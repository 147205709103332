import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { AppUI } from '@seedlang/state';
import { SubscriptionChangeStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import Button from 'components/button/button';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import Spinner from 'components/spinner';
import Text from 'components/text';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router';

const Wrapper = styled.div`
`;

const BorderedWrapper = styled.div`
  background: white;
  padding: 20px;
  margin: 50px;
  border: 20px solid ${props => props.borderColor};
  border-radius: 10px;
  text-align: center;
  @media only screen and (max-width : 649px) {
    margin: 40px 20px;
  }
`;

@observer
class SubscriptionChangeShow extends React.Component {
  refreshInterval = 1000;
  refreshTimer = null;

  @observable isLoading = false;

  constructor(props) {
    super(props);
    this.loadData();
  }

  get mode() {
    return AppUI.routeStore.queryParam('mode');
  }

  async loadData() {
    this.isLoading = true;
    await SubscriptionChangeStore.getShowPromise();
    this.isLoading = false;

    this.periodicallyRefreshIfNeeded();
  }

  periodicallyRefreshIfNeeded() {
    if (this.subscriptionChange?.status === 'stripe_payment_setup_started') {
      this.refreshTimer = setTimeout(async () => {
        await SubscriptionChangeStore.getShowPromise();
        this.refreshInterval *= 1.5;
        this.periodicallyRefreshIfNeeded();
      }, this.refreshInterval);
    }
  }

  @computed get subscriptionChange() {
    return SubscriptionChangeStore.showData;
  }

  @autobind routeToHome() {
    if (AppUI.siteIsDefault) {
      AppUI.routeStore.routeToNamed('stories.home');
    } else {
      AppUI.routeStore.routeToNamed('worksheets.index');
    }
  }

  render() {
    return (
      <Wrapper>
        {
          this.isLoading ? <Spinner background={AppUI.site.accentColor || Theme.blue} /> : (
            <BorderedWrapper
              borderColor={AppUI.siteIsDefault ? Theme.blue : AppUI.site.accentColor}
              isMobile={AppUI.layout.isMobile}
            >
              {
                this.mode === 'payment_setup_cancelled' ? (
                  this.renderCancelledState()
                ) : this.subscriptionChange?.status === 'stripe_payment_setup_started' ? (
                  this.renderWaitingState()
                ) :
                this.subscriptionChange?.status === 'scheduled' ? (
                  this.renderScheduledState()
                ) : (
                  this.renderUnexpectedState()
                )
              }

            </BorderedWrapper>
          )
        }
      </Wrapper>
    );
  }

  renderCancelledState() {
    return (
      <>
        <Text>
          The payment method setup was cancelled.
        </Text>

        {this.renderSentenceWithLinkToBilling()}

        <Button
          onClick={this.routeToHome}
          margin="20px 0 10px 0"
        >
          Back to Home
        </Button>
      </>
    )
  }

  renderWaitingState() {
    return (
      <>
        <Text>
          Waiting for confirmation from the payment provider. This should only take a few seconds...
        </Text>

        {this.renderSentenceWithLinkToBilling()}
      </>
    )
  }

  renderScheduledState() {
    return (
      <>
        <h3>Thank you for your subscription!</h3>

        <Text>
          Your payment details have been recorded. Your subscription will change on {this.subscriptionChange.effectiveAt.formattedDateWithYear}.
        </Text>

        {this.renderSentenceWithLinkToBilling()}

        <Button
          onClick={this.routeToHome}
          margin="20px 0 10px 0"
        >
          View Content
        </Button>
      </>
    )
  }

  renderUnexpectedState() {
    return (
      <>
        <Text>Something went wrong.</Text>

        {this.renderSentenceWithLinkToBilling()}

        <Link
          to={{ name: 'memberships.index' }}
        >
          <Button
            margin="10px 0 0 0"
            background={AppUI.site.accentColor || Theme.blue}
          >
            Update Your Membership Tier
          </Button>
        </Link>
      </>
    )
  }

  renderSentenceWithLinkToBilling() {
    return (
      <Text margin="10px 0 0 0">
        <span>You can view your subscription status in the </span>
        <Link
          to={{ name: 'settings.billing' }}
          className='underline'
        >
          Billing
        </Link>
        <span> section.</span>
      </Text>
    )
  }
}

export default ErrorBoundary(SubscriptionChangeShow);
