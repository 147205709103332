import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { WordStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';
import WordSearch from 'components/word_search';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import styled from '@emotion/styled';
import { isFunction } from "lodash";

const Wrapper = styled.div`
  input {
    width: 100px;
  }
  .word-search {
    display: inline-block;
  }
  .in-place-checkbox {
    display: inline-block;
    margin: 3px 0 0 3px;
  }
`;

@observer
class RootWordEdit extends React.Component {

  @computed get filters() {
    let filters = {root: true, language_id: this.props.word?.languageId};
    return filters;
  }

  @autobind updateWord(data) {
    WordStore.update({ ids: {wordId: this.props.word.id}, data: data }, this.props.onChange);
  }

  @autobind onRemoveRootWord() {
    if (!this.props.blockEdit) {
      this.updateWord({root_word_id: null});
    }
  }

  @autobind onSubmit(word) {
    if (!word) { return null }
    this.updateWord({root_word_id: word.id});
  }

  @autobind onCreateValue(targetText) {
    WordStore.create({data: {
      root: true,
      target_text: targetText,
      language_id: this.props.word?.languageId,
      root_word_type_id: this.props.word?.rootWordType?.id,
      word_type_id: this.props.word?.rootWordType?.id,
    }}, this.afterCreateValue)
  }

  @autobind afterCreateValue(resp) {
    this.updateWord({root_word_id: resp.word.id});
    if (isPresent(this.props.afterCreate) && isFunction(this.props.afterCreate)) {
      this.props.afterCreate(resp);
    }
  }

  render() {
    if (isPresent(this.props.word.rootWord)) {
      return (
        <div
          className={!this.props.blockEdit ? "editable" : ""}
          onClick={this.onRemoveRootWord}
        >
          <Link
            to={{name: 'builder.words.edit', params: {wordId: this.props.word.rootWord.id}}}
          >
            {this.props.word.rootWord.targetText}
          </Link>
        </div>
      );
    }
    return (
      <Wrapper>
        <WordSearch
          onSubmit={this.onSubmit}
          onCreateValue={this.onCreateValue}
          displayField="targetTextWithDefiniteArticleAndSourceAndWordType"
          placeholder="Enter Root"
          filters={this.filters}
        />
      </Wrapper>
    )
  }
}

export default RootWordEdit;
