import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { isBlank, isPresent } from '@seedlang/utils';
import { isString } from 'lodash';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  width: 100%;
`;

const CollapsedValue = styled.div`
  position: relative;
  font-size: 14px;
  border: 1px solid transparent;
  line-height: 18px;
  border: 1px solid #cacaca;
  padding: 5px;
  cursor: pointer;
  .fa-caret-down {
    position: absolute;
    right: 10px;
    top: 4px;
    color: #cacaca;
    font-size: 18px;
  }
  &:hover {
    background: whitesmoke;
  }
`;

const OptionWrapper = styled.div`

`;

const Option = styled.div`
  background: ${props => props.background};
  color: ${props => props.color};
  border: ${props => props.border};
  border-radius: 10px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
  padding: 15px 0;
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    background: ${Theme.green};
    color: #FFF;
  }
`;

const Description = styled.div`
  font-size: 13px;
  font-style: italic;
`;

const Value = styled.div`

`;

@observer
class ListSelect extends React.Component {

  staticPropTypes = {
    onSelect: PropTypes.func,
    onClear: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  }

  onSelect(value) {
    this.props.onSelect(value);
  }


  _value() {
    if (isPresent(this.props.value)) {
      if (isString(this.props.options[0])) {
        return this.props.value;
      }
      const obj = this.props.options.find(item => item.id === this.props.value);
      if (isPresent(obj)) {
        return obj.name;
      } else if (isPresent(this.props.options)) {
        console.warn('ListSelect could not find a value', this.props.value, this.props.options)
      }
    }
  }

  background(selected, index) {
    if (!selected && this.props.backgroundColors) {
      return this.props.backgroundColors[index % (this.props.backgroundColors.length)];
    }
    return selected ? Theme.green : '#FFF';
  }

  color(selected, index) {
    const background = this.background(selected, index);
    return background === "#FFF" ? "#333" : "#FFF";
  }

  border(selected, index) {
    const background = this.background(selected, index);
    return background === "#FFF" ? "2px solid #CCC" : "2px solid transparent";
  }

  render() {
    return (
      <Wrapper>
        {
          isPresent(this.props.value) && this.props.collapseValue &&
            <CollapsedValue
              onClick={() => this.props.onSelect(null)}
            >
              {this._value()}
              <i className='fa fa-caret-down' />
            </CollapsedValue>
        }
        {
          (!this.props.collapseValue || (isBlank(this.props.value) && isPresent(this.props.options))) &&
            <OptionWrapper>
              {
                this.props.options && this.props.options.map((item, index) => {
                  return (
                    <Option
                      key={item.id}
                      onClick={() => this.onSelect(item.id)}
                      background={this.background(item.id === this.props.value, index)}
                      color={this.color(item.id === this.props.value, index)}
                      border={this.border(item.id === this.props.value, index)}
                    >
                      <Value>
                        {isPresent(this.props.fieldName) ? item[this.props.fieldName] : item.name}
                      </Value>
                      {
                        item.description &&
                          <Description>
                            {item.description}
                          </Description>
                      }
                    </Option>
                  );
                })
              }
            </OptionWrapper>
        }
      </Wrapper>
    );
  }
}

export default ListSelect;
