import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { AppUI } from '@seedlang/state';
import NotificationsWithPopup from 'components/notification/notifications_with_popup';
import StreakWithPopup from 'components/streak_with_popup';
import { Link } from 'react-router';
import { CrownIcon, SeedlangIconWithCircle } from '@seedlang/icons';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import HeaderButton from 'components/button/header_button';
import Modal from 'components/modal';
import LanguagePicker from 'components/language_picker';
import { LanguageIcon } from '@seedlang/icons';
import { HeaderWrapper } from '@seedlang/hoc';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`
  z-index: ${Theme.z["topmost-under-overlay"]};
  background: ${Theme.siteHeaderColor};
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 0 10px;
  .seedlang-icon-with-circle {
    svg {
      width: 35px;
      height: 35px;
    }
  }
`;

const Buttons = styled.div`
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
`;

const ButtonText = styled.span`
  margin-left: 5px;
`;

const LanguageIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  cursor: pointer;
  margin-left: 10px;
`;

@observer
class Header extends React.Component {
  @observable showLanguagePicker = false;

  @autobind onChangeTargetLanguageId(languageId) {
    this.showLanguagePicker = false;
    this.props.onChangeTargetLanguageId(languageId);
  }

  render() {
    return (
      <Wrapper>
        {
          this.showLanguagePicker &&
            <Modal
              width="400px"
              height="300px"
              onCloseModal={() => this.showLanguagePicker = false}
              gradient
              padding="0"
              title="Select a Language"
              contentShouldScroll
            >
              <LanguagePicker
                languageOptions={['DE', 'ES', 'FR']}
                onClick={this.onChangeTargetLanguageId}
              />
            </Modal>
        }
        <SeedlangIconWithCircle
          onClick={() => AppUI.routeStore.routeToNamed('home')}
          style={{cursor: "pointer"}}
        />
        <Buttons>
          {
            !AppUI.userIsLoading && AppUI.user.signedOut &&
              <Link
                to={{name: 'onboarding.sign_in'}}
              >
                <HeaderButton>
                  Sign In
                </HeaderButton>
              </Link>
          }
          {
            !AppUI.user.signedOut && !AppUI.userIsLoading && !AppUI.user.registered &&
              <Link
                to={{name: 'onboarding.registration'}}
              >
                <HeaderButton>
                  Register
                </HeaderButton>
              </Link>
          }
          {
            !AppUI.user.isMember && !AppUI.user.signedOut &&
                <HeaderButton
                  onClick={() => AppUI.routeStore.routeToNamed('memberships.index')}
                >
                  <CrownIcon
                    width={AppUI.layout.isMobile ? "14px" : "19px"}
                  />
                  <ButtonText>
                    Go Pro
                  </ButtonText>
              </HeaderButton>
          }
          {
            AppUI.userIsLoaded &&
              <LanguageIconWrapper
                onClick={() => this.showLanguagePicker = true}
              >
                <LanguageIcon
                  width="25px"
                  languageId={AppUI.targetLanguage.languageId || 'DE'}
                />
              </LanguageIconWrapper>
          }
          {
            AppUI.userIsLoaded && !AppUI.user.guest &&
              <NotificationsWithPopup />

          }
          {
            AppUI.userIsLoaded && !AppUI.user.guest &&
              <StreakWithPopup />
          }
        </Buttons>
      </Wrapper>
    );
  }
}

export default HeaderWrapper(Header);
