import styled from "@emotion/styled";
import {observer} from "mobx-react";
import React from "react";
import Button from "components/button/button";
import {Theme} from "@seedlang/constants";
import PropTypes from "prop-types";
import uuid from "uuid/v4";


const ConfirmationPopUp = styled.div`
  z-index: ${Theme.z["modal-background"]};
  right: ${(props) => props.right};
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  position: absolute;
  background: white;
  border: 1px solid #cccccc;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:after, &:before {
    left: ${(props) => props.floatsRight ? 'auto' : "100%"};
    right: ${(props) => props.floatsRight ? '100%' : "auto"};
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: ${(props) => props.floatsRight ? 'transparent' : 'white'};
    border-right-color: ${(props) => props.floatsRight ? 'white' : 'transparent'};
    border-width: 10px;
    margin-top: -10px;
  }
  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: ${(props) => props.floatsRight ? 'transparent' : '#cccccc'};
    border-right-color: ${(props) => props.floatsRight ? '#cccccc' : 'transparent'};
    border-width: 11px;
    margin-top: -11px;
  }
`;

const Background = styled.div`
  z-index: ${Theme.z["background"]};
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

const Message = styled.div`
  color: black;
  white-space: nowrap;
`;

const Buttons = styled.div`
  text-align: center;
`;

@observer
class Confirmation extends React.Component {

  static propTypes = {
    close: PropTypes.func,
    floatsRight: PropTypes.bool,
    confirm: PropTypes.func,
  }

  static defaultProps = {
    right: "40px",
    left: "auto",
    top: "-30px",
  }

  constructor(props) {
    super(props);
    this.id = uuid();
  }

  render() {
    return (
      <div>
        <Background
          onClick={this.props.close}
        />
        <ConfirmationPopUp
          left={this.props.left}
          right={this.props.right}
          top={this.props.top}
          floatsRight={this.props.floatsRight}
        >
          <Message>
            {this.props.message}
          </Message>
          {
            !this.props.showCloseButton &&
              <Buttons>
                <Button
                  borderRadius="5px"
                  fontSize="14px"
                  height="35px"
                  padding="0 10px"
                  margin="2px 2px 0px 2px"
                  background={Theme.green}
                  onClick={this.props.onConfirm}
                >
                  Yes
                </Button>
                <Button
                  borderRadius="5px"
                  fontSize="14px"
                  height="35px"
                  padding="0 10px"
                  margin="2px 2px 0px 2px"
                  background={Theme.red}
                  onClick={this.props.close}
                >
                  No
                </Button>
              </Buttons>
          }
          {
            this.props.showCloseButton &&
              <Buttons>
                <Button
                  borderRadius="5px"
                  fontSize="14px"
                  height="35px"
                  padding="0 10px"
                  margin="2px 2px 0px 2px"
                  background={Theme.red}
                  onClick={this.props.close}
                >
                  Close
                </Button>
              </Buttons>
          }
        </ConfirmationPopUp>
      </div>
    );
  }
}

export default Confirmation;