import React from 'react';
import { observer } from 'mobx-react';
import { EvaluateButtonsWrapper } from '@seedlang/hoc';
import { DeckUI } from '@seedlang/state';
import { Constants, Theme } from '@seedlang/constants';
import { BigSmileFaceIcon, NeutralFaceIcon, SmileFaceIcon } from '@seedlang/icons';
import CardButtons from 'components/card/card_buttons';
import CardButton from 'components/card/card_button';
import NextButton from 'components/button/next_button';

@observer
class EvaluateButtons extends React.Component {

  render() {
    if (!DeckUI.reviewDeck) {
      return (
        <CardButtons>
          <NextButton
            disabled={this.props.disabled}
            onClick={this.props.onNextClick}
            submitOnEnter
            shortcut="1"
          />
        </CardButtons>
      )
    }
    if (DeckUI.reviewDeck) {
      return (
        <CardButtons>
          {
            this.props.showHardButton &&
              <CardButton
                shortcut="1"
                text='Hard'
                backgroundColor={Theme.darkestGreen}
                icon={<NeutralFaceIcon />}
                onClick={() => this.props.advanceCard(this.props.card, this.props.cardState, this.props.userCard, {score: Constants.BAD_SCORE, skipSoundEffect: true, xp: 0, xpXPos: DeckUI.layout.quarterDeckWidth * 3})}
                subText={this.props.card.formattedReviewInterval(Constants.BAD_SCORE)}
                disabled={this.props.disabled}
                marginRight={5}
              />
          }
          {
            !this.props.mergeRetireButtons &&
              <CardButton
                shortcut={this.props.showHardButton ? "2" : "1"}
                text={this.props.goodButtonRetiresCard ? "Retire" : "Good"}
                backgroundColor={this.props.goodButtonRetiresCard ? Theme.purple : this.props.showHardButton ? Theme.mediumGreen : Theme.darkestGreen}
                icon={<SmileFaceIcon/>}
                onClick={() => this.props.advanceCard(this.props.card, this.props.cardState, this.props.userCard, {
                  score: Constants.GOOD_SCORE,
                  xp: 2,
                  xpXPos: DeckUI.layout.quarterDeckWidth * 1
                })}
                subText={this.props.goodButtonRetiresCard ? `> ${this.props.daysUntilReviewRetired} days` : this.props.card.formattedReviewInterval(Constants.GOOD_SCORE)}
                disabled={this.props.disabled}
                marginRight={5}
                marginLeft={5}
              />
          }
          {
            this.props.showEasyButton &&
              <CardButton
                shortcut={this.props.showHardButton && !this.props.mergeRetireButtons ? "3" : "2"}
                marginLeft={5}
                text={this.props.easyButtonRetiresCard ? "Retire" : "Easy"}
                backgroundColor={this.props.easyButtonRetiresCard ? Theme.purple : Theme.green}
                icon={<BigSmileFaceIcon />}
                onClick={() => this.props.advanceCard(this.props.card, this.props.cardState, this.props.userCard, {score: Constants.EASY_SCORE, xp: 2, xpXPos: DeckUI.layout.quarterDeckWidth * 1})}
                subText={this.props.easyButtonRetiresCard ? `> ${this.props.daysUntilReviewRetired} days` : this.props.card.formattedReviewInterval(Constants.EASY_SCORE)}
                disabled={this.props.disabled}
              />
          }
        </CardButtons>
      );
    }
  }
}

export default EvaluateButtonsWrapper(EvaluateButtons);
