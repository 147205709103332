import React from 'react';
import { observer } from 'mobx-react';
import { ChatStore } from '@seedlang/stores';
import UserSearch from 'components/user_search';
import { UserChatAssociationStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import FrontendChatShow from 'components/chat/chat_show';

@observer
class ChatShow extends React.Component {

  componentDidMount() {
    ChatStore.getShow({ids: {chatId: this.props.params.chatId}});
  }

  @autobind onSubmit(user) {
    UserChatAssociationStore.create({data: {user_id: user.id, chat_id: this.props.params.chatId}}, ChatStore.reloadShow.bind(ChatStore))
  }

  render() {
    return (
      <div className='chat-show'>
        <UserSearch
          onSubmit={this.onSubmit}
          placeholder="Search for User"
          displayField="name"
        />
        <FrontendChatShow
          readOnly
          {...this.props}
        />
      </div>
    );
  }
}

export default ChatShow;
