import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import Text from 'components/text';
import WhitelabelLogo from "components/whitelabel_logo";
import { AppUI } from "@seedlang/state";
import PatreonButton from "components/user/patreon_button";
import ErrorBoundary from "components/error_boundary";
import { isPresent } from "@seedlang/utils/src";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  @media only screen and (max-width : 649px) {
    padding: 20px 0;
  }
  color: #494747;
  @media only screen and (max-width : 649px) {
    background: #FFF;
  }
`;

const Content = styled.div`
  border-radius: 12px;
  margin: 38px 0 20px 0;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 40px 10px 40px;
  @media only screen and (max-width : 649px) {
    padding: 20px 5px;
    border-radius: 0px;
    margin: 10px 0;
  }
  background: #FFF;
`;

const InnerText = styled.div`
  padding: 25px 100px;
  @media only screen and (max-width : 649px) {
    padding: 25px;
  }
  display: flex;
  flex-direction: column;
  gap: 2px;
  ol {
    font-weight: bold;
  }
  li {
    margin-bottom: 5px;
  }
  p {
    margin-block-start: 10px;
    margin-block-end: 10px;
  }
`;

const Footer = styled.div`
  text-align: center;
  max-width: 600px;
  @media only screen and (max-width : 649px) {
    padding: 0 10px;
  }
`;

const ContactLink = styled.div`
  margin-top: 5px;
  font-size: 16px;
`;

@observer
class PatreonConnect extends React.Component {

  render() {
    return (
      <Wrapper>
        <a href=".">
          <WhitelabelLogo
            image={AppUI.site?.image}
          />
        </a>
        <Content>
          <Text heading={1} center style={{maxWidth: '80%'}}>Already a Patreon Member?</Text>
          <InnerText>
            <p>As a Patreon member, we invite you to connect your account to our interactive platform, so you can start using new exciting features that we've prepared for you.</p>
            <p>Later, we will help you to migrate your payment, so you can gain full access to the new platform and enjoy your benefits all in one place.</p>
            <p>Don't worry, you will not lose out on any pre-paid membership period on Patreon.</p>
            <p>Here is how to proceed:</p>
            <ol>
              <li>Connect <span style={{fontWeight: 'normal'}}>your Patreon account with us.</span></li>
              <li>Create <span style={{fontWeight: 'normal'}}>a Secure Password.</span></li>
              <li>Migrate <span style={{fontWeight: 'normal'}}>your payment for full feature access.</span></li>
            </ol>
            <Text color="#656565" textAlign='left' fontSize={16} margin="20px 0 10px 0">
              By connecting your Patreon account, you are creating an account with Easy Catalan under Easy Languages GmbH and Seedlang, Inc. You agree to Easy Languages GmbH <a target="blank" href={AppUI.site.termsOfServiceUrl}>Terms of Use</a> & <a target="blank" href={AppUI.site.privacyUrl}>Privacy Policy</a> and Seedlang, Inc’s <a target="blank" href="https://seedlang.com/terms">Terms of Use</a> & <a target="blank" href="https://seedlang.com/privacy">Privacy Policy</a>.
            </Text>
            <PatreonButton
              margin="20px 0"
              width="330px"
              height="56px"
              borderRadius="36px"
              fontSize="18px"
              scaleOnHover="1.1"
            />

          </InnerText>
        </Content>
        <Footer>
          <ContactLink><a target="_blank" href={`mailto:${AppUI.site.supportEmailAddress}`} className="underline">If you have any questions, please contact us.</a></ContactLink>
          {
            !AppUI.siteIsDefault && isPresent(AppUI.site.paymentMigrationConfig?.infoUrl) &&
              <ContactLink><a target="_blank" href={AppUI.site.paymentMigrationConfig.infoUrl} className="underline">Learn More About Our Migration Process</a></ContactLink>
          }
        </Footer>
      </Wrapper>
    );
  }
}

export default PatreonConnect;
