import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { Motion, spring } from 'react-motion';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { pixify } from '@seedlang/utils';
import { CloseIcon } from '@seedlang/icons';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  z-index: ${Theme.z["topmost"]};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: 0 auto;
  background: white;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 20px;
  h3 {
    margin-bottom: 10px;
    i {
      margin: 3px 3px 0 0;
    }
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  svg {
    width: 24px;
    height: 24px;
  }
`;

@observer
class CardModal extends React.Component {

  render() {
    return (
      <Motion
        style={{
          scale: spring(this.props.scale, {stiffness: 300, damping: 18}),
        }}
      >
        {
          ({scale}) =>
            <Wrapper
              width={pixify(this.props.width)}
              height={pixify(this.props.height)}
              style={Object.assign({}, this.props.style, {
                transform: `scale(${scale})`,
              })}
            >
              <IconWrapper
                onClick={this.props.onClose}
              >
                <CloseIcon />
              </IconWrapper>
              {this.props.children}
            </Wrapper>
        }
      </Motion>
    );
  }
}

export default ErrorBoundary(CardModal);
