"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = removeCookie;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function removeCookie(name) {
  _jsCookie["default"].remove(name, {
    path: '/',
    expires: 999
  });
}