import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FormLabel from 'components/form_factory/form_label';
import FormError from 'components/form_factory/form_error';
import autobind from 'autobind-decorator';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
`;

@observer
class FormInput extends React.Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    store: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    defaultValue: '',
    type: 'text',
    margin: '0 0 10px 0',
  }

  constructor(props) {
    super(props);
    this.props.form.registerComponent(this.props);
  }

  componentWillUnmount() {
    // this.props.form.unregisterComponent(this.props);
  }

  @autobind onChange() {
    this.props.onChange(this.props.name, this.refs.input.value);
  }

  getValue() {
    return this.props.store.formCache[this.props.name] || '';
  }

  getError() {
    return this.props.store.getError(this.props.name);
  }

  name() {
    if (this.props.name.indexOf('.') !== -1) {
      const ary = this.props.name.split('.');
      return `${ary[0]}[${ary[1]}]`;
    }
    return this.props.name;
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        {
          this.props.type !== 'hidden' &&
            <FormLabel
              {...this.props}
            />
        }
        <input
          key={`input-${this.props.name}`}
          ref='input'
          name={this.name()}
          type={this.props.type}
          onChange={this.onChange}
          value={this.getValue()}
          disabled={this.props.disabled}
        />
        {
          this.props.type !== 'hidden' &&
            <FormError
              message={this.getError()}
            />
        }
      </Wrapper>
    );
  }
}

export default FormInput;
