import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import Button from 'components/button/button';
import { AppUI } from '@seedlang/state';

@observer
class HeaderButton extends React.Component {

  render() {
    return (
      <Button
        gradient={!this.props.background}
        background={this.props.background}
        fontWeight={this.props.fontWeight || "300"}
        margin={this.props.margin || "0 0 0 10px"}
        border="3px solid white"
        height={this.props.height || "35px"}
        fontSize={AppUI.layout.isMobile ? "12px" : "14px"}
        borderRadius={this.props.borderRadius}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </Button>
    );
  }
}

export default ErrorBoundary(HeaderButton);
