import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { StickerReactionWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { shuffle } from 'lodash';
import { Motion, presets, spring } from 'react-motion';
import autobind from 'autobind-decorator';
import { depixify, pixify } from '@seedlang/utils';

const HAPPY_PNGS = {
  DE: ['janusz_happy_1.png', 'janusz_happy_2.png', 'cari_happy_1.png', 'cari_happy_2.png', 'Emma_happy.png', 'Emma_thumbs_up.png'],
  FR: ['Loane_happy.png', 'Loane_thumbs_up.png'],
  ES: ['Marina_happy.png', 'Marina_thumbs_up.png'],
}

const DISAPPOINTED_PNGS = {
  DE: ['janusz_disappointed_1.png', 'cari_disappointed_1.png', 'Emma_sorry.png'],
  FR: ['Loane_sorry.png'],
  ES: ['Marina_sorry.png'],
}

const THOUGHTFUL_PNGS = {
  DE: ['sofie_thoughtful_1.png', 'sofie_thoughtful_2.png'],
  FR: ['alix_thoughtful_1.png', 'alix_thoughtful_2.png'],
  ES: ['camila_thoughtful_1.png', 'camila_thoughtful_2.png'],
}

const Wrapper = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: 50%;
  background: ${props => props.background};
  border: 5px solid ${props => props.borderColor};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: ${props => props.imageHeight};
  }
`;

@observer
class StickerReaction extends React.Component {
  @observable scale = 0;
  @observable src;

  static defaultProps = {
    width: "120px",
    background: "#FFF"
  }

  constructor(props) {
    super(props);
    this.initiateAnimationWithDelay();
    if (this.props.reaction === 'happy') {
      this.src = shuffle(HAPPY_PNGS[this.props.languageId || this.props.card?.languageId])[0]
    } else if (this.props.reaction === 'thoughtful') {
      this.src = shuffle(THOUGHTFUL_PNGS[this.props.languageId || this.props.card?.languageId])[0]
    } else {
      this.src= shuffle(DISAPPOINTED_PNGS[this.props.languageId || this.props.card?.languageId])[0]
    }
  }

  @autobind initiateAnimationWithDelay() {
    if (this.scale !== 1) {
      setTimeout(this.initiateAnimation, 150);
    }
  }

  @autobind initiateAnimation() {
    this.scale = 1;
  }

  render () {
    return (
      <Motion
        style={{
          scale: this.props.skipAnimation ? 1 : spring(this.scale, presets.wobbly),
        }}
      >
        {
          ({scale}) =>
            <Wrapper
              style={{
                transform: `scale(${scale})`,
              }}
              height={this.props.width}
              width={this.props.width}
              imageHeight={pixify(depixify(this.props.width) - 30)}
              borderColor={this.props.borderColor}
              background={this.props.background}
            >
              <img src={`/images/${this.src}`} alt="" />
            </Wrapper>
        }
      </Motion>
    );
  }
}

export default StickerReactionWrapper(StickerReaction);
