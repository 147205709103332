"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = formattedRoundNumber;
var _is_present = _interopRequireDefault(require("./is_present"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function formattedRoundNumber(number) {
  if ((0, _is_present["default"])(number)) {
    var roundedNumber = Math.round(number / 100) * 100;
    return "more than ".concat(roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
  }
}