import React from 'react';
import { observer } from 'mobx-react';
import { PluralCardWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import CardSection from 'components/vocab_card/card_section';
import MediaSection from 'components/vocab_card/media_section';
import { DeckUI } from '@seedlang/state';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import CardSourceList from 'components/vocab_card/card_source_list';
import { Theme } from '@seedlang/constants';
import { pixify } from '@seedlang/utils';
import MultipleChoiceButtons from 'components/card/multiple_choice_buttons';
import ExpandedTarget from 'components/vocab_card/expanded_target';
import EvaluateButtons from 'components/card/evaluate_buttons';

const IconWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: ${Theme.z["card-3"]};
  .fa-times {
    color: ${Theme.red};
  }
  .fa-check {
    color: ${Theme.green};
  }
`;

const PromptText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #FFF;
  font-style: italic;
`;

@observer
class PluralCard extends React.Component {

  render() {
    return (
      <CardWrapper
        card={this.props.card}
        cardState={this.props.cardState}
        userCard={this.props.userCard}
        icons={this.props.icons}
      >
        <CardContent
          padding={0}
          height={pixify(this.props.revealed ? DeckUI.layout.contentHeight : DeckUI.layout.contentHeightWithMultipleChoiceButtons(this.props.pluralOptionsCount))}
        >
          {
            !this.props.revealed &&
              <CardSection
                background="#5d5d5d"
                borderTopRadius={10}
                numSections={3}
              >
                <PromptText>
                  What is the plural of this noun?
                </PromptText>
              </CardSection>
          }
          {
            this.props.revealed && this.props.card.hasTargetVideoClip &&
              <MediaSection
                autoPlay={this.props.inView}
                showMedia
                borderTopRadius={10}
                card={this.props.card}
                cardState={this.props.cardState}
              />
          }
          <CardSection
            background="#FFF"
            height={this.props.revealed ? null : DeckUI.layout.contentHeightWithMultipleChoiceButtons(this.props.pluralOptionsCount) / 2}
            borderTopRadius={!this.props.card.hasTargetVideoClip && this.props.revealed ? 10 : 0}
            numSections={this.props.card.hasTargetVideoClip ? 3 : 2}
          >
            {
              this.props.revealed &&
                <IconWrapper>
                  {
                    this.props.userCard.correct &&
                      <i className='fa fa-check' />
                  }
                  {
                    !this.props.userCard.correct &&
                      <i className='fa fa-times' />
                  }
                </IconWrapper>
            }
            <ExpandedTarget
              card={this.props.card}
              cardState={this.props.cardState}
              word={this.props.card.word}
              hideDefiniteArticle={!this.props.revealed}
              hidePlural={!this.props.revealed}
              grammarTextSize={16}
              grammarFontWeight="bold"
              description={this.props.revealed && this.props.card.concept ? this.props.card.concept.shortDescription : null}
            />
          </CardSection>
          <CardSection
            background="#CCC"
            borderBottomRadius={10}
            numSections={this.props.card.hasTargetVideoClip ? 3 : 2}
            height={this.props.revealed ? null : DeckUI.layout.contentHeightWithMultipleChoiceButtons(this.props.pluralOptionsCount) / 2}
          >
            <CardSourceList
              showEdit
              word={this.props.card.word}
              card={this.props.card}
            />
          </CardSection>
        </CardContent>
        {
          !this.props.revealed &&
            <MultipleChoiceButtons
              blockFontCalculation
              width={DeckUI.layout.innerDeckWidth}
              height={pixify(DeckUI.layout.totalMultipleChoiceButtonHeight(this.props.pluralOptionsCount))}
              buttonHeight={DeckUI.layout.multipleChoiceButtonHeight}
              selected={[this.props.multipleChoiceSelected]}
              correct={this.props.correct}
              options={this.props.card.word.randomPluralOptions}
              onClick={(val) => this.props.onClick(val)}
              isMobile={DeckUI.layout.isMobile}
              field="value"
              padding="5px"
              margin="0"
              fontSize={DeckUI.layout.minimumHeight ? "13px" : "16px"}
              disabled={!this.props.inView}
              languageId={this.props.card.languageId}
            />
        }
        {
          this.props.revealed &&
            <EvaluateButtons
              card={this.props.card}
              onNextClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard)}
              cardState={this.props.cardState}
              userCard={this.props.userCard}
              disabled={!this.props.inView}
            />
        }
      </CardWrapper>
    );
  }
}

export default PluralCardWrapper(PluralCard);
