import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  border: ${props => props.removalPossible ? `2px solid ${Theme.darkBlue}` : "2px solid #C1C1C1"};
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: ${props => props.margin};
  background: #FFF;
  border-radius: 10px;
  padding: 5px;
  position: relative;
  &:hover {
    border: 2px solid ${Theme.darkBlue};
  }
`;

@observer
class FeedbackItem extends React.Component {

  static defaultProps = {
    margin: "0 5px 0 0",
  }

  render() {
    return (
      <Wrapper
        onClick={this.props.onClick}
        margin={this.props.margin}
      >
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(FeedbackItem);
