import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

class FormError extends React.Component {

  static propTypes = {
    message: PropTypes.string,
  }

  _error() {
    if (!isEmpty(this.props.message)) {
      return (
        <div className="form__error">
          { this.props.message }
        </div>
      );
    }
    return <span />;
  }

  render() {
    return this._error();
  }
}

export default FormError;
