import React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import ProfileCard from 'components/profile/profile_card';
import FeedEntryIndex from 'components/feed/feed_entry_index';
import { isPresent } from '@seedlang/utils';
import { AppUI } from '@seedlang/state';
import { SettingsIcon } from '@seedlang/icons';
import styled from '@emotion/styled';
import DesktopTitle from 'components/desktop_title';
import { Link } from 'react-router';
import { ProfileHomeWrapper } from '@seedlang/hoc';
import Button from 'components/button/button';
import { flexCenterColumn } from '@seedlang/style_mixins';

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => props.isMobile ? 'column' : 'row'};
  position: relative;
`;

const ProfileCardWrapper = styled.div`
  flex: 1;
`;

const FeedWrapper = styled.div`
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
`;

const SettingsIconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 20px;
  cursor: pointer;
  .settings-icon {
    width: 30px;
    fill: #525252;
  }
`;

const ButtonWrapper = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  padding: 20px;
`;

@observer
class ProfileHome extends React.Component {

  render() {
    return (
      <Wrapper
        isMobile={AppUI.layout.isMobile}
      >
        {
          AppUI.layout.isMobile && AppUI.siteIsDefault &&
            <SettingsIconWrapper>
              <Link
                to={{name: "more"}}
              >
                <SettingsIcon />
              </Link>
            </SettingsIconWrapper>
        }
        <ProfileCardWrapper>
          <ProfileCard
            hideBadges={!AppUI.siteIsDefault}
            user={this.props.user}
            userIsMe={this.props.userIsMe}
            isMobile={AppUI.layout.isMobile}
            onCreateChat={this.props.onCreateChat}
            onToggleFollowing={this.props.onToggleFollowing}
            {...this.props}
          />
        </ProfileCardWrapper>
        {
          AppUI.layout.isMobile && !this.props.expandFeed && AppUI.siteIsDefault &&
            <ButtonWrapper>
              <Button
                onClick={() => this.props.setExpandFeed(true)}
              >
                View Feed Entries
              </Button>
            </ButtonWrapper>
        }
        {
          AppUI.siteIsDefault && isPresent(toJS(this.props.user)) && (!AppUI.layout.isMobile || this.props.expandFeed) &&
            <FeedWrapper
              margin={AppUI.layout.isMobile ? null : "0 0 0 10px"}
              width={AppUI.layout.isMobile ? "100%" : "400px"}
            >
              <DesktopTitle>
                Feed
              </DesktopTitle>
              <FeedEntryIndex
                hideHeader
                user={this.props.user}
                show='my.feed'
              />
            </FeedWrapper>
        }
      </Wrapper>
    );
  }
}

export default ProfileHomeWrapper(ProfileHome);
