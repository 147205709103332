import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import cx from 'classnames';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';
import { Motion, spring } from 'react-motion';
import { CheckmarkOutlineIcon, LockIcon } from '@seedlang/icons';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { pixify } from '@seedlang/utils';

const Wrapper = styled.div`
  visibility: ${(props) => props.imageNotLoaded ? 'hidden' : 'visible'};
  border: ${(props) => props.borderWidth} solid ${(props) => props.borderColor};
  border-top-right-radius: ${(props) => props.borderTopRadius};
  border-top-left-radius: ${(props) => props.borderTopRadius};
  border-bottom-right-radius: ${(props) => props.borderBottomRadius};
  border-bottom-left-radius: ${(props) => props.borderBottomRadius};
  position: relative;
  overflow: hidden;
  z-index: 0;
  width: ${props => props.width};
  height: ${props => props.height};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: whitesmoke;
`;

const LockWrapper = styled.div`
  z-index: ${Theme.z["foreground"]};
  width: 0;
  height: 0;
  border-style: solid;
  border-width: ${(props) => props.smallLock ? "0 50px 50px 0" : "0 70px 70px 0"};
  border-color: transparent #777777 transparent transparent;
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  .icon-wrapper {
    svg {
      fill: #FFF;
      width: 11px;
      position: absolute;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      right: ${(props) => props.smallLock ? "-40px" : "-50px"};
      top: ${(props) => props.smallLock ? "5px" : "10px"};
    }
  }
`;

const Scale = styled.div`
  visibility: hidden;
`;

const ImageWrapper = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${props => props.borderRadius};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  img {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    border-radius: ${props => props.borderRadius};
    opacity: ${(props) => props.noAccess ? 0.75 : 1};
    -webkit-filter: ${(props) => props.noAccess ? "grayscale(1)" : "none"};
    filter: ${(props) => props.noAccess ? "gray" : "none"};
    filter: ${(props) => props.noAccess ? "grayscale(1)" : "none"};
  }
`;

const FinishedOverlay = styled.div`
  background: ${(props) => props.finished ? "rgba(45, 224, 37, 0.29)" : "none"};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const CheckmarkWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon-wrapper {
    background-image: linear-gradient(to right top, rgba(19, 171, 94, 0.73), rgba(61, 188, 85, 0.75), rgba(95, 205, 72, 0.75), rgba(131, 220, 54, 0.75), rgba(168, 235, 18, 0.74));
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .checkmark-outline-icon {
    svg {
      width: ${(props) => props.checkmarkWidth};
      fill: #FFF;
    }
  }
  @media only screen and (max-width : 549px) {
    .icon-wrapper {
      border-radius: 0;
    }
    svg {
      width: 45px;
    }
  }
`;

@observer
class ImageWithStatus extends React.Component {
  @observable initiateAnimation = false;
  @observable imageLoaded = false;

  static defaultProps = {
    checkmarkWidth: "70px",
    borderTopRadius: "40px",
    borderBottomRadius: "40px",
  }

  @computed get finished() {
    return !AppUI.user.hideDeckProgress && this.props.finished;
  }

  @computed get scale() {
    return !this.finished || (this.finished && this.props.animate && !this.initiateAnimation) ? 0 : 1;
  }

  componentDidMount() {
    if (this.props.animate) {
      this.delayInitiateAnimation();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.animate && this.props.animate) {
      this.delayInitiateAnimation();
    }
  }

  delayInitiateAnimation() {
    setTimeout(this.animate, 500);
  }

  @autobind animate() {
    AppUI.soundEffectStore.play('chime')
    this.initiateAnimation = true;
  }

  @autobind onImageLoad() {
    this.imageLoaded = true;
  }

  render() {
    return (
      <Wrapper
        width={pixify(this.props.width)}
        height={pixify(this.props.height)}
        borderColor={this.props.borderColor}
        borderTopRadius={this.props.borderTopRadius}
        borderBottomRadius={this.props.borderBottomRadius}
        borderWidth={pixify(this.props.borderWidth)}
        imageNotLoaded={this.props.src && !this.imageLoaded}
        className={cx({
          'image-not-loaded': this.props.src && !this.imageLoaded,
          'image-loaded': this.imageLoaded,
        })}
      >
        {
          !this.props.hasAccess && this.props.showLock &&
            <LockWrapper
               smallLock={this.props.smallLock && AppUI.layout.isMobile}
            >
              <LockIcon />
            </LockWrapper>
        }
        <ImageWrapper
          width={pixify(this.props.imageWidth || this.props.width || "100%")}
          height={pixify(this.props.imageHeight || this.props.height || "100%")}
          noAccess={!this.props.hasAccess}
          borderRadius={this.props.imageBorderRadius}
          marginTop={this.props.imageMarginTop}
          marginBottom={this.props.imageMarginBottom}
        >
          {
            this.props.src &&
              <img
                src={this.props.src}
                onLoad={this.onImageLoad}
                alt=""
              />
          }
        </ImageWrapper>
        <FinishedOverlay
          borderTopRadius={this.props.borderTopRadius}
          borderBottomRadius={this.props.borderBottomRadius}
          finished={this.props.finished}
        >
          <Scale>
            {this.scale}
          </Scale>
          {
            this.props.animate &&
              <Motion
                style={{
                  scale: spring(this.scale),
                }}
                onRest={() => this.props.afterAnimation()}
              >
                {
                  ({scale, borderRadius}) =>
                    <CheckmarkWrapper
                      checkmarkWidth={pixify(this.props.checkmarkWidth)}
                      borderTopRadius={this.props.borderTopRadius}
                      borderBottomRadius={this.props.borderBottomRadius}
                      style={{
                        transform: `scale(${scale})`,
                      }}
                    >
                      <CheckmarkOutlineIcon />
                    </CheckmarkWrapper>
                }
              </Motion>
          }
          {
            !this.props.animate && this.finished &&
              <CheckmarkWrapper
                checkmarkWidth={this.props.checkmarkWidth}
              >
                <CheckmarkOutlineIcon />
              </CheckmarkWrapper>
          }
        </FinishedOverlay>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ImageWithStatus);
