import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  box-sizing: border-box;
  display: ${(props) => props.display};
  padding: ${(props) => props.padding};
`;

@observer
class Container extends React.Component {

  render() {
    return (
      <Wrapper
        display={this.props.display}
        padding={this.props.padding}
      >
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Container);
