import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  position: relative;
  margin-top: 0;
  background: #DDD;
  padding: 10px;
  text-align: center;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  height: 46px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

@observer
class DesktopTitle extends React.Component {

  render() {
    return (
      <Wrapper>
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(DesktopTitle);
