import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { TreeUI } from '@seedlang/state';
import Spinner from 'components/spinner';
import { GrammarWrapper } from "@seedlang/hoc";
import GrammarTreeNodeShow from "components/grammar/grammar_tree_node_show";
import GrammarIndex from "components/grammar/grammar_index";
import LearnLayout from 'pages/main/learn_layout';

const Wrapper = styled.div`

`;

@observer
class GrammarHome extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          !TreeUI.grammarTree &&
            <Spinner />
        }
        <LearnLayout
          leftSidebar
        >
          {
            this.props.currentTreeNodeId &&
              <GrammarTreeNodeShow
                treeNodeId={this.props.currentTreeNodeId}
              />
          }
          {
            this.props.tree && this.props.currentTreeNodeId &&
              <GrammarIndex
                {...this.props}
              />
          }
        </LearnLayout>
      </Wrapper>

    );
  }
}

export default GrammarWrapper(GrammarHome);
