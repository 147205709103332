import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import Text from 'components/text';

@observer
class TriviaRecordScore extends React.Component {

  @computed get fontSize() {
    const numDigits = this.props.won.toString().length + this.props.lost.toString().length;
    const fontSizes = {8: 15, 7: 18, 6: 21, 5: 23, 4: 26, 3:31, 2: 36};
    return fontSizes[numDigits];
  }

  render() {
    return (
      <Text
        fontSize={this.fontSize}
        bold
      >
        {this.props.won}-{this.props.lost}
      </Text>
    );
  }
}

export default ErrorBoundary(TriviaRecordScore);
