import React from 'react';
import { observer } from 'mobx-react';
import { PartnersWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import Text from 'components/text';
import Button from 'components/button/button';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  padding: 10px;
`;

const Row = styled.div`
  border-bottom: 1px solid #CCC;
  padding: 20px 0;
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
`;

const ButtonWrapper = styled.span`
  margin-right: 10px;
`;

const Info = styled.div`
  flex: 1;
`;

const ImageWrapper = styled.div`
  width: 200px;
  height: 200px;
  margin-right: 20px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
`;

@observer
class Partners extends React.Component {

  render() {
    return (
      <Wrapper>
        <Text heading="2">Our Partners</Text>
        {
          this.props.partners.map(item => {
            return (
              <Row
                key={item.name}
                flexDirection={AppUI.layout.isMobile ? 'column' : 'row'}
              >
                <ImageWrapper>
                  <img src={`/images/${item.imageUrl}`} alt="" />
                </ImageWrapper>
                <Info>
                  <Text
                    heading="3"
                    margin="10px 0 0 0"
                  >
                    {item.name}
                  </Text>
                  <Text
                    margin="10px 0 0 0"
                  >
                    {item.text}
                  </Text>
                  {
                    item.buttons.map(item => {
                      return (
                        <ButtonWrapper
                          key={item.url}
                        >
                          <a href={item.url} target="_blank" rel="noopener noreferrer">
                            <Button
                              margin="10px 0 0 0"
                            >
                              {item.text}
                            </Button>
                          </a>
                        </ButtonWrapper>
                      )
                    })
                  }
                </Info>
              </Row>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default PartnersWrapper(Partners);
