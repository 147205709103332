import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import WordSourceEditModal from 'pages/builder/sources/word_source_edit_modal';
import { WordStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`

`;

@observer
class WordModal extends React.Component {

  constructor(props) {
    super(props);
    this.getWord();
  }

  componentWillUnmount() {
    WordStore.clearShowData();
  }

  @autobind onChange() {
    this.getWord();
    this.props.onChange();
  }

  getWord() {
    WordStore.getShow({ids: {wordId: this.props.wordId}});
  }

  render() {
    return (
      <Wrapper>
        {
          WordStore.hasShowData &&
            <WordSourceEditModal
              word={WordStore.showData}
              onChange={this.onChange}
              onCloseModal={this.props.onCloseModal}
            />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WordModal);
