import React from 'react';
import { OverlayUI } from '@seedlang/state';
import { DeckUI } from '@seedlang/state';
import { observer } from 'mobx-react';
import Overlay from 'components/layout/overlay';
import ChatShow from 'components/chat/chat_show';
import ProfileHome from 'components/profile/profile_home';
import ChatEdit from 'components/chat/chat_edit';
import ChatCreatorEdit from 'components/chat/chat_creator_edit';
import DeckShow from 'components/deck/deck_show';
import UserIndex from 'components/user/user_index';
import Faq from 'pages/main/faq';
import Contact from 'pages/main/contact';
import Privacy from 'pages/main/privacy';
import Partners from 'pages/main/partners';
import DeckSettings from 'components/deck/deck_settings';
import Shortcuts from 'components/deck/shortcuts';
import MediaBlocked from 'components/card/media_blocked';
import SourceEdit from 'components/vocab_card/source_edit';
import TranslationComments from 'components/comment/translation_comments';
import TriviaResults from 'components/trivia/trivia_results';
import ReviewDetails from 'components/card/review_details';
import CardNote from 'components/card/card_note';
import Settings from 'components/user/settings';
import ReportBug from 'components/user/report_bug';
import DeckGrammar from 'components/deck/deck_grammar';
import TenseDescription from 'components/deck/tense_description';
import ConjugationTable from 'components/conjugation_table';
import GrammarTip from 'components/grammar_tip';
import VocabCard from "components/card/vocab_card";

@observer
class OverlayWrapper extends React.Component {

  constructor(props) {
    super(props);
    this.components = {
      cardNote: CardNote,
      chatShow: ChatShow,
      chatEdit: ChatEdit,
      profile: ProfileHome,
      chatCreatorEdit: ChatCreatorEdit,
      deckShow: DeckShow,
      userIndex: UserIndex,
      faq: Faq,
      settings: Settings,
      contact: Contact,
      privacy: Privacy,
      partners: Partners,
      deckSettings: DeckSettings,
      mediaBlocked: MediaBlocked,
      shortcuts: Shortcuts,
      sourceEdit: SourceEdit,
      cardComments: TranslationComments,
      triviaResults: TriviaResults,
      reviewDetails: ReviewDetails,
      deckGrammar: DeckGrammar,
      tenseDescription: TenseDescription,
      conjugationTable: ConjugationTable,
      reportBug: ReportBug,
      vocabCard: VocabCard,
      grammarTip: GrammarTip,
    };
  }

  render() {
    return (
      <div>
        {
          OverlayUI.overlays?.map((item, index) => {
            return (
              <Overlay
                height={DeckUI.hasDeck && item.name !== "deckShow" ? DeckUI.layout.deckHeight : item.props.layout.viewportHeight}
                layout={item.props.layout}
                overlayState={item.overlayState}
                key={item.props.overlayId}
                deckOverlay={DeckUI.hasDeck && item.name !== "deckShow"}
                {...item.props}
              >
                {React.createElement(this.components[item.name],
                  {
                          ...item.props,
                          debugInfo: DeckUI.commentDebugInfo,
                          commentCreatePlaceholder: "Leave a Public Comment"
                        })}
              </Overlay>
            )
          })
        }
      </div>
    );
  }
}

export default OverlayWrapper;
