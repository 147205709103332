import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { DeckUI } from '@seedlang/state';
import { AppUI } from '@seedlang/state';
import { isBlank } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import Text from 'components/text';
import Button from 'components/button/button';
import InPlaceSelect from 'components/form/in_place_select';
import { sortBy } from 'lodash';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  max-width: 500px;
  select {
    height: 40px;
  }
  .page-onboarding {
    box-shadow: none;
    margin-bottom: 20px;
  }
`;

@observer
class VocabCreate extends React.Component {
  @observable showVocabCreatedMessage = false;
  @observable targetText = '';
  @observable sourceText = '';
  @observable wordTypeId;
  @observable levelId;
  @observable gender;

  @computed get disableSubmit() {
    return isBlank(this.targetText) || isBlank(this.sourceText) || isBlank(this.wordTypeId) || isBlank(this.levelId) || (this.isNoun && isBlank(this.gender));
  }

  @computed get wordTypes() {
    const wordTypes = AppUI.wordTypeStore.indexData.filter(item => item.showInVocabTrainer).map(item => [item.id, item.formattedName]);
    return sortBy(wordTypes, item => item[1]);
  }

  @computed get isNoun() {
    return this.selectedWordType && this.selectedWordType.name === 'Noun';
  }

  @computed get levels() {
    return AppUI.levelStore.indexData.filter(item => ['A1', 'A2', 'B1', 'B2'].indexOf(item.abbreviation) !== -1);
  }

  @computed get selectedWordType() {
    if (this.wordTypeId) {
      return AppUI.wordTypeStore.indexData.find(item => item.id === this.wordTypeId);
    } return null;
  }

  constructor(props) {
    super(props);
    if (!AppUI.wordTypeStore.hasIndexData) {
      AppUI.wordTypeStore.getIndex();
    }
    if (!AppUI.levelStore.hasIndexData) {
      AppUI.levelStore.getIndex();
    }
  }

  @autobind onSubmit() {
    if (!this.disableSubmit) {
      AppUI.wordStore.create({data: {
        gender: this.gender,
        pending_approval: true,
        submitted_by_id: DeckUI.user.id,
        root: true,
        word_type_id: this.wordTypeId,
        level_id: this.levelId,
        target_text: this.targetText,
        source_text: this.sourceText
      }}, this.afterSubmit);
    }
  }

  @autobind afterSubmit(resp) {
    this.showVocabCreatedMessage = true;
    this.targetText = '';
    this.sourceText = '';
    this.wordTypeId = null;
    this.levelId = null;
    this.gender = null;
    setTimeout(this.hideVocabCreatedMessage, 5000);
  }

  @autobind hideVocabCreatedMessage() {
    this.showVocabCreatedMessage = false;
  }

  render() {
    return (
      <Wrapper>
        {
          this.showVocabCreatedMessage &&
            <div className='alert success'>
              This word was successfully added.
            </div>
        }
        <Text
          heading="3"
          margin="20px 0 20px 0"
        >
          Add Word
        </Text>
        <div className='form-group'>
          <Text
            bold
          >
            Target
          </Text>
          <textarea
            value={this.targetText}
            onChange={(e) => this.targetText = e.target.value}
          />
        </div>

        <div className='form-group'>
          <Text
            bold
            margin="20px 0 0 0"
          >
            English
          </Text>
          <textarea
            value={this.sourceText}
            onChange={(e) => this.sourceText = e.target.value}
          />
        </div>
        <div className='form-group'>
          <Text
            bold
            margin="20px 0 0 0"
          >
            Level
          </Text>
          <InPlaceSelect
            allowUpdate
            includeBlank
            value={this.levelId}
            options={this.levels && this.levels.map(item => [item.id, item.abbreviation])}
            onChange={(val) => this.levelId = val}
          />
        </div>
        <div className='form-group'>
          <Text
            bold
            margin="20px 0 0 0"
          >
            Word Type
          </Text>
          <InPlaceSelect
            allowUpdate
            includeBlank
            value={this.wordTypeId}
            options={this.wordTypes}
            onChange={(val) => this.wordTypeId = val}
          />
        </div>
        {
          this.isNoun &&
            <div className='form-group'>
              <Text
                bold
                margin="20px 0 0 0"
              >
                Gender
              </Text>
              <InPlaceSelect
                allowUpdate
                includeBlank
                value={this.gender}
                options={[['feminine', 'feminine'], ['masculine', 'masculine'], ['neuter', 'neuter']]}
                onChange={(val) => this.gender = val}
              />
            </div>
        }

        <Button
          onClick={this.onSubmit}
          disabled={this.disableSubmit}
          margin="20px 0 0 0"
        >
          Submit
        </Button>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(VocabCreate);
