import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import FooterColumn from 'components/layout/desktop/footer_column';
import { Theme, Constants } from '@seedlang/constants';
import Container from 'components/layout/container';
import { flexCenter } from '@seedlang/style_mixins';
import { AppUI } from '@seedlang/state';
import NavigationItemLinks from 'components/layout/desktop/navigation_item_links';

const Wrapper = styled.div`
  height: 155px;
  padding: 28px 15px;
  margin: 10px 0 0 0;
  background: ${props => (props.bgColor ? props.bgColor : Theme.footerColor)};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${props => props.flexDirection};
`;

const Content = styled.div`
  display: flex;
  width: 530px;
  margin: 0 auto;
`;

const Social = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const SocialIcon = styled.div`
  ${flexCenter()}
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
  img {
    width: 26px;
    height: 26px;
  }
`;

const DiscordIcon = styled.div`
  ${flexCenter()}
  background: #5865f2;
  border: 2px solid white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
  img {
    width: 18px;
    height: 18px;
  }
`;

const Seedlang = styled.div`
  ${flexCenter()}
  margin-top: 20px;
  color: #FFF;
  img {
    width: 23px;
    margin-right: 5px;
  }
`;

const Copyright = styled.div`
  color: #FFF;
  font-size: 12px;
`;

@observer
class Footer extends React.Component {

  render() {
    return (
      <Wrapper
        bgColor={this.props.bgColor}
        flexDirection={AppUI.siteIsDefault ? 'row' : 'column'}
      >
        {
          !AppUI.siteIsDefault &&
            <NavigationItemLinks
              footerLink={true}
              color="#FFF"
              hideBorder
              justifyContent="center"
              navigationItems={AppUI.user?.site?.navigationItems}
            />
        }
        {
          !AppUI.siteIsDefault &&
            <a href='http://www.seedlang.com' target='_blank' rel="noopener noreferrer">
              <Seedlang>
                <img alt='' src="/images/seedlang_mark.png" />
                Powered by Seedlang
              </Seedlang>
            </a>
        }
        {
          AppUI.siteIsDefault &&
            <Container
              display="flex"
            >
              <Content>
                <FooterColumn
                  title="Seedlang"
                  links={[
                    {
                      name: 'Memberships', route: 'memberships.index',
                    },
                    {
                      name: 'Team', route: 'team',
                    },
                    {
                      name: 'Jobs', route: 'jobs',
                    },
                    {
                      name: 'Partners', route: 'partners',
                    },
                  ]}
                />
                <FooterColumn
                  title="Help"
                  links={[
                    {
                      name: 'Help Center', route: 'help_center',
                    },
                    {
                      name: 'Contact Us', route: 'contact',
                    },
                    {
                      name: 'Site Releases', route: 'site_releases',
                    },
                  ]}
                />
                <FooterColumn
                  title="Legal"
                  links={[
                    {
                      name: 'Terms of Service', route: 'terms',
                    },
                    {
                      name: 'Privacy Terms', route: 'privacy',
                    },
                    {
                      name: `© ${new Date().getFullYear()} Seedlang, Inc.`, route: 'legal',
                    }
                  ]}
                />
              </Content>
              <Social>
                <a href={Constants.SOCIAL_MEDIA_LINKS["instagram"][AppUI.targetLanguageId || 'FR']} target="_blank" rel="noopener noreferrer">
                  <SocialIcon>
                    <img alt='' src="/images/instagram_icon.png" />
                  </SocialIcon>
                </a>
                <a href={Constants.SOCIAL_MEDIA_LINKS["tiktok"][AppUI.targetLanguageId || 'FR']} target="_blank" rel="noopener noreferrer">
                  <SocialIcon>
                    <img alt='' src="/images/tiktok_icon.png" />
                  </SocialIcon>
                </a>
                <a href={Constants.SOCIAL_MEDIA_LINKS["youtube"][AppUI.targetLanguageId || 'FR']} target="_blank" rel="noopener noreferrer">
                  <SocialIcon>
                    <img alt='' src="/images/youtube_icon.png" />
                  </SocialIcon>
                </a>
              </Social>
            </Container>
          }
      </Wrapper>
    );
  }
}

export default Footer;
