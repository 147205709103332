import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { LanguageIcon } from '@seedlang/icons';
import Text from 'components/text';
import { Languages } from '@seedlang/constants';

const Wrapper = styled.div`
  padding: 20px;
  border: 4px solid #FFF;
  width: 140px;
  height: 140px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  margin: 0 5px;
  background: rgba(255, 255, 255, 0.3);
  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }
`;

@observer
class LanguageOption extends React.Component {

  render () {
    return (
      <Wrapper
        onClick={this.props.onClick}
      >
        <LanguageIcon 
          languageId={this.props.languageId}
          width="60px"
        />
        <Text
          bold
          margin="5px 0 0 0"
          fontSize="16px"
          color="#FFF"
        >
          {Languages[this.props.languageId]}
        </Text>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(LanguageOption);
