import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  background: ${(props) => props.background};
  margin: ${(props) => props.margin};
  color: ${(props) => props.color};
  cursor: ${(props) => props.cursor};
  white-space: ${props => props.whiteSpace};
  box-shadow: ${(props) => props.assigned ? "none" : "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)"};
  padding: 10px 20px;
  height: ${(props) => props.height};
  min-height: ${props => props.minHeight};
  display: inline-flex;
  align-items: center;
  font-size: ${(props) => props.fontSize};
  line-height: 18px;
  border-radius: 10px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .fa-times {
    margin-left: 5px;
    margin-right: -5px;
    opacity: 0.7;
  }
  width: ${props => props.width};
`;

@observer
class ReorderClickItem extends React.Component {

  static defaultProps = {
    height: "50px",
    fontSize: "14px",
  }

  @computed get background() {
    if (this.props.assigned) {
      return "#d1d1d1";
    } else if (this.props.correct) {
      return Theme.green;
    } else if (this.props.incorrect) {
      return Theme.red;
    } else if (!this.props.inDeck) {
      return '#FFF';
    } return Theme.orange;
  }

  @computed get color() {
    if (this.props.assigned) {
      return "#d1d1d1";
    } else if (this.props.correct || this.props.incorrect) {
      return '#FFF';
    } else if (!this.props.inDeck) {
      return '#000';
    } return '#FFF';
  }

  @computed get cursor() {
    return this.props.assigned || this.props.correct || this.props.incorrect ? 'default' : 'pointer';
  }

  render() {
    return (
      <Wrapper
        onClick={this.props.onClick}
        assigned={this.props.assigned}
        background={this.background}
        color={this.color}
        margin={this.props.margin}
        cursor={this.cursor}
        height={this.props.height}
        minHeight={this.props.minHeight}
        fontSize={this.props.fontSize}
        width={this.props.width}
        whiteSpace={this.props.whiteSpace}
      >
        {this.props.children}
        {
          this.props.incorrect &&
            <i className='fa fa-times' />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ReorderClickItem);
