import React from 'react';
import { observer } from 'mobx-react';
import { DeckRulebookStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';

@observer
class DeckRulebookCreate extends React.Component {

  onClick() {
    if (isPresent(this.refs.name.value)) {
      DeckRulebookStore.create(
        {
          data: {
            name: this.refs.name.value,
          },
        },
      );
      this.refs.name.value = '';
    }
  }

  render() {
    return (
      <div className="deck-rulebook-create row">
        <div className="col-xs-10">
          <input
            placeholder="Add a name"
            ref="name"
          />
        </div>
        <div className="col-xs-2">
          <button
            className="button-primary"
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default DeckRulebookCreate;
