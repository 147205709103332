import React from 'react';
import { observer } from 'mobx-react';
import ExerciseInputTable from 'pages/creator/exercises/exercise_input_table';

@observer
class ExerciseReorder extends React.Component {

  render() {
    return (
      <div className='exercise-reorder'>
        <ExerciseInputTable
          {...this.props}
        />
      </div>
    );
  }
}

export default ExerciseReorder;
