import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { ExerciseUI, AppUI } from '@seedlang/state';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';
import RatingInput from 'components/rating_input';
import Text from 'components/text';
import NoAccessButton from "components/worksheet/no_access_button";
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { isPresent, isBlank } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import BackToTopButton from 'components/button/back_to_top_button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-top: 20px;
  @media (max-width: 1100px) {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 899px) {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

const RatingWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  @media (max-width: 899px) {
    width: 100%;
  }
`;

const RatingBox = styled.div`
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background: whitesmoke;
  @media (max-width: 899px) {
    width: 100%;
  }
`;

const RatingCommentBox = styled.div`
  margin: 10px;
  height: 150px;
`;

const LeftWrapper = styled.div`
  flex: 1;
`;

const RightWrapper = styled.div`

`;

@observer
class ExerciseButtons extends React.Component {

  render () {
    return (
      <Wrapper>
        <Buttons>
          <LeftWrapper>
            <Button
              margin="0 5px 0 0"
              disabled={ExerciseUI.exercise.state.revealed}
              onClick={ExerciseUI.revealExerciseAnswers}
              background={ExerciseUI.site.accentColor || '#716f6f'}
            >
              <i className="fa fa-check" />
              Submit
            </Button>
            <Button
              margin="0 5px 0 0"
              disabled={!ExerciseUI.exercise.state.revealed || !ExerciseUI.exercise.anyIncorrect}
              background={Theme.red}
              onClick={() => ExerciseUI.exercise.anyIncorrect && ExerciseUI.exercise.state.revealed && ExerciseUI.resetIncorrectExerciseAnswers()}
            >
              <i className="fa fa-times" />
              Reset Incorrect
            </Button>
            <Button
              margin="0 5px 0 0"
              background={Theme.red}
              disabled={!ExerciseUI.exercise.state.revealed}
              onClick={() => ExerciseUI.exercise.state.revealed && ExerciseUI.resetExerciseAnswers()}
            >
              <i className="fa fa-times" />
              Reset All
            </Button>
          </LeftWrapper>
          <RightWrapper>
            {
              !ExerciseUI.exerciseIsLast &&
                <Button
                  background={AppUI.site.accentColor || '#716f6f'}
                  margin="0 5px 0 0"
                  onClick={this.props.goToNextExercise}
                >
                  <i className="fa fa-angle-double-right" />
                  Next Exercise
                </Button>
            }
            {
              AppUI.layout.viewportWidth > 899 &&
                <BackToTopButton
                  height="40px"
                  width="40px"
                  fontSize="24px"
                  display="inline-flex"
                  background={AppUI.site.accentColor}
                  onClick={this.props.goBackToTop}
                />
            }
          </RightWrapper>
        </Buttons>
        {
          ExerciseUI.exercise?.enriched && ExerciseUI.exercise.state.hasBeenRevealed &&
            <RatingWrapper>
              <RatingBox>
                <Text
                  margin="0 5px 0 0"
                  fontSize="14px"
                >
                  Rate this Exercise
                </Text>
                <RatingInput
                  maxRating={5}
                  id={ExerciseUI.exercise && ExerciseUI.exercise.id}
                  defaultValue={ExerciseUI.userExercise && ExerciseUI.userExercise.rating}
                  slug='overall'
                  onClick={(rating) => this.props.onSubmitRating(rating)}
                />
              </RatingBox>
            </RatingWrapper>
        }
        {
          this.props.showRatingComment && isPresent(ExerciseUI.exerciseRatingId) && !this.props.ratingCommentSubmitted &&
            <RatingCommentBox>
              <Text
                italic
                fontSize="14px"
                margin="30px 0 10px 0"
                center
              >
                Do you have anything to tell us about this exercise?  All feedback is appreciated!
              </Text>
              <textarea
                value={this.props.comment}
                placeholder="Enter your comment"
                onChange={(e) => this.props.setComment(e.target.value)}
              />
              <div style={{textAlign: 'end'}}>
                <Button
                  disabled={isBlank(this.props.comment)}
                  margin="10px 0 0 0"
                  onClick={this.props.onSubmitRatingComment}
                >
                  Submit Message
                </Button>
              </div>
            </RatingCommentBox>
        }
        {
          this.props.ratingCommentSubmitted &&
            <RatingCommentBox>
              <Text
                italic
                fontSize="14px"
                margin="60px 0 10px 0"
                center
              >
                Thank you for submitting a comment!
              </Text>
            </RatingCommentBox>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ExerciseButtons);


