import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import { shuffle } from 'lodash';
import autobind from 'autobind-decorator';

const HAPPY_PNGS = {
  DE: ['janusz_happy_1.png', 'janusz_happy_2.png', 'cari_happy_1.png', 'cari_happy_2.png'],
  FR: ['Loane_happy.png', 'Loane_thumbs_up.png'],
  ES: ['Marina_happy.png', 'Marina_thumbs_up.png'],
}

const DISAPPOINTED_PNGS = {
  DE: ['janusz_disappointed_1.png', 'cari_disappointed_1.png'],
  FR: ['Loane_sorry.png'],
  ES: ['Marina_sorry.png'],
}

const THOUGHTFUL_PNGS = {
  DE: ['sofie_thoughtful_1.png', 'sofie_thoughtful_2.png'],
  FR: ['alix_thoughtful_1.png', 'alix_thoughtful_2.png'],
  ES: ['camila_thoughtful_1.png', 'camila_thoughtful_2.png'],
}

@observer
class StickerReaction extends React.Component {
  @observable src;

  static defaultProps = {
    width: "120px",
    background: "#FFF"
  }

  constructor(props) {
    super(props);
    this.assignImage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.reaction !== prevProps.reaction) {
      this.assignImage();
    }
  }

  @autobind assignImage() {
    if (this.props.reaction === 'happy') {
      this.src = shuffle(HAPPY_PNGS[this.props.languageId || this.props.card?.languageId])[0]
    } else if (this.props.reaction === 'thoughtful') {
      this.src = shuffle(THOUGHTFUL_PNGS[this.props.languageId || this.props.card?.languageId])[0]
    } else {
      this.src= shuffle(DISAPPOINTED_PNGS[this.props.languageId || this.props.card?.languageId])[0]
    }
  }

  render () {
    return (
      <img src={`/images/${this.src}`} alt="" />
    );
  }
}

export default StickerReaction;
