import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import Spinner from 'components/spinner';
import { kFormatter } from '@seedlang/utils';
import { LanguageIcon } from '@seedlang/icons';
import Paginator from 'components/paginator';
import { flexCenterColumn } from '@seedlang/style_mixins';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`

`;

const SpinnerWrapper = styled.div`
  ${flexCenterColumn()}
  height: 490px;
`;

const PaginatorWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Row = styled.div`
  background: ${(props) => props.selected ? "rgba(255, 255, 0, 0.26)" : "#FFF"};
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  display: flex;
  margin: 0;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
  width: 100%;
`;

const Position = styled.div`
  width: 30px;
`;

const Table = styled.div`
  width: 100%;
`;

const Name = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 5px 0 10px;
  cursor: pointer;
`;

const Count = styled.div`
  width: 45px;
  padding-left: 5px;
`;


@observer
class LanguageIndex extends React.Component {

  constructor(props) {
    super(props);
    this.getList();
  }

  @autobind getList() {
    AppUI.languageStore.getIndex({limit: 10, sort: "-sources_count"});
  }

  @autobind sourcesCount(language) {
    const count = language.id === 'EN' ? Math.floor(language.sourcesCount/1.6) : language.sourcesCount;
    return kFormatter(count);
  }

  render() {
    return (
      <Wrapper>
        {
          this.showSpinner &&
            <SpinnerWrapper>
              <Spinner
                className='blue'
              />
            </SpinnerWrapper>
        }

        <Table>
          {
            AppUI.languageStore.indexData.map((item, index) => {
              return (
                <Row
                  key={item.id}
                >
                  <Position>
                    {((AppUI.languageStore.page - 1) * 10) + index + 1}
                  </Position>
                  <LanguageIcon
                    languageId={item.id}
                    width="20px"
                  />
                  <Name
                    onClick={() => AppUI.routeStore.routeToNamed('profiles.show', {userId: item.id})}
                  >
                    {item.name}
                  </Name>
                  <Count>
                    {this.sourcesCount(item)}
                  </Count>
                </Row>
              )
            })
          }
        </Table>
        {
          !this.showSpinner &&
            <PaginatorWrapper>
              <Paginator
                compact
                margin="10px 0 5px 5px"
                store={AppUI.languageStore}
                maxPage={10}
              />
            </PaginatorWrapper>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(LanguageIndex);
