import React from 'react';
import { observer } from 'mobx-react';
import { PodcastEpisodeStore } from '@seedlang/stores';
import TypeAhead from 'components/type_ahead';
import { isEmpty } from 'lodash';

@observer
class PodcastEpisodeSearch extends React.Component {

  static defaultProps = {
    searchByField: 'title',
    placeholder: 'Search for Podcast Episode',
  }

  constructor (props) {
    super();
    if (props.podcastId) {
      PodcastEpisodeStore.setFilter('podcast_id', props.podcastId);
    }
  }

  componentWillUnmount() {
    this.onClear();
  }

  onKeyUp(value) {
    if (isEmpty(value)) {
      this.onClear();
    } else {
      PodcastEpisodeStore.setFilter(this.props.searchByField, `~${value}~`, { refresh: true });
    }
  }

  onSelect(obj) {
    this.props.onSubmit(obj);
  }

  onClear() {
    PodcastEpisodeStore.clearIndexData();
    PodcastEpisodeStore.clearFilter();
  }

  render() {
    return (
      <div className="podcast-episode-search">
        <TypeAhead
          onKeyUp={this.onKeyUp.bind(this)}
          onSelect={this.onSelect.bind(this)}
          onClear={this.onClear.bind(this)}
          options={PodcastEpisodeStore.indexData}
          displayField={this.props.displayField || this.props.searchByField}
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}

export default PodcastEpisodeSearch;
