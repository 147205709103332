import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { indexOf, isEmpty, map, without } from 'lodash';
import cx from 'classnames';
import { isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import FilterComponent from 'components/backend_filter/filter_component';
import FilterButton from 'components/backend_filter/filter_button';
import FilterButtonText from 'components/backend_filter/filter_button_text';
import styled from '@emotion/styled';

const Wrapper = styled.div`

`;

@observer
class FilterMultiSelect extends React.Component {
  @observable value = this.props.default;

  componentDidMount() {
    let value = this.props.store.getFilterValue(this.props.name);
    if (!value || value === 'null') {
      value = [];
    }
    this.value = value;
  }

  @autobind clearIndexData() {
    this.updateFilter(null);
  }

  @autobind selectAll() {
    const ids = map(this.props.options, (item) => { return item.id; });
    this.updateFilter(ids);
  }

  toggleOption(option) {
    let options = [];
    if (isEmpty(this.value)) {
      options.push(option);
    } else if (indexOf(this.value, option) === -1) {
      options = this.value;
      options.push(option);
    } else {
      options = without(this.value, option);
    }
    this.updateFilter(options);
  }

  updateFilter(value) {
    this.value = value;
    if (value) {
      this.props.store.setFilter(this.props.name, value, { refresh: true });
    } else {
      this.props.store.removeFilter(this.props.name, { persistRemoval: true, refresh: true });
    }
  }

  label(item) {
    if (this.props.labelField) {
      return item[this.props.labelField];
    }
    return item.name;
  }

  render() {
    return (
      <FilterComponent
        selected={isPresent(this.value)}
      >
        <Wrapper>
          {
            this.props.options && this.props.options.map((item) => {
              return (
                <FilterButton
                  key={item.id}
                  onClick={this.toggleOption.bind(this, item.id)}
                >
                  <i
                    className={cx('fa', {
                      'fa-check-circle': indexOf(this.value, item.id) !== -1,
                      'fa-ban': indexOf(this.value, item.id) === -1,
                    })}
                  />
                  <FilterButtonText
                    highlightDictionaryWordTypes={Boolean(this.props.highlightDictionaryWordTypes)}
                  >
                    {this.label(item)}
                  </FilterButtonText>
                </FilterButton>
              );
            })
          }
          <FilterButton
            onClick={this.selectAll}
          >
            <i className="fa fa-check-circle" />
          </FilterButton>
          <FilterButton
            onClick={this.clearIndexData}
          >
            <i className="fa fa-ban" />
          </FilterButton>
        </Wrapper>
      </FilterComponent>
    );
  }
}

export default FilterMultiSelect;
