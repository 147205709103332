import React from 'react';
import { observer } from 'mobx-react';
import { PromotionStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';
import { computed } from "mobx";
import autobind from "autobind-decorator";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  margin: 10px 0;
`;

@observer
class PromotionCreate extends React.Component {

  @computed get randomSlug() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }

    return result;
  }

  @autobind setRandomSlug() {
    if (this.refs.slug.value === '') {
      this.refs.slug.value = this.randomSlug;
    }
  }

  onClick() {
    if (isPresent(this.refs.name.value)) {
      PromotionStore.create(
        {
          data: {
            name: this.refs.name.value,
            slug: this.refs.slug.value,
          },
        },
      );
      this.refs.name.value = '';
      this.refs.slug.value = '';
    }
  }

  render() {
    return (
      <Wrapper>
        <div className="col-xs-4">
          <input
            placeholder="Add a name"
            ref="name"
            onChange={this.setRandomSlug}
          />
        </div>
        <div className="col-xs-2">
          <input
            placeholder="Add a slug"
            ref="slug"
          />
        </div>
        <div className="col-xs-2">
          <button
            className="button-primary"
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </Wrapper>
    );
  }
}

export default PromotionCreate;
