import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Link } from 'react-router';

const Wrapper = styled.div`

`;

@observer
class Legal extends React.Component {

  render () {
    return (
      <Wrapper>
        <h3>Legal Information</h3>
        <h4>Seedlang, Inc.</h4>
        <p>
          2093 Philadelphia Pike #9846<br />
          Claymont, DE 19703<br />
          United States<br />
        </p>
        <h4>Seedlang GmbH</h4>
        <p>
          Florastraße 48<br />
          13187 Berlin<br />
          Germany<br />
        </p>

        <h4>Managing Director</h4>
        <p>
          Jeremy Smith
        </p>

        <h4>Contact</h4>
        Email: <a className='underline' href='mailto:help@seedlang.com'>help@seedlang.com</a><br />
        You can also contact us via our <Link className='underline' to="contact">contact form</Link>.<br />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Legal);
