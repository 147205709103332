import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import LineChartWithControls from 'components/visualization/line_chart_with_controls';

const Wrapper = styled.div`

`;

@observer
class CampaignMessagesDashboard extends React.Component {

  render() {
    return (
      <Wrapper>
        <fieldset>
          <legend>CampaignMessages</legend>
          <div className='row'>
            <div className='col-xs-12'>
              <LineChartWithControls
                namespace='user-dashboard-mailings'
                charts={[
                  {
                    name: 'CampaignMessages',
                    resources: [
                      {
                        name: 'CampaignMessage',
                        label: 'CampaignMessages',
                      },
                      {
                        name: 'CampaignMessage',
                        label: 'Clicked',
                        query: 'clicked = true',
                      },
                    ],
                  },
                ]}
              />
            </div>
          </div>
        </fieldset>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(CampaignMessagesDashboard);
