import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import VocabList from 'components/vocab/vocab_list';
import Filter from 'components/filter';
import { VocabWrapper } from '@seedlang/hoc';
import SearchInput from 'components/form/search_input';

const Wrapper = styled.div`

`;

const SearchInputWrapper = styled.div`
  margin-top: 10px;
`;

const FilterWrapper = styled.div`
  margin-bottom: 10px;
`;

@observer
class TranslatorWords extends React.Component {

  render () {
    return (
      <Wrapper>
        <SearchInputWrapper>
          <SearchInput
            placeholder="Search for a word"
            onChange={this.props.onUpdateSearchQuery}
            insertText={this.props.insertText}
            focusOnId={this.props.searchQuery}
            value={this.props.searchQuery}
            caretPosition={this.props.caretPosition}
            background="#FFF"
          />
        </SearchInputWrapper>
        <FilterWrapper>
          <Filter
            filterName="Vocab Filters"
            noFiltersSelectedMessage={this.props.noFiltersSelectedMessage}
            filterSettings={this.props.filterSettings}
            onSetFilter={this.props.onSetFilter}
            searchQuery={this.props.searchQuery}
            hasActiveFilter={this.props.hasActiveFilter}
            onToggleFiltersExpanded={this.props.onToggleFiltersExpanded}
            setShowFilterOptions={this.props.setShowFilterOptions}
            showFilterOptions={this.props.showFilterOptions}
            filteredItemsCount={this.props.filteredItemsCount}
            filteredItemsType="Word"
          />
        </FilterWrapper>
        <VocabList
          {...this.props}
        />
      </Wrapper>
    );
  }
}

export default VocabWrapper(TranslatorWords);
