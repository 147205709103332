import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { WordStore } from '@seedlang/stores';
import { isBlank, isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import Spinner from 'components/spinner';
import { RouteStore } from '@seedlang/stores';
import { WordTypeStore, LevelStore } from '@seedlang/stores';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import styled from '@emotion/styled';
import InPlaceLanguageSelect from 'components/form/in_place_language_select';
import InPlaceFlatSelect from 'components/form/in_place_flat_select';
import { keys } from 'lodash';
import { Constants } from '@seedlang/constants';
import { AppUI } from '@seedlang/state';
import TranslationIndex from "pages/builder/translations/translation_index";
import { TranslationSourceStore } from "@seedlang/stores";

const Wrapper = styled.div`
  .row {
    margin: 5px 0 15px 0;
  }
  .col-xs-5 {
    padding-right: 5px;
  }
  label {
    font-size: 12px;
    margin: 0;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
`;

const Link = styled.div`
    cursor: pointer;
    margin: 5px 0;
    text-decoration: underline;
    font-size: 12px;
`;

@observer
class SentenceCreate extends React.Component {
  @observable targetText;
  @observable sourceText;
  @observable digitSource;
  @observable sourceLanguageId;
  @observable languageId = 'DE';
  @observable otherSource;
  @observable wordTypeId;
  @observable gender;
  @observable pluralTargetText;
  @observable plural;
  @observable noPlural;
  @observable root;
  @observable duplicates = [];
  @observable alreadySubmitted = false;
  @observable levelId = "3130a18e-6ac5-44e5-b305-09600811407e";
  @observable showSpinner = false;
  @observable excludeFromPublicLists = false;
  @observable isDate = false;
  @observable showMoreWordTypes = false;

  constructor(props) {
    super(props);
    this.targetText = this.props.targetText && this.props.targetText.trim();
    this.sourceText = this.props.sourceText && this.props.sourceText.trim();
    this.sourceLanguageId = this.props.sourceLanguageId;
    if (keys(Constants.LANGUAGE_OPTIONS).indexOf(AppUI.user.languageId) !== -1) {
      this.languageId = AppUI.user.languageId;
    }
    if (this.targetText && this.targetText.toLowerCase().match(/^die /)) {
      this.gender = "feminine";
      this.wordTypeId = "d5c5fcef-bb92-48a8-b00b-e7c71cb33068";
    } else if (this.targetText && this.targetText.toLowerCase().match(/^der /)) {
      this.gender = "masculine";
      this.wordTypeId = "d5c5fcef-bb92-48a8-b00b-e7c71cb33068";
    } else if (this.targetText && this.targetText.toLowerCase().match(/^das /)) {
      this.gender = "neuter";
      this.wordTypeId = "d5c5fcef-bb92-48a8-b00b-e7c71cb33068";
    }
  }

  @computed get toError() {
    return this.isVerb && isPresent(this.sourceText) && isBlank(this.sourceText.match(/^to /))
  }

  @computed get isVerb() {
    return this.wordTypeId === '4c4389c1-f805-4d1c-ba45-6721cf400aef';
  }

  @computed get isNoun() {
    return this.wordTypeId === "d5c5fcef-bb92-48a8-b00b-e7c71cb33068";
  }

  @computed get isNumber() {
    return this.wordTypeId === "1a0d84ff-a903-4be9-b0a8-22fdcc211506";
  }

  @computed get disabled() {
    return this.invalidNoun || this.toError || isBlank(this.targetText) || isBlank(this.sourceText) || isBlank(this.wordTypeId);
  }

  @computed get invalidNoun() {
    return this.isNoun && (isBlank(this.gender) || !(this.plural || this.noPlural || isPresent(this.pluralTargetText)));
  }

  @autobind updateTargetText() {
    this.targetText = this.refs.targetText.value;
  }

  @autobind createWord() {
    WordStore.create(
        {
          data: {
            targetText: this.targetText,
            sourceText: this.sourceText,
            root: true,
            languageId: this.languageId,
            sourceLanguageId: this.otherSource && this.sourceLanguageId,
            otherSource: this.otherSource,
            wordTypeId: this.wordTypeId,
            levelId: this.levelId,
            cardId: this.props.cardId,
            gender: this.gender,
            userId: this.props.userId || AppUI.user.id,
            pluralTargetText: this.pluralTargetText,
            plural: this.plural,
            noPlural: this.noPlural,
            excludeFromPublicLists: this.excludeFromPublicLists,
            isDate: this.isDate,
            allowDuplicateRoots: this.duplicates.length > 0,
          },
          queryStrings: {force_create: true},
        },
        this.afterCreate,
    );
    this.targetText = '';
    this.sourceLanguageId = null;
    this.sourceText = '';
    this.pluralTargetText = '';
    this.plural = null;
    this.noPlural = null;
    this.gender = null;
    this.wordTypeId = null;
  }

  @autobind onClick() {
    if (isPresent(this.refs.targetText.value) && isPresent(this.refs.sourceText.value)) {
      this.showSpinner = true;
      if (!this.alreadySubmitted) {
        this.alreadySubmitted = true;
        WordStore.searchByParams(
            {
              data: {
                targetText: this.targetText,
                root: true,
                languageId: this.languageId,
                wordTypeId: this.wordTypeId,
                gender: this.gender,
                // pluralTargetText: this.pluralTargetText,
                plural: this.plural,
                noPlural: this.noPlural,
              }
            }, this.afterSearchByParams
        )
      } else {
        this.createWord();
      }
    }
  }

  @autobind onSwap() {
    let intermediary = this.targetText || '';
    this.targetText = this.sourceText || '';
    this.sourceText = intermediary;
  }

  @autobind onMoveToOtherTranslation() {
    this.otherSource = this.sourceText;
    this.sourceText = '';
  }

  @autobind afterCreate(resp) {
    this.showSpinner = false;
    if (isPresent(this.digitSource)) {
      TranslationSourceStore.create({data: {translation_id: resp.word.id, text: this.digitSource, languageId: 'U'}})
    }
    if (this.props.afterCreate) {
      this.props.afterCreate();
    } else {
      RouteStore.routeToNamed('builder.words.edit', {wordId: resp.word.id});
    }
  }

  @autobind afterSearchByParams(resp)  {
    this.duplicates = resp;
    if (this.duplicates.length === 0) {
      this.createWord();
    } else {
        this.showSpinner = false;
    }
  }

  @autobind updateLanguage(sourceLanguageId) {
    this.sourceLanguageId = sourceLanguageId;
  }

  @autobind onChangeLevel(levelId) {
    this.levelId = levelId;
  }

  render() {
    return (
      <Wrapper>
        {this.showSpinner && <Spinner className='blue' />}
        <div className='row'>
          <div className="col-xs-12">
            <label>Word Language</label>
            <InPlaceSelect
              onChange={(item) => this.languageId = item}
              value={this.languageId}
              options={Constants.LANGUAGE_SELECT_OPTIONS}
            />
          </div>
        </div>
        <div className='row'>
          <div className="col-xs-5">
            <label>{`${Constants.LANGUAGE_OPTIONS[this.languageId]} Word`}</label>
            <textarea
              className='targetText'
              type="text"
              maxLength="110"
              placeholder={`${Constants.LANGUAGE_OPTIONS[this.languageId]} Word`}
              onChange={() => this.updateTargetText()}
              ref="targetText"
              value={this.targetText}
            />
          </div>
          <div className="col-xs-5">
            <label>English Source</label>
            <textarea
              type="text"
              placeholder="English Source"
              value={this.sourceText}
              onChange={() => this.sourceText = this.refs.sourceText.value}
              ref="sourceText"
            />
            {
              this.toError &&
                <ErrorMessage>
                  Please use "to " as the prefix for verb translations
                </ErrorMessage>
            }
          </div>
          <div className="col-xs-2">
            {
              (isPresent(this.targetText) || isPresent(this.sourceText)) &&
                <i
                  className='fa fa-exchange fa-on-click'
                  style={{margin: "40px 10px 0 10px"}}
                  onClick={this.onSwap}
                />
            }
            {
              isPresent(this.sourceText) &&
                <i
                  className='fa fa-arrow-down fa-on-click'
                  style={{margin: "40px 10px 0 10px"}}
                  onClick={this.onMoveToOtherTranslation}
                />
            }
          </div>
        </div>
        <div className='row'>
          <div className="col-xs-5">
            <label>Other Source Language</label>
            <InPlaceLanguageSelect
              showEdit
              includeBlank
              value={this.sourceLanguageId}
              onChange={this.updateLanguage}
            />
          </div>
          {
            this.sourceLanguageId !== 'EN' &&
              <div className="col-xs-5">
                <label>Other Source Text</label>
                <textarea
                  type="text"
                  placeholder="Other Source"
                  value={this.otherSource}
                  onChange={() => this.otherSource = this.refs.otherSource.value}
                  ref="otherSource"
                />
              </div>
          }
        </div>
        <div className='row'>
          <div className="col-xs-5">
            <label>Word Type</label>
            {
              !this.showMoreWordTypes &&
                <InPlaceFlatSelect
                  fontSize="12px"
                  margin="2px"
                  padding="3px"
                  includeBlank
                  placeholder="Select Word Type"
                  value={this.wordTypeId}
                  options={WordTypeStore.indexData.filter(item => item.root && item.showInVocabTrainer).map(item => [item.id, item.name])}
                  onChange={(id) => this.wordTypeId = id}
                />
            }
            {
              this.showMoreWordTypes &&
                <InPlaceFlatSelect
                  fontSize="12px"
                  margin="2px"
                  padding="3px"
                  includeBlank
                  placeholder="Select Word Type"
                  value={this.wordTypeId}
                  options={WordTypeStore.indexData.filter(item => item.root).map(item => [item.id, item.name])}
                  onChange={(id) => this.wordTypeId = id}
                />
            }
            <Link
              onClick={() => this.showMoreWordTypes = !this.showMoreWordTypes}
            >
              { this.showMoreWordTypes ? "Only show dictionary word types" : "Show more word types" }
            </Link>
          </div>
          <div className='col-xs-5'>
            <label>Level</label>
            <InPlaceFlatSelect
              fontSize="12px"
              margin="2px"
              padding="3px"
              options={LevelStore.indexData.filter(word => isPresent(word.abbreviation) && word.abbreviation !== 'N').map(word => [word.id, word.abbreviation])}
              onChange={this.onChangeLevel}
              value={this.levelId}
            />
            <label>Exclude from Vocab</label>
            <InPlaceCheckbox
              value={this.excludeFromPublicLists}
              icon="check"
              onChange={(val) => this.excludeFromPublicLists = val}
            />
          </div>
        </div>
        {
          this.isNoun &&
            <div className='row'>
              <div className='col-xs-5'>
                <label>Gender</label>
                <InPlaceFlatSelect
                  fontSize="12px"
                  margin="2px"
                  padding="3px"
                  includeBlank
                  placeholder="Select Gender"
                  value={this.gender}
                  options={this.languageId === 'DE' ? [['feminine', 'feminine'], ['masculine', 'masculine'], ['neuter', 'neuter']] : [['feminine', 'feminine'], ['masculine', 'masculine']]}
                  onChange={(gender) => this.gender = gender}
                />
              </div>
              <div className="col-xs-5">
                <label>Plural</label>
                <textarea
                  type="text"
                  placeholder="Enter Root Plural"
                  value={this.pluralTargetText}
                  onChange={() => this.pluralTargetText = this.refs.pluralTargetText.value}
                  ref="pluralTargetText"
                />
                <label>Has No Plural</label>
                <InPlaceCheckbox
                  value={this.noPlural}
                  icon="check"
                  onChange={(val) => this.noPlural = val}
                />
                <label>Is Plural (And No Singular Exists)</label>
                <InPlaceCheckbox
                  value={this.plural}
                  icon="check"
                  onChange={(val) => this.plural = val}
                />
              </div>
            </div>
        }
        {
          this.isNumber &&
          <div className='row'>
            <div className='col-xs-5'>
              <label>Number as digits</label>
              <textarea
                type="text"
                placeholder="(ex: 18)"
                value={this.digitSource}
                onChange={() => {
                  this.digitSource = this.refs.digitSource.value
                }}
                ref="digitSource"
              />
              <label>This number is a date</label>
              <InPlaceCheckbox
                value={this.isDate}
                icon="check"
                onChange={(val) => this.isDate = val}
              />
            </div>
          </div>
        }
        <div className='row'>
          <div
              className="col-xs-12"
              style={{display: "flex"}}
          >
            <button
              className={cx("button-primary", {
                disabled: this.disabled,
              })}
              onClick={this.disabled ? null : this.onClick}
            >
              Create Word
            </button>
          </div>
        </div>
        {
            this.duplicates.length > 0 &&
              <div>
                <div
                    className='alert'
                    style={{
                      margin: "0 0 10px 0",
                    }}
                >
                  This word already seems to exist. Please click on 'Create Word' again to create it anyway.
                </div>
                <TranslationIndex
                    translations={this.duplicates}
                    targetType={"Word"}
                    hide={['root']}
                />
              </div>
        }
      </Wrapper>
    );
  }
}

export default SentenceCreate;
