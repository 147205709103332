import React from 'react';
import { observer } from 'mobx-react';
import { SpecialCharacterButtonsWrapper } from "@seedlang/hoc";
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import { pixify } from "@seedlang/utils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  width: ${props => pixify(props.width)};
  padding: ${props => pixify(props.padding)};
  margin: ${props => props.margin}
`;

const Button = styled.div`
  color: ${(props) => props.disabled ? '#868686' : '#565656'};
  cursor: ${(props) => props.disabled ? 'default' : 'pointer'};
  background: ${(props) => props.disabled ? '#CCC' : props.background};
  border: ${(props) => props.border};
  padding: ${(props) => props.padding};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  margin-right: ${(props) => props.marginRight};
  margin-left: ${(props) => props.marginLeft};
  border-radius: 5px;
  webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
`;

@observer
class SpecialCharacterButtons extends React.Component {

  static defaultProps = {
    padding: "5px 10px",
    lineHeight: "14px",
    fontSize: "16px",
    background: '#FFF',
  }


  render() {
    return (
      <Wrapper
        center={this.props.center}
        margin={this.props.margin}
        width={this.props.width}
        padding={this.props.outerPadding}
      >
        {
          AppUI.user.showSpecialCharacters && this.props.options && this.props.options.map((item, index) => {
            return (
              <Button
                key={item}
                background={this.props.background}
                padding={this.props.padding}
                border={this.props.border}
                fontSize={this.props.fontSize}
                lineHeight={this.props.lineHeight}
                disabled={this.props.disabled}
                onClick={() => this.props.onClick(item)}
                marginLeft={index === 0 && this.props.center ? 'auto' : '3px'}
                marginRight={index === this.props.options.length - 1 && this.props.center ? 'auto' : '3px'}
              >
                {item}
              </Button>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default SpecialCharacterButtonsWrapper(SpecialCharacterButtons);
