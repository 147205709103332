import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import styled from '@emotion/styled';
import TextDisplay from 'components/text_display';
import { DeckUI } from '@seedlang/state';
import StickerReaction from 'components/sticker_reaction';

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  position: relative;
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  flex: 1;
`;

const Tip = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: bold;
`;

const On = styled.div`
  display: flex;
  margin: 5px 0;
  font-size: 14px;
`;

const Title = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  text-align: left;
`;

const TextWrapper = styled.div`
  padding: 0 20px;
  text-align: left;
`;

const NAMES = {
  FR: 'Alix',
  ES: 'Camila',
  DE: 'Sofie',
};

@observer
class GrammarTip extends React.Component {

  constructor(props) {
    super(props);
    this.props.card.cardState.markGrammarTipAsSeen();
    this.name = NAMES[this.languageId];
  }

  @computed get languageId() {
    return this.props.card.languageId || DeckUI.deck.languageId || 'DE';
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        <Header>
          <StickerReaction 
            skipAnimation
            languageId={this.languageId}
            card={this.props.card}
            reaction="thoughtful"
          />
          <TitleWrapper>
            <Tip>
              {this.name}'s Tip
            </Tip>
            <On>
              on
            </On>
            <Title>
              {this.props.card.grammarTipTitle}
            </Title>
          </TitleWrapper>
        </Header>
        <TextWrapper>
          <TextDisplay
            htmlText={this.props.card.grammarTip}
          />
        </TextWrapper>
      </Wrapper>
    );
  }
}

export default GrammarTip;
