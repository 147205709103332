import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  padding: 10px 20px;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin: ${props => props.margin};
`;

@observer
class Prompt extends React.Component {

  render() {
    return (
      <Wrapper margin={this.props.margin}>
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Prompt);
