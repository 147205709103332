"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = normalizeCharacters;
var _constants = require("@seedlang/constants");
var _utils = require("@seedlang/utils");
function normalizeCharacters(string) {
  if ((0, _utils.isBlank)(string)) {
    return;
  }
  var str = string.replace(/ä/g, "ae").replace(/Ä/g, "Ae").replace(/ä/g, "ae").replace(/Ä/g, "Ae") // different character
  .replace(/ö/g, "oe").replace(/Ö/g, "Oe").replace(/ö/g, "oe").replace(/Ö/g, "Oe") // different character
  .replace(/ü/g, "ue").replace(/Ü/g, "Ue").replace(/ü/g, "ue").replace(/Ü/g, "Ue") // different character
  .replace(/œ/g, "oe").replace(/Œ/g, "Oe").replace(/ß/g, "ss").trim().replace("  ", " ").replace(_constants.Constants.SPECIAL_PUNCTUATION.RIGHT_SINGLE_QUOTATION_MARK, _constants.Constants.SPECIAL_PUNCTUATION.APOSTROPHE).replace(_constants.Constants.SPECIAL_PUNCTUATION.LEFT_SINGLE_QUOTATION_MARK, _constants.Constants.SPECIAL_PUNCTUATION.APOSTROPHE).replace(/'\s+/, _constants.Constants.SPECIAL_PUNCTUATION.APOSTROPHE).replace(/\(|\)/g, "").replace(new RegExp("\\s*(\\.{2,4}|".concat(_constants.Constants.SPECIAL_PUNCTUATION.ELLIPSIS, ")\\s*")), " "); // different versions of " ... "
  return str;
}