import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';

const MediumScreenshot = styled.div`
  max-width: 450px;
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #efefef;
  border-radius: 10px;
  box-shadow: 0 6px 30px 0 rgba(0,0,0,.3);
`

const Wrapper = styled.div`

`;

@observer
class VocabTrainerDescription extends React.Component {

  render() {
    return (
      <Wrapper>
        <MediumScreenshot>
          <video
            muted
            autoPlay
            playsInline
            loop
            id="video"
            width="90%"
            preload="auto"
            src="/images/vocab_walkthrough.mp4"
          />
        </MediumScreenshot>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(VocabTrainerDescription);
