import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import autobind from 'autobind-decorator';
import { MembershipTypeStore, WorksheetMembershipTypeStore } from '@seedlang/stores';
import { flexCenter } from '@seedlang/style_mixins';
import cx from 'classnames';
import Spinner from 'components/spinner';
import { isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  .fa-check {
    color: #BBB;
    margin-right: 5px;
  }
  .fa-check.selected {
    color: #000;
  }
  h3 {
    text-align: center;
    margin-bottom: 20px!important;
  }
`;

const TagName = styled.div`
  flex: 1;
  font-size: 14px;
`;

const MembershipType = styled.div`
  ${flexCenter()}
  cursor: pointer;
`;

@observer
class MembershipTypeSelect extends React.Component {
  @observable showSpinner = false;

  constructor(props) {
    super(props);
    this.showSpinner = true;
    this.loadMembershipTypes();
  }

  @autobind loadMembershipTypes() {
    MembershipTypeStore.getIndex({}, this.afterLoadMembershipTypes);
  }

  @autobind afterLoadMembershipTypes(resp) {
    MembershipTypeStore.setIndexData(resp);
    this.showSpinner = false;
  }

  @autobind membershipExists(membershipType) {
    if (this.props.worksheet && isPresent(this.props.worksheet.worksheetMembershipTypes)) {
      return this.props.worksheet.worksheetMembershipTypes.find(item => item.membershipType.id === membershipType.id);
    }
  }

  @autobind onToggle(membershipType) {
    if (this.membershipExists(membershipType)) {
      const worksheetMembershipType = this.props.worksheet.worksheetMembershipTypes.find(item => item.membershipType.id === membershipType.id);
      WorksheetMembershipTypeStore.destroy({ids: {worksheetMembershipTypeId: worksheetMembershipType.id}}, this.props.onChange);
    } else {
      WorksheetMembershipTypeStore.create({data: {membership_type_id: membershipType.id, worksheet_id: this.props.worksheet.id}}, this.props.onChange);
    }
  }

  render() {
    return (
      <Wrapper>
        {
          this.showSpinner &&
            <Spinner />
        }
        {
          MembershipTypeStore.indexData.length > 0 && this.props.worksheet &&
          <h3>Edit Membership Types for this Post</h3>
        }
        {
          MembershipTypeStore.indexData.map(tag => {
            return (
              <MembershipType
                key={tag.id}
                onClick={() => this.onToggle(tag)}
              >
                <i
                  className={cx('fa', 'fa-check', {
                    selected: this.membershipExists(tag),
                  })}
                />
                <TagName>
                  {tag.name}
                </TagName>
              </MembershipType>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(MembershipTypeSelect);
