import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';

@observer
class TreeModuleIndex extends React.Component {

  render() {
    return (
      <div className='tree-module-index'>

      </div>
    );
  }
}

export default ErrorBoundary(TreeModuleIndex);
