import React from 'react';
import { observer } from 'mobx-react';
import { computed, toJS } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Paginator from 'components/paginator';
import ExerciseProgress from 'components/exercise/exercise_progress';
import { flexCenter } from '@seedlang/style_mixins';
import Text from 'components/text';
import { clone, each, without } from 'lodash';
import { AppUI, ExerciseUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import { isPresent } from '@seedlang/utils';
import TagIndex from 'components/tag/tag_index';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
`;

const TableWrapper = styled.div`
  flex: 1;
`;

const Table = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const Row = styled.div`
  ${flexCenter()}
  background: ${(props) => props.header ? "#CCC" : "#FFF"};
  cursor: ${(props) => props.header ? "default" : "pointer"};
  border-radius: ${props => props.borderRadius};
  margin: ${props => props.margin};
  padding: 10px;
  &:hover {
    background: ${(props) => props.header ? "#CCC" : "whitesmoke"};
  }
`;

const Exercise = styled.div`
  flex: 1;
`;

const Score = styled.div`
  width: 80px;
`;

const Date = styled.div`
  width: 100px;
`;

const Tags = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  font-size: 11px;
  padding: 4px 8px;
  border: 1px solid gray;
  border-radius: 3px;
  margin: 1px;
  line-height: 11px;
`;

const TagSection = styled.div`
  width: 200px;
  margin-right: 10px;
`;

@observer
class ExerciseIndex extends React.Component {

  @computed get hasSelectedTags() {
    return isPresent(AppUI.user.tagIds);
  }

  @computed get selectedTagIds() {
    return this.hasSelectedTags ? AppUI.user.tagIds.split(",") : [];
  }

  @computed get paginatorIds() {
    let params = {groupId: this.props.params.groupId};
    if (this.selectedTagIds) {
      params['tag_ids'] = this.selectedTagIds;
    }
    return params;
  }

  constructor(props) {
    super(props);
    this.getExercises();
    AppUI.createEvent('worksheets - view exercise list');
  }

  @autobind onClickTag(tagCategory, id) {
    let ary = clone(this.selectedTagIds)
    const tag = tagCategory.tags.find(item => item.id === id);
    if (ary.indexOf(id) === -1) {
      if (tagCategory.selectOnlyOne) {
        each(tagCategory.tags, (tag) => {
          ary = without(toJS(ary), tag.id);
        })
      }
      ary.push(id);
    } else {
      ary = without(toJS(ary), id);
    }
    this.updateUser('tagIds', ary.join(','))
    ExerciseUI.exerciseStore.setPage(1);
  }

  @autobind updateUser(field, value) {
    let data = {};
    data[field] = value;
    AppUI.user.set(field, value);
    AppUI.authUserStore.update({data: data}, this.getExercises);
  }

  @autobind getExercises() {
    let params = {};
    if (this.hasSelectedTags) {
      params = {queryStrings: {tag_ids: this.selectedTagIds.join(",")}};
    }
    if (ExerciseUI.user.admin) {
      params['limit'] = 30;
    }
    ExerciseUI.exerciseStore.getIndex(params);
  }

  @computed get selectedExerciseType() {
    return ExerciseUI.exerciseTypeStore.hasIndexData ? ExerciseUI.exerciseTypeStore.indexData.find(item => item.id === ExerciseUI.exerciseStore.filters['exercise_type_id']) : null;
  }

  render() {
    return (
      <Wrapper>
        {
          !ExerciseUI.layout.isMobile &&
            <TagSection>
              <TagIndex
                tagCategories={ExerciseUI.tagCategoryStore.indexData}
                selectedTagIds={toJS(this.selectedTagIds)}
                groupId={this.props.params.groupId}
                onClickTag={this.onClickTag}
              />
            </TagSection>
        }
        <TableWrapper>
          {
            (ExerciseUI.user.admin || ExerciseUI.user.canUpdateRecords) && isPresent(ExerciseUI.exerciseStore.filters['exercise_type_id']) && isPresent(this.selectedExerciseType) &&
            <Table>
              <Row header>
                <Text
                  textAlign='left'
                  width='100%'
                  bold
                  fontSize="14px"
                >
                {this.selectedExerciseType.name}
                </Text>
              </Row>
              <Row  margin='0 0 10px 0' borderRadius='0 0 10px 10px'><Text textAlign='left' width='100%'>{this.selectedExerciseType.description}</Text></Row>
            </Table>
          }
          <Table>
            <Row
              header
            >
              <Exercise>
                <Text
                  bold
                  fontSize="14px"
                >
                  Exercise
                </Text>
              </Exercise>
              <Date>
                <Text
                  bold
                  fontSize="14px"
                >
                  Date
                </Text>
              </Date>
              <Score>
                <Text
                  bold
                  fontSize="14px"
                >
                  My Score
                </Text>
              </Score>
            </Row>
            {
              ExerciseUI.exerciseStore.indexData.map((item,index) => {
                return (
                  <Row
                    key={item.id}
                    onClick={() => ExerciseUI.routeStore.routeToNamed('exercises.show', {worksheetId: item.worksheetId, exerciseId: item.id, namespace: 'exercises'})}
                    borderRadius={index === ExerciseUI.exerciseStore.indexData.length - 1 ? '0 0 10px 10px' : ''}
                  >
                    <Exercise>
                      <Text
                        bold
                      >
                        {item.name}
                      </Text>
                      {
                        item.worksheet?.name &&
                          <Text fontSize='12px' lineHeight='14px' color='#a8a8a8'>
                            {item.worksheet?.name}
                          </Text>
                      }
                      <Tags>
                        {
                          item.orderedTags.map(tag => {
                            return (
                              <Tag
                                key={`${tag.id}-${item.id}`}
                              >
                                {tag.name}
                              </Tag>
                            )
                          })
                        }
                        {
                          (ExerciseUI.user.admin || ExerciseUI.user.canAccessAdminSite) && isPresent(item.exerciseType) &&
                            <Tag
                              key={`${item.id}-exercise-type`}
                            >
                              {item.exerciseType?.name}
                            </Tag>
                        }
                      </Tags>
                    </Exercise>
                    <Date>
                      <Text
                        italic
                        fontSize="14px"
                      >
                        {item.worksheet && item.worksheet.publishedAtDate}
                      </Text>
                    </Date>
                    <Score>
                      <ExerciseProgress
                        largeVersion
                        current={item.correctAnswersCount}
                        total={item.exerciseEntriesCount}
                      />
                    </Score>
                  </Row>
                )
              })
            }
            <Paginator
              store={ExerciseUI.exerciseStore}
              ids={this.paginatorIds}
              margin="20px 0"
            />
          </Table>
        </TableWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ExerciseIndex);
