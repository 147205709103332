import React from 'react';
import { observer } from 'mobx-react';
import { PointsBarWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { pixify } from '@seedlang/utils';

const Wrapper = styled.div`
  height: ${(props) => props.height};
  position: relative;
  background: #565656;
  border-radius: 5px;
  width: ${(props) => props.width};
`;

const ProgressBarWrapper = styled.div`
  z-index: ${(props) => props.zIndex};
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const ProgressBar = styled.div`
  will-change: height;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  height: ${(props) => props.height};
  background: ${(props) => props.background};
  border-radius: 5px;
  width: ${(props) => props.width};
`;

const TopTriangle = styled.div`
  left: 0;
  top: 0;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 25px 25px 0;
  border-color: transparent #333 transparent transparent;
  z-index: 3;
  transform: ${(props) => props.transform};
`;

const BottomTriangle = styled.div`
  left: 0;
  bottom: 0;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 25px 25px;
  border-color: transparent transparent #333 transparent;
  z-index: 3;
  transform: ${(props) => props.transform};
`;

@observer
class PointsBar extends React.Component {

  render() {
    return (
      <Wrapper
        height={pixify(this.props.height)}
        width={pixify(this.props.width)}
      >
        <TopTriangle
          transform={this.props.align === "right" ? "scaleX(-1)" : null}
        />
        <BottomTriangle
          transform={this.props.align === "right" ? "scaleX(-1)" : null}
        />
        <ProgressBarWrapper
          zIndex="2"
        >
          <ProgressBar
            background={this.props.color}
            width={pixify(this.props.width)}
            height={pixify(this.props.getHeight(this.props.delayedCurrent))}
          />
        </ProgressBarWrapper>
        <ProgressBarWrapper
          zIndex="1"
        >
          <ProgressBar
            background="#21fd8d"
            width={pixify(this.props.width)}
            height={pixify(this.props.getHeight(this.props.current))}
          />
        </ProgressBarWrapper>
      </Wrapper>
    );
  }
}

export default PointsBarWrapper(PointsBar);
