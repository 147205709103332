import React from 'react';
import { observer } from 'mobx-react';
import VideoPlayer from 'components/media/video_player';
import styled from '@emotion/styled';
import { pixify } from '@seedlang/utils';
import { CompositeVideoPlayerWrapper, BorderRadius } from '@seedlang/hoc';

const Wrapper = styled.div`
  overflow: auto;
  background: #dadada;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border-top-right-radius: ${(props) => props.borderTopRadius};
  border-top-left-radius: ${(props) => props.borderTopRadius};
  border-bottom-right-radius: ${(props) => props.borderBottomRadius};
  border-bottom-left-radius: ${(props) => props.borderBottomRadius};
  overflow: hidden;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

const Quadrant = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin: 5px;
  border: 2px solid black;
  border-radius: 10px;
  position: relative;
`;

const Number = styled.div`
  pointer-events: none;
  font-size: 20px;
  color: #FFF;
  position: absolute;
  top: 5px;
  left: 5px;
  background: #1DB5D7;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

@observer
class CompositeVideoPlayer extends React.Component {

  render() {
    return (
      <Wrapper
        height={pixify(this.props.height)}
        width={pixify(this.props.width)}
        borderTopRightRadius={pixify(this.props.borderTopRightRadius)}
        borderTopLeftRadius={pixify(this.props.borderTopLeftRadius)}
        borderBottomLeftRadius={pixify(this.props.borderBottomLeftRadius)}
        borderBottomRightRadius={pixify(this.props.borderBottomRightRadius)}
      >
        {
          this.props.mediaItems.map((item, index) => {
            return (
              <Quadrant
                key={item.id}
                height={pixify(this.props.mediaWidth)}
                width={pixify(this.props.mediaWidth)}
              >
                {
                  this.props.showNumbers &&
                    <Number>
                      {index + 1}
                    </Number>
                }
                {
                  item.videoClip &&
                    <VideoPlayer
                      autoPlay
                      left="0"
                      loop
                      muted
                      borderRadius={8}
                      videoClip={item.videoClip}
                      width={this.props.mediaWidth - 4}
                    />
                }
                {
                  item.image &&
                    <img
                      alt=''
                      src={item.image.url}
                      style={{
                        width: this.props.mediaWidth - 4,
                        height: this.props.mediaWidth - 4,
                      }}
                    />
                }
              </Quadrant>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default BorderRadius(CompositeVideoPlayerWrapper(CompositeVideoPlayer));
