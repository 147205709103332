import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import DeleteButton from 'components/button/delete_button';
import { CheckWithCircleIcon } from '@seedlang/icons';
import { Theme } from '@seedlang/constants';
import { flexCenter } from '@seedlang/style_mixins';

const TextWrapper = styled.div`
  flex: 1;
`;

const Target = styled.div`
  font-size: 14px;
  line-height: 18px;
`;

const Source = styled.div`
  font-size: 11px;
  line-height: 14px;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 10px;
  background: ${(props) => props.selected ? "rgba(255, 255, 0, 0.18)" : "none"};
  border-bottom: 1px solid #CCC;
  border-right: 1px solid #CCC;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  width: 40px;
  ${flexCenter()}
  svg {
    width: 24px;
    fill: ${(props) => props.selected ? Theme.green : "#e0e0e0"};
  }
`;

@observer
class SimpleSentenceIndexRow extends React.Component {

  render() {
    return (
      <Wrapper
        selected={this.props.sentence.id === this.props.sentenceId}
      >
        <TextWrapper
          onClick={() => this.props.onSelectSentenceId && this.props.onSelectSentenceId(this.props.sentence.id)}
        >
          <Target>
            {this.props.sentence.targetText}
          </Target>
          <Source>
            {this.props.sentence.sourceText}
          </Source>
        </TextWrapper>
        <ButtonWrapper
          selected={this.props.sentence.hasAllVideoClips}
        >
          <CheckWithCircleIcon />
        </ButtonWrapper>
        <ButtonWrapper>
          <DeleteButton
            onConfirm={() => this.props.onDeleteSentence(this.props.sentence.id)}
            message="Delete this sentence?"
            className="fa-block right"
          />
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SimpleSentenceIndexRow);
