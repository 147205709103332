import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { ExerciseUI } from '@seedlang/state';
import styled from '@emotion/styled';
import Button from "components/button/button";
import { flexCenter } from "@seedlang/style_mixins";
import { Theme } from "@seedlang/constants";
import autobind from "autobind-decorator";

const Wrapper = styled.div`
  ${flexCenter()}
  @media (max-width: 899px) {
    flex-direction: column;
  }
`;

@observer
class ExerciseEntryButton extends React.Component {

  @computed get style() {
    if (!this.props.exerciseEntry.state.revealed) {
      return {background: '#D8D8D8', color: '#333'};
    }
    if (this.props.exerciseEntry.state.revealed && this.props.exerciseEntry.state.correct) {
      return {background: Theme.green, color: 'white'};
    }
    if (this.props.exerciseEntry.state.revealed && !this.props.exerciseEntry.state.correct) {
      return {background: Theme.red, color: 'white'};
    }
  }

  @autobind onClickButton() {
    if (!this.props.exerciseEntry.state.revealed) {
      ExerciseUI.revealExerciseEntryAnswer(this.props.exerciseEntry);
    } else if (this.props.exerciseEntry.state.revealed && !this.props.exerciseEntry.state.correct) {
      ExerciseUI.resetIncorrectExerciseEntryAnswers(this.props.exerciseEntry);
    }
  }

  render() {
    return (
      <Wrapper>
        <Button
          margin="0 5px 0 0"
          height="40px"
          width="120px"
          lineHeight="14px"
          fontSize="12px"
          padding="0 10px"
          borderRadius="10px"
          color={this.style.color}
          background={this.style.background}
          onClick={this.onClickButton}
        >
          {
            !this.props.exerciseEntry.state.revealed &&
              <span>Show Answer</span>
          }
          {
            this.props.exerciseEntry.state.revealed && this.props.exerciseEntry.state.correct &&
              <span><i className="fa fa-check" /> Correct!</span>
          }
          {
            this.props.exerciseEntry.state.revealed && !this.props.exerciseEntry.state.correct &&
              <span><i className="fa fa-times" /> Reset</span>
          }
        </Button>
      </Wrapper>
    );
  }
}

export default ExerciseEntryButton;
