import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';

const TagItem = styled.div`
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid gray;
  font-size: 12px;
  font-weight: bold;
  pointer-events: none;
  margin-right: 5px;
`;

const Wrapper = styled.span`
  display: flex;
  align-items: center;
`;

@observer
class Tag extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.text.split(",").map(item => {
            return (
              <TagItem
                key={item}
              >
                {item}
              </TagItem>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Tag);
