import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexCenter } from '@seedlang/style_mixins';
import { formatNumber } from '@seedlang/utils';

const Wrapper = styled.div`
  ${flexCenter()}
  width: 50px;
  height: 30px;
  color: #656565;
  font-weight: bold;
`;

@observer
class ForumCount extends React.Component {

  render() {
    return (
      <Wrapper>
        {formatNumber(this.props.count)}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ForumCount);
