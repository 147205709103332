import React from 'react';
import { observer } from 'mobx-react';
import ListSelect from 'components/list_select';
import styled from '@emotion/styled';
import { OnboardingLevelWrapper } from '@seedlang/hoc';
import { flexCenterColumn } from '@seedlang/style_mixins';
import Button from 'components/button/button';
import { AppUI } from '@seedlang/state';
import OnboardingContent from 'components/onboarding/onboarding_content';
import OnboardingWrapper from 'components/onboarding/onboarding_wrapper';
import { isBlank } from '@seedlang/utils';
import { Levels } from '@seedlang/constants';
import OnboardingIllustration from 'components/onboarding/onboarding_illustration';
import autobind from 'autobind-decorator';

const Continue = styled.div`
  margin-top: 20px;
`;

@observer
class OnboardingLevel extends React.Component {

  @autobind continue() {
    AppUI.routeStore.routeToNamed('onboarding.native_language', {languageId: this.props.params.languageId});
  }

  render() {
    return (
      <OnboardingWrapper>
        <OnboardingIllustration
          text={this.props.illustrationText}
          reaction="disappointed"
          languageId={AppUI.targetLanguageId}
        />
        <OnboardingContent>
          <ListSelect
            value={this.props.levelId}
            options={Levels.filter(item => item.abbreviation !== 'N')}
            onSelect={this.props.onSetLevelId}
            fieldName="name"
          />
        </OnboardingContent>
        <Continue>
          <Button
            disabled={isBlank(this.props.levelId)}
            onClick={this.continue}
            width='440px'
            height='60px'
          >
            Continue
          </Button>
        </Continue>
      </OnboardingWrapper>
    );
  }
}

export default OnboardingLevelWrapper(OnboardingLevel);
