import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import InPlaceText from 'components/form/in_place_text';
import { RecordingSessionStore } from '@seedlang/stores';
import { Link } from 'react-router';
import VideoClipIndex from 'pages/builder/video_clips/video_clip_index';
import autobind from 'autobind-decorator';
import { VideoClipStore, UserStore, RouteStore } from '@seedlang/stores';
import Alert from 'components/alert';
import InPlaceSelect from 'components/form/in_place_select';
import DeleteButton from 'components/button/delete_button';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  
`;

const SmallText = styled.div`
  font-size: 12px;
  line-height: 14px;
  margin: ${props => props.margin};
  color: #333;
`;

const ReviewedUser = styled.div`
  font-size: 12px;
  padding-left: 18px;
  background: #FFF;
  margin: 0 0 5px 0;
`;

@observer
class RecordingSessionsShow extends React.Component {
  @observable showMessage = false;

  constructor(props) {
    super(props);
    this.loadRecordingSession();
    UserStore.getIndex({filters: {video_creator: true}})
  }

  @autobind onClickClear(queryStrings) {
    RecordingSessionStore.removeVideosFromTargets({queryStrings: queryStrings, ids: {recordingSessionId: this.props.params.recordingSessionId}}, this.afterClickClear);
  }

  @autobind onDestroyRecordingSession() {
    RecordingSessionStore.destroy({ids: {recordingSessionId: this.props.params.recordingSessionId}}, this.afterDestroySession);
  }

  @autobind afterDestroySession() {
    RouteStore.routeToNamed("creator.producer.recording_sessions.index");
  }

  @autobind loadRecordingSession() {
    RecordingSessionStore.getShow({ids: {recordingSessionId: this.props.params.recordingSessionId}}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    RecordingSessionStore.setShowData(resp);
  }

  @autobind afterClickClear() {
    this.showMessage = true;
    this.loadRecordingSession();
    VideoClipStore.reloadIndexFiltered();
  }

  render() {
    return (
      <Wrapper>
        {
          this.showMessage &&
            <Alert
              textAlign="center"
            >
              Videos have been cleared.
            </Alert>
        }
        <div
          style={{margin: "10px 0", textDecoration: "underline"}}
        >
          <Link
            to={{name: 'creator.producer.recording_sessions.index'}}
          >
            <i className='fa fa-angle-left' style={{margin: "2px 3px 0 0"}} /> Back to Recording Sessions
          </Link>
        </div>
        <div className='row'>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Date</legend>
              {RecordingSessionStore.hasShowData && RecordingSessionStore.showData.occurredAt.formattedDateWithYear}
            </fieldset>
          </div>
          <div className='col-xs-3'>
            <fieldset>
              <legend>User</legend>
                <InPlaceSelect
                  includeBlank
                  model="recording_sessions"
                  id={RecordingSessionStore.showData.id}
                  field="user_id"
                  options={UserStore.indexData.map(item => [item.id, item.name])}
                  afterChange={this.loadRecordingSession}
                  value={RecordingSessionStore.showData?.user?.id}
                />
            </fieldset>
          </div>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Clear Videos</legend>
              <div style={{display: 'flex'}}>
                <DeleteButton
                  onConfirm={() => this.onDestroyRecordingSession()}
                  message="Clear all videos? This is not reversible."
                >
                  <button
                    style={{marginRight: '5px'}}
                    className='gray-button'
                  >
                    All
                  </button>
                </DeleteButton>
                <DeleteButton
                  onConfirm={() => this.onClickClear({only_not_reviewed: true})}
                  message="Clear all not approved videos? This is not reversible."
                >
                  <button
                    style={{marginRight: '5px'}}
                    className='gray-button'
                  >
                    Not Approved
                  </button>
                </DeleteButton>
                <DeleteButton
                  onConfirm={() => this.onClickClear({only_words: true})}
                  message="Clear word videos? This is not reversible."
                >
                  <button
                    style={{marginRight: '5px'}}
                    className='gray-button'
                  >
                    Words
                  </button>
                </DeleteButton>
                <DeleteButton
                  onConfirm={() => this.onClickClear({only_nouns: true})}
                  message="Clear noun videos? This is not reversible."
                >
                  <button
                    className='gray-button'
                  >
                    Nouns
                  </button>
                </DeleteButton>
              </div>
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Reviews</legend>
                <div>
                <InPlaceCheckbox
                  model="recording_sessions"
                  field="initial_reviewed"
                  value={RecordingSessionStore.showData.initialReviewed}
                  id={RecordingSessionStore.showData.id}
                  icon="thumbs-up"
                  afterChange={this.loadRecordingSession}
                >
                  Initial Review 
                </InPlaceCheckbox>
              </div>
              <SmallText
                margin="5px 0"
              >
                Setup of lighting, sound, and framing was approved
              </SmallText>
              {
                isPresent(RecordingSessionStore.showData.initialReviewedUser) &&
                  <ReviewedUser>
                    {RecordingSessionStore.showData.initialReviewedUser.name}
                  </ReviewedUser>
              }
                <div>
                <InPlaceCheckbox
                  model="recording_sessions"
                  field="final_reviewed"
                  value={RecordingSessionStore.showData.finalReviewed}
                  id={RecordingSessionStore.showData.id}
                  icon="thumbs-up"
                  afterChange={this.loadRecordingSession}
                >
                  Final Review 
                </InPlaceCheckbox>
              </div>
              <SmallText
                margin="5px 0"
              >
                All videos were reviewed but not necessarily deleted
              </SmallText>
              {
                isPresent(RecordingSessionStore.showData.finalReviewedUser) &&
                  <ReviewedUser>
                    {RecordingSessionStore.showData.finalReviewedUser.name}
                  </ReviewedUser>
              }
            </fieldset>
          </div>
          <div className='col-xs-9'>
          <fieldset>
              <legend>Notes</legend>
                <InPlaceText
                  id={RecordingSessionStore.showData.id}
                  field="description"
                  richText
                  defaultValue={RecordingSessionStore.showData.description}
                  model="recording_sessions"
                />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Video Clips</legend>
              <VideoClipIndex
                userId={RecordingSessionStore.showData?.user?.id}
                recordingSessionId={this.props.params.recordingSessionId}
              />
            </fieldset>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(RecordingSessionsShow);
