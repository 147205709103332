import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { EmblemIcon } from '@seedlang/icons';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  position: absolute;
  width: 60px;
  height: 60px;
  z-index: ${Theme.z.background};
  top: 5px;
  right: 5px;
  color: #FFF;
  svg {
    fill: ${props => props.background};
    z-index: ${Theme.z.foreground};
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
  }
`;

const WrapperInner = styled.div`
  ${flexCenterColumn()}
  position: relative;
  width: 100%;
  height: 100%;
`;

const TextWrapper = styled.div`
  ${flexCenterColumn()}
  z-index: ${Theme.z["over-foreground"]};
`;

const Amount = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
`;

const Off = styled.div`
  font-size: 12px;
  line-height: 12px;
`;

@observer
class DiscountEmblem extends React.Component {

  render() {
    return (
      <Wrapper
        className='wrapper'
        background={this.props.background}
      >
        <WrapperInner className='wrapper-inner'>
          <TextWrapper className='text-wrapper'>
            <Amount>{this.props.amount}%</Amount>
            <Off>Off</Off>
          </TextWrapper>
          <EmblemIcon />
        </WrapperInner>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(DiscountEmblem);
