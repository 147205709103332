import React from 'react';
import { observer } from 'mobx-react';
import { DeckUI } from '@seedlang/state';
import { isPresent } from '@seedlang/utils';
import styled from '@emotion/styled';
import TextDisplay from 'components/text_display';
import Text from 'components/text';


const Wrapper = styled.div`
  text-align: left;
  font-size: 16px;
  line-height: 22px;
  padding: 20px;
  .close-icon {
    width: ${(props) => props.minimized ? "22" : "30"}px;
  }
  h3 {
    border-bottom: 1px solid gray;
    font-size: 18px;
    width: 100%;
  }
  table {
    .header-row {
      td {
        background: #777;
        color: white;
        font-size: 12px;
        font-weight: bold;
        padding: 5px 10px;
      }
    }
  }
  .info-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 4px;
    }
  }
`;

const ImageWrapper = styled.div`
  img {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    margin-right: 20px;
  }
`;

const DescriptionAndImage = styled.div`
  display: flex;
  margin-bottom: 5px;
  .description {
    flex: 1;
  }
`;

const TextWrapper = styled.div`
`;

@observer
class DeckGrammar extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          isPresent(DeckUI.deck.combinedDescription) &&
            <div className="description-wrapper">
              <h3>Description</h3>
              <DescriptionAndImage>
                <ImageWrapper>
                  <img src={DeckUI.deck.imageUrl} alt="" />
                </ImageWrapper>
                <TextWrapper>
                  <TextDisplay
                    markdown={DeckUI.deck.combinedDescription}
                  />
                </TextWrapper>
              </DescriptionAndImage>
            </div>
        }
        {
          DeckUI.deck.deckGrammarConcepts && !DeckUI.deck.grammarDescriptionOverride && DeckUI.deck.deckGrammarConcepts.map((item, index) => {
            return (
                <div
                  className="grammar-section"
                  key={`grammar-section-${index}`}
                >
                  <Text heading="3" style={{borderBottom: "1px solid gray", marginTop: "10px"}}>{item.concept.displayNameLine1WithCase}</Text>
                  {
                    item.concept.displayNameLine2 &&
                      <Text italic fontSize="14px">{item.concept.displayNameLine2}</Text>
                  }
                  <TextDisplay
                    htmlText={item.concept?.longDescription}
                  />
                </div>
            )
          })
        }
        {
          (!isPresent(DeckUI.deck.deckGrammarConcepts) || DeckUI.deck.grammarDescriptionOverride) && DeckUI.deck.hasCombinedGrammarDescription &&
            <TextDisplay
              htmlText={DeckUI.deck.combinedGrammarDescription}
            />
        }
      </Wrapper>
    );
  }
}

export default DeckGrammar;
