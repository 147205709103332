import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import { TreeUI } from '@seedlang/state';
import Spinner from 'components/spinner';
import FollowingIndex from 'components/following//following_index';
import { CrownIcon } from '@seedlang/icons';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection};
  align-items: ${props => props.alignItems};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${props => props.margin};
  .icon-wrapper {
    margin-right: 5px;
  }
`;

const ButtonText = styled.div`
  width: 280px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.color};
  i {
    position: absolute;
    left: -5px;
    top: 16px;
    transform: rotate(-45deg);
    color: ${props => props.color};
  }
`;

const Friends = styled.div`
  background: #d8d8d8;
  border-radius: 10px;
  width: 310px;
  padding: 0 0 10px 0;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: -5px;
  top: 16px;
`;

@observer
class TriviaModule extends React.Component {

  @computed get percentageSeen() {
    const seen = Math.floor(100 * (TreeUI.sentencesSeenCount / TreeUI.triviaTreeNode.publishedSentencesCount));
    return seen > 100 ? 100 : seen;
  }

  constructor(props) {
    super(props);
    this.loadTreeNode();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.treeNodeId !== this.props.treeNodeId) {
      this.loadTreeNode();
    }
  }

  loadTreeNode() {
    TreeUI.set('treeNodeId', this.props.treeNodeId);
  }

  componentWillUnmount() {
    TreeUI.triviaReset();
  }

  render() {
    return (
      <Wrapper>
        {
          TreeUI.loadingTree &&
            <Spinner
              className="blue"
            />
        }
        {
          TreeUI.userIsLoaded && !TreeUI.loadingTree && TreeUI.triviaHasPublishedSentences &&
            <Content
              flexDirection="column"
              alignItems="center"
            >
              {
                TreeUI.showFollowingIndex &&
                  <Friends>
                    <FollowingIndex
                      treeNodeId={this.props.treeNodeId}
                    />
                  </Friends>
              }
              {
                !TreeUI.showFollowingIndex &&
                  <ButtonWrapper>
                    {
                      !TreeUI.disableStartNewTriviaGame &&
                        <Button
                          height="50px"
                          width="280px"
                          background={Theme.blue}
                          onClick={() => TreeUI.onClickPlayTrivia()}
                          margin="0 0 10px 0"
                        >
                          <ButtonText
                            color="#FFF"
                          >
                            <i className='fa fa-arrow-right' />
                            Play Trivia
                          </ButtonText>
                        </Button>
                    }
                    {
                      !TreeUI.disableStartNewTriviaGame &&
                        <Button
                          height="50px"
                          background="#FFF"
                          width="280px"
                          margin={TreeUI.user.isMember ? null : "0 0 10px 0"}
                          onClick={() => TreeUI.showFollowingIndex = true}
                          border={`3px solid ${Theme.blue}`}
                        >
                          <ButtonText
                            color={Theme.blue}
                          >
                            <i className='fa fa-arrow-right' />
                            Challenge a Friend
                          </ButtonText>
                        </Button>
                    }
                    {
                      !TreeUI.user.isMember &&
                        <Button
                          height="50px"
                          onClick={() => TreeUI.routeStore.routeToNamed('memberships.index')}
                          width="280px"
                          background={Theme.green}
                        >
                          <ButtonText
                            color="#FFF"
                          >
                            <IconWrapper>
                              <CrownIcon />
                            </IconWrapper>
                            Unlock All Games
                          </ButtonText>
                        </Button>
                    }
                  </ButtonWrapper>
                }
            </Content>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TriviaModule);
