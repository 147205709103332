import React from 'react';
import { observer } from 'mobx-react';
import { computed } from "mobx";
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Checkbox from 'components/checkbox';
import { noop } from 'lodash';
import { isPresent, pixify, isBlank } from '@seedlang/utils';
import Text from 'components/text';
import VocabListWord from 'components/vocab/vocab_list_word';
import Paginator from 'components/paginator';
import Alert from 'components/alert';
import { AppUI } from '@seedlang/state';
import cx from 'classnames';
import Spinner from 'components/spinner';
import AddToReviews from "components/vocab/add_to_reviews";
import { VocabUI } from "@seedlang/state";


const Wrapper = styled.div`
  padding: 0 10px 10px 10px;
  background: white;
`;

const HeaderRow = styled.div`
  background: #636363;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  height: 30px;
`;

const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => props.width};
  flex: ${(props) => props.expand ? 1 : null};
  padding: 0 10px;
  border-right: 1px solid white;
  height: 30px;
  cursor: ${(props) => props.clickable ? "pointer" : "default"};
  color: #FFF;
  i {
    color: #FFF;
    text-decoration: none;
    margin-right: 4px;
  }
  .fa-check {
    margin-right: 0;
  }
`;

const ToggleLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  hover {
    text-decoration: none;
  }
`;

const ReviewOptionsWrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 0;
  margin-bottom: 10px;
  border: 2px solid #CCC;
  input {
    height: 30px!important;
    width: calc(100% - 40px)!important;
    margin-left: 20px;
  }
`;

const RowsWrapper = styled.div`
  height: ${props => props.height};
  overflow-y: scroll;
`;

@observer
class VocabList extends React.Component {

  componentDidMount() {
    AppUI.createEvent('vocab - view dictionary');
  }

  @computed get mobileHeight() {
    if (isPresent(this.props.searchQuery)) {
      return AppUI.layout.mobileContentHeight - 220;
    }
    return AppUI.layout.mobileContentHeight- 275;
  }

  render() {
    return (
      <Wrapper>
        {
          isPresent(this.props.reviewDeckOptions) &&
            <AddToReviews
              reviewDeckOptions={this.props.reviewDeckOptions}
              deckId={this.props.deckId}
              onSetDeckId={this.props.onSetDeckId}
              cardTypeOptions={this.props.cardTypeOptions}
              cardTypeId={this.props.cardTypeId}
              onSetCardTypeId={this.props.onSetCardTypeId}
              onCreateReviews={this.props.onCreateReviews}
              deckButtonDisabled={this.props.deckButtonDisabled}
              selectedWordsExcludedFromGenderTrainer={this.props.selectedWordsExcludedFromGenderTrainer}
              selectedWordsExcludedFromPluralTrainer={this.props.selectedWordsExcludedFromPluralTrainer}
              selectedWordsExcludedFromConjugationTrainer={this.props.selectedWordsExcludedFromConjugationTrainer}
              showReviewsCreatedMessage={this.props.createReviewsFinished || this.props.createReviewsIsSlow}
              selectedWordIds={this.props.selectedWordIds}
            />
        }
        {
          this.props.totalCount === 0 &&
            <Alert>
              There are no matching results for this search.
            </Alert>
        }
        <HeaderRow>
          {
            !this.props.grammarSection &&
              <HeaderCell
                width="40px"
              >
                {
                  this.props.showColumnCheckbox &&
                  <Checkbox
                    background="#d0d0d0"
                    selectedBackground="#232323"
                    onClick={this.props.onToggleAddAllToDeck}
                    value={this.props.allToggleSelected}
                  />
                }
              </HeaderCell>
          }
          {
            !this.props.grammarSection && !AppUI.layout.isMobile && AppUI.layout.leftColumnWidth > 700 &&
              <HeaderCell
                clickable={isBlank(this.props.searchQuery)}
                width="60px"
                onClick={() => isPresent(this.props.searchQuery) ? noop : this.props.onClickSort('frequency_ranking')}
              >
                {
                  (!this.props.sort || this.props.sort === 'frequency_ranking') && isBlank(this.props.searchQuery) &&
                    <i className={cx('fa', {
                      'fa-caret-down': !this.props.sortDirection || this.props.sortDirection === 'asc',
                      'fa-caret-up': this.props.sortDirection === 'desc',
                    })} />
                }
                <Text
                  color="white"
                  fontSize="10px"
                  lineHeight="11px"
                  textDecoration={isBlank(this.props.searchQuery) ? "underline" : ''}
                  bold
                >
                  Freq
                </Text>
              </HeaderCell>
          }
          <HeaderCell
            clickable={!AppUI.layout.isMobile && !this.props.grammarSection && isBlank(this.props.searchQuery)}
            expand={!this.props.showSource}
            width={this.props.showSource ? "200px" : null}
            onClick={() => AppUI.layout.isMobile || this.props.grammarSection || isPresent(this.props.searchQuery) ? noop : this.props.onClickSort('target_text')}
          >
            {
              !AppUI.layout.isMobile && !this.props.grammarSection && this.props.sort === 'target_text' && isBlank(this.props.searchQuery) &&
                <i className={cx('fa', {
                  'fa-caret-down': !this.props.sortDirection || this.props.sortDirection === 'asc',
                  'fa-caret-up': this.props.sortDirection === 'desc',
                })} />
            }
            <Text
              color="white"
              fontSize="10px"
              lineHeight="11px"
              textDecoration={AppUI.layout.isMobile || isPresent(this.props.searchQuery) ? null : "underline"}
              bold
            >
              Word
            </Text>
          </HeaderCell>
          {
            this.props.showSource &&
              <HeaderCell
                expand
              >
                <Text
                  color="white"
                  fontSize="10px"
                  lineHeight="11px"
                  bold
                >
                  <span>Translation </span>
                  <span>(</span>
                  <ToggleLink
                    onClick={this.props.onToggleExpandTranslation}
                  >
                    {this.props.expandTranslation ? 'hide' : 'show'}
                  </ToggleLink>
                  <span>)</span>
                </Text>
              </HeaderCell>
          }
          <HeaderCell
            width='70'
          >
            <Text
              color="white"
              fontSize="10px"
              lineHeight="11px"
              bold
            >
              Progress
            </Text>
          </HeaderCell>
          <HeaderCell
            width="65px"
          >
            <Text
              color="white"
              fontSize="10px"
              lineHeight="11px"
              bold
            >
              Learned
            </Text>
          </HeaderCell>
        </HeaderRow>
        {
          this.props.showSpinner &&
            <Spinner
              blue
            />
        }
        <RowsWrapper
          height={AppUI.layout.isMobile ? pixify(this.mobileHeight) : null}
        >
          {
            !this.props.showSpinner && this.props.wordStore.indexData.map(item => {
              return (
                <VocabListWord
                  key={item.id}
                  setWord={this.props.setWord}
                  showExpanded={isPresent(this.props.searchQuery) && !this.props.isMobile}
                  word={item}
                  user={AppUI.user}
                  wordType={this.props.wordType}
                  selectedWordIds={this.props.selectedWordIds}
                  isPro={this.props.user.isMember}
                  onToggleAddWordToDeck={this.props.onToggleAddWordToDeck}
                  onToggleLearned={this.props.onToggleLearned}
                  isMobile={this.props.isMobile}
                  allowSelection={AppUI.user.isMember}
                  showSource={this.props.showSource}
                  afterSubmitSourceInput={this.props.afterSubmitSourceInput}
                  expandTranslation={this.props.expandTranslation}
                  selectedCardTypeId={this.props.cardTypeId}
                  grammarSection={this.props.grammarSection}
                />
              )
            })
          }
          {
            !this.props.showSpinner && ((this.props.wordStore.hasIndexData && this.props.wordStore.pagesCount > 1) || isPresent(this.props.totalCount)) &&
            <Paginator
              store={this.props.wordStore}
              totalCount={this.props.totalCount}
              ids={this.props.ids}
              queryStrings={this.props.grammarSection ? {} : {vocab_list: true}}
              onPagination={() => VocabUI.allToggleSelected = false}
              updateUrl={!this.props.grammarSection}
              onClickScrollToTop
            />
          }
          {
            this.props.showNoResultsMessage &&
            <Alert
              textAlign="center"
              width="100%"
            >
              There are no results to display
            </Alert>
          }
        </RowsWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(VocabList);
