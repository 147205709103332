import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import ExerciseProgress from 'components/exercise/exercise_progress';
import { Theme } from '@seedlang/constants';
import { AppUI, ExerciseUI } from '@seedlang/state';
import { isPresent } from "@seedlang/utils/src";
import { computed } from "mobx";

const Wrapper = styled.div`
  flex: ${props => props.flex};
  height: ${props => props.height};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  background: white;
  border-radius: 10px;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 2px;
  position: relative;
  .sparkle-icon {
    width: 13px;
    position: absolute;
    top: 0px;
    left: 1px;
    transform: rotate(6deg);
    svg {
      fill: #ffff00;
    }
  }
`;

const Button = styled.div`
  background: ${(props) => props.current ? "white" : '#efefef' };
  color: #353535;
  font-weight: ${(props) => props.current ? 'bold' : 'normal'};
  border: ${(props) => props.current ? `1px solid #353535` : '1px solid #35353533'};
  margin: 2px 0 0 0;
  padding: 5px 5px 5px 10px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};
  display: flex;
  align-items: center;
  flex: 1;
  min-height: 30px;
  font-size: 14px;
  line-height: 19px;
`;

const LevelTag = styled.div`
  color: #353535;
  font-size: 10px;
  font-weight: normal;
  margin-left: 10px;
  width: 30px;
  height: 20px;
  display: flex;
  justify-content: center;
  padding: 3px 10px;
  border-radius: 5px;
  align-items: center;
  background: ${props => props.current ? '#efefef' : '#dcdcdc'};
`;

@observer
class ExerciseNavigation extends React.Component {

  static defaultProps = {
    flex: "1",
    padding: "20px",
    hasAccess: true,
  }

  @computed get accentColor() {
    if (!AppUI.siteIsDefault && isPresent(AppUI.site.accentColor)) {
      return AppUI.site.accentColor;
    } else {
      return Theme.blue;
    }
  }

  render() {
    return (
      <Wrapper
        flex={this.props.flex}
        height={this.props.height}
        padding={this.props.padding}
        margin={this.props.margin}
      >
        {
          this.props.exercises && this.props.exercises.map(item => {
            return (
              <Row
                key={item.id}
                onClick={() => this.props.hasAccess ? AppUI.routeStore.routeToNamed(this.props.useWorksheetRoute ? `${ExerciseUI.worksheetNamespace}.exercises.show` : 'exercises.show', {groupId: this.props.groupId, worksheetId: this.props.worksheetId, exerciseId: item.id}, this.props.afterRouteToExercise) : null}
              >
                <Button
                  disabled={!this.props.hasAccess}
                  accentColor="#848282"
                  current={this.props.currentExerciseId === item.id}
                  style={{display: 'flex', justifyContent: 'space-between'}}
                >
                  <div style={{maxWidth: '85%'}}>{item.name}</div>
                  {
                    isPresent(item.levelTagsFormatted) &&
                      <LevelTag
                        current={this.props.currentExerciseId === item.id}
                      >
                        {item.levelTagsFormatted}
                      </LevelTag>
                  }
                </Button>
                <ExerciseProgress
                  hideCounts
                  width={AppUI.layout.isMobile ? "70px" : "300px"}
                  exercise={item}
                  userExercises={this.props.userExercises}
                  marginLeft="4px"
                />
              </Row>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ExerciseNavigation);
