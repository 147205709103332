import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceSelect from 'components/form/in_place_select';
import { Link } from 'react-router';
import WordIndex from 'pages/builder/words/word_index';
import { orderBy, keys, isObject, noop } from 'lodash';
import ConceptIndex from 'pages/builder/concepts/concept_index';
import SentenceBreakdown from 'components/sentence_breakdown';
import autobind from 'autobind-decorator';
import SentenceConceptEdit from 'pages/builder/sentence_concepts/sentence_concept_edit';
import VocabConceptCreate from 'pages/builder/vocab_concepts/vocab_concept_create';
import Modal from 'components/modal';
import cx from 'classnames';
import { isBlank, isPresent } from '@seedlang/utils';
import { SentenceConceptStore, SentenceStore, AuthUserStore, InterjectionStore, SentenceAnswerStore, SentenceConceptIndexStore, TranslationSourceStore } from '@seedlang/stores';
import QuestionAssociationEdit from 'pages/builder/sentences/question_association_edit';
import AnswerAssociationEdit from 'pages/builder/sentences/answer_association_edit';
import Copy from 'components/copy';
import InPlaceVideoUpload from 'components/form/in_place_video_upload';
import Alert from 'components/alert';
import DeleteButton from 'components/button/delete_button';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import Button from 'components/button/button';
import { Theme, Constants } from '@seedlang/constants';
import MergeWordsEdit from "./merge_words_edit";
import InfoTooltip from "components/info_tooltip";
import SentenceSourceEdit from "pages/builder/sources/sentence_source_edit";

const TRIVIA_CONCEPTS = [
  ['5ab3e299-2cb2-494c-9d06-c36aa4470b91', 'A1 German Language Trivia - Unpublished'],
  ['e9510fb8-b181-44fe-a99c-2c1d57f962e8', 'A2 German Language Trivia - Unpublished'],
  ['e9102032-27ee-4de2-b1b1-de21b804dca3', 'B1 German Language Trivia - Unpublished'],
  ['04ebbaa4-fded-4b2d-82fa-10b897d19bc4', 'B2 German Language Trivia - Unpublished'],
  ['fe4d0f8b-894e-4ead-82f4-13bfd5528ddf', 'A1 General Knowledge Trivia - Unpublished'],
  ['9a0f9d82-5432-403f-823a-313cf353d0ee', 'A2 General Knowledge Trivia - Unpublished'],
  ['c1246e74-0e4b-421f-9f69-37f49d58209d', 'B1 General Knowledge Trivia - Unpublished'],
  ['42dadf4a-0a86-4edb-9919-f1edb5e2a661', 'B2 General Knowledge Trivia - Unpublished'],
];

const Wrapper = styled.div`
  .fa-thumbs-up {
    margin: 1px 4px 0 0;
  }
  .fieldset-info {
    position: absolute;
    top: 15px;
    right: 5px;
    i {
      color: #CCC;
    }
  }
  .sentence-edit {
    font-family: 'arial';
    .copy {
      margin: 3px 8px 0 0;
    }
    .video-clip-index {
      width: 100%;
    }
    .word-info {
      border: 1px solid #CCC;
      .icon-wrapper {
        position: initial;
      }
    }
    .question.fa-times {
      margin: 3px 0 0 3px;
    }
    .target {
      display: flex;
      .edit-target {
        flex: 1;
      }
      .fa-edit {
        width: 30px;
        margin-top: 4px;
      }
      .fa-edit.selected {
        color: green;
      }
    }
    .word-index {
      display: inline-block;
      width: 100%;
    }
    .word-help {
      top: 35px;
      position: absolute;
    }
    .translation-breakdown  {
      margin-left: -4px;
    }
    .word {
      margin: 0 0 0 5px;
      line-height: 20px;
    }
    .punctuation {
      line-height: 20px;
    }
    .modal {
      height: 600px;
    }
  }
`;

const Updated = styled.div`
  background: ${Theme.orange};
  padding: 3px 8px;
  color: #FFF;
  font-size: 12px;
`;

const AttributeRow = styled.div`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 1px;
`;

const SmartyTokens = styled.div`
  height: 600px;
  padding: 20px;
  overflow: auto;
`;

const Token = styled.div`
  margin: 10px 0;
  padding-bottom: 10px;
`;

const TokenAttribute = styled.div`
  display: flex;
  align-attributes: center;
  margin: 3px 0;
  padding-left: 5px;
  background: ${props => props.background};
`;

const TokenKey = styled.div`
  width: 100px;
`;

const TokenValue = styled.div`
  flex: 1;
`;

const TokenText = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
  background: #efefef;
  width: 100%;
`;

const Table = styled.div`
  border-top: 1px solid #CCC;
  border-left: 1px solid #CCC;
  border-right: 1px solid #CCC;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #CCC;
`;

const TableCell = styled.div`
  width: ${props => props.width};
  padding: 5px;
  line-height: ${props => props.lineHeight};
  font-size: ${props => props.fontSize};
`;

const ReviewedUser = styled.div`
  font-size: 12px;
  padding-left: 18px;
  background: #FFF;
`;

@observer
class SentenceEdit extends React.Component {
  @observable showSentenceConceptEdit = false;
  @observable showMergeWordsEdit = false;
  @observable showVocabConceptCreate = false;
  @observable showNote = false;
  @observable editTargetText = false;
  @observable showQuestionAssociation = false;
  @observable showUkIdiomaticSource = false;
  @observable showAnswerAssociation = false;
  @observable showTranslationUpdatedMessage = false;
  @observable showConcepts = false;
  @observable conceptId = null;
  @observable smartyTokens = null;
  @observable recalculating = false;
  @observable showUpdatedLiteralSource = false;
  @observable showSourcesModal = false;

  tokenisations =
    Object.freeze({
      legacy: 0,
      smartyParallel: 1,
      smarty: 2,
    })

  componentDidMount() {
    this.getConcepts();
    InterjectionStore.getIndex();
    SentenceAnswerStore.getIndex({ids: {sentenceId: this.props.routeParams.sentenceId}});
    SentenceStore.getShow({ ids: {sentenceId: this.props.routeParams.sentenceId, expanded: true }}, this.afterSentenceGetShow);
  }

  @autobind afterSentenceGetShow(resp) {
    SentenceStore.setShowData(resp);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.routeParams.sentenceId !== this.props.routeParams.sentenceId) {
      this.getConcepts();
      SentenceAnswerStore.clearIndexData();
      SentenceAnswerStore.getIndex({ids: {sentenceId: this.props.routeParams.sentenceId}});
      SentenceStore.getShow({ ids: {sentenceId: this.props.routeParams.sentenceId, expanded: true }});
    }
  }

  componentWillUnmount() {
    SentenceAnswerStore.clearIndexData();
    SentenceStore.clearShowData();
  }

  @autobind afterChange() {
    SentenceStore.reloadShow(this.afterReloadShow);
  }

  @autobind afterMergeWordsEdit() {
    this.showMergeWordsEdit = false;
    this.afterChange();
  }

  @autobind afterReloadShow(resp) {
    SentenceStore.setShowData(resp);
    this.recalculating = false;
  }

  @autobind reloadAnswers() {
    SentenceAnswerStore.clearIndexData();
    SentenceAnswerStore.getIndex({ids: {sentenceId: this.props.routeParams.sentenceId}});
  }

  @autobind afterChangeTarget() {
    this.showTranslationUpdatedMessage = true;
    this.afterChange();
  }

  @autobind onRecalculate(tokenisation) {
    this.recalculating = true;
    SentenceStore.update({ids: {
      sentenceId: this.props.routeParams.sentenceId
    }, data: {
      reviewed2: null,
      reviewed2_user_id: null,
      reviewed3: null,
      reviewed3_user_id: null,
      target_text: SentenceStore.showData.targetText,
      tokenisation: tokenisation
    }}, this.afterChange)
  }

  @autobind onCreateSource(val) {
    TranslationSourceStore.create({data: {translation_id: this.props.params.sentenceId, text: val}}, this.afterChange);
  }

  @autobind onEditSourceUkSpelling(val) {
    SentenceStore.update({data: {ukSpelling: val}}, this.afterChange);
  }

  @autobind onRemoveAnswer() {
    const answer = SentenceAnswerStore.indexData[0];
    SentenceStore.update({ids: {sentenceId: answer.id}, data: {question_id: null}}, this.afterRemoveAnswer)
  }

  @autobind afterRemoveAnswer() {
    SentenceAnswerStore.clearIndexData();
  }

  @autobind addSentenceToDeck() {
    SentenceConceptStore.create({data: {conceptId: this.conceptId, sentenceId: this.props.params.sentenceId}}, this.afterAddSentenceToDeck);
  }

  @autobind afterAddSentenceToDeck() {
    this.afterChange();
    this.getConcepts();
  }

  @autobind getConcepts() {
    SentenceConceptIndexStore.getIndex({sort: '-sentences_count', ids: {sentenceId: this.props.routeParams.sentenceId}});
  }

  @autobind getSmartyResponse() {
    SentenceStore.smartyTokens({
      data: {
        text: SentenceStore.showData.targetText,
        languageId:  SentenceStore.showData.languageId
      }
    }, this.afterGetSmartyTokens);
  }

  @autobind afterGetSmartyTokens(resp) {
    this.smartyTokens = resp;
  }

  @autobind onRemoveSmarty() {
    SentenceStore.update({ids: {sentenceId: this.props.routeParams.sentenceId}, data: {smarty_word_associations: []}}, this.afterChange)
  }

  @autobind updateLiteralSource() {
    SentenceStore.updateLiteralSource({ids: {sentenceId: this.props.routeParams.sentenceId}}, this.afterUpdateLiteralSource);
  }

  @autobind afterUpdateLiteralSource() {
    this.showUpdatedLiteralSource = true;
    setTimeout(() => this.showUpdatedLiteralSource = false, 5000);
    this.afterChange();
  }

  @autobind deleteConceptFromSentence(conceptId) {
    SentenceConceptStore.destroyByIds({data: {sentenceId: SentenceStore.showData.id, conceptId: conceptId}}, this.afterDeleteConcept);
  }

  @autobind afterDeleteConcept(resp) {
    this.getConcepts();
    this.afterChange();
  }

  hide(column) {
    return isPresent(this.props.hide) && this.props.hide.indexOf(column) !== -1;
  }

  _content() {
    if (SentenceStore.hasShowData) {
      return (
        <div className="sentence-edit">
          {
            this.smartyTokens &&
             <Modal
               onCloseModal={() => this.smartyTokens = null}
             >
               <SmartyTokens>
                  <Table>
                    {
                      this.smartyTokens.map((item, index) => {
                        return (
                          <TableRow
                            key={index}
                          >
                            <TableCell
                              width="130px"
                            >
                              {item["text"]}
                            </TableCell>
                            <TableCell
                              width="80px"
                            >
                              {item["pos"]}
                            </TableCell>
                            <TableCell
                              width="80px"
                            >
                              {item["tag"]}
                            </TableCell>
                            <TableCell
                              width="80px"
                            >
                              {item["morph"]["case"]}
                              {item["morph"]["tense"]}
                              {item["morph"]["verbForm"]}
                            </TableCell>
                            <TableCell
                              fontSize="12px"
                              lineHeight="15px"
                            >
                              {item["tagExplain"]}
                            </TableCell>
                          </TableRow>
                        )
                      })
                    }
                  </Table>
                  {
                    this.smartyTokens.map((item, index) => {
                      return (
                        <Token
                          key={index}
                        >
                          <TokenText>
                            {item['text']}
                          </TokenText>
                          {
                            keys(item).splice(0, keys(item).length - 1).sort().map((key, keyIndex) => {
                              return (
                                <TokenAttribute
                                  key={`key-${keyIndex}`}
                                >
                                  <TokenKey>
                                    {key}
                                  </TokenKey>
                                  <TokenValue>
                                    {
                                      !isObject(item[key]) && item[key]
                                    }
                                    {
                                      isObject(item[key]) && keys(item[key]).sort().map((key2, key2Index) => {
                                        return (
                                          <TokenAttribute
                                            key={key2Index}
                                            background="#f3efd6"
                                          >
                                            <TokenKey>
                                              {key2}
                                            </TokenKey>
                                            <TokenValue>
                                              {item[key][key2]}
                                            </TokenValue>
                                          </TokenAttribute>
                                        )
                                      })
                                    }
                                  </TokenValue>
                                </TokenAttribute>
                              )
                            })
                          }
                        </Token>
                      )
                    })
                  }
               </SmartyTokens>
             </Modal>
          }
          {
            this.showNote &&
              <Modal
                onCloseModal={() => this.showNote = false}
              >
                <fieldset>
                  <legend>Note</legend>
                  <InPlaceText
                    defaultValue={SentenceStore.showData.note}
                    model="sentences"
                    inputType="textarea"
                    field="note"
                    id={SentenceStore.showData.id}
                    afterChange={this.afterChange}
                  />
                </fieldset>
              </Modal>
          }
          <div className='row'>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Primary Video (with sound)</legend>
                <InPlaceVideoUpload
                  model="video_clips"
                  removeModel="target"
                  removeId={SentenceStore.showData.target?.id}
                  videoClip={SentenceStore.showData.target?.videoClip}
                  onChange={this.afterChange}
                  querystring={{
                    videoable_id: SentenceStore.showData.target?.id,
                    videoable_type: "Target",
                  }}
                />
                {
                  this.showTranslationUpdatedMessage &&
                    <Alert
                      margin="5px 0 0 0"
                    >
                      This video may need to be deleted.
                    </Alert>
                }
                {
                  SentenceStore.showData.target?.videoClip &&
                    <div>
                      <InPlaceCheckbox
                        icon="check"
                        model="targets"
                        id={SentenceStore.showData.target.id}
                        value={SentenceStore.showData.target.replaceVideo}
                        field="replace_video"
                      >
                        Replace Video
                      </InPlaceCheckbox>
                    </div>
                }
              </fieldset>
            </div>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Secondary Video (without sound)</legend>
                <InPlaceVideoUpload
                  model="video_clips"
                  removeModel="sentence"
                  removeId={SentenceStore.showData.id}
                  videoClip={SentenceStore.showData.videoClip}
                  onChange={this.afterChange}
                  querystring={{
                    videoable_id: SentenceStore.showData.id,
                    videoable_type: "Sentence",
                  }}
                />
              </fieldset>
            </div>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Trivia Correct Video</legend>
                <InPlaceVideoUpload
                  model="video_clips"
                  removeModel="sentence"
                  removeId={SentenceStore.showData.id}
                  videoClip={SentenceStore.showData.correctVideoClip}
                  onChange={this.afterChange}
                  querystring={{
                    videoable_id: SentenceStore.showData.id,
                    videoable_type: "Sentence",
                    foreign_key: "correct_video_clip_id",
                  }}
                />
              </fieldset>
            </div>
            <div className='col-xs-2'>
              <fieldset>
                <legend>Trivia Incorrect Video</legend>
                <InPlaceVideoUpload
                  model="video_clips"
                  removeModel="sentence"
                  removeId={SentenceStore.showData.id}
                  videoClip={SentenceStore.showData.incorrectVideoClip}
                  onChange={this.afterChange}
                  querystring={{
                    videoable_id: SentenceStore.showData.id,
                    videoable_type: "Sentence",
                    foreign_key: "incorrect_video_clip_id",
                  }}
                />
              </fieldset>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-9">
              <fieldset className='target-fieldset'>
                <legend>Target</legend>
                <div className='target'>
                  <div className='edit-target'>
                    {
                      !this.editTargetText &&
                        <SentenceBreakdown
                          fontSize="18px"
                          showCase
                          showUnderline
                          sentence={SentenceStore.showData}
                        />
                    }
                    {
                      this.editTargetText &&
                        <InPlaceText
                          defaultValue={SentenceStore.showData.targetText}
                          model="sentences"
                          field="target_text"
                          id={SentenceStore.showData.id}
                          afterChange={this.afterChangeTarget}
                          editable
                          warning="You will need to fix cards containing this sentence in decks if you edit the target."
                        />
                    }
                  </div>
                  <span
                    data-for="copy"
                    data-tip
                  >
                    <Copy
                      text={SentenceStore.showData.targetText}
                    />
                    <ReactTooltip
                      place="top"
                      type="dark"
                      effect="solid"
                      id="copy"
                      className="custom-tooltip"
                    >
                      Copy target text
                    </ReactTooltip>
                  </span>
                  <span
                    data-for="edit"
                    data-tip
                  >
                    <i
                      style={{marginLeft: 10, marginTop: 3}}
                      className={cx('fa', 'fa-edit', 'fa-on-click', {
                        selected: this.editTargetText
                      })}
                      onClick={() => this.editTargetText = !this.editTargetText}
                    />
                    <ReactTooltip
                      place="top"
                      type="dark"
                      effect="solid"
                      id="edit"
                      className="custom-tooltip"
                    >
                      Edit target text
                    </ReactTooltip>
                  </span>
                </div>
              </fieldset>
            </div>
            <div className='col-xs-3'>
              <fieldset className='target-fieldset'>
                <legend>Language</legend>
                <InPlaceSelect
                  options={Constants.LANGUAGE_SELECT_OPTIONS}
                  id={SentenceStore.showData.id}
                  value={SentenceStore.showData.languageId}
                  model="sentences"
                  field="language_id"
                />
              </fieldset>
            </div>
          </div>
          <div className="row">
            <div className={SentenceStore.showData.literalSourceUk ? "col-xs-6" : "col-xs-12"}>
              <fieldset style={{position: 'relative'}}>
                <legend>Literal Source</legend>
                {SentenceStore.showData.literalSource}
                {
                  !this.showUpdatedLiteralSource &&
                    <span
                      data-for="update-literal-source"
                      data-tip
                      style={{position: 'absolute', right: 10}}
                    >
                      <i
                        style={{color: "#333"}}
                        className="fa fa-refresh fa-on-click"
                        onClick={this.updateLiteralSource}
                      />
                      <ReactTooltip
                        place="top"
                        type="dark"
                        effect="solid"
                        id="update-literal-source"
                        className="custom-tooltip"
                      >
                        Update Literal Source
                      </ReactTooltip>
                    </span>
                }
                {
                  this.showUpdatedLiteralSource &&
                    <Updated>
                      Updated!
                    </Updated>
                }
              </fieldset>
            </div>
            {
              SentenceStore.showData.literalSourceUk &&
              <div className="col-xs-6">
              <fieldset style={{position: 'relative'}}>
                <legend>British Literal Source</legend>
                {SentenceStore.showData.literalSourceUk}
                <div className='fieldset-info'>
                  <span
                    data-for="british-literal-source"
                    data-tip
                  >
                    <i className='fa fa-info-circle' />
                    <ReactTooltip
                      place="left"
                      type="dark"
                      effect="solid"
                      id="british-literal-source"
                      className="custom-tooltip"
                    >
                      <div>The literal source displayed to users who chose British English.</div>
                      <div>It can be modified by editing the words' sources and updating the literal source.</div>
                    </ReactTooltip>
                  </span>
                </div>
              </fieldset>
            </div>
            }
          </div>
          <div className="row">
            <div className="col-xs-9">
              <fieldset style={{position: 'relative'}}>
                <legend>
                  Idiomatic Source
                  {
                    isBlank(SentenceStore.showData.sourceText) &&
                      <i className='fa fa-warning' />
                  }
                </legend>
                <InPlaceText
                  defaultValue={SentenceStore.showData.sourceText}
                  model={SentenceStore.showData.sourceText ? "sources" : null}
                  field="text"
                  onSubmit={SentenceStore.showData.sourceText ? null : this.onCreateSource}
                  afterChange={this.afterChange}
                  id={SentenceStore.showData.defaultSource && SentenceStore.showData.defaultSource.id}
                />
                <div className='fieldset-info'>
                  <span
                    data-for="idiomatic-source"
                    data-tip
                  >
                    <i className='fa fa-info-circle' />
                    <ReactTooltip
                      place="left"
                      type="dark"
                      effect="solid"
                      id="idiomatic-source"
                      className="custom-tooltip"
                    >
                      <div>The default source that is displayed on the site.</div>
                      <div>It should be a blend between purely idiomatic English and a literal translation,</div>
                      <div>because students will often be prompted to translate from English to German.</div>
                    </ReactTooltip>
                  </span>
                </div>
              </fieldset>
            </div>
            {
              this.showTranslationUpdatedMessage &&
                <Alert
                  width="100%"
                  margin="5px 0 0 0"
                >
                  This source may need to be updated.
                </Alert>
            }
            <div className="col-xs-3">
              <fieldset>
                <legend>Other Sources</legend>
                <div>
                  <span
                    onClick={() => this.showSourcesModal = true}
                    className='underline'
                    style={{fontSize: "14px"}}
                  >
                    {`View & Edit ${SentenceStore.showData.sourcesCount-1} Other Source${SentenceStore.showData.sourcesCount-1 > 1 ? 's' : ''}`}
                  </span>
                </div>
              </fieldset>
            </div>

          </div>
          <div className="row">
            <div className="col-xs-2">
              <fieldset style={{position: 'relative'}}>
                <legend>Approved</legend>
                  <div
                    className='inline-block'
                    style={{fontSize: "14px"}}
                  >
                    {`Created on ${SentenceStore.showData?.createdAt?.formattedDateWithYear}${SentenceStore.hasShowData && isPresent(SentenceStore.showData.createdByUser?.name) ? " by " + SentenceStore.showData.createdByUser.name : ''}${SentenceStore.showData.sentenceSubmissionsCount > 0 ? ' from a submission' : ''}`}
                  </div>
                  <div>
                    {
                      !SentenceStore.showData.validSetup &&
                        <div
                          className='inline-block'
                          style={{fontSize: "14px"}}
                        >
                          <i className="fa fa-on-click fa-thumbs-up disabled" />
                          Didactics 1 (Disabled until valid)
                        </div>
                    }
                    {
                      SentenceStore.showData.validSetup &&
                        <InPlaceCheckbox
                          model="sentences"
                          field="reviewed2"
                          value={SentenceStore.showData.reviewed2}
                          id={SentenceStore.showData.id}
                          icon="thumbs-up"
                          afterChange={this.afterChange}
                        >
                          Didactics 1
                        </InPlaceCheckbox>
                    }
                  </div>
                  {
                    isPresent(SentenceStore.showData.reviewed2User) &&
                      <ReviewedUser>
                        {SentenceStore.showData.reviewed2User.name}
                      </ReviewedUser>
                  }
                  <div>
                    <InPlaceCheckbox
                      model="sentences"
                      field="reviewed3"
                      value={SentenceStore.showData.reviewed3}
                      id={SentenceStore.showData.id}
                      icon="thumbs-up"
                      afterChange={this.afterChange}
                    >
                      Didactics 2
                    </InPlaceCheckbox>
                  </div>
                  {
                    isPresent(SentenceStore.showData.reviewed3User) &&
                      <ReviewedUser>
                        {SentenceStore.showData.reviewed3User.name}
                      </ReviewedUser>
                  }
                  <div>
                    <InPlaceCheckbox
                      model="sentences"
                      field="reviewed1"
                      value={SentenceStore.showData.reviewed1}
                      id={SentenceStore.showData.id}
                      icon="thumbs-up"
                      afterChange={this.afterChange}
                    >
                      English
                    </InPlaceCheckbox>
                  </div>
                  {
                    isPresent(SentenceStore.showData.reviewed1User) &&
                      <ReviewedUser>
                        {SentenceStore.showData.reviewed1User.name}
                      </ReviewedUser>
                  }
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <InPlaceCheckbox
                      model="sentences"
                      field="public"
                      value={SentenceStore.showData.public}
                      id={SentenceStore.showData.id}
                      icon="check"
                      afterChange={this.afterChange}
                    >
                      Public in Vocab
                    </InPlaceCheckbox>
                    {
                      !SentenceStore.showData.public &&
                        <i className='fa fa-warning' />
                    }
                  </div>
              </fieldset>
            </div>
            <div className="col-xs-2">
              <fieldset style={{position: 'relative'}}>
                <legend>Settings</legend>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <InPlaceCheckbox
                    model="sentences"
                    field="is_trivia_sentence"
                    value={SentenceStore.showData.isTriviaSentence}
                    id={SentenceStore.showData.id}
                    icon="check"
                    afterChange={this.afterChange}
                  >
                    Trivia
                  </InPlaceCheckbox>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <InPlaceCheckbox
                    model="sentences"
                    field="is_question"
                    value={SentenceStore.showData.isQuestion}
                    id={SentenceStore.showData.id}
                    icon="check"
                    afterChange={this.afterChange}
                  >
                    Is Question
                  </InPlaceCheckbox>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <InPlaceCheckbox
                    model="sentences"
                    field="has_external_content"
                    value={SentenceStore.showData.hasExternalContent}
                    id={SentenceStore.showData.id}
                    icon="check"
                    afterChange={this.afterChange}
                  >
                    External Content
                  </InPlaceCheckbox>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <InPlaceCheckbox
                    model="sentences"
                    field="use_in_feed_entry"
                    value={SentenceStore.showData.useInFeedEntry}
                    id={SentenceStore.showData.id}
                    icon="check"
                    afterChange={this.afterChange}
                  >
                    Use In Feed Entry
                  </InPlaceCheckbox>
                </div>
              </fieldset>
            </div>
            <div className="col-xs-2">
              <fieldset style={{position: 'relative'}}>
                <legend>Attributes</legend>
                <AttributeRow>
                  Level: {SentenceStore.showData.levelAbbreviation} <InfoTooltip>This takes into account the levels of words and concepts.</InfoTooltip>
                </AttributeRow>
                <AttributeRow>
                  <span>
                    Valid: {SentenceStore.showData.validSetup ? 'Yes' : 'No'}
                  </span>
                  {
                    !SentenceStore.showData.validSetup &&
                      <i className='fa fa-warning' />
                  }
                </AttributeRow>
                <AttributeRow>
                  Has Deck: {SentenceStore.showData.hasDeck ? 'Yes' : 'No'}
                </AttributeRow>
              </fieldset>
            </div>
            <div className="col-xs-3">
              <fieldset style={{position: 'relative'}}>
                <legend>Interjection Card</legend>
                <InPlaceSelect
                  options={InterjectionStore.indexData}
                  optionName="name"
                  model="sentences"
                  field="interjection_id"
                  id={SentenceStore.showData.id}
                  value={SentenceStore.showData.interjectionId}
                  includeBlank="true"
                  afterChange={this.afterChange}
                />
                <InPlaceSelect
                  includeBlank
                  options={['web', 'ios', 'android']}
                  model="sentences"
                  field="show_on_device"
                  id={SentenceStore.showData.id}
                  value={SentenceStore.showData.showOnDevice}
                  afterChange={this.afterChange}
                  margin="5px 0 0 0"
                />
              </fieldset>
            </div>
            {
              SentenceStore.showData.forumThreadId &&
              <div className='col-xs-3'>
                <fieldset style={{position: 'relative'}}>
                  <legend>Links</legend>
                  <a
                    className='underline'
                    style={{fontSize: 14}}
                    href={`https://www.seedlang.com/discussions/${Constants.VOCAB_FORUM_IDS[SentenceStore.showData.languageId]}/threads/${SentenceStore.showData.forumThreadId}`}
                    target='_blank'
                    rel="noopener noreferrer"
                  >
                    Forum
                  </a>
                </fieldset>
              </div>
            }
          </div>
          {
            !SentenceStore.showData.isQuestion &&
              <div className="row">
                <div className="col-xs-12">
                  <fieldset style={{marginBottom: "10px"}}>
                    <legend>Question</legend>
                    {
                      SentenceStore.showData.hasQuestion &&
                        <Link
                          to={{name: 'builder.sentences.edit', params: {sentenceId: SentenceStore.showData.question.id }}}
                        >
                          {SentenceStore.showData.question.targetText}
                        </Link>
                    }
                    {
                      SentenceStore.showData.hasQuestion &&
                        <i className='fa fa-times fa-on-click question'
                          onClick={() => SentenceStore.update({ids: {sentenceId: this.props.routeParams.sentenceId}, data: {question_id: ''}})}
                        />
                    }
                    {
                      !SentenceStore.showData.hasQuestion &&
                        <div
                          onClick={() => this.showQuestionAssociation = true}
                          className='underline'
                          style={{fontSize: "14px"}}
                        >
                          <i className='fa fa-plus' /> Add Question
                        </div>
                    }
                  </fieldset>
                </div>
              </div>
          }
          {
            SentenceStore.showData.isQuestion &&
              <fieldset>
                <legend>Sentence Answer</legend>
                {
                  !SentenceAnswerStore.hasIndexData &&
                    <div
                      onClick={() => this.showAnswerAssociation = true}
                      className='underline'
                    >
                      <i className='fa fa-plus' /> Add Answer
                    </div>
                }
                <table className='table-wrapper'>
                  <tbody>
                    {
                      SentenceAnswerStore.indexData.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <Link
                                to={{ name: 'builder.sentences.edit', params: { sentenceId: item.id } }}
                                className="on-click"
                              >
                                <i className="fa fa-chevron-right fa-block" />
                              </Link>
                            </td>
                            <td>
                              {item.targetText}
                            </td>
                            <td>
                              <DeleteButton
                                onConfirm={this.onRemoveAnswer}
                                message="Delete this answer?"
                                className="fa-block right"
                              />
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </fieldset>
          }
          {
            SentenceStore.showData.isTriviaSentence && SentenceStore.showData.isQuestion &&
              <fieldset>
                <legend>Trivia Answers</legend>
                <table className="table-wrapper">
                  <thead>
                    <tr>
                      <th width="25%">Correct</th>
                      <th width="25%">Wrong 1</th>
                      <th width="25%">Wrong 2</th>
                      <th width="25%">Wrong 3</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {
                        orderBy(SentenceStore.showData.multipleChoiceOptions, ['correct']).reverse().map(item => {
                          return (
                            <td
                              key={item.id}
                            >
                              <InPlaceText
                                defaultValue={item.targetText}
                                model="multiple_choice_option"
                                field="target_text"
                                id={item.id}
                              />
                            </td>
                          )
                        })
                      }
                    </tr>
                  </tbody>
                </table>
              </fieldset>
          }
          {
            SentenceStore.showData.isTriviaSentence && SentenceStore.showData.isQuestion && !SentenceStore.showData.hasDeck &&
              <fieldset>
                <legend>Add Trivia Question to Deck</legend>
                <div className='row'>
                  <div className='col-xs-4'>
                    <InPlaceSelect
                      includeBlank
                      onChange={(id) => this.conceptId = id}
                      options={TRIVIA_CONCEPTS}
                      value={this.conceptId}
                    />
                  </div>
                  <div className='col-cs-6'>
                    <Button
                      margin="0 0 0 10px"
                      onClick={this.addSentenceToDeck}
                    >
                      Add to Deck
                    </Button>
                  </div>
                </div>
              </fieldset>

          }
          {
            AuthUserStore.showData.admin &&
              <fieldset>
                <legend>Words</legend>
                <WordIndex
                  sentence={SentenceStore.showData}
                  scopedToSentence="true"
                  hideCase={SentenceStore.showData.languageId !== 'DE'}
                  wordAssociations={SentenceStore.showData.wordAssociations}
                  smartyWordAssociations={SentenceStore.showData.smartyWordAssociations}
                  words={SentenceStore.showData.enrichedWords}
                />
              </fieldset>
          }
          <div className='buttons' style={{marginBottom: "10px"}}>
            <button
              className='gray-button'
              style={{marginTop: 10, marginRight: 10}}
              onClick={() => this.onRecalculate(this.tokenisations.legacy)}
            >
              <i className='fa fa-refresh' />
              Recalculate Sentence
            </button>
            {
              !this.hide('concepts') &&
                <button
                  className='gray-button'
                  style={{marginTop: 10, marginRight: 10}}
                  onClick={() => this.showSentenceConceptEdit = true}
                >
                  <i className='fa fa-plus' />
                  Edit Sentence Concepts
                </button>
            }
            {
              <button
                className='gray-button'
                style={{marginTop: 10}}
                onClick={() => this.showMergeWordsEdit = true}
              >
                <i className='fa fa-plus' />
                Merge words
              </button>
            }
          </div>
          {
            this.showQuestionAssociation &&
              <Modal
                onCloseModal={() => this.showQuestionAssociation = false}
                height="300px"
              >
                <QuestionAssociationEdit
                  sentence={SentenceStore.showData}
                  afterChange={this.afterChange}
                />
              </Modal>
          }
          {
            this.showAnswerAssociation &&
              <Modal
                onCloseModal={() => this.showAnswerAssociation = false}
                height="600px"
              >
                <AnswerAssociationEdit
                  sentence={SentenceStore.showData}
                  afterChange={this.reloadAnswers}
                />
              </Modal>
          }
          {
            this.showSentenceConceptEdit &&
              <Modal
                onCloseModal={() => this.showSentenceConceptEdit = false}
              >
                <SentenceConceptEdit
                  concepts={SentenceConceptIndexStore.indexData}
                  languageId={SentenceStore.showData.languageId}
                  sentenceId={SentenceStore.showData.id}
                  afterChange={this.afterChange}
                />
              </Modal>
          }
          {
            this.showMergeWordsEdit &&
            <Modal
              onCloseModal={() => this.showMergeWordsEdit = false}
              width="400px"
            >
              <MergeWordsEdit
                words={SentenceStore.showData.enrichedWords}
                sentence={SentenceStore.showData}
                afterChange={this.afterMergeWordsEdit}
              />
            </Modal>
          }
          {
            this.showVocabConceptCreate &&
              <Modal
                onCloseModal={() => this.showVocabConceptCreate = false}
              >
                <VocabConceptCreate
                  afterChange={this.afterChange}
                />
              </Modal>
          }
          <fieldset>
            <legend>Concepts <InfoTooltip position='right'>Some concepts are automatically added with rules.</InfoTooltip></legend>
            {
              this.showTranslationUpdatedMessage &&
                <Alert
                  width="100%"
                  margin="5px 0 0 0"
                >
                  Please check if this sentence appears in decks and fix the cards missing underlines.
                </Alert>
            }
            <ConceptIndex
              concepts={SentenceConceptIndexStore.indexData}
              sentenceId={this.props.routeParams.sentenceId}
              store={SentenceConceptIndexStore}
              scopedToSentence
              deleteMessage="Remove from this sentence?"
              onDelete={this.deleteConceptFromSentence}
              hideFilter
              hideCreateButton
            />
          </fieldset>
        </div>
      );
    }
    return <span />;
  }

  render() {
    return (
      <Wrapper>
        {
          SentenceStore.hasShowData && this.showSourcesModal &&
          <Modal
            onCloseModal={() => this.showSourcesModal = false}
          >
            <SentenceSourceEdit
              sentence={SentenceStore.showData}
              onChange={this.afterChange}
            />
          </Modal>
        }
        {this._content()}
      </Wrapper>
    )
  }
}

export default SentenceEdit;
