import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import InPlaceTextInput from 'components/form/in_place_text_input';
import autobind from 'autobind-decorator';
import SpecialCharacterButtons from 'components/special_character_buttons';
import Text from 'components/text';
import { pixify } from '@seedlang/utils';
import { Theme } from '@seedlang/constants';
import Uuid from 'uuid/v4';

const Wrapper = styled.div`
  width: 100%;
  padding: ${(props) => props.padding || "10px 20px"};
  input, .false_input {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 73px;
    width: 100%;
    padding: 20px;
    font-size: ${(props) => props.fontSize};
    text-align: center;
    border-radius: 20px;
    background: ${(props) => props.background ? props.background : '#FFF'};
    color: ${(props) => props.background ? '#FFF' : '#000'};
    height: 73px;
  }
  .diff {
    text-decoration: underline;
    text-underline-offset: 6px;
  }
  .diff, .no-diff {
    white-space: pre;
  }
`;


const CharacterWrapper = styled.div`
  margin-top: 10px;
  display: ${props => props.display};
`;

@observer
class WordEntry extends React.Component {
  @observable caretPosition;
  @observable overflow = false;

  constructor(props) {
    super(props);
    this.id = Uuid();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.detectOverflow();
    }
  }

  @computed get background() {
    if (this.props.correct) {
      return Theme.green;
    } else if (this.props.incorrect) {
      return Theme.red;
    }
    return null;
  }

  @computed get fontSize() {
    return this.overflow ? 18 : 26;
  }

  @computed get disabled() {
    return this.props.disabled || this.props.correct || this.props.incorrect;
  }

  @autobind insertText(text) {
    if (this.disabled) { return null }
    const el = document.getElementById(this.id);
    if (el) {
      const caretPosition = el.selectionStart;
      const newValue = [this.props.value.slice(0, caretPosition), text, this.props.value.slice(caretPosition)].join('');
      this.onChange(newValue, caretPosition + 1);
    }
  }

  @autobind onChange(val, caretPosition) {
    this.detectOverflow();
    this.caretPosition = caretPosition;
    this.props.onChange(val);
  }

  @autobind detectOverflow() {
    const input = document.getElementById(this.id);
    const c = document.createElement("canvas");
    let ctx = c.getContext("2d");
    ctx.font = '26px "Gotham Rounded SSm A", "Gotham Rounded SSm B", "arial"';
    const textWidth = ctx.measureText(input.value).width;
    this.overflow = textWidth > (input.clientWidth - 40);
  }

  @autobind prependText(text) {
    if (this.disabled) { return null }
    this.onChange(text + ' ' + this.props.value);
    document.getElementById(this.id).focus();
  }

  @autobind onKeyDown(e) {
    if (e.keyCode === 13 && e.shiftKey === false) {
     this.props.onPressEnter(e.currentTarget.value);
    }
  }

  render() {
    return (
      <Wrapper
        padding={this.props.padding}
        background={this.background}
        fontSize={pixify(this.fontSize)}
      >
        <Text
          center
          fontStyle="italic"
          color="#565656"
          fontSize="14px"
          style={{display: this.props.revealed ? 'none' : null}}
        >
          {this.props.message}
        </Text>
        {
          !this.props.revealed &&
            <InPlaceTextInput
              blurOnNumber={!this.props.numberEntry}
              caretPosition={this.caretPosition}
              focus={this.props.focus}
              value={this.props.correct ? this.props.target : this.props.value}
              onKeyDown={this.onKeyDown}
              onChange={this.onChange}
              id={this.id}
              disabled={this.disabled}
            />
        }
        {
          this.props.revealed &&
          <div className='false_input'>{this.props.revealedValue || this.props.value}</div>
        }
        <CharacterWrapper
          display={(this.props.revealed || this.props.numberEntry) ? 'none' : null}
        >
          <SpecialCharacterButtons
            center
            disabled={this.disabled}
            onClick={this.insertText}
          />
        </CharacterWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WordEntry);
