import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import PaymentCreate from 'pages/builder/payments/payment_create';
import { PaymentStore } from '@seedlang/stores';
import { Link } from 'react-router';
import DeleteButton from 'components/button/delete_button';
import autobind from 'autobind-decorator';
import Paginator from 'components/paginator';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { isPresent } from '@seedlang/utils';
import Filter from 'components/backend_filter/filter';

@observer
class PaymentIndex extends React.Component {

  componentWillUnmount() {
    PaymentStore.clearFilter();
  }

  @computed get payments() {
    return this.props.subscriptionId ? this.props.payments || [] : PaymentStore.indexData;
  }

  componentDidMount() {
    this.loadPayments();
  }

  @autobind onDelete(id) {
    PaymentStore.destroy({ids: {paymentId: id}}, this.afterUpdatePayment);
  }

  @autobind loadPayments() {
    if (!this.props.subscriptionId) {
      PaymentStore.getIndex();
    }
  }

  @autobind afterUpdatePayment() {
    if (this.props.afterUpdatePayment) {
      this.props.afterUpdatePayment();
    }
    this.loadPayments();
  }

  render() {
    return (
      <div className='payment-index'>
        {
          this.props.subscriptionId &&
            <PaymentCreate
              subscriptionId={this.props.subscriptionId}
              afterUpdatePayment={this.afterUpdatePayment}
            />
        }
        {
          !this.props.subscriptionId &&
            <Filter
                store={PaymentStore}
                defaultSort="-occurred_at"
                namespace="payment-backend"
                queryStrings={{include_count: true}}
                filters={
                  [
                    {
                      label: 'Occurred At',
                      type: 'date',
                      name: 'occurred_at',
                    },
                  ]
                }
              />
        }
        <table
          className="table-wrapper"
          style={{marginTop: 10}}
        >
          <thead>
            <tr>
              <th />
              <th>Date</th>
              {
                !this.props.subscriptionId &&
                  <th>User</th>
              }
              <th>Amount</th>
              <th>Currency</th>
              <th>Promotion</th>
              <th>Refunded</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {
              this.payments.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: this.props.subscriptionId ? 'builder.subscriptions.payments.edit' : 'builder.payments.edit', params: { subscriptionId: this.props.subscriptionId, paymentId: item.id } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>
                      {item.occurredAt.formattedDateWithYear}
                    </td>
                    {
                      !this.props.subscriptionId &&
                        <td>
                          {
                            item.user &&
                              <Link
                                to={{name: 'builder.users.edit', params: {userId: item.user.id}}}
                              >
                                {item.user.name}
                              </Link>
                          }
                        </td>
                    }
                    <td>
                      {item.amountFormatted}
                    </td>
                    <td>
                      {item.currency}
                    </td>
                    <td>
                      {isPresent(item.promotion) ? item.promotion.name : null}
                    </td>
                    <td>
                      <InPlaceCheckbox
                        model="payments"
                        id={item.id}
                        field="refunded"
                        value={item.refunded}
                        icon="check"
                        afterChange={this.afterUpdatePayment}
                      />
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={this.onDelete.bind(this, item.id)}
                        message="Delete this payment?"
                        className="fa-block right"
                      />
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Paginator
          store={this.props.store || PaymentStore}
        />
      </div>
    );
  }
}

export default ErrorBoundary(PaymentIndex);
