import React from 'react';
import { observer } from 'mobx-react';
import { SessionStore } from '@seedlang/stores';
import { UserStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import { Link } from 'react-router';

@observer
class SessionIndex extends React.Component {

  componentDidMount() {
    if (!UserStore.hasShowData || UserStore.showData.id !== this.props.userId) {
      UserStore.getShow({ ids: {userId: this.props.userId }});
    }
    SessionStore.getIndex({ ids: { userId: this.props.userId } });
  }

  componentWillUnmount() {
    SessionStore.clearIndexData();
  }

  render() {
    return (
      <div className="session-index">
        <table className="table-wrapper">
          <thead>
            <tr>
              <th></th>
              <th>
                Started
              </th>
              <th>
                Ended
              </th>
              <th>
                Events
              </th>
            </tr>
          </thead>
          <tbody>
            {
              SessionStore.indexData.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.events.index', params: { sessionId: item.id, userId: this.props.userId } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>
                      <div className="text">
                        {item.startedAt.formatted}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.endedAt.formatted}
                      </div>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={SessionStore}
        />
      </div>
    );
  }
}

export default SessionIndex;
