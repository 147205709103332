import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import MembershipOptions from 'components/membership/membership_options';

@observer
class SettingsMemberships extends React.Component {

  render() {
    return (
      <div className='settings-memberships'>
        <MembershipOptions />
      </div>
    );
  }
}

export default ErrorBoundary(SettingsMemberships);
