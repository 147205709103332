import React from 'react';
import { observer } from 'mobx-react';
import { PromotionStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import PromotionCreate from 'pages/builder/promotions/promotion_create';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import { AppUI } from "@seedlang/state";
import Copy from "components/copy";

@observer
class PromotionIndex extends React.Component {

  constructor(props) {
    super(props);
    PromotionStore.getIndex();
  }

  @autobind onDelete(id) {
    PromotionStore.destroy({ids: {promotionId: id}});
  }

  render() {
    return (
      <div className='promotion-index'>
        <PromotionCreate />
        <table className="table-wrapper admin-table">
          <thead>
            <tr>
              <th width="20" />
              <th>Created</th>
              <th>Name</th>
              <th>Slug</th>
              <th>Link</th>
              <th># Visited</th>
              <th># Signed Up</th>
              <th>Conversions</th>
              <th>Conversion %</th>
              <th width="40"></th>
            </tr>
          </thead>
          <tbody>
            {
              PromotionStore.indexData.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.promotions.edit', params: { promotionId: item.id } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>
                      <div className="text">
                        {item.createdAt.formatted}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.name}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.slug}
                      </div>
                    </td>
                    <td>
                      <Copy
                        className='outline-button blue'
                        text={item.link}
                      />
                    </td>
                    <td>
                      <div className="text">
                        {item.uniqueVisitorsCount}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.signedUpWithCount}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.conversions}
                      </div>
                    </td>
                    <td>
                      <div className="text">
                        {item.conversionPercentage.toFixed(2)}%
                      </div>
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message="Delete this promotion?"
                        className="fa-block right"
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={PromotionStore}
        />
      </div>
    );
  }
}

export default PromotionIndex;
