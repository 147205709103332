import React from 'react';
import { observer } from 'mobx-react';
import { AppUI } from '@seedlang/state';
import FeedEntry from 'components/feed/feed_entry';
import Paginator from 'components/paginator';
import { FeedEntryIndexWrapper } from '@seedlang/hoc';
import PageOnboarding from 'components/onboarding/page_onboarding';
import styled from '@emotion/styled';
import Alert from 'components/alert';

const Wrapper = styled.div`
  width: 100%;
  margin: ${(props) => props.margin};
`;

@observer
class FeedEntryIndex extends React.Component {

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        {
          AppUI.userIsLoaded && AppUI.user.xpLevel < 2 && !AppUI.wasUiElementSeen('feed_index_page') &&
            <PageOnboarding
              uiElement="feed_index_page"
            >
              Your feed is a collection of your answered questions or descriptions of scenes within our story decks.
            </PageOnboarding>
        }
        {
          !AppUI.feedEntryStore.hasIndexData && AppUI.feedEntryStore.initialRequestReceived &&
            <Alert
              width="100%"
              textAlign="center"
            >
              There aren't any entries.
            </Alert>
        }
        {
          AppUI.feedEntryStore.indexData.map((item) => {
            return (
              <FeedEntry
                afterChange={this.props.afterChange}
                user={AppUI.user}
                key={item.id}
                feedEntry={item}
                width={AppUI.layout.feedVideoWidth}
                feedMinimizedSupplementWidth={AppUI.layout.feedMinimizedSupplementWidth}
                onDestroyFeedEntry={this.props.onDestroyFeedEntry}
              />
            )
          })
        }
        <Paginator
          store={AppUI.feedEntryStore}
        />
      </Wrapper>
    );
  }
}

export default FeedEntryIndexWrapper(FeedEntryIndex);
