import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import PieChartWithLegend from 'components/visualization/pie_chart_with_legend';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  margin: 0 10px 10px 0;
  border-radius: 10px;
  background: whitesmoke;
  padding: 10px;
  width: 360px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
`;

@observer
class DashboardPieChart extends React.Component {

  @computed get colors() {
    return [Theme.green, "#969696"];
  }

  @computed get piechartData(){
    return this.props.countData.data.map((item) => {
      return {y: item[1]};
    });
  }

  @computed get legendData() {
    return this.props.countData.data.map((item) => {
      return {y: item[1]};
    });
  }

  @computed get primaryCount() {
    return this.props.countData.data[1][1];
  }

  @computed get legend() {
    return this.props.countData.data.map((item, index) => {
      return [this.colors[index], item[0]];
    })
  }

  @computed get tooltipText() {
    return this.props.countData.data.map((item) => {
      return `${item[0]}: ${item[1]}`
    }).join(" ")
  }

  @computed get percentage() {
    return '15%';
  }

  render() {
    return (
      <Wrapper>
        <Title>
          {this.props.countData.name}
        </Title>
        <PieChartWithLegend 
          data={this.piechartData}
          id={`pie-chart-${this.props.countData.slug}-${this.props.languageId}`}
          colors={this.colors}
          fontSize="30px"
          count={this.primaryCount}
          width={160}
          legends={this.legend}
          tooltipText={this.tooltipText}
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(DashboardPieChart);
