import React from 'react';
import { observer } from 'mobx-react';
import FaqItem from 'pages/main/faq_item';
import styled from '@emotion/styled';
import { FaqWrapper } from '@seedlang/hoc';

const Wrapper = styled.div`
  h3 {
    text-align: left;
    margin-left: 10px;
  }
`;

@observer
class Faq extends React.Component {

  render() {
    return (
      <Wrapper>
        <h3>FAQ</h3>
        {
          this.props.questions.map((item, index) => {
            return (
              <FaqItem
                key={index}
                {...item}
              />
            );
          })
        }
      </Wrapper>
    );
  }
}

export default FaqWrapper(Faq);
