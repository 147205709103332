import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { CheckWithCircleIcon } from '@seedlang/icons';
import { AppUI } from '@seedlang/state';
import { isBlank, isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import Button from 'components/button/button';
import Spinner from 'components/spinner';
import Text from 'components/text';
import { last } from 'lodash';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import pluralize from 'pluralize';
import React from 'react';
import { Link } from 'react-router';
import { CancellationReasonStore } from '@seedlang/stores';

const Wrapper = styled.div`
  .fa-angle-left {
    margin: 0 4px 0 0;
    font-size: 20px;
  }
`;

const Option = styled.div`
  background: ${(props) => props.selected ? 'whitesmoke' : '#FFF'};
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid whitesmoke;
  cursor: pointer;
  .check-with-circle-icon {
    margin-right: 10px;
    svg {
      fill: ${(props) => props.selected ? Theme.green : '#CCC'};
    }
  }
  &:hover {
    background: whitesmoke;
  }
`;

const Message = styled.div`
  textarea {
    height: ${(props) => props.expanded ? 100 : 40}px;
  }
`;

const CancelButtonGroup = styled.div`
  margin-top: 20px;
  display: grid;
  max-width: 15em;
`

const BackButton = ({ to, children, ...props }) => (
  <Link
    to={to}
    style={{ display: 'flex', alignItems: 'center' }}
    {...props}
  >
    <i className='fa fa-angle-left' />
    {children}
  </Link>
);

@observer
class SettingsCancelWhitelabel extends React.Component {
  @observable isProratedRefundSelected = false;
  @observable step = 'initial';
  @observable selectedReason;
  @observable cancellationMessage = "";
  @observable showSpinner = false;
  @observable expandedReasonTextArea = false;

  @computed get subscription() {
    if (!AppUI.userSubscriptionStore.hasIndexData) { return {} }
    return last(AppUI.userSubscriptionStore.indexData);
  }

  constructor(props) {
    super(props);
    this.init();
  }

  async init() {
    await this.loadData();
  }

  async loadData() {
    if (AppUI.userSubscriptionStore.hasIndexData) return;

    this.showSpinner = true;
    try {
      await AppUI.userSubscriptionStore.getIndexPromise({ ids: { user_id: AppUI.user.id } });
    }
    finally {
      this.showSpinner = false;
    }
  }

  get isProratedRefundAvailable() {
    return !AppUI.siteIsDefault || this.subscription?.proratedRefundPolicy?.isAvailable;
  }

  @autobind async submitCancellation({ proratedRefund }) {
    this.isProratedRefundSelected = proratedRefund;

    await this.withSpinner(async () => {
      const data = {
        cancel_at_period_end: true,
        prorated_refund: proratedRefund,
      }
      await AppUI.subscriptionStore.updatePromise({
        data,
        ids: { subscriptionId: this.subscription.id }
      });
    })

    this.step = "reason";
  }

  @autobind async submitReason() {
    if (isPresent(this.selectedReason)) {
      await this.withSpinner(async () => {
        const data = {
          cancellation_reason: this.selectedReason.slug,
          cancellation_message: this.cancellationMessage,
        }
        await AppUI.subscriptionStore.updatePromise({
          data,
          ids: { subscriptionId: this.subscription.id }
        });
      });

      this.selectedReason = null;
      AppUI.routeStore.routeToNamed('settings.billing');
    }
  }

  @autobind onSetSelected(selected) {
    this.selectedReason = selected;
  }

  @autobind expandReasonTextArea() {
    this.expandedReasonTextArea = true;
  }


  async withSpinner(callback) {
    this.showSpinner = true;
    try {
      await callback();
    }
    finally {
      this.showSpinner = false;
    }
  }

  render() {
    if (this.subscription) {
      return (
        <Wrapper>

          {
            this.showSpinner ? (
              <Spinner
                className={AppUI.siteIsDefault ? "blue" : null}
                background={AppUI.siteIsDefault || isBlank(AppUI.site.accentColor) ? null : AppUI.site.accentColor}
              />
            ) : (
              this.renderContent()
            )
          }
        </Wrapper>
      )
    }

    return <span />
  }

  renderContent() {
    return (
      <>
        {this.step === 'initial' && this.renderInitialScreen()}
        {this.step === 'refund' && this.renderRefundScreen()}
        {this.step === 'reason' && this.renderReasonScreen()}
      </>
    )
  }

  renderInitialScreen() {
    return (
      <>
        <BackButton to={{ name: 'settings.billing' }}>Back to Billing</BackButton>
        <Text
          heading="3"
          margin="20px 0 0 0"
        >
          Cancel Membership
        </Text>

        <Text size="small" margin="20px 0 0 0">
          You will still have access to your membership benefits
          until {this.subscription.currentPeriodEnd?.fullTextFormattedDateWithYear}.
        </Text>

        <CancelButtonGroup>
          <Button
            onClick={() => {
              this.submitCancellation({ proratedRefund: false });
            }}
          >
            Cancel Yearly Membership
          </Button>

          {
            this.subscription.proratedRefundPolicy?.isAvailable && (
              <Button
                background="transparent"
                fontSize="16px"
                fontWeight="400"
                color="black"
                onClick={() => this.step = "refund"}
              >
                Request a pro-rated refund
              </Button>
            )
          }
        </CancelButtonGroup>
      </>
    )

  }

  renderRefundScreen() {
    return (
      <>
        <BackButton to={{ name: 'settings.billing' }} onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          this.step = 'initial';
        }}>Back</BackButton>

        <Text
          heading="3"
          margin="20px 0 0 0"
        >
          Cancel Membership
        </Text>

        <Text size="small" margin="20px 0 0 0">
          If you request a pro-rated refund, your membership benefits will end
          on {this.subscription.proratedRefundPolicy.refundableFrom.fullTextFormattedDateWithYear} and
          you will receive a pro-rated refund of {this.subscription.proratedRefundPolicy.refundableAmountFormatted} for
          the remaining {pluralize('month', this.subscription.proratedRefundPolicy.refundableMonths, true)}.
        </Text>

        <div>
          <Button
            margin="20px 20px 0 0"
            onClick={() => {
              this.submitCancellation({ proratedRefund: true });
            }}
          >
            Request a pro-rated refund
          </Button>
        </div>
      </>
    )
  }

  renderReasonScreen() {
    return (
      <>
        <BackButton to={{ name: 'settings.billing' }}>Back to Billing</BackButton>
        <Text
          heading="3"
          margin="20px 0 0 0"
        >
          Your Membership Is Cancelled
        </Text>

        <Text
          margin="30px 0 20px 0"
        >
          You still have access to your membership benefits until {
            this.isProratedRefundSelected ?
            this.subscription.proratedRefundPolicy.refundableFrom.fullTextFormattedDateWithYear :
            this.subscription.currentPeriodEnd.fullTextFormattedDateWithYear
          }.
        </Text>

        <Text
          margin="30px 0 20px 0"
        >
          Could you do us a favor and let us know why you cancelled your membership?
        </Text>
        {
          CancellationReasonStore.indexData.map(item => {
            return (
              <Option
                key={item.slug}
                onClick={() => this.onSetSelected(item)}
                selected={this.selectedReason && this.selectedReason.slug === item.slug}
              >
                <CheckWithCircleIcon />
                <Text>
                  {item.option}
                </Text>
              </Option>
            )
          })
        }
        {
          isPresent(this.selectedReason) && isPresent(this.selectedReason.expanded) &&
          <Message
            expanded={this.expandedReasonTextArea}
          >
            <Text
              bold
              margin="20px 0 0 0"
            >
              {this.selectedReason.expanded}
            </Text>
            <textarea
              onFocus={this.expandReasonTextArea}
              value={this.cancellationMessage}
              onChange={(event) => this.cancellationMessage = event.target.value}
            />
          </Message>
        }
        <Button
          margin="20px 0 0 0"
          disabled={isBlank(this.selectedReason)}
          onClick={this.submitReason}
        >
          Send Feedback
        </Button>
      </>
    )
  }
}

export default SettingsCancelWhitelabel;
