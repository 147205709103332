import React from 'react';
import Deck from 'components/deck/deck';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import autobind from 'autobind-decorator';
import { AppUI, DeckUI } from '@seedlang/state';
import Backdrop from 'components/backdrop';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  position: relative;
  background: rgba(7,49,78,1);
  margin: ${(props) => props.mobileLayout ? null : "10px 0 0 0"};
`;

const CardNavigationIcon = styled.div`
  position: absolute;
  left: ${props => props.left};
  right: ${props => props.right};
  top: 20px;
  color: #ffff008a;
  font-size: 30px;
  cursor: pointer;
`;

@observer
class DeckShow extends React.Component {
  @observable deckPercentage;

  constructor(props) {
    super(props);
    DeckUI.loadDeck({
      id: this.props.params.deckId,
      treeNodeId: this.props.params.treeNodeId,
      userDeckId: this.props.params.userDeckId,
      opponentId: this.props.params.opponentId,
      callback: this.afterLoadDeck,
    });
  }

  @autobind afterLoadDeck(resp) {
  }

  componentWillUnmount() {
    DeckUI.deckStore.clearShowData();
  }

  render() {
    return (
      <Wrapper
        mobileLayout={DeckUI.layout.isMobile}
      >
        {
         !AppUI.layout.isMobile &&
            <Backdrop
              background={DeckUI.isLastCard ? "#171004" : "#032033"}
              percentageComplete={DeckUI.userDeck && DeckUI.userDeck.deckPercentage}
              bigStars={DeckUI.currentCard && (DeckUI.currentCard.isLastCard || DeckUI.currentCard.checkpointCard)}
            />
        }
        {
          DeckUI.user.admin &&
            <CardNavigationIcon
              onClick={() => DeckUI.skipAnimationGotoLastCard()}
              left="10px"
              right="auto"
            >
              <i className='fa fa-angle-left' />
            </CardNavigationIcon>
        }
        {
          DeckUI.user.admin &&
            <CardNavigationIcon
              onClick={() => DeckUI.skipAnimationNextCard()}
              right="10px"
              left="auto"
            >
              <i className='fa fa-angle-right' />
            </CardNavigationIcon>
        }
        {
          AppUI.userIsLoaded &&
            <Deck
              treeNodeId={this.props.params.treeNodeId}
              deckId={this.props.params.deckId}
              triadWordId={this.props.params.wordId}
              fromGrammarSection={this.props.route.name.startsWith('grammar')}
            />
        }
      </Wrapper>
    );
  }
}

export default DeckShow;
