import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { LockIcon } from '@seedlang/icons';
import cx from 'classnames';
import { Theme } from '@seedlang/constants';
import { pixify } from '@seedlang/utils';
import { AppUI } from "@seedlang/state";
import { isPresent } from "@seedlang/utils";

const Btn = styled.button`
  cursor: ${(props) => props.cursor};
  background: ${(props) => props.background};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  flex: ${(props) => props.flex};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  color: ${(props) => props.color};
  display: inline-flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: ${(props) => props.backgroundOnHover};
    transform: scale(${(props) => props.scaleOnHover});
  }
  &:active {
    background: ${(props) => props.backgroundOnActive};
    transform: scale(${(props) => props.scaleOnActive});
  }
  &.disabled {
    background: #CCC;
  }
  &.cancel {
    background: ${Theme.red};
  }
  i {
    margin-right: ${props => props.iconMarginRight};
  }
  .lock-icon {
    height: 13px;
    svg {
      width: 10px;
      fill: #FFF;
      margin-right: 4px;
    }
  }
`

@observer
class Button extends React.Component {

  static defaultProps = {
    scaleOnHover: 1,
    color: "#FFF",
    padding:  "0 20px",
    height: "40px",
    lineHeight: "19px",
    fontSize: "16px",
    fontWeight: "800",
    borderRadius: "20px",
    border: 'none',
    width: 'auto',
    margin: 0,
    justifyContent: 'center',
    iconMarginRight: '3px',
  }

  @computed get background() {
    if (this.props.gradient) {
      return "linear-gradient(-134deg,rgba(59,215,197,.8) 0,#0daff6 100%)";
    }
    if (this.props.background) {
      return this.props.background;
    }
    if (!AppUI.siteIsDefault && isPresent(AppUI.site.accentColor)) {
      return AppUI.site.accentColor;
    } return Theme.blue;
  }

  @computed get cursor() {
    if (this.props.cursor) {
      return this.props.cursor;
    }
    return this.props.disabled ? 'default' : 'pointer';
  }

  render() {
    return (
      <Btn
        scaleOnHover={this.props.scaleOnHover}
        scaleOnActive={this.props.scaleOnActive}
        backgroundOnHover={this.props.backgroundOnHover}
        backgroundOnActive={this.props.backgroundOnActive}
        disabled={this.props.disabled}
        cursor={this.cursor}
        color={this.props.color}
        margin={this.props.margin}
        flex={this.props.flex}
        width={pixify(this.props.width)}
        minWidth={this.props.minWidth}
        border={this.props.border}
        onClick={this.props.onClick}
        borderRadius={this.props.borderRadius}
        fontWeight={this.props.fontWeight}
        fontSize={this.props.fontSize}
        height={this.props.height}
        lineHeight={this.props.lineHeight}
        padding={this.props.padding}
        background={this.background}
        className={cx({
          cancel: this.props.cancel,
          disabled: this.props.disabled,
        })}
        style={this.props.style}
        justifyContent={this.props.justifyContent}
        iconMarginRight={this.props.iconMarginRight}
      >
        {this.props.icon === 'lock' && <LockIcon /> }
        {this.props.children}
      </Btn>
    );
  }
}

export default ErrorBoundary(Button);
