import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import VideoPlayer from 'components/media/video_player';
import VideoRecorder from 'components/media/video_recorder';
import { isPresent, isBlank, pixify } from '@seedlang/utils';
import Meter from 'components/meter';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

const VideoPlayerWrapper = styled.div`
  position: relative;
  .fa-times {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background: white;
    text-align: center;
    line-height: 30px;
    z-index: ${Theme.z["video-overlay-foreground"]};
    cursor: pointer;
    opacity: 0.4;
  }
`;

@observer
class VideoPlayerRecorder extends React.Component {

  static propTypes = {
    simplified: PropTypes.bool,
    objectUrl: PropTypes.string,
    videoClip: PropTypes.object,
    onPlaybackClosed: PropTypes.func,
    userId: PropTypes.string,
    afterCreateVideo: PropTypes.func,
    videoableId: PropTypes.string,
    videoableType: PropTypes.string,
    afterCreateRequestSent: PropTypes.func,
    onCreateVideoClip: PropTypes.func,
  }

  componentWillUnmount() {
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return (
      <Wrapper
        width={pixify(this.props.width)}
        height={pixify(this.props.width)}
      >
        {
          (isPresent(this.props.objectUrl) || isPresent(this.props.videoClip)) &&
            <VideoPlayerWrapper
              width={pixify(this.props.width)}
              height={pixify(this.props.width)}
            >
              {
                this.props.onPlaybackClosed &&
                  <i
                    className="fa fa-times"
                    onClick={this.props.onPlaybackClosed}
                  />
              }
              <VideoPlayer
                useWebm
                objectUrl={this.props.objectUrl}
                videoClip={this.props.videoClip}
                width={this.props.width}
                autoPlay
              />
            </VideoPlayerWrapper>
        }
        {
          isBlank(this.props.objectUrl) && isBlank(this.props.videoClip) &&
            <VideoRecorder
              keepVideoStream
              countdownBeep={this.props.countdownBeep}
              mediaStore={this.props.mediaStore}
              simplified={this.props.simplified}
              onCreateVideoClip={this.props.onCreateVideoClip}
              afterCreateVideo={this.props.afterCreateVideo}
              videoableId={this.props.videoableId}
              foreignKey={this.props.foreignKey}
              videoableType={this.props.videoableType}
              width={this.props.width}
              afterCreateRequestSent={this.props.afterCreateRequestSent}
              overwriteVideo={this.props.overwriteVideo}
            />
        }
        {
          !this.props.simplified &&
            <Meter
              numSegments={20}
              margin={5}
              projectToward="right"
              level={this.props.mediaStore.normalizedAudioLevel}
            />
        }
      </Wrapper>
    );
  }
}

export default VideoPlayerRecorder;
