import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import cx from 'classnames';
import DeleteButton from 'components/button/delete_button';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`

`;

@observer
class WordSourceEditChild extends React.Component {

  render() {
    return (
      <Wrapper>
        <h5>Literal Source for '{this.props.wordAssociation.word.targetText}'</h5>
        <div style={{marginBottom: 5}}>
          <InPlaceCheckbox
            model="word_associations"
            field="blank_source"
            value={this.props.wordAssociation?.blankSource}
            id={this.props.wordAssociation.id}
            afterChange={this.props.onChange.bind(this)}
          >
            This word does not have a source
          </InPlaceCheckbox>
        </div>
        <table className="table-wrapper admin-table">
          <thead>
            <tr>
              <th width="70">Literal</th>
              <th>Source</th>
              <th>British variant</th>
              {
                this.props.wordAssociation.word.isNoun &&
                <th>Display gender</th>
              }
              <th width="30" />
            </tr>
          </thead>
          <tbody>
            {
              this.props.wordAssociation.word.translationSources.map((item) => {
                return (
                  <tr key={item.id} >
                    <td>
                      <i
                        className={cx('fa', 'fa-big', 'fa-on-click', 'fa-check-circle', {
                          selected: this.props.defaultSource && item.source && this.props.defaultSource.id === item.source.id,
                          disabled: !this.props.defaultSource || !item.source || this.props.defaultSource.id !== item.source.id,
                        })}
                        onClick={() => this.props.onSelectForWordAssociation(item)}
                      />
                    </td>
                    <td
                      style={{paddingLeft: 10}}
                    >
                      {item.source && item.source.text}
                      {item.source && item.source.displayGender ? this.props.wordAssociation.word.genderAbbreviation : ''}
                    </td>
                    <td
                      style={{paddingLeft: 10}}
                    >
                      <InPlaceText
                        placeholder="No British variant"
                        defaultValue={item.source.ukSpelling}
                        model="sources"
                        field="ukSpelling"
                        submitOnEnter={true}
                        rejectBlank={false}
                        id={item.source.id}
                        suffix={item.source && item.source.displayGender ? this.props.wordAssociation.word.genderAbbreviation : ''}
                      />
                    </td>
                    {
                      this.props.wordAssociation.word.isNoun &&
                      <td>
                        <InPlaceCheckbox
                          icon="check"
                          iconStyle={{margin: "0 5px 0 0"}}
                          id={item.source.id}
                          value={item.source.displayGender}
                          model="sources"
                          field="display_gender"
                          afterChange={this.props.onChange.bind(this)}
                        />
                      </td>
                    }
                    <td>
                      <DeleteButton
                        onConfirm={this.props.onRemove.bind(this, item.id)}
                        message="Remove this source from this word?"
                        className="fa-block right"
                      />
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <div style={{ marginTop: 10 }}>
          <InPlaceText
            model="translation_sources"
            placeholder="Add a new source"
            field="text"
            verb="POST"
            data={{ translation_id: this.props.wordAssociation.word.id, user_id: AppUI.user.id, force_accepted: true }}
            afterChange={this.props.onChange}
            style={{ padding: '20px 10px' }}
            show
            submitOnEnter
            clearOnEnter
            focusOnMount
          />
        </div>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WordSourceEditChild);
