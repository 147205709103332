import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { MembershipTree2Icon, MembershipTree1Icon, Membership3LeafIcon, Membership2LeafIcon } from '@seedlang/icons';
import { flexCenter } from '@seedlang/style_mixins';
import DiscountEmblem from 'components/discount_emblem';
import { dollar, cents } from '@seedlang/utils';
import Text from 'components/text';
import { isPresent, isBlank } from '@seedlang/utils';
import { AppUI } from '@seedlang/state';
import { noop } from 'lodash';
import ExpandableTextDisplay from "components/expandable_text_display";
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  position: relative;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: ${props => props.border};
  cursor: ${props => props.cursor};
  margin: ${props => props.margin};
  min-width: ${props => props.width};
  max-width: ${props => props.width};
  transform: ${props => props.transform};
  background: ${props => props.background};
  padding: 15px;
  display: flex;
  flex-basis: calc(50% - 10px);
  align-items: center;
  flex-direction: column;
  border-radius: ${props => props.borderRadius};
  position: relative;
  height: ${props => props.height};
  box-shadow: ${props => props.boxShadow};
  &:hover {
    transform: ${props => props.hoverTransform};
  }
`;

const MembershipTypeIcon = styled.div`
  ${flexCenter()}
  width: 100%;
  margin-bottom: 30px;
  .icon-wrapper {
    svg {
      width: 68px;
    }
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${props => props.height};
  justify-content: space-between;
  margin-top: 10px;
`;

const AmountSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Amount = styled.div`
  font-weight: bold;
  color: ${props => props.color};
  text-shadow: ${props => props.color !== 'white' ? 'none' : '1px 1px rgba(49, 49, 49, 0.8)' };
  height: 46px;
`;

const AmountWrapper = styled.div`
  display: flex;
`;

const CurrencySymbol = styled.div`
  font-size: 36px;
  margin-top: -4px;
  font-family: ${props => props.fontFamily};
`;

const DollarAmount = styled.div`
  font-size: 60px;
  font-family: ${props => props.fontFamily};
`;

const CentsAmount = styled.div`
  font-size: 23px;
  margin-top: -8px;
  font-family: ${props => props.fontFamily};
`;

const Slash = styled.div`
  font-size: 20px;
`;

const Month = styled.div`
  font-size: 15px;
`;

const Image = styled.div`
  margin-bottom: 35px;
  img {
    max-width: 250px;
    border-radius: 5px;
  }
`;

const NotesWrapper = styled.div`
  height: ${props => props.height};
  display: flex;
  align-items: center;
`;

const Strikethrough = styled.div`
  text-decoration: line-through;
  margin-right: 5px;
  color: #FFF;
  font-size: 13px;
`;

const Description = styled.div`
  font-size: 13px;
  color: ${(props) => props.color};
  flex-direction: column;
  margin-top: 2.5px;
  line-height: 15px;
  margin-bottom: 2.5px;
  margin: ${props => props.margin};
  text-align: center;
  text-wrap: balance;
  display: grid;
  place-items: center;
`;

const FullDescription = styled.div`
  width: 100%;
  flex-direction: column;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  height: ${props => props.height};
  overflow: hidden;
  p {
    color: ${(props) => props.color};
  }
  ul {
    margin-left: 20px;
  }
  li {
    list-style-type: disc;
  }
  a {
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: ${props => props.margin};
  a {
    color: white;
    width: 100%;
  }
`;

const Button = styled.div`
  background: ${props => props.background};
  font-size: 16px;
  padding: 25px;
  line-height: 0;
  width: 100%;
  color: ${props => props.color};
  border: ${props => props.border};
  border-radius: 50px;
  margin: 0;
  height: 50px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    transform: ${props => props.hoverTransform};
  }
  &:active {
    transform: ${props => props.clickTransform};
  }
`;

const ExpandLink = styled.div`
  color: white;
  border-top: 1px solid white;
  margin-top: 20px;
  padding-top: 10px;
  font-size: 16px;
  width: 100%;
  text-align: center;
`;

const Title = styled.div`
  margin-bottom: 5px;
  min-height: 50px;
  display: flex;
  align-items: center;
`;

const DiscountText = styled.div`
  margin-top: 10px;
  margin-bottom: -5px;
  font-size: 16px;
  background: ${props => props.whiteBackground ? '' : '#408f60'};
  border-radius: 20px;
  color: ${props => props.whiteBackground ? '#408f60' : '#FFF'};
  width: 100%;
  text-align: center;
  font-weight: 800;
  cursor: pointer;
`;

const TopBanner = styled.div`
  background: ${Theme.purple};
  position: absolute;
  top: -24px;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #FFF;
`;

@observer
class MembershipOption extends React.Component {
  @observable expanded = false;

  @computed get membershipMatchesCurrent() {
    if (this.props.paymentMigration) {
      return !AppUI.siteIsDefault && this.props.paymentMigration.membership?.id  === this.props.membership.id;
    }
    return !AppUI.siteIsDefault && isPresent(this.props.activeSubscription) && this.props.activeSubscription.membership?.id === this.props.membership.id;
  }

  @computed get membershipTypeMatchesCurrent() {
    if (this.props.paymentMigration) { return }
    return !AppUI.siteIsDefault && isPresent(this.props.activeSubscription) && this.props.activeSubscription.membershipTypeId === this.props.membership?.membershipType?.id;
  }

  @computed get buttonText() {
    if (!AppUI.siteIsDefault && AppUI.user.hasPermanentGroupMembership) {
      return '-';
    } else if (this.membershipMatchesCurrent) {
      return 'Current';
    } else if (this.membershipTypeMatchesCurrent) {
      if (this.props.membership?.numberMonths === 12) {
        return 'Switch to Yearly';
      } else if (this.props.membership?.numberMonths === 1) {
        return 'Switch to Monthly';
      } return 'Switch';
    } else if (!AppUI.siteIsDefault && isPresent(this.props.activeSubscription?.membership) && this.props.activeSubscription.membership.position < this.props.membership.position) {
      return 'Upgrade';
    } else if (!AppUI.siteIsDefault && isPresent(this.props.activeSubscription?.membership) && this.props.activeSubscription.membership.position > this.props.membership.position) {
      return 'Downgrade';
    } else if (!AppUI.siteIsDefault) {
      return 'Join';
    } else if (this.props.signedOut) {
      return 'Get Started';
    } else if (this.props.membership.recurring) {
      return 'Subscribe';
    }
    return 'Buy Now';
  }

  @computed get border() {
    if (!this.props.hideBorder) {
      return this.props.membership.highlight ? `10px solid ${Theme.purple}` : '10px solid #408f60';
    } if (!AppUI.siteIsDefault) {
      return this.membershipTypeMatchesCurrent ? `6px solid ${AppUI.site.accentColor}` : '6px solid #FFF';
    }
    return null;
  }

  @computed get membershipName() {
    if (isPresent(this.props.membership?.membershipType?.name)) {
      return this.props.membership?.membershipType?.name;
    } else {
      return this.props.membership.title || "";
    }
  }

  @computed get equivalentYearlyMembership() {
    if (isPresent(this.props.membership) && isPresent(this.props.memberships) && this.props.membership.numberMonths === 1 && isPresent(this.props.membership.title)) {
      return this.props.memberships.find(item => item.numberMonths === 12 && item.title === this.props.membership.title)
    } return null;
  }

  @computed get yearlyDiscount() {
    return this.equivalentYearlyMembership?.discount
  }

  render() {
    return (
      <Wrapper
        className="MembershipOption__Wrapper"
        onClick={() => this.membershipMatchesCurrent || this.props.onlyButtonIsClickable ? noop : this.props.onClick(this.props.membership)}
        border={this.border}
        borderRadius={this.props.borderRadius}
        background={this.props.background}
        height={this.props.hideIcon ? "auto" : "365px"}
        hoverTransform={this.props.onlyButtonIsClickable ? null : this.props.hoverTransform}
        transform={this.props.transform}
        margin={this.props.margin}
        width={this.props.width}
        flexWrap={this.props.expandable ? 'wrap' : 'nowrap'}
        boxShadow={this.props.boxShadow}
        cursor={this.props.onlyButtonIsClickable ? 'auto' : 'pointer'}
        style={this.props.style}
      >
        {
          this.props.membership.highlight &&
            <TopBanner>
              Best Deal!
            </TopBanner>
        }
        <TopSection
          height={`${this.props.topSectionHeight ?? 400}px`}
        >
          <Title>
            <Text
              fontSize="22px"
              bold
              color={this.props.lightBackground ? "black" : "white"}
              textAlign="center"
              maxWidth="180px"
            >
              <div
                dangerouslySetInnerHTML={{ __html: this.membershipName }}
              />
            </Text>
          </Title>
          {
            this.props.membership.imageUrl &&
              <Image>
                <img src={this.props.membership.imageUrl} alt="" />
              </Image>
          }
          {
            !this.props.hideIcon &&
              <MembershipTypeIcon
              >
                {
                  this.props.membership.icon === "2-leaf" &&
                    <Membership2LeafIcon />
                }
                {
                  this.props.membership.icon === "3-leaf" &&
                    <Membership3LeafIcon />
                }
                {
                  this.props.membership.icon === "tree-1" &&
                    <MembershipTree1Icon />
                }
                {
                  this.props.membership.icon === "tree-2" &&
                    <MembershipTree2Icon />
                }
              </MembershipTypeIcon>
          }
          <AmountSection>
            <Amount
              color={this.props.lightBackground ? "black" : "white"}
            >
              <AmountWrapper>
                <CurrencySymbol
                  fontFamily={AppUI.siteIsDefault ? null : "'Rubik'!important"}
                >
                  {this.props.currencySymbol}
                </CurrencySymbol>
                <DollarAmount
                  fontFamily={AppUI.siteIsDefault ? null : "'Rubik'!important"}
                >
                  {this.props.membership.recurring ? dollar(this.props.membership.costMonthly(this.props.currency)) : dollar(this.props.membership.cost(this.props.currency))}
                </DollarAmount>
                <CentsAmount
                  fontFamily={AppUI.siteIsDefault ? null : "'Rubik'!important"}
                >
                  {this.props.membership.recurring ? cents(this.props.membership.costMonthly(this.props.currency)) : cents(this.props.membership.cost(this.props.currency))}
                </CentsAmount>
                {
                  this.props.membership.recurring &&
                    <Slash>
                      /
                    </Slash>
                }
                {
                  this.props.membership.recurring &&
                    <Month>
                      month
                    </Month>
                }
              </AmountWrapper>
            </Amount>
            <NotesWrapper height={`${this.props.notesHeight}px`}>
              {
                isPresent(this.props.membership.strikethroughUsd) &&
                  <Strikethrough
                    color={this.props.lightBackground ? "black" : "rgba(255, 255, 255, 0.9)"}
                  >
                    {this.props.currency?.toUpperCase() === 'USD' ? this.props.membership.strikethroughUsd : this.props.membership.strikethroughEur}
                  </Strikethrough>
              }
              {
                isPresent(this.props.membership.descriptionUsd) &&
                  <Description
                    color={this.props.lightBackground ? "black" : "rgba(255, 255, 255, 0.9)"}
                  >
                    {this.props.currency?.toUpperCase() === 'USD' ? this.props.membership.descriptionUsd : this.props.membership.descriptionEur}
                  </Description>
              }
              {
                !AppUI.siteIsDefault &&
                  <Description
                    color={this.props.lightBackground ? "black" : "rgba(255, 255, 255, 0.7)"}
                  >
                    {this.props.membership.numberMonths === 1 ? "You can cancel at any time." : "Renews yearly, flexible cancellation after 1st year."}
                  </Description>
              }
            </NotesWrapper>
            {
              isPresent(this.yearlyDiscount) && this.yearlyDiscount > 0 &&
              <DiscountText onClick={this.props.toggleYearly}>
                {`Switch to yearly for ${this.yearlyDiscount}% off.`}
              </DiscountText>
            }
            {
              !AppUI.siteIsDefault && isPresent(this.props.membership.discount) && this.props.membership.discount > 0 && isBlank(this.yearlyDiscount) &&
              <DiscountText whiteBackground>
                {`${this.props.membership.discount}% off compared to monthly`}
              </DiscountText>
            }
          </AmountSection>
          {
            this.props.membership.discount > 0 && AppUI.siteIsDefault &&
              <DiscountEmblem
                amount={this.props.membership.discount}
                background={this.props.membership.highlight ? Theme.purple : '#408f60'}
              />
          }
        </TopSection>
        {
          (AppUI.siteIsDefault || !AppUI.user.hasPermanentGroupMembership) &&
            <ButtonWrapper
              margin={AppUI.siteIsDefault ? '20px 0 10px 0' : '20px 0 10px 0'}
            >
              <Button
                color={AppUI.siteIsDefault ? '#3b9c61' : this.membershipMatchesCurrent ? this.props.accentColor : this.props.background}
                background={this.props.lightBackground && this.props.accentColor && !this.membershipMatchesCurrent ? this.props.accentColor : 'white'}
                border={!AppUI.siteIsDefault && this.props.lightBackground && this.props.accentColor ? `4px solid ${this.props.accentColor}` : null}
                hoverTransform={this.props.onlyButtonIsClickable && !this.membershipMatchesCurrent ? this.props.hoverTransform : null}
                clickTransform={this.props.onlyButtonIsClickable && !this.membershipMatchesCurrent ? this.props.clickTransform : null}
                onClick={() => this.props.onlyButtonIsClickable && (!this.membershipMatchesCurrent || this.props.mode === "payment_migration") ? this.props.onClick(this.props.membership) : noop}
              >
                { this.buttonText }
              </Button>
            </ButtonWrapper>
        }
        <FullDescription
          color={this.props.lightBackground ? "black" : "white"}
        >
          <ExpandableTextDisplay
            paragraphLimit={AppUI.layout.isMobile ? 3 : 100}
            markdownText={this.props.membership.descriptionMd}
            nonExpandedHeight={AppUI.layout.isMobile ? "180px" : null}
            htmlText={isPresent(this.props.membership.description) && isBlank(this.props.membership.descriptionMd) && this.props.membership.description}
          />
        </FullDescription>
        {
          this.props.expandable && !this.expanded &&
            <ExpandLink
              onClick={() => this.expanded = true}
            >
              Show More
            </ExpandLink>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(MembershipOption);
