"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = incrementId;
function incrementId(ary, value) {
  var index = ary.findIndex(function (item) {
    return item.id === value;
  });
  if (index < ary.length - 1) {
    return ary[index + 1].id;
  } else {
    return ary[0].id;
  }
}