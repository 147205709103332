import React from 'react';
import { observer } from 'mobx-react';
import { VocabProgressBarWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import ReactTooltip from 'react-tooltip';
import { TriangleProgress } from '@seedlang/icons';

const Wrapper = styled.div`
  .triangle-progress {
    height: 15px;
    svg {
      height: 15px;
    }
    #segment-1 {
      fill: ${(props) => props.enableSegment1 ? Theme.orange : '#d8d8d8'};
    }
    #segment-2 {
      fill: ${(props) => props.enableSegment2 ? Theme.orange : '#d8d8d8'};
    }
    #segment-3 {
      fill: ${(props) => props.enableSegment3 ? Theme.orange : '#d8d8d8'};
    }
    #segment-4 {
      fill: ${(props) => props.enableSegment4 ? Theme.orange : '#d8d8d8'};
    }
  }
`;

const TooltipWrapper = styled.div`

`;

@observer
class VocabProgressBar extends React.Component {

  render() {
    return (
      <TooltipWrapper
        data-tip
        data-for={this.props.word.id}
      >
        <Wrapper
          enableSegment1={this.props.enableSegment1}
          enableSegment2={this.props.enableSegment2}
          enableSegment3={this.props.enableSegment3}
          enableSegment4={this.props.enableSegment4}
        >
          <TriangleProgress />
        </Wrapper>
        <ReactTooltip
          place="top"
          type="dark"
          effect="solid"
          id={this.props.word.id}
          class="custom-tooltip"
        >
          {this.props.tooltipText}
        </ReactTooltip>
      </TooltipWrapper>
    );
  }
}

export default VocabProgressBarWrapper(VocabProgressBar);
