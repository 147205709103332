import React from 'react';
import { observer } from 'mobx-react';
import RatingIndex from "pages/builder/ratings/rating_index";

@observer
class ExerciseRatingsIndex extends React.Component {

  render() {
    return (
      <RatingIndex
        simple
        showExercise
        groupId={this.props.params.groupId}
        defaultFilters={{group_id: this.props.params.groupId, comment: true}}
      />
    );
  }
}

export default ExerciseRatingsIndex;
