import React from 'react';
import { observer } from 'mobx-react';
import { RatingTypeStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';

@observer
class RatingTypeEdit extends React.Component {

  componentDidMount() {
    RatingTypeStore.getShow({ ids: {ratingTypeId: this.props.params.ratingTypeId }});
  }

  render() {
    return (
      <div className='rating-type-edit'>
        <div className="row">
          <div className="col-xs-4">
            <fieldset>
              <legend>Name</legend>
              <InPlaceText
                defaultValue={RatingTypeStore.showDataField('name')}
                model="rating_types"
                field="name"
                id={RatingTypeStore.showDataField('id')}
              />
            </fieldset>
          </div>
          <div className="col-xs-4">
            <fieldset>
              <legend>Slug</legend>
              <InPlaceText
                defaultValue={RatingTypeStore.showDataField('slug')}
                model="rating_types"
                field="slug"
                id={RatingTypeStore.showDataField('id')}
              />
            </fieldset>
          </div>
          <div className="col-xs-4">
            <fieldset>
              <legend>Overall</legend>
              <InPlaceCheckbox
                icon="check"
                value={RatingTypeStore.showDataField('overall')}
                model="rating_types"
                field="overall"
                id={RatingTypeStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <fieldset>
              <legend>Description</legend>
              <InPlaceText
                defaultValue={RatingTypeStore.showDataField('description')}
                model="rating_types"
                field="description"
                id={RatingTypeStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default RatingTypeEdit;
