import React from 'react';
import { observer } from 'mobx-react';
import { SourceListWithInputWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import SourceList from 'components/source/source_list';
import SourceSubmission from 'components/source/source_submission';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SourceRow = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: ${(props) => props.padding};
  background: ${(props) => props.background};
`;

const SourceSubmissionWrapper = styled.div`
  padding: 10px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 10px 10px;
`;


@observer
class SourceListWithInput extends React.Component {

  static defaultProps = {
    background: "#ECECEC",
    padding: "0 10px 5px 10px",
  }

  render() {
    return (
      <Wrapper>
        {
          this.props.showTargetText &&
            <Title>
              {this.props.word.targetTextWithDefiniteArticle}
            </Title>
        }
        <SourceRow
          padding={this.props.padding}
        >
          <SourceList
            background={this.props.background}
            showFlag
            flagWrapperWidth="25px"
            flagWrapperAlignLeft
            onRemoveSource={this.props.onRemoveSource}
            borderBottom="1px solid none"
            leftAlign
            padding="10px"
            sourcePadding="5px"
            sourceMargin="0"
            user={AppUI.user}
            word={this.props.word}
            fontSize={this.props.fontSize}
            lineHeight={this.props.lineHeight}
            showInput={this.props.showInput}
            loadWord={this.props.loadWord}
            showSpinner={this.props.showSpinner}
          />
        </SourceRow>
        {
          this.props.showInput &&
            <SourceSubmissionWrapper>
              <SourceSubmission
                afterUpdate={this.props.afterSubmitSourceInput}
                word={this.props.word}
              />
            </SourceSubmissionWrapper>
        }
      </Wrapper>
    );
  }
}

export default SourceListWithInputWrapper(SourceListWithInput);
