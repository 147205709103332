import { isPresent } from "./index";

export default function getYoutubeEmbedUrl(youtubeUrl) {
  let youtubeVideoId;
  if (isPresent(youtubeUrl) && youtubeUrl.match('watch')) {
    const regexp = /watch\?v=(...........)/g;
    const match = regexp.exec(youtubeUrl);
    if (match) {
      youtubeVideoId =  match[1];
    }
  }

  if (isPresent(youtubeUrl) && youtubeUrl.match('watch') && isPresent(youtubeVideoId)) {
    return `https://www.youtube.com/embed/${youtubeVideoId}`;
  } else {
    return youtubeUrl;
  }
  return null;
}
