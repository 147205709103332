import React from 'react';
import { observer } from 'mobx-react';
import MembershipGroupIndex from 'pages/builder/membership_groups/membership_group_index';

@observer
class SiteMembershipGroupIndex extends React.Component {
  render() {
    return (
      <MembershipGroupIndex
        {...this.props}
        whiteLabeled
      />
    );
  }
}

export default SiteMembershipGroupIndex;

