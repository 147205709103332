import React from 'react';
import { observer } from 'mobx-react';
import { autorun, computed, observable } from 'mobx';
import { AuthenticateStore, ServerStore } from '@seedlang/stores';
import AuthenticateStatus from 'components/authenticate/authenticate_status';
import autobind from 'autobind-decorator';
import { AppUI, TreeUI } from '@seedlang/state';
import FormWrapper from 'components/authenticate/form_wrapper';
import Button from 'components/button/button';
import { isBlank } from '@seedlang/utils';
import { Theme } from '@seedlang/constants';
import Text from 'components/text';
import styled from '@emotion/styled';
import AppleSignInButton from './apple_sign_in_button';

const Subtitle = styled.div`
  font-size: 14px;
  margin: -5px 0 10px 0;
  font-weight: bold;
  text-align: center;
`;

@observer
class AuthenticateSignin extends React.Component {
  @observable email = '';
  @observable password = '';
  @observable readyForRedirect = false;

  @computed get disabled() {
    return isBlank(this.email) || isBlank(this.password);
  }

  @computed get signInHeader() {
    return AppUI.siteIsDefault ? `Sign In to ${AppUI.user?.site?.name}` : `Sign In`;
  }

  componentDidMount() {
    ServerStore.clearMessages();
    autorun(() => {
      if (this.readyForRedirect) {
        AppUI.routeToSignedInHome();
      }
    })
  }

  @autobind onSubmit(e) {
    e.preventDefault();
    if (!this.disabled) {
      AuthenticateStore.signIn({
        data: {
          assign_membership_group_id: AppUI.getCookieValue('assign_membership_group_id'),
          concept_id: this.props.conceptId,
          password: this.password,
          email: this.email,
        },
        failureCallback: this.onSignInFailure
      }, this.afterSignIn);
    }
  }

  @autobind afterSignIn(resp) {
    if (resp.success) {
      this.afterLoadUser(resp);
      this.readyForRedirect = true;
      if (AppUI.siteIsDefault) {
        AppUI.loadReviewDecks();
        TreeUI.loadTreesFromTargetLanguage(AppUI.targetLanguageId);
      }
      if (this.props.onboarding) {
        AppUI.createEvent("onboarding - finish onboarding");
      }
    } else {
      ServerStore.setErrorMessage(resp.message);
    }
  }

  @autobind onSignInFailure(resp) {
    // Note: errors are intercepted by Api.ajax, the error is stored in ServerStore and rendered in `<AuthenticateStatus/>`

    if (resp.code === 'unconfirmed') {
      this.props.onConfirmationRequired({
        email: this.email,
        action: 'signIn',
      });
    }
  }

  @autobind afterLoadUser(resp) {
    AppUI.setAuthUserStoreShowData(resp);
    if (this.props.onboarding) {
      this.readyForRedirect = true;
    }
  }

  render() {
    return (
      <FormWrapper
        padding={0}
        color={AppUI.siteIsDefault ? "#333" : "#000"}
      >
        <form
          acceptCharset="UTF-8"
          onSubmit={this.onSubmit}
          id="new_user"
        >
          {
            !window.location.href.match("from_patreon") &&
              <Text
                heading="3"
                center
                margin={AppUI.siteIsDefault ? "0 0 20px 0" : "0 0 10px 0"}
                color="#333"
              >
                {this.signInHeader}
              </Text>
          }
          {
            AppUI.site.published && !AppUI.siteIsDefault && !window.location.href.match("from_patreon") &&
              <Subtitle>
                {`Sign in with Your ${AppUI.user?.site?.displayName} or Seedlang Credentials`}
              </Subtitle>
          }
          {
            !AppUI.site.published && !AppUI.siteIsDefault && !window.location.href.match("from_patreon") &&
              <Subtitle>
                Sign in With Your Early Access Credentials
              </Subtitle>
          }
          {
            !AppUI.siteIsDefault && window.location.href.match("from_patreon") &&
              <Subtitle>
                <div>You have already connected your Patreon account.</div>
                <div>{`Please sign in with your ${AppUI.user?.site?.displayName} or Seedlang Credentials`}</div>
              </Subtitle>
          }
          <AuthenticateStatus />
          <div>
            <label htmlFor="user_email">Email</label>
            <input
              required
              autoFocus={(!AppUI.layout.isMobile || AppUI.siteIsDefault) && this.props.show}
              type="email"
              value={this.email}
              onChange={(el) => this.email = el.target.value}
            />
          </div>
          <div>
            <label htmlFor="user_password">Password</label>
            <input
              required
              type="password"
              value={this.password}
              onChange={(el) => this.password = el.target.value}
            />
          </div>
          <Button
            margin="10px 0 0 0"
            width="100%"
            disabled={this.disabled}
            onClick={this.onSubmit}
            height="50px"
            borderRadius="50px"
            scaleOnHover={this.disabled ? 1 : 1.05}
            background={AppUI.siteIsDefault || isBlank(AppUI.site.accentColor) ? Theme.blue : AppUI.site.accentColor}
            backgroundOnHover={AppUI.siteIsDefault || isBlank(AppUI.site.accentColor) ? Theme.blue: AppUI.site.transparentAccentColor}
            backgroundOnActive={AppUI.siteIsDefault || isBlank(AppUI.site.accentColor) ? Theme.blue: AppUI.site.accentColor}
            color={this.disabled ? "#e4e4e4" : '#FFF'}
          >
            Sign In
          </Button>

          {
            AppUI.siteIsDefault && (
              <div style={{ margin: "5px 0 20px 0" }}>
                <AppleSignInButton afterSignIn={this.afterSignIn} scaleOnHover={1.05} />
              </div>
            )
          }
        </form>
      </FormWrapper>
    );
  }
}

export default AuthenticateSignin;
