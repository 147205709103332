import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import { Constants } from '@seedlang/constants';
import { range } from 'lodash';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`
  width: 400px;
  display: flex;
  flex-wrap: wrap;
`;

const MonthWrapper = styled.div`
  border-left: 1px solid #CCC;
  border-top: 1px solid #CCC;
  width: 361px;
  display: flex;
  flex-wrap: wrap;
`;

const Month = styled.div`
  width: 90px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  &:hover {
    background: #f0f0f0;
  }
`;

const YearWrapper = styled.div`
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;
  margin-bottom: 10px;
`;

const Year = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 5px;
  color: #333;
  width: 40px;
  height: 40px;
  &:hover {
    background: #e0e0e0;
  }
  border-radius: 50px;
  font-size: 28px;
`;

@observer
class AnalyticsDateModal extends React.Component {
  @observable year = this.props.year;

  @autobind incrementYear() {
    this.year += 1;
  }

  @autobind decrementYear() {
    this.year -= 1;
  }

  render () {
    return (
      <Wrapper>
        <YearWrapper>
          <Arrow
            onClick={this.decrementYear}
          >
            <i className='fa fa-angle-left' />
          </Arrow>
          <Year>
            {this.year}
          </Year>
          <Arrow
            onClick={this.incrementYear}
          >
            <i className='fa fa-angle-right' />
          </Arrow>
        </YearWrapper>
        <MonthWrapper>
          {
            range(12).map(item => {
              return (
                <Month
                  key={item}
                  onClick={() => this.props.onSelectDate(this.year, item + 1)}
                >
                  {Constants.MONTHS[item].substring(0, 3)}
                </Month>
              )
            })
          }
        </MonthWrapper>
      </Wrapper>
    );
  }
}

export default AnalyticsDateModal;
