import React from 'react';
import { observer } from 'mobx-react';
import { AppUI, ExerciseUI } from '@seedlang/state';
import { isPresent } from '@seedlang/utils';
import Button from 'components/button/button';
import { ChatOutlineIcon } from '@seedlang/icons';
import autobind from "autobind-decorator";
import { last } from "lodash";
import { computed } from "mobx";

@observer
class WorksheetTab extends React.Component {

  @autobind routeIs(name) {
    if (this.props.routeName.includes('custom_tab')) {
      return name.endsWith(last(ExerciseUI.routeStore.router?.routes)?.name) && this.props.params.customTabId === this.props.customTabId;
    }
    return name.endsWith(last(ExerciseUI.routeStore.router?.routes)?.name);
  }

  @autobind routeTo(name) {
    ExerciseUI.routeStore.routeToNamed(name, {
      groupId: this.props.params.groupId,
      worksheetId: this.props.worksheetId,
      exerciseId: ExerciseUI.defaultExerciseId,
      customTabId: this.props.customTabId,
    });
  }

  render() {
    return (
      <Button
        height="40px"
        fontSize={ExerciseUI.layout.isMobile ? "12px" : "14px"}
        color="#333"
        borderRadius={ExerciseUI.layout.isMobile ? "10px" : "10px 10px 0 0"}
        background={this.routeIs(this.props.routeName) ? "#FFF" : "#D8D8D8"}
        backgroundOnHover={this.routeIs(this.props.routeName) ? null : "#c7c7c7"}
        padding={ExerciseUI.layout.isMobile ? "10px" : "20px"}
        onClick={() => this.routeTo(this.props.routeName)}
        width={null}
        minWidth={this.props.minWidth}
        flex={ExerciseUI.layout.isMobile ? 1 : null}
      >
        {
          isPresent(this.props.iconName) && this.props.iconName !== 'ChatOutline' &&
          <i className={`fa fa-${this.props.iconName}`}/>
        }
        {
          this.props.iconName === 'ChatOutline' &&
          <ChatOutlineIcon />
        }
        {this.props.buttonText.toUpperCase()}
      </Button>
    );
  }
}

export default WorksheetTab;
