import React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  margin-left: 5px;
  color: ${(props) => props.color};
  font-weight:  ${(props) => props.fontWeight};
`;

@observer
class FilterButtonText extends React.Component {

  @computed get color() {
    if (this.props.disabled){
      return "#CCC";
    } else if (this.props.highlightDictionaryWordTypes && ["adj","adv","num","noun","vrb"].indexOf(this.props.children) !== -1) {
      return Theme.darkGreen;
    }
    return "#333";
  }

  @computed get fontWeight() {
    return this.props.highlightDictionaryWordTypes && ["adj","adv","num","noun","vrb"].indexOf(this.props.children) !== -1 ? 'bold' : 'default';
  }

  render() {
    return (
      <Wrapper
        color={this.color}
        fontWeight={this.fontWeight}
      >
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(FilterButtonText);
