import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { formatNumber } from '@seedlang/utils';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  border: 5px solid ${(props) => props.borderColor};
  background: white;
  margin: ${(props) => props.margin};
  cursor: ${(props) => props.cursor};
  border-radius: 10px;
  width: ${(props) => props.mobile ? "80px" : "130px"};
  overflow: hidden;
`;

const Label = styled.div`
  position: relative;
  background: ${(props) => props.background};
  font-weight: bold;
  width: 100%;
  font-size: ${(props) => props.mobile ? "10px" : "12px"};
  text-align: center;
  color: white;
  height: 23px;
  .info-tooltip {
    position: absolute;
    top: 3px;
    right: 5px;
    font-weight: normal;
  }
`;

const Count = styled.div`
  font-size: ${(props) => props.mobile ? "18px" : "30px"};
  font-weight: bold;
  padding: ${(props) => props.mobile ? "5px" : "20px 10px"};
`;

@observer
class Status extends React.Component {

  static defaultProps = {
    margin: "0 0 10px 0",
  }

  render() {
    return (
      <Wrapper
        onClick={this.props.onClick}
        borderColor={this.props.color}
        mobile={this.props.mobile}
        margin={this.props.margin}
        cursor={this.props.cursor}
      >
        <Label
          background={this.props.color}
          mobile={this.props.mobile}
        >
          {this.props.text}
          {this.props.children}
        </Label>
        {
          Boolean(this.props.showDash) &&
            <Count
              mobile={this.props.mobile}
            >
              -
            </Count>
        }
        {
          Boolean(!this.props.showDash) &&
            <Count
              mobile={this.props.mobile}
            >
              {this.props.count > 0 ? formatNumber(this.props.count) : 0}
            </Count>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Status);
