import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Link } from 'react-router';
import { flexCenter } from '@seedlang/style_mixins';
import { isBlank } from '@seedlang/utils';

const BreadcrumbWrapper = styled.div`
  ${flexCenter()}
  text-transform: uppercase;
`;

const Title = styled.div`
  text-decoration: ${(props) => props.textDecoration};
  padding: ${(props) => props.padding};
  cursor: ${(props) => props.cursor};
  font-size: 14px;
  font-weight: bold;
  color: #333;
`;

@observer
class HeaderBarItem extends React.Component {
  @observable page;

  componentDidMount() {
    if (this.props.lookupPage) {
      const page = window.location.href.split("page/")[1];
      this.page = page;
    }
  }

  render () {
    return (
      <React.Fragment>
        <i className='fa fa-angle-right' />
        <BreadcrumbWrapper>
          <Link
            to={{name: this.props.item[0], params: this.props.lookupPage && isBlank(this.page) ? {page: 1} : {page: this.page}}}
          >
            <Title
              textDecoration="underline"
              padding="0 5px"
            >
              {this.props.item[1]}
            </Title>
          </Link>
        </BreadcrumbWrapper>
      </React.Fragment>
    );
  }
}

export default ErrorBoundary(HeaderBarItem);
