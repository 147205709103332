import React from 'react';
import { observer } from 'mobx-react';
import MembershipOptionsDefault from 'components/membership/membership_options_default';
import MembershipOptions from 'components/membership/membership_options';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import FlashcardDescription from 'components/landing/flashcard_description';
import TreeDescription from 'components/landing/tree_description';
import VocabTrainerDescription from 'components/landing/vocab_trainer_description';
import { AppUI } from '@seedlang/state';
import PromotedRatings from 'components/rating/promoted_ratings';
import SiteComparison from 'components/landing/site_comparison';
import LandingStrip from 'components/landing/landing_strip';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  @media (min-width: 700px) {
    margin: -10px 0 0 0;
  }
`;

@observer
class MembershipIndex extends React.Component {

  componentDidMount() {
    AppUI.createEvent("checkout - memberships");
  }

  render() {
    return (
      <Wrapper>
        {
          AppUI.siteIsDefault &&
            <MembershipOptionsDefault
              showMoneyback
              {...this.props}
            />
        }
        {
          !AppUI.siteIsDefault &&
            <MembershipOptions />
        }
        {
          AppUI.siteIsDefault &&
            <>
              <LandingStrip
                headline="Unlock All Stories"
                description={<span>We tell stories that are fun, surprising, and memorable. This will help to give context to what you are learning and <b>make learning feel effortless.</b></span>}
              >
                <TreeDescription />
              </LandingStrip>
              <LandingStrip
                lightBlue
                headline={<span>Our Flashcards Have <i>Superpowers</i></span>}
                description={<span><b>You've never seen flashcards like these before.</b>  They combine video, speaking practice, and embedded grammar to create a fun and effective learning experience.</span>}
              >
                <FlashcardDescription />
              </LandingStrip>
              <LandingStrip
                headline="Customize Your Learning"
                description={<span>Use our vocab trainer to build flashcard decks tailored to <b>specific vocabulary that you want to learn</b>. Each card is pulled from one of our stories and has all of the fun context that will make this vocabulary easy to remember.</span>}
              >
                <VocabTrainerDescription />
              </LandingStrip>
              <LandingStrip
                darkGray
                headline="How We are Different"
                description={<span>Seedlang brings <b>many new ideas and innovations</b> to app-based language learning. Here is how we stack up against some notable competitors.</span>}
              >
                <SiteComparison />
              </LandingStrip>
              <LandingStrip
                gray
                headline="What our students love about Seedlang!"
              >
                <PromotedRatings />
              </LandingStrip>
            </>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(MembershipIndex);
