import React from 'react';
import { ConjugationTableCardWrapper } from '@seedlang/hoc';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import NextButton from 'components/button/next_button';
import CardButtons from 'components/card/card_buttons';
import { DeckUI } from '@seedlang/state';
import { flexCenterColumn } from '@seedlang/style_mixins';
import Text from 'components/text';
import { Constants } from '@seedlang/constants';

const InnerContent = styled.div`
  background: #FFF;
  border-radius: 10px;
  overflow: auto;
  height: 100%;
  padding: 0 20px;
`;

const Header = styled.div`
  ${flexCenterColumn()}
  padding-top: 20px;
`;

const TableWrapper = styled.div`
  margin-bottom: 10px;
  width: 100%;
  background: whitesmoke;
`;

const Table = styled.div`
  width: 100%;
  border-left: 1px solid #CCC;
  border-right: 1px solid #CCC;
  border-top: 1px solid #CCC;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.background};
  border-bottom: 1px solid #CCC;
`;

const Cell = styled.div`
  flex: 1;
  padding: ${(props) => props.padding || '10px'};
  color: ${(props) => props.color};
  display: flex;
  font-weight: ${props => props.fontWeight};
  text-align: left;
`;

@observer
class ConjugationTableCard extends React.Component {

  render() {
    return (
      <CardWrapper>
        <CardContent
          scroll
        >
          <InnerContent>
            <Header>
              {
                this.props.conjugatedVerbString &&
                  <div>
                    In this deck, we will practice conjugations<br /> in the <b>{this.props.conjugatedVerbString.concept && this.props.conjugatedVerbString.concept.name}</b> of
                  </div>
              }
              <Text
                heading="2"
                italic
                margin="20px 0 0 0"
              >
                "{this.props.word.targetText}"
              </Text>
              <Text
                fontSize="18px"
                bold
                margin="0 0 20px 0"
              >
                {this.props.word.sourceText}
              </Text>
            </Header>
            {
              this.props.conjugatedVerbString &&
                <TableWrapper>
                  <Table>
                    <Row>
                      <Cell>
                        {this.props.conjugatedVerbString.singular1p ? this.props.singular1pPronoun : Constants.PRONOUN_STRINGS[this.props.word.languageId || 'DE'].singular_1p}
                      </Cell>
                      <Cell
                        dangerouslySetInnerHTML={{ __html: this.props.conjugatedVerbString.singular1pDisplay || "" }}
                      />
                    </Row>
                    <Row>
                      <Cell>
                        {Constants.PRONOUN_STRINGS[this.props.word.languageId || 'DE'].singular_2p}
                      </Cell>
                      <Cell
                        dangerouslySetInnerHTML={{ __html: this.props.conjugatedVerbString.singular2pDisplay || "" }}
                      />
                    </Row>
                    <Row>
                      <Cell>
                        {Constants.PRONOUN_STRINGS[this.props.word.languageId || 'DE'].singular_3p}
                      </Cell>
                      <Cell
                        dangerouslySetInnerHTML={{ __html: this.props.conjugatedVerbString.singular3pDisplay || "" }}
                      />
                    </Row>
                    <Row>
                      <Cell>
                        {Constants.PRONOUN_STRINGS[this.props.word.languageId || 'DE'].plural_1p}
                      </Cell>
                      <Cell
                        dangerouslySetInnerHTML={{ __html: this.props.conjugatedVerbString.plural1pDisplay || "" }}
                      />
                    </Row>
                    <Row>
                      <Cell>
                        {Constants.PRONOUN_STRINGS[this.props.word.languageId || 'DE'].plural_2p}
                      </Cell>
                      <Cell
                        dangerouslySetInnerHTML={{ __html: this.props.conjugatedVerbString.plural2pDisplay || "" }}
                      />
                    </Row>
                    <Row>
                      <Cell>
                        {Constants.PRONOUN_STRINGS[this.props.word.languageId || 'DE'].plural_3p}
                      </Cell>
                      <Cell
                        dangerouslySetInnerHTML={{ __html: this.props.conjugatedVerbString.plural3pDisplay || ""  }}
                      />
                    </Row>
                  </Table>
                </TableWrapper>
            }
          </InnerContent>
        </CardContent>
        <CardButtons>
          <NextButton
            disabled={!this.props.inView}
            card={this.props.card}
            cardState={this.props.cardState}
            userCard={this.props.userCard}
            shortcut="1"
            submitOnEnter
            onClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard)}
          />
        </CardButtons>
      </CardWrapper>
    );
  }
}

export default ConjugationTableCardWrapper(ConjugationTableCard);
