import React from 'react';
import { observer } from 'mobx-react';
import { TriviaResultsWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import ProgressBar from 'components/progress_bar';
import { sortBy } from 'lodash';
import { CheckWithCircleIcon } from '@seedlang/icons';
import { Theme } from '@seedlang/constants';
import TranslationWithSource from 'components/translation_with_source';
import { flexCenter } from '@seedlang/style_mixins';
import ReactTooltip from 'react-tooltip';

const Wrapper = styled.div`
  height: 100%;
  padding: 20px 10px;
`;

const Section = styled.div`

`;

const Options = styled.div`
  margin-top: 20px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  border-top: ${(props) => props.borderTop ? "1px solid #d4d4d4" : null};
  padding: ${(props) => props.borderTop ? "10px 0" : "0 0 10px 0"};
  border-bottom: 1px solid #d4d4d4;
  margin-bottom: 10px;
`;

const CheckWrapper = styled.div`
  ${flexCenter()}
  width: 40px;
  svg {
    width: 20px;
    fill: ${Theme.green};
  }
`;

const TextWrapper = styled.div`
  flex: 1;
  text-align: left;
`;

const ProgressBarWithPercentageWrapper = styled.div`
  width: 150px;
  padding: 0 10px 0 0;
`;

const ProgressBarWithPercentage = styled.div`
  display: flex;
`;

const Target = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const Source = styled.div`
  font-size: 20px;
  font-style: italic;
  margin-top: 20px;
`;

const Percentage = styled.div`
  font-weight: bold;
  font-style: italic;
  width: 55px;
  font-size: 12px;
  color: #4e4e4e;
  text-align: right;
  padding: 0 5px 0 0;
`;

const ProgressBarWrapper = styled.div`
  flex: 1;
`;

@observer
class TriviaResults extends React.Component {

  render() {
    return (
      <Wrapper>
        <Section>
          {
            this.props.card && this.props.card.sentence &&
              <Target>
                {this.props.card.sentence.targetText}
              </Target>
          }
          {
            this.props.card && this.props.card.sentence &&
              <Source>
                {this.props.card.sentence.sourceText}
              </Source>
          }
          <Options>
            {
              this.props.card.sentence && sortBy(this.props.card.sentence.randomMultipleChoiceOptions, ['selectedCount']).reverse().map((item, index) => {
                return (
                  <Option
                    borderTop={index === 0}
                    key={item.id}
                  >
                    <CheckWrapper>
                      {
                        item.correct &&
                          <CheckWithCircleIcon />
                      }
                    </CheckWrapper>
                    <TextWrapper>
                      <TranslationWithSource
                        translation={item}
                        showTargetText
                        languageButtonWrapperWidth="27px"
                      />
                    </TextWrapper>
                    <ProgressBarWithPercentageWrapper
                      data-tip
                      data-for={item.id}
                    >
                      <ProgressBarWithPercentage>
                        <Percentage>
                          {this.props.totalSelectedCount > 0 ? ((item.selectedCount / this.props.totalSelectedCount) * 100).toFixed(1) : 0}%
                        </Percentage>
                        <ProgressBarWrapper
                        >
                          <ProgressBar
                            borderRadius="10px"
                            background="#E8E8E8"
                            current={item.selectedCount}
                            total={this.props.totalSelectedCount}
                            height="20px"
                          />
                        </ProgressBarWrapper>
                      </ProgressBarWithPercentage>
                      <ReactTooltip
                        type="dark"
                        effect="solid"
                        id={item.id}
                        class="custom-tooltip"
                      >
                        {item.selectedCount} of {this.props.totalSelectedCount} Answers
                      </ReactTooltip>
                    </ProgressBarWithPercentageWrapper>
                  </Option>
                )
              })
            }
          </Options>
        </Section>
      </Wrapper>
    );
  }
}

export default TriviaResultsWrapper(TriviaResults);
