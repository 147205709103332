import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import VocabSentences from 'components/vocab/vocab_sentences';
import VocabLabels from "components/vocab/vocab_labels";
import VocabWordInfoList from "components/vocab/vocab_word_info_list";
import { VocabWordWrapper } from "@seedlang/hoc";
import VocabWordConcepts from "components/vocab/vocab_word_concepts";
import { AppUI } from "@seedlang/state";
import { Link } from "react-router";
import LearnedButton from "components/review/learned_button";

const Wrapper = styled.div`
  padding: 10px 10px 30px 10px;
`;

const VocabInfo = styled.div`
`;

const AdminLink = styled.div`
  font-size: 11px;
  text-decoration: underline;
  text-align: center;
  padding: 10px 5px 0 5px;
`;

const LearnedWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

@observer
class VocabCard extends React.Component {
  @observable showVocabSentences = false;

  constructor(props) {
    super(props);
    setTimeout(() => this.showVocabSentences = true, 300);
  }

  render() {
    return (
      <Wrapper>
        <VocabInfo>
          <VocabLabels
            flexDirection='row'
            position='static'
            word={this.props.word}
          />
          <LearnedWrapper>
            <LearnedButton
              word={this.props.word}
            />
          </LearnedWrapper>
          <VocabWordInfoList
            {...this.props}
            word={this.props.word}
          />
          {
            this.props.word.concepts?.length > 0 &&
              <VocabWordConcepts
                word={this.props.word}
                expandedId={this.props.expandedId}
                onClickRow={this.props.onClickRow}
              />
          }
          {
            AppUI.user.admin && this.props.word && this.props.word.id &&
            <AdminLink>
              <Link
                to={{name: 'builder.words.edit', params: {wordId: this.props.word.id}}}
                target="_blank"
              >
                Admin Link
              </Link>
            </AdminLink>
          }
        </VocabInfo>
        <h3 style={{marginTop: '20px', paddingTop: '10px', borderTop: '1px solid #CCC'}}>Sentences</h3>
        {
          this.showVocabSentences &&
            <VocabSentences
              wordId={this.props.word.id}
              showSentenceSubmissions
              expandSentenceSubmissionCreate
            />
        }
      </Wrapper>
    );
  }
}

export default VocabWordWrapper(VocabCard);
