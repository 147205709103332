import React from 'react';
import { observer } from 'mobx-react';
import ModalWrapper from 'components/hoc/modal_wrapper';
import { observable, computed } from 'mobx';
import { WordAssociationStore } from '@seedlang/stores';
import { TranslationSourceStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import WordSourceEditChild from 'pages/builder/sources/word_source_edit_child';
import WordSourceEditRoot from 'pages/builder/sources/word_source_edit_root';
import { WordStore } from '@seedlang/stores';
import Spinner from 'components/spinner';

@observer
class WordSourceEditModal extends React.Component {
  @observable defaultSource = this.props.wordAssociation && this.props.wordAssociation.source;

  constructor(props) {
    super(props);
    this.getWord();
  }

  @computed get word() {
    if (this.props.wordAssociation && this.props.wordAssociation.word) {
      return this.props.wordAssociation.word;
    }
    return this.props.word;
  }

  @computed get rootWord() {
    return WordStore.showData;
  }

  @autobind onSelectForWordAssociation(translationSource) {
    this.defaultSource = translationSource.source;
    WordAssociationStore.update({data: {sourceId: translationSource.source.id}, ids: {wordAssociationId: this.props.wordAssociation.id}}, this.props.onChange);
  }

  @autobind updateTranslationSource(translationSource, params) {
    TranslationSourceStore.update({data: params, ids: {translationSourceId: translationSource.id}}, this.props.onChange.bind(this))
  }

  onRemove(id) {
    TranslationSourceStore.destroy({ ids: { translationSourceId: id } }, this.props.onChange.bind(this));
  }

  @autobind onUpdateScore(translationSourceId, score) {
    TranslationSourceStore.update({data: {score: score}, ids: {translationSourceId: translationSourceId}}, this.getWord)
  }

  @autobind getWord() {
    if (!this.word.root && this.word.rootWord) {
      // removed
    } else {
      WordStore.getShow({queryStrings: {expand_translation_sources: true}, ids: {wordId: this.word.id}});
    }
  }

  @autobind onChange() {
    if (this.props.onChange) {
      this.props.onChange();
    }
    this.getWord();
  }

  render() {
    return (
      <div className="word-source-edit-modal">
        {
          !WordStore.hasShowData && !this.props.scopedToSentence &&
            <Spinner />
        }
        {
          this.props.scopedToSentence &&
            <WordSourceEditChild
              onChange={this.onChange}
              wordAssociation={this.props.wordAssociation}
              defaultSource={this.defaultSource}
              onSelectForWordAssociation={this.onSelectForWordAssociation}
              onRemove={this.onRemove}
            />
        }
        {
          WordStore.hasShowData && !this.props.scopedToSentence &&
            <WordSourceEditRoot
              margin={this.props.scopedToSentence ? "20px 0 0 0" : "0"}
              onChange={this.onChange}
              word={WordStore.showData}
              defaultSource={this.defaultSource}
              onSelectForWordAssociation={this.onSelectForWordAssociation}
              onRemove={this.onRemove}
              onUpdateScore={this.onUpdateScore}
              languageId={this.props.languageId}
              worksheetWord={this.props.worksheetWord}
            />
        }
      </div>
    );
  }
}

export default ModalWrapper(WordSourceEditModal, {width: "700px"});
