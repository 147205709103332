import React from 'react';
import { observer } from 'mobx-react';
import { AppUI, ChatUI } from '@seedlang/state';
import ChatIndex from 'components/chat/chat_index';
import { last } from 'lodash';
import PageOnboarding from 'components/onboarding/page_onboarding';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const Left = styled.div`
  position: relative;
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
`;

const AddButton = styled.div`
  font-size: 31px;
  cursor: pointer;
  position: absolute;
  top: 12px;
  z-index: 3;
  right: 10px;
`;

const Right = styled.div`
  flex: 1;
`;

@observer
class Chat extends React.Component {

  constructor(props) {
    super(props);
    if (this.props.children) {
      ChatUI.loadChatIndex();
    } else {
      ChatUI.loadChatIndexAndForward();
    }
  }

  componentDidMount() {
    this.routeToFirstChat();
  }

  componentDidUpdate(prevProps, prevState) {
    this.routeToFirstChat();
  }

  routeToFirstChat() {
    if (!ChatUI.layout.isMobile && !this.props.children) {
      ChatUI.routeToFirstChat();
    }
  }

  childRoute() {
    return last(this.props.routes).name;
  }

  _pageOnboarding() {
    if (AppUI.userIsLoaded && AppUI.user.xpLevel < 2 && !AppUI.wasUiElementSeen('chat_page')) {
      return (
        <PageOnboarding
          uiElement="chat_page"
          margin="10px"
        >
          {
            AppUI.user.nativeSpeaker &&
              <span>
                <b>Chat</b> allows students to leave video, audio, or text chats either privately or in site-wide chats.  Our students would love to get your feedback and corrections as a <b>native speaker</b>.  This feedback will eventually be rewarded with a revenue-sharing program, contact us if you are interested in learning more.
              </span>
          }
          {
            !AppUI.user.nativeSpeaker &&
              <span>
                <b>Chat</b> is important because it gives you a chance to use the words that you've learned in conversation.  We encourage you to use video or audio chat as much as possible to work on your spoken German.  This is the best way to improve your fluency!
              </span>
          }
        </PageOnboarding>
      )
    }
  }

  render() {
    return (
      <Wrapper>
        <Left
          width={AppUI.layout.isMobile ? "100%" : "300px"}
          margin={AppUI.layout.isMobile ? "0 0 10px 0" : "0 10px 0 0"}
        >
          <AddButton
            onClick={() => AppUI.gotoCreateChat()}
          >
            +
          </AddButton>
          {ChatUI.layout.isMobile && this._pageOnboarding()}
          <ChatIndex
            {...this.props}
          />
        </Left>
        {
          !ChatUI.layout.isMobile &&
            <Right>
              {this.props.children}
            </Right>
        }
      </Wrapper>
    );
  }
}

export default Chat;
