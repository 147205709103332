import React from 'react';
import styled from '@emotion/styled';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { observer } from 'mobx-react';
import PrivacySettingsContent from 'components/privacy_settings_content.jsx'
const Wrapper = styled.div`

`;

@observer
class SettingsPrivacy extends React.Component {

  render () {
    return (
      <Wrapper>
        <PrivacySettingsContent />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SettingsPrivacy);
