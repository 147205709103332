import React from 'react';
import { observer } from 'mobx-react';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { SiteNoticeStore } from '@seedlang/stores';

@observer
class CreatorEdit extends React.Component {

  constructor(props) {
    super(props);
    SiteNoticeStore.getShow({ids: {siteNoticeId: this.props.params.siteNoticeId}});
  }

  onChange() {
    SiteNoticeStore.getShow({ids: {siteNoticeId: this.props.params.siteNoticeId}});
  }

  render() {
    return (
      <div className='creator-edit'>
        <div className="row">
          <div className="col-xs-12">
            <fieldset>
              <legend>Title</legend>
              <InPlaceText
                defaultValue={SiteNoticeStore.showDataField('title')}
                model="site_notices"
                field="title"
                id={SiteNoticeStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <fieldset>
              <legend>Message</legend>
              <InPlaceText
                newLineToBr
                defaultValue={SiteNoticeStore.showDataField('text')}
                model="site_notices"
                field="text"
                inputType="textarea"
                id={SiteNoticeStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <fieldset>
              <legend>Onboarding</legend>
              <InPlaceCheckbox
                model="site_notices"
                field="onboarding"
                value={SiteNoticeStore.showDataField('onboarding')}
                id={SiteNoticeStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default CreatorEdit;
