import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import VideoPlayersWithType from 'pages/builder/video_clips/video_players_with_type';
import { VideoClipStore } from '@seedlang/stores';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import autobind from 'autobind-decorator';
import CheckIfTrue from 'components/check_if_true';

const Wrapper = styled.div`

`;

@observer
class VideoClipEdit extends React.Component {

  constructor(props) {
    super(props);
    this.getVideoClip();
  }

  @autobind getVideoClip() {
    VideoClipStore.getShow({ids: {videoClipId: this.props.params.videoClipId}});
  }

  @autobind update(data) {
    VideoClipStore.update({ids: {videoClipId: this.props.params.videoClipId}, data: data}, this.getVideoClip);
  }

  render() {
    return (
      <Wrapper>
        <div className='row'>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Video Clip</legend>
              <VideoPlayersWithType
                videoClip={VideoClipStore.showData}
                width="150px"
              />
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Translation</legend>
              {VideoClipStore.showData.videoable && VideoClipStore.showData.videoable.text}
              {VideoClipStore.showData.cleared && " (Cleared)"}
            </fieldset>
          </div>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Size</legend>
              {VideoClipStore.showData.formattedFileSize}
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Normalized Audio</legend>
              <CheckIfTrue
                value={VideoClipStore.showData.audioIsNormalized}
              />
              {
                VideoClipStore.showData.audioIsNormalized &&
                  <button
                    className='gray-button'
                    onClick={() => this.update({audio_is_normalized: false})}
                  >
                    Restore Source Audio
                  </button>
              }
              {
                !VideoClipStore.showData.audioIsNormalized &&
                  <button
                    className='gray-button'
                    onClick={() => this.update({audio_is_normalized: true})}
                  >
                    Normalize Audio
                  </button>
              }
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Replace</legend>
              {
                VideoClipStore.showData && VideoClipStore.showData.videoable &&
                  <InPlaceCheckbox
                    model="targets"
                    field="replace_video"
                    id={VideoClipStore.showData.videoable.id}
                    value={VideoClipStore.showData.videoable.replaceVideo}
                    icon="check"
                    afterChange={this.props.afterChange}
                  />
              }
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Loading Info</legend>
              <div>Average Load (Android): {VideoClipStore.showData.averageLoadTimeAndroid}</div>
              <div>Average Load (iOS): {VideoClipStore.showData.averageLoadTimeIos}</div>
              <div>Timed Out (Android): {VideoClipStore.showData.numberTimedOutAndroid}</div>
              <div>Timed Out (iOS): {VideoClipStore.showData.numberTimedOutIos}</div>
            </fieldset>
          </div>
        </div>

      </Wrapper>
    );
  }
}

export default ErrorBoundary(VideoClipEdit);
