import React from 'react';
import ReactTooltip from 'react-tooltip';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
    text-align: center;
    padding-right: 12px;
`


const IconWrapper = styled.div`
  color: ${props => props.color};
  font-size: 20px;
`

const PartialMatchIconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

@observer
class WordAssociationSmartyReport extends React.Component {

    @computed get missingMatch() {
        return this.props.smartyWordAssociation === null;
    }

    @computed get fullMatch() {
        return this.props.smartyWordAssociation && this.props.smartyWordAssociation.smartyWord.id === this.props.wordAssociation.word.id
    }

    @computed get partialMatch() {
        return this.props.smartyWordAssociation && this.props.smartyWordAssociation.smartyWord.id !== this.props.wordAssociation.word.id
    }

    @computed get differences() {
        let diff = [];
        const word = this.props.wordAssociation.word;
        const smartyWord = this.props.smartyWordAssociation.smartyWord;
        if (smartyWord.concepts.map(item => item.name).join(",") !== word.concepts.map(item => item.name).join(",")) {
          diff.push(smartyWord.concepts.map(item => item.name).join(","))
        }
        if (word.rootWord && smartyWord.rootWord && word.rootWord?.targetText !== smartyWord.rootWord?.targetText) {
          diff.push(`Root word: ${smartyWord.rootWord.targetText}`);
        }
        if (word?.wordType?.id !== smartyWord?.wordType?.id) {
          diff.push(`Word Type: ${smartyWord.wordType.name}`);
        }
        return diff
    }

    render() {
        return (
            <Wrapper>
                {
                  this.fullMatch &&
                    <IconWrapper
                      color="green"
                    >
                      <i className='fa fa-check-circle' />
                    </IconWrapper>
                }
                {
                  this.missingMatch &&
                    <IconWrapper
                      color='#FF5252'
                    >
                      <i className='fa fa-minus-circle' />
                    </IconWrapper>
                }
                {
                  this.partialMatch &&
                    <PartialMatchIconWrapper
                    data-for={`smarty-partial-match-${this.props.wordAssociation.id}`}
                    data-tip
                    >
                      <IconWrapper
                        color="#333"
                      >
                        <i className='fa fa-exclamation-triangle' />
                      </IconWrapper>
                      <ReactTooltip
                          place="top"
                          type="dark"
                          effect="solid"
                          id={`smarty-partial-match-${this.props.wordAssociation.id}`}
                          class="custom-tooltip"
                      >
                          <strong>Smarty Word</strong>
                          <ul>
                            {
                              this.differences.map((item, index) => {
                                return <li key={ index }>{ item }</li>
                              })
                            }
                          </ul>
                      </ReactTooltip>
                    </PartialMatchIconWrapper>
                }
            </Wrapper>
        );
    }
}

export default WordAssociationSmartyReport;
