import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import DeleteButton from 'components/button/delete_button';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { LanguageIcon } from '@seedlang/icons';
import Button from 'components/button/button';
import { noop, sortBy } from 'lodash';
import { AppUI } from '@seedlang/state';
import { Theme } from '@seedlang/constants';
import { isBlank, isPresent } from '@seedlang/utils';
import Alert from 'components/alert';
import InPlaceLanguageSelect from 'components/form/in_place_language_select';
import autobind from 'autobind-decorator';
import { WorksheetWordStore } from '@seedlang/stores';
import ReactTooltip from 'react-tooltip';
import InfoTooltip from "components/info_tooltip";
import FlagButton from 'components/button/flag_button';

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  overflow: auto;
  height: 600px;
  table {
    margin-top: 10px;
  }
`;

const LangWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Arrows = styled.div`
  display: flex;
  flex-direction: column;
  i {
    margin: 2px 0;
    color: #333;
    cursor: pointer;
  }
`;

const AddSource = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  width: ${(props) => props.width};
  margin-right: 10px;
`;

@observer
class WordSourceEditRoot extends React.Component {
  @observable displayType = 'english-not-rejected';
  @observable text;
  @observable languageId = 'EN';

  constructor(props) {
    super(props);
    if (isPresent(props.languageId)) {
      this.languageId = props.languageId;
    }
    if (isPresent(props.worksheetWord)) {
      this.displayType = 'english-accepted';
    }
  }

  componentWillUnmount () {

  }


  @computed get translationSources() {
    if (this.displayType === 'all') {
      return sortBy(this.props.word.translationSources, ["source.languageId"]);
    } else if (this.displayType === 'english-expanded') {
      return this.props.word.translationSources.filter(item => item.source && (item.source?.languageId === 'EN' || item.source?.languageId === 'U'));
    } else if (this.displayType === 'english-not-rejected') {
      return this.props.word.translationSources.filter(item => item.source && (item.source?.languageId === 'EN' || item.source?.languageId === 'U') && !item.rejected);
    }  else if (this.displayType === 'english-accepted') {
      return this.props.word.translationSources.filter(item => item.source && (item.source?.languageId === 'EN' || item.source?.languageId === 'U') && !item.rejected && (!item.userId || item.accepted));
    }
    return null;
  }

  @computed get wordAttributes() {
    return [this.props.word.targetText, this.props.word.level && this.props.word.level.abbreviation, this.props.word.wordType && this.props.word.wordType.name].filter(item => item);
  }

  @computed get verbHasTranslationSourceWithoutTo() {
    return this.props.word.isVerb
      && (this.displayType === 'english-expanded' || this.displayType === 'english-not-rejected')
      && this.translationSources.some((item) => !item.source?.text.startsWith('to '));
  }

  @autobind onSubmit() {
    const data = {
      user_id: AppUI.user.id,
      force_accepted: true,
      translation_id: this.props.word.id,
      show_in_vocab: !this.props.scopedToSentence,
      text: this.text,
      language_id: this.languageId,
    };
    AppUI.translationSourceStore.create({data: data}, this.afterSubmit);
  }

  @autobind afterSubmit() {
    this.text = null;
    this.props.onChange();
  }

  @autobind translationSourceHidden(translationSource) {
    return isPresent(this.props.worksheetWord.hiddenTranslationSourceIds) && this.props.worksheetWord.hiddenTranslationSourceIds.includes(translationSource.id);
  }

  @autobind toggleHideTranslationSource(translationSource) {
    if (isBlank(this.props.worksheetWord)) { }
    let translationSourceIdsArray = isPresent(this.props.worksheetWord.hiddenTranslationSourceIds) ? this.props.worksheetWord.hiddenTranslationSourceIds.split(',') : [];
    let newTranslationSourceIds;
    if (this.translationSourceHidden(translationSource)) {
      newTranslationSourceIds = translationSourceIdsArray.filter(item => item !== translationSource.id).join(',');
    } else {
      translationSourceIdsArray.push(translationSource.id);
      newTranslationSourceIds = translationSourceIdsArray.join(',');
    }
    this.props.worksheetWord.set('hiddenTranslationSourceIds', newTranslationSourceIds);
    WorksheetWordStore.update({ids: {worksheetWordId: this.props.worksheetWord.id}, data: {hiddenTranslationSourceIds: newTranslationSourceIds}}, noop);
  }
  
  @autobind flagTranslation(translation){
    translation.flaggedForRetranslation = !translation.flaggedForRetranslation;
    AppUI.translationSourceStore.update({ids: {translationSourceId: translation.id}, data: {flaggedForRetranslation: translation.flaggedForRetranslation}}, noop);
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        <h5>Vocab Trainer Source for {this.wordAttributes.join(' | ')}</h5>
        {
          isBlank(this.props.worksheetWord) &&
            <>
              <Button
                fontSize="12px"
                onClick={() => this.displayType = 'english-not-rejected'}
                margin="10px 10px 0 0"
                background={this.displayType === 'english-not-rejected' ? "#FFF" : Theme.blue}
                color={this.displayType === 'english-not-rejected' ? Theme.blue : "#FFF"}
                border={this.displayType === 'english-not-rejected' ? "2px solid #1DB5D7" : "2px solid #FFF"}
              >
                Show English (Not Rejected)
              </Button>
              <Button
                fontSize="12px"
                onClick={() => this.displayType = 'english-expanded'}
                margin="10px 10px 0 0"
                background={this.displayType === 'english-expanded' ? "#FFF" : Theme.blue}
                color={this.displayType === 'english-expanded' ?  Theme.blue : "#FFF"}
                border={this.displayType === 'english-expanded' ? "2px solid #1DB5D7" : "2px solid #FFF"}
              >
                Show English (All)
              </Button>
              <Button
                fontSize="12px"
                onClick={() => this.displayType = 'all'}
                margin="10px 10px 0 0"
                background={this.displayType === 'all' ? "#FFF" : Theme.blue}
                color={this.displayType === 'all' ?  Theme.blue : "#FFF"}
                border={this.displayType === 'all' ? "2px solid #1DB5D7" : "2px solid #FFF"}
              >
                Show All
              </Button>
            </>
        }
        <table className="table-wrapper admin-table">
          <thead>
            <tr>
              <th />
              { isBlank(this.props.worksheetWord) && <th>Score</th> }
              <th>Source</th>
              <th>British variant</th>
              <th>User</th>
              { isBlank(this.props.worksheetWord) && <th>Lang</th> }
              {
                (this.props.word.isNoun || this.props.word.isPronoun) &&
                <th>Display gender
                  <InfoTooltip position="bottom">
                    <div>Check this option if the source needs to specify the gender to be correct.</div>
                    <div style={{fontStyle: 'italic'}}>eg. die Lehrerin - teacher (f.)</div>
                    <div>This is mostly relevant for gendered nouns referring to people,</div>
                    <div>when translated to a language that doesn't have this nuance (eg. English).</div>
                  </InfoTooltip>
                </th>
              }
              {
                isPresent(this.props.worksheetWord) &&
                <th>Hide</th>
              }
              <th width="30" />
              <th width="30" />
            </tr>
          </thead>
          <tbody>
            {
              this.translationSources.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Arrows>
                        <i
                          className='fa fa-arrow-up'
                          onClick={() => this.props.onUpdateScore(item.id, item.score + 1)}
                        />
                        <i
                          className='fa fa-arrow-down'
                          onClick={() => this.props.onUpdateScore(item.id, item.score - 1)}
                        />
                      </Arrows>
                    </td>
                    {
                      isBlank(this.props.worksheetWord) &&
                        <td>
                          {item.score}
                        </td>
                    }
                    <td
                      style={{paddingLeft: 10}}
                    >
                      {`${item.source.text}${item.source.displayGender ? this.props.word.genderAbbreviation : ''}`}
                    </td>
                    <td
                      style={{paddingLeft: 10}}
                    >
                      {
                        item.source?.languageId === 'EN' &&
                          <InPlaceText
                              placeholder="No British variant"
                              defaultValue={item.source.ukSpelling}
                              model="sources"
                              field="ukSpelling"
                              submitOnEnter={true}
                              rejectBlank={false}
                              id={item.source.id}
                              suffix={item.source && item.source.displayGender ? this.props.word.genderAbbreviation : ''}
                          />
                      }
                    </td>
                    <td>
                      {
                        isPresent(item.userId) &&
                          <div style={{display: 'flex'}}>
                            {
                              isBlank(this.props.worksheetWord) &&
                              <>
                                <InPlaceCheckbox
                                  icon="check"
                                  iconStyle={{margin: "0 5px 0 0"}}
                                  id={item.id}
                                  value={item.accepted}
                                  model="translation_sources"
                                  field="accepted"
                                  afterChange={this.props.onChange}
                                  disabled={false}
                                />
                                <InPlaceCheckbox
                                  icon="times"
                                  iconStyle={{margin: "0 5px 0 0"}}
                                  id={item.id}
                                  value={item.rejected}
                                  model="translation_sources"
                                  field="rejected"
                                  afterChange={this.props.onChange}
                                  disabled={false}
                                />
                              </>
                            }
                            {item.user.name}{isPresent(item.user.role) ? ` (${item.user.role})` : ''}
                          </div>
                      }
                      {
                        isBlank(item.userId) && item.translatedByAi &&
                        <div>AI</div>
                      }
                    </td>
                    {
                      isBlank(this.props.worksheetWord) &&
                        <td>
                          {
                            item.source && item.source?.languageId &&
                            <LangWrapper>
                              <LanguageIcon
                                languageId={item.source.languageId}
                                showTooltip
                                margin="0 5px 0 0"
                              />
                              {item.source.languageId}
                            </LangWrapper>
                          }
                        </td>
                    }
                    {
                      (this.props.word.isNoun || this.props.word.isPronoun) &&
                        <td>
                          <InPlaceCheckbox
                            icon="check"
                            iconStyle={{margin: "0 5px 0 0"}}
                            id={item.source.id}
                            value={item.source.displayGender}
                            model="sources"
                            field="display_gender"
                            afterChange={this.props.onChange}
                          />
                        </td>
                    }
                    {
                      isPresent(this.props.worksheetWord) &&
                      <td>
                        <span
                          data-tip
                          data-for={`${item.id}-hide`}
                        >
                          <InPlaceCheckbox
                            icon="check"
                            value={this.translationSourceHidden(item)}
                            onChange={() => this.toggleHideTranslationSource(item)}
                          />
                          <ReactTooltip
                            place="top"
                            type="dark"
                            effect="solid"
                            id={`${item.id}-hide`}
                            class="custom-tooltip"
                          >
                            Hide this source for this worksheet
                          </ReactTooltip>
                        </span>
                      </td>
                    }
                    <td>
                      <FlagButton 
                        onConfirm={()=>this.flagTranslation(item)}
                        flagged={item.flaggedForRetranslation}
                        />
                    </td>
                    <td>
                      {
                        (isBlank(this.props.worksheetWord) || item.user?.id === AppUI.user?.id || AppUI.user.admin) &&
                          <DeleteButton
                            onConfirm={this.props.onRemove.bind(this, item.id)}
                            message="Remove this source from this word?"
                            className="fa-block right"
                          />
                      }
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <AddSource>
          <Column width="300px">
            <InPlaceText
              placeholder="Enter a new source"
              value={this.text}
              onChange={(val) => this.text = val}
              style={{ padding: '20px 10px' }}
              show
            />
          </Column>
          {
            isBlank(this.props.languageId) &&
              <Column
                width="150px"
              >
                <InPlaceLanguageSelect
                  onChange={(val) => this.languageId = val}
                  value={this.languageId}
                  showAbbreviation
                  showEdit
                />
              </Column>
          }
          <Column
            width="80px"
          >
            <button
              onClick={this.onSubmit}
              className="button-primary"
            >Submit</button>
          </Column>
        </AddSource>
        {
          this.verbHasTranslationSourceWithoutTo &&
            <Alert
              margin="5px 0 0 0"
              background={Theme.orange}
            >
              Please use "to " as the prefix for verb translations.
            </Alert>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WordSourceEditRoot);
