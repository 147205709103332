import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Link } from 'react-router';
import { PatreonUserStore } from '@seedlang/stores';
import UserSearch from 'components/user_search';
import autobind from 'autobind-decorator';
import { without } from 'lodash';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';

const Wrapper = styled.div`

`;

const UserRow = styled.div`
  background: white;
  border-bottom: 1px solid #CCC;
  display: flex;
  padding: 5px;
`;

const Email = styled.div`
  flex: 1;
`;

const UserSearchWrapper = styled.div`
  margin-top: ${(props) => props.marginTop};
`;

@observer
class PatreonUsersEdit extends React.Component {

  constructor(props) {
    super(props);
    this.loadPatreonUser();
  }

  @autobind onAddSeedlangUser(user) {
    let userIds = PatreonUserStore.showData.users.map(item => item.id) || [];
    userIds.push(user.id);
    PatreonUserStore.update({ids: {patreonUserId: this.props.params.patreonUserId}, data: {user_ids: userIds}}, this.loadPatreonUser);
  }

  @autobind onRemoveSeedlangUser(user) {
    let userIds = PatreonUserStore.showData.users.map(item => item.id) || [];
    userIds = without(userIds, user.id);
    PatreonUserStore.update({ids: {patreonUserId: this.props.params.patreonUserId}, data: {user_ids: userIds}}, this.loadPatreonUser);
  }

  @autobind loadPatreonUser() {
    PatreonUserStore.getShow({ids: {patreonUserId: this.props.params.patreonUserId}});
  }

  render() {
    return (
      <Wrapper>
        <div className='breadcrumbs' style={{marginBottom: 20}}>
          <Link
            to={{name: 'creator.groups.patreon_users.index', params: {groupId: this.props.params.groupId}}}
          >
            All Patreon Users
          </Link>
        </div>
        {
          PatreonUserStore.hasShowData &&
            <div>
              <div
                className='row'
              >
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Patreon Email</legend>
                    {PatreonUserStore.showData.email}
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>First</legend>
                    {PatreonUserStore.showData.firstName}
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Last</legend>
                    {PatreonUserStore.showData.lastName}
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Seedlang Users</legend>
                    {
                      PatreonUserStore.showData.users && PatreonUserStore.showData.users.map(item => {
                        return (
                          <UserRow
                            key={item.id}
                          >
                            <Email>
                              <Link
                                to={{name: "creator.groups.users.edit", params: {groupId: this.props.params.groupId, userId: item.id}}}
                              >
                                {item.email}
                              </Link>
                            </Email>
                            <i
                              className='fa fa-times'
                              style={{marginLeft: 5, cursor: 'pointer'}}
                              onClick={() => this.onRemoveSeedlangUser(item)}
                            />
                          </UserRow>
                        )
                      })
                    }
                    <UserSearchWrapper
                      marginTop={PatreonUserStore.showData.users && PatreonUserStore.showData.users.length > 0 ? "10px" : 0}
                    >
                      <UserSearch
                        onSubmit={this.onAddSeedlangUser}
                        placeholder="Add Seedlang User"
                      />
                    </UserSearchWrapper>
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Reward Title</legend>
                    <InPlaceText
                      defaultValue={PatreonUserStore.showData.rewardTitle}
                      model="patreon_users"
                      field="reward_title"
                      id={PatreonUserStore.showData.id}
                      afterChange={this.loadPatreonUser}
                    />
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Active Patron</legend>
                    <InPlaceCheckbox
                      icon="check"
                      model="patreon_users"
                      id={PatreonUserStore.showData.id}
                      afterChange={this.loadPatreonUser}
                      field="is_patreon_active"
                      value={PatreonUserStore.showData.isPatreonActive}
                    />
                  </fieldset>
                </div>
              </div>
            </div>
        }
        <div style={{marginTop: 20, fontWeight: 'bold', fontSize: 12}}>
          This data comes from Patreon. If you want to edit access, assign this Patreon user to a Seedlang User and edit the Seedlang User.
        </div>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(PatreonUsersEdit);
