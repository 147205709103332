import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Navigation from 'components/navigation';
import { last } from 'lodash';

const Wrapper = styled.div`

`;

@observer
class Dashboard extends React.Component {

  render() {
    return (
      <Wrapper>
        <Navigation
          currentRoute={last(this.props.routes).name}
          smallButtons
          links={[
            {
              link: 'builder.dashboard.analytics',
              title: 'Analytics',
            },
            {
              link: 'builder.dashboard.didactics',
              title: 'Didactics',
            },
            {
              link: 'builder.dashboard.subscriptions',
              title: 'Subscriptions',
            },
            {
              link: 'builder.dashboard.users',
              title: 'Users',
            },
            {
              link: 'builder.dashboard.decks',
              title: 'Decks',
            },
            {
              link: 'builder.dashboard.levels',
              title: 'Levels',
            },
          ]}
        />
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Dashboard);
