"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getYoutubeEmbedUrl;
var _index = require("./index");
function getYoutubeEmbedUrl(youtubeUrl) {
  var youtubeVideoId;
  if ((0, _index.isPresent)(youtubeUrl) && youtubeUrl.match('watch')) {
    var regexp = /watch\?v=(...........)/g;
    var match = regexp.exec(youtubeUrl);
    if (match) {
      youtubeVideoId = match[1];
    }
  }
  if ((0, _index.isPresent)(youtubeUrl) && youtubeUrl.match('watch') && (0, _index.isPresent)(youtubeVideoId)) {
    return "https://www.youtube.com/embed/".concat(youtubeVideoId);
  } else {
    return youtubeUrl;
  }
  return null;
}