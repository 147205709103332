import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { times } from 'lodash';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${props => props.margin};
`;

const Dot = styled.div`
  background: ${props => props.background};
  height: 8px;
  width: 8px;
  border-radius 8px;
  margin: 0 3px;
`;

@observer
class DotProgress extends React.Component {

  render () {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        {
          times(this.props.numDots, (num) => {
            return (
              <Dot
                background={this.props.currentDot === num + 1 ? '#333' : '#CCC'}
                key={num}
              />
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(DotProgress);
