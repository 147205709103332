import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import Module from 'components/module';
import DailyReviewSummaryChart from 'components/review/daily_review_summary_chart';
import ProgressTriangleWithLevel from 'components/progress_triangle_with_level';
import DailyActivity from 'components/daily_activity/daily_activity';
import Contact from 'pages/main/contact';
import BadgeCollection from 'components/badge_collection';

const Wrapper = styled.div`
  margin: 0;
  width: 100%;
`;

@observer
class ReviewProgress extends React.Component {

  @computed get chartWidth() {
    if (AppUI.routeStore.routeName !== 'reviews.progress') {
      return 240;
    } else {
      return Math.min(900, AppUI.layout.viewportWidth - 140);
    }
  }

  render() {
    return (
      <Wrapper
        id="review-extras"
      >
        {
          AppUI.userIsLoaded && AppUI.targetLanguage &&
            <Module
              title="My Level"
              padding="10px 0 10px 0"
            >
              <ProgressTriangleWithLevel
                user={AppUI.user}
              />
            </Module>
        }
        {
          AppUI.userIsLoaded &&
            <Module
              title="My Activity"
              padding="10px 20px 20px 32px"
            >
              <DailyActivity
                store={AppUI.authUserDailyXpCountStore}
                dailyXpGoal={AppUI.user?.dailyXpGoal}
                userId={AppUI.user?.id}
                todaysDate={AppUI.user?.todaysDate}
              />
            </Module>
        }
        <Module
          title='My Reviews Progress'
          padding="0 15px 10px 15px"
          center
        >
          {
            this.chartWidth &&
              <DailyReviewSummaryChart
                width={this.chartWidth}
                limit={AppUI.routeStore.routeName === 'reviews.progress' ? 50 : 20}
              />
          }
        </Module>
        {
          AppUI.userIsLoaded &&
            <Module
              title="My Badges"
              padding="0 20px 10px 20px"
            >
              <BadgeCollection
                user={AppUI.user}
                badges={["deckmaster", "backInTime"]}
                showProgress
                width="70px"
                badgeWidth="100%"
              />
            </Module>
        }
        {
          AppUI.userIsLoaded && AppUI.routeStore.routeName !== 'reviews.progress' &&
            <Module
              title="Contact us"
            >
              <Contact
                hideLabels
                hideReset
                autoExpand={false}
                message="Contact us with any questions or feedback that you might have."
              />
            </Module>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ReviewProgress);
