import React from 'react';
import { LanguageIcon } from '@seedlang/icons';
import HalfCircleCurvedArrow from 'components/svg/half_circle_curved_arrow';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  height: ${(props) => props.width};
  position: relative;
  cursor: pointer;
`;

const ArrowWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: ${Theme.z["foreground"]};
  width: ${(props) => props.width};
  height: ${(props) => props.width};
  transform: scale(1.01);
  svg {
    width: ${(props) => props.width};
    .background {
      fill: ${(props) => props.background};
    }
    .arrow {
      fill: ${(props) => props.arrowColor};
    }
  }
`;

class LanguageButton extends React.Component {

  static defaultProps = {
    background: "#FFF",
    arrowColor: "#717171",
    width: "20px",
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
        width={this.props.width}
        onClick={this.props.onClick}
      >
        <LanguageIcon
          languageId={this.props.languageId}
          width={this.props.width}
          useUkFlagForEn={this.props.useUkFlagForEn}
        />
        <ArrowWrapper
          width={this.props.width}
          background={this.props.background}
          arrowColor={this.props.arrowColor}
        >
          <HalfCircleCurvedArrow />
        </ArrowWrapper>
      </Wrapper>
    );
  }
}

export default LanguageButton;
