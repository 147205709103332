import React from 'react';
import { observer } from 'mobx-react';
import ChatCreatorEdit from 'components/chat/chat_creator_edit';
import ChatParticipantEdit from 'components/chat/chat_participant_edit';
import { ChatUI } from '@seedlang/state';
import { CloseIcon } from '@seedlang/icons';
import { Link } from 'react-router';
import styled from '@emotion/styled';
import DesktopTitle from 'components/desktop_title';

const Wrapper = styled.div`
  padding-bottom: 20px;
  .confirmation {
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 200px;
    text-align: center;
    top: -70px;
  }
  .confirmation:before, .confirmation:after {
    display: none;
  }
`;

const Content = styled.div`
  background: white;
  border-radius: 10px;
  padding: 10px;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
`;

@observer
class ChatEdit extends React.Component {

  constructor(props) {
    super(props);
    ChatUI.chatStore.clearShowData();
    if (!ChatUI.chatStore.hasShowData && this.props.route.name !== 'chats.create') {
      ChatUI.initiateChatPoll(this.props.params.chatId);
    }
  }

  render() {
    return (
      <Wrapper>
        {
          !ChatUI.layout.isMobile &&
            <DesktopTitle>
              { this.props.params.chatId ? 'Edit Chat' : 'Create Chat' }
              {
                !ChatUI.layout.isMobile &&
                  <CloseIconWrapper>
                    <Link
                      to={{
                        name: this.props.params.chatId ? 'chats.show' : 'chats.index',
                        params: this.props.params,
                      }}
                    >
                      <CloseIcon />
                    </Link>
                  </CloseIconWrapper>
              }
            </DesktopTitle>
        }
        <Content>
          {
            (!this.props.params.chatId || ChatUI.chatCreatedByMe) &&
              <ChatCreatorEdit
                {...this.props}
              />
          }
          {
            this.props.params.chatId && !ChatUI.chatCreatedByMe &&
              <ChatParticipantEdit
                {...this.props}
              />
          }
        </Content>
      </Wrapper>
    );
  }
}

export default ChatEdit;
