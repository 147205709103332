import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router';
import OutlineButton from 'components/button/outline_button';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.ul`
  white-space: normal;
  margin: ${props => props.margin};
  overflow: hidden;
  li {
    display: inline-block;
  }
`;

@observer
class Navigation extends React.Component {

  static defaultProps = {
    height: "40px",
  }

  matchRoute(item) {
    return this.props.currentRoute && this.props.currentRoute.match(item.link);
  }

  render() {
    return (
      <Wrapper
        margin={this.props.smallButtons ? "5px 0 10px 0" : "0"}
        height={this.props.smallButtons ? "30px" : "40px"}
      >
        {
          this.props.links.map((item) => {
            if (!item.hide) {
              return (
                <li
                  key={item.link}
                >
                  <Link
                    to={{name: item.link, params: item.params}}
                  >
                    <OutlineButton
                      background={Theme.green}
                      selectedBackground='#FFF'
                      selected={this.matchRoute(item)}
                      margin="0 10px 0 0"
                      padding={this.props.smallButtons ? "3px 10px" : "9px 20px"}
                      fontSize={this.props.smallButtons ? "14px" : "17px"}
                      borderRadius={this.props.smallButtons ? "5px" : "40px"}
                    >
                      {item.title}
                    </OutlineButton>
                  </Link>
                </li>
              );
            } else {
              return null;
            }
          })
        }
      </Wrapper>
    );
  }
}

export default Navigation;
