import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI, ExerciseUI } from '@seedlang/state';
import { last, min, startCase } from "lodash";
import autobind from "autobind-decorator";
import { computed } from "mobx";
import Breadcrumbs from "components/breadcrumbs";

const BackButton = styled.span`
  font-weight: bold;
  font-size: 20px;
  color: #a2a2a2;
  cursor: pointer;
  display: flex;
  height: 100%;
  .fa {
    margin-right: 5px;
  }
`;

const Wrapper = styled.div`
  height: 20px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

@observer
class WorksheetBackButton extends React.Component {

  @computed get homeRouteName() {
    return `${this.props.namespace || ExerciseUI.worksheetNamespace}.index`;
  }

  @autobind goBackToPosts() {
    ExerciseUI.routeStore.routeToNamed(this.homeRouteName, {groupId: ExerciseUI.groupId})
  }

  @computed get homeName() {
    return startCase(['videos', 'podcast'].indexOf(ExerciseUI.worksheetNamespace) !== -1 ? ExerciseUI.worksheetNamespace : AppUI.user?.site?.navigationItems?.find(item => item.routeName?.startsWith(ExerciseUI.worksheetNamespace))?.name || this.props.namespace || 'Posts');
  }

  @computed get breadCrumbs() {
    const breadCrumbs = [
      {name: this.homeName, routeToName: this.homeRouteName},
    ];
    if (this.props.currentRouteName.endsWith('info.show')) {
      breadCrumbs.push({name: ExerciseUI.worksheet?.nameWithPublished});
    } else {
      breadCrumbs.push({name: ExerciseUI.worksheet?.nameWithPublished, routeToName: `${ExerciseUI.worksheetNamespace}.info.show`, routeToParams: {groupId: ExerciseUI.groupId, worksheetId: ExerciseUI.worksheetId}});
    }
    return breadCrumbs;
  }

  render() {
    if (this.props.showAsBreadcrumbs) {
      return (
        <Breadcrumbs
          breadcrumbs={this.breadCrumbs}
          height={null}
          margin="0 0 10px 0"
        />
      )
    }

    return (
      <Wrapper>
        <BackButton
          background={AppUI.site.accentColor}
          top={ExerciseUI.siteIsDefault ? min([AppUI.layout.viewportHeight - 100, this.worksheetBottom - 55]) : min([AppUI.layout.viewportHeight - 100, this.worksheetBottom - 61])}
          onClick={this.goBackToPosts}
          margin="0 0 0 -40px"
        >
          <i className='fa fa-angle-left'/>
          <span style={{fontSize: '14px', color: "#a2a2a2"}}>{this.homeName}</span>
        </BackButton>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WorksheetBackButton);
