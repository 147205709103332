import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import MarkdownIt from 'markdown-it';
import { computed } from "mobx";
import { isPresent } from "@seedlang/utils/src";

const Wrapper = styled.div`
  table {
    width: 100% !important;
    max-width: 100%;
    margin-bottom: 20px;
    border-spacing: 0px !important;
    border: 1px solid #ccc;
    background: #fff;
    margin: 0;
  }
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
  tr {
    background: #fff;
  }
  th {
    font-size: 12px;
    text-align: left;
  }
  th {
    padding: 3px 8px;
    color: white;
    background: #929292;
  }
  td {
    padding: 8px;
  }
  td, th {
    text-align: left;
    border: 1px solid #ddd;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
  }
  img {
    max-width: 400px;
    max-height: 400px;
    border-radius: 10px;
  }
  a {
    text-decoration: underline;
  }
  p {
    font-size: ${props => props.fontSize};
  }
  ul {
    margin-left: 20px;
  }
  li {
    list-style-type: disc;
    margin-bottom: 0;
  }
`;

@observer
class TextDisplay extends React.Component {

  constructor(props) {
    super(props);
    this.md = new MarkdownIt();
  }

  formatNewline(string) {
    return string.replace(/\\r/g, "<br />");
  }

  @computed get htmlSource() {
    if (this.props.htmlText) {
      return this.props.htmlText;
    } else if (this.props.markdown) {
      return this.formatNewline(this.md.enable(['newline']).render(this.props.markdown));
    } return null;
  }



  render() {
    return (
      <Wrapper
        fontSize={this.props.fontSize}
        style={{ maxHeight: this.props.maxHeight, overflow: this.props.scrollable? 'auto' : 'hidden', margin: this.props.margin }}
      >
        <div dangerouslySetInnerHTML={{ __html: this.htmlSource }} />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TextDisplay);
