import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Link } from 'react-router';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.span`

`;

@observer
class NavigationLink extends React.Component {

  @computed get showLink() {
    return (this.props.whiteLabel && !AppUI.siteIsDefault) || (this.props.siteIsDefault && AppUI.siteIsDefault) || (!this.props.siteIsDefault && !this.props.whiteLabel);
  }

  render () {
    if (this.showLink) {
      return (
        <Wrapper>
          <Link
            {...this.props}
          />
        </Wrapper>
      );
    } else {
      return <span />;
    }
  }
}

export default ErrorBoundary(NavigationLink);
