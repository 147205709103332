import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  border: 2px solid #525252;
  border-radius: 5px;
  font-size: 8px;
  width: 18px;
  height: 18px;
  margin: 0 2px;
  padding: 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

@observer
class LevelTag extends React.Component {

  render() {
    return (
        <Wrapper>
          {this.props.level.abbreviation}
        </Wrapper>
    );
  }
}

export default LevelTag;
