import React from 'react';
import { observer } from 'mobx-react';
import { DeckRatingWrapper } from '@seedlang/hoc';
import { DeckUI } from '@seedlang/state';
import RatingInput from 'components/rating_input';
import ArrowBox from 'components/arrow_box';
import { isBlank, pixify } from '@seedlang/utils';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { flexCenter } from '@seedlang/style_mixins';
import Text from 'components/text';
import Button from 'components/button/button';

const Wrapper = styled.div`
  z-index: ${Theme.z["card-info-2"]};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  bottom: ${(props) => props.bottom};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  textarea {
    height: 120px;
  }
`;

const RatingInputWrapper = styled.div`
  ${flexCenter()}
  flex: 1;
`;

const RatingBar = styled.div`
  background: white;
  display: flex;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const CallToAction = styled.div`
  width: 50%;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  height: ${(props) => props.height};
  background: ${Theme.purple};
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  line-height: 16px;
  border-bottom-left-radius: 10px;
`;

const ArrowBoxContent = styled.div`
  padding: 20px;
`;

@observer
class DeckRating extends React.Component {

  render() {
    return (
      <Wrapper
        height={pixify(DeckUI.layout.userInputHeight)}
        width={pixify(DeckUI.layout.innerDeckWidth)}
        bottom={pixify(DeckUI.layout.audioBottom)}
      >
        {
          this.props.showCommentInput &&
            <ArrowBox
              show
              onClick={() => this.props.setShowCommentInput(false)}
              bottom={pixify(DeckUI.layout.userInputHeight + 15)}
              width={pixify(DeckUI.layout.innerDeckWidth)}
              zIndex={Theme.z["overlay"]}
              padding="20px"
              closeIconTop="18px"
              closeIconRight="10px"
              arrowPosition="bottom"
              left="0"
              right="0"
            >
              <ArrowBoxContent>
                <Text
                  bold
                  margin="0 0 10px 0"
                  center
                >
                  Leave feedback for this deck.
                </Text>
                {
                  !this.props.submittedComment &&
                    <Text
                      italic
                      fontSize="14px"
                      margin="0 0 10px 0"
                      center
                    >
                      Do you have anything to tell us about this deck?  All feedback is appreciated!
                    </Text>
                }
                <textarea
                  value={this.props.comment}
                  placeholder="Enter your comment"
                  onChange={(e) => this.props.setComment(e.target.value)}
                />
                <Button
                  disabled={isBlank(this.props.comment)}
                  margin="10px 0 0 0"
                  onClick={() => this.props.submitComment()}
                >
                  Submit Message
                </Button>
              </ArrowBoxContent>
            </ArrowBox>
        }
        <RatingBar
          height={pixify(DeckUI.layout.userInputHeight)}
          width={pixify(DeckUI.layout.innerDeckWidth)}
        >
          <CallToAction
            height={pixify(DeckUI.layout.userInputHeight)}
          >
            {
              DeckUI.layout.minimumHeight &&
                <div>Rate this deck?</div>
            }
            {
              !DeckUI.layout.minimumHeight &&
                <div>
                  <div>
                    How would you
                  </div>
                  <div>
                    {DeckUI.reviewDeck ? 'rate these reviews?' : 'rate this deck?'}
                  </div>
                </div>
            }
          </CallToAction>
          <RatingInputWrapper>
            <RatingInput
              maxRating={5}
              defaultValue={DeckUI.userDeck.rating}
              slug='overall'
              onClick={this.props.onClickRating}
            />
          </RatingInputWrapper>
        </RatingBar>
      </Wrapper>
    );
  }
}

export default DeckRatingWrapper(DeckRating);
