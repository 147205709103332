import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { isPresent, isBlank } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import { WordStore } from '@seedlang/stores';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceText from 'components/form/in_place_text';
import ReactTooltip from 'react-tooltip';

const Wrapper = styled.div`
  display: flex;
`;

const Selectable = styled.div`
  border: 1px solid transparent;
  &:hover {
    border: 1px dotted gray;
  }
`;

const Input = styled.div`
  .fa {
    cursor: pointer;
    color: #CCC;
  }
`;

@observer
class PluralEdit extends React.Component {
  @observable value;

  @computed get word() {
    if (this.props.wordAssociation && this.props.wordAssociation.word) {
      return this.props.wordAssociation.word;
    }
    return this.props.word;
  }

  @computed get createWord() {
    return this.word.root ? this.word : this.word.rootWord;
  }

  @computed get showInput() {
    return isBlank(this.word.pluralNoun) && !this.word.noPlural;
  }

  @autobind removePlural() {
    WordStore.removePlural({ids: {wordId: this.word.id}}, this.props.onChange);
  }

  @autobind createPlural() {
    if (isPresent(this.value) && this.createWord) {
      WordStore.createPlural({ids: {wordId: this.createWord.id}, data: {target_text: this.value}}, this.props.onChange);
    }
  }

  @autobind onClickNoPlural() {
    WordStore.update({ids: {wordId: this.word.id}, data: {no_plural: true}}, this.props.onChange);
  }

  render() {
    return (
      <Wrapper>
        {
          !this.showInput && !this.word.noPlural && this.word.pluralNoun &&
            <Selectable
              onClick={this.removePlural}
            >
              {this.word.pluralNoun.targetText}
            </Selectable>
        }
        {
          !this.showInput && this.word.noPlural &&
            <div style={{fontSize: 12, fontWeight: 'bold'}}>
              No Plural
            </div>
        }
        {
          this.showInput &&
            <Input>
              <div style={{width: 100, display: "inline-block"}}>
              <InPlaceText
                show
                fontSize="12px"
                submitOnEnter
                placeholder="Enter Root Plural"
                onChange={(val) => this.value = val}
                onSubmit={this.createPlural.bind(this)}
              />
              </div>
              <span
                data-for={`info-${this.word.id}`}
                data-tip
              >
                <InPlaceCheckbox
                  model="words"
                  icon="check"
                  wrapperMargin="4px 0 0 3px"
                  field="plural"
                  value={this.word.plural}
                  id={this.word.id}
                  afterChange={this.props.onChange.bind(this)}
                />
                <ReactTooltip
                  place="top"
                  type="dark"
                  effect="solid"
                  id={`info-${this.word.id}`}
                  class="custom-tooltip"
                >
                  <div>This word is plural</div>
                </ReactTooltip>
              </span>
              <span
                data-for={`no-plural-${this.word.id}`}
                data-tip
              >
                <i
                  className='fa fa-ban'
                  style={{margin: "5px 0 0 3px"}}
                  onClick={this.onClickNoPlural}
                />
                <ReactTooltip
                  place="top"
                  type="dark"
                  effect="solid"
                  id={`no-plural-${this.word.id}`}
                  class="custom-tooltip"
                >
                  This word has no plural
                </ReactTooltip>
              </span>
            </Input>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(PluralEdit);
