import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Paginator from 'components/paginator';
import { PaymentIntentStore } from '@seedlang/stores';
import { Link } from 'react-router';

const Wrapper = styled.div`

`;

@observer
class PaymentIntentIndex extends React.Component {

  constructor(props) {
    super(props);
    PaymentIntentStore.getIndex();
  }

  render() {
    return (
      <Wrapper>
        <table
          className="table-wrapper"
          style={{marginTop: 10}}
        >
          <thead>
            <tr>
              <th>Date</th>
              <th>User</th>
              <th>Membership</th>
              <th>Plan Id</th>
              <th>Subscription</th>
              <th>Amount</th>
              <th>Currency</th>
            </tr>
          </thead>
          <tbody>
            {
              PaymentIntentStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      {item.createdAt.formatted}
                    </td>
                    <td>
                      <Link
                        to={{name: 'builder.users.edit', params: {userId: item.user.id}}}
                      >
                        {item.user.name}
                      </Link>
                    </td>
                    <td>
                      {item.membership.name}
                    </td>
                    <td>
                      {item.planId}
                    </td>
                    <td>
                      {
                        item.subscription &&
                          <Link
                            to={{name: 'builder.subscriptions.edit', params: {subscriptionId: item.subscription.id}}}
                          >
                            {item.subscription.id}
                          </Link>
                      }
                    </td>
                    <td>
                      {item.amount}
                    </td>
                    <td>
                      {item.currency}
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Paginator
          store={PaymentIntentStore}
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(PaymentIntentIndex);
