import React from 'react';
import { observer } from 'mobx-react';
import { StoriesDeckLinkWrapper } from '@seedlang/hoc';
import DeckLink from 'components/deck/deck_link';

@observer
class StoriesDeckLink extends React.Component {

  render() {
    return (
      <DeckLink
        {...this.props}
        progressLabel="Cards Seen"
      />
    );
  }
}

export default StoriesDeckLinkWrapper(StoriesDeckLink);
