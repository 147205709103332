import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import { SentenceConceptStore } from '@seedlang/stores';
import TranslatorSentence from 'pages/translator/translator_sentence';
import Spinner from 'components/spinner';
import autobind from 'autobind-decorator';
import { noop } from 'lodash';
import { Link } from 'react-router';
import { DeckUI } from "@seedlang/state";
import {isPresent} from "@seedlang/utils/src";

const Wrapper = styled.div`
  a {
    display: flex;
    margin: 20px 0 0 0!important;
  }
  h3 {
    margin: 20px 0 0 0!important;
  }
`;

@observer
class TranslatorDecksShow extends React.Component {
  @observable showSpinner = false;
  @observable conceptId;

  constructor(props) {
    super(props);
    if (AppUI.deckStore.hasShowData) {
      this.loadSentences();
    } else {
      this.getDeck();
    }
  }

  componentWillUnmount() {
    if (!AppUI.routeStore.routeName.match('decks.show')) {
      AppUI.deckStore.clearShowData();
    }
    SentenceConceptStore.clearIndexData();
  }

  @autobind getDeck() {
    this.showSpinner = true;
    AppUI.deckStore.getShow({queryStrings: {expanded: true}, ids: {deckId: this.props.params.deckId}}, this.afterGetDeck);
  }

  @autobind afterGetDeck(resp) {
    this.showSpinner = false;
    AppUI.deckStore.setShowData(resp);
    this.loadSentences();
  }

  @autobind loadSentences() {
    SentenceConceptStore.getIndex({ids: {conceptId: AppUI.deckStore.showData.concept.id}});
  }

  @autobind updateLanguagesString(sentenceId) {
    if (this.props.inDeck) {
      const updatedCardInAnimationCards = DeckUI.animationCards.find((item) => item.card.sentence?.id === sentenceId)?.card;
      if (isPresent(updatedCardInAnimationCards)) {
        DeckUI.enrichSentence(updatedCardInAnimationCards, true);
      } else {
        DeckUI.enrichSentence(AppUI.deckStore.showData.cards.find((card) => card.sentence?.id === sentenceId), true);
      }
    } else {
      AppUI.deckStore.updateLanguagesString({ids: {deckId: this.props.params.deckId}}, noop);
    }
  }

  render () {
    return (
      <Wrapper>
        {
          this.showSpinner &&
            <Spinner />
        }
        {
          !this.showSpinner &&
            <div>
              {
                !this.props.inDeck &&
                  <Link
                    to={{name: 'translator.decks_index', params: {languageId: this.props.params.languageId}}}
                    className='underline'
                    style={{margin: "20px 0 0 0"}}
                  >
                    <span role="img">⬅</span>️ All Decks
                  </Link>
              }
              {
                !this.props.inDeck &&
                  <h3>{AppUI.deckStore.showData?.name}</h3>
              }
              {
                SentenceConceptStore.hasIndexData && SentenceConceptStore.indexData.map(item => {
                  return (
                    <TranslatorSentence
                      key={item.id}
                      sentence={item}
                      afterSubmit={() => this.updateLanguagesString(item.id)}
                      hideVideos={this.props.inDeck}
                    />
                  )
                })
              }
            </div>
        }
      </Wrapper>
    );
  }
}

export default TranslatorDecksShow;
