import React from 'react';
import { observer } from 'mobx-react';
import { AppUI, ChatUI } from '@seedlang/state';
import UserProfileImage from 'components/user/user_profile_image';
import styled from '@emotion/styled';
import DesktopTitle from 'components/desktop_title';
import { flexCenter } from '@seedlang/style_mixins';

const Wrapper = styled.div`
  width: 100%;
`;

const Notice = styled.div`
  background: white;
  margin: 20px 0;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
`;

const ChatList = styled.div`
  border-radius: 5px;
  overflow: hidden;
`;

const Row = styled.div`
  ${flexCenter()}
  background: ${(props) => props.selected ? "#DDD" : "#FFF"};
  cursor: pointer;
  height: 68px;
  display: flex;
  position: relative;
  margin: 0;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
  font-size: 14px;
  padding: 0 10px;
  .icon-wrapper {
    border: none;
  }
  .seedlang-icon {
    svg {
      width: 58px;
    }
  }
`;

const DateWrapper = styled.div`
  line-height: 20px;
  font-size: 11px;
  font-style: italic;
  width: 45px;
  text-align: right;
`;

const Content = styled.div`
  line-height: 20px;
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
  align-items: flex-start!important;
  padding: 0 10px;
`;

const ImageWrapper = styled.div`

`;

const Name = styled.div`
  font-weight: bold;
`;

const Title = styled.div`

`;

@observer
class ChatIndex extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          !ChatUI.layout.isMobile &&
            <DesktopTitle>
              Chats
            </DesktopTitle>

        }
        {
          !ChatUI.chatStore.hasIndexData &&
            <Notice>
              You don't have any chats.
            </Notice>
        }
        <ChatList>
          {
            ChatUI.userIsLoaded && ChatUI.chatStore.indexData.map((item) => {
              return (
                <Row
                  selected={!ChatUI.layout.isMobile && this.props.params.chatId === item.id}
                  key={item.id}
                  onClick={() => AppUI.gotoChat(item.id)}
                >
                  <ImageWrapper>
                    <UserProfileImage
                      width={60}
                      user={item.otherUser(ChatUI.user)}
                    />
                  </ImageWrapper>
                  <Content>
                    <Name>
                      {item.feedback ? 'Tutor Chat' : item.chatUserNames(ChatUI.user)}
                    </Name>
                    <Title>
                      {item.title}
                    </Title>
                  </Content>
                  {
                    !item.feedback &&
                      <DateWrapper>
                        <div>
                          {item.createdAt.formattedDate}
                        </div>
                        <div>
                          {item.createdAt.formattedTime}
                        </div>
                      </DateWrapper>
                  }
                </Row>
              )
            })
          }
        </ChatList>
      </Wrapper>
    );
  }
}

export default ChatIndex;
