import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router';
import UserProfileImage from 'components/user/user_profile_image';
import UserLevel from 'components/user/user_level';
import autobind from 'autobind-decorator';
import { isBlank, isPresent, formatComment } from '@seedlang/utils';
import { RouteStore } from '@seedlang/stores';
import { AppUI } from '@seedlang/state';
import DailyActivity from 'components/daily_activity/daily_activity';
import BadgeCollection from 'components/badge_collection';
import LevelBadgeWithFlag from 'components/level_badge_with_flag';
import Button from 'components/button/button';
import TriviaRecord from 'components/trivia/trivia_record';
import DailyReviewSummaryChart from 'components/review/daily_review_summary_chart';
import styled from '@emotion/styled';
import { sortBy } from 'lodash';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardWrapper = styled.div`
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: ${(props) => props.borderRadius};
  border-bottom-right-radius: ${(props) => props.borderRadius};
`;

const UserHeader = styled.section`
  background: #DDD;
  flex-direction: column;
  padding: 20px;
  border-bottom-left-radius: ${(props) => props.borderRadius};
  border-bottom-right-radius: ${(props) => props.borderRadius};
`;

const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  text-align: center;
`;

const NameWrapper = styled.div`
  font-size: 20px;
  margin-bottom: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NameWithLevel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

const Name = styled.div`
  margin-right: 5px;
`;

const Location = styled.div`
  font-size: 14px;
`;

const Introduction = styled.div`
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 18px;
  background: white;
  padding: 10px;
  border-radius: 10px;
`;

const StatWrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.padding};
  border-top: 1px solid white;
`;

const StatInnerWrapper = styled.div`
  flex: 1;
  width: 100%;
  padding-bottom: 20px;
`;

const Label = styled.div`
  text-align: center;
  font-weight: 700;
  background: #ddd;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
`;

const LevelsWrapper = styled.div`
  display: flex;
  justify-content: center;
`

@observer
class ProfileCard extends React.Component {

  @autobind gotoSettings() {
    RouteStore.routeToNamed('settings.user_profile');
  }

  render() {
    if (isPresent(this.props.user)) {
      return (
        <Wrapper>
          <CardWrapper
            borderRadius={AppUI.layout.isMobile ? "0" : "5px"}
          >
            <UserHeader
              borderRadius={AppUI.layout.isMobile ? "0" : "5px"}
            >
              <User>
                <UserProfileImage
                  width={90}
                  margin="0 0 10px 0"
                  anonymousBorder="3px solid white"
                  user={this.props.user}
                />
                {
                  isPresent(this.props.user) && isPresent(this.props.user.name) &&
                    <NameWrapper
                      onClick={this.props.onClickEditUser}
                    >
                      <NameWithLevel>
                        <Name>
                          {this.props.user.name}
                        </Name>
                        {
                            sortBy(this.props.user.targetLanguages.filter(item => ['ES', 'FR', 'DE'].includes(item.languageId)), (item) => item.languageId).map(item => {
                              return (
                                  item.level &&
                                    <UserLevel
                                      level={item.level}
                                      languageId={item.languageId}
                                      key={`${item.languageId}-user-level`}
                                    />
                              )
                            })
                        }
                      </NameWithLevel>
                      <Location>
                        {this.props.user.location}
                      </Location>
                      {
                        AppUI.user.admin &&
                          <Link
                            to={{name: 'builder.users.edit', params: {userId: this.props.user.id}}}
                            style={{fontSize: 12}}
                          >
                            Go to Admin Page
                          </Link>
                      }
                    </NameWrapper>
                }
                {
                  this.props.userIsMe && isBlank(this.props.user.introduction) &&
                    <Button
                      background="#FFF"
                      color="#000"
                      fontSize="14px"
                      onClick={this.gotoSettings}
                    >
                      Edit Profile
                    </Button>
                }
                {
                  !this.props.userIsMe &&
                    <Button
                      background="#FFF"
                      color="#000"
                      fontSize="14px"
                      onClick={this.props.onToggleFollowing}
                    >
                      <i
                        className={this.props.user.followAssociationId ? 'fa fa-minus' : 'fa fa-plus'}
                        style={{margin: "0 3px 0 0", fontSize: 13}}
                      />
                      {this.props.user.followAssociationId ? 'Remove Friend' : 'Add Friend'}
                    </Button>
                }
              </User>
              {
                isPresent(this.props.user.introduction) &&
                  <Introduction
                    onClick={this.props.onClickEditUser}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: formatComment(this.props.user.introduction) }}
                    />
                  </Introduction>
              }
            </UserHeader>
            {
              AppUI.siteIsDefault &&
                <StatWrapper
                  padding="0 0 20px 0"
                >
                  <StatInnerWrapper>
                    <Label>
                      Level
                    </Label>
                    <LevelsWrapper>
                      {
                        isPresent(AppUI.user.targetLanguages) && sortBy(this.props.user.targetLanguages.filter(item => item.xp > 0 || item.languageId === AppUI.targetLanguageId), item => -item.xp).map(targetLanguage => {
                          return (
                            <LevelBadgeWithFlag
                              padding="10px 20px 0 20px"
                              targetLanguage={targetLanguage}
                              key={`${targetLanguage.languageId}-level-badge`}
                            />
                          )
                        })
                      }
                    </LevelsWrapper>
                  </StatInnerWrapper>
                </StatWrapper>
            }
            {
              AppUI.user.admin && AppUI.siteIsDefault &&
                <StatWrapper
                  padding="0 0 40px 0"
                >
                  <StatInnerWrapper>
                    <Label>
                      Review Progress
                    </Label>
                  </StatInnerWrapper>
                  <DailyReviewSummaryChart
                    user={this.props.user}
                    limit={20}
                  />
                </StatWrapper>
            }
            {
              AppUI.siteIsDefault &&
              <StatWrapper
                padding="0 0 40px 0"
              >
                <StatInnerWrapper>
                  <Label>
                    XP Progress
                  </Label>
                </StatInnerWrapper>
                <DailyActivity
                  loadUser
                  store={AppUI.dailyXpCountStore}
                  dailyXpGoal={this.props.user?.dailyXpGoal}
                  userId={this.props.user?.id}
                  todaysDate={this.props.user?.todaysDate}
                />
              </StatWrapper>
            }
            {
              (!this.props.user.hideTriviaRecord || AppUI.user.admin) && (this.props.user.triviaGamesWon > 0 || this.props.user.triviaGamesLost > 0) && AppUI.siteIsDefault &&
                <StatWrapper
                  padding="0 0 40px 0"
                >
                  <StatInnerWrapper>
                    <Label>
                      German Trivia Record
                    </Label>
                  </StatInnerWrapper>
                  <TriviaRecord
                    won={this.props.user.triviaGamesWon}
                    lost={this.props.user.triviaGamesLost}
                    tied={this.props.user.triviaGamesTied}
                  />
                </StatWrapper>
            }
            {
              !this.props.hideBadges &&
                <StatWrapper>
                  <StatInnerWrapper>
                    <Label>
                      Badges
                    </Label>
                    <BadgeCollection
                      margin="10px"
                      hideLevel0
                      alignment="vertical"
                      width="100px"
                      wrapperWidth="120px"
                      wrapperheight="130px"
                      user={this.props.user}
                      fontSize="14px"
                      textMarginTop="5px"
                      bottom="5px"
                      right="3px"
                    />
                  </StatInnerWrapper>
                </StatWrapper>
            }
          </CardWrapper>
        </Wrapper>
      )
    }
    return <span />
  }
}

export default ProfileCard;
