import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';

const Wrapper = styled.div`

`;

@observer
class ClassroomDashboard extends React.Component {

  render () {
    return (
      <Wrapper>

      </Wrapper>
    );
  }
}

export default ErrorBoundary(ClassroomDashboard);
