import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FormLabel from 'components/form_factory/form_label';
import FormError from 'components/form_factory/form_error';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  display: flex;
  align-items: center;
  input {
    width: 25px;
  }
  label {
    display: inline-block;
    margin: 0;
  }
`;

@observer
class FormCheckbox extends React.Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    store: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    defaultValue: '',
    type: 'text',
  }

  constructor(props) {
    super(props);
    this.props.form.registerComponent(this.props);
  }

  componentWillUnmount() {
    // this.props.form.unregisterComponent(this.props);
  }

  onChange() {
    this.props.onChange(this.props.name, this.refs.checkbox.checked);
  }

  getValue() {
    return this.props.form.getValue(this.props.name) || false;
  }

  getError() {
    return this.props.store.getError(this.props.name);
  }

  name() {
    if (this.props.name.indexOf('.') !== -1) {
      const ary = this.props.name.split('.');
      return `${ary[0]}[${ary[1]}]`;
    }
    return this.props.name;
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        <input
          key={`input-${this.props.name}`}
          ref='checkbox'
          name={this.name()}
          id={this.name()}
          type="checkbox"
          className="form-control-checkbox"
          onChange={this.onChange.bind(this)}
          checked={this.getValue()}
        />
        <FormLabel
          {...this.props}
          htmlFor={this.name()}
        />
        <FormError
          message={this.getError()}
        />
      </Wrapper>
    );
  }
}

export default FormCheckbox;
