import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { pixify } from '@seedlang/utils';
import { DeckProgressBarWrapper } from '@seedlang/hoc';

const Wrapper = styled.div`
  border-radius: 50px;
  background: #315065;
  height: ${(props) => props.height};
  flex: 1;
  position: relative;
  padding-right: 30px;
  margin-right: 3px;
`;

const ProgressWrapper = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 10px;
`;

const Progress = styled.div`
  height: ${(props) => props.height};
  background: #0cce6c;
  transform: ${(props) => props.transform};
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  border-radius: 10px;
  will-change: transform;
  -moz-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
`;

@observer
class DeckProgressBar extends React.Component {

  render() {
    return (
      <Wrapper
        height={pixify(this.props.backgroundHeight)}
      >
        <ProgressWrapper
          height={pixify(this.props.progressHeight)}
          width={pixify(this.props.progressBarWidth)}
        >
          <Progress
            height={pixify(this.props.progressHeight)}
            transform={`translate(${this.props.xPos}px, 0)`}
          />
        </ProgressWrapper>
      </Wrapper>
    );
  }
}

export default DeckProgressBarWrapper(DeckProgressBar);
