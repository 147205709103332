import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import SentenceSubmissionCreate from 'components/sentence_submission/sentence_submission_create';
import { flexCenter, flexCenterColumn } from '@seedlang/style_mixins';
import { AppUI } from '@seedlang/state';
import Paginator from 'components/paginator';
import { LanguageIcon } from '@seedlang/icons';
import Text from 'components/text';
import { SentenceSubmissionIndexWrapper } from "@seedlang/hoc";

const Wrapper = styled.div`
  margin-top: 10px;
  border-top: ${props => props.showTopBorder ? '1px solid #CCC' : ''};
`;

const Table = styled.div`
  border-radius: 5px;
  ${flexCenterColumn()}
  h3 {
    width: 100%;
  }
`;

const SentenceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  cursor: pointer;
  padding: 10px;
  &:hover {
    background: #ECECEC;
  }
`;

const SentenceRow = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  width: 100%;
  border-bottom: 1px solid #CCC;
`;

const ToggleButton = styled.div`
  ${flexCenter()}
  width: 100%;
  margin-top: 20px;
  background: #ECECEC;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  border-radius: 5px;
  i {
    margin-right: 4px;
  }
  &:hover {
    background: #ECECEC;
  }
`;

const TextWrapper = styled.div`
  margin: ${props => props.margin};
  display: flex;
`;

const LanguageIconWrapper = styled.div`
  margin: ${props => props.margin};
  width: ${(props) => props.width};
`;

@observer
class SentenceSubmissionIndex extends React.Component {

  render() {
    return (
      <Wrapper showTopBorder={this.props.showTopBorder}>
        {
          AppUI.sentenceSubmissionStore.hasIndexData &&
            <Table>
              <h3>My Submissions</h3>
              {
                AppUI.sentenceSubmissionStore.indexData.map(item => {
                  return (
                    <SentenceRow
                      key={item.id}
                      flexDirection={AppUI.layout.isMobile ? 'column' : 'row'}
                    >
                      <SentenceWrapper>
                        <TextWrapper
                          margin="0 0 3px 0"
                        >
                          <LanguageIconWrapper
                            margin="3px 0"
                            width="25px"
                          >
                            <LanguageIcon
                              languageId={item.targetLanguage}
                            />
                          </LanguageIconWrapper>
                          <Text textAlign='left' margin="0 0 0 10px">{item.targetText}</Text>
                        </TextWrapper>
                        <TextWrapper>
                          <LanguageIconWrapper
                            margin="3px 0"
                            width="25px"
                          >
                            <LanguageIcon
                              languageId={item.sourceLanguage}
                              useUkFlagForEn={AppUI.user.useBritishEnglish}
                            />
                          </LanguageIconWrapper>
                          <Text textAlign='left' margin="0 0 0 10px">{item.sourceText}</Text>
                        </TextWrapper>
                      </SentenceWrapper>
                    </SentenceRow>
                  )
                })
              }
            </Table>
        }
        {
          (AppUI.sentenceSubmissionStore.page > 1 || AppUI.sentenceSubmissionStore.indexData.length >= 10) &&
            <Paginator
              store={AppUI.sentenceSubmissionStore}
            />
        }
        {
          !this.props.expandSentenceSubmissionCreate &&
            <ToggleButton
              onClick={() => this.props.showSentenceSubmissionForm = !this.props.showSentenceSubmissionForm}
            >
              <i className='fa fa-plus' />
              Add Sentence
            </ToggleButton>
        }
        {
          (this.props.showSentenceSubmissionForm || this.props.expandSentenceSubmissionCreate) &&
            <SentenceSubmissionCreate
              afterCreate={this.props.getSentenceSubmissions}
              wordId={this.props.wordId}
            />
        }
      </Wrapper>
    );
  }
}

export default SentenceSubmissionIndexWrapper(SentenceSubmissionIndex);
