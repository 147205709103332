import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { AssignableConceptStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import { SentenceConceptStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import styled from '@emotion/styled';
import Checkbox from 'components/checkbox';

const Wrapper = styled.div`
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

@observer
class SentenceConceptEdit extends React.Component {
  @observable concepts = [];

  componentDidMount() {
    this.concepts = this.props.concepts;
    AssignableConceptStore.getIndex({filters: {language_id: 'ES'}});
  }

  @autobind onClick(concept) {
    if (this.hasConcept(concept.id)) {
      this.concepts = this.concepts.filter(item => item.id !== concept.id);
      SentenceConceptStore.destroyByIds({data: {sentenceId: this.props.sentenceId, conceptId: concept.id}}, this.props.afterChange);
    } else {
      this.concepts.push(concept);
      SentenceConceptStore.create({data: {sentenceId: this.props.sentenceId, conceptId: concept.id}}, this.props.afterChange)
    }
  }

  hasConcept(id) {
    return this.concepts && this.concepts.find(item => item.id === id);
  }

  render() {
    return (
      <Wrapper>
        <div className='sentence-concept-edit'>
          {
            AssignableConceptStore.indexData.map((item) => {
              return (
                <Row
                  key={item.id}
                >
                  <Checkbox
                    onClick={() => this.onClick(item)}
                    value={this.hasConcept(item.id)}
                  />
                  <div className='name' style={{marginLeft: "5px"}}>
                    {item.name}
                  </div>
                </Row>
              )
            })
          }
          <Paginator
            store={AssignableConceptStore}
          />
        </div>
      </Wrapper>
    );
  }
}

export default SentenceConceptEdit;
