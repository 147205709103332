import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import autobind from 'autobind-decorator';
import { Theme } from '@seedlang/constants';
import { AppUI, DeckUI } from '@seedlang/state';
import DeckHeader from 'components/deck/deck_header';
import CardModal from 'components/card/card_modal';
import OutlineButton from 'components/button/outline_button';
import Spinner from 'components/spinner';
import { pixify } from '@seedlang/utils';
import Modal from 'components/modal';
import DeckClose from 'components/deck/deck_close';
import OverlayWrapper from 'components/layout/overlay_wrapper';
import XpAnimation from 'components/xp_animation';
import key from 'keymaster';
import styled from '@emotion/styled';
import CardNotice from 'components/card/card_notice';
import { DeckWrapper } from '@seedlang/hoc';
import CardAnimationWrapper from 'components/card/card_animation_wrapper';
import DebugOverlay from 'components/deck/debug_overlay';
import Button from 'components/button/button';
import { Link } from 'react-router';
import LottieAnimation from 'components/lottie_animation';

const Wrapper = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border-radius: ${(props) => props.borderRadius};
  text-align: center;
  position: relative;
  margin: 0 auto;
  left: 0;
  right: 0;
  overflow: ${(props) => props.overflow};
  background: #333;
`;

const SlowLoadingWarning = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFF;
  padding: 20px;
  width: 350px;
  margin: 40px auto;
  border-radius: 10px;
  font-size: 14px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const SpinnerWrapper = styled.div`
  opacity: 0.8;
`;

@observer
class Deck extends React.Component {

  constructor(props) {
    super(props);
    const k = key.noConflict();
    if (k) {
      key('left', this.gotoPreviousCard);
    }
  }


  componentWillUnmount() {
    DeckUI.clearAudioStreams();
    key.unbind('left');
  }

  @computed get showDataSaverModal() {
    return !AppUI.user.hideDataSaverModal && !DeckUI.dataSaverModalSeen && AppUI.usingDataSaverHeader;
  }

  @autobind gotoPreviousCard() {
    DeckUI.gotoPreviousCard();
  }

  render() {
    return (
      <Wrapper
        height={DeckUI.layout.loaded && pixify(DeckUI.layout.deckHeight)}
        width={pixify(DeckUI.layout.deckWidth)}
        borderRadius={DeckUI.layout.isMobile ? 0 : '20px'}
        overflow={DeckUI.user.displayDeckDebugInfo ? null : 'hidden'}
      >
        {
          DeckUI.cardNotice &&
            <CardNotice
              {...DeckUI.cardNotice}
            />
        }
        {
          this.showDataSaverModal &&
            <CardModal
              scale={1}
              onClose={() => DeckUI.set('dataSaverModalSeen', true)}
            >
              <h3><i className='fa fa-warning' />You have "Data Saver" turned on.</h3>
              <div>This will prevent Seedlang from autoplaying videos.  You can turn Data Saver off within Chrome by going to top right menu and clicking "Settings".</div>
              <p>You can also continue, but be aware that you will have to click on videos to play them.</p>
              <a
                style={{cursor: 'pointer', marginBottom: 10, textDecoration: 'underline'}}
                onClick={() => AppUI.authUserStore.update({data: {hide_data_saver_modal: true}})}
              >
                Don't show this message again
              </a>
              <OutlineButton
                background="#FFF"
                selectedBackground={Theme.blue}
                onClick={() => DeckUI.set('dataSaverModalSeen', true)}
              >
                Continue
              </OutlineButton>
            </CardModal>
        }
        {
          DeckUI.showCloseModal &&
            <Modal
              animate
              modalBackground='rgba(0, 0, 0, 0.7)'
              onCloseModal={() => DeckUI.showCloseModal = false}
              width='310px'
            >
              <DeckClose
                treeNodeId={this.props.treeNodeId}
                triadWordId={this.props.triadWordId}
                fromGrammarSection={this.props.fromGrammarSection}
              />
            </Modal>
        }
        {
          !DeckUI.layout.isMobile &&
            <OverlayWrapper />
        }
        <DeckHeader
          treeNodeId={this.props.treeNodeId}
        />
        {
          DeckUI.animationCards.filter(item => item.position >= 0).map((item) => {
            return (
              <CardAnimationWrapper
                key={item['id']}
                triadWordId={this.props.triadWordId}
                fromGrammarSection={this.props.fromGrammarSection}
                {...item}
              />
            )
          })
        }
        {
          DeckUI.showLoadingIcon &&
            <SpinnerWrapper>
              <LottieAnimation
                animation="seedlang-logo-draw"
                width="200px"
                height="200px"
              />
            </SpinnerWrapper>
        }
        {
          DeckUI.user.displayDeckDebugInfo &&
            <DebugOverlay />
        }
        {
          !DeckUI.hasDeck && DeckUI.showSlowDeckLoadingWarning &&
            <SlowLoadingWarning>
              <Title>🐢 Slow Deck Warning </Title>
              We are sorry that this deck is taking so long to load. We may be experiencing network issues.

              <Link
                to={{name: 'reviews.home'}}
              >
                <Button
                  margin="20px 0 0 0"
                >
                  Back to Home Page
                </Button>
              </Link>
            </SlowLoadingWarning>
        }
        <XpAnimation
          position="absolute"
          bottom="130px"
          namespace={DeckUI.userDeck.id}
        />
      </Wrapper>
    );
  }
}

export default DeckWrapper(Deck);
