import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable, autorun } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import TranslatorSentence from 'pages/translator/translator_sentence';
import { SentenceStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';
import { isPresent, isBlank } from '@seedlang/utils';
import { each, keys } from 'lodash';
import { FilterIcon } from '@seedlang/icons';
import Modal from 'components/modal';
import FilterOptionsItem from 'components/filter_options_item';
import Text from 'components/text';
import Paginator from 'components/paginator';
import Spinner from 'components/spinner';
import SearchInput from 'components/form/search_input';

const Wrapper = styled.div`

`;

const Filter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #FFF;
  border: 3px solid #CCC;
  border-radius: 5px;
  height: 46px;
  width: calc(100% - 20px);
  margin: 10px;
`;

const FilterIconWrapper = styled.div`
  width: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
  cursor: pointer;
  svg {
    fill: #333;
    width: 20px;
  }
`;

const FilterLabel = styled.div`
  width: 60px;
  padding-left: 10px;
  font-size: 12px;
  font-weight: bold;
  margin-right: 5px;
  cursor: pointer;
  color: #333;
  height: 19px;
`;

const Center = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ModalWrapper = styled.div`
  padding: 20px 10px;
`;

const Tag = styled.div`
  background: #FFF;
  padding: 5px;
  padding-right: ${props => props.paddingRight};
  border-radius: 5px;
  margin: 3px;
  position: relative;
  border: 1px solid #CCC;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: max-content;
`;

const SearchInputWrapper = styled.div`
  margin-top: 10px;
`;

@observer
class TranslatorSentencesIndex extends React.Component {
  @observable selected = {};
  @observable filtersExpanded = true;
  @observable showFilterOptions = false;
  @observable userRequestIsPending = false;
  @observable userIsLoaded = false;
  @observable showSpinner = false;
  @observable searchQuery;
  @observable caretPosition;

  @computed get filters() {
    let filters = {language_id: 'DE'};
    if (isPresent(this.searchQuery) && this.searchQuery.match('"')) {
      filters["target_text"] = this.searchQuery.replace(/"/g, '');
    } else if (isPresent(this.searchQuery)) {
      filters["target_text"] = `~${this.searchQuery}~`;
    } else {
      filters['languages_string'] = AppUI.user.translatorsFilterHasTranslation ? this.props.params.languageId : `!${this.props.params.languageId}`;
    }
    return filters;
  }

  constructor(props) {
    super(props);
    autorun(() => {
      if (AppUI.userIsLoaded && !this.userIsLoaded) {
        this.userIsLoaded = true;
        this.getSentences();
      }
    })
  }

  @autobind onUpdateSearchQuery(value, caretPosition) {
    this.searchQuery = value;
    this.caretPosition = caretPosition;
    clearInterval(this.interval);
    this.interval = setTimeout(this.getSentences, 500)
  }

  @autobind insertText(text, caretPosition) {
    let newValue;
    if (!caretPosition) {
      caretPosition = this.searchQuery ? this.searchQuery.length : 0;
    }
    if (this.disabled) { return null }
    if (this.searchQuery) {
      newValue = [this.searchQuery.slice(0, caretPosition), text, this.searchQuery.slice(caretPosition)].join('');
    } else {
      newValue = text;
    }
    this.onUpdateSearchQuery(newValue, caretPosition + 1);
  }

  @autobind onSetFilter(key, value) {
    this.selected[key] = value;
    let data = {};
    data[key] = value;
    this.updateUser(data, this.getSentences);
  }

  @autobind onToggleFiltersExpanded() {
    this.filtersExpanded = !this.filtersExpanded;
  }

  @autobind setShowFilterOptions(showFilterOptions) {
    this.showFilterOptions = showFilterOptions;
  }

  @autobind getSentences() {
    this.showSpinner = true;
    this.userRequestIsPending = false;
    SentenceStore.clearFilter();
    SentenceStore.getIndex({filters: this.filters}, this.afterShowSentence);
  }

  @autobind afterShowSentence(resp) {
    this.showSpinner = false;
    SentenceStore.setIndexData(resp);
  }

  @autobind updateUser(data, callback) {
    each(keys(data), (key) => {
      if (AppUI.user) {
        AppUI.user.set(key, data[key]);
      }
      if (isBlank(this.selected)) {
        this.selected = {};
      }
      if (this.userRequestIsPending) {
        this.selected[key] = data[key];
      }
    })
    if (!this.userRequestIsPending) {
      this.userRequestIsPending = true;
      if (callback) {
        this.callback = callback;
      }
      AppUI.authUserStore.update({data: this.selected}, this.getSentences);
    }
  }

  render () {
    return (
      <Wrapper>
        {
          this.showFilterOptions &&
            <Modal
              width="400px"
              height="600px"
              onCloseModal={() => this.setShowFilterOptions(false)}
              gradient
              padding="0"
              title="Sentence Filters"
              contentShouldScroll
            >
              <ModalWrapper>
                <FilterOptionsItem
                  label="Has Translation"
                  filterType="select"
                  userKey="translatorsFilterHasTranslation"
                  options={[[true, 'Yes'], [false, 'No']]}
                  value={AppUI.user.translatorsFilterHasTranslation}
                  onSetFilter={this.onSetFilter}
                />
              </ModalWrapper>
            </Modal>
        }
        <SearchInputWrapper>
          <SearchInput
            placeholder="Search for a sentence"
            onChange={this.onUpdateSearchQuery}
            insertText={this.insertText}
            focusOnId={this.searchQuery}
            value={this.searchQuery}
            caretPosition={this.caretPosition}
            background="#FFF"
          />
        </SearchInputWrapper>
        <Filter>
          <FilterLabel
            onClick={() => this.setShowFilterOptions(true)}
          >
            Filters
          </FilterLabel>
          <Center>
            <Tag>
              <Text
                color="#333"
                bold
                fontSize={12}
                lineHeight={15}
              >
                Has Translation: {AppUI.user.translatorsFilterHasTranslation ? 'Yes' : 'No'}
              </Text>
            </Tag>
          </Center>
          <FilterIconWrapper
            onClick={() => this.setShowFilterOptions(true)}
          >
            <FilterIcon
              width={20}
              height={20}
              fill="#444"
            />
          </FilterIconWrapper>
        </Filter>
        {
          this.showSpinner &&
            <Spinner />
        }
        {
          !this.showSpinner && SentenceStore.indexData.map(item => {
            return (
              <TranslatorSentence
                key={item.id}
                sentence={item}
              />
            )
          })
        }
        {
          !this.showSpinner && AppUI.userIsLoaded &&
            <Paginator
              store={SentenceStore}
              filters={this.filters}
            />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TranslatorSentencesIndex);
