import React from 'react';
import { observer } from 'mobx-react';
import { ReviewDeckLinkWrapper } from '@seedlang/hoc';
import { BookmarkOutlineIcon } from '@seedlang/icons';
import DeckLink from 'components/deck/deck_link';
import { depixify, formatNumber, pixify } from '@seedlang/utils';
import DeckLinkIconWrapper from 'components/deck/deck_link_icon_wrapper';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import { isPresent } from "@seedlang/utils/src";

const BookmarkOutlineIconWrapper = styled.div`
  .bookmark-outline-icon {
    svg {
      width: ${props => props.width};
    }
  }
`;

@observer
class ReviewDeckLink extends React.Component {

  static defaultProps = {
    width: 190,
    iconWidth: 80,
  }

  render() {
    return (
      <DeckLink
        onClick={this.props.onClick}
        width={depixify(this.props.width)}
        title={this.props.deck.name || 'Default'}
        subTitle={`${formatNumber(this.props.deck.reviewCardsCount)} Cards`}
        disabled={this.props.disabled}
        progressCurrent={this.props.deck.useDailyGoal && this.props.deck.effectiveDailyGoal > 0 ? this.props.deck.reviewedToday : this.props.deck.reviewCardsCount - this.props.deck.pastDueCount}
        progressTotal={this.props.deck.useDailyGoal && this.props.deck.effectiveDailyGoal > 0  ? this.props.deck.effectiveDailyGoal : this.props.deck.reviewCardsCount}
        borderColor={this.props.borderColor}
        marginRight="10px"
        marginBottom="0px"
        progressLabel="Reviews Caught Up"
      >
        <DeckLinkIconWrapper
          disabled={this.props.disabled}
          iconWidth={pixify(this.props.iconWidth)}
          width={depixify(this.props.width) - 10}
          widthOffsets={[10, 60, 100]}
          backgroundColors={this.props.backgroundColors}
          iconMarginTop="0px"
        >
          <BookmarkOutlineIconWrapper
            width={AppUI.layout.isMobile ? "30px" : "44px"}
          >
            <BookmarkOutlineIcon />
          </BookmarkOutlineIconWrapper>
        </DeckLinkIconWrapper>
      </DeckLink>
    );
  }
}

export default ReviewDeckLinkWrapper(ReviewDeckLink);
