import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { isObject } from 'lodash';
import { isPresent } from '@seedlang/utils';
import cx from 'classnames';
import autobind from 'autobind-decorator';
import FilterComponent from 'components/backend_filter/filter_component';
import FilterButton from 'components/backend_filter/filter_button';
import FilterButtonText from 'components/backend_filter/filter_button_text';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  select {
    height: 30px;
    margin: 0;
    font-size: 14px;
  }
`;

const ButtonWrapper = styled.div`

`;

@observer
class FilterSelect extends React.Component {
  @observable value = this.props.default;

  componentDidMount() {
    if (this.props.store) {
      this.value = this.props.store.getFilterValue(this.props.name);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.default !== this.props.default) {
      this.value = this.props.default;
    }
  }

  onChange() {
  	this.setValue(this.refs.select.value);
  }

  @autobind setValue(value) {
      this.value = value;
      if (this.props.onChange) {
        this.props.onChange(this.value);
      } else {
       this.props.store.setFilter(this.props.name, this.refs.select.value, { ids: this.props.ids, refresh: true });
      }
  }

  render() {
    return (
      <FilterComponent
        selected={isPresent(this.value)}
      >
        <Wrapper>
          {
            !this.props.flat &&
              <select
                ref="select"
                onChange={this.onChange.bind(this)}
                value={this.value}
              >
                {
                  this.props.label &&
                    <option
                      key="-99"
                      value=""
                    >
                      {`Select a ${this.props.label}`}
                    </option>
                }
                {
          				this.props.options && this.props.options.map((item) => {
          					return (
                      <option
                        key={isObject(item) ? item.id : item}
                        value={isObject(item) ? item.id : item}
                      >
                        {isObject(item) ? item[this.props.labelField] : item}
                      </option>
          					);
          				})
          			}
              </select>
          }
          {
            this.props.flat &&
              <ButtonWrapper>
                {
                  this.props.options && this.props.options.map((item) => {
                    return (
                      <FilterButton
                        key={item}
                        onClick={() => this.setValue(item)}
                      >
                        <i
                          className={cx('fa', {
                            'fa-check-circle': this.value === item,
                            'fa-circle': this.value !== item,
                            disabled: this.value !== item,
                          })}
                        />
                        <FilterButtonText>
                          {isObject(item) ? item[this.props.labelField] : item}
                        </FilterButtonText>
                      </FilterButton>
                    )
                  })
                }
              </ButtonWrapper>
          }
        </Wrapper>
      </FilterComponent>
    );
  }
}

export default FilterSelect;
