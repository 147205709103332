import React from 'react';
import { observer } from 'mobx-react';
import { CreatorUI } from '@seedlang/state';
import SentenceStudio from 'components/sentence_studio/sentence_studio';
import ProducerDefault from 'pages/creator/producer/producer_default';
import { Link } from 'react-router';
import styled from '@emotion/styled';
import InPlaceText from 'components/form/in_place_text';

const Wrapper = styled.div`
  .breadcrumbs {
    flex: 1;
  }
  .links {
    float: right;
    font-size: 14px;
    text-decoration: underline;
    a {
      margin: 0 0 0 5px;
    }
  }
  .producer {
    .question-list {
      margin-top: 10px;
    }
    .question-button-row {
      margin-top: 10px;
      display: flex;
      .creator-button {
        flex: 1;
      }
      .toggle {
        margin: 0 5px;
      }
    }
    .answer-help {
      margin-top: 20px;
      line-height: 18px;
      font-size: 14px;
      .example {
        margin-top: 10px;
      }
      p {
        margin: 0;
      }
    }
    .tabs {
      .description {
        font-size: 14px;
      }
      .required {
        color: #bd0d0d;
        margin-left: 3px;
        font-style: italic;
      }
      .col-xs-12 {
        padding: 0;
      }
      .tab-body {
        padding: 20px;
      }
      img {
        width: 180px;
      }
    }
    .sentence-studio {
      .tabs {
        .tab-body {
          padding: 0;
        }
      }
    }
    .list-select {
      margin-top: 10px;
      .option {
        cursor: pointer;
        text-align: left;
        border: 1px solid gray;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 18px;
      }
      .option:hover {
        background: whitesmoke;
      }
    }
    .question-fieldset {
      .delete-button {
        position: absolute;
        top: 20px;
        right: 5px;
      }
    }
    .add-deck {
      h3 {
        margin-bottom: 10px;
      }
    }
    .question-asked-by {
      font-size: 14px;
      font-style: italic;
    }
    .create-deck-link {
      font-size: 12px;
      margin-left: 5px;
      line-height: 27px;
      position: absolute;
      right: 10px;
      top: 0;
      i {
        margin-right: 3px;
        line-height: 27px;
      }
    }
    fieldset {
      position: relative;
      .col-xs-12 {
        padding: 0;
      }
    }
    .sentence-create {
      textarea {
        height: 80px;
      }
    }
    .toggle {
      background: white;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      margin-top: 10px;
    }
    .tab-body {
      padding-bottom: 20px;
      border: 1px solid #e2e2e2;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
    .alert, .note {
      margin-top: 20px;
      width: 100%;
      margin-bottom: 0;
    }
    .note {
      background: #e8e8e8;
      font-size: 14px;
      padding: 10px;
      line-height: 18px;
      i {
        margin: 1px 4px 0 0;
      }
    }
    .admin-row {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      margin-bottom: 10px;
      .col {
        flex: 1;
      }
      .col {
        margin: 5px;
        position: relative;
      }
      .separator {
        width: 50px;
        text-align: center;
        font-size: 30px;
        margin-top: 10px;
        i {
          margin-top: 20px;
        }
      }
      .header-option {
        position: relative;
        flex: 1;
        background: white;
        border-radius: 5px;
        text-align: center;
        padding: 20px;
        max-width: 480px;
        min-height: 110px;
        .add-deck {
          margin-top: 10px;
          display: flex;
          button {
            width: 50px;
            margin-left: 10px;
            height: 38px;
          }
          input {
            flex: 1;
          }
        }
        h3 {
          font-size: 17px;
        }
        .info-tooltip {
          position: absolute;
          top: 5px;
          right: 5px;
        }
      }
    }
    h5 {
      font-size: 13px;
    }
    .topics {
      width: 220px;
      margin-right: 5px;
    }
    .concept-table {
      margin-bottom: 10px;
    }
    .selected {
      background: $paleYellow;
    }
    .left-column {
      flex: 1;
      .tab-body {
        background: white;
      }
      .table-wrapper {
        margin-bottom: 10px;
      }
    }
    .right-column {
      flex: 1;
      margin-left: 10px;
    }
    .increment, .decrement {
      width: 100px;
      i {
        font-size: 16px;
        margin: 0 3px;
      }
    }
    .increment-icon, .decrement-icon {
      font-size: 30px;
      cursor: pointer;
    }
    .decrement {
      margin-right: 3px;
    }
    .increment {
      margin-left: 3px;
      text-align: right;
    }
    .question, h3 {
      flex: 1;
      text-align: center;
    }
  }

`;

@observer
class Producer extends React.Component {

  constructor(props) {
    super(props);
    this.loadConcept();
  }

  componentWillUnmount() {
    CreatorUI.sentenceConceptStore.clearIndexData();
    CreatorUI.conceptStore.clearShowData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.params.conceptId !== this.props.params.conceptId) {
      this.loadConcept();
    }
  }

  loadConcept() {
    CreatorUI.set('conceptId', this.props.params.conceptId)
    CreatorUI.loadConcept(this.props.params.conceptId);
  }

  render() {
    return (
      <Wrapper>
      <div className='producer'>
        <div className='breadcrumbs-wrapper'>
          <div className='breadcrumbs'>
            <div className='custom-breadcrumb'>
              <Link
                className="underline"
                to={{
                  name: 'creator.producer.concepts.index',
                }}
              >
                Concepts
              </Link>
            </div>
            <i className='fa fa-angle-double-right' />
            <div className='custom-breadcrumb'>
              <InPlaceText 
                field="name"
                id={CreatorUI.concept.id}
                defaultValue={CreatorUI.concept.name}
                model="concepts"
              />
            </div>
          </div>
          <div className='links'>
            <Link
              to={{name: 'builder.concepts.edit', params: {conceptId: this.props.params.conceptId}}}
            >
              View Concept
            </Link>
            {
              CreatorUI.deck &&
                <Link
                  to={{name: 'builder.decks.edit', params: {deckId: CreatorUI.deck.id}}}
                >
                  View Deck
                </Link>
            }
          </div>
        </div>
        <div className='admin-row'>
          <div className='left-column'>
            <ProducerDefault />
          </div>
          <div className='right-column'>
            {
              CreatorUI.showSentenceStudio &&
                <SentenceStudio
                  hideInput
                  triviaDeck={CreatorUI.concept && CreatorUI.concept.name && CreatorUI.concept.name.match('Trivia')}
                  associateMedia={CreatorUI.deckRulebookSlug === 'default'}
                  canUpload={CreatorUI.deckRulebookSlug === 'default' }
                  width={300}
                  sentenceId={CreatorUI.sentenceId}
                  afterCreateVideoRequestSent={CreatorUI.afterCreateVideoRequestSent}
                  afterCreateVideo={CreatorUI.afterCreateVideo}
                  afterDestroyVideo={CreatorUI.loadSentences}
                  concept={CreatorUI.concept}
                  conceptId={CreatorUI.conceptId}
                />
            }
          </div>
        </div>
      </div>
      </Wrapper>
    );
  }
}

export default Producer;
