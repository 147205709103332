import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import autobind from 'autobind-decorator';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import TextDisplay from 'components/text_display';
import Button from "components/button/button";
import { Theme } from "@seedlang/constants";
import isBlank from "is-blank";

const Wrapper = styled.div`
  font-size: ${props => props.fontSize};
`;

const MarkdownWrapper = styled.div`
  height: ${props => props.height};
  -webkit-mask-image: ${props => props.expanded ? null : "linear-gradient(to bottom,black,80%,transparent)"};
`;

const PARAGRAPH_LIMIT = 4;

@observer
class ExpandableTextDisplay extends React.Component {
  @observable descriptionIsExpanded = false;

  @computed get paragraphLimit() {
    return this.props.paragraphLimit || PARAGRAPH_LIMIT;
  }

  constructor(props) {
    super(props);
    this.initializeDescriptionIsExpanded(props.htmlText, props.markdownText);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.htmlText !== this.props.htmlText || prevProps.markdownText !== this.props.markdownText) {
      this.initializeDescriptionIsExpanded(this.props.htmlText, this.props.markdownText);
    }
  }

  @autobind splitMarkdownIntoParagraphs(markdownText) {
    return markdownText?.split(/\n(\n|\\r)+/g);
  }

  @autobind splitHTMLIntoParagraphs(htmlText) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlText, 'text/html');
    return Array.from(doc.body.children);
  }

  @autobind initializeDescriptionIsExpanded(htmlText, markdownText) {
    let paragraphs = [];
    if (htmlText) {
      paragraphs = this.splitHTMLIntoParagraphs(htmlText);
    } else {
      paragraphs = this.splitMarkdownIntoParagraphs(markdownText);
    }
    if (paragraphs?.length <= this.paragraphLimit + 1 && isBlank(this.props.nonExpandedHeight)) {
      this.descriptionIsExpanded = true;
    } else {
      this.descriptionIsExpanded = false;
    }
  }

  @computed get paragraphs() {
    if (this.props.htmlText) {
      return this.splitHTMLIntoParagraphs(this.props.htmlText);
    }
    return this.splitMarkdownIntoParagraphs(this.props.markdownText);
  }

  @computed get shortDescriptionMd() {
    if (this.paragraphs?.length > this.paragraphLimit + 1) {
      return this.paragraphs.slice(0,this.paragraphLimit).join("\n\n");
    }
    return this.props.markdownText;
  }

  @computed get shortDescription() {
    if (this.paragraphs?.length > this.paragraphLimit + 1) {
      return this.paragraphs.slice(0,this.paragraphLimit).map((element) => element.outerHTML).join('');;
    }
    return this.props.htmlText;
  }

  @autobind toggleReadMore() {
    this.descriptionIsExpanded = !this.descriptionIsExpanded;
  }

  @computed get descriptionMd() {
    return this.descriptionIsExpanded ? this.props.markdownText : this.shortDescriptionMd;
  }

  @computed get description() {
    return this.descriptionIsExpanded ? this.props.htmlText : this.shortDescription;
  }

  render() {
    return (
      <Wrapper
        fontSize={this.props.fontSize}
      >
        <MarkdownWrapper
          expanded={this.descriptionIsExpanded}
          height={this.descriptionIsExpanded ? 'auto' : this.props.nonExpandedHeight}
        >
          <TextDisplay
            markdown={this.descriptionMd}
            htmlText={this.description}
            maxHeight={this.descriptionIsExpanded ? null : this.props.nonExpandedHeight}
          />
        </MarkdownWrapper>
        {
          !this.props.hideReadMore && (this.paragraphs?.length > this.paragraphLimit + 1 || this.props.nonExpandedHeight) &&
            <div
              style={{display: 'flex', justifyContent: 'center', margin: '5px 0'}}
            >
              <Button
                background={Theme.lightGray}
                color="black"
                onClick={this.toggleReadMore}
                fontSize="12px"
                height="30px"
                scaleOnActive={0.9}
              >
                {`Read ${this.descriptionIsExpanded ? 'Less' : 'More'}`}
              </Button>
            </div>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ExpandableTextDisplay);
