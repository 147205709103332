import React from 'react';
import { observer } from 'mobx-react';
import { FrequencyWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';

const DotWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  margin: 2px;
  border-radius: 50%;
  background: ${(props) => props.selected ? "#636363" : "#CCC"};
`;

@observer
class Frequency extends React.Component {

  render() {
    return (
      <div
        data-tip
        data-for={`frequency-${this.props.word.id}`}
      >
        <DotWrapper>
          <Dot
            selected={this.props.word.frequencyRanking <= 10000}
          />
          <Dot
            selected={this.props.word.frequencyRanking <= 3000}
          />
          <Dot
            selected={this.props.word.frequencyRanking <= 300}
          />
        </DotWrapper>
        <ReactTooltip
          place="top"
          type="dark"
          effect="solid"
          id={`frequency-${this.props.word.id}`}
          class="custom-tooltip"
        >
          {
            this.props.topNumber &&
              <div>Top {this.props.topNumber} Ranking</div>
          }
          {
            !this.props.topNumber &&
              <div>This word is not in the top 10,000</div>
          }
          {
            this.props.topNumber &&
              <div>#{this.props.word.frequencyRanking} Overall</div>
          }
        </ReactTooltip>
      </div>
    );
  }
}

export default FrequencyWrapper(Frequency);
