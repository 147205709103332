import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { flexCenterColumn } from '@seedlang/style_mixins';
import ReactTooltip from 'react-tooltip';
import LevelBadge from 'components/level_badge';


const Wrapper = styled.div`
  ${flexCenterColumn()}
  padding: ${(props) => props.padding};
`;

const Content = styled.div`
  ${flexCenterColumn()}
`;

const BadgeWrapper = styled.div`
  top: -20px;
  left: 0;
  right: 0;
  width: ${(props) => props.width};
  margin: 0 auto;
`;

@observer
class LevelBadgeWithFlag extends React.Component {

  static defaultProps = {
    padding: "20px 0 0 0",
  }

  backgroundForSegment(num) {
    const progress = Math.ceil((this.props.targetLanguage.progressOnCurrentLevel / this.props.targetLanguage.xpRangeBetweenLevels) * 10);
    return progress >= num ? Theme.green : "#e6e6e6";
  }

  render() {
    return (
      <Wrapper
        data-tip
        data-for={`xp-progress-${this.props.targetLanguage.languageId}`}
        padding={this.props.padding}
      >
        <Content>
          <BadgeWrapper
            width="100px"
          >
            <LevelBadge
              width="100px"
              fontSize="16px"
              level={this.props.targetLanguage.xpLevel}
              languageId={this.props.targetLanguage.languageId}
            />
          </BadgeWrapper>
        </Content>
        <ReactTooltip
          place="bottom"
          type="dark"
          effect="solid"
          id={`xp-progress-${this.props.targetLanguage.languageId}`}
          class="custom-tooltip"
        >
          <div>{this.props.targetLanguage.xp.toLocaleString('en')} total XP</div>
        </ReactTooltip>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(LevelBadgeWithFlag);
