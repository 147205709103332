import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { HeaderWrapper } from '@seedlang/hoc';
import { Link } from 'react-router';
import Dropdown from 'components/dropdown';
import { ClassroomIcon, ChatOutlineIcon, PatreonIcon, QuestionIcon, SearchWordsIcon, TreeIcon } from '@seedlang/icons';
import { isPresent } from '@seedlang/utils';
import { AppUI } from '@seedlang/state';
import NotificationsWithPopup from 'components/notification/notifications_with_popup';
import UserProfileImage from 'components/user/user_profile_image';
import StreakWithPopup from 'components/streak_with_popup';
import SeedlangLogoWithBold from 'components/seedlang_logo_with_bold';
import Button from 'components/button/button';
import HeaderLink from 'components/header_link';
import styled from '@emotion/styled';
import GoProButton from 'components/button/go_pro_button';
import { Theme } from '@seedlang/constants';
import HeaderButton from 'components/button/header_button';
import autobind from 'autobind-decorator';
import LanguageDropdown from 'components/language_dropdown';
import { Constants } from '@seedlang/constants';

const LanguageDropdownWrapper = styled.div`
  width: 42px;
  margin: 0 10px 5px 5px;
`;

const AgreementWrapper = styled.div`
  width: 100%;
`;

const Wrapper = styled.header`
  display: flex;
  z-index: 999;
  width: 100%;
  height: 70px;
  min-height: 70px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: ${(props) => props.background};
  position: ${(props) => props.position};
  border-bottom: ${props => props.borderBottom};
  margin: 0 0 10px 0;
  .on-click {
    cursor: pointer;
  }
  .on-click:hover {
    color: #FFF;
  }
`;

const InnerWrapper = styled.div`
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1180px;
  position: relative;
`;

const LogoWrapper = styled.div`
  width: 190px;
  cursor: pointer;
`;

const NavigationButtons = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ExpandableLinks = styled.div`
`;

@observer
class Header extends React.Component {
  @observable showMobileNavigation = false;

  static defaultProps = {
    background: Theme.siteHeaderColor,
  }

  matchRoute(str) {
    return isPresent(AppUI.routeStore.routeName) && AppUI.routeStore.routeName.match(str);
  }

  @computed get showLinkText() {
    if (AppUI.targetLanguageId === 'DE') {
      return AppUI.layout.viewportWidth > 1110;
    }
    return AppUI.layout.viewportWidth > 1059;
  }

  @computed get showMembershipButton() {
    return !this.simplifiedHeader && !AppUI.user.anyAdmin && !AppUI.siteIsDefault && !AppUI.user.isMember;
  }

  @computed get simplifiedHeader() {
    return AppUI.routeStore.routeName === 'memberships.create';
  }

  @computed get showWorksheetsButton() {
    return !this.simplifiedHeader && AppUI.userIsSignedIn && AppUI.user.hasContentGroup && !AppUI.user.guest;
  }

  @computed get showClassroomButton() {
    return !this.simplifiedHeader && AppUI.userIsSignedIn && AppUI.user.hasClassroomGroup && !AppUI.user.guest;
  }

  @computed get showNavigationButton() {
    return !this.simplifiedHeader;
  }

  @computed get showTriviaNewLabel() {
    return AppUI.userIsSignedIn && AppUI.user.site?.daysSinceLastTriviaUpdate <= 2 && !AppUI.user.hideTriviaNewLabel && (!AppUI.user.lastVisitedTriviaAt || AppUI.user.lastVisitedTriviaAt.formattedWithSeconds < AppUI.user.site?.lastWebTriviaPublishedAt.formattedWithSeconds);
  }

  @autobind signOutAndHideNavigation() {
    this.showMobileNavigation = false;
    AppUI.signOut(AppUI.siteIsDefault);
  }

  render() {
    return (
      <AgreementWrapper>
        <Wrapper
          background={this.props.background}
          position={this.props.position}
        >
          <InnerWrapper>
            {
              AppUI.userIsSignedIn &&
                <LogoWrapper>
                  <Link
                    to={{ name: 'reviews.home' }}
                  >
                    <SeedlangLogoWithBold />
                  </Link>
                </LogoWrapper>
            }
            <NavigationButtons>
              {
                AppUI.userIsSignedIn &&
                  <ExpandableLinks>
                  {
                    this.showNavigationButton &&
                      <HeaderLink
                        showText={this.showLinkText}
                        showNew={this.props.showStoriesNewLabel}
                        selected={this.matchRoute('stories')}
                        to={{ name: 'stories.home'}}
                        text="Stories"
                        icon={
                          <TreeIcon
                            width="20px"
                          />
                        }
                      />
                  }
                  {
                    this.showNavigationButton &&
                      <HeaderLink
                        showText={this.showLinkText}
                        selected={this.matchRoute('vocab') && !this.matchRoute('worksheets')}
                        to={{ name: 'vocab.home', params: {page: 1}}}
                        text="Vocab"
                        iconMargin="0 0 0 0px"
                        iconWidth="20px"
                        icon={
                          <SearchWordsIcon
                            width="20px"
                          />
                        }
                      />
                  }
                  {
                    this.showNavigationButton && AppUI.targetLanguageId === 'DE' &&
                      <HeaderLink
                        showNew={this.showTriviaNewLabel}
                        showText={this.showLinkText}
                        selected={this.matchRoute('trivia')}
                        to={{ name: 'trivia.home'}}
                        text="Trivia"
                        icon={<QuestionIcon />}
                      />
                  }
                  {
                    this.showNavigationButton && AppUI.userIsSignedIn && AppUI.user.isMember &&
                      <HeaderLink
                        showText={this.showLinkText}
                        selected={this.matchRoute('grammar')}
                        to={{ name: 'grammar'}}
                        text="Grammar"
                        icon={<ClassroomIcon />}
                      />
                  }
                  {
                    this.showWorksheetsButton &&
                      <HeaderLink
                        showText={this.showLinkText}
                        selected={this.matchRoute('group') || this.matchRoute('worksheets') || this.matchRoute('exercises')}
                        to={{ name: 'groups.show', params: {groupId: AppUI.user.contentGroupId} }}
                        text="Easy German"
                        fontSize="11px"
                        icon={<PatreonIcon />}
                      />
                  }
                  {
                    this.showClassroomButton &&
                      <HeaderLink
                        showText={this.showLinkText}
                        selected={this.matchRoute('classroom')}
                        to={{ name: 'classroom.show', params: {groupId: AppUI.user.classroomGroupId} }}
                        text="Class"
                        fontSize="11px"
                        icon={<ClassroomIcon />}
                        textMarginLeft="2px"
                      />
                  }
                  {
                    this.showNavigationButton && !AppUI.user.guest &&
                      <HeaderLink
                        showText={this.showLinkText}
                        selected={this.matchRoute('forum')}
                        to={{ name: 'forums.index' }}
                        text="Discuss"
                        icon={<ChatOutlineIcon />}
                      />
                  }
                </ExpandableLinks>
              }
              {
                !this.simplifiedHeader && AppUI.userIsSignedIn && !AppUI.user.isMember && !AppUI.user.anyAdmin &&
                  <Link
                    to={{name: 'memberships.index'}}
                  >
                    <GoProButton
                      height="42px"
                    >
                      <div className='text'>
                        <span>Go Pro</span>
                        {
                          AppUI.user.discount && AppUI.layout.viewportWidth > 899 &&
                            <span className='discount'> - 47% Off</span>
                        }
                      </div>
                    </GoProButton>
                  </Link>
              }
              {
                !this.simplifiedHeader && AppUI.userIsSignedIn && !AppUI.user.guest &&
                  <NotificationsWithPopup
                    background={this.props.btnColor}
                  />
              }
              {
                !this.simplifiedHeader && AppUI.userIsSignedIn && !AppUI.user.guest &&
                  <StreakWithPopup
                    background={this.props.btnColor}
                  />
              }
              {
                !AppUI.userIsSignedIn &&
                  <Link
                    to={{name: 'onboarding.sign_in'}}
                  >
                    <Button
                      background="#FFF"
                      color="#32d0cd"
                      fontSize="14px"
                      height="47px"
                      borderRadius="47px"
                      padding="0 20px"
                      scaleOnHover="1.15"
                      backgroundOnHover="#FFF"
                    >
                      SIGN IN
                    </Button>
                  </Link>
              }
              {
                AppUI.user.guest &&
                  <Link
                    to={{name: 'onboarding.registration'}}
                  >
                    <HeaderButton
                      height="47px"
                      borderRadius="47px"
                      fontWeight="bold"
                    >
                      Register
                    </HeaderButton>
                  </Link>
              }
              {
                AppUI.userIsSignedIn &&
                  <LanguageDropdownWrapper>
                    <LanguageDropdown
                      languageId={AppUI.targetLanguageId}
                      languageIds={Constants.LANGUAGE_ABBREVIATIONS}
                      margin="5px 0 0 10px"
                      onClick={this.props.onChangeTargetLanguageId}
                    />
                  </LanguageDropdownWrapper>
              }
              {
                !AppUI.whiteLabelGuest && !this.simplifiedHeader && AppUI.userIsSignedIn && !AppUI.whiteLabelMobileView &&
                  <Dropdown
                    className='user-profile user-image'
                    top="60px"
                    right={0}
                  >
                    <UserProfileImage
                      hideTooltip
                      margin={AppUI.siteIsDefault ? "0 0 0 2px" : "0 0 0 10px"}
                      clickToProfile
                      width={42}
                      user={AppUI.user}
                    />
                    <ul>
                      <li>
                        <Link
                          to="profile"
                        >
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="settings.user_profile"
                        >
                          Settings
                        </Link>
                      </li>
                      {
                        AppUI.user.admin &&
                          <li>
                            <a href="/builder/dashboard/analytics">Admin</a>
                          </li>
                      }
                      {
                        (AppUI.user.admin || AppUI.user.translator) &&
                          <li>
                            <Link
                              to={{name: "translator.home", params: {languageId: AppUI.user.languageId}}}
                            >
                              Translators
                            </Link>
                          </li>
                      }
                      {
                        AppUI.user.groupAdmins.filter(item => item.group?.groupType === 'classroom' || item.group?.id === 'f74a4102-d65b-448c-b261-60b6be2c7eca').map(item => {
                          return (
                            <li
                              key={item.id}
                            >
                              <Link
                                to={{name: item.group?.groupType === 'classroom' ? 'classroom.students' : 'creator.groups.worksheets.index', params: {groupId: item.group.id}}}
                              >
                                {item.group?.groupType === 'classroom' ? 'Classroom' : 'Easy German'}
                              </Link>
                            </li>
                          )
                        })
                      }
                      {
                        AppUI.user.admin &&
                          <li>
                            <a href="/creator/decks">Video</a>
                          </li>
                      }
                      <li
                        onClick={() => AppUI.signOut(AppUI.siteIsDefault)}
                        className='on-click'
                      >
                        Sign Out
                      </li>
                    </ul>
                  </Dropdown>
              }
            </NavigationButtons>
          </InnerWrapper>
        </Wrapper>
      </AgreementWrapper>
    );
  }
}

export default HeaderWrapper(Header);
