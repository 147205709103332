import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ProgressBar from 'components/progress_bar';
import { isPresent } from '@seedlang/utils';
import styled from '@emotion/styled';
import { SparkleIcon } from "@seedlang/icons";

const Wrapper = styled.div`
  margin-left: ${props => props.marginLeft};
  width: ${props => props.width};
  position: relative;
  .sparkle-icon {
    width: 13px;
    position: absolute;
    top: 0px;
    right: 1px;
    transform: rotate(6deg);
    svg {
      fill: #ffff00;
    }
  }
`;

const Counts = styled.div`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  text-align: center;
  line-height: 30px;
`;

@observer
class ExerciseProgress extends React.Component {


  @computed get userExercise() {
    return this.props.userExercises?.find(item => item.exerciseId === this.props.exercise.id);
  }

  @computed get total() {
    if (isPresent(this.props.exercise)) {
      if (this.userExercise?.completed && this.props.exercise.exerciseEntriesCount === 0) {
        return 1;
      }
      return this.props.exercise.exerciseEntriesCount;
    } else {
      return this.props.total || 0;
    }
  }

  @computed get current() {
    if (isPresent(this.props.userExercises)) {
      if (isPresent(this.userExercise)) {
        if (this.userExercise?.completed && this.props.exercise.exerciseEntriesCount === 0) {
          return 1;
        }
        return this.userExercise.correctAnswersCount ;
      } return 0;
    } else {
      return this.props.current || 0;
    }
  }

  render() {
    return (
      <Wrapper>
        <ProgressBar
          current={this.current}
          total={this.total}
          width={this.props.largeVersion ? this.props.width || "80px" : "100%"}
          height={this.props.largeVersion ? this.props.height || "30px" : "5px"}
          borderTopRightRadius={this.props.largeVersion ? "5px" : null}
          borderTopLeftRadius={this.props.largeVersion ? "5px" : null}
          borderBottomRightRadius="5px"
          borderBottomLeftRadius="5px"
        />
        {
          !this.props.hideCounts &&
            <Counts>
              {this.current > this.total ? this.total : this.current} / {this.total}
            </Counts>
        }
        {
          this.props.largeVersion && this.current !== 0 && this.current >= this.total &&
            <SparkleIcon />
        }
      </Wrapper>
    );
  }
}

export default ExerciseProgress;
