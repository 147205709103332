import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { AppUI, DeckUI } from '@seedlang/state';
import Button from 'components/button/button';
import { Theme } from "@seedlang/constants/src";
import Spinner from "components/spinner";
import { BulkEditReviewsWrapper } from "@seedlang/hoc";
import Text from "components/text";
import InPlaceSelect from "components/form/in_place_select";
import { isBlank, isPresent } from "@seedlang/utils";
import Alert from "components/alert";
import CardTypeSelect from "components/review/card_type_select";
import OutlineButton from "components/button/outline_button";
import Modal from "components/modal";
import MenuList from "components/review/menu_list";

const Wrapper = styled.div`

`;

const ButtonRow = styled.div`
  margin: ${props => props.margin};
  display: flex;
  gap: 10px;
  @media only screen and (max-width : 649px) {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;
    margin: 10px 0;
    width: 80%;
  }
  .fa {
    margin-right: 5px;
  }
`;

const ModalButtons = styled.div`
  margin: 10px 0 0 0;
  display: flex;
  gap: 10px;
`;

const Selector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  background: white;
  padding: 10px;
  border-radius: 10px;
  border: #f1f1f1 solid 1px;
  margin-top: -8px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 3px 10px;
`;

const ReviewOptionsWrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 0;
  margin: 10px 0;
  border: 3px solid #CCC;
  border-radius: 5px;
  input {
    height: 30px!important;
    width: calc(100% - 40px)!important;
    margin-left: 20px;
  }
`;

const DeleteModal = styled.div`
  display: flex;
  align-items center;
  justify-content: center;
  flex-direction: column;
  p {
    margin: 20px 0 0 0;
  }
`;

const ButtonWrapper = styled.div`
  flex: 1;
  padding: 0 5px;
`;

const Option = styled.li`
  &:hover {
    background: #f1f1f1;
  }
  padding: 2px 10px;
  margin-bottom: 0;
  font-size: 12px;
  border-radius: 10px;
  cursor: ${props => props.selected ? 'default' : 'pointer'};
  background: ${props => props.selected ? '#e6e6e6' : ''};
`;

const Options = styled.ul`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

@observer
class BulkEditReviews extends React.Component {

  componentDidMount() {
    document.addEventListener('mousedown', this.props.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.props.handleOutsideClick);
  }

  render() {
    return (
      <Wrapper>
        {
          this.props.showDeleteConfirmationModal &&
          <Modal
            onCloseModal={this.props.toggleDeleteModal}
            width={350}
            top='200px'
            gradient
            title='Delete Reviews'
          >
            <DeleteModal>
                {`Are you sure that you want to delete ${this.props.selectedReviewIds.length <= 1 ? 'this review' : `these ${this.props.selectedReviewIds.length} reviews`}? This cannot be undone.`}
                  <ModalButtons>
                    <ButtonWrapper>
                      <Button
                        onClick={this.props.destroyReviews}
                        background={Theme.green}
                        width="100%"
                      >
                        <i className='fa fa-check'/>
                        Confirm
                      </Button>
                    </ButtonWrapper>
                    <ButtonWrapper>
                      <Button
                        onClick={this.props.toggleDeleteModal}
                        background={Theme.red}
                        width="100%"
                      >
                        <i className='fa fa-times'/>
                        Cancel
                      </Button>
                    </ButtonWrapper>
                  </ModalButtons>
            </DeleteModal>
          </Modal>
        }
        {
          this.props.showResetConfirmationModal &&
          <Modal
            onCloseModal={this.props.toggleResetModal}
            width={350}
            top='200px'
            gradient
            title='Reset Reviews'
          >
            <DeleteModal>
                {`Are you sure that you want to reset ${this.props.selectedReviewIds.length <= 1 ? 'this review' : `these ${this.props.selectedReviewIds.length} reviews`}? Your progress will be erased and they will be due today.`}
                  <ModalButtons>
                    <ButtonWrapper>
                      <Button
                        onClick={this.props.resetReviews}
                        background={Theme.green}
                        width="100%"
                      >
                        <i className='fa fa-check'/>
                        Confirm
                      </Button>
                    </ButtonWrapper>
                    <ButtonWrapper>
                      <Button
                        onClick={this.props.toggleResetModal}
                        background={Theme.red}
                        width="100%"
                      >
                        <i className='fa fa-times'/>
                        Cancel
                      </Button>
                    </ButtonWrapper>
                  </ModalButtons>
            </DeleteModal>
          </Modal>
        }
        {
          isPresent(this.props.selectedReviewIds) && isPresent(this.props.reviewDeckOptions) &&
          <ReviewOptionsWrapper>
            <Text fontSize='12px' fontWeight="800">{`${this.props.selectedReviewIds.length} Review${this.props.selectedReviewIds.length > 1 ? 's' : ''} Selected`}</Text>
            {
              !this.props.showSpinner &&
              <ButtonRow>
                <div>
                  <OutlineButton
                    margin={AppUI.layout.isMobile ? "0" : "10px 0"}
                    onClick={this.props.toggleShowDeckSelector}
                    fontSize='12px'
                    padding='5px 20px'
                    borderRadius='20px'
                  >
                    <i className="fa fa-share" />
                    Move to
                  </OutlineButton>
                  {
                    this.props.showDeckSelector &&
                    <Selector className='selector'>
                      <Text
                        fontSize="12px"
                        bold
                        color="#333"
                      >
                        Select Review Deck
                      </Text>
                      <MenuList
                        options={AppUI.reviewDeckOptions}
                        onClick={this.props.updateSelectedCardsDeck}
                        selectedId={this.props.deckId}
                      />
                    </Selector>
                  }
                </div>
                <div>
                  <OutlineButton
                    margin={AppUI.layout.isMobile ? "0" : "10px 0"}
                    onClick={this.props.toggleShowCardTypeSelector}
                    fontSize='12px'
                    padding='5px 20px'
                    borderRadius='20px'
                  >
                    <i className="fa fa-gear" />
                    Change Type
                  </OutlineButton>
                  {
                    this.props.showCardTypeSelector &&
                    <Selector className='selector'>
                      <Text
                        fontSize="12px"
                        bold
                        color="#333"
                      >
                        Select Card Type
                      </Text>
                      <CardTypeSelect
                        compact
                        cards={this.props.selectedCards}
                        onChangeMultipleCards={this.props.updateSelectedCardsType}
                      />
                    </Selector>
                  }
                </div>
                <OutlineButton
                  margin={AppUI.layout.isMobile ? "0" : "10px 0"}
                  onClick={this.props.toggleResetModal}
                  fontSize='12px'
                  width='100px'
                  padding='5px 20px'
                  borderRadius='20px'
                >
                  <i className='fa fa-refresh' />
                  Reset
                </OutlineButton>
                <OutlineButton
                  margin={AppUI.layout.isMobile ? "0" : "10px 0"}
                  onClick={this.props.retireReviews}
                  fontSize='12px'
                  width='110px'
                  padding='5px 20px'
                  borderRadius='20px'
                >
                  <i className={`fa fa-arrow-${this.props.nonRetiredSelectedCards.length === 0 ? 'up' : 'down'}`} />
                  {this.props.nonRetiredSelectedCards.length === 0 ? 'Restore' : 'Retire'}
                </OutlineButton>
                <OutlineButton
                  margin={AppUI.layout.isMobile ? "0" : "10px 0"}
                  onClick={this.props.toggleDeleteModal}
                  selectedBackground={Theme.red}
                  disabled={DeckUI.cardStore.requestCounter > 0}
                  padding='5px 20px'
                  borderRadius='20px'
                  fontSize='12px'
                >
                  <i className='fa fa-trash'/>
                  Delete
                </OutlineButton>
              </ButtonRow>
            }
            {
              this.props.showSpinner &&
              <Spinner margin="10px 0" />
            }
          </ReviewOptionsWrapper>
        }
        {
          isPresent(this.props.alertMessage) &&
          <Alert background={Theme.green} fontSize='12px'>{this.props.alertMessage}</Alert>
        }
      </Wrapper>
    );
  }
}

export default BulkEditReviewsWrapper(BulkEditReviews);
