import React from 'react';
import styled from '@emotion/styled';
import { TreeUI } from '@seedlang/state';
import UserProfileImage from 'components/user/user_profile_image';
import Button from 'components/button/button';
import Text from 'components/text';
import { flexCenter } from '@seedlang/style_mixins';
import Spinner from 'components/spinner';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import { Theme } from '@seedlang/constants';
import Alert from 'components/alert';
import { isValidEmail } from '@seedlang/utils';
import { CloseIcon, SendIcon } from '@seedlang/icons';
import { FollowingIndexWrapper } from '@seedlang/hoc';

const Wrapper = styled.div`
  .send-icon {
    margin: 0 5px 0 0;
    svg {
      fill: #FFF;
      width: 15px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid #FFF;
  padding: 5px 10px;
  .fa-block {
    background: white;
  }
`;

const Name = styled.div`
  flex: 1;
  padding-left: 10px;
  font-size: 14px;
`;

const AddFriend = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  padding: 5px 10px 0 10px;
  .spinner {
    margin: 10px 0 0 5px;
  }
`;

const Navigation = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid #FFF;
  padding: 10px;
  background: #848484;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 50px;
`;

const Notice = styled.div`
  background: ${(props) => props.background};
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  line-height: 18px;
`;

const CloseIconWrapper = styled.div`
  ${flexCenter()}
  height: 20px;
  width: 20px;
  cursor: pointer;
  .icon-wrapper {
    height: 20px;
    width: 20px;
  }
  svg {
    fill: #FFF;
    width: 20px;
  }
`;

const DeleteButtonWrapper = styled.div`
  margin: ${(props) => props.margin};
  .fa-block {
    color: ${(props) => props.iconColor};
  }
`;

const FriendsLabel = styled.div`
  flex: 1;
  color: white;
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  width: 95px;
`;

const ChallengeSent = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #5c5c5c;
  font-style: italic;
  text-align: center;
  width: 85px;
`;

class FollowingIndex extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.treeNodeId &&
            <Navigation>
              <FriendsLabel>
                Friends
              </FriendsLabel>
              <CloseIconWrapper
                onClick={() => TreeUI.showFollowingIndex = false}
                iconColor={this.props.iconColor}
                right={this.props.closeIconRight}
              >
                <CloseIcon />
              </CloseIconWrapper>
            </Navigation>
        }
        {
          this.props.showSpinner &&
            <Spinner
              className="blue"
            />
        }
        {
          !this.props.showSpinner && TreeUI.followAssociationStore.indexData.length === 0 &&
            <Notice
              background={Theme.orange}
            >
              You have not yet added any friends
            </Notice>
        }
        {
          !this.props.showSpinner && TreeUI.followAssociationStore.indexData.map(item => {
            return (
              <Row
                key={item.id}
              >
                {
                  this.props.treeNodeId &&
                    <ButtonWrapper>
                      {
                        (!this.props.editMode && this.props.finishedMatchInTreeNode.indexOf(item.user.id) !== -1) &&
                          <Button
                            height="35px"
                            lineHeight="10px"
                            fontSize="12px"
                            width="85px"
                            padding="10px"
                            borderRadius="10px"
                            margin="0 10px 0 0"
                            onClick={() => TreeUI.onClickPlayTrivia({opponentId: item.user.id})}
                          >
                            Play
                          </Button>
                      }
                      {
                        !this.props.editMode && this.props.finishedMatchInTreeNode.indexOf(item.user.id) === -1 && !TreeUI.challengeIssued(item.user.id) &&
                          <Button
                            height="35px"
                            lineHeight="10px"
                            fontSize="12px"
                            width="85px"
                            padding="10px"
                            borderRadius="10px"
                            margin="0 10px 0 0"
                            background={Theme.orange}
                            onClick={() => TreeUI.onChallengeUser(item.user.id)}
                          >
                            Challenge
                          </Button>
                      }
                      {
                        TreeUI.challengeIssued(item.user.id) &&
                          <ChallengeSent>
                            Challenge Sent!
                          </ChallengeSent>
                      }
                    </ButtonWrapper>
                }
                <UserProfileImage
                  user={item.user}
                  clickToProfile
                />
                <Name>
                  {item.user.name}
                </Name>
                {
                  this.props.editMode &&
                    <DeleteButtonWrapper
                      iconColor={this.props.iconColor}
                      margin={this.props.closeIconMargin}
                    >
                      <DeleteButton
                        allowDeletion
                        onConfirm={() => this.props.onRemoveFollowAssociation(item.id)}
                        message="Remove From Friends List?"
                        className="fa-block right"
                      />
                    </DeleteButtonWrapper>
                }
              </Row>
            )
          })
        }
        {
          (TreeUI.followAssociationStore.indexData.length === 10 || TreeUI.followAssociationStore.page > 1) &&
            <Paginator
              margin="5px 0 0 5px"
              store={TreeUI.followAssociationStore}
            />
        }
        <Text
          bold
          fontSize="12px"
          margin="5px 0 0 10px"
        >
          Add a Friend
        </Text>
        <AddFriend
          flexDirection={this.props.invitationId || this.props.showMessage ? 'column' : 'rows'}
        >
          {
            this.props.showMessage === 'already-following' &&
              <Alert
                margin="0 0 5px 0"
              >
                This person is already your friend!
              </Alert>
          }
          {
            this.props.showMessage === 'already-invited' &&
              <Alert
                margin="0 0 5px 0"
              >
                You have already invited this friend! After your friend sets up an account, they will appear in this list.
              </Alert>
          }
          {
            this.props.showMessage === 'message-sent' &&
              <Alert
                margin="0 0 5px 0"
              >
                We've sent an email inviting your friend! After your friend sets up an account, they will appear in this list.
              </Alert>
          }
          {
            !this.props.creatingFollowAssociation &&
              <input
                placeholder="Enter Email Address"
                value={this.props.email}
                onChange={(e) => this.props.updateEmail(e.target.value)}
              />
          }
          {
            !this.props.creatingFollowAssociation &&
              <Button
                width="70px"
                height="35px"
                lineHeight="10px"
                fontSize="12px"
                padding="10px"
                borderRadius="10px"
                disabled={!isValidEmail(this.props.email)}
                onClick={this.props.onCreateFollowing}
                margin={this.props.invitationId || this.props.showMessage ? "5px 0 0 0" : "0 0 0 5px"}
              >
                {
                  this.props.invitationId &&
                    <SendIcon />
                }
                {this.props.invitationId ? 'Send Invitation' : 'Add'}
              </Button>
          }
          {
            this.props.creatingFollowAssociation &&
              <Spinner
                className='blue'
              />
          }
        </AddFriend>
      </Wrapper>
    );
  }
}

export default FollowingIndexWrapper(FollowingIndex);
