import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import MembershipGroupEdit from 'pages/builder/membership_groups/membership_group_edit';

@observer
class SiteMembershipGroupEdit extends React.Component {

  render() {
    return (
      <MembershipGroupEdit
        {...this.props}
        whiteLabeled
      />
    );
  }
}

export default ErrorBoundary(SiteMembershipGroupEdit);
