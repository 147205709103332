import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';

const Wrapper = styled.div`

`;

const Title = styled.div`
  font-weight: bold;
  line-height: 18px;
  font-size: 16px;
  padding: 0 15px;
`;

const Text = styled.div`
  line-height: 18px;
  font-size: 16px;
`;

const SubText = styled.div`
  font-style: italic;
  line-height: 16px;
  font-size: 13px;
  padding: 0 15px;
`;

@observer
class CardArrowBoxText extends React.Component {

  render() {
    return (
      <Wrapper
        onClick={this.props.onClick}
      >
        {
          this.props.title &&
            <Title>
              {this.props.title}
            </Title>
        }
        {
          this.props.text &&
            <Text>
              {this.props.text}
            </Text>
        }
        {
          this.props.subText &&
            <SubText>
              {this.props.subText}
            </SubText>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(CardArrowBoxText);
