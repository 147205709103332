import React from 'react';
import { observer } from 'mobx-react';
import Navigation from 'components/navigation';
import { last } from 'lodash';

@observer
class Outreach extends React.Component {

  render() {
    return (
      <div className="page">
        <Navigation
          currentRoute={last(this.props.routes).name}
          smallButtons
          links={[
            {
              link: 'builder.campaign_types.index',
              title: 'Campaign Types',
            },
            {
              link: 'builder.campaign_messages.index',
              title: 'Campaign Messages',
            },
            {
              link: 'builder.notifications.index',
              title: 'Notifications',
            },
            {
              link: 'builder.notifications.send_notification',
              title: 'Send Notification',
            },
            {
              link: 'builder.referrer_options.index',
              title: 'Referrer Options',
            },
            {
              link: 'builder.promotions.index',
              title: 'Promotions',
            },
          ]}
        />
        {this.props.children}
      </div>
    );
  }
}

export default Outreach;
