import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ConceptWordStore } from '@seedlang/stores';
import { indexOf } from 'lodash';
import autobind from 'autobind-decorator';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import ReactTooltip from 'react-tooltip';

@observer
class ConceptCheckbox extends React.Component {

  static propTypes = {
    word: PropTypes.object.isRequired,
    concept: PropTypes.object.isRequired,
    afterUpdate: PropTypes.func.isRequired,
    label: PropTypes.string,
  }

  checked() {
  	if (this.props.word && this.props.concept) {
  		return indexOf(this.props.word.conceptIds, this.props.concept.id) !== -1;
  	}
  }

  @autobind onChange(val) {
    if (val) {
      ConceptWordStore.create({ data: { concept_id: this.props.concept.id, word_id: this.props.word.id } }, this.props.afterUpdate);
    } else {
      ConceptWordStore.destroy({ ids: { concept_id: this.props.concept.id, word_id: this.props.word.id } }, this.props.afterUpdate);
    }
  }

  render() {
    return (
      <div
        data-for={`${this.props.label}-${this.props.word.id}`}
        data-tip
      >
        <InPlaceCheckbox
          hideCheckbox
          onChange={this.onChange}
          value={this.checked()}
        >
          {this.props.label}
        </InPlaceCheckbox>
        <ReactTooltip
          place="top"
          type="dark"
          effect="solid"
          id={`${this.props.label}-${this.props.word.id}`}
          class="custom-tooltip"
        >
          {this.props.tooltip}
        </ReactTooltip>
      </div>
    );
  }
}

export default ConceptCheckbox;
