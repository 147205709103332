import React from 'react';
import { observer } from 'mobx-react';
import { DeckUI, AppUI } from '@seedlang/state';
import { DeckCloseWebWrapper, PronunciationCardWrapper } from '@seedlang/hoc';
import CardUserInput from 'components/card/card_user_input';
import { DownIcon } from '@seedlang/icons';
import { Theme } from '@seedlang/constants';
import EvaluateButtons from 'components/card/evaluate_buttons';
import CardButtons from 'components/card/card_buttons';
import CardButton from 'components/card/card_button';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import CardArrowBox from 'components/card/card_arrow_box';
import ExpandedTarget from 'components/vocab_card/expanded_target';
import CardSourceList from 'components/vocab_card/card_source_list';
import CardSection from 'components/vocab_card/card_section';
import MediaSection from 'components/vocab_card/media_section';
import MediaWithSupplement from "components/media/media_with_supplement";
import CardWordText from "components/card/card_word_text";
import { pixify } from "@seedlang/utils/src";

@observer
class WordPronunciationCard extends React.Component {

  render() {
    return (
      <CardWrapper
        card={this.props.card}
        cardState={this.props.cardState}
        userCard={this.props.userCard}
        showWordCard={this.props.showWordCard}
        icons={this.props.icons}
      >
        <CardContent>
          {
            this.props.cardState.revealed &&
              <MediaWithSupplement
                card={this.props.card}
                cardState={this.props.cardState}
                autoPlay={this.props.inView}
                borderTopRadius={10}
                afterVideoPlayback={this.props.afterVideoPlayback}
                borderBottomRadius={DeckUI.layout.cardTextHasMinimumHeight ? 10 : 0}
                videoClip={ this.props.card.targetVideoClip }
                supplementVideoClip={this.props.card.supplementVideoClip}
                supplementImage={this.props.card.supplementImage}
                usingDataSaverHeader={AppUI.usingDataSaverHeader}
                supplementBottom={DeckUI.layout.supplementBottom}
                loopSupplement={DeckUI.user.loopSupplement}
                hideSupplement={!this.props.showDeckSupplement}
                maximizedWidth={pixify(DeckUI.layout.videoHeight)}
                minimizedWidth={pixify(DeckUI.layout.supplementWidth)}
                supplementMaximized={this.props.supplementMaximized}
                loadVideo
                marginBottom={DeckUI.layout.cardTextHasMinimumHeight ? 10 : 0}
              />
          }
          {
            !this.props.cardState.revealed &&
              <CardSection
                background={this.props.cardState.revealed ? "#FFF" : "#e8e8e8"}
                height={this.props.expandedTargetSectionHeight}
                borderTopRadius={this.props.cardState.revealed ? 0 : 10}
              >
                <ExpandedTarget
                  card={this.props.card}
                  cardState={this.props.cardState}
                  word={this.props.card.word}
                  showVocabTriadIntro={this.props.showVocabTriadIntro}
                  prompt={!this.props.cardState.revealed ? `Read the ${this.props.card.word.isPhoneme || this.props.card.word.isGrapheme ? 'sound' : 'word'} out loud` : null}
                />
              </CardSection>
          }
          {
            this.props.cardState.revealed &&
              <CardWordText
                showIfRevealed
                toggleToShowSources
                hideSources
                hasUserInput
                card={this.props.card}
                cardState={this.props.cardState}
                sectionHeight={DeckUI.layout.contentHeight - DeckUI.layout.innerDeckWidth - DeckUI.layout.userInputHeight}
                showType={this.props.cardState.showType}
              />
          }
          <CardUserInput
            disabled={!this.props.inView}
            card={this.props.card}
            cardState={this.props.cardState}
          />
          <CardArrowBox
            card={this.props.card}
            possibleArrowBoxes={this.props.possibleArrowBoxes}
            currentArrowBox={this.props.currentArrowBox}
          />
        </CardContent>
        {
          this.props.showEvaluationButtons &&
            <EvaluateButtons
              card={this.props.card}
              cardState={this.props.cardState}
              userCard={this.props.userCard}
              onNextClick={this.props.onNextClick}
              advanceCard={this.props.advanceCard}
            />
        }
        {
          !this.props.showEvaluationButtons &&
            <CardButtons>
              <CardButton
                submitOnEnter
                shortcut="1"
                text="Reveal Pronunciation"
                backgroundColor={Theme.orange}
                icon={<DownIcon />}
                onClick={() => DeckUI.reveal(this.props.card, this.props.cardState)}
                disabled={!this.props.inView}
              />

            </CardButtons>
        }
      </CardWrapper>
    );
  }
}

export default DeckCloseWebWrapper(PronunciationCardWrapper(WordPronunciationCard));
