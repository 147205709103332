import React from 'react';
import { observer } from 'mobx-react';
import autobind from 'autobind-decorator';
import { computed } from 'mobx';
import { WordStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';
import WordSearch from 'components/word_search';
import { Link } from 'react-router';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  input {
    width: 100%;
  }
  .word-search {
    display: inline-block;
  }
  .in-place-checkbox {
    display: inline-block;
    margin: 3px 0 0 3px;
  }
`;

@observer
class MinimalPairEdit extends React.Component {

  @computed get filters() {
    let filters = {language_id: this.props.word?.languageId};
    return filters;
  }

  @autobind updateWord(data) {
    WordStore.update({ ids: {wordId: this.props.word.id}, data: data }, this.props.onChange);
  }

  @autobind onRemoveMinimalPairWord() {
    if (!this.props.blockEdit) {
      this.updateWord({minimal_pair_id: null});
    }
  }

  @autobind onSubmit(word) {
    if (!word) { return }
    this.updateWord({minimal_pair_id: word.id});
  }

  render() {
    if (isPresent(this.props.word.minimalPair)) {
      return (
        <div
          className={!this.props.blockEdit ? "editable" : ""}
          style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
        >
          <Link
            to={{name: 'builder.words.edit', params: {wordId: this.props.word.minimalPair.id}}}
          >
            {this.props.word.minimalPair.targetText}
          </Link>
          <i
            onClick={this.onRemoveMinimalPairWord}
            className={`fa fa-times fa-on-click ${this.props.className}`}
          />
        </div>
      );
    }
    return (
      <Wrapper>
        <WordSearch
          onSubmit={this.onSubmit}
          displayField="targetWithWordTypeParentInfinitiveAndSource"
          placeholder="Enter Minimal Pair"
          filters={this.filters}
        />
      </Wrapper>
    )
  }
}

export default MinimalPairEdit;
