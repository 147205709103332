import React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import InPlaceMediaUpload from 'components/form/in_place_media_upload';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import styled from '@emotion/styled';
import autobind from "autobind-decorator";

const Wrapper = styled.div`
  .media-option-index {
    display: flex;
    .media-option {
      position: relative;
      width: ${props => props.width ? props.width : 'calc(25% - 10px)'};
      margin-right: 10px;
      border: 1px solid gray;
      border-radius: 5px;
      padding: 5px;
      img {
        width: 100%;
        height: 100%;
      }
      .selected {
        margin-top: 10px;
      }
    }
    .add-media {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 30px;
      cursor: pointer;
    }
    .fa-times {
      position: absolute;
      top: 0;
      right: 0;
      background: red;
      width: 13px;
      height: 13px;
      color: white;
      border-radius: 40px;
      font-size: 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: z("foreground");
    }
  }
`;

@observer
class MediaOptionIndex extends React.Component {

  @computed get isMultipleChoice() {
    return this.props.exerciseType?.slug === 'multipleChoice';
  }

  @autobind afterChangeSelected(mediaOption, value) {
    if (value) {
      mediaOption.set('selected', true);
    } else {
      mediaOption.set('selected', false);
    }
  }

  render() {
    return (
      <Wrapper
        width={this.isMultipleChoice ? '100%' : null}
      >
        <div className='media-option-index'>
          {
            this.props.exerciseEntry.mediaOptions.map(item => {
              return (
                <div
                  key={item.id}
                  className='media-option'
                >
                  {
                    item.noMedia &&
                      <i
                        onClick={() => this.props.onDestroyMediaOption(item.id)}
                        className='fa fa-times'
                      />
                  }
                  <InPlaceMediaUpload
                    autoPlay
                    simpleVideoPlayer
                    loop
                    muted
                    image={item.image}
                    afterChange={this.props.onChangeMedia}
                    onChange={this.props.onChangeMedia}
                    mediaId={item.id}
                    mediaType="MediaOption"
                    videoClip={item.videoClip}
                  />
                  {
                    !this.isMultipleChoice &&
                      <div className='selected'>
                        <InPlaceCheckbox
                          id={item.id}
                          model="media_options"
                          field="selected"
                          value={item.selected}
                          icon="check"
                          afterChange={this.afterChangeSelected.bind(this, item)}
                        >
                          Correct
                        </InPlaceCheckbox>
                      </div>
                  }
                  {
                    item.noMedia &&
                      <div style={{color: 'red'}}>Please upload a square image</div>
                  }
                </div>
              )
            })
          }
          {
            !this.isMultipleChoice && this.props.exerciseEntry.mediaOptions.length < 4 &&
              <div
                className='media-option add-media'
                onClick={() => this.props.onCreateMediaOption(this.props.exerciseEntry.id)}
              >
                <i className='fa fa-plus' />
              </div>
          }
        </div>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(MediaOptionIndex);
