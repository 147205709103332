import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import { isPresent, pixify } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import { flexCenter } from '@seedlang/style_mixins';

const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  font-size: ${(props) => props.fontSize};
  height: ${(props) => props.height};
  font-weight: bold;
`;

const Breadcrumb = styled.span`
  line-height: 26px;
  text-decoration: ${(props) => props.hasLink ? 'underline' : 'normal'};
  cursor: ${(props) => props.hasLink ? 'pointer' : 'default'};
  .fa-angle-double-right {
    margin: 0 5px;
    vertical-align: baseline;
  }
`;

@observer
class Breadcrumbs extends React.Component {

  static defaultProps = {
    margin: 0,
    fontSize: "16px",
    height: "40px",
  }

  @autobind onClick(name, params) {
    if (name) {
      AppUI.routeStore.routeToNamed(name, params);
    }
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
        fontSize={pixify(this.props.fontSize)}
        height={pixify(this.props.height)}
        id='breadcrumbs'
      >
        {
          this.props.breadcrumbs.map((item, index) => {
            return (
                <>
                  <Breadcrumb
                    hasLink={isPresent(item.routeToName)}
                    onClick={() => this.onClick(item.routeToName, item.routeToParams)}
                    key={item.id}
                  >
                    {item.name}
                    {
                      this.props.breadcrumbs[index + 1] &&
                        <i className='fa fa-angle-double-right' />
                    }
                  </Breadcrumb>
                </>
            )
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Breadcrumbs);
