import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import SiteReleaseCreate from 'pages/builder/site_releases/site_release_create';
import { SiteReleaseStore } from '@seedlang/stores';
import { Link } from 'react-router';
import InPlaceCheckbox from 'components/form/in_place_checkbox';

const Wrapper = styled.div`

`;

@observer
class SiteReleaseIndex extends React.Component {

  constructor(props) {
    super(props);
    SiteReleaseStore.getIndex();
  }

  render() {
    return (
      <Wrapper>
        <SiteReleaseCreate />
        <table className="table-wrapper admin-table">
          <thead>
            <tr>
              <th width="50px" />
              <th width="80px">Release</th>
              <th width="80px">Published</th>
              <th>Title</th>
              <th width="150px"># Comments</th>
            </tr>
          </thead>
          <tbody>
            {
              SiteReleaseStore.indexData.map(item => {
                return (
                  <tr
                    key={item.id}
                  >
                    <td>
                      <Link
                        to={{ name: 'builder.site_releases.edit', params: { siteReleaseId: item.id } }}
                        className="on-click"
                      >
                        <i className="fa fa-chevron-right fa-block" />
                      </Link>
                    </td>
                    <td>{item.version}</td>
                    <td>
                      <InPlaceCheckbox
                        id={item.id}
                        field="published"
                        model="site_releases"
                        value={item.published}
                        icon="check"
                      />
                    </td>
                    <td>
                      {item.title}
                    </td>
                    <td>
                      {item.commentsCount}
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SiteReleaseIndex);
