import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import Alert from 'components/alert';
import Paginator from 'components/paginator';
import { Link } from 'react-router';
import { isPresent } from '@seedlang/utils';

const Wrapper = styled.div`

`;

const SiteRelease = styled.div`
  display: flex;
  background: white;
  border-bottom: 1px solid gray;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background: whitesmoke;
  }
`;

const Title = styled.div`
  flex: 1;
`;

const Version = styled.div`
  width: 100px;
`;

const NumComments = styled.div`
  width: 100px;
  font-size: 13px;
`;


const Date = styled.div`
  width: 70px;
  font-size: 13px;
`;

const Table = styled.div`
  margin: 20px 0 0 0;
`;

@observer
class SiteReleases extends React.Component {

  constructor(props) {
    super(props);
    AppUI.siteReleaseStore.getIndex();
    AppUI.loadUser();
  }

  render() {
    return (
      <Wrapper>
        {
          isPresent(AppUI.user.site) && !AppUI.user.showWebReleaseBanner && !AppUI.user.webReleaseUpToDate &&
            <Alert
              textAlign="center"
              onClick={() => window.location.reload()}
              cursor="pointer"
            >
              We have released updates to Seedlang. Please click here to get the latest updates.
            </Alert>
        }
        <h3>Site Releases</h3>
        <div>
          Latest Seedlang release: {AppUI.userIsLoaded && AppUI.user.site && AppUI.user.site.currentWebVersion}
        </div>

        {
          AppUI.user.webReleaseUpToDate &&
            <div>
              <span role="img" aria-label="celebration">🎉</span> You are up to date with the latest Seedlang release
            </div>
        }
        {
          !AppUI.user.webReleaseUpToDate &&
            <div>
              Your Version: {AppUI.user.lastWebVersionDownloaded}
            </div>
        }
        <Table>
          {
            AppUI.siteReleaseStore.indexData.map(item => {
              if (item.forumThreadId) {
                return (
                  <Link
                    to={{name: 'forums.forum_threads.show', params: {forumId: "9332f9ec-35ac-453d-aab3-fec5d814e300", forumThreadId: item.forumThreadId}}}
                    key={item.id}
                  >
                    <SiteRelease>
                      <Date>
                        {item.createdAt.formattedDate}
                      </Date>
                      <Version>
                        {item.version}{item.published ? '' : '[unp]'}
                      </Version>
                      <Title>
                        {item.title}
                      </Title>
                      <NumComments>
                        {item.commentsCount} Comments
                      </NumComments>
                    </SiteRelease>
                  </Link>
                )
              } else {
                return <span />
              }
            })
          }
        </Table>
        <Paginator
          store={AppUI.siteReleaseStore}
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SiteReleases);
